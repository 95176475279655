import {Injectable, OnDestroy} from '@angular/core';
import {
    FrontCurrentPersonBusinessService, switchTap,
} from 'lib-front';
import {BehaviorSubject, combineLatest, noop, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, debounceTime, filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {FleetImportHttpService} from '../http/fleetImportHttp.service';
import {CurrentUserContextService} from '../business/currentUserContext.service';

@Injectable()
export class ProgressingImportService implements OnDestroy {
    private currentImportIds: Subject<string[]> = new BehaviorSubject([]);
    private searchingTaskGroups = false;
    private foAccountId: string;

    private pendingImportsSubscription: Subscription;

    constructor(private currentPersonBusinessService: FrontCurrentPersonBusinessService,
        private fleetImportHttpService: FleetImportHttpService,
        private readonly currentUserContextService: CurrentUserContextService
    ) {
        // listen change of person and timer
        // if the current user exist : get the imports
        // if not delete all of them
        this.pendingImportsSubscription = combineLatest([this.currentPersonBusinessService.detailedPerson$, timer(0, 30_000)])
            .pipe(
                debounceTime(100),
                map(personAndTimer => personAndTimer[0]),
                switchTap(person => {
                    if (person) {
                        return this.currentUserContextService.getCurrentUserContext$().pipe(
                            filter(currentUserContext => !!currentUserContext)
                        );
                    } else {
                        return of(person);
                    }
                }),
                switchMap(person => {
                    if (person) {
                        const foAccount = person.foAccounts.find(foAccountOfPerson => foAccountOfPerson.foAccountRef == currentUserContextService.getCurrentFoAccountId());
                        if (foAccount) {
                            this.foAccountId = foAccount.foAccountRef;
                            return this.searchProgressingImport().pipe(catchError(() => of(noop())));
                        } else {
                            return of(this.deleteImports());
                        }
                    } else {
                        return of(this.deleteImports());
                    }
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.pendingImportsSubscription?.unsubscribe();
    }

    /**
     * Searching imports ids
     * If you want result, subscribe to currentTaskGroup
     */
    public searchProgressingImport(): Observable<void> {
        if (this.searchingTaskGroups || !this.foAccountId) {
            return of(noop());
        }

        this.searchingTaskGroups = true;

        return this.fleetImportHttpService.fetchInProgressImportIds(this.foAccountId)
            .pipe(
                tap(taskGroupIds => this.currentImportIds.next(taskGroupIds)),
                map(() => noop()),
                finalize(() => this.searchingTaskGroups = false)
            );
    }

    /**
     * Get the events observable for read purpose
     */
    public get currentImportIds$(): Observable<string[]> {
        return this.currentImportIds.asObservable();
    }

    /**
     * Remove cache imports
     */
    public deleteImports(): void {
        this.currentImportIds.next([]);
    }
}
