import {NgModule} from '@angular/core';
import {SubscriptionsComponent} from './subscriptions.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../../components/components.module';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AddOptionsComponent} from './add-options/add-options.component';
import {PipesModule} from '../../../../pipes/pipes.module';
import {RevokeOptionsComponent} from './revoke-options/revoke-options.component';
import {UpdateSubscriptionModule} from './update-subscription/update-subscription.module';
import {SubscriptionsResolver} from './subscriptions.resolver';
import {SubscriptionsGuard} from './subscriptions.guard';
import {SubscriptionsReadRoleGuard} from './subscriptions-read-role.guard';
import {SubscriptionsWriteRoleGuard} from './subscriptions-write-role.guard';
import {DirectivesModule} from '../../../../directives/directives.module';

@NgModule({
    declarations: [
        SubscriptionsComponent,
        AddOptionsComponent,
        RevokeOptionsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ComponentsModule,
        FormsModule,
        RouterModule,
        PipesModule,
        UpdateSubscriptionModule,
        DirectivesModule
    ],
    providers: [
        SubscriptionsGuard,
        SubscriptionsResolver,
        SubscriptionsReadRoleGuard,
        SubscriptionsWriteRoleGuard
    ]
})
export class SubscriptionsModule {

}
