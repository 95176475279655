import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {
    ChargeRecordCriteria,
    ConsumptionKind,
    ConsumptionStatus,
    FrontEndFleetConfig,
    FrontFoAccountHttpService,
    FrontInvoiceBusinessService,
    FrontInvoiceHttpService,
    FrontSessionBusinessService,
    HttpParamsUtil
} from 'lib-front';
import {MetaConsumptionDto} from '../../../../../domain/meta/metaConsumptionDto';
import {ConsumptionCriteria} from '../../../../../domain/consumptionCriteria';
import {finalize, map, switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {ProConsumptionHttpService} from '../../../../../services/http/proConsumptionHttp.service';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {InactivityService} from '../../../../../services/utils/inactivity.service';
import {RepeatService} from '../../../../../services/utils/repeat.service';
import {MetaConsumptionsKpi} from '../../../../../domain/meta/kpi/metaConsumptionsKpi';

@Component({
    selector: 'consumption-tab',
    templateUrl: './consumptionTab.component.html',
    styleUrls: ['./consumptionTab.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ConsumptionTabComponent implements OnInit {

    @Input() fleetConfig: FrontEndFleetConfig;

    ConsumptionStatus = ConsumptionStatus;
    ConsumptionKind: ConsumptionKind[];

    consumptions: Array<MetaConsumptionDto>;
    isSearchingConsumptions: boolean;

    consumptionsKpi?: MetaConsumptionsKpi;
    isSearchingKpis: boolean;

    criteria: ConsumptionCriteria;
    consumptionStatuses: Array<ConsumptionStatus> = [ConsumptionStatus.NEW, ConsumptionStatus.INVOICING, ConsumptionStatus.INVOICED];

    page: number = 1;
    maxPage: number;
    displayLimitMessage: boolean = false;

    consumptionSelectedId: string;

    inactiveRefreshIntervalSubscription: Subscription;
    refundAutomaticallyEnabled: boolean = false;

    private goingToInvoiceUrlMap: Map<string, boolean> = new Map<string, boolean>();

    constructor(private readonly invoiceService: FrontInvoiceHttpService,
        private readonly invoiceBusiness: FrontInvoiceBusinessService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly frontFoAccountService: FrontFoAccountHttpService,
        private readonly consumptionService: ProConsumptionHttpService,
        private readonly notificationService: NotificationService,
        private readonly inactivityService: InactivityService,
        private readonly repeatService: RepeatService) {

    }
    ngOnInit(): void {
        this.criteria = {
            from: moment().startOf('month'),
            to: moment(),
            status: null,
            kind: null,
            skip: 0,
            limit: 10,
            excludeExpenseReportChargeConsumption: true
        };
        this.frontFoAccountService.findFOAccount().subscribe(foAccountWrapper => {
            this.refundAutomaticallyEnabled = foAccountWrapper.refundedExpensesConfiguration.automatically;
            this.ConsumptionKind = Object.values(ConsumptionKind)
                .filter(kind => this.refundAutomaticallyEnabled ? true : kind !== ConsumptionKind.EXPENSE_REPORT_REFUND);
        });
        this.search(this.criteria);
        this.inactivityService.init(
            () => this.setRefreshInterval(),
            () => this.clearRefreshInterval()
        );
    }

    search(criteria: ConsumptionCriteria, page = 1, onlySearchConsumptions: boolean = false): void {
        this.criteria.skip = (page - 1) * this.criteria.limit;
        this.page = page;
        this.searchConsumptions(criteria);

        if (!onlySearchConsumptions) {
            this.searchKPIs(criteria);
        }
    }

    searchConsumptions(criteria: ConsumptionCriteria) {
        this.isSearchingConsumptions = true;
        this.consumptionService.findConsumptions(criteria)
            .pipe(finalize(() => this.isSearchingConsumptions = false))
            .subscribe(consumptions => {
                this.consumptions = consumptions;
            },
            error => {
                this.notificationService.error(error.error.labelKey || 'error.metaConsumption.search.error');
            });
    }

    searchKPIs(criteria: ConsumptionCriteria) {
        this.isSearchingKpis = true;
        this.consumptionService.findConsumptionsKpi(criteria)
            .pipe(finalize(() => this.isSearchingKpis = false))
            .subscribe(consumptionsKpi => {
                this.consumptionsKpi = consumptionsKpi;
                this.maxPage = Math.min(Math.max(Math.ceil(this.consumptionsKpi.nbOfConsumptions / this.criteria.limit), 1), 10);
                this.displayLimitMessage = this.consumptionsKpi.nbOfConsumptions > 100;
            });
    }

    goToInvoiceUrl(consumption: MetaConsumptionDto) {
        this.goingToInvoiceUrlMap.set(consumption.consumptionId, true);
        this.sessionBusinessService.currentConnectedUser$.pipe(
            map(connectedUser => connectedUser?.user._id),
            switchMap(userId => this.loadInvoiceUrlForConsumption(userId, consumption)),
            finalize(() => this.goingToInvoiceUrlMap.set(consumption.consumptionId, false))
        ).subscribe(
            url => window.open(url, '_blank'),
            error => this.notificationService.error(error.error.label.key || 'error.consumptions.invoice.error')
        );
    }

    export(criteria: ChargeRecordCriteria): void {
        const params = HttpParamsUtil.toHttpParams(criteria);
        window.open(`/api/consumptions/data/export/csv?${params.toString()}`);
    }

    toggleConsumptionDetails(consumption: MetaConsumptionDto) {
        if (consumption.consumptionId !== this.consumptionSelectedId) {
            this.consumptionSelectedId = consumption.consumptionId;
        } else {
            this.consumptionSelectedId = null;
        }
    }

    trackByConsumptionId(index: number, consumption: MetaConsumptionDto) {
        return consumption.consumptionId ?? index;
    }

    isConsumptionInvoiceLoading(consumption: MetaConsumptionDto) {
        return this.goingToInvoiceUrlMap.has(consumption.consumptionId)
            && this.goingToInvoiceUrlMap.get(consumption.consumptionId);
    }

    validateNumberInput(event) {
        // Authorize suppression key
        if (event.key === 'Backspace' || event.key === 'Delete') {
            return;
        }

        if (!/^[0-9]*$/.test(event.key)) {
            event.preventDefault();
        }
    }

    private loadInvoiceUrlForConsumption(userId: string, consumption: MetaConsumptionDto): Observable<string> {
        return this.invoiceService
            .findInvoiceById(userId, consumption.invoiceId)
            .pipe(
                map(invoice => this.invoiceBusiness.getInvoicePdfUrlFromInvoiceWrapper(invoice))
            );
    }

    private refreshData() {
        this.searchConsumptions(this.criteria);
        this.searchKPIs(this.criteria);
    }

    private setRefreshInterval() {
        this.refreshData();
        this.inactiveRefreshIntervalSubscription =
            this.repeatService.repeat(() => this.refreshData(), 5 * 60 * 1000);
    }

    private clearRefreshInterval() {
        if (this.inactiveRefreshIntervalSubscription) {
            this.inactiveRefreshIntervalSubscription.unsubscribe();
        }
    }
}

