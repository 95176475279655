import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FrontEndService} from '../../services/frontEnd.service';
import {map} from 'rxjs/operators';
import {forkJoin, Subscription} from 'rxjs';
import {
    buildRole,
    CurrentSubscriberRolesService,
    FleetUser,
    FrontEndFleetConfig,
    FrontInvoiceBusinessService,
    FrontPersonPortalContextService,
    FrontSessionBusinessService,
    SubscriberRoleLevel,
    SubscriberRoleType
} from 'lib-front';
import {ReportingService} from '../../services/business/reporting.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {'class': 'cell auto grid-y'}
})
export class AppComponent implements OnInit, OnDestroy {
    public isFirstBillPayed: boolean;
    private user: FleetUser;
    public isStationManager: boolean;
    public isRefundManager: boolean;
    public fleetConfig: FrontEndFleetConfig;
    public hasInvoiceReadRole: boolean;
    public hasChargesReadRole: boolean;
    public hasPassReadRole: boolean;
    public hasExpenseReportReadRole: boolean;
    public hasSubscriptionsReadRole: boolean;
    public hasChargeAreasReadRole: boolean;
    public hasConfigurationTabReadRole: boolean;
    /**
     * true means the home view is replaced by reporting view
     * false means the reporting view is not accessible, so home is displayed
     * */
    public isReportingView: Boolean = undefined;

    private stationManagerHandlerSubscription: Subscription;

    constructor(route: ActivatedRoute, router: Router, invoiceBusinessService: FrontInvoiceBusinessService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly frontEndService: FrontEndService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly reportingService: ReportingService) {
        route.data.subscribe(data => {
            this.user = data.user.user;

            invoiceBusinessService.hasPaidFirstInvoice(this.user._id)
                .subscribe(isFirstBillPayed => this.isFirstBillPayed = isFirstBillPayed);
        });
        this.computeRoleAuthorizations();
    }

    ngOnInit(): void {
        this.personPortalContextService.isStationManager().subscribe(isStationManager => this.isStationManager = isStationManager);

        // If current user just became station manager, then we want to refresh the entire page
        this.stationManagerHandlerSubscription = this.sessionBusinessService.handleBecameStationManagerSubject()
            .subscribe(() => {
                window.location.reload();
            });

        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => {
                this.fleetConfig = fleetConfig;
                this.computeReportingView();
            });

        this.personPortalContextService.isRefundManager().subscribe(isRefundManager => this.isRefundManager = isRefundManager);
    }

    ngOnDestroy() {
        this.stationManagerHandlerSubscription.unsubscribe();
    }

    public placeRedirect(url: string): string {
        return this.isFirstBillPayed ? url : '#';
    }

    private computeRoleAuthorizations() {
        forkJoin({
            hasInvoiceReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.INVOICES, SubscriberRoleLevel.READ)
            ),
            hasChargesReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.CHARGES, SubscriberRoleLevel.READ)
            ),
            hasPassReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.PASS, SubscriberRoleLevel.READ)
            ),
            hasExpenseReportReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.EXPENSES_REPORTS, SubscriberRoleLevel.READ)
            ),
            hasSubscriptionsReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.SUBSCRIPTIONS, SubscriberRoleLevel.READ)
            ),
            hasChargeAreasReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.READ)
            ),
            hasConfigurationTabReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.CONFIGURATION_TAB, SubscriberRoleLevel.READ)
            )
        }).subscribe((roles) => {
            this.hasInvoiceReadRole = roles.hasInvoiceReadRole;
            this.hasChargesReadRole = roles.hasChargesReadRole;
            this.hasPassReadRole = roles.hasPassReadRole;
            this.hasExpenseReportReadRole = roles.hasExpenseReportReadRole;
            this.hasSubscriptionsReadRole = roles.hasSubscriptionsReadRole;
            this.hasChargeAreasReadRole = roles.hasChargeAreasReadRole;
            this.hasConfigurationTabReadRole = roles.hasConfigurationTabReadRole;
        });
    }

    private computeReportingView() {
        if (!this.fleetConfig?.reportingEnabled) {
            this.isReportingView = false;
        } else {
            this.reportingService.activeReportingTabs()
                .subscribe(tabs => {
                    this.isReportingView = tabs.length !== 0;
                });
        }
    }
}
