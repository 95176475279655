import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
    selector: '[iziviaTariffPrice]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: TariffPriceValidatorDirective, multi: true}
    ]
})
export class TariffPriceValidatorDirective implements Validator {
    @Input('iziviaTariffPrice') freeCost: boolean;

    validate(control: AbstractControl): ValidationErrors | null {
        return validatePrice(this.freeCost)(control);
    }
}

export function validatePrice(isFree: boolean): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        return !isFree && control.value <= 0 ? {'wrongPrice': {value: control.value, isFree: isFree}} : null;
    };
}
