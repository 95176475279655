import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    FOAccountWrapper, FrontEndInfo,
    MediaCanOrderDto,
    MediaOrderContext,
    SubscriptionPack
} from 'lib-front';
import {UntypedFormBuilder, NgForm} from '@angular/forms';
import {MediaOrderDto} from '../../../../../../domain/mediaOrderDto';
import {isEmpty} from 'lodash-es';
import { ModeAddressForm } from '../../../../../../components/address-form/modeAddressForm';
import {Subscription} from 'rxjs';
import {FrontEndService} from '../../../../../../services/frontEnd.service';

@Component({
    selector: 'update-subscription-medias',
    templateUrl: './update-subscription-medias.component.html',
    styleUrls: ['./update-subscription-medias.component.scss'],
})
export class UpdateSubscriptionMediasComponent implements OnInit, OnDestroy, AfterViewInit {
    ModeAddressForm = ModeAddressForm;

    @Input() set foAccount(foAccount) {
        this._foAccount = foAccount;
        this.initKeepAddress();
    }

    @Input() set subscriptionPack(subscriptionPack) {
        this._subscriptionPack = subscriptionPack;
        this.checkOrderAllow();
    }

    @Input() mode: 'update' | 'new' = 'update';

    @Input() numberCompatiblePass: number;
    @Input() numberActivablePass: number;
    @Input() mediaOrderForm: MediaOrderDto;
    @Input() mediaCanOrderDtoList: MediaCanOrderDto[];

    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter(true);
    @Output() changeDeliveryAddress: EventEmitter<boolean> = new EventEmitter(false);
    @Output() mediaOrdersEvent: EventEmitter<MediaOrderDto> = new EventEmitter<MediaOrderDto>();

    @ViewChild('updateSubscriptionMediasForm') form: NgForm;

    public _foAccount: FOAccountWrapper;
    public _subscriptionPack: SubscriptionPack;
    public keepAddress: boolean;
    public isValidAddress: boolean;

    public isOrderAllow: boolean;
    public isOrderAllowfromAccount: boolean;
    public mediaOrderContext: MediaOrderContext;
    frontEndInfo: FrontEndInfo;
    private frontEndInfoSubscription: Subscription;

    constructor(private fb: UntypedFormBuilder,
        private readonly frontEndService: FrontEndService) {
        this.isOrderAllow = true;
        this.isOrderAllowfromAccount = true;
    }

    public ngOnInit() {
        this.frontEndInfoSubscription = this.frontEndService.currentFrontEndInfo$.subscribe((frontEndInfo: FrontEndInfo) => this.frontEndInfo = frontEndInfo);
    }

    ngOnDestroy() {
        this.frontEndInfoSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.form.valueChanges.subscribe(() => {
            this.sendFormValidation(this.mediaOrderForm);
        });
    }

    public canLinkAllMedia(): boolean {
        const maxMediasByAccount = this._subscriptionPack.maxMediasByAccount;
        const canLinkAllCompatiblePasses = !maxMediasByAccount || maxMediasByAccount === 0 || maxMediasByAccount >= this.numberCompatiblePass;

        return this.numberCompatiblePass > 0 && canLinkAllCompatiblePasses;
    }

    private checkOrderAllow() {
        if (!!this._subscriptionPack) {
            let mediaFamilyConfigurations = Object.keys(this._subscriptionPack.mediaFamilyConfigurations)
                .filter(mediaFamilyRef => this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].activatedOnCustomerAccount
                    && this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].maxProducedWithSubscription
                    && this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].minProducedWithSubscription !== undefined);
            this.isOrderAllow = !!mediaFamilyConfigurations && mediaFamilyConfigurations.length > 0;
            mediaFamilyConfigurations = Object.keys(this._subscriptionPack.mediaFamilyConfigurations)
                .filter(mediaFamilyRef => this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].activatedOnCustomerAccount
                    && this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].maxProducedFromAccount);
            this.isOrderAllowfromAccount = !!mediaFamilyConfigurations && mediaFamilyConfigurations.length > 0;
        }
    }

    private initKeepAddress() {
        this.keepAddress = this._foAccount && !isEmpty(this._foAccount.companyName) && !isEmpty(this._foAccount.companySiret)
            && this._foAccount.delivery && !isEmpty(this._foAccount.delivery.firstName)
            && !isEmpty(this._foAccount.delivery.lastName) && !isEmpty(this._foAccount.delivery.phoneNumber)
            && !isEmpty(this._foAccount.delivery.email) && this._foAccount.delivery.address
            && !isEmpty(this._foAccount.delivery.address.route) && !isEmpty(this._foAccount.delivery.address.postalCode)
            && !isEmpty(this._foAccount.delivery.address.city);
    }

    public onFormAddressChange(isValid: boolean) {
        this.isValidAddress = isValid;
        this.sendFormValidation(this.mediaOrderForm);
    }

    public sendFormValidation(mediaOrderFormValue: MediaOrderDto) {
        const quantity = mediaOrderFormValue?.quantity;
        this.isFormValid.emit(!this.form.invalid &&
            (!this.isOrderAllow || quantity === 0 || this.isValidAddress));
    }

    public keepDeliveryAddress() {
        this.keepAddress = !this.keepAddress;
        this.changeDeliveryAddress.emit(this.keepAddress);
    }

    public updateMediaOrderForm(mediaOrderFormValue: MediaOrderDto) {
        this.sendFormValidation(mediaOrderFormValue);
        this.mediaOrdersEvent.emit(mediaOrderFormValue);
    }
}
