import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {FrontPersonPortalContextService} from 'lib-front';
import {map} from 'rxjs/operators';

@Injectable()
export class TariffGuard implements CanActivate {

    constructor(private personPortalContextService: FrontPersonPortalContextService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return forkJoin([
            this.personPortalContextService.isInfraManagementTariffCreationEnabled(),
            this.personPortalContextService.isStationManager()
        ]).pipe(
            map(([isInfraManagementTariffCreationEnabled, isConnectedUserStationManager]) =>
                isInfraManagementTariffCreationEnabled && isConnectedUserStationManager)
        );
    }

}
