<section class="filter-wrapper" *ngIf="autofocus">
    <div class="keyword-wrapper" [ngClass]="{'_iconTime': iconTime}">
        <input [formControl]="queryField"
               type="text"
               id="keyword"
               [placeholder]="placeholder || 'Tapez votre recherche ...'"
               autocomplete="off"
               autofocus
               (focus)="searchItems(queryField.value)"
               (blur)="cleanResults()"
        />
    </div>
    <ul class="filter-select" [ngClass]="{'_openDropdown': results && results.length}">
        <li *ngFor="let result of results" class="filter-select-list"
            (click)="selectValue(result)">
            <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: result}"></ng-container>
        </li>
    </ul>
</section>

<section class="filter-wrapper" *ngIf="!autofocus">
    <div class="keyword-wrapper" [ngClass]="{'_iconTime': iconTime}">
        <input [formControl]="queryField"
               type="text"
               id="keyword"
               [placeholder]="placeholder || 'Tapez votre recherche ...'"
               autocomplete="off"
               (focus)="searchItems(queryField.value)"
               (blur)="cleanResults()"
        />
    </div>
    <ul class="filter-select" [ngClass]="{'_openDropdown': results && results.length}">
        <li *ngFor="let result of results" class="filter-select-list"
            (pointerdown)="selectValue(result)">
            <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: result}"></ng-container>
        </li>
    </ul>
</section>
