<a *ngIf="currentFoAccount"
   (click)="openCloseFoAccountPanel($event)"
   class="accountSwitcher" #accountSwitcher>
    <a>{{currentFoAccount.companyName}} {{currentFoAccount.siteName ? '- ' + currentFoAccount.siteName : ''}}
        <span *ngIf="finalClientConfigurable && currentFoAccount.finalClientRef && currentFoAccount.socialReason"
              class="finalClientName">
            {{buildFinalClientName(currentFoAccount)}}
        </span>
        <div class="accountSwitcher-icon" *ngIf="isFoAccountPanelOpen">
            <i class="fas fa-chevron-down"></i>
        </div>
        <div class="accountSwitcher-icon" *ngIf="!isFoAccountPanelOpen">
            <i class="fas fa-chevron-up"></i>
        </div>
    </a>
    <div class="accountPanelOpen" [hidden]="!isFoAccountPanelOpen">
        <div class="accountPanelOpen-wrapper">
            <div class=" cell search-label space-top-8 space-bottom-16">
                <input type="text"
                       [(ngModel)]="foAccountNameFilter"
                       (ngModelChange)="filterFoAccounts()"
                       (click)="$event.stopPropagation()"
                       placeholder="{{'header.foAccounts.filter.placeholder' | translate}}"
                       class="search cell auto force-space-right-16">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="accountPanelOpen-wrapper_content" *ngFor="let foAccount of filteredFoAccounts">
                <div (click)="changeCurrentFoAccount(foAccount)">
                    <p>
                        {{buildDisplayedName(foAccount)}}
                        <span *ngIf="finalClientConfigurable && foAccount.socialReason && foAccount.finalClientRef"
                              class="finalClientName">
                            {{buildFinalClientName(foAccount)}}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="accountPanelOpen-wrapper_button">
            <button class="button primary" (click)="logout()">
                {{'header.logout' | translate}}
            </button>
        </div>
    </div>
</a>
