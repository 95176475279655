<h3 class="space-bottom-8">{{'stations.edit.tariff.description'|translate}}</h3>

<div class="grid-x cell auto">
    <div class="grid-y cell small-8 tariffContainer">
        <h5 class="space-bottom-8"><i class="fas fa-lock"></i> {{'stations.edit.tariff.availableTariffs.inscriptionCode'|translate}}</h5>
        <div class="grid-y cell">
            <div *ngFor="let inscriptionCodeTariff of linkedInscriptionCodeTariffs; trackBy: trackByTariffId"
                 class="grid-x cell align-middle">
                <div class="cell auto tariff"
                     [class.selected]="selectedTariff?.id === inscriptionCodeTariff.id"
                     (click)="selectTariff(inscriptionCodeTariff.id)">
                    {{'tariff.name' | translate:{name: inscriptionCodeTariff.name} }}
                </div>
                <button (click)="unlinkTariff(inscriptionCodeTariff.id)"
                        *ngIf="!tariffsAllowSubscriberToDeclareStation"
                        [iziviaLoading]="unlinkingTariff"
                        class="cell shrink button round orange">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </div>
            <div class="grid-x cell align-middle"
                 *ngIf="!linkedInscriptionCodeTariffs || linkedInscriptionCodeTariffs.length === 0">
                {{'stations.edit.tariff.noTariff' | translate}}
            </div>

            <div class="cell" *ngIf="unLinkedInscriptionCodeTariffs.length > 0 && !tariffsAllowSubscriberToDeclareStation">
                <h6 class="space-top-32 space-bottom-8">{{'stations.edit.tariff.otherTariffs.description'|translate}}</h6>
            </div>
            <div class="cell" *ngIf="unLinkedInscriptionCodeTariffs.length > 0 && !tariffsAllowSubscriberToDeclareStation">
                <select [(ngModel)]="inscriptionCodeTariffIdToLink"
                        [disabled]="!unLinkedInscriptionCodeTariffs || unLinkedInscriptionCodeTariffs.length === 0"
                        (ngModelChange)="selectTariff($event)">
                    <option *ngIf="!unLinkedInscriptionCodeTariffs || unLinkedInscriptionCodeTariffs.length === 0"
                            value="">{{'stations.edit.tariff.link.noTariff'|translate}}
                    </option>

                    <option *ngFor="let inscriptionCodeTariff of unLinkedInscriptionCodeTariffs; let first = first; trackBy: trackByTariffId"
                            [value]="inscriptionCodeTariff.id">
                        {{'tariff.name'|translate:{name: inscriptionCodeTariff.name} }}
                    </option>
                </select>
                <button class="button space-top-24"
                        (click)="linkTariff(inscriptionCodeTariffIdToLink)"
                        [iziviaLoading]="linkingTariff"
                        [disabled]="!unLinkedInscriptionCodeTariffs || unLinkedInscriptionCodeTariffs.length === 0">
                    {{'stations.edit.tariff.link.button'|translate}}
                </button>
            </div>
        </div>

        <br/><br/>

        <h5 *ngIf="!tariffsAllowSubscriberToDeclareStation && canCreatePaynowTariffs">
            <i class="fas fa-lock-open"></i> {{'stations.edit.tariff.availableTariffs.qrCode'|translate}}
            <i class="fas fa-question-circle"
               [title]="'stations.edit.tariff.availableTariffs.qrCodeDesc' | translate"></i>
        </h5>
        <div class="grid-y cell" *ngIf="!tariffsAllowSubscriberToDeclareStation && canCreatePaynowTariffs">
            <div *ngFor="let qrCodeTariff of linkedQRCodeTariffs; trackBy: trackByTariffId"
                 class="grid-x cell align-middle">
                <div class="cell auto tariff" [class.selected]="selectedTariff?.id === qrCodeTariff.id"
                     (click)="selectTariff(qrCodeTariff.id)">
                    {{'tariff.name'|translate:{name: qrCodeTariff.name} }}
                </div>
                <button (click)="unlinkTariff(qrCodeTariff.id)"
                        [iziviaLoading]="unlinkingTariff"
                        class="cell shrink button round secondary">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </div>
            <div class="grid-x cell align-middle"
                 *ngIf="!linkedQRCodeTariffs || linkedQRCodeTariffs.length === 0">
                {{'stations.edit.tariff.noTariff' | translate}}
            </div>

            <div class="cell" *ngIf="linkedQRCodeTariffs.length === 0">
                <select [(ngModel)]="qrCodeTariffIdToLink"
                        [disabled]="!unLinkedQRCodeTariffs || unLinkedQRCodeTariffs.length === 0">
                    <option *ngIf="!unLinkedQRCodeTariffs || unLinkedQRCodeTariffs.length === 0"
                            value="">{{'stations.edit.tariff.link.noTariff'|translate}}
                    </option>

                    <option *ngFor="let qrCodeTariff of unLinkedQRCodeTariffs; let first = first; trackBy: trackByTariffId"
                            [value]="qrCodeTariff.id">
                        {{'tariff.name' | translate:{name: qrCodeTariff.name} }}
                    </option>
                </select>
                <button class="button space-top-24"
                        (click)="linkTariff(qrCodeTariffIdToLink)"
                        [iziviaLoading]="linkingTariff"
                        [disabled]="!unLinkedQRCodeTariffs || unLinkedQRCodeTariffs.length === 0 || linkedQRCodeTariffs.length > 0">
                    {{'stations.edit.tariff.link.button'|translate}}
                </button>
            </div>
            <br/><br/>
        </div>

        <h5 *ngIf="othrTariff">
            <i class="fas fa-road"></i> {{'stations.edit.tariff.othrTariff.title'|translate}}
        </h5>
        <br/>
        <div *ngIf="othrTariff" class="grid-y cell" >
            {{othrTariff.pricingInfo}}
        </div>
    </div>

    <div class="cell auto tariffForm" *ngIf="linkedTariffs?.length > 0 else noTariff">
        <tariff-form *ngIf="!loadingTariff else spinner"
                     [tariff]="selectedTariff"
                     [readonlyMode]="true"
                     [editMode]="false"
                     [disableTariffModification]="true">
        </tariff-form>
    </div>

    <ng-template class="cell auto" #noTariff>{{'stations.edit.tariff.noTariff'|translate}}</ng-template>
</div>

<ng-template #spinner>
    <div class="loader">
        <span class="spinner"></span>
    </div>
</ng-template>
