import {NgModule} from '@angular/core';
import {ProgressingImportService} from './progressingImport.service';

@NgModule({
    imports: [
    ],
    providers: [
        ProgressingImportService,
    ]
})
export class EventsServicesModule { }
