<form #indemnityForm="ngForm"
      *ngIf="indemnityDetails"
      (submit)="save()">

    <div class="grid-y cell auto">
        <h5 *ngIf="indemnityDetails.id">
            {{'config.indemnity.edit.title' | translate}}
            {{indemnityDetails?.name}}
        </h5>
        <h5 *ngIf="!indemnityDetails.id">
            {{'config.indemnity.edit.titleCreate' | translate}}
            {{indemnityDetails?.name}}
        </h5>
        <div *ngIf="indemnityDetails.id">
            <h6>{{'config.tariff.reference'|translate}} :
                <span>{{indemnityDetails.privateName}}</span>
            </h6>
        </div>
    </div>

    <div class="indemnityEdit">
        <div class="grid-x cell auto align-middle editBloc">
            <label for="indemnityName"
                   class="indemnityEdit-label small-5 required">
                <span>{{'config.indemnity.edit.name' | translate}}</span>
            </label>
            <input type="text"
                   [(ngModel)]="indemnityDetails.name"
                   required
                   id="indemnityName"
                   name="indemnityName"
                   class="cell small-8">
        </div>

        <div class="grid-x cell auto align-middle editBloc">
            <div class="cell small-5">
                <label class="indemnityEdit-label required" for="indemnityPrice">
                    <span>{{'config.indemnity.edit.price' | translate}} ({{'config.indemnity.edit.priceHT' | translate}})</span>
                </label>
                <div class="indemnityEdit-subLabel">{{'config.indemnity.edit.priceExplanation' | translate}}</div>
            </div>
            <input type="number"
                   [(ngModel)]="indemnityDetails.tariffElements[0].energyPrice"
                   id="indemnityPrice"
                   min="0"
                   name="indemnityPrice"
                   class="cell small-4">
            <span class="cell auto unit">{{'config.indemnity.edit.currencyUnitByKWh' | translate}}</span>
        </div>

        <div class="grid-x cell auto editBloc">
            <div class="cell text-right">
                <button [disabled]="indemnityForm.invalid || isIndemnityEnergyZero()"
                        [iziviaLoading]="savingTariff"
                        type="submit"
                        class="button">{{'config.indemnity.edit.save' | translate}}</button>
            </div>
        </div>
    </div>
</form>
