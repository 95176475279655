<ng-container [ngPlural]="numberCompatiblePass">
    <ng-template ngPluralCase="1">
        <h6 class="space-bottom-16">{{'update.subscription.pass.link.confirmationMessageSingular' | translate}}</h6>
    </ng-template>
    <ng-template ngPluralCase="other">
        <h6 class="space-bottom-16">{{'update.subscription.pass.link.confirmationMessagePlural' | translate: {passesNumber: numberCompatiblePass} }}</h6>
    </ng-template>
</ng-container>

<label class="checkbox align-middle">
    <input type="checkbox" [ngModel]="linkMedia" (ngModelChange)="onLinkMediaChange($event)">
    <span class="custom-checkbox"></span>
    <span class="label-choice"[ngPlural]="numberCompatiblePass">
        <ng-template ngPluralCase="1">
            {{'update.subscription.pass.link.checkboxLabelSingular' | translate}}
        </ng-template>
        <ng-template ngPluralCase="other">
            {{'update.subscription.pass.link.checkboxLabelPlural' | translate}}
        </ng-template>
    </span>
</label>
