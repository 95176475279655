<form class="grid-x grid-margin-x align-bottom">
    <label *ngIf="isSelfcareInvoiceActivated" class="cell auto">
        <span>{{'income.history.search.invoiceNumber.title' | translate}}</span>
        <input class="cell auto" type="text" [(ngModel)]="searchRequest.invoiceNumber" name="invoiceNumber"
               [placeholder]="'income.history.search.invoiceNumber.placeholder' | translate">
    </label>

    <label class="cell auto">
        <span>{{'income.history.search.financialReport.title' | translate}}</span>
        <input class="cell auto" type="text" [(ngModel)]="searchRequest.financialReportName" name="financialReportName"
               [placeholder]="'income.history.search.financialReport.placeholder' | translate">
    </label>
    <label class="cell small-4">
        <span>{{'income.history.search.startDate.title' | translate}}</span>
        <date-picker class="full-input" [(ngModel)]="searchRequest.from" name="startDate"
                     [placeholder]="'income.history.search.startDate.placeholder' | translate"></date-picker>
    </label>
    <label class="cell small-4">
        <span>{{'income.history.search.endDate.title' | translate}}</span>
        <date-picker class="full-input" [(ngModel)]="searchRequest.to" name="endDate"
                     [placeholder]="'income.history.search.endDate.placeholder' | translate"></date-picker>
    </label>
    <button class="cell shrink button" type="submit" (click)="invoiceSearch()">
        {{'income.history.search.button' | translate}}
    </button>
</form>
