import {NgModule} from '@angular/core';
import {ColorsGuideComponent} from './colors-guide/colors-guide.component';
import {TypographyGuideComponent} from './typography-guide/typography-guide.component';
import {IconsGuideComponent} from './icons-guide/icons-guide.component';
import {FormsGuideComponent} from './forms-guide/forms-guide.component';
import {ButtonsGuideComponent} from './buttons-guide/buttons-guide.component';
import {StyleGuideComponent} from './style-guide.component';
import {DashboardGuideComponent} from './dashboard-guide/dashboard-guide.component';
import {MessagesGuideComponent} from './messages-guide/messages-guide.component';
import {TableGuideComponent } from './table-guide/table-guide.component';
import { StatusGuideComponent } from './status-guide/status-guide.component';
import {BlocksGuideComponent} from './blocks-guide/blocks-guide.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';
import {PrismSyntaxHighlighterDirective} from '../../directives/prism-syntax-highlighter.directive';
import {ServicesModule} from '../../services/services.module';

@NgModule({
    declarations: [
        StyleGuideComponent,
        ColorsGuideComponent,
        TypographyGuideComponent,
        IconsGuideComponent,
        FormsGuideComponent,
        ButtonsGuideComponent,
        DashboardGuideComponent,
        MessagesGuideComponent,
        TableGuideComponent,
        StatusGuideComponent,
        BlocksGuideComponent,
        PrismSyntaxHighlighterDirective
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ServicesModule,
        ComponentsModule
    ]
})
export class StyleGuideModule {
}
