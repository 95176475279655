import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import moment from 'moment';
import {ConsumptionSelfCareHttpService, SelfCareConsumptionCriteria, SelfCareConsumptionResultsDto} from 'lib-front';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'consumption-search',
    templateUrl: './consumption-search.component.html',
    styleUrls: ['./consumption-search.component.scss']
})
export class ConsumptionSearchComponent {
    public criteria: SelfCareConsumptionCriteria = {
        from: moment().subtract(1, 'years').startOf('month'),
        to: moment().endOf('day'),
        skip: 0,
        limit: 10
    };

    @Output()
    public consumptionsChange: EventEmitter<SelfCareConsumptionResultsDto> = new EventEmitter<SelfCareConsumptionResultsDto>();
    @Output()
    public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public page: number = 1;
    public maxPage: number = 0;
    public get loading(): boolean {
        return this._loading;
    }

    public set loading(value: boolean) {
        this._loading = value;
        this.loadingChange.emit(value);
    }
    private _loading: boolean;

    constructor(private readonly consumptionHttpService: ConsumptionSelfCareHttpService) {
    }

    public search(criteria: SelfCareConsumptionCriteria, page = 1): void {
        this.criteria = criteria;
        this.loading = true;
        this.criteria.skip = (page - 1) * this.criteria.limit;
        this.page = page;
        this.consumptionHttpService.findConsumptionToCollect(criteria)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(result => {
                this.maxPage = Math.ceil(result.total / this.criteria.limit);
                this.consumptionsChange.emit(result);
            });
    }

}
