<div class="grid-x">
    <select [(ngModel)]="remoteAction"
            [disabled]="actions.length === 0"
            class="cell auto"
            name="action"
            required>
        <option [value]="undefined"
                *ngIf="!!chargePoint && actions.length === 0">{{ 'chargePoint.actions.none' | translate }}</option>
        <option [value]="undefined"
                *ngIf="!chargePoint && actions.length === 0">{{ 'chargePoint.actions.selectPlug' | translate }}</option>
        <option *ngFor="let action of actions" [value]="action">
            {{'chargePoint.actions.' + action | translate}}
        </option>
    </select>
    <div class="cell shrink space-left-8">
        <button (click)="sendAction()"
                [iziviaLoading]="sendingAction"
                [disabled]="!plug || !remoteAction || !foAccountHasMedia"
                class="button"
                type="button">
            {{'chargePoint.validate' | translate}}
        </button>
    </div>
</div>
