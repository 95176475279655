import {Component, Input} from '@angular/core';
import {ChargeRecordDto} from 'lib-front';

@Component({
    selector: 'charge-record-details',
    templateUrl: './charge-record-details.component.html',
    styleUrls: ['./charge-record-details.component.scss']
})

export class ChargeRecordDetailsComponent {
    @Input() chargeRecord: ChargeRecordDto;

    constructor() {
    }
}
