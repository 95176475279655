<div class="grid-x cell align-center tariffElementEdit">
    <div class="cell small-22">
        <h4 class="tariffElementEdit-title">{{'config.tariff.element.powerPrice'|translate}}</h4>
        <p *ngIf="fleetConfig.emoMention" class="tariffElementEdit-description">{{'config.tariff.element.powerPriceExplanation'|translate}}</p>
        <p *ngIf="!fleetConfig.emoMention" class="tariffElementEdit-description">{{'config.tariff.element.powerPriceExplanationWithoutEmoMention'|translate}}</p>
        <div class="grid-x align-middle grid-padding-x tariffElementEdit-editing">
            <label class="grid-x align-middle">
                <switch trueText=" " falseText=" "
                        [(ngModel)]="paidEnergy" (change)="freeEnergyPriceChange()"
                        [class.disabled]="!editMode" [disabled]="!editMode" name="freeEnergy"></switch>
            </label>
            <ng-container *ngIf="paidEnergy else freeCost">
                <div class="input-group cell small-3 force-inner-space-right-0">
                    <input class="input-group-field cell shrink" [(ngModel)]="tariffElement.energyPrice" min="0"
                           iziviaNumeric [decimals]="4"
                           step="0.0001" type="text" [disabled]="!editMode" [required]="paidEnergy" name="energyPrice "
                           [iziviaTariffPrice]="!paidEnergy">
                    <span
                        class="input-group-label">{{'config.tariff.element.currencyUnitByKWh'|translate:{currencyUnit:
                            '€'} }}</span>
                </div>
            </ng-container>
        </div>
    </div>

    <hr class="tariffElementEdit-separator">

    <div class="cell small-22 tariffElementEdit">
        <h4 class="tariffElementEdit-title">{{'config.tariff.element.parkingTimePrice'|translate}}</h4>
        <p *ngIf="fleetConfig.emoMention" class="tariffElementEdit-description">{{'config.tariff.element.parkingTimePriceExplanation'|translate}}</p>
        <p *ngIf="!fleetConfig.emoMention" class="tariffElementEdit-description">{{'config.tariff.element.parkingTimePriceExplanationWithoutEmoMention'|translate}}</p>
        <div class="grid-x align-middle grid-padding-x tariffElementEdit-editing">
            <label class="grid-x align-middle">
                <switch trueText=" " falseText=" "
                        [(ngModel)]="paidParking" (change)="freeParkingPriceChange()"
                        [class.disabled]="!editMode" [disabled]="!editMode" name="freeParking"></switch>
            </label>
            <ng-container *ngIf="paidParking else freeCost">
                <div class="grid-x cell tariffElementEdit-slider"
                     *ngIf="!!tariffElement.parkingTimePrice"
                     [class.disabled]="!editMode">
                    <div class="cell">
                        <ngx-slider class="slider"
                                    name="parkingTimeSlider"
                                    [(highValue)]="tariffElement.parkingTimePrice.shortDuration.duration"
                                    [(value)]="tariffElement.parkingTimePrice.freeDuration.duration"
                                    [options]="sliderOptions"></ngx-slider>
                    </div>
                    <div class="grid-x grid-padding-x cell small-24" [hidden]="editMode">
                        <div class="cell small-4 text-center tariffElementEdit-sliderPrice">
                            0 {{'config.tariff.element.currencyUnitByHour'|translate:{currencyUnit: '€'} }}
                        </div>

                        <div class="cell auto text-center tariffElementEdit-sliderPrice border-let-0">
                            {{tariffElement.parkingTimePrice.shortDuration.price}}
                            {{'config.tariff.element.currencyUnitByHour'|translate:{currencyUnit: '€'} }}
                        </div>

                        <div class="cell small-6 text-center tariffElementEdit-sliderPrice border-let-0">
                            {{tariffElement.parkingTimePrice.longDuration.price}}
                            {{'config.tariff.element.currencyUnitByHour'|translate:{currencyUnit: '€'} }}
                        </div>
                    </div>
                    <div class="grid-x grid-padding-x cell small-24 tariffElementEdit-sliderInputGroup" [hidden]="!editMode">
                        <div class="input-group cell small-8 tariffElementEdit-sliderPrice grid-y">
                            <div class="grid-x cell shrink">
                                <input class="cell auto input-group-field" disabled min="0" type="number" value="0">
                                <span class="cell shrink input-group-label">{{'config.tariff.element.currencyUnitByHour'|translate:{currencyUnit: '€'} }}</span>
                            </div>
                            <p class="cell shrink input-group-infos">{{'config.tariff.element.until' | translate}}
                                {{tariffElement.parkingTimePrice.freeDuration.duration | duration: hourDurationFormat}} </p>
                        </div>

                        <div class="input-group cell small-8 tariffElementEdit-sliderPrice border-let-0 grid-y">
                            <div class="grid-x cell shrink">
                                <input class="input-group-field"
                                       [(ngModel)]="tariffElement.parkingTimePrice.shortDuration.price"
                                       [disabled]="!editMode"
                                       min="0" step="0.01" type="text"
                                       iziviaNumeric [decimals]="4"
                                       [required]="paidParking" name="reducePrice">
                                <span
                                    class="input-group-label">{{'config.tariff.element.currencyUnitByHour'|translate:{currencyUnit: '€'} }}</span>
                            </div>
                            <p class="cell shrink input-group-infos">{{'config.tariff.element.start' | translate}}
                                {{tariffElement.parkingTimePrice.freeDuration.duration | duration: hourDurationFormat }} {{'config.tariff.element.end' | translate}} {{tariffElement.parkingTimePrice.shortDuration.duration | duration: hourDurationFormat}}</p>
                        </div>

                        <div class="input-group cell small-8 tariffElementEdit-sliderPrice border-let-0 grid-y">
                            <div class="grid-x cell shrink">
                                <input class="input-group-field"
                                       [(ngModel)]="tariffElement.parkingTimePrice.longDuration.price"
                                       [disabled]="!editMode" min="0" step="0.01"
                                       type="text"
                                       iziviaNumeric [decimals]="4"
                                       [required]="paidParking"
                                       name="fullPrice">
                                <span
                                    class="input-group-label">{{'config.tariff.element.currencyUnitByHour'|translate:{currencyUnit: '€'} }}</span>
                            </div>
                            <p class="cell shrink input-group-infos">{{'config.tariff.element.hereafter' | translate}}
                                {{tariffElement.parkingTimePrice.shortDuration.duration | duration: hourDurationFormat }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #freeCost>
    <div class="grid-x align-middle grid-padding-x tariffElementEdit-datas">
        <span class="tariffElementEdit-datas-number">{{'config.tariff.element.free'|translate}}</span>
    </div>
</ng-template>
