import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AccountFormValue} from '../../components/informations/account-informations.component';
import {
    AdditionalInformationService,
    AuthenticateOptions,
    AuthenticationMethod,
    Credentials,
    FrontInvoiceHttpService,
    FrontRegisterHttpService,
    FrontSessionBusinessService,
    FrontUploadHttpService,
    InvoiceForm,
    InvoiceStatusEnum,
    PaymentParams,
    PaymentSource,
    PaymentType,
    RegisterTokenType,
    RegisterUserRequest
} from 'lib-front';
import {Router} from '@angular/router';
import {filter, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {forkJoin, noop, of, Subscription} from 'rxjs';
import {SubscriptionFormValue} from './subscription/register-subscription.component';
import {FleetFormValue} from './fleet/register-fleet.component';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    host: {'class': 'grid-x cell auto'}
})
export class RegisterComponent implements OnInit, OnDestroy {
    @ViewChild('scrollTop') private scrollTop;
    currentStep: number;

    accountFormValue: AccountFormValue;
    subscriptionsInformation: SubscriptionFormValue;
    fleetInformation: FleetFormValue;
    promoToken: string;
    paymentSelected: PaymentType;
    paymentConfigName: string;
    invoiceForm: InvoiceForm;
    isPaymentStepActivated: boolean = undefined;
    isAccountCreationProcessSecured: boolean = undefined;
    disablePaymentComponent = false;

    private subscriptionConnectedUser: Subscription;

    constructor(private registerHttpService: FrontRegisterHttpService,
        private invoiceHttpService: FrontInvoiceHttpService,
        private sessionBusinessService: FrontSessionBusinessService,
        private additionalInformationService: AdditionalInformationService,
        private router: Router,
        private readonly frontEndService: FrontEndService,
        private readonly uploadService: FrontUploadHttpService) {
    }

    ngOnInit() {
        this.currentStep = 1;
        // check if user is connected : filter undefined => subject has initial value and does not yet the http response
        this.subscriptionConnectedUser = this.sessionBusinessService.connectedUser$
            .pipe(
                filter(value => value !== undefined),
                take(1)
            ).subscribe(user => {
                if (user) {
                    this.router.navigate(['home']);
                }
            });

        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => {
                this.isPaymentStepActivated = fleetConfig.showPaymentSection || false;
                this.isAccountCreationProcessSecured = fleetConfig.accountCreationProcessSecured;
            });
    }

    goToNext() {
        if (this.currentStep >= 1) {
            this.currentStep++;
            this.scrollTop.nativeElement.scrollIntoView();
        }
    }

    goToBack() {
        if ((this.isPaymentStepActivated && this.currentStep <= 4)
            || (!this.isPaymentStepActivated && this.currentStep <= 3)) {
            this.currentStep--;
            this.scrollTop.nativeElement.scrollIntoView();
        }
    }

    onInformation(accountFormValue: AccountFormValue) {
        this.accountFormValue = accountFormValue;
        this.goToNext();
    }

    onSubscription(subscriptionsInformation: SubscriptionFormValue) {
        this.subscriptionsInformation = subscriptionsInformation;
        this.goToNext();
    }

    onFleet(fleetInformation: FleetFormValue) {
        this.fleetInformation = fleetInformation;

        if (this.isPaymentStepActivated && !this.isAccountCreationProcessSecured) {
            this.goToNext();
        } else {
            this.createAccount();
        }
    }

    onPromoToken(promoToken: string) {
        this.promoToken = promoToken;
    }

    onPayment(paymentSelected?: PaymentType) {
        this.paymentSelected = paymentSelected;
        this.createAccount();
    }

    onPaymentConfig(configName?: string) {
        this.paymentConfigName = configName;
    }

    private createAccount() {
        // step 5 is successfull
        this.disablePaymentComponent = true;
        const subscriptionPackRefs = this.subscriptionsInformation.subscriptionPackOrTokenWrapper.isPackTokenMode() ?
            [] : this.subscriptionsInformation.subscriptionPackOrTokenWrapper.subscriptionPacks
                .map(subscription => subscription._id);

        const registerUserRequest: RegisterUserRequest = {
            ...this.accountFormValue.user,
            '@type': 'fleet',
            login: this.accountFormValue.user.email,
            passwordHash: this.accountFormValue.password as string,
            offerRef: this.subscriptionsInformation.offer._id,
            subscriptionPackRefs: subscriptionPackRefs,
            subscriptionTokens: [],
            paymentSource: PaymentSource.FLEET_REGISTRATION,
            foAccount: this.accountFormValue.foAccount,
            optIn: this.subscriptionsInformation.optIn
        };

        if (this.subscriptionsInformation.subscriptionPackOrTokenWrapper.tokenValue) {
            registerUserRequest.subscriptionTokens.push({
                type: RegisterTokenType.PACK_TOKEN,
                token: this.subscriptionsInformation.subscriptionPackOrTokenWrapper.tokenValue
            });
        }

        if (this.promoToken) {
            registerUserRequest.subscriptionTokens.push({
                type: RegisterTokenType.PROMO_SUBSCRIPTION,
                token: this.promoToken
            });
        }

        if (this.fleetInformation
            && this.fleetInformation.mediaOrder
            && this.fleetInformation.mediaOrder.isOrderable()) {
            registerUserRequest.mediaOrders = [
                {
                    quantity: this.fleetInformation.mediaOrder.quantity,
                    mediaFamilyRef: this.fleetInformation.mediaOrder.mediaCanOrderDto.mediaFamily.id,
                    subscriptionPackRef: this.subscriptionsInformation.subscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder._id
                }];
        }

        if (this.subscriptionsInformation.options && this.subscriptionsInformation.options.length) {
            registerUserRequest.subscriptionOptions = this.subscriptionsInformation.options.map(option => {
                return {
                    parent: option.subscription.subscriptionDefParentRef,
                    option: option.subscription._id,
                    quantity: option.number
                };
            });
        }


        this.additionalInformationService.handleAdditionalInformationFiles(
            this.subscriptionsInformation.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations,
            this.subscriptionsInformation.additionalInformationAnswers
        ).pipe(
            tap(filteredAdditionalInformation => registerUserRequest['additionalInformations'] = filteredAdditionalInformation),
            switchMap(() => {
                if (this.isAccountCreationProcessSecured) {
                    let promises = {};
                    registerUserRequest.foAccount.supportingDocuments
                        .map(doc => promises[doc.type.valueOf()] = this.uploadService.upload(doc.file, doc.file.name));
                    return forkJoin(promises);
                } else {
                    return of(noop());
                }
            }),
            map((files: void | Object) => {
                if (files) {
                    Object.keys(files).forEach((key) => {
                        let document = registerUserRequest.foAccount.supportingDocuments.find((doc) => doc.type.valueOf() == key);
                        document.fileRef = files[key].fileId;
                        document.filename = files[key].filename;
                    });
                }
            }),
            switchMap(() => this.registerHttpService.createUser(registerUserRequest)),
            map(invoices => invoices && invoices.length && invoices[0].currentStatus !== InvoiceStatusEnum.PAID ? invoices[0] : null),
            finalize(() => this.disablePaymentComponent = false)
        )
            .subscribe(invoice => {
                if (this.isAccountCreationProcessSecured) {
                    this.goToNext();
                } else {
                    const credentials: Credentials = {
                        login: this.accountFormValue.user.email,
                        password: this.accountFormValue.password as string,
                        ignoreTwinningRelation: true,
                        rememberMe: false
                    };
                    const authenticateOptions: AuthenticateOptions = {
                        shouldHashPassword: false,
                        preferredAuthenticationMethod: AuthenticationMethod.COOKIE
                    };
                    this.sessionBusinessService.authenticate(credentials, authenticateOptions).subscribe(connectedUser => {
                        if (invoice && invoice.netAmount > 0 && this.isPaymentStepActivated && connectedUser && connectedUser.length === 1) {
                            if (this.paymentSelected === 'SEPA') {
                                this.invoiceHttpService.setPaymentType(connectedUser[0].user._id, this.paymentSelected,
                                    invoice.providerRef.split('/')[1] || invoice.providerRef,
                                    this.paymentConfigName, null, PaymentSource.FLEET_REGISTRATION)
                                    .subscribe(invoiceForm => {
                                        this.invoiceForm = invoiceForm;
                                    });
                            } else {
                                const paymentParams: PaymentParams = {
                                    paymentType: this.paymentSelected,
                                    paymentConfigName: this.paymentConfigName
                                };

                                this.invoiceHttpService.pay(invoice._id, paymentParams, PaymentSource.FLEET_REGISTRATION)
                                    .subscribe(invoiceForm => {
                                        this.invoiceForm = invoiceForm;
                                    });
                            }
                        } else {
                            this.router.navigate(['/register/success']);
                        }
                    });
                }
            });
    }

    public ngOnDestroy(): void {
        this.subscriptionConnectedUser.unsubscribe();
    }
}
