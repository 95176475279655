import {filter, flatten, groupBy, map, mapValues, values} from 'lodash-es';

/**
 * Add here the lodash functions you want to support.
 * <br>
 * Beware! Only add functions that will be effectively used to limit the final bundle size.
 * @see https://github.com/lodash/lodash/issues/3298
 */
const chainableFunctions = {
    map,
    flatten,
    filter,
    groupBy,
    values
};

/**
 * Creates a lodash wrapper instance that wraps value with explicit method chain sequences enabled.
 * The result of such sequences <strong>must</strong> be unwrapped by calling `value()`.
 * <br>
 * The chain method (and the sibling method `_(value)`) have been removed from `lodash-es` as they prevent from tree-shaking.
 * Therefore, we have introduced our own chain method for backward compatibility with existing code
 * but with a limited set of {@link chainableFunctions} to limit the final bundle size.
 *
 * @param input The value to wrap
 * @see https://lodash.com/docs/4.17.15#chain
 * @since 44
 */
export const chain = (input) => {
    let value: any = input;
    const wrapper = {
        ...mapValues(
            chainableFunctions,
            (f: any) => (...args: any[]) => {
                // lodash always puts input as the first argument
                value = f(value, ...args);
                return wrapper;
            },
        ),
        value: () => value,
    };
    return wrapper;
};
