<div class="grid-y cell auto force-space-bottom-0 has-space">
    <form class="grid-x cell shrink align-justify align-bottom grid-padding-x space-bottom-8 filter-bloc">
        <label class="cell small-24 xlarge-8 xxlarge-10 search-label with-label force-inner-space-left-0"
               [class.validate]="criteria.query">
            <span>{{'chargeConsumptions.search.label' | translate}}</span>
            <input type="text" [placeholder]="'chargeConsumptions.criteria.q' | translate"
                   [(ngModel)]="criteria.query"
                   name="q" class="search">
            <i class="fas fa-search search-icon"></i>
        </label>

        <label class="cell auto"
               [class.validate]="criteria.from">
            <span>{{'chargeConsumptions.criteria.startDate' | translate}}</span>
            <date-picker class="full-input" [(ngModel)]="criteria.from" name="fromDate"></date-picker>
        </label>

        <label class="cell auto"
               [class.validate]="criteria.to">
            <span>{{'chargeConsumptions.criteria.endDate' | translate}}</span>
            <date-picker class="full-input" [(ngModel)]="criteria.to" name="toDate"></date-picker>
        </label>

        <label class="cell auto"
               [class.validate]="status">
            <span>{{'chargeConsumptions.criteria.status' | translate}}</span>
            <select [ngModel]="status"
                    (ngModelChange)="changeStatus($event)"
                    name="status">
                <option [ngValue]="null">{{'chargeRecordDtoStatus.all' | translate}}</option>
                <option [value]="chargeStatus" *ngFor="let chargeStatus of chargeStatus">
                    {{'chargeRecordDtoStatus.' + chargeStatus | translate}}
                </option>
            </select>
        </label>

        <button type="submit" class="cell small-24 medium-shrink button primary responsiveButton" (click)="search(criteria)"
                [iziviaLoading]="!searchChargeSubscription?.closed || !searchStatsSubscription?.closed">
            {{'chargeConsumptions.search.button' | translate}}
        </button>
    </form>

    <div class="grid-x cell shrink align-center indicators-bloc space-bottom-8"
         [ngClass]="fleetConfig?.reportingEnabled && fleetConfig?.reportingList?.includes(Reporting.CHARGES_EVOLUTION) ? 'small-up-5' : 'small-up-4'">
        <indicator class="cell color" [img]="'charge'">
            <ng-container *ngIf="searchStatsSubscription?.closed else spinnerStats"
                          value>{{statsResult?.nbCharge || 0 | number}}</ng-container>
            <ng-container *ngIf="searchStatsSubscription?.closed" label>{{(statsResult && statsResult?.nbCharge > 1
                ? 'chargeConsumptions.charges'
                : 'chargeConsumptions.charge') | translate}}
            </ng-container>
        </indicator>

        <indicator class="cell color" [img]="'time'">
            <ng-container *ngIf="searchStatsSubscription?.closed else spinnerStats" value>{{statsResult?.durationInSecond |
                fleetDuration:{seconds : false, minutes : true} }}
            </ng-container>
        </indicator>

        <indicator class="cell color" [img]="'price'">
            <span *ngIf="searchStatsSubscription?.closed" value>
                <i *ngIf="mode === 'FLEET'" class="fas fa-arrow-left icon-margin" ></i>
                <i *ngIf="mode !== 'FLEET'" class="fas fa-arrow-right icon-margin" ></i>
            </span>
            <ng-container *ngIf="searchStatsSubscription?.closed else spinnerStats" value>
                {{(mode === "FLEET" ? statsResult?.customerAmountWithVat|| 0 : statsResult?.emoAmountWithVat|| 0) | currency:'EUR' }}
            </ng-container>
            <ng-container *ngIf="searchStatsSubscription?.closed" label>{{'TTC' | translate}}</ng-container>

        </indicator>

        <indicator class="cell color" [img]="'energy'">
            <ng-container *ngIf="searchStatsSubscription?.closed else spinnerStats" value>{{statsResult?.energyConsumedInWh / 1000 || 0 |
                number}}
            </ng-container>
            <ng-container *ngIf="searchStatsSubscription?.closed" label>{{'kWh' | translate}}</ng-container>
        </indicator>

        <a *ngIf="fleetConfig?.reportingEnabled && fleetConfig?.reportingList?.includes(Reporting.CHARGES_EVOLUTION)"
           [routerLink]="'/main/reporting/chargesEvolution'"
           class="cell button secondary"
           type="button">
            {{'common.more' | translate}}
        </a>
    </div>

    <div class="grid-x cell shrink align-middle grouping-bloc space-bottom-8">
        <span class="cell shrink grouping-title">{{'chargeConsumptions.grouping.title' | translate}}</span>

        <div class="grid-x cell auto align-center small-align-left">
            <div class="cell shrink">
                <label class="radio">
                    <span class="label-choice">  {{'chargeConsumptions.grouping.status' | translate}}</span>
                    <input type="radio"
                           [ngModel]="criteria.sort"
                           [name]="mode"
                           [value]="getStatusSort()"
                           (ngModelChange)="onGroupingChange($event)"
                           [disabled]="!searchChargeSubscription?.closed">
                    <span class="custom-radio"></span>
                </label>
            </div>

            <div class="cell shrink">
                <label class="radio">
                    <span class="label-choice">{{'chargeConsumptions.grouping.place' | translate}}</span>
                    <input type="radio"
                           [ngModel]="criteria.sort"
                           [name]="mode"
                           [value]="ChargeRecordSortingFieldEnum.CHARGE_AREA"
                           (ngModelChange)="onGroupingChange($event)"
                           [disabled]="!searchChargeSubscription?.closed">
                    <span class="custom-radio"></span>
                </label>
            </div>

            <div class="cell shrink">
                <label class="radio">
                    <span class="label-choice"> {{'chargeConsumptions.grouping.media' | translate}}</span>
                    <input type="radio" [ngModel]="criteria.sort" [name]="mode"
                           [value]="ChargeRecordSortingFieldEnum.MEDIA"
                           (ngModelChange)="onGroupingChange($event)"
                           [disabled]="!searchChargeSubscription?.closed">
                    <span class="custom-radio"></span>
                </label>
            </div>

            <div class="cell shrink">
                <label class="radio">
                    <span class="label-choice">{{'chargeConsumptions.grouping.person' | translate}}</span>
                    <input type="radio" [ngModel]="criteria.sort" [name]="mode"
                           [value]="ChargeRecordSortingFieldEnum.MEDIA_OWNERS"
                           (ngModelChange)="onGroupingChange($event)"
                           [disabled]="!searchChargeSubscription?.closed">
                    <span class="custom-radio"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="grid-y cell shrink">
        <div class="grid-x cell shrink align-justify align-middle space-bottom-8 paginationBlock"
             *ngIf="searchStatsSubscription?.closed &&
         chargeRecords?.length > 0; let chargeRecords">
            <div class="pagination">
                <button class="pagination-button" (click)="search(criteria, page - 1, true)"
                        [disabled]="page - 1 <= 0 || !searchStatsSubscription?.closed || !searchChargeSubscription?.closed">
                    <i class="fas fa-angle-left"></i>
                </button>
                <span class="pagination-label">{{page}}&nbsp;/&nbsp;{{maxPage}}</span>
                <button class="pagination-button" (click)="search(criteria, page + 1, true)"
                        [disabled]="page + 1 > maxPage || !searchStatsSubscription?.closed || !searchChargeSubscription?.closed">
                    <i class="fas fa-angle-right"></i>
                </button>
            </div>
            <div>
                <a class="button secondary color" (click)="export(criteria)">{{'chargeConsumptions.export' |
                    translate}}</a>
            </div>
        </div>
        <div class="cell small-2 medium-4 large-24 cell-block">
            <table>
                <thead>
                <tr>
                    <th>{{'chargeConsumptions.status' | translate}}</th>
                    <th>{{'chargeConsumptions.station' | translate}}</th>
                    <th>{{'chargeConsumptions.chargePoint' | translate}}</th>
                    <th *ngIf="frontEndConfig.consumptionDisplayMode === consumptionDisplayMode.CLASSIC">
                        {{'chargeConsumptions.person' | translate}}
                    </th>
                    <th *ngIf="frontEndConfig.consumptionDisplayMode === consumptionDisplayMode.CLASSIC">
                        {{'chargeConsumptions.media' | translate}}
                    </th>
                    <th *ngIf="frontEndConfig.consumptionDisplayMode === consumptionDisplayMode.COPRO">
                        {{'chargeConsumptions.user' | translate}}
                    </th>
                    <th>{{'chargeConsumptions.detail.title' | translate}}</th>
                    <th>{{'chargeConsumptions.vehicle' | translate}}</th>
                    <th>{{'chargeConsumptions.startDate' | translate}}</th>
                    <th>{{'chargeConsumptions.endDate' | translate}}</th>
                    <th>{{'chargeConsumptions.duration' | translate}}</th>
                    <th>{{'chargeConsumptions.energy' | translate}}</th>
                    <th>{{'chargeConsumptions.price' | translate}}</th>
                    <th *ngIf="mode === 'STATION'">{{'chargeConsumptions.amount' | translate}}*</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody *ngIf="searchChargeSubscription?.closed && chargeRecords?.length > 0">
                <ng-container *ngIf="chargeRecords && chargeRecordsGroupedBy">
                    <ng-container *ngFor="let chargeRecordStatus of chargeRecordGroupedByValues; last as last">
                        <ng-container *ngFor="let chargeRecord of chargeRecordsGroupedBy[chargeRecordStatus]">
                            <tr [class.selected]="chargeRecordSelectedId === chargeRecord.id">
                                <td>
                                    <span class="status status-color-{{chargeRecord.chargeRecordDtoStatus}}"
                                          [ngClass]="{'has-link' : chargeRecord.chargeRecordDtoStatus === 'INVOICED'}">
                                        {{'chargeRecordDtoStatus.' + chargeRecord.chargeRecordDtoStatus | translate}}
                                        <a class="status-link"
                                           [hidden]="mode === 'STATION'||
                                           chargeRecord.chargeRecordDtoStatus !=='INVOICED' ||
                                                       !chargeRecordInvoicePdfUrls[chargeRecord.id] ||
                                                       !fleetConfig?.showInvoiceSection"
                                           [href]="chargeRecordInvoicePdfUrls[chargeRecord.id]"
                                           target="_blank"><i class="fa fa-link"></i></a>
                                    </span>
                                </td>
                                <td>
                                    {{chargeRecord.infraChargeDto.chargeArea?.name}} -
                                    {{'stations.visualId'|translate:{visualId: chargeRecord.infraChargeDto.station?.name} }}
                                </td>
                                <td>
                                    {{chargeRecord.infraChargeDto.chargePoint?.name}}
                                </td>
                                <td *ngIf="frontEndConfig.consumptionDisplayMode === consumptionDisplayMode.CLASSIC">
                                    <span *ngFor="let owner of chargeRecord.startingMediumOwners, first as first"><span *ngIf="!first"> / </span>{{owner.name}}</span>
                                </td>
                                <td *ngIf="frontEndConfig.consumptionDisplayMode === consumptionDisplayMode.CLASSIC">
                                    <ng-container *ngIf="chargeRecord.paynowCharge; else startingMediumPublicCodeTmpl">
                                        {{'chargeConsumptions.paynow' | translate}}
                                    </ng-container>
                                    <ng-template #startingMediumPublicCodeTmpl>
                                        {{chargeRecord.startingMediumPublicCode}}
                                    </ng-template>

                                </td>
                                <td *ngIf="frontEndConfig.consumptionDisplayMode === consumptionDisplayMode.COPRO">
                                    <span *ngFor="let owner of chargeRecord.startingMediumOwners, first as first"><span *ngIf="!first"> / </span>{{owner.name}}</span>
                                </td>
                                <td>{{chargeRecord.startingMediumAlias}}</td>
                                <td><span *ngFor="let vehicle of chargeRecord.startingMediumVehicles, first as first; trackBy: trackByVehicleId"><span *ngIf="!first"> / </span>{{vehicle.vehicleLicenseNumber}}</span></td>
                                <td>{{chargeRecord.startDate | date: 'short'}}</td>
                                <td>{{(chargeRecord.stopDate || undefined) | date: 'short'}}</td>
                                <td>
                                    {{chargeRecord.duration | fleetDuration}}
                                </td>
                                <td>{{(chargeRecord.energyConsumedInWh / 1000 || 0) | number:'1.0-2'}}</td>
                                <td>
                                    <ng-container *ngIf="!!chargeRecord.customerPriceWithVat || chargeRecord.customerPriceWithVat === 0">
                                        {{chargeRecord.customerPriceWithVat | currency:'EUR': chargeRecord.currency || ''}}
                                    </ng-container>
                                </td>
                                <td *ngIf="mode === 'STATION'">
                                    <ng-container *ngIf="!!chargeRecord.emoPriceWithVat || chargeRecord.emoPriceWithVat === 0">
                                        {{chargeRecord.emoPriceWithVat | currency:'EUR': chargeRecord.currency || ''}}
                                    </ng-container>
                                </td>
                                <td>
                                    <button *ngIf="chargeRecordSelectedId !== chargeRecord.id" class="button round eye" (click)="selectChargeDetails(chargeRecord)">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button *ngIf="chargeRecordSelectedId === chargeRecord.id" class="button round eye"
                                            (click)="selectChargeDetails(chargeRecord)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr class="retractable retracted" [class.retracted]="chargeRecordSelectedId !== chargeRecord.id">
                                <td colspan="12">
                                    <charge-record-details
                                        class="grid-y cell auto main-container force-space-bottom-0"
                                        [chargeRecord]="chargeRecord">
                                    </charge-record-details>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="!last" class="separator">
                            <td colspan="11">
                                <hr>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
        </div>
        <div class="grid-x cell shrink align-justify align-middle space-bottom-8 paginationBlock"
             *ngIf="searchStatsSubscription?.closed && chargeRecords?.length > 0">
            <div class="pagination">
                <button class="pagination-button" (click)="search(criteria, page - 1, true)"
                        [iziviaLoading]="!searchChargeSubscription?.closed"
                        [disabled]="page - 1 <= 0 || !searchStatsSubscription?.closed || !searchChargeSubscription?.closed">
                    <i class="fas fa-angle-left"></i>
                </button>
                <span class="pagination-label">{{page}}&nbsp;/&nbsp;{{maxPage}}</span>
                <button class="pagination-button" (click)="search(criteria, page + 1, true)"
                        [iziviaLoading]="!searchChargeSubscription?.closed"
                        [disabled]="page + 1 > maxPage || !searchStatsSubscription?.closed || !searchChargeSubscription?.closed">
                    <i class="fas fa-angle-right"></i>
                </button>
            </div>
        </div>

        <div *ngIf="mode === 'STATION'">* {{'chargeConsumptions.amountExplanation' | translate}}</div>
    </div>

    <div class="grid-y cell auto align-center align-middle message-empty"
         *ngIf="searchChargeSubscription?.closed && (!chargeRecords || chargeRecords.length === 0)">
        <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
        <span class="message-empty-label">{{'chargeConsumptions.no.result' | translate}}</span>
    </div>

    <div *ngIf="!searchChargeSubscription?.closed">
        <div class="app-loader small text-center">
            <span class="spinner small"></span>
        </div>
    </div>

    <ng-template #spinnerStats>
        <div class="app-loader small text-center spinner-margin">
            <span class="spinner small"></span>
        </div>
    </ng-template>
</div>
