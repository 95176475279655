<div class="faq-ariane-container"
     *ngIf="faqNode">
    <div class="faq-ariane-label-container">
         <span class="faq-ariane label space-left-16"
               *ngIf="faqNode?.ancestors?.length > 0"
               (click)="loadFaqNode(null)">{{'config.faq.home' | translate}}
        </span>
    </div>

    <div class="faq-ariane-label-container"
         *ngFor="let ancestor of faqNode.ancestors.slice(1)">
        <i class="fas fa-chevron-right faq-ariane-next"></i>
        <div class="faq-ariane label"
             (click)="loadFaqNode(ancestor.id)">
            {{ancestor.title}}
        </div>
    </div>

    <div class="faq-ariane-label-container"
         *ngIf="faqNode.ancestors.length > 0">
        <i class="fas fa-chevron-right faq-ariane-next"></i>
        <div class="faq-ariane active">
            {{faqNode.title}}
        </div>
    </div>
</div>
