<div class="iziviaPopup"
     role="dialog">
    <div class="iziviaPopup-header">
        <i class="fas fa-exclamation iziviaPopup-header_icon"></i>
    </div>
    <div class="iziviaPopup-content">
        <div>
            <p>{{ 'expenseReport.popup.refuse' | translate:{expenseReportNumberMessage: expenseReportNumberMessage} }}</p>
        </div>

        <textarea class="iziviaPopup-content_textarea"
                  [(ngModel)]="refuseReason"
                  maxlength="101"
                  placeholder="{{'expenseReport.popup.reasonPlaceHolder' | translate}}">
        </textarea>
        <div class="iziviaPopup-content_message"
             *ngIf="refuseReasonMissing">{{ 'expenseReport.popup.reasonIsMandatory' | translate }}
        </div>
    </div>

    <div class="iziviaPopup-buttons">
        <button class="button color withMargin"
                type="button"
                (click)="rejectGroup()">
            {{ 'expenseReport.popup.button.refuse' | translate}}
        </button>
        <button class="button secondary withMargin"
                type="button"
                (click)="closeModal()">
            {{ 'common.cancel' | translate }}
        </button>
    </div>
</div>
