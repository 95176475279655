<div class="grid-x indicator-container infraStatus space-bottom-8 station"
     [ngClass]="'infraStatus-'+station.infraStatus">
    <div class="station-container">
        <div class="grid-x">
            <div class="grid-x cell shrink inner-space-right-16 station-subContainer">
                <h5 class="cell shrink station-title">
                    <ng-container *ngIf="!!station?.visualId; else undefinedName">
                        {{'stations.visualId' | translate : {visualId: station.visualId} }}
                    </ng-container>
                    <ng-template #undefinedName> {{'stations.noVisualId' | translate }}</ng-template>
                </h5>
                <h6 class="cell shrink station-title_alias">
                    <ng-container *ngIf="!!station?.alias; else undefinedAlias">
                        ( {{'stations.alias' | translate : {alias: station.alias} }} )
                    </ng-container>
                    <ng-template #undefinedAlias> ( {{'stations.noAlias' | translate }} )</ng-template>
                </h6>
            </div>
            <div class=" cell shrink text-right">
                <button type="button" class="button round station-settings eye"
                        (click)="editStation()">
                    <i class="fas fa-cog"></i>
                </button>
            </div>
        </div>
        <div class="border-none infraStatus" [ngClass]="'infraStatus-'+station?.infraStatus">
            <div class="cell station-status" [ngClass]="'background-'+ station?.infraStatus">
                <i class="fas fa-circle station-status-color"
                   [ngClass]="'color-'+ station?.infraStatus"></i>
                {{'stations.status.' + station?.infraStatus | translate}}
            </div>
        </div>
        <div class="grid-x cell station-chargePoint text-center"
             *ngFor="let chargePoint of station.chargePoints; trackBy: trackByChargePointId">
            <h5 class="cell shrink station-subTitle">
                <ng-container *ngIf="!!chargePoint?.visualId; else undefinedName">
                    <i class="fas fa-circle station-subTitle-status" [ngClass]="'color-'+ chargePoint.infraStatus"></i>
                    <span>{{'stations.chargePoint.visualId' | translate : {visualId: chargePoint.visualId} }}</span>
                </ng-container>
                <ng-template #undefinedName> {{'stations.chargePoint.noVisualId' | translate }}</ng-template>
            </h5>
            <h5 *ngIf="!!chargePoint?.licenseNumber" class="cell shrink station-subTitle">
                <ng-container>
                    <span class="station-vehicle">
                        {{'stations.chargePoint.vehicle' | translate : {licenseNumber: chargePoint.licenseNumber} }}
                    </span>
                </ng-container>
            </h5>

        </div>


        <collapsible-card-detail #collapsibleCardDetailComponent (collapse)="loadStationDetails()">
            <ng-container collapsible *ngIf="!waitingChargePoints&& ! waitingStationDetails; else spinner">
                <div class="grid-x space-bottom-8 stationTariff" *ngIf="stationTariffs">
                    <span class="stationTariff-title">{{'stations.tariff' | translate}}</span>
                    <span class="stationTariff-name" *ngFor="let tariff of stationTariffs; trackBy: trackByTariffId">
                        {{tariff.name}}
                    </span>
                </div>

                <div class="grid-x small-up-2 large-up-1 space-bottom-8 space-top-8 grid-margin-x">
                    <div class="cell chargePoint-wrapper" *ngFor="let chargePoint of chargePoints; trackBy: trackByChargePointId">
                        <div class="grid-y cell auto  grid-padding-x grid-padding-y chargePoint-container space-bottom-8 space-top-8 no-padding-bottom">
                            <div class="cell station-detailSubTitle">
                                <ng-container *ngIf="!!chargePoint?.visualId; else undefinedName">
                                    <i class="fas fa-circle station-detailSubTitle-status" [ngClass]="'color-'+ chargePoint.infraStatus"></i>
                                    {{'stations.chargePoint.visualId' | translate : {visualId: chargePoint.visualId} }}
                                </ng-container>
                                <ng-template
                                    #undefinedName> {{'stations.chargePoint.noVisualId' | translate }}</ng-template>
                            </div>
                            <div class="grid-x small-up-2">
                                <div class="cell" *ngFor="let plug of chargePoint.plugs; trackBy: trackByPlugId">
                                    <div class="cell grid-y plugType-content space-bottom-8 space-top-8"
                                         (click)="selectPlug(chargePoint, plug)"
                                         [class.selected]="isSelected(plug)">
                                        <div class="cell plugType-content-style">
                                            <img [src]="'/api/download/' + plug?.plugTypeDto?.iconRef" [alt]="''"
                                                 *ngIf="plug?.plugTypeDto?.iconRef"/>
                                        </div>
                                        <div class="cell">
                                            <span class="">{{'plugType.' + plug.plugTypeDto.name | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <plug-remote-action [chargePoint]="selectedChargePoint"
                                    [plug]="selectedPlug"
                                    [hasStationWriteRole]="hasChargeAreasWriteRole">
                </plug-remote-action>
            </ng-container>
        </collapsible-card-detail>
    </div>
</div>

<ng-template #spinner>
    <div class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>
</ng-template>

