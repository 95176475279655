import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {VehicleVersionDto} from 'lib-front';
import {Observable, of} from 'rxjs';

@Pipe({
    name: 'fleetVehicleVersionFormatter'
})
export class FleetVehicleVersionFormatterPipe implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {
    }

    transform(vehicleVersion: VehicleVersionDto): string {
        if (vehicleVersion.version === '') {
            return this.translateService.instant('vehicle.version.empty');
        }
        return vehicleVersion.version;
    }
}

