import {Component, Input} from '@angular/core';
import {StationDto, TariffDto} from 'lib-front';
import {OthrTariffDetailDto} from 'lib-front/lib/domain/tariff/othrTariffDetailDto';

@Component({
    selector: 'station-tariff-card',
    templateUrl: './station-tariff-card.component.html',
    styleUrls: ['./station-tariff-card.component.scss']
})
export class StationTariffCardComponent {
    @Input() station: StationDto;
    @Input() tariffs: TariffDto[];
    @Input() selected: boolean;
    @Input() othrTariff: OthrTariffDetailDto;

    constructor() {
    }

    trackByTariffId(index: number, tariff: TariffDto) {
        return tariff?.id ?? index;
    }
}
