import {Directive, ElementRef, OnInit} from '@angular/core';

declare const Prism: any;

@Directive({
    selector: '[iziviaPrism]'
})
export class PrismSyntaxHighlighterDirective implements OnInit {

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        const html = this.elementRef.nativeElement.innerHTML.replace(/_ngcontent-c[0-9]*=""/g, '');
        this.elementRef.nativeElement.innerHTML = Prism.highlight(html, Prism.languages.html);
    }
}
