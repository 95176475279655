<div class="grid-x cell shrink align-middle primary-nav-container">
    <nav class="grid-x cell shrink primary-nav">
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           [routerLink]="isReportingView ? '/main/reporting' : '/home'"
           routerLinkActive="selected"
           [ngClass]="{disabled : !isFirstBillPayed}"
            *ngIf="isReportingView != undefined">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.home' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="!isStationManager && fleetConfig?.showInvoiceSection && hasInvoiceReadRole"
           [routerLink]="'/main/invoices'" routerLinkActive="selected">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.invoices' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="isStationManager && hasInvoiceReadRole"
           [routerLink]="'/main/finances'" routerLinkActive="selected">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.finances' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="hasChargesReadRole"
           [routerLink]="'/main/chargeConsumptions'" routerLinkActive="selected"
           [ngClass]="{disabled : !isFirstBillPayed}">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.consumptions' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="hasSubscriptionsReadRole"
           [routerLink]="'/main/subscriptions'" routerLinkActive="selected" [ngClass]="{disabled : !isFirstBillPayed}">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.subscriptions' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="hasPassReadRole"
           [routerLink]="'/main/media'" routerLinkActive="selected" [ngClass]="{disabled : !isFirstBillPayed}">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.media' | translate}}
            </span>
        </a>
        <a *ngIf="isRefundManager && hasExpenseReportReadRole"
           class="grid-y cell shrink align-middle align-center primary-nav-item"
           [routerLink]="'/main/expenseReport'" routerLinkActive="selected" [ngClass]="{disabled : !isFirstBillPayed}">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.expenseReport' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="isStationManager && hasChargeAreasReadRole"
           [routerLink]="'/main/chargeAreas'" routerLinkActive="selected" [ngClass]="{disabled : !isFirstBillPayed}">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.chargeAreas' | translate}}
            </span>
        </a>
        <a class="grid-y cell shrink align-middle align-center primary-nav-item"
           *ngIf="hasConfigurationTabReadRole"
           [routerLink]="'/main/configuration'"
           routerLinkActive="selected"
           [ngClass]="{disabled : !isFirstBillPayed}">
            <span class="cell shrink primary-nav-item-text">
                {{'nav.config' | translate}}
            </span>
        </a>
    </nav>
</div>

<payment-conf-reminders></payment-conf-reminders>
<div class="callout warning">
    <i class="fas fa-exclamation-circle callout-icon"></i>
    <p>{{'home.screenTooSmall' | translate}}</p>
</div>

<router-outlet></router-outlet>
