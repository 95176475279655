import {Component, Input, OnInit} from '@angular/core';
import {
    TariffElementDto,
    ParkingTimePriceDto,
    DurationPipeParam,
    FrontEndFleetConfig
} from 'lib-front';
import {LabelType, Options} from '@angular-slider/ngx-slider';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {FleetDurationPipe} from '../../pipes/fleetDuration.pipe';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'tariff-element-edit',
    templateUrl: './tariff-element-edit.component.html',
    styleUrls: ['./tariff-element-edit.component.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}]
})
export class TariffElementEditComponent implements OnInit {
    @Input()
    get tariffElement(): TariffElementDto {
        return this._tariffElement;
    }

    set tariffElement(value: TariffElementDto) {
        this._tariffElement = value;
        if (!!this._tariffElement) {
            this.paidEnergy = this._tariffElement?.energyPrice > 0;
            this.paidParking = this._tariffElement?.parkingTimePrice?.shortDuration?.price > 0 ||
                this._tariffElement?.parkingTimePrice?.longDuration?.price > 0;
        }
    }
    public fleetConfig: FrontEndFleetConfig;

    private _tariffElement: TariffElementDto;
    parkingPrice: ParkingTimePriceDto = new ParkingTimePriceDto();
    @Input() editMode: boolean;
    paidEnergy: boolean;
    paidParking: boolean;

    energyPrice: number;

    sliderOptions: Options;

    hourDurationFormat: DurationPipeParam = {
        report: false,
        minutes: true,
        seconds: false,
        entryUnit: 'h'
    };

    constructor(private fleetDurationPipe: FleetDurationPipe,
        private readonly frontEndService: FrontEndService) {
    }

    ngOnInit(): void {
        this.sliderOptions = {
            floor: 0,
            ceil: 24,
            step: 0.25,
            hideLimitLabels: true,
            disabled: !this.editMode,
            translate: (value: number, label: LabelType): string => {
                return this.fleetDurationPipe.transform(value, this.hourDurationFormat);
            }
        };
        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);
    }

    freeEnergyPriceChange() {
        if (this.paidEnergy) {
            this._tariffElement.energyPrice = this.energyPrice;
        } else {
            this.energyPrice = this._tariffElement.energyPrice;
            this._tariffElement.energyPrice = 0;
        }
    }

    freeParkingPriceChange() {
        if (this.paidParking) {
            this._tariffElement.parkingTimePrice = this.parkingPrice;
        } else {
            this.parkingPrice = this._tariffElement.parkingTimePrice;
            this._tariffElement.parkingTimePrice = null;
        }
    }
}
