import {
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    UntypedFormBuilder
} from '@angular/forms';
import {
    ACCEPTED_FILE_FORMATS,
    FleetImportRequestReportProcessing,
    FrontEndFleetConfig, FrontMediaHttpService,
    FrontPersonPortalContextService,
    FrontVehicleHttpService,
    FrontVehicleVersionHttpService,
    MediaPublicCodeDto,
    Reporting,
    Vehicle,
    VehicleAcquisitionType,
    VehicleMakeDto,
    VehicleModelDto,
    VehicleSearchCriteria,
    VehicleUsageType,
    VehicleVersionDto
} from 'lib-front';
import {debounceTime, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../services/utils/notification.service';
import {reduceFormGroupErrors} from '../../utils/formGroupUtils.service';
import {noop, Observable, of, Subject, Subscription} from 'rxjs';
import {FleetVehicleUtils} from '../fleetVehicleUtils';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';
import {FrontEndService} from '../../services/frontEnd.service';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'fleet-list-form',
    templateUrl: './fleet-list-form.component.html',
    styleUrls: ['./fleet-list-form.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FleetListFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FleetListFormComponent),
            multi: true
        }
    ]
})
export class FleetListFormComponent extends AbstractHasRoleActionComponent implements OnInit, Validator, OnDestroy {
    @Input() searching: boolean;
    @Input() nbrVehicles: number;
    @Input() hasFleetWriteRole: boolean;
    @Input() public pageNumber: number;
    @Input() public nbrPageMax: number;
    @Input() public displayLimitMessage: boolean;
    @Input() public vehicles: Vehicle[];

    @Output() readonly searchChange: EventEmitter<VehicleSearchCriteria> = new EventEmitter();
    @Output() readonly nbrVehiclesChange: EventEmitter<number> = new EventEmitter();
    @Output() readonly pageChange: EventEmitter<number> = new EventEmitter<number>();

    isInsertOpened: boolean = false;
    criteria: VehicleSearchCriteria;
    vehicleDataLoaded: boolean = false;
    VehicleUsageType = VehicleUsageType;
    VehicleAcquisitionType = VehicleAcquisitionType;
    licenseNumbers = new Set();
    vehicleIdentifierNumbers = new Set();
    isRigeUser: boolean;

    form: UntypedFormGroup;
    fleetVehicleForm: UntypedFormGroup;
    vehicleVersionForm: UntypedFormGroup;
    makeCtrl: UntypedFormControl;
    licenseNumberCtrl: UntypedFormControl;
    vehicleIdentifierNumberCtrl: UntypedFormControl;
    carRegistrationDocumentCtrl: UntypedFormControl;

    get vehiclesForm(): UntypedFormArray {
        return this.form.controls['vehicles'] as UntypedFormArray;
    }

    vehicleMakesObs: Observable<VehicleMakeDto[]>;
    vehicleModelsObs: Observable<VehicleModelDto[]>;
    vehicleVersionsObs: Observable<VehicleVersionDto[]>;
    destroy$: Subject<void> = new Subject();

    Reporting = Reporting;
    fleetConfig: FrontEndFleetConfig;

    vehicleMakesFilterObs: Observable<VehicleMakeDto[]>;
    vehicleModelsFilterObs: Observable<VehicleModelDto[]>;
    vehicleVersionsFilterObs: Observable<VehicleVersionDto[]>;

    foAccountRef: string;
    userId: string;

    mediasCanActivate: MediaPublicCodeDto[] = [];
    mediaCanActivateExist: boolean | undefined = undefined;
    private mediaCanActivateSubject: Subject<string> = new Subject();
    private mediaCanActivateSubscription: Subscription;

    onChangeCallback = (vehicles: Vehicle[]) => {};

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly vehicleHttpService: FrontVehicleHttpService,
        protected readonly notificationService: NotificationService,
        private readonly vehicleVersionHttpService: FrontVehicleVersionHttpService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly frontEndService: FrontEndService,
        private readonly mediaService: FrontMediaHttpService,
        private readonly route: ActivatedRoute,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        @Inject(ACCEPTED_FILE_FORMATS) public readonly fileFormats: string[]) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.criteria = this.vehicleHttpService.getCriteria();
        this.route.data.pipe(
            map(data => data.detailedPerson),
        ).subscribe(detailedPerson => {
            this.userId = detailedPerson.person._id;
            this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId();
            this.vehicleDataLoaded = true;
            this.personPortalContextService.isRigeUser().subscribe(isRigeUser => {
                this.isRigeUser = isRigeUser;
                this.initFormData();
            });
        });
        this.vehicleMakesFilterObs = this.vehicleVersionHttpService.findAllMakes().pipe(shareReplay(1));
        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);

        this.mediaCanActivateSubscription = this.mediaCanActivateSubject.pipe(
            debounceTime(200),
            switchMap((mediaCode) => this.mediaService.findLightMedias(
                {
                    foAccountId: this.foAccountRef,
                    partialPublicCode: mediaCode,
                    withDeactivated: false,
                    withoutLinkedToVehicle: true,
                    limit: 20
                }
            ))
        ).subscribe(mediaPublicCodeDtos => {
            this.mediasCanActivate = mediaPublicCodeDtos;
            if (!this.mediaCanActivateExist) {
                this.mediaCanActivateExist = this.mediasCanActivate.length > 0;
            }
        });
    }

    private initFormData() {
        this.form = this.fb.group({
            licenseNumber: [''],
            vehicleIdentifierNumber: [''],
            make: [''],
            model: [''],
            version: [''],
            fleetVehicleForm: this.fleetVehicleForm,
            vehicles: this.fb.array([])
        });
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => this.onChangeCallback(value));
    }

    public onMakeFilterChange(make: string) {
        this.criteria.make = make === 'undefined' ? undefined : make;
        this.clearModel();
        this.clearVersion();
        this.setVehiclesModelsFilter(this.criteria.make);
    }

    public onModelFilterChange(model: string) {
        this.criteria.model = model === 'undefined' ? undefined : model;
        this.clearVersion();
        this.setVehiclesVersionsFilter(this.criteria.make, this.criteria.model);
    }

    private clearModel() {
        this.criteria.model = undefined;
    }

    private clearVersion() {
        this.criteria.version = 'initial';
    }

    private setVehiclesVersionsFilter(make: string, model: string) {
        let vehicleVersionsObs: Observable<VehicleVersionDto[]>;
        if (make && model) {
            vehicleVersionsObs = this.vehicleVersionsFilterObs = this.vehicleVersionHttpService
                .findVersionsByMakeModel(make, model)
                .pipe(shareReplay(1));
        } else {
            vehicleVersionsObs = of([]);
        }

        return this.vehicleVersionsFilterObs = vehicleVersionsObs;
    }

    private setVehiclesModelsFilter(make: string) {
        let vehicleModelsObs: Observable<VehicleModelDto[]>;
        if (make) {
            vehicleModelsObs = this.vehicleModelsFilterObs = this.vehicleVersionHttpService
                .findModelsByMake(make)
                .pipe(shareReplay(1));
        } else {
            vehicleModelsObs = of([]);
        }

        return this.vehicleModelsFilterObs = vehicleModelsObs;
    }

    private sortVehicle(vehicle1: Vehicle, vehicle2: Vehicle): number {
        if (!vehicle1 || !vehicle1.vehicleVersion || !vehicle1.vehicleVersion.make) {
            return -1;
        } else if (!vehicle2 || !vehicle2.vehicleVersion || !vehicle2.vehicleVersion.make) {
            return 1;
        } else {
            let compareResult: number = vehicle1.vehicleVersion.make.localeCompare(vehicle2.vehicleVersion.make);
            if (compareResult === 0) {
                compareResult = vehicle1.vehicleVersion.model
                    .localeCompare(vehicle2.vehicleVersion.model);
            }
            return compareResult;
        }
    }

    public addVehicle() {
        // As tabs use location, we need to set onSameUrlNavigation to 'reload'
        // because when we try to go back to fleet list with tab, the router is not aware
        // that the URL changed.
        this.router.navigate(
            ['/main/configuration/fleet/new'],
            {
                onSameUrlNavigation: 'reload',
            }
        );
    }

    public updateNumberVehicles(nbrVehicles: number) {
        this.nbrVehiclesChange.emit(nbrVehicles);
    }

    public deleteVehicle(vehicle: Vehicle) {
        let deletionValidated: boolean;
        this.alertService.confirm(
            this.translateService.instant('fleet.vehicle.delete.message'),
            this.translateService.instant('fleet.vehicle.delete.title'),
            this.translateService.instant('fleet.vehicle.delete.validate'),
            this.translateService.instant('fleet.vehicle.delete.cancel')
        ).pipe(
            switchMap((response) => {
                deletionValidated = response;
                if (response) {
                    return this.vehicleHttpService.deleteVehicle(vehicle.id);
                }
                return of(noop);
            })
        ).subscribe(() => {
            if (deletionValidated) {
                this.notificationService.success('fleet.delete');
                this.vehicles.splice(this.vehicles.findIndex(item => vehicle.id === item.id), 1);
                this.updateNumberVehicles(this.nbrVehicles - 1);
                this.licenseNumbers.delete(vehicle.licenseNumber.toLowerCase());
                this.vehicleIdentifierNumbers.delete(vehicle.vehicleIdentifierNumber.toLowerCase());
                this.resetSearch();
                this.sortForm();
            }
        }, () => {
            this.notificationService.error('fleet.error');
        });
    }
    public sortForm() {
        this.vehiclesForm.controls = this.vehiclesForm.controls.sort(
            (vehicle1, vehicle2) => this.sortVehicle(vehicle1.value, vehicle2.value));
    }

    public resetSearch(): void {
        this.form.controls['licenseNumber'].setValue('');
        this.form.controls['vehicleIdentifierNumber'].setValue('');
        this.form.controls['make'].setValue(undefined);
        this.form.controls['model'].setValue(undefined);
        this.form.controls['version'].setValue('initial');
        this.updateCriteria();
    }

    public searchFleet() {
        this.updateCriteria();
        this.searchChange.emit(Object.assign({}, this.criteria));
    }

    private updateCriteria() {
        this.criteria.licenseNumber = this.form.get('licenseNumber').value;
        this.criteria.vehicleIdentifierNumber = this.form.get('vehicleIdentifierNumber').value;
        this.criteria.make = this.form.get('make').value;
        this.criteria.model = this.form.get('model').value;
        this.criteria.version = this.form.get('version').value;
    }

    public trackByName(index: number, vehicle: VehicleMakeDto | VehicleModelDto) {
        return vehicle?.name ?? index;
    }

    public trackByVehicleVersionId(index: number, vehicleVersion: VehicleVersionDto) {
        return vehicleVersion?.id ?? index;
    }

    public sortNull() {
        return FleetVehicleUtils.sortNull();
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        return this.form.invalid ? {'fleet-vehicule-list': reduceFormGroupErrors(this.form)} : null;
    }

    public searchMedias(mediaCode: string) {
        this.mediaCanActivateSubject.next(mediaCode);
    }

    ngOnDestroy(): void {
        this.mediaCanActivateSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected readonly undefined = undefined;

    // Import vehicle
    createVehicleImportRequestReportObs: (string, number) => Observable<string> =
        (fileId, maxErrorToReport) => this.vehicleHttpService.createVehicleImportRequestReport(
            this.foAccountRef,
            fileId,
            maxErrorToReport
        );

    fetchImportRequestReportObs: (string) => Observable<FleetImportRequestReportProcessing> =
        (importId) => this.vehicleHttpService.fetchImportRequestReportObs(this.foAccountRef, importId);

    importRequestObs: (string) => Observable<void> =
        vehicleImportRequestId => this.vehicleHttpService.importVehicles(this.foAccountRef, vehicleImportRequestId);

    public getQueryParamsFromCriteria(): string {
        let queryParams = '';
        if (!!this.form.get('licenseNumber').value ||
            !!this.form.get('vehicleIdentifierNumber').value ||
            !!this.form.get('make').value ||
            !!this.form.get('model').value ||
            !!this.form.get('version').value) {
            queryParams += '?';
        } else {
            return queryParams;
        }
        if (!!this.form.get('licenseNumber').value) {
            queryParams += 'licenseNumber=' + this.form.get('licenseNumber').value + '&';
        }
        if (!!this.form.get('vehicleIdentifierNumber').value) {
            queryParams += 'vehicleIdentifierNumber=' + this.form.get('vehicleIdentifierNumber').value + '&';
        }
        if (!!this.form.get('make').value) {
            queryParams += 'make=' + this.form.get('make').value + '&';
        }
        if (!!this.form.get('model').value) {
            queryParams += 'model=' + this.form.get('model').value + '&';
        }
        if (!!this.form.get('version').value && this.form.get('version').value !== 'initial') {
            queryParams += 'version=' + this.form.get('version').value;
        }
        return queryParams;
    }

    displayMedias(vehicle: Vehicle) {
        return vehicle.mediaUsages?.map((media) => media.publicCode).join(', ');
    }

    seeDetails(vehicle: Vehicle) {
        this.navigateToDetails(vehicle, false);
    }

    goToDetails(vehicle: Vehicle) {
        this.navigateToDetails(vehicle, true);
    }

    private navigateToDetails(vehicle: Vehicle, isModifying: boolean) {
        // As tabs use location, we need to set onSameUrlNavigation to 'reload'
        // because when we try to go back to fleet list with tab, the router is not aware
        // that the URL changed.
        this.router.navigate(
            [`/main/configuration/fleet/${vehicle.id}`, {}],
            {
                onSameUrlNavigation: 'reload',
                state: {
                    isModifying
                }
            }
        );
    }
}
