import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FrontPersonPortalContextService} from 'lib-front';

@Injectable()
export class InfraManagementTariffCreationEnabledGuard implements CanActivate {

    constructor(private personPortalContextService: FrontPersonPortalContextService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.personPortalContextService.isInfraManagementTariffCreationEnabled();
    }

}
