import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, RouterStateSnapshot} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegisterComponent} from './register.component';
import {RegisterSubscriptionComponent} from './subscription/register-subscription.component';
import {RegisterFleetComponent} from './fleet/register-fleet.component';
import {ComponentsModule} from '../../components/components.module';
import {RegisterCreationSuccessComponent } from './creationSuccess/register-creation-success.component';
import {RegisterPaymentComponent} from './payment/register-payment.component';
import {RegisterInformationComponent} from './informations/register-information.component';
import {ServicesModule} from '../../services/services.module';
import {RegisterCreationPendingComponent} from './creationPending/register-creation-pending.component';

@NgModule({
    declarations: [
        RegisterComponent,
        RegisterInformationComponent,
        RegisterSubscriptionComponent,
        RegisterFleetComponent,
        RegisterPaymentComponent,
        RegisterCreationSuccessComponent,
        RegisterCreationPendingComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ServicesModule,
        ComponentsModule
    ]
})
export class RegisterModule {
}
