import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {
    ConnectedUser,
    ConnectedUserStatus,
    FrontApiInfoHttpService,
    FrontSessionBusinessService,
    HttpCodesUtil,
    IziviaLoggerService,
    IziviaSentryService,
    MatomoService
} from 'lib-front';
import {AVAILABLE_SERVER_LANGUAGES, DEFAULT_APP_LANGUAGE, ServerLanguage} from '../services/i18n/i18n.config';
import {browserLanguageToAppLanguage, serverLanguageToAppLanguage} from '../services/i18n/i18n.function';
import {forkJoin, Subscription} from 'rxjs';
import moment from 'moment';
import {ProConfigHttpService} from '../services/http/proConfigHttp.service';
import {ProConfig} from '../domain/proConfig';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './appRoot.component.html',
    styleUrls: ['./appRoot.component.scss']
})
export class AppRootComponent implements OnInit, OnDestroy {
    private _connectedUser: ConnectedUser;
    private isConnectedUserSuspended: boolean;
    private isConnectedUserSuspendedByMissingPaymentConf: boolean;

    private connectedUserSubscription: Subscription;

    set connectedUser(connectedUser: ConnectedUser) {
        this.setCurrentAppLanguage(connectedUser);
        this._connectedUser = connectedUser;
    }

    constructor(private readonly translateService: TranslateService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly sentryService: IziviaSentryService,
        private readonly apiInfoHttpService: FrontApiInfoHttpService,
        private readonly loggerService: IziviaLoggerService,
        private readonly proConfigService: ProConfigHttpService,
        private readonly router: Router,
        private readonly matomoService: MatomoService) {
    }

    ngOnInit(): void {
        this.initErrorTrackingReporting();
        this.initAnalytics();

        this.connectedUserSubscription = this.sessionBusinessService.connectedUser$
            .subscribe(connectedUser => this.connectedUser = connectedUser);

        this.sessionBusinessService.isConnectedUserSuspended().subscribe(isSuspended =>
            this.isConnectedUserSuspended = isSuspended
        );

        this.sessionBusinessService.isConnectedUserSuspendedByMissingPaymentConf().subscribe(isSuspended =>
            this.isConnectedUserSuspendedByMissingPaymentConf = isSuspended
        );

        this.translateService.setDefaultLang(DEFAULT_APP_LANGUAGE);
    }

    ngOnDestroy(): void {
        this.connectedUserSubscription?.unsubscribe();
    }

    onLoginOrRegister() {
        return this.router.url.indexOf('/login') > -1 || this.router.url.indexOf('/register') > -1;
    }

    public isSuspendedAccount(): boolean {
        return this.isConnectedUserSuspended;
    }

    public isSuspendedByMissingPaymentConf(): boolean {
        return this.isConnectedUserSuspendedByMissingPaymentConf;
    }

    private setCurrentAppLanguage(connectedUser?: ConnectedUser): void {
        let locale: string;

        if (connectedUser && !connectedUser.anonymous && !!connectedUser.user && connectedUser.user.locale) {
            const userLocal = connectedUser.user.locale as ServerLanguage;
            if (AVAILABLE_SERVER_LANGUAGES.includes(userLocal)) {
                locale = serverLanguageToAppLanguage(userLocal);
            } else {
                console.warn(`Cannot handle server language ${userLocal}`);
            }
        } else {
            const savedLanguage = this.sessionBusinessService.getLanguage();

            if (savedLanguage) {
                locale = browserLanguageToAppLanguage(savedLanguage);
            } else {
                locale = browserLanguageToAppLanguage(this.translateService.getBrowserCultureLang());
            }
        }

        if (!locale) {
            locale = DEFAULT_APP_LANGUAGE;
        }
        this.translateService.use(locale);
        moment.locale(locale);
    }

    private initErrorTrackingReporting(): void {
        if (!environment.production) {
            this.loggerService.debug('Sentry is disabled in development mode');
            return;
        }

        forkJoin([this.apiInfoHttpService.version(), this.proConfigService.findProConfig()])
            .subscribe(
                ([apiVersion, proConfig]: [string, ProConfig]) => this.sentryService.startSentry(apiVersion, proConfig),
                error => this.handleInitializationError(error)
            );
    }

    private initAnalytics(): void {
        this.proConfigService.findProConfig()
            .subscribe(proConfig => {
                if (!!proConfig.matomoSiteId && !!proConfig.matomoTrackerUrl) {
                    this.matomoService.startMatomo({
                        siteId: proConfig.matomoSiteId,
                        trackerUrl: proConfig.matomoTrackerUrl
                    });
                }
            });
    }

    private handleInitializationError(error) {
        if (HttpCodesUtil.isServerUnderMaintenance(error)) {
            this.loggerService.debug('[handleInitializationError] Server under maintenance', error);
        } else {
            this.loggerService.error('[handleInitializationError] Unexpected error while initializing app', error);
        }
    }
}
