import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProConfig} from '../../domain/proConfig';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProConfigHttpService {
    private proConfig$: Observable<ProConfig>;

    constructor(private http: HttpClient) {
    }

    findProConfig(): Observable<ProConfig> {
        if (!this.proConfig$) {
            this.proConfig$ = this.http.get<ProConfig>(`/api/v2/pro/config`)
                .pipe(shareReplay(1));
        }
        return this.proConfig$;
    }
}
