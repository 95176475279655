import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../../../components/components.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {HomeInvoiceComponent} from './home-invoice/home-invoice.component';
import {HomeMapComponent} from './home-map/home-map.component';
import {HomeChargeConsumptionsComponent} from './home-charge-consumptions/home-charge-consumptions.component';
import {HomeMediaComponent} from './home-media/home-media.component';
import {HomeFleetCollaboratorsComponent} from './home-fleet-collaborators/home-fleet-collaborators.component';
import {HomeSubscriptionComponent} from './home-subscription/home-subscription.component';
import {ServicesModule} from '../../../services/services.module';
import {HomeStationManagementComponent} from './home-station-management/home-station-management.component';
import {ReportingModule} from '../main/reporting/reporting.module';
import {HomeGuard} from './home.guard';

@NgModule({
    declarations: [
        HomeComponent,
        HomeInvoiceComponent,
        HomeMapComponent,
        HomeChargeConsumptionsComponent,
        HomeMediaComponent,
        HomeFleetCollaboratorsComponent,
        HomeSubscriptionComponent,
        HomeStationManagementComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        ComponentsModule,
        PipesModule,
        ServicesModule,
        ReportingModule
    ],
    providers: [
        HomeGuard
    ]
})
export class HomeModule {

}
