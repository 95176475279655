<section class="grid-y style-guide-block">
    <h1 class="style-guide-title">Form</h1>

    <div class="cell style-guide-section-content">
        <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Retrouvez les styles des éléments de formulaire sur la feuille de style ce trouvant dans <strong>styles/components/form.scss</strong>
            </p>
        </div>

        <h4 class="style-guide-sub-title">Bases styles Input / Select</h4>
        <!-- START / Basic Input -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label>
                        Label input
                        <input class="form-control" name="" type="text">
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>
                        &lt;label&gt;Label input
                        &lt;input class="form-control" name="" type="text"&gt;
                        &lt;/label&gt;
                    </li>
                </ul>
            </div>
        </div>
        <!-- END / Basic Input -->

        <!-- START / Basic Input required -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="required">
                        <span>Label input required</span>
                        <input class="form-control" name="" type="text">
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>
                        &lt;label class="required"&gt;
                        &lt;span&gt; Label input &lt;/span&gt;
                        &lt;input class="form-control" name="" type="text" required&gt;
                        &lt;/label&gt;
                    </li>
                </ul>
            </div>
        </div>
        <!-- END / Basic Input required -->

        <!-- START / Basic Input validate -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="required validate">
                        <span>Label input validate</span>
                        <input class="form-control" name="" type="text">
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>
                        &lt;label class="required validate"&gt;
                        &lt;span&gt; Label input &lt;/span&gt;
                        &lt;input class="form-control" name="" type="text" required&gt;
                        &lt;/label&gt;
                    </li>
                </ul>
            </div>
        </div>
        <!-- END / Basic Input validate -->


        <!-- START / Label login -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="cell grid-y label-login primary login">
                        <span class="label-container">Label login</span>
                        <input class="form-control input-login" name="" type="text">
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>
                        &lt;label class="cell grid-y label-login primary login"&gt;
                        &lt;span class="label-container"&gt; Label login &lt;/span&gt;
                        &lt;input class="form-control input-login" name="" type="text"&gt;
                        &lt;/label&gt;
                    </li>
                </ul>
            </div>
        </div>
        <!-- END / Label login -->

        <!-- START / Label login password -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="cell grid-y label-login secondary password">
                        <span class="label-container">Label login password</span>
                        <input class="form-control input-login" name="" type="text">
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>
                        &lt;label class="cell grid-y label-login secondary-password"&gt;
                        &lt;span class="label-container"&gt; Label login password &lt;/span&gt;
                        &lt;input class="form-control input-login" name="" type="text" /&gt;
                        &lt;/label&gt;
                    </li>
                </ul>
            </div>
        </div>
        <!-- END / Label password -->

        <!-- START / Basic DatePicker -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="date-picker">
                        <span>Date Picker</span>
                        <date-picker></date-picker>
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>
                        &lt;label class="date-picker"&gt;
                        &lt;span&gt; Date Picker &lt;/span&gt;
                        &lt;date-picker&gt;&lt;/date-picker&gt;
                    </li>
                </ul>
            </div>
        </div>
        <!-- END / Basic DatePicker -->

        <!-- START / Checkbox -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="checkbox">
                        <input type="checkbox" name="">
                        <span class="custom-checkbox"></span>
                        <span class="label-choice">Label Right</span>
                    </label>

                    <label class="checkbox">
                        <span class="label-choice">Label Left</span>
                        <input type="checkbox" name="">
                        <span class="custom-checkbox"></span>
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <label class="checkbox">
                    <input type="checkbox" name="">
                    <span class="custom-checkbox"></span>
                    <span class="label-choice">Label Right</span>
                </label>

                <label class="checkbox">
                    <span class="label-choice">Label Left</span>
                    <input type="checkbox" name="">
                    <span class="custom-checkbox"></span>
                </label>
            </div>
        </div>
        <!-- END / Checkbox -->

        <!-- START / Radio -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <label class="radio">
                        <input type="radio" name="">
                        <span class="custom-radio"></span>
                        <span class="label-choice">Label Right</span>
                    </label>

                    <label class="radio">
                        <span class="label-choice">Label Left</span>
                        <input type="radio" name="">
                        <span class="custom-radio"></span>
                    </label>
                </div>
            </div>
            <div class="style-guide-code">
                <label class="radio">
                    <input type="radio" name="">
                    <span class="custom-radio"></span>
                    <span class="label-choice">Label Right</span>
                </label>

                <label class="radio">
                    <span class="label-choice">Label Left</span>
                    <input type="radio" name="">
                    <span class="custom-radio"></span>
                </label>
            </div>
        </div>
        <!-- END / Radio -->
    </div>
</section>
