<div class="cell auto app-view-content">
    <div class="grid-y cell auto main-container has-space force-space-bottom-0">
        <div class="grid-x cell shrink align-justify space-bottom-8 space-top-8">
            <h3 class="grid-x align-middle secondary block-title">
                {{'stations.edit.title' | translate}}
            </h3>

            <a type="button" class="cell shrink button secondary" [routerLink]="['/main/chargeAreas', chargeAreaId]">
                {{'stations.edit.back' | translate}}
            </a>
        </div>
        <div class="grid-x grid-margin-x cell shrink align-center space-top-16 space-bottom-16">
            <station-card (click)="selectTab(stationTemplate)"
                          [class.selected]="isSelected(stationTemplate)"
                          [location]="station?.address"
                          [station]="station"
                          class="cell auto cardContainer hover"></station-card>
            <charge-points-card (click)="selectTab(chargePointsTemplate)"
                                [class.selected]="isSelected(chargePointsTemplate)"
                                [station]="station"
                                class="cell auto cardContainer hover"></charge-points-card>
            <station-tariff-card *ngIf="isInfraManagementTariffCreationEnabled"
                                 (click)="selectTab(pricingTemplate)"
                                 [class.selected]="isSelected(pricingTemplate)"
                                 [tariffs]="linkedTariffs"
                                 [othrTariff]="othrTariff"
                                 class="cell auto cardContainer hover"></station-tariff-card>
        </div>
        <div class="grid-x space-top-16">
            <ng-container *ngIf="selectedTemplate" [ngTemplateOutlet]="selectedTemplate"></ng-container>
        </div>
        <div class="grid-x cell shrink align-justify space-bottom-8 space-top-32">
            <h3 class="grid-x align-middle secondary block-title">
                {{'stations.chargeRecords.title' | translate}}
            </h3>
        </div>
        <div class="grid-x space-top-16">
            <charge-record-table [chargeRecords]="chargeRecords"
                                 [currencyUnit]="'€'"></charge-record-table>
        </div>
    </div>
</div>
<ng-template #stationTemplate>
    <station-edit *ngIf="station" class="cardContainer width-100"
                  [station]="station"
                  [hasStationWriteRole]="hasStationWriteRole"></station-edit>
</ng-template>
<ng-template #chargePointsTemplate>
    <div class="grid-y cell cardContainer">
        <charge-point-actions *ngFor="let chargePoint of chargePoints; trackBy: trackByChargePointId"
                              [hasStationWriteRole]="hasStationWriteRole"
                              [chargePoint]="chargePoint" [stationId]="station.id"
                              class="cell marginBottom"></charge-point-actions>
    </div>
</ng-template>
<ng-template #pricingTemplate>
    <div class="grid-y cell main-container cardContainer">
        <station-tariff-links [stationId]="station?.id"
                              [hasStationWriteRole]="hasStationWriteRole"
                              [linkedTariffs]="linkedTariffs"
                              [othrTariff]="othrTariff"
                              [tariffsAllowSubscriberToDeclareStation]="tariffsAllowSubscriberToDeclareStation"
                              [canCreatePaynowTariffs]="canCreatePaynowTariffs"
                              (linkedTariffEmitter)="linkedTariffChanged()" >
        </station-tariff-links>
    </div>
</ng-template>
