import {Component, Inject} from '@angular/core';
import {FrontPasswordHttpService, HOST_THIRD_PARTY_ID} from 'lib-front';
import {NotificationService} from '../../../services/utils/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'forgotten-pwd',
    templateUrl: './forgotten-pwd.component.html',
    styleUrls: ['./forgotten-pwd.component.scss'],
    host: {'class': 'cell grid-y auto align-center align-middle'}
})
export class ForgottenPwdComponent {
    public email: string = '';

    public sendingEmail: boolean;

    constructor(private readonly passwordHttpService: FrontPasswordHttpService,
        private readonly notificationService: NotificationService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {
    }

    public forgottenPassword() {
        this.sendingEmail = true;
        this.passwordHttpService.forgottenPassword(this.email, true)
            .pipe(
                finalize(() => this.sendingEmail = false)
            )
            .subscribe(
                () => this.notificationService.success('forgottenPwd.success'),
                () => this.notificationService.success('forgottenPwd.error')
            );
    }
}
