import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ChargePreviewDto, FrontEndFleetConfig, FrontTariffHttpService, TariffDetailDto, TariffPreviewDto,
    TariffPreviewRequestDto,
    TariffSimulationRequestDto,
    TariffSimulationResponseDto
} from 'lib-front';
import moment, {Moment} from 'moment';
import {finalize, tap} from 'rxjs/operators';
import {FrontEndService} from '../../services/frontEnd.service';


@Component({
    selector: 'tariff-charge-simulator',
    templateUrl: './tariff-charge-simulator.component.html',
    styleUrls: ['./tariff-charge-simulator.component.scss']
})
export class TariffChargeSimulatorComponent implements OnInit {


    @Input()
    get tariff(): TariffDetailDto {
        return this._tariff;
    }

    set tariff(value: TariffDetailDto) {
        this._tariff = value;
        if (!!value.id) {
            this.simulate();
        } else {
            this.loading = false;
        }
    }

    public _tariff: TariffDetailDto;
    loading: boolean = true;
    startTimeStr: string = '09:00';
    endTimeStr: string = '12:00';
    startTime: Moment = moment(this.startTimeStr, 'HH:mm');
    endTime: Moment = moment(this.endTimeStr, 'HH:mm');
    energyConsumptionInKWh: number = 18;

    public totalPrice: number = 0;
    public tariffSimulationResponses: TariffSimulationResponseDto[];
    public fleetConfig: FrontEndFleetConfig;

    constructor(
        private readonly tariffHttpService: FrontTariffHttpService,
        private readonly frontEndService: FrontEndService
    ) {
    }

    ngOnInit() {
        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);
    }

    private simulate() {
        this.loading = true;
        const tariffSimulationRequest: TariffSimulationRequestDto = {
            tariffId: this._tariff.id,
            startTime: this.startTimeStr,
            endTime: this.endTimeStr,
            power: this.energyConsumptionInKWh,
            fixedCost: this.tariff.chargePrice
        };
        this.tariffHttpService.simulate(tariffSimulationRequest.tariffId, tariffSimulationRequest)
            .pipe(
                tap(tariffSimulationResponse => this.tariffSimulationResponses = tariffSimulationResponse),
                finalize(() => this.loading = false)
            ).subscribe();
    }

    preview(): void {

        const chargePreview: ChargePreviewDto = {
            startTime: this.startTimeStr,
            endTime: this.endTimeStr,
            power: this.energyConsumptionInKWh
        };

        const tariffPreview: TariffPreviewDto = {
            tariffElements: this._tariff.tariffElements,
            chargePrice: this._tariff.chargePrice,
            currency: this._tariff.currency
        };

        const previewRequest: TariffPreviewRequestDto = {
            chargePreview: chargePreview,
            tariffPreview: tariffPreview
        };

        this.tariffHttpService.previewTariff(previewRequest)
            .pipe(
                tap(tariffPreviewResponse => this.tariffSimulationResponses = [tariffPreviewResponse]),
                finalize(() => this.loading = false)
            );

    }
}
