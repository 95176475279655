<div class="cell small-24" *ngIf="totalConsumptions.length > 0 else emptyConsumptions">
    <table>
        <thead>
        <tr>
            <th>{{'invoice.consumptions.object' | translate}}</th>
            <th>{{'invoice.consumptions.totalHT' | translate}}</th>
            <th>{{'invoice.consumptions.totalTTC' | translate}}</th>
        </tr>
        </thead>
        <tbody class="border">
        <tr *ngFor="let totalConsumption of totalConsumptions | mergeTotalConsumptionsByType">
            <td>{{ 'invoice.consumptions.metaConsumptionTotalType.' + totalConsumption.type | translate }}</td>
            <td>{{totalConsumption.priceWithoutVat | currency:'EUR'}}</td>
            <td>{{totalConsumption.priceWithVat | currency:'EUR'}}</td>
        </tr>
        </tbody>
    </table>
</div>

<ng-template #emptyConsumptions>
    <div class="cell">{{ 'invoice.consumptions.empty' | translate }}</div>
</ng-template>

