import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {
    ChargePointDto,
    FrontChargePointHttpService,
    FrontPlugHttpService,
    FrontStationHttpService,
    PlugDto
} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, filter, skip, switchMap} from 'rxjs/operators';

@Component({
    selector: 'charge-point-actions',
    templateUrl: './charge-point-actions.component.html',
    styleUrls: ['./charge-point-actions.component.scss']
})
export class ChargePointActionsComponent implements AfterViewInit, OnDestroy {

    @Input() chargePoint: ChargePointDto;
    @Input() stationId: string;
    @Input() public hasStationWriteRole: boolean;
    @ViewChild('chargePointForm', {static: true}) private chargePointForm: NgForm;
    private chargePointFormSubscription: Subscription;
    public selectedPlug: PlugDto;

    constructor(private readonly stationService: FrontStationHttpService,
        private readonly chargePointHttpService: FrontChargePointHttpService,
        private readonly plugHttpService: FrontPlugHttpService,
        private readonly notificationService: NotificationService) {
    }

    ngAfterViewInit(): void {
        this.chargePointFormSubscription = this.chargePointForm.form.valueChanges.pipe(
            filter(() => !!this.chargePointForm.valid),
            skip(1),
            debounceTime(1000),
            filter(() => !!this.chargePointForm.valid),
            switchMap(() => this.chargePointHttpService.updateChargePoint(this.chargePoint))
        ).subscribe(() => this.notificationService.success('chargePoint.update.success'),
            () => this.notificationService.error('chargePoint.update.error'));
    }

    ngOnDestroy(): void {
        if (!!this.chargePointFormSubscription) {
            this.chargePointFormSubscription.unsubscribe();
        }
    }

    selectPlug(plug: PlugDto) {
        this.selectedPlug = plug;
    }

    isSelected(plug: PlugDto) {
        return !!this.selectedPlug && !!plug && this.selectedPlug.id === plug.id;
    }

    trackByPlugId(index: number, plug: PlugDto) {
        return plug?.id ?? index;
    }
}
