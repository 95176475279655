import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DetailedPersonDto, FrontMediaHttpService, MediaData, User} from 'lib-front';
import {Subscription} from 'rxjs';
import {CurrentUserContextService} from '../../../../services/business/currentUserContext.service';

@Component({
    selector: 'home-media',
    templateUrl: './home-media.component.html',
    styleUrls: ['./home-media.component.scss'],
    host: {
        'class': '' +
            'cell auto home-card'
    }

})
export class HomeMediaComponent implements OnInit, OnDestroy {

    public mediaData: MediaData;

    private fetchMediaDataSubscription: Subscription;

    constructor(private frontMediaHttpService: FrontMediaHttpService,
        private readonly currentUserContextService: CurrentUserContextService) {
    }

    ngOnDestroy(): void {
        this.fetchMediaDataSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        let currentFoAccountId = this.currentUserContextService.getCurrentFoAccountId();
        let personId = this.currentUserContextService.getCurrentPersonId();

        if (personId && currentFoAccountId) {
            this.fetchMediaDataSubscription =
                this.frontMediaHttpService.findMediaData(this.currentUserContextService.getCurrentPersonId(), currentFoAccountId)
                    .subscribe(medias => this.mediaData = medias);
        }
    }
}
