import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AddressSuggestionDetailDto,
    AddressSuggestionDto,
    CityDto,
    CountryDto,
    FrontReferenceHttpService,
    FrontSuggestionHttpService,
    Location,
    LocationPipe, PostalCodeDto
} from 'lib-front';
import {debounceTime, switchMap, take} from 'rxjs/operators';
import {of, Subject, Subscription} from 'rxjs';

@Directive()
export abstract class AddressDirective implements OnInit, OnDestroy {
    protected _address: Location;
    public description: string;

    @Input()
    set address(address: Location) {
        this._address = address ? address : {};
        if (!this._address.countryCode) {
            this._address.countryCode = 'FR';
        }
        this.description = this.locationPipe.transform(this._address);
    }

    get address(): Location {
        return this._address;
    }

    @Input() required: boolean = false;

    @Output() formAddressValidChange: EventEmitter<boolean> = new EventEmitter(false);
    @Output() formAddressDirtyChange: EventEmitter<boolean> = new EventEmitter(false);
    addressSuggestions: AddressSuggestionDto[] = [];
    postalCodeSuggestions: PostalCodeDto[] = [];
    citySuggestions: CityDto[] = [];
    countries: CountryDto[] = [];

    protected SUGGESTION_LIMIT: number = 5;

    protected addressSuggestionSubject: Subject<string> = new Subject();
    protected postalCodeSuggestionSubject: Subject<string> = new Subject();
    protected citySuggestionSubject: Subject<CitySearchRequest> = new Subject();

    protected addressSuggestionSubscription: Subscription;
    protected postalCodeSuggestionSubscription: Subscription;
    protected citySuggestionSubscription: Subscription;

    constructor(protected readonly suggestionService: FrontSuggestionHttpService,
        protected readonly referenceService: FrontReferenceHttpService,
        protected readonly locationPipe: LocationPipe) {
    }

    ngOnInit() {
        this.referenceService.findCountries().pipe(take(1)).subscribe(countries => {
            this.countries = countries;
        });

        this.addressSuggestionSubscription = this.addressSuggestionSubject.pipe(
            debounceTime(500),
            switchMap((route) => {
                if (!!route) {
                    return this.suggestionService.findAddressSuggestions(route, this.SUGGESTION_LIMIT);
                } else {
                    return of([]);
                }
            })
        ).subscribe(addresses => this.addressSuggestions = addresses);

        this.postalCodeSuggestionSubscription = this.postalCodeSuggestionSubject.pipe(
            debounceTime(500),
            switchMap((postalCode) => {
                if (!!postalCode) {
                    return this.referenceService.findPostalCodes(postalCode, this.SUGGESTION_LIMIT);
                } else {
                    return of([]);
                }
            })
        ).subscribe(postalCodes => this.postalCodeSuggestions = postalCodes);

        this.citySuggestionSubscription = this.citySuggestionSubject.pipe(
            debounceTime(500),
            switchMap((request: CitySearchRequest) =>
                this.referenceService.findCities(request.city, request.postalCode, this.SUGGESTION_LIMIT)
            )
        ).subscribe(cities => this.citySuggestions = cities);
    }

    ngOnDestroy(): void {
        this.addressSuggestionSubscription.unsubscribe();
        this.postalCodeSuggestionSubscription.unsubscribe();
        this.citySuggestionSubscription.unsubscribe();
    }

    clearAddressSuggestions() {
        this.addressSuggestions = [];
    }

    public mergeStreetNumberAndRoute(streetNumber: string, route: string) {
        let mergedRoute: string;
        if (!!streetNumber) {
            mergedRoute = streetNumber + ' ' + route;
        } else {
            mergedRoute = route;
        }
        return mergedRoute;
    }

    public updateCountryFromCountryCode(address: Location) {
        let foundCountry = this.countries.find(country => country.code === address.countryCode);
        address.country = foundCountry.name;
    }

    public computeAddress(address: Location, addressDetail: AddressSuggestionDetailDto) {
        this.clearAddressSuggestions();
        address.route = this.mergeStreetNumberAndRoute(addressDetail.streetNumber, addressDetail.route);

        address.city = addressDetail.city;
        address.postalCode = addressDetail.postalCode;
        address.countryCode = addressDetail.countryCode;

        this.updateCountryFromCountryCode(address);
    }

    trackByPlaceId(index: number, addressSuggestion: AddressSuggestionDto) {
        return addressSuggestion?.placeId ?? index;
    }

    validAddressSuggestion(placeId: string) {
        if (!!placeId) {
            this.suggestionService.findAddressSuggestionDetail(placeId).subscribe(addressDetail => {
                this.computeAddress(this._address, addressDetail);
                this.description = this.locationPipe.transform(this.address);
                this.updateCountryFromCountryCode(this._address);
            });
        }
    }

    pushNewRoute(event: {term: string, items: any[]}) {
        this.address.route = event.term;
        this.addressSuggestionSubject.next(this.address.route);
    }
}

export interface CitySearchRequest {
    city: string,
    postalCode: string
}
