import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationService} from '../services/utils/notification.service';

@Injectable()
export class CollaboratorRoleInterceptor implements HttpInterceptor {

    private readonly ROLE_MISSING_HEADER = 'x-subscriber-role-missing';

    constructor(private notificationService: NotificationService) {
    }


    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError((err: HttpErrorResponse, caught) => {
                    if (err.status === 403 && err.headers.has(this.ROLE_MISSING_HEADER)) {
                        this.notificationService.error('config.permission.alertNoSufficientRole');
                    }
                    return throwError(err);
                })
            );
    }
}
