import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {EnergyPipe} from 'lib-front';
import {MetaChargeConsumptionDto} from '../domain/meta/metaChargeConsumptionDto';
import {TranslateService} from '@ngx-translate/core';
@Pipe({
    name: 'fleetMetaConsumptionEnergy'
})
export class FleetMetaConsumptionEnergyPipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private readonly locale: string,
        private readonly translateService: TranslateService) {}

    transform(metaConsumption: MetaChargeConsumptionDto): string {
        if (!metaConsumption.energyInWh) {
            return this.translateService.instant('common.unavailable');
        }

        return new EnergyPipe(this.locale).transform(metaConsumption.energyInWh);
    }
}
