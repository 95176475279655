import {Route} from '@angular/router';
import {ExpenseReportSearchComponent} from './expense-report-search.component';
import {ExpenseReportDetailComponent} from './expense-report-detail/expense-report-detail.component';
import {ExpenseReportRgpdAcceptanceComponent} from './expense-report-rgpd-acceptance/expense-report-rgpd-acceptance.component';
import {ExpenseReportGuard} from './expense-report.guard';
import {ExpenseReportReadRoleGuard} from './expense-report-read-role.guard';

export const expenseReportRoutes: Array<Route> = [
    {path: '', component: ExpenseReportSearchComponent, canActivate: [ExpenseReportGuard, ExpenseReportReadRoleGuard], runGuardsAndResolvers: 'always'},
    {path: 'detail/:id', component: ExpenseReportDetailComponent, canActivate: [ExpenseReportGuard], runGuardsAndResolvers: 'always'},
    {path: 'rgpdAcceptance', component: ExpenseReportRgpdAcceptanceComponent}
];
