import { Component, EventEmitter, Input, Output } from '@angular/core';
import {PaymentConf } from 'lib-front';

@Component({
    selector: 'add-payment-popup',
    templateUrl: './add-payment-popup.component.html',
    styleUrls: ['./add-payment-popup.component.scss']
})
export class AddPaymentPopupComponent {

    @Input() selectedPaymentConf: PaymentConf;
    @Output() modalConfirm = new EventEmitter<string>();
    @Output() modalClose = new EventEmitter<void>();
    public paymentName: string;
    public paymentNameMissing = false;

    closeModal() {
        delete this.paymentName;
        this.paymentNameMissing = false;
        this.modalClose.emit();
    }

    confirmModal() {
        if (!this.paymentName) {
            this.paymentNameMissing = true;
            return;
        }

        this.modalConfirm.emit(this.paymentName);
    }
}

