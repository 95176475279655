import {Component, Input, OnInit} from '@angular/core';
import {
    ChargePointDto,
    FrontMediaHttpService,
    FrontPlugHttpService,
    InfraStatus,
    PlugDto, TradeAgreementKind,
    UserRemoteAction,
    UserRemoteActionRequest,
    UserStartTransactionRequest,
    UserStopTransactionRequest
} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {finalize, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';

@Component({
    selector: 'plug-remote-action[chargePoint][plug][hasStationWriteRole]',
    templateUrl: './plug-remote-action.component.html',
    styleUrls: ['./plug-remote-action.component.scss']
})
export class PlugRemoteAction extends AbstractHasRoleActionComponent implements OnInit {
    get chargePoint(): ChargePointDto {
        return this._chargePoint;
    }

    @Input()
    set chargePoint(value: ChargePointDto) {
        this.actions.length = 0;
        if (!!value && value.infraStatus === InfraStatus.AVAILABLE) {
            this.actions.push(UserRemoteAction.START);
            this.remoteAction = UserRemoteAction.START;
        }
        if (!!value && value.infraStatus === InfraStatus.CHARGING) {
            this.actions.push(UserRemoteAction.STOP);
            this.remoteAction = UserRemoteAction.STOP;
        }
        this._chargePoint = value;
    }
    get plug(): PlugDto {
        return this._plug;
    }

    @Input()
    set plug(value: PlugDto) {
        this._plug = value;
    }

    @Input() hasStationWriteRole: boolean;

    private _plug: PlugDto;
    private _chargePoint: ChargePointDto;
    remoteAction: UserRemoteAction;
    actions: UserRemoteAction[] = [];

    public foAccountHasMedia = false;
    public sendingAction: boolean;

    private currentUserId: string;
    private currentUserFoAccountRef: string;

    constructor(private readonly plugHttpService: FrontPlugHttpService,
        private readonly mediaHttpService: FrontMediaHttpService,
        protected readonly notificationService: NotificationService,
        private readonly currentUserContextService: CurrentUserContextService,
        private route: ActivatedRoute) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.route.data.pipe(
            tap(data => this.currentUserId = data.user.user._id),
            tap(() =>
                this.currentUserFoAccountRef = this.currentUserContextService.getCurrentFoAccountId())
        ).subscribe(() => {
            this.mediaHttpService.hasMediaForTradeAgreementKind(this.currentUserId,
                this.currentUserFoAccountRef, TradeAgreementKind.INFRA_ELEM)
                .subscribe(hasMedia => this.foAccountHasMedia = hasMedia);
        });
    }

    public sendAction(): void {
        this.doActionIfHasRole(
            () => {
                this.sendingAction = true;
                this.plugHttpService.sendAction(this._plug.id, this.fromUserRemoteAction(this.remoteAction))
                    .pipe(
                        finalize(() => this.sendingAction = false)
                    )
                    .subscribe(
                        () => this.notificationService.success('chargePoint.success.' + this.remoteAction),
                        error => this.notificationService.error(error.error?.labelKey || 'chargePoint.error')
                    );
            },
            this.hasStationWriteRole
        );
    }

    private fromUserRemoteAction(userRemoteAction: UserRemoteAction): UserRemoteActionRequest {
        switch (userRemoteAction) {
            case UserRemoteAction.START:
                return new UserStartTransactionRequest();
            case UserRemoteAction.STOP:
                return new UserStopTransactionRequest();
            default:
                throw new Error(`${userRemoteAction} is not implemented yet`);
        }
    }

}
