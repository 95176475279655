import {Component, Input} from '@angular/core';
import {OptionSubscription} from 'lib-front';
import * as moment from 'moment';
import {calculateProgressBarOptions, ProgressBarOptions} from '../../utils/progressBarUtils.service';

@Component({
    selector: 'subscription-options-view',
    templateUrl: './subscription-options-view.component.html',
    styleUrls: ['./subscription-options-view.component.scss']
})
export class SubscriptionOptionsViewComponent {
    @Input('options') set optionsInput(options: Array<OptionSubscription>) {
        this.options = options;
        if (this.options) {
            this.sumCarryOverTime = 0;
            this.totalPriceWithVAT = 0;
            this.options.forEach(option => {
                if (option.subscription && option.subscription.currentPeriod && option.subscription.currentPeriod.carriedOverFreeTime) {
                    this.sumCarryOverTime += option.subscription.currentPeriod.carriedOverFreeTime;
                }

                if (option.subscription && option.subscription.subscriptionDef && option.subscription.subscriptionDef.fixAmountWithVAT) {
                    this.totalPriceWithVAT += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
                }
            });
        }
        this.progressBarOptions = calculateProgressBarOptions(this.options, this.showTotal, this.showBar);
    }

    @Input() showTotal = true;
    @Input() smallBackground = false;
    @Input() showBar = true;
    @Input() showOptionTiles = true;
    @Input() smallTitle = false;

    options: Array<OptionSubscription>;
    sumCarryOverTime: number;
    totalPriceWithVAT: number;
    progressBarOptions: ProgressBarOptions;
    resetDate: moment.Moment = moment().add(1, 'month').startOf('month');

    constructor() {
    }

}

