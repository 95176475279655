import {Component, OnInit} from '@angular/core';
import {FrontStatHttpService, getNbStationWithStatus, InfraStatus, StationChargeStats, StationStatusStats} from 'lib-front';
import * as moment from 'moment';

@Component({
    selector: 'home-station-management',
    templateUrl: './home-station-management.component.html',
    styleUrls: ['./home-station-management.component.scss'],
    host: {'class': 'cell shrink home-card'}

})
export class HomeStationManagementComponent implements OnInit {
    public InfraStatus = InfraStatus;
    public stationStats?: StationStatusStats;
    public stationChargeStats?: StationChargeStats;

    public getNbStationWithStatus = getNbStationWithStatus;

    constructor(private readonly statHttpService: FrontStatHttpService) {
    }

    ngOnInit(): void {
        this.statHttpService.findStationStats()
            .subscribe(stationStats => this.stationStats = stationStats);

        const now = moment();
        const monthBeginning = moment().startOf('month');
        this.statHttpService.findStationChargeStats(monthBeginning, now)
            .subscribe(stats => this.stationChargeStats = stats);
    }
}
