import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FOAccountWrapper, FrontFoAccountHttpService, FoAccountStatusEnum, FrontSessionBusinessService} from 'lib-front';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class FoAccountValidatedGuard implements CanActivate {

    constructor(
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly foAccountService: FrontFoAccountHttpService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.foAccountService.findFOAccount()
            .pipe(
                map((foAccount: FOAccountWrapper) => {
                    if (
                        foAccount &&
                        foAccount.currentStatus &&
                        foAccount.currentStatus.status === FoAccountStatusEnum.VALIDATED
                    ) {
                        return true;
                    } else {
                        this.sessionBusinessService.logout()
                            .subscribe(() => this.router.navigate(['/login']));
                    }
                })
            );
    }
}
