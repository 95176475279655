import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {appendAnonymousHeader, FrontSessionBusinessService, HOST_THIRD_PARTY_ID} from 'lib-front';

@Injectable({
    providedIn: 'root'
})
export class AnonymousInterceptor implements HttpInterceptor {
    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.ignoreInterceptor(req)) {
            return next.handle(req);
        }

        // Add anonymous header if local user
        return this.sessionBusinessService.connectedUser$.pipe(
            first(),
            switchMap(user => {
                if (!user) {
                    const headers = appendAnonymousHeader(this.thirdPartyId, req.headers);
                    return next.handle(req.clone({
                        headers: headers
                    }));
                } else {
                    return next.handle(req);
                }
            }));
    }

    /**
     * Never add anonymous header to request URL containing the following paths
     * <br>
     * We do not want to add an anonymous header in the following cases:
     * <ul>
     *     <li>path towards resource that need an authenticated user (with a personal login unlike anonymous)</li>
     *     <li>path towards resource available to anyone (i.e. with <code>@PermitAll</code> annotation)
     *     that may be called <strong>before initializing user session</strong> (otherwise it could lead to unexpected behaviour
     *     by overriding the current authenticated user with anonymous)</li>
     *     <li>path towards local assets</li>
     * </ul>
     */
    private ignoreInterceptor(req: HttpRequest<any>): boolean {
        return req.url.includes('/sessions/current')
            || req.url.includes('/info/version')
            || req.url.includes('/v2/pro/config')
            || req.url.includes('/v2/front/foAccounts/currentId')
            || req.url.includes('/v2/front/personPortalContext')
            || req.url.includes('/front/persons/')
            || req.url.includes('./');
    }
}
