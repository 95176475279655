<table>
    <thead>
    <tr>
        <th>{{'financialReport.table.date' | translate}}</th>
        <th>{{'financialReport.table.reportName' | translate}}</th>
        <th>{{'financialReport.table.totalHT' | translate}}</th>
        <th>{{'financialReport.table.totalTTC' | translate}}</th>
        <th *ngIf="isSelfcareInvoiceActivated">{{'financialReport.table.invoices' | translate}}</th>
    </tr>
    </thead>
    <tbody class="border">
        <tr *ngFor="let report of financialReports">
            <td>{{report.date | date : 'dd/MM/yyyy'}}</td>
            <td><a target="_blank" href="{{report.downloadLink}}">{{report.name}}</a></td>
            <td>{{report.amountCommissionFeeDeductedWithoutVAT | currency:'EUR' : report.currencyUnit}}</td>
            <td>{{report.amountCommissionFeeDeductedWithVAT | currency:'EUR' : report.currencyUnit}}</td>
            <td *ngIf="isSelfcareInvoiceActivated">
                <div class="margin-bottom-5 flex-invoice" *ngFor="let invoice of report.invoices">
                    {{invoice.number}} - {{'invoiceStatus.' + invoice.status | translate}}
                    <a class="button round eye margin-left-5"
                       [href]="invoice.downloadLink"
                       target="_blank"
                       rel="noopener noreferrer">
                        <i class="fas fa-eye"></i>
                    </a>
                </div>
            </td>
        </tr>
    </tbody>
</table>


<div class="grid-y cell auto align-center align-middle message-empty"
     *ngIf="financialReports?.length === 0">
        <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
        <span class="message-empty-label">{{'financialReport.search.noResults' | translate}}</span>
</div>
