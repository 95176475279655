import {Component, Input, OnInit} from '@angular/core';
import {
    FrontPersonPortalContextService,
    ManagerRoles,
    RolesHttpService,
    Subscriber,
    SubscriberRole,
    SubscriberRoleLevel,
    SubscriberRoles,
    SubscriberRolesType,
    SubscriberRolesUpdateRequest,
    SubscriberRoleType
} from 'lib-front';

import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {NotificationService} from '../../services/utils/notification.service';
import {FrontEndService} from '../../services/frontEnd.service';
import {forkJoin} from 'rxjs';


type SubscriberRolesEntry = Partial<Record<SubscriberRoleType | 'ALL', SubscriberRoleLevel | 'NONE' | 'UNKNOWN_LEVEL'>>;

interface SubscriberToRoleEntryMap {
    [subscriberId: string]: SubscriberRolesEntry
}

const CONFIGURATION_TAB_SUB_ROLE: SubscriberRoleType[] = [
    SubscriberRoleType.MANAGER_INFOS,
    SubscriberRoleType.SUBSCRIBER_ROLES,
    SubscriberRoleType.COLLABORATORS,
    SubscriberRoleType.VEHICLES,
    SubscriberRoleType.NOTIFICATIONS,
    SubscriberRoleType.TARIFFS,
    SubscriberRoleType.INDEMNITY,
    SubscriberRoleType.SUPPORTS,
    SubscriberRoleType.FAQ,
    SubscriberRoleType.HELPFUL_DOCUMENTS
];

@Component({
    selector: 'manager-roles-form',
    templateUrl: './manager-roles-form.component.html',
    styleUrls: ['./manager-roles-form.component.scss']
})
export class ManagerRolesFormComponent implements OnInit {
    readonly NONE_LEVEL = 'NONE';
    readonly UNKNOWN_LEVEL = 'UNKNOWN_LEVEL';
    readonly ALL_ROLES = 'ALL';
    readonly LIMIT = 100;
    readonly ITEMS_PER_PAGE = 10;

    public availableRoles: SubscriberRoles;
    public availableMainRoles: Array<SubscriberRole>;
    public availableConfigurationTabSubRoles: Array<SubscriberRole>;
    public managerRoles: Array<ManagerRoles>;
    public managerRolesDisplayed: Array<ManagerRoles>;
    public subscriberMainRolesUpdateEntries: SubscriberToRoleEntryMap = {};
    public subscriberConfigurationTabSubRolesUpdateEntries: SubscriberToRoleEntryMap = {};
    public subscriberMainRolesEntries: SubscriberToRoleEntryMap = {};
    public subscriberConfigurationTabSubRolesEntries: SubscriberToRoleEntryMap = {};
    public availableRolesMap: Partial<Record<SubscriberRoleType, SubscriberRoleLevel[]>> = {};
    public loadingTable: boolean = true;
    public validatingSubscriberEdit: boolean;
    public isStationManager: boolean;

    public SubscriberRoleLevel = SubscriberRoleLevel;
    public textToSearch: string;
    public displayLimitMessage: boolean = false;
    public rolePage = 1;
    public lastPage: number;
    private _hasSubscriberRolesReadRole;
    @Input() public hasSubscriberRolesWriteRole: boolean;
    @Input() public set filter(filter) {
        if (filter) {
            this.textToSearch = filter;
            this.searchManagersRoles();
        }
    }
    @Input()
    public set hasSubscriberRolesReadRole(hasSubscriberRolesReadRole) {
        if (hasSubscriberRolesReadRole && !this._hasSubscriberRolesReadRole) {
            this._hasSubscriberRolesReadRole = hasSubscriberRolesReadRole;
        } else {
            this.managerRoles = null;
            this.availableRoles = null;
            this.loadingTable = false;
        }
    }

    private subscriberAccessibleConfigurationTabSubRoles: Set<SubscriberRoleType> = new Set<SubscriberRoleType>();

    ngOnInit() {
        forkJoin({
            showVehicleSection: this.frontEndService.currentFrontEndInfo$
                .pipe(
                    map(frontEndInfo => frontEndInfo?.fleetConfig?.showVehicleSection)
                ),
            isStationManager: this.personPortalContextService.isStationManager(),
            isRefundManager: this.personPortalContextService.isRefundManager(),
            isInfraManagementTariffCreationEnabled:
                this.personPortalContextService.isInfraManagementTariffCreationEnabled()
        }).subscribe((variables) => {
            this.isStationManager = variables.isStationManager;
            this.fillSubscriberAccessibleConfigurationSubRoles(
                variables.showVehicleSection,
                variables.isStationManager,
                variables.isRefundManager,
                variables.isInfraManagementTariffCreationEnabled
            );
            this.searchManagersRoles();
        });
    }

    private fillSubscriberAccessibleConfigurationSubRoles(showVehicleSection, isStationManager, isRefundManager, isInfraManagementTariffCreationEnabled) {
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.MANAGER_INFOS);
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.COLLABORATORS);
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.SUBSCRIBER_ROLES);
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.NOTIFICATIONS);
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.SUPPORTS);
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.FAQ);
        this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.HELPFUL_DOCUMENTS);

        if (!!showVehicleSection) {
            this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.VEHICLES);
        }

        if (isStationManager && isInfraManagementTariffCreationEnabled) {
            this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.TARIFFS);
        }

        if (isRefundManager) {
            this.subscriberAccessibleConfigurationTabSubRoles.add(SubscriberRoleType.INDEMNITY);
        }
    }

    public get hasSubscriberRolesReadRole() {
        return this._hasSubscriberRolesReadRole;
    }

    constructor(
        private readonly rolesService: RolesHttpService,
        private readonly notificationService: NotificationService,
        private readonly frontEndService: FrontEndService,
        private readonly personPortalContextService: FrontPersonPortalContextService) {
    }

    public searchManagersRoles() {
        this.rolesService.getManagersRoles(this.textToSearch, {limit: this.LIMIT + 1})
            .pipe(
                tap(managersRoles => this.managerRoles = managersRoles),
                tap(() => this.handleLimitation()),
                tap(() => this.computePageNumberAndSetToFirst()),
                switchMap(() => this.rolesService.getAvailableManagerRoles()),
                tap(foAvailableRoles => this.availableRoles = foAvailableRoles),
                tap(() => this.generateAvailableLevelsMap()),
                tap(() => this.generateAvailableRoles()),
                tap(() => this.generateSubscriberRoleEntries()),
                finalize(() => this.loadingTable = false)
            )
            .subscribe();
    }

    private generateAvailableRoles(): void {
        this.availableMainRoles = this.filterMainRoles(this.availableRoles.subscriberRoles);
        this.availableConfigurationTabSubRoles = this.filterAccessibleSubscriberConfigurationTabSubRoles(this.filterConfigurationTabSubRoles(this.availableRoles.subscriberRoles));
    }

    public filterMainRoles(subscriberRoles: SubscriberRole[]): SubscriberRole[] {
        return subscriberRoles.filter(role => role.type !== SubscriberRoleType.CONFIGURATION_TAB
            && !CONFIGURATION_TAB_SUB_ROLE.includes(role.type));
    }

    public filterConfigurationTabSubRoles(subscriberRoles: SubscriberRole[]): SubscriberRole[] {
        return subscriberRoles.filter(role => CONFIGURATION_TAB_SUB_ROLE.includes(role.type));
    }

    public filterConfigurationRole(subscriberRoles: SubscriberRole[]): SubscriberRole[] {
        return subscriberRoles.filter(role => role.type === SubscriberRoleType.CONFIGURATION_TAB);
    }

    private generateAvailableLevelsMap() {
        this.generateAvailableLevelsForAll();
        this.availableRoles.subscriberRoles.forEach(role => this.availableRolesMap[role.type] = this.getAvailableLevelsForRoleType(role.type));
    }


    private generateAvailableLevelsForAll() {
        if (this.availableRoles['@type'] === 'ALL_ROLES') {
            this.availableRoles.subscriberRoles = [];
            Object.keys(SubscriberRoleType).map((type) => this.availableRoles.subscriberRoles.push({
                type: SubscriberRoleType[type],
                level: SubscriberRoleLevel.WRITE
            }));
        }
        this.sortAvailableSubscriberRoles();

        let subscriberRoleLevels: SubscriberRoleLevel[] = [];
        this.availableRoles.subscriberRoles.map(role => this.getLevelsByMax(role.level)).forEach(roleArray => subscriberRoleLevels = subscriberRoleLevels.concat(roleArray));
        this.availableRolesMap[this.ALL_ROLES] = [...new Set(subscriberRoleLevels)];
    }

    private sortAvailableSubscriberRoles(): void {
        this.availableRoles.subscriberRoles =
            this.availableRoles.subscriberRoles
                .sort((firstRole, secondRole) => {
                    const subscriberRole = Object.keys(SubscriberRoleType);
                    return subscriberRole.indexOf(firstRole.type) - subscriberRole.indexOf(secondRole.type);
                });
    }

    private filterAccessibleSubscriberConfigurationTabSubRoles(subscriberRoles: SubscriberRole[]): SubscriberRole[] {
        return subscriberRoles.filter(role => this.subscriberAccessibleConfigurationTabSubRoles.has(role.type));
    }

    private generateSubscriberRoleEntries() {
        this.managerRoles.forEach(managerRoles => {
            this.subscriberMainRolesEntries[managerRoles.subscriberId] = this.getEntryFromRoles(managerRoles.roles, this.filterMainRoles);
            this.subscriberConfigurationTabSubRolesEntries[managerRoles.subscriberId] = this.getEntryFromRoles(managerRoles.roles, this.filterConfigurationTabSubRoles);
        });
    }

    private getRolesForManager(subscriberId: string): ManagerRoles {
        return this.managerRoles.find(managerRoles => managerRoles.subscriberId === subscriberId);
    }

    private getMaxAvailableLevel(roleType: SubscriberRoleType): SubscriberRoleLevel {
        return this.availableRoles.subscriberRoles.find(availableRole => availableRole.type === roleType).level;
    }

    getAvailableLevelsForRoleType(roleType: SubscriberRoleType): SubscriberRoleLevel[] {
        const availableLevels = [];

        if (!this.availableRoles || !this.availableRoles.subscriberRoles) {
            return availableLevels;
        }

        const availableLevel = this.getMaxAvailableLevel(roleType);

        return this.getLevelsByMax(availableLevel);
    }

    private getLevelsByMax(availableLevel: SubscriberRoleLevel): SubscriberRoleLevel[] {
        const availableLevels = [];
        if (availableLevel == SubscriberRoleLevel.WRITE) {
            availableLevels.push(SubscriberRoleLevel.WRITE, SubscriberRoleLevel.READ);
        } else if (availableLevel == SubscriberRoleLevel.READ) {
            availableLevels.push(SubscriberRoleLevel.READ);
        }
        return availableLevels;
    }

    cancelSubscriberMainRolesEdit(subscriberId: string) {
        this.removeMainRolesUpdateRequestForSubscriber(subscriberId);
    }

    cancelSubscriberConfigurationTabSubRolesEdit(subscriberId: string) {
        this.removeConfigurationTabSubRolesUpdateRequestForSubscriber(subscriberId);
    }

    private removeMainRolesUpdateRequestForSubscriber(subscriberId: string) {
        delete this.subscriberMainRolesUpdateEntries[subscriberId];
    }

    private removeConfigurationTabSubRolesUpdateRequestForSubscriber(subscriberId: string) {
        delete this.subscriberConfigurationTabSubRolesUpdateEntries[subscriberId];
    }

    private buildUpdateEntries(subscriberId: string, rolesFilter: (roles: SubscriberRole[]) => SubscriberRole[]) {
        return this.getEntryFromRoles(this.getRolesForManager(subscriberId).roles, rolesFilter);
    }

    startSubscriberMainRolesEdit(subscriberId: string) {
        this.subscriberMainRolesUpdateEntries[subscriberId] = this.buildUpdateEntries(subscriberId, this.filterMainRoles);
    }

    startSubscriberConfigurationTabSubRoleEdit(subscriberId: string) {
        this.subscriberConfigurationTabSubRolesUpdateEntries[subscriberId] = this.buildUpdateEntries(subscriberId, this.filterConfigurationTabSubRoles);
    }
    private validateSubscriberRolesEdit(subscriberId: string,
        mainRolesEntries: SubscriberRolesEntry,
        configurationTabSubRolesEntries: SubscriberRolesEntry,
        finalizeCallback: (subscriberId: string) => void) {
        let newSubscriberRoles = this.getNewRolesFromEntry(
            mainRolesEntries,
            configurationTabSubRolesEntries,
            this.buildUpdateEntries(subscriberId, this.filterConfigurationRole),
        );

        if (newSubscriberRoles['@type'] !== SubscriberRolesType.ALL_INHERITED_ROLES) {
            this.setConfigurationTabRoleLevelIfNeeded(newSubscriberRoles);
        }

        const updateRequest: SubscriberRolesUpdateRequest = {
            subscriberId,
            newRoles: newSubscriberRoles
        };

        this.validatingSubscriberEdit = true;
        this.rolesService.updateSubscriberRoles(updateRequest)
            .pipe(
                finalize(() => {
                    finalizeCallback.call(this, subscriberId);
                    this.validatingSubscriberEdit = false;
                })
            )
            .subscribe(manager => {
                this.updateManager(manager);
                this.notificationService.success('config.permission.collaboratorRightsUpdated');
            });
    }


    validateSubscriberMainRolesEdit(subscriberId: string) {
        this.validateSubscriberRolesEdit(
            subscriberId,
            this.subscriberMainRolesUpdateEntries[subscriberId],
            this.buildUpdateEntries(subscriberId, this.filterConfigurationTabSubRoles),
            this.removeMainRolesUpdateRequestForSubscriber
        );
    }

    validateSubscriberConfigurationTabSubRolesEdit(subscriberId: string) {
        this.validateSubscriberRolesEdit(
            subscriberId,
            this.buildUpdateEntries(subscriberId, this.filterMainRoles),
            this.subscriberConfigurationTabSubRolesUpdateEntries[subscriberId],
            this.removeConfigurationTabSubRolesUpdateRequestForSubscriber
        );
    }

    private setConfigurationTabRoleLevelIfNeeded(subscriberRoles: SubscriberRoles) {
        let configurationSubRoles = subscriberRoles.subscriberRoles.filter(role => CONFIGURATION_TAB_SUB_ROLE.includes(role.type));
        if (configurationSubRoles.length === 0) {
            subscriberRoles.subscriberRoles = subscriberRoles.subscriberRoles.filter(role => role.type !== SubscriberRoleType.CONFIGURATION_TAB);
        } else {
            let rolesLevelsSet = new Set(configurationSubRoles.map(role => role.level));
            let configurationLevel: SubscriberRoleLevel;
            if (rolesLevelsSet.has(SubscriberRoleLevel.WRITE)) {
                configurationLevel = SubscriberRoleLevel.WRITE;
            } else if (rolesLevelsSet.has(SubscriberRoleLevel.READ)) {
                configurationLevel = SubscriberRoleLevel.READ;
            }

            let configurationTabRole = subscriberRoles.subscriberRoles.find(role => role.type === SubscriberRoleType.CONFIGURATION_TAB);
            if (configurationTabRole !== undefined) {
                configurationTabRole.level = configurationLevel;
            } else {
                subscriberRoles.subscriberRoles.push(
                    {
                        type: SubscriberRoleType.CONFIGURATION_TAB,
                        level: configurationLevel
                    }
                );
            }
        }
    }

    private getNewRolesFromEntry(subscriberRolesUpdateEntry: SubscriberRolesEntry,
        subscriberConfigurationSubRolesUpdateEntry: SubscriberRolesEntry,
        subscriberConfigurationRoleUpdateEntry: SubscriberRolesEntry) {
        if (subscriberRolesUpdateEntry[this.ALL_ROLES] == SubscriberRoleLevel.WRITE && subscriberConfigurationSubRolesUpdateEntry[this.ALL_ROLES] === SubscriberRoleLevel.WRITE) {
            return {'@type': SubscriberRolesType.ALL_INHERITED_ROLES, subscriberRoles: []};
        }
        return {
            '@type': SubscriberRolesType.RESTRICTED_ROLES,
            subscriberRoles: this.getRolesFromEntry(subscriberRolesUpdateEntry)
                .concat(this.getRolesFromEntry(subscriberConfigurationSubRolesUpdateEntry))
                .concat(this.getRolesFromEntry(subscriberConfigurationRoleUpdateEntry))
        };
    }

    private updateManager(subscriber: Subscriber) {
        const managerIndex = this.managerRoles.findIndex(item => subscriber._id === item.subscriberId);
        this.managerRoles[managerIndex].roles = subscriber.subscriberRoles;
        this.subscriberMainRolesEntries[subscriber._id] = this.getEntryFromRoles(subscriber.subscriberRoles, this.filterMainRoles);
        this.subscriberConfigurationTabSubRolesEntries[subscriber._id] = this.getEntryFromRoles(subscriber.subscriberRoles, this.filterConfigurationTabSubRoles);
    }

    private getRolesFromEntry(subscriberRoles: SubscriberRolesEntry): SubscriberRole[] {
        const newRoles: SubscriberRole[] = [];
        Object.keys(subscriberRoles).forEach(key => {
            const type = key as (SubscriberRoleType | 'ALL');
            const level = subscriberRoles[type] as SubscriberRoleLevel;

            if (level.valueOf() !== this.NONE_LEVEL && type !== this.ALL_ROLES) {
                newRoles.push({type, level});
            }
        });

        return newRoles;
    }


    private getEntryFromRoles(roles: SubscriberRoles, roleFilter: (role: SubscriberRole[]) => SubscriberRole[]): SubscriberRolesEntry {
        let subscriberEntry: SubscriberRolesEntry = {};

        if (roles['@type'] == SubscriberRolesType.ALL_ROLES ||
            roles['@type'] == SubscriberRolesType.ALL_INHERITED_ROLES
        ) {
            return this.setAllRolesToMaxLevel(subscriberEntry, roleFilter);
        }

        let rolesFiltered = roleFilter(roles.subscriberRoles);
        let availableRolesFiltered = roleFilter(this.availableRoles.subscriberRoles);
        // Need to check specific types before since subscriberRoles is undefined in their cases
        let allCurrentLevels = [...new Set(rolesFiltered.map(role => role.level))];

        if (rolesFiltered.length === availableRolesFiltered.length &&
            allCurrentLevels.length === 1 &&
            allCurrentLevels[0] === SubscriberRoleLevel.WRITE) {
            return this.setAllRolesToMaxLevel(subscriberEntry, roleFilter);
        }

        availableRolesFiltered.forEach(availableRole => subscriberEntry[availableRole.type] = this.NONE_LEVEL);
        rolesFiltered.forEach(subscriberRole => subscriberEntry[subscriberRole.type] = subscriberRole.level);

        this.setAllRolesLevel(subscriberEntry);

        return subscriberEntry;

    }

    private setAllRolesToMaxLevel(subscriberEntry: SubscriberRolesEntry, roleFilter: (role: SubscriberRole[]) => SubscriberRole[]): SubscriberRolesEntry {
        subscriberEntry[this.ALL_ROLES] = SubscriberRoleLevel.WRITE;
        roleFilter(this.availableRoles.subscriberRoles).forEach(availableRole => subscriberEntry[availableRole.type] = availableRole.level);
        return subscriberEntry;
    }

    private setAllRolesLevel(subscriberEntry: SubscriberRolesEntry) {
        let allLevel: SubscriberRoleLevel | 'NONE' | 'UNKNOWN_LEVEL' = this.UNKNOWN_LEVEL;

        if (Object.values(subscriberEntry).every(level => level === SubscriberRoleLevel.WRITE)) {
            allLevel = SubscriberRoleLevel.WRITE;
        }

        if (Object.values(subscriberEntry).every(level => level === SubscriberRoleLevel.READ)) {
            allLevel = SubscriberRoleLevel.READ;
        }

        if (Object.values(subscriberEntry).every(level => level === this.NONE_LEVEL)) {
            allLevel = this.NONE_LEVEL;
        }

        subscriberEntry[this.ALL_ROLES] = allLevel;
    }

    updateMainRolesFromAllRolesType(roleLevel: SubscriberRoleLevel | 'NONE', subscriberId: string) {
        this.updateRolesFromAllRolesType(roleLevel, subscriberId, this.filterMainRoles, this.subscriberMainRolesUpdateEntries);
    }

    updateConfigurationTabSubRolesFromAllRolesType(roleLevel: SubscriberRoleLevel | 'NONE', subscriberId: string) {
        this.updateRolesFromAllRolesType(roleLevel, subscriberId, this.filterConfigurationTabSubRoles, this.subscriberConfigurationTabSubRolesUpdateEntries);
    }

    private updateRolesFromAllRolesType(roleLevel: SubscriberRoleLevel | 'NONE',
        subscriberId: string,
        roleFilter: (role: SubscriberRole[]) => SubscriberRole[],
        subscriberToRoleEntryMap: SubscriberToRoleEntryMap) {
        if (roleLevel === SubscriberRoleLevel.WRITE) {
            this.setAllRolesToMaxLevel(subscriberToRoleEntryMap[subscriberId], roleFilter);
        } else if (roleLevel === SubscriberRoleLevel.READ) {
            this.setSubscriberRolesToLevel(subscriberId, SubscriberRoleLevel.READ, roleFilter, subscriberToRoleEntryMap);
        } else if (roleLevel === this.NONE_LEVEL) {
            this.setSubscriberRolesToLevel(subscriberId, this.NONE_LEVEL, roleFilter, subscriberToRoleEntryMap);
        }
    }

    private setSubscriberRolesToLevel(subscriberId: string,
        level: SubscriberRoleLevel | 'NONE',
        roleFilter: (role: SubscriberRole[]) => SubscriberRole[],
        subscriberToRoleEntryMap: SubscriberToRoleEntryMap) {
        roleFilter(this.availableRoles.subscriberRoles).forEach(role => subscriberToRoleEntryMap[subscriberId][role.type] = level);
    }

    private setAllRolesTypeUpdateEntryToUnknown(subscriberId: string, subscriberToRoleEntryMap: SubscriberToRoleEntryMap) {
        subscriberToRoleEntryMap[subscriberId][this.ALL_ROLES] = this.UNKNOWN_LEVEL;
    }

    public setMainRolesAllRolesTypeUpdateEntryToUnknown(subscriberId: string) {
        this.setAllRolesTypeUpdateEntryToUnknown(subscriberId, this.subscriberMainRolesUpdateEntries);
    }

    public setConfigurationTabSubRolesAllRoleUpdateEntryToUnknown(subscriberId: string) {
        this.setAllRolesTypeUpdateEntryToUnknown(subscriberId, this.subscriberConfigurationTabSubRolesUpdateEntries);
    }

    private handleLimitation(): void {
        if (this.managerRoles && this.managerRoles.length > this.LIMIT) { // so length will be LIMIT + 1
            this.managerRoles.pop();
            this.displayLimitMessage = true;
        } else {
            this.displayLimitMessage = false;
        }
    }

    public getRolesFromPage(page: number): void {
        this.rolePage = page;
        this.managerRolesDisplayed = this.managerRoles.slice((page - 1) * this.ITEMS_PER_PAGE, page * this.ITEMS_PER_PAGE);
    }

    private computePageNumberAndSetToFirst(): void {
        this.rolePage = 1;
        if (this.managerRoles) {
            this.lastPage = Math.ceil(this.managerRoles.length / this.ITEMS_PER_PAGE);
        } else {
            this.lastPage = 1;
        }
        this.getRolesFromPage(this.rolePage);
    }

    public readonly SubscriberRoleType = SubscriberRoleType;
}
