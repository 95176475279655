import {AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {SubscriptionMode, SubscriptionPack} from 'lib-front';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {FleetTokenHttpService} from '../../services/http/tokenHttp.service';
import {NotificationService} from '../../services/utils/notification.service';
import {SubscriptionPackOrTokenWrapper} from '../../domain/subscriptionPackOrTokenWrapper';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'subscription-pack-choice',
    templateUrl: './subscription-pack-choice.component.html',
    styleUrls: ['./subscription-pack-choice.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SubscriptionPackChoiceComponent),
            multi: true
        }
    ]
})
export class SubscriptionPackChoiceComponent implements ControlValueAccessor, AfterViewInit {
    @Input() mode: SubscriptionMode = SubscriptionMode.register;
    @Input() selectionMode: 'single' | 'multiple' = 'single';
    @Input() showPrivateOffers: boolean = false;
    @Input() subscriptionPacksChoice: Array<SubscriptionPack> = [];

    @Output() acceptTerms: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('cguTerms') form: NgForm;

    public token: string;
    public lastValidatedToken: string;
    public tokenValid: boolean;
    public privateOffersSelected: boolean = false;
    public subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper = new SubscriptionPackOrTokenWrapper();
    public tokenErrorLabel: string;
    public fetchingToken: boolean;

    private readonly INVALID_TOKEN_LABEL_KEY: string = 'error.registration.packTokenNotValid';
    private readonly INVALID_TOKEN_TYPE_LABEL_KEY: string = 'error.registration.packTokenNotValid';
    private readonly TOKEN_ADDED_LABEL_KEY: string = 'subscription.token.added';

    private onChange: (_: SubscriptionPackOrTokenWrapper) => void;
    private onTouched: () => void;

    constructor(private fleetTokenHttpService: FleetTokenHttpService,
        private translateService: TranslateService,
        private notificationService: NotificationService) {
    }

    get selectedSubscriptionPacks() {
        return this.privateOffersSelected ? [] : this.subscriptionPackOrTokenWrapper.subscriptionPacks;
    }

    ngAfterViewInit(): void {
        this.form.statusChanges.subscribe(() => {
            const validControlNumbers = Object.values(this.form.form.controls).filter(control => control.valid).length;
            const cguToAcceptNumbers = this.subscriptionPackOrTokenWrapper.subscriptionPacks
                .filter(subscriptionPack => !!subscriptionPack.termsAndConditionsI18nCode).length;
            this.acceptTerms.emit(validControlNumbers === cguToAcceptNumbers);
        });
    }

    onSelectSubscriptionPack(subscriptionPack: SubscriptionPack) {
        this.privateOffersSelected = false;

        this.subscriptionPackOrTokenWrapper.subscriptionPacks = this.subscriptionPackOrTokenWrapper.subscriptionPacks
            .filter(subscription => subscription !== subscriptionPack);

        if (this.selectionMode === 'single') {
            this.subscriptionPackOrTokenWrapper.subscriptionPacks =
                [subscriptionPack];
        } else {
            this.subscriptionPackOrTokenWrapper.subscriptionPacks =
                [...this.subscriptionPackOrTokenWrapper.subscriptionPacks, subscriptionPack];
        }

        this.onChange(this.subscriptionPackOrTokenWrapper);
    }

    isSubscriptionPackSelected(subscriptionPack: SubscriptionPack): boolean {
        return !this.privateOffersSelected
            && !!this.subscriptionPackOrTokenWrapper?.subscriptionPacks?.find(subscriptionPackSelected => subscriptionPackSelected._id === subscriptionPack._id);
    }

    onPrivateOfferSelected() {
        this.privateOffersSelected = true;
        this.subscriptionPackOrTokenWrapper.subscriptionPacks = [];
    }

    fetchToken() {
        this.fetchingToken = true;
        this.lastValidatedToken = this.token;
        const registerMode = this.mode === 'register' ? SubscriptionMode.register : SubscriptionMode.client;
        this.fleetTokenHttpService.checkPackToken(this.token, registerMode)
            .pipe(
                finalize(() => this.fetchingToken = false)
            )
            .subscribe(
                packTokenWrapper => {
                    this.tokenValid = true;
                    this.loadPackToken(packTokenWrapper);
                    this.displaySuccessToast();
                },
                err => {
                    this.tokenValid = false;
                    this.displayError(err?.error?.labelKey || this.INVALID_TOKEN_LABEL_KEY);
                }
            );
    }

    onTokenChange(event: string) {
        this.token = event;
        this.tokenValid = null;
        this.clearPackToken();
    }

    registerOnChange(onChange: (_: SubscriptionPackOrTokenWrapper) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    writeValue(subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper): void {
        this.subscriptionPackOrTokenWrapper = subscriptionPackOrTokenWrapper;
    }

    clearToken(): void {
        this.token = null;
        this.clearPackToken();
    }

    clearPackToken(): void {
        this.subscriptionPackOrTokenWrapper.packTokenWrapper = null;
        this.onChange(this.subscriptionPackOrTokenWrapper);
    }

    showTokenError(): boolean {
        return this.tokenValid === false ||
            (this.tokenValid === null &&
                !!this.token &&
                this.token === this.lastValidatedToken &&
                !!this.tokenErrorLabel);
    }

    private displayError(error: string): void {
        this.notificationService.error(this.INVALID_TOKEN_LABEL_KEY);
        this.tokenErrorLabel = this.translateService.instant(error);
    }

    private displaySuccessToast(): void {
        this.tokenErrorLabel = null;
        this.notificationService.success(this.TOKEN_ADDED_LABEL_KEY);
    }

    private loadPackToken(packTokenWrapper) {
        this.subscriptionPackOrTokenWrapper.packTokenWrapper = packTokenWrapper;
        this.onChange(this.subscriptionPackOrTokenWrapper);
    }
}
