import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {
    User,
    ChargeHomeStat,
    FrontChargeRecordHttpService
} from 'lib-front';

@Component({
    selector: 'home-charge-consumptions',
    templateUrl: './home-charge-consumptions.component.html',
    styleUrls: ['./home-charge-consumptions.component.scss'],
    host: {'class': 'cell shrink home-card'}

})
export class HomeChargeConsumptionsComponent {
    public chargeHomeStat: ChargeHomeStat;


    constructor(route: ActivatedRoute, chargeRecordHttpService: FrontChargeRecordHttpService) {
        const today = moment();
        const monthAgo = moment().month(moment().month() - 1);
        route.data.pipe(
            switchMap(() => chargeRecordHttpService.findHomeStat(monthAgo, today)),
        ).subscribe(homeStat => this.chargeHomeStat = homeStat);
    }

}
