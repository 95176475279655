import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    buildRole,
    ChargeAreaDto,
    ChargeAreaLazyCategory,
    ChargeAreaSearchCriteria, CurrentSubscriberRolesService,
    FrontChargeAreaHttpService,
    FrontEndFleetConfig,
    Reporting,
    StationSearchCriteria, SubscriberRoleLevel, SubscriberRoleType,
} from 'lib-front';
import {InactivityService} from '../../../../services/utils/inactivity.service';
import {RepeatService} from '../../../../services/utils/repeat.service';
import {Subscription} from 'rxjs';
import {FrontEndService} from '../../../../services/frontEnd.service';


@Component({
    selector: 'charge-areas',
    templateUrl: './charge-areas.component.html',
    styleUrls: ['./charge-areas.component.scss'],
    host: {'class': ' cell auto scroll-container'}
})
export class ChargeAreasComponent implements OnInit, OnDestroy {
    public isLoadingChargeAreas: boolean;
    public noResult: boolean;
    public Reporting = Reporting;
    public fleetConfig: FrontEndFleetConfig;
    public hasStationWriteRole: boolean;
    chargeAreas: ChargeAreaDto[];
    availableChargeAreas: ChargeAreaDto[];
    initialChargeAreas: ChargeAreaDto[];
    criteria: ChargeAreaSearchCriteria = {
        lazyCategories: [
            ChargeAreaLazyCategory.STATION_INFRA_STATUS_METRICS,
            ChargeAreaLazyCategory.PLUG_TYPE_NAMES
        ]
    };
    stationCriteria: StationSearchCriteria = {};
    inactiveRefreshInterval: Subscription;

    constructor(private readonly chargeAreaService: FrontChargeAreaHttpService,
        private readonly inactivityService: InactivityService,
        private readonly repeatService: RepeatService,
        private readonly frontEndService: FrontEndService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    ngOnInit(): void {
        this.isLoadingChargeAreas = true;
        this.chargeAreaService.fetchChargeAreas(this.criteria).subscribe(chargeAreas => {
            this.chargeAreas = chargeAreas.sort((a, b) => a.name.localeCompare(b.name));
            this.initialChargeAreas = chargeAreas;
            this.availableChargeAreas = this.chargeAreas;
            this.isLoadingChargeAreas = false;
        });

        this.inactivityService.init(
            () => this.setRefreshInterval(),
            ()=> this.clearRefreshInterval()
        );

        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);

        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.WRITE)
        ).subscribe((hasRole) => {
            this.hasStationWriteRole = hasRole;
        });
    }

    ngOnDestroy(): void {
        this.inactivityService.destroy();
        this.clearRefreshInterval();
    }

    filterChargeAreas(criteria) {
        this.criteria = criteria;
        this.setStationCriteria(this.criteria);
        this.chargeAreaService.fetchChargeAreas(this.criteria).subscribe(chargeAreas => {
            if (chargeAreas.length > 0) {
                this.chargeAreas = chargeAreas;
                this.noResult = false;
            } else {
                this.noResult = true;
            }
        });
    }

    setStationCriteria(filterCriteria) {
        const attributs = ['plugs', 'station', 'status'];
        Object.keys(filterCriteria).forEach(criteria => {
            if (!!criteria && attributs.includes(criteria)) {
                this.stationCriteria[criteria] = filterCriteria[criteria];
            }
        });
    }

    trackByChargeAreaId(index: number, chargeAreaDto: ChargeAreaDto) {
        return chargeAreaDto?.id ?? index;
    }

    setRefreshInterval() {
        this.filterChargeAreas(this.criteria);
        this.inactiveRefreshInterval =
            this.repeatService.repeat(() => this.filterChargeAreas(this.criteria), 5 * 60 * 1000);
    }

    clearRefreshInterval() {
        if (this.inactiveRefreshInterval) {
            this.inactiveRefreshInterval.unsubscribe();
        }
    }
}
