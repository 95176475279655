import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';
import {FrontInvoiceBusinessService, Invoice, InvoiceStatusEnum, PaymentType} from 'lib-front';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'fleetInvoiceStatus'
})
export class FleetInvoiceStatusLabelPipe implements PipeTransform {

    constructor(private translateService: TranslateService,
        private invoiceBusinessService: FrontInvoiceBusinessService) {
    }

    transform(invoice: Invoice, ...args: any[]): string {
        if (!invoice || !invoice.currentStatus) {
            return '';
        } else if (invoice.currentStatus.status === InvoiceStatusEnum.PAYMENT_ISSUE) {
            return this.getPaymentErrorLabel(invoice);
        } else if (this.invoiceBusinessService.invoiceWillBePaidByCard(invoice)) {
            return this.translateService.instant('invoice.status.cb',
                {date: formatDate(invoice.paymentContext.estimatedPaymentDate, 'shortDate', 'fr'),
                    cbName: this.getConfigName(invoice)});
        } else if (this.invoiceBusinessService.invoiceWillBePaidByMandate(invoice)) {
            return this.translateService.instant('invoice.status.mandat',
                {date: formatDate(invoice.paymentContext.estimatedPaymentDate, 'shortDate', 'fr'),
                    mandatName: this.getConfigName(invoice)});
        } else if (this.invoiceBusinessService.invoiceWillBeRefundedByMandate(invoice)) {
            return this.translateService.instant('invoice.status.mandat_refund',
                {date: formatDate(invoice.paymentContext.estimatedPaymentDate, 'shortDate', 'fr'),
                    mandatName: this.getConfigName(invoice)});
        } else {
            return this.translateService.instant('invoiceStatus.' + invoice.currentStatus.status);
        }
    }

    private getConfigName(invoice: Invoice): string {
        return !!invoice && !invoice.paymentContext.paymentConfigName ? '' : invoice.paymentContext.paymentConfigName;
    }

    private getPaymentErrorLabel(invoice): string {
        let errorLabel;
        if (invoice.paymentContext.errorCode) {
            if (invoice.paymentContext.paymentType === PaymentType.SEPA) {
                const errorKey = 'SEPA.error.' + invoice.paymentContext.errorCode;
                errorLabel = this.translateService.instant(errorKey);
                if (!errorLabel || errorLabel === errorKey) {
                    errorLabel = this.translateService.instant('SEPA.error.default');
                }
            } else {
                const errorKey = 'CB.error.' + invoice.paymentContext.errorCode;
                errorLabel = this.translateService.instant(errorKey);
                if (!errorLabel || errorLabel === errorKey) {
                    errorLabel = this.translateService.instant('CB.error.technical');
                }
            }
        }
        return this.translateService.instant('invoiceStatus.' + invoice.currentStatus.status) + (errorLabel ? ' (' + errorLabel + ')' : '');
    }
}
