import {Component, Input, OnInit} from '@angular/core';
import {buildRole, CurrentSubscriberRolesService, FOAccountWrapper, FrontFoAccountHttpService, SubscriberRoleLevel, SubscriberRoleType} from 'lib-front';
import {NotificationService} from '../../../../../services/utils/notification.service';

@Component({
    selector: 'permission-configuration',
    templateUrl: './permission-configuration.component.html',
    styleUrls: ['./permission-configuration.component.scss'],
})
export class PermissionConfigurationComponent implements OnInit {

    @Input() public filter: string = undefined;

    public foAccount: FOAccountWrapper;
    public hasSubscriberRolesWriteRole: boolean;
    public hasSubscriberRolesReadRole: boolean;

    public constructor(private readonly foAccountHttpService: FrontFoAccountHttpService,
        private readonly notificationService: NotificationService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    ngOnInit(): void {
        this.foAccountHttpService.findFOAccount().subscribe(value => {
            this.foAccount = value;
        });

        this.currentSubscriberRolesService.hasRole(buildRole(SubscriberRoleType.SUBSCRIBER_ROLES, SubscriberRoleLevel.WRITE))
            .subscribe(hasRole => this.hasSubscriberRolesWriteRole = hasRole);

        this.currentSubscriberRolesService.hasRole(buildRole(SubscriberRoleType.SUBSCRIBER_ROLES, SubscriberRoleLevel.READ))
            .subscribe(hasRole => this.hasSubscriberRolesReadRole = hasRole);
    }

    public updateFOAccount(): void {
        this.foAccountHttpService.foAccountSave(this.foAccount).subscribe(() => {
            this.notificationService.success('config.saved');
        });
    }
}
