import {
    AfterViewInit,
    Component,
    forwardRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    CityDto,
    CountryDto,
    FrontReferenceHttpService,
    FrontSuggestionHttpService,
    LocationPipe,
    PostalCodeDto
} from 'lib-front';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {AddressDirective} from '../address.directive';


export type AddressFormInputElem = 'address'|'postalCode'|'city';

@Component({
    selector: 'manual-address-form[address]',
    templateUrl: './manual-address-form.component.html',
    styleUrls: ['./manual-address-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ManualAddressFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ManualAddressFormComponent),
            multi: true
        }
    ]
})
export class ManualAddressFormComponent extends AddressDirective implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('addressForm') formAddress: NgForm;

    private inputFocusByAddressFormInputElem: Map<AddressFormInputElem, boolean> = new Map([['address', false], ['postalCode', false], ['city', false]]);
    constructor(
        protected readonly suggestionService: FrontSuggestionHttpService,
        protected readonly referenceService: FrontReferenceHttpService,
        protected readonly locationPipe: LocationPipe) {
        super(suggestionService, referenceService, locationPipe);
        this.SUGGESTION_LIMIT = 4;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        this.formAddress.statusChanges
            .pipe(debounceTime(1000))
            .subscribe(() => {
                this.formAddressDirtyChange.emit(this.formAddress.dirty);
                this.formAddressValidChange.emit(!this.formAddress.invalid);
            });
    }

    removeRouteAndClearAddressSuggestions() {
        this.address.route = null;
        this.clearAddressSuggestions();
    }

    clearPostalCodeAndPostalCodeSuggestions() {
        this.address.postalCode = null;
        this.clearPostalCodeSuggestions();
    }

    onPostalCodeClear() {
        this.clearCityAndCitySuggestions();
        this.clearPostalCodeSuggestions();
    }

    clearCityAndCitySuggestions() {
        this.address.city = null;
        this.clearCitySuggestions();
    }

    clearPostalCodeSuggestions() {
        this.postalCodeSuggestions = [];
    }

    clearCitySuggestions() {
        this.citySuggestions = [];
    }

    pushNewPostalCodeAndClearCity(event: {term: string, items: any[]}) {
        this.address.city = null;
        this.pushNewPostalCode(event.term);
    }

    pushNewPostalCode(postalCode :string) {
        this.address.postalCode = postalCode;
        //Postal code suggestion can only be used for France (db only contains French postal code)
        if (this.address.countryCode === 'FR') {
            this.postalCodeSuggestionSubject.next(this.address.postalCode);
        }
    }

    pushNewCity(event: {term: string, items: any[]}) {
        this.address.city = event.term;
        //City suggestion can only be used for France (db only contains French cities)
        if (this.address.countryCode === 'FR') {
            this.citySuggestionSubject.next({city: this.address.city, postalCode: this.address.postalCode});
        }
    }

    validPostalCodeSuggestion(postalCode: string) {
        this.address.postalCode = postalCode;
        this.clearPostalCodeSuggestions();
        this.referenceService.findCities(this.address.city, this.address.postalCode, this.SUGGESTION_LIMIT).subscribe(cities => {
            if (cities.length == 1) {
                this.clearCitySuggestions();
                this.address.city = cities[0].name;
            } else {
                this.citySuggestions = cities;
            }
        });
    }

    validCitySuggestion(cityName: string) {
        this.address.city = cityName;
        this.clearCitySuggestions();
    }

    focusOn(elem: AddressFormInputElem) {
        this.inputFocusByAddressFormInputElem.set(elem, true);
    }

    focusOut(elem: AddressFormInputElem) {
        this.inputFocusByAddressFormInputElem.set(elem, false);
    }

    shouldShowAddressSuggestions(): boolean {
        return this.inputFocusByAddressFormInputElem.get('address') && this.addressSuggestions.length > 0;
    }

    shouldShowPostalCodeSuggestions(): boolean {
        return this.inputFocusByAddressFormInputElem.get('postalCode')
            && this.postalCodeSuggestions.length > 0
            && this.address.countryCode === 'FR';
    }

    shouldShowCitySuggestions(): boolean {
        return this.inputFocusByAddressFormInputElem.get('city')
            && this.citySuggestions.length > 0
            && this.address.countryCode === 'FR';
    }
    trackByPostalCode(index: number, postalCodeSuggestion: PostalCodeDto) {
        return postalCodeSuggestion?.postalCode ?? index;
    }

    trackByCityName(index: number, citySuggestion: CityDto) {
        return citySuggestion?.name ?? index;
    }

    trackByCountryCode(index: number, country: CountryDto) {
        return country?.code ?? index;
    }

    onCountryChange() {
        this.clearPostalCodeAndPostalCodeSuggestions();
        this.clearCityAndCitySuggestions();
        this.updateCountryFromCountryCode(this._address);
    }

    // By default (a, b) => a === b but if it returns
    // true you can't select the option.
    compareAlwaysFalse(a, b) {
        return false;
    }
}

