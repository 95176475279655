import {Component, forwardRef} from '@angular/core';
import {OpeningDayDto, OpeningType} from 'lib-front';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'opening-day-edit',
    templateUrl: './opening-day-edit.component.html',
    styleUrls: ['./opening-day-edit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OpeningDayEditComponent),
            multi: true
        }
    ]
})
export class OpeningDayEditComponent implements ControlValueAccessor {
    private onTouch: () => void;
    public onChange: (opening: OpeningDayDto) => void;
    public openingDay: OpeningDayDto;
    public disabled: boolean;
    public OpeningType = OpeningType;


    constructor() {
    }

    writeValue(obj: OpeningDayDto): void {
        this.openingDay = obj;
    }

    registerOnChange(fn: (opening: OpeningDayDto) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    openingChanges(): void {
        if (this.openingDay.open && !this.openingDay.openingType) {
            this.openingDay.openingType = OpeningType.OPEN_2424;
        }
        this.onChange(this.openingDay);
    }
}
