<div class="passwordContainer">
    <input class="cell"
           #pwd="ngModel"
           [type]="showPassword ? 'text' : 'password'"
           [(ngModel)]="password"
           (ngModelChange)="onChange($event)"
           [required]="required"
           [autocomplete]="autocomplete"
    >
    <button class="button round medium secondary force-space-left-8"
            [hidden]="showPassword"
            type="button"
            (click)="toggleShowPassword()">
            <i class="fa fa-eye"></i>
    </button>

    <button class="button round medium color force-space-left-8"
            [hidden]="!showPassword"
            type="button"
            (click)="toggleShowPassword()">
            <i class="fa fa-eye-slash"></i>
    </button>
</div>
