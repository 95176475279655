import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {CurrentSubscriberRolesService} from 'lib-front';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CurrentSubscriberRolesGuard implements CanActivate {
    constructor(private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.currentSubscriberRolesService.getCurrentSubscriberRoles()
            .pipe(
                map(_ => true)
            );
    }
}
