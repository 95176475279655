<div class="grid-y cell auto indemnity" [class.selected]="selected">
    <div class="grid-x">
        <h4 class="cell auto indemnity-name">
            {{'config.indemnity.name' | translate:{name: indemnity.name} }}
        </h4>
    </div>

    <ng-template #createOn class="cell">
        <p class="indemnity-date">
            {{'config.tariff.createOn' | translate:{date: indemnity.createDate | date : "shortDate"} }}
        </p>
    </ng-template>

    <div *ngIf="indemnityWasUpdated(indemnity) else createOn">
        <p class="cell indemnity-date">{{'config.tariff.updateOn' | translate:{
            date: indemnity.lastEditDate | date :
                "shortDate"
        } }}</p>
    </div>

    <div class="grid-x align-right">
        <a class="moreDetail indemnity-detail"
           [class.selected]="selected">{{'config.indemnity.moreDetails' | translate}}</a>
    </div>
</div>
