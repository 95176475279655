import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import dayjs, {Dayjs} from 'dayjs';

@Pipe({
    name: 'toDayjs'
})
export class ToDayjsPipe implements PipeTransform {

    constructor() {
    }

    transform(date: string | Dayjs | moment.Moment): Dayjs {
        if (!date) {
            return undefined;
        } else if (dayjs.isDayjs(date)) {
            return date;
        } else if (moment.isMoment(date)) {
            return dayjs(date.toISOString());
        } else {
            return dayjs(date);
        }
    }
}

