import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Location} from 'lib-front';
import {LocationType} from '../../pipes/fleetLocation.pipe';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {NotificationService} from '../../services/utils/notification.service';

@Component({
    selector: 'charge-area-geolocation-edit',
    templateUrl: './charge-area-geolocation-edit.component.html',
    styleUrls: ['./charge-area-geolocation-edit.component.scss']
})
export class ChargeAreaGeolocationEditComponent {
    @Input() public hiddenMap: boolean = false;
    @Input()
    get geolocation(): Location {
        return this._geolocation;
    }

    set geolocation(value: Location) {
        if (!!value) {
            this._geolocation = value;
            this.setCoordinates(value);
        }
    }

    @Output() geolocationChange: EventEmitter<Location> = new EventEmitter();

    LocationType = LocationType;
    coordinates: { lat: number, lng: number };
    mapMarkerOptions: google.maps.MarkerOptions = {
        draggable: true
    };

    private _geolocation: Location;

    constructor(private readonly notificationService: NotificationService) {
    }

    handleAddressChange(address: Address): void {
        const addressComponentsLength = address.address_components.length;
        if (addressComponentsLength < 6) {
            this.notificationService.error('chargeAreas.edit.location.notPrecisionEnough');
        } else {
            this.geolocation = Object.assign(this.geolocation, {
                streetNumber: addressComponentsLength > 6 ?
                    address.address_components[addressComponentsLength - 7].long_name : '',
                route: address.address_components[addressComponentsLength - 6].long_name,
                postalCode: address.address_components[addressComponentsLength - 1].long_name,
                city: address.address_components[addressComponentsLength - 5].long_name,
                province: address.address_components[addressComponentsLength - 4].long_name,
                country: address.address_components[addressComponentsLength - 2].long_name,
                countryCode: address.address_components[addressComponentsLength - 2].short_name,
                coordinates: [address.geometry.location.lng(), address.geometry.location.lat()],
            });
            this.geolocationChange.emit(this.geolocation);
        }
    }

    updateGeolocationCoordinates(event: google.maps.MapMouseEvent): void {
        this._geolocation.coordinates = [event.latLng.lng(), event.latLng.lat()];
        this.setCoordinates(this._geolocation);
        this.geolocationChange.emit(this._geolocation);
    }

    private setCoordinates(value: Location) {
        this.coordinates = {
            lat: value.coordinates[1],
            lng: value.coordinates[0]
        };
    }
}
