import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    buildRole,
    CurrentSubscriberRolesService,
    CustomerAccount,
    FOAccountWrapper, FrontEndInfo,
    FrontFoAccountHttpService,
    FrontMediaHttpService,
    FrontMediaOrderHttpService,
    FrontPackSubscriptionHttpService,
    FrontPersonHttpService,
    MediaCanOrderDto,
    MediaOrderContext,
    PaymentConf,
    PersonAddress,
    SubscribeAnswerDto, SubscriberRoleLevel, SubscriberRoleType,
    SubscriptionRequestDto, switchTap,
    User
} from 'lib-front';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {MediaOrderDto} from '../../../../../domain/mediaOrderDto';
import {Observable, of, Subscription} from 'rxjs';
import {cloneDeep} from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../../../services/utils/alert.service';
import {AbstractMediaOrderDeliveryChangeWorkflowComponent} from '../../../../../components/abstract-media-order-delivery-change-workflow.component';
import {CurrentUserContextService} from '../../../../../services/business/currentUserContext.service';
import {ModeAddressForm} from '../../../../../components/address-form/modeAddressForm';
import {FrontEndService} from '../../../../../services/frontEnd.service';

@Component({
    selector: 'main-media-order',
    templateUrl: './main-media-order.component.html',
    styleUrls: ['./main-media-order.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class MainMediaOrderComponent extends AbstractMediaOrderDeliveryChangeWorkflowComponent implements OnInit, OnDestroy {
    ModeAddressForm = ModeAddressForm;
    frontEndInfo: FrontEndInfo;
    private user: User;
    private paymentConfs: PaymentConf[];

    public orderForm: MediaOrderDto;
    public foAccount: FOAccountWrapper;
    public isValidAddress: boolean;
    public mediaCanOrderDtoList: MediaCanOrderDto[];
    public readonly mediaOrderContext: MediaOrderContext = MediaOrderContext.ACCOUNT;
    private initialFoAccountDelivery: PersonAddress;
    private hasInvoiceReadRole: boolean;
    private frontEndInfoSubscription: Subscription;

    constructor(private readonly mediaHttpService: FrontMediaHttpService,
        protected readonly mediaOrderHttpService: FrontMediaOrderHttpService,
        protected readonly foAccountHttpService: FrontFoAccountHttpService,
        protected readonly notificationService: NotificationService,
        private readonly router: Router,
        private readonly packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private readonly personHttpService: FrontPersonHttpService,
        protected readonly alertService: AlertService,
        protected readonly translateService: TranslateService,
        readonly packFOAccountHttpService: FrontFoAccountHttpService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        readonly route: ActivatedRoute,
        private readonly frontEndService: FrontEndService) {

        super(notificationService, mediaOrderHttpService, alertService, translateService);
        this.paymentConfs = [];
        route.data.subscribe(data => {
            this.user = data.user.user;
            packFOAccountHttpService.findFOAccount().subscribe(value => {
                this.initializeFOAccount(value);
            });
            this.loadPaymentConfs();
            this.mediaHttpService.fetchOrderable(this.mediaOrderContext).subscribe(mediaCanOrderDtoList => {
                this.mediaCanOrderDtoList = mediaCanOrderDtoList;
                this.orderForm = new MediaOrderDto();
                this.orderForm.mediaCanOrderDto = mediaCanOrderDtoList[0];
            });
        });
    }

    public ngOnInit() {
        this.frontEndInfoSubscription = this.frontEndService.currentFrontEndInfo$.subscribe((frontEndInfo: FrontEndInfo) => this.frontEndInfo = frontEndInfo);
    }

    ngOnDestroy() {
        this.frontEndInfoSubscription.unsubscribe();
    }

    public initializeFOAccount(foAccount) {
        this.foAccount = foAccount;
        this.initialFoAccountDelivery = this.foAccount.delivery ? cloneDeep(this.foAccount.delivery) : null;
        if (!this.foAccount.delivery) {
            this.foAccount.delivery.firstName = this.user.firstName;
            this.foAccount.delivery.lastName = this.user.lastName;
            this.foAccount.delivery.email = this.user.email;
            this.foAccount.delivery.phoneNumber = this.user.phoneNumber;
            this.foAccount.delivery.address = {};
            this.foAccount.delivery.address = this.user.address;
        }
    }

    public commandPass(): void {
        this.orderingMedia = true;
        this.foAccountHttpService.foAccountSave(this.foAccount)
            .subscribe(
                () => this.checkDeliveryChanged(this.foAccount, this.initialFoAccountDelivery),
                (error) => {
                    let errorKey = error.error.labelKey ? error.error.labelKey : 'config.error';
                    this.notificationService.error(errorKey);
                    this.orderingMedia = false;
                }
            );
    }

    protected mediaOrderDeliveryCommonCallback(foAccount: FOAccountWrapper): void {
        let promoCodeObservable: Observable<SubscribeAnswerDto> = of(null);

        if(this.orderForm.token){

            const subscriptionRequest: SubscriptionRequestDto = new SubscriptionRequestDto();
            subscriptionRequest.personAddress = this.user.address;
            subscriptionRequest.packSubscriptionRequest = {additionalInformations: {}};
            subscriptionRequest.promoToken = this.orderForm.token;

            promoCodeObservable =  this.packSubscriptionHttpService.subscribe(subscriptionRequest);
        }

        if (this.orderForm.mediaCanOrderDto.mediaOrderSubscriptionPackDtos.some(pack => !pack.mediaFamilyConfiguration.activatedOnCustomerAccount)) {
            this.alertService.confirm(
                this.translateService.instant('media.order.notActionable.message'),
                this.translateService.instant('media.order.notActionable.title'),
                this.translateService.instant('media.order.notActionable.validate'),
                this.translateService.instant('media.order.notActionable.cancel')
            ).subscribe(response => {
                if (response) {
                    this.createMediaOrder(promoCodeObservable);
                } else {
                    this.orderingMedia = false;
                }
            });
        } else {
            this.createMediaOrder(promoCodeObservable);
        }
    }

    private createMediaOrder(promoCodeObservable: Observable<SubscribeAnswerDto>): void {
        promoCodeObservable.pipe(
            map(subscriptionAnswer => {
                if(subscriptionAnswer){
                    return subscriptionAnswer.packSubscriptionRefs[0];
                } else {
                    const subscriptionPackRef = this.orderForm.emoMediaOrderPrice.subscriptionPackRef;
                    return this.orderForm.mediaCanOrderDto.mediaOrderSubscriptionPackDtos
                        .find(subscriptionPack => subscriptionPack.id === subscriptionPackRef)?.packSubscriptionRef;
                }
            }),
            switchMap(packSubscriptionRef => this.mediaHttpService.createMediaOrder(
                packSubscriptionRef,
                {
                    quantity: this.orderForm.quantity,
                    mediaFamilyRef: this.orderForm.mediaCanOrderDto.mediaFamily.id
                })
            ),
            switchTap(_ => this.currentSubscriberRolesService
                .hasRole(buildRole(SubscriberRoleType.INVOICES, SubscriberRoleLevel.READ))
                .pipe(
                    tap(hasRole => this.hasInvoiceReadRole = hasRole)
                )
            ),
            finalize(() => this.orderingMedia = false)
        ).subscribe({
            next: value => this.paymentConfs.length === 0 && this.hasInvoiceReadRole ?
                this.router.navigate(['/main/invoices/' + value.invoiceRef]).then() :
                this.router.navigate(['/main/media']).then(),
            error: () => this.notificationService.error('media.order.error')
        });
    }

    public isQuantityInvalid(): boolean {
        return !this.orderForm || !this.orderForm.quantity || Number(this.orderForm.quantity) === 0;

    }

    private loadPaymentConfs(): void {
        if (this.user) {
            this.personHttpService.findDetailedPerson(this.currentUserContextService.getCurrentPersonId())
                .subscribe(person => this.paymentConfAvailable(person.customerAccounts));
        }
    }

    private paymentConfAvailable(customerAccounts: CustomerAccount[]): void {
        customerAccounts.forEach((customerAccount) => {
            customerAccount.paymentConfs.forEach((paymentConf) => {
                if (!paymentConf.disabled && (paymentConf.paymentType === 'CREDIT_CARD_AUTHORIZATION' ||
                    paymentConf.paymentType === 'SEPA' || paymentConf.paymentType === 'CREDIT_CARD_RECURRING')) {
                    this.paymentConfs.push(paymentConf);
                }
            });
        });
    }

    public onFormAddressChange(isValid: boolean) {
        this.isValidAddress = isValid;
    }

    protected readonly MediaOrderContext = MediaOrderContext;
}
