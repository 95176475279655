import {Routes} from '@angular/router';
import {ColorsGuideComponent} from './colors-guide/colors-guide.component';
import {ButtonsGuideComponent} from './buttons-guide/buttons-guide.component';
import {IconsGuideComponent} from './icons-guide/icons-guide.component';
import {TypographyGuideComponent} from './typography-guide/typography-guide.component';
import {FormsGuideComponent} from './forms-guide/forms-guide.component';
import {DashboardGuideComponent} from './dashboard-guide/dashboard-guide.component';
import {StyleGuideComponent} from './style-guide.component';
import {MessagesGuideComponent} from './messages-guide/messages-guide.component';
import {TableGuideComponent } from './table-guide/table-guide.component';
import {StatusGuideComponent } from './status-guide/status-guide.component';
import {BlocksGuideComponent} from './blocks-guide/blocks-guide.component';

export const styleGuideRoutes: Routes = [
    {
        path: '',
        component: StyleGuideComponent,
        children: [
            {path: 'dashboard', component: DashboardGuideComponent},
            {path: 'colors', component: ColorsGuideComponent},
            {path: 'typography', component: TypographyGuideComponent},
            {path: 'icons', component: IconsGuideComponent},
            {path: 'buttons', component: ButtonsGuideComponent},
            {path: 'forms', component: FormsGuideComponent},
            {path: 'messages', component: MessagesGuideComponent},
            {path: 'tables', component: TableGuideComponent},
            {path: 'status', component: StatusGuideComponent},
            {path: 'blocks', component: BlocksGuideComponent},
            {path: '**', redirectTo: 'dashboard'}
        ]
    },
    {path: '**', redirectTo: 'dashboard'}
];
