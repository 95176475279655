<div class="cell auto app-view-content">
    <div class="has-space" *ngIf="isStationManager && !loading; else chargeFleet">
        <tabs-round [hideHeads]="!isStationManager || !!fleetConfig?.managerCreationOnly">
            <tab-round [hidden]="!isStationManager" [title]="'chargeConsumptions.myNetworkCharges' | translate" tab-section>
                <charge-tab [fleetConfig]="fleetConfig"
                            [chargeRecords]="stationChargeRecordResults.chargeRecords"
                            [statsResult]="stationChargeRecordResults.stats"
                            [chargeStatus]="stationChargeStatus"
                            [mode]="modes.STATION"
                            [searchChargeSubscription]="searchChargeSubscription"
                            [searchStatsSubscription]="searchStatsSubscription"
                            (searchChargeRecords)="searchCharges($event)"
                            (searchStats)="searchStats($event)">
                </charge-tab>
            </tab-round>
            <tab-round  *ngIf="!fleetConfig?.managerCreationOnly" [title]="'consumptions.myCollaboratorConsumptions' | translate" tab-section>
                <consumption-tab [fleetConfig]="fleetConfig"></consumption-tab>
            </tab-round>
        </tabs-round>
    </div>

    <ng-template #chargeFleet>
        <div *ngIf="!loading else spinner">
            <consumption-tab [fleetConfig]="fleetConfig"></consumption-tab>
        </div>
    </ng-template>

    <ng-template #spinner>
        <div class="app-loader small text-center">
            <span class="spinner small"></span>
        </div>
    </ng-template>
</div>
