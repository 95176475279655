import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ReportingService} from '../../../../services/business/reporting.service';
import {map} from 'rxjs/operators';

@Injectable()
export class ReportingPanelGuard implements CanActivate {

    constructor(private reportingService: ReportingService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.reportingService.activeReportingTabs()
            .pipe(
                map(tabs => tabs.map(tab => tab.path)),
                map(paths => paths.filter(tab => tab.endsWith(route.params.reportingType)).length > 0)
            );
    }

}
