<div class="grid-x cell space-bottom-8 space-top-16 align-right align-middle action-bar space-top-8 space-bottom-8">
    <div class="cell shrink margin-8 space-right-8">
        <ng-container *ngIf="station.infraStatus !== InfraStatus.SERVICING; else stopServicing">
            <button type="button" class="button secondary color" (click)="sendAction(UserRemoteAction.CHANGE_AVAILABILITY)"
                    [iziviaLoading]="waitingForAction">
                <i class="fas fa-tools space-right-8"></i> {{'stations.edit.startServicing' | translate}}
            </button>
        </ng-container>
    </div>
<!--    https://issues.4sh.fr/browse/SMBP-11012 -->
<!--    Should be uncomment when specs are ready -->
<!--    <div class="cell shrink margin-8">-->
<!--        <button type="button" class="button secondary color" (click)="incidentReportPopup.open()" [disabled]="waitingForAction">-->
<!--            <i class="fas fa-exclamation-triangle space-right-8"></i> {{'stations.edit.incident' | translate}}-->
<!--        </button>-->
<!--    </div>-->
    <div class="cell shrink space-left-8">
        <button type="button" class="button primary" (click)="sendAction(UserRemoteAction.RESET)"
                [iziviaLoading]="waitingForAction">
            <i class="fas fa-redo-alt space-right-8"></i> {{'stations.edit.restart' | translate}}
        </button>
    </div>
</div>
<ng-template #stopServicing>
    <button type="button" class="button secondary color" (click)="sendAction(UserRemoteAction.CHANGE_AVAILABILITY)"
            [iziviaLoading]="waitingForAction">
        <i class="fas fa-tools space-right-8"></i> {{'stations.edit.stopServicing' | translate}}
    </button>
</ng-template>

<izivia-popup #incidentReportPopup [size]="'unset'">
<station-report-incident-popup [station]="station"
                               (modalConfirm)="createIncident($event)"
                               (modalClose)="incidentReportPopup.close()"></station-report-incident-popup>
</izivia-popup>

