import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SubscriberRoleLevel} from 'lib-front';

@Component({
    selector: 'role-select',
    templateUrl: './role-select.component.html',
    styleUrls: ['./role-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RoleSelectComponent),
            multi: true
        }
    ]
})
export class RoleSelectComponent implements ControlValueAccessor {

    private NONE_OPTION = {class: 'fas fa-eye-slash', value: 'NONE', order: 3};

    availableIconOptions = [
        {class: 'fas fa-pen', value: SubscriberRoleLevel.WRITE, order: 1},
        {class: 'fas fa-eye', value: SubscriberRoleLevel.READ, order: 2},
        this.NONE_OPTION
    ];

    get roleLevels(): SubscriberRoleLevel[] {
        return this._roleLevels;
    }

    @Input()
    set roleLevels(levels: SubscriberRoleLevel[]) {
        levels.forEach(level => this.iconOptions.push(this.availableIconOptions.find(option => option.value === level)));
        this.iconOptions.sort((optionA, optionB) => (optionA.order < optionB.order ? -1 : 1));
    }

    public disabled: boolean;
    public icon: string;


    private _roleLevels: SubscriberRoleLevel[];


    public iconOptions = [this.NONE_OPTION];

    private onNgChange: (value: string) => void;
    private onNgTouched: () => void;
    @Input() public defaultValue: string;
    @Input() public required: boolean;

    public constructor() {
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(icon: string): void {
        this.icon = icon;
        if (!this.icon) {
            this.icon = this.defaultValue;
        }
    }

    public onChange(icon): void {
        this.icon = icon;
        this.onNgChange(icon);
    }

    onOpen(): void {
        this.onNgTouched();
    }

    onClose(): void {
        this.onNgTouched();
    }
}
