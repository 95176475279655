import {NgModule} from '@angular/core';
import {ImportsComponent} from './imports.component';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../../../components/components.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '../../../../pipes/pipes.module';
import {DirectivesModule} from '../../../../directives/directives.module';

@NgModule({
    declarations: [
        ImportsComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        TranslateModule,
        FormsModule,
        PipesModule,
        DirectivesModule
    ],
    providers: []
})
export class ImportsModule {

}
