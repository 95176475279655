import {Directive} from '@angular/core';
import {NotificationService} from '../../services/utils/notification.service';

@Directive()
export abstract class AbstractHasRoleActionComponent {

    protected constructor(protected notificationService: NotificationService) {
    }


    doActionIfHasRole(actionCallback: () => void, hasRole: boolean): void {
        if(hasRole) {
            actionCallback();
        } else {
            this.notificationService.error('config.permission.alertNoSufficientRole');
        }
    }
}
