import {Pipe, PipeTransform} from '@angular/core';
import {
    isMetaChargeConsumption, isMetaOtpConsumption,
    isMetaReservationConsumption, isMetaServiceConsumption,
    MetaConsumptionDto
} from '../domain/meta/metaConsumptionDto';

@Pipe({
    name: 'fleetMetaConsumptionLicenseNumber'
})
export class FleetMetaConsumptionLicenseNumberPipe implements PipeTransform {

    constructor() {}

    transform(metaConsumption: MetaConsumptionDto): string {
        if (isMetaChargeConsumption(metaConsumption)
            || isMetaReservationConsumption(metaConsumption)
            || isMetaOtpConsumption(metaConsumption)
            || isMetaServiceConsumption(metaConsumption)) {
            return metaConsumption.licenseNumber || '';
        } else {
            return '';
        }
    }
}
