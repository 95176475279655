import {Component, Input} from '@angular/core';
import {InfraStatus, StationDto, Location} from 'lib-front';
import {LocationType} from '../../pipes/fleetLocation.pipe';

@Component({
    selector: 'station-card',
    templateUrl: './station-card.component.html',
    styleUrls: ['./station-card.component.scss']
})
export class StationCardComponent {
    LocationType = LocationType;
    @Input() station: StationDto;
    @Input() location: Location;
    @Input() selected: boolean;

    constructor() {
    }


}
