<div class="home-component">
    <h2 class="grid-x home-card-title">
        {{'home.chargeConsumptions' | translate}}
    </h2>

    <div class="home-card-content">
        <div class="grid-y align-center home-content-center">
            <indicator class="cell shrink sm-block color" [img]="'chargeNow'">
                <ng-container value>{{chargeHomeStat?.now}}
                </ng-container>
                <ng-container label>{{'chargeConsumptions.now' | translate}}</ng-container>
            </indicator>
            <indicator class="cell shrink sm-block color" [img]="'chargeLastWeek'">
                <ng-container value>{{chargeHomeStat?.lastWeekDuration | fleetDuration:{
                    seconds: false,
                    minutes: false,
                    report: true,
                    entryUnit: 'ms'
                } }}
                </ng-container>
                <ng-container label>{{'chargeConsumptions.lastWeek' | translate}}</ng-container>
            </indicator>
            <indicator class="cell shrink sm-block color" [img]="'chargeLastMonth'">
                <ng-container value>{{chargeHomeStat?.lastMonthDuration | fleetDuration:{
                    seconds: false,
                    minutes: false,
                    report: true,
                    entryUnit: 'ms'
                } }}
                </ng-container>
                <ng-container label>{{'chargeConsumptions.lastMonth' | translate}}</ng-container>
            </indicator>
        </div>
        <div class="grid-x align-right align-middle moreDetail">
            <a>{{'home.details' |translate}}</a>
            <i class="moreDetail-arrow fas fa-arrow-right"></i>
        </div>
    </div>
</div>
