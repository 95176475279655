import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CurrentSubscriberRolesService} from 'lib-front';
import {SubscriberRoleLevel, SubscriberRoleType, buildRole} from 'lib-front';
import {Injectable} from '@angular/core';

@Injectable()
export class ChargeReadRoleGuard implements CanActivate {

    constructor(private currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGES, SubscriberRoleLevel.READ)
        );
    }

}
