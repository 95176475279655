<div class="grid-y cell auto force-space-bottom-0 has-space">
    <form class="grid-x cell shrink align-justify align-bottom grid-padding-x space-bottom-8 filter-bloc">
        <label class="cell small-24 xlarge-8 xxlarge-5 search-label with-label force-inner-space-left-0"
               [class.validate]="criteria.query">
            <span>{{'consumptions.search.label' | translate}}</span>
            <input type="text" [placeholder]="'consumptions.criteria.query' | translate"
                   [(ngModel)]="criteria.query"
                   name="q" class="search">
            <i class="fas fa-search search-icon"></i>
        </label>

        <label class="cell auto"
               [class.validate]="criteria.from">
            <span>{{'consumptions.criteria.from' | translate}}</span>
            <date-picker class="full-input" [(ngModel)]="criteria.from" name="fromDate"></date-picker>
        </label>

        <label class="cell auto"
               [class.validate]="criteria.to">
            <span>{{'consumptions.criteria.to' | translate}}</span>
            <date-picker class="full-input" [(ngModel)]="criteria.to" name="toDate"></date-picker>
        </label>

        <label class="cell auto"
               [class.validate]="criteria.minPrice">
            <span>{{'consumptions.criteria.minPrice' | translate}}</span>
            <input type="number"
                   [placeholder]="'consumptions.criteria.minPrice' | translate"
                   [(ngModel)]="criteria.minPrice"
                   (keydown)="validateNumberInput($event)"
                   name="minPrice"
                   class="search">
        </label>

        <label class="cell auto"
               [class.validate]="criteria.maxPrice">
            <span>{{'consumptions.criteria.maxPrice' | translate}}</span>
            <input type="number"
                   step="1"
                   [placeholder]="'consumptions.criteria.maxPrice' | translate"
                   [(ngModel)]="criteria.maxPrice"
                   (keydown)="validateNumberInput($event)"
                   name="maxPrice"
                   class="search">
        </label>

        <label class="cell auto"
               [class.validate]="criteria.kind">
            <span>{{'consumptions.criteria.kind' | translate}}</span>
            <select [(ngModel)]="criteria.kind"
                    name="kind">
                <option [ngValue]="null">{{'consumptions.kind.all' | translate}}</option>
                <option [value]="consumptionKind.value" *ngFor="let consumptionKind of ConsumptionKind | keyvalue">
                    {{'consumptions.kind.' + consumptionKind.value | translate}}
                </option>
            </select>
        </label>

        <label class="cell auto"
               [class.validate]="criteria.status">
            <span>{{'consumptions.criteria.status' | translate}}</span>
            <select [(ngModel)]="criteria.status"
                    name="status">
                <option [ngValue]="null">{{'consumptionStatus.all' | translate}}</option>
                <option [value]="consumptionStatus" *ngFor="let consumptionStatus of consumptionStatuses">
                    {{'consumptionStatus.' + consumptionStatus | translate}}
                </option>
            </select>
        </label>

        <button type="submit" class="cell small-24 medium-shrink button primary responsiveButton" (click)="search(criteria)"
                [iziviaLoading]="isSearchingConsumptions">
            {{'consumptions.search.button' | translate}}
        </button>
    </form>

    <div class="grid-x grid-padding-x align-center space-bottom-8">
        <div class="cell auto no-padding-left">
            <div class="grid-y grid-padding-y cell indicators-bloc">
                <span class="label-title">{{'consumptions.kpi.consumptions.title' | translate}}</span>
                <div class="grid-x">
                    <indicator class="cell auto color" [img]="'charge'">
                        <ng-container *ngIf="!isSearchingKpis else spinnerStats" value>
                            {{consumptionsKpi?.nbOfConsumptions | number}}
                        </ng-container>

                        <ng-container *ngIf="!isSearchingKpis && consumptionsKpi" label [ngPlural]="consumptionsKpi.nbOfConsumptions">
                            <ng-template ngPluralCase="=0">
                                {{ 'consumptions.kpi.consumptions.0' | translate}}
                            </ng-template>
                            <ng-template ngPluralCase="=1">
                                {{ 'consumptions.kpi.consumptions.1' | translate}}
                            </ng-template>
                            <ng-template ngPluralCase="other">
                                {{ 'consumptions.kpi.consumptions.other' | translate}}
                            </ng-template>
                        </ng-container>
                    </indicator>

                    <indicator class="cell auto color" [img]="'price'">
                        <ng-container *ngIf="!isSearchingKpis else spinnerStats" value>
                            {{consumptionsKpi?.priceWithVat | currency:'EUR' }}
                        </ng-container>
                        <ng-container *ngIf="!isSearchingKpis && consumptionsKpi" label>{{'TTC' | translate}}</ng-container>
                    </indicator>
                </div>
            </div>
        </div>

        <hr class="cell shrink separator-vertical">

        <div class="cell auto no-padding-right">
            <div class="grid-y grid-padding-y cell indicators-bloc">
                <span class="label-title">{{'consumptions.kpi.charges.title' | translate}}</span>
                <div class="grid-x">
                    <indicator class="cell auto color" [img]="'time'">
                        <ng-container *ngIf="!isSearchingKpis else spinnerStats" value>{{consumptionsKpi?.durationInSec |
                            fleetDuration:{seconds : false, minutes : true} }}
                        </ng-container>
                    </indicator>

                    <indicator class="cell auto color" [img]="'energy'">
                        <ng-container *ngIf="!isSearchingKpis else spinnerStats" value>{{consumptionsKpi?.energyInWh |
                            energy}}
                        </ng-container>
                    </indicator>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="displayLimitMessage"
         class="info cell auto common-block force-space-bottom-0 space-top-25">
        {{'consumptions.search.limit' | translate}}
    </div>

    <div class="grid-y cell shrink">
        <div class="grid-x cell shrink align-justify align-middle space-bottom-8 paginationBlock"
             *ngIf="consumptions?.length > 0 && maxPage; let chargeRecords">
            <div class="pagination">
                <button class="pagination-button" (click)="search(criteria, page - 1, true)"
                        [disabled]="isSearchingKpis || page - 1 <= 0">
                    <i class="fas fa-angle-left"></i>
                </button>
                <span class="pagination-label">{{page}}&nbsp;/&nbsp;{{maxPage}}</span>
                <button class="pagination-button" (click)="search(criteria, page + 1, true)"
                        [disabled]="isSearchingKpis || page + 1 > maxPage">
                    <i class="fas fa-angle-right"></i>
                </button>
            </div>
            <div>
                <button class="button secondary color" (click)="export(criteria)" [disabled]="isSearchingConsumptions">
                    {{'chargeConsumptions.export' | translate}}
                </button>
            </div>
        </div>
        <div class="cell small-2 medium-4 large-24 cell-block">
            <table>
                <thead>
                <tr>
                    <th>{{'consumptions.status' | translate}}</th>
                    <th>{{'consumptions.table.kind' | translate}}</th>
                    <th>{{'consumptions.reason' | translate}}</th>
                    <th>{{'consumptions.date' | translate}}</th>
                    <th>{{'consumptions.collaborator' | translate}}</th>
                    <th>{{'consumptions.pass' | translate}}</th>
                    <th>{{'consumptions.vehicle' | translate}}</th>
                    <th>{{'consumptions.rawCost' | translate}}</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let consumption of consumptions; trackBy: trackByConsumptionId">
                        <tr [class.selected]="consumptionSelectedId === consumption.consumptionId">
                            <td>
                                            <span class="status consumption-status-color-{{consumption.status}}"
                                                  [ngClass]="{'has-link' : consumption.status === ConsumptionStatus.INVOICED}">
                                                {{'consumptionStatus.' + ((consumption.kind === 'EXPENSE_REPORT_REFUND' && consumption.status === 'NEW') ? 'INVOICING' : consumption.status) | translate}}
                                                <button class="status-link"
                                                        [disabled]="isConsumptionInvoiceLoading(consumption)"
                                                        *ngIf="consumption.status === ConsumptionStatus.INVOICED && fleetConfig?.showInvoiceSection"
                                                        (click)="goToInvoiceUrl(consumption)">
                                                    <div *ngIf="!isConsumptionInvoiceLoading(consumption) else spinnerStats">
                                                        <i class="fa fa-link"></i>
                                                    </div>
                                                </button>
                                            </span>
                            </td>
                            <td><span class="kind kind-color-{{consumption.kind}}">{{'consumptions.kind.' + consumption.kind | translate}}</span></td>
                            <td><span>{{consumption.reason}}</span></td>
                            <td>{{consumption | fleetMetaConsumptionDate:'short'}}</td>
                            <td>
                                <span *ngIf="consumption.kind !== 'EXPENSE_REPORT_REFUND'">{{(consumption.mediaOwners && consumption.mediaOwners.length > 0) ? consumption.mediaOwners[0].name : consumption.consumerName}}</span>
                            </td>
                            <td>{{consumption | fleetMetaConsumptionMediaPublicCode}}</td>
                            <td>{{consumption | fleetMetaConsumptionLicenseNumber}}</td>
                            <td>
                                {{consumption.priceWithoutVat | currency:undefined:consumption.currencyUnit || ''}}
                            </td>
                            <td>
                                <button *ngIf="consumptionSelectedId !== consumption.consumptionId" class="button round eye" (click)="toggleConsumptionDetails(consumption)">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button *ngIf="consumptionSelectedId === consumption.consumptionId" class="button round eye"
                                        (click)="toggleConsumptionDetails(consumption)">
                                    <i class="fas fa-times"></i>
                                </button>
                            </td>
                        </tr>
                        <tr class="retractable retracted" [class.retracted]="consumptionSelectedId !== consumption.consumptionId">
                            <td colspan="12">
                                <consumption-details class="grid-y cell auto main-container force-space-bottom-0"
                                                     *ngIf="consumptionSelectedId === consumption.consumptionId"
                                                     [consumption]="consumption">
                                </consumption-details>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="grid-x cell shrink align-justify align-middle space-bottom-8 paginationBlock"
             *ngIf="consumptions?.length > 0 && maxPage">
            <div class="pagination">
                <button class="pagination-button" (click)="search(criteria, page - 1, true)"
                        [disabled]="isSearchingKpis || page - 1 <= 0">
                    <i class="fas fa-angle-left"></i>
                </button>
                <span class="pagination-label">{{page}}&nbsp;/&nbsp;{{maxPage}}</span>
                <button class="pagination-button" (click)="search(criteria, page + 1, true)"
                        [disabled]="isSearchingKpis || page + 1 > maxPage">
                    <i class="fas fa-angle-right"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="grid-y cell auto align-center align-middle message-empty"
         *ngIf="!isSearchingConsumptions && (!consumptions || consumptions.length === 0)">
        <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
        <span class="message-empty-label">{{'chargeConsumptions.no.result' | translate}}</span>
    </div>

    <div *ngIf="isSearchingConsumptions">
        <div class="app-loader small text-center">
            <span class="spinner small"></span>
        </div>
    </div>

    <ng-template #spinnerStats>
        <div class="app-loader small text-center spinner-margin">
            <span class="spinner small"></span>
        </div>
    </ng-template>
</div>
