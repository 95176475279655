<form #addressForm="ngForm">
    <div>
        <label class="cell margin-label bottom required" [ngClass]="{validate : !!route.valid}">
            <span class="labelName">{{'informations.billing.route' | translate}}</span>
            <ng-select name="description"
                       #route="ngModel"
                       [(ngModel)]="description"
                       [editableSearchTerm]="true"
                       (search)="pushNewRoute($event)"
                       (focus)="focusOn('description')"
                       (blur)="focusOut('description')"
                       [required]="required"
                       (change)="validAddressSuggestion($event)"
                       [isOpen]="shouldShowAddressSuggestions()"
                       (clear)="removeRouteAndClearAddressSuggestions()">
                <ng-option *ngFor="let suggestion of addressSuggestions; trackBy:trackByPlaceId"
                           [value]="suggestion.placeId"
                >{{suggestion.description}}</ng-option>
            </ng-select>
        </label>
        <label class="cell margin-label bottom" [ngClass]="{validate : !!extra.valid}">
            <span class="labelName">{{'common.address.additionalAddress.label' | translate}}</span>
            <input type="text"
                   [(ngModel)]="address.extra"
                   name="extra"
                   [placeholder]="'common.address.additionalAddress.placeholder' | translate"
                   #extra="ngModel">
        </label>
    </div>
</form>
