<div>
    <h3 [hidden]="mode !== 'register'" class="grid-x align-middle cell secondary block-title space-bottom-12">
        {{'register.subscription.pack.title' | translate}}
    </h3>
    <h3 [hidden]="mode !== 'client'" class="grid-x align-middle cell secondary block-title space-bottom-12">
        {{'update.subscription.pack' | translate}}
    </h3>

    <div class="grid-x small-up-4 offer-selection grid-padding-y grid-padding-x">
        <div *ngIf="subscriptionPacksChoice && subscriptionPacksChoice.length === 0" class="grid-x cell grid-margin-x">
            <h5 class="info alert-info cell auto common-block force-space-bottom-0">
                {{'register.subscription.pack.none' | translate}}
            </h5>
        </div>

        <subscription-pack-item class="cell offer-selection-item"
                                (select)="onSelectSubscriptionPack(subscriptionPack)"
                                *ngFor="let subscriptionPack of subscriptionPacksChoice"
                                [currencyUnit]="subscriptionPack.currencyUnit"
                                [engagementDuration]="subscriptionPack.engagementDuration"
                                [fixPriceWithVat]="subscriptionPack.fixPriceWithVat"
                                [overloadedFixPriceLabel]="subscriptionPack.overloadedFixPriceLabel"
                                [selected]="isSubscriptionPackSelected(subscriptionPack)"
                                [subtitle]="subscriptionPack.subtitle"
                                [title]="subscriptionPack.name">
        </subscription-pack-item>

        <subscription-pack-item class="cell offer-selection-item"
                                (select)="onPrivateOfferSelected()"
                                *ngIf="showPrivateOffers"
                                [selected]="privateOffersSelected"
                                [title]="'register.subscription.privateOffer' | translate">
        </subscription-pack-item>
    </div>
    <form #cguTerms="ngForm">
        <subscription-pack-item-detail class="cell"
                                       *ngFor="let subscriptionPack of selectedSubscriptionPacks"
                                       [infoItems]="subscriptionPack.infoItems"
                                       [subscriptionPackId]="subscriptionPack._id"
                                       [termsAndConditionsI18nCode]="subscriptionPack.termsAndConditionsI18nCode"
                                       [title]="subscriptionPack.name">
        </subscription-pack-item-detail>

        <ng-container *ngIf="privateOffersSelected">
            <subscription-pack-item-detail [title]="'register.subscription.privateOffer' | translate">
                <label class="margin-label bottom">
                    <span>{{'register.subscription.iHaveToken' | translate}}</span>

                    <div class="grid-x">
                        <input class="cell small-8"
                               [disabled]="!!subscriptionPackOrTokenWrapper.packTokenWrapper"
                               [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="onTokenChange($event)"
                               [ngModel]="token" type="text">
                        <button *ngIf="!subscriptionPackOrTokenWrapper.packTokenWrapper"
                                class="cell shrink button color round offer-selection-detail_button"
                                type="button"
                                (click)="fetchToken()"
                                [iziviaLoading]="fetchingToken"
                                [disabled]="!token || showTokenError()">
                            <i class="fas fa-check"></i>
                        </button>
                        <button *ngIf="subscriptionPackOrTokenWrapper.packTokenWrapper"
                                (click)="clearToken()"
                                class="cell shrink button color round offer-selection-detail_button"
                                type="button">
                            <i class="fas fa-times"></i>
                        </button>
                        <div class="cell note error"
                             [ngClass]="{'hiddenError': !showTokenError()}"
                             [hidden]="!showTokenError()">
                            {{tokenErrorLabel}}
                        </div>
                    </div>
                </label>

                <ng-container *ngIf="subscriptionPackOrTokenWrapper.isPackTokenMode()">
                    <div class="grid-x small-up-4 grid-padding-y offer-selection">
                        <subscription-pack-item class="cell offer-selection-item"
                                                *ngFor="let subscriptionPack of subscriptionPackOrTokenWrapper.packTokenWrapper.subscriptionPacks"
                                                [currencyUnit]="subscriptionPack.currencyUnit"
                                                [engagementDuration]="subscriptionPack.engagementDuration"
                                                [fixPriceWithVat]="subscriptionPack.fixPriceWithVat"
                                                [overloadedFixPriceLabel]="subscriptionPack.overloadedFixPriceLabel"
                                                [selected]="true"
                                                [subtitle]="subscriptionPack.subtitle"
                                                [title]="subscriptionPack.name">
                        </subscription-pack-item>
                    </div>

                    <subscription-pack-item-detail
                        *ngFor="let subscriptionPack of subscriptionPackOrTokenWrapper.packTokenWrapper.subscriptionPacks"
                        [infoItems]="subscriptionPack.infoItems"
                        [subscriptionPackId]="subscriptionPack._id"
                        [termsAndConditionsI18nCode]="subscriptionPack.termsAndConditionsI18nCode"
                        [title]="subscriptionPack.name">
                    </subscription-pack-item-detail>
                </ng-container>

            </subscription-pack-item-detail>
        </ng-container>
    </form>
</div>
