import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'subscription-progress-bar',
    templateUrl: './subscription-progress-bar.component.html',
    styleUrls: ['./subscription-progress-bar.component.scss']
})
export class SubscriptionProgressBarComponent {

    @Input() isBarAlert = false;
    @Input() consumePercent = 0;
    @Input() sumCarryOverTime = 0;
    @Input() resetDate;
}
