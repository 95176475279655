import {Component, Input} from '@angular/core';
import {EmoMediaOrderPrice, MediaOrderInfo, SubscriptionPackDto} from 'lib-front';

@Component({
    selector: 'media-order-price-info',
    templateUrl: './media-order-price-info.component.html',
    styleUrls: ['./media-order-price-info.component.scss']
})
export class MediaOrderPriceInfoComponent {

    @Input() priceInfo: EmoMediaOrderPrice;
    @Input() mediaOrderInfo: MediaOrderInfo;
    @Input() promoSubscriptionPack: SubscriptionPackDto;


    constructor() { }


}
