<div class="tabs cell shrink clear-padding vertical focus-block">
    <next-last-page [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                    (changePage)="search(criteria, $event)">
    </next-last-page>

    <div id="sideBar" class="tabs-wrapper ng-star-inserted">
        <button class="button secondary"
                (click)="backToFleet()">{{'fleet.planning.back' | translate}}</button>
        <a *ngIf="fleetConfig?.reportingEnabled && fleetConfig?.reportingList?.includes(Reporting.RIGE)"
           [routerLink]="'/main/reporting/rige'"
           class="button secondary"
           type="button">
            {{'common.more' | translate}}
        </a>

        <div class="tabs-content">
            <h5 class="tabs-content-title">{{'fleet.planning.fleet' | translate}}</h5>

            <div *ngFor="let vehicle of vehicles; trackBy: trackyByVehicleId">
                <div *ngIf="isVehicleCreatedInDreevProvider(vehicle)" class="tabs-content-flotte">
                    <a [routerLink]="vehicle.id"
                       [queryParams]="{licenseNumber: vehicle.licenseNumber, timezone: vehicle.timezone}"
                       [routerLinkActiveOptions]="{exact: true}"
                       routerLinkActive="active">
                        {{vehicle.licenseNumber}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tabs-img" *ngIf="noVehicleSelected">
    <img src="assets/svg/no-event.svg">
    <h6>{{'fleet.planning.noEvent' | translate}}</h6>
</div>

<router-outlet></router-outlet>
