import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AdditionalInformationAnswers,
    AdditionalInformationService,
    FleetUser,
    FormElement,
    FormElementType,
    FrontPackSubscriptionHttpService,
    PackSubscription
} from 'lib-front';
import {NgForm} from '@angular/forms';
import {debounceTime, switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../services/utils/notification.service';
import {Subject} from 'rxjs';
import {orderBy} from 'lodash-es';

@Component({
    selector: 'additional-information',
    templateUrl: './additional-information.component.html',
    styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {

    private _additionalInformationList: FormElement[];
    private connectedUser: FleetUser;

    private formChangedEventEmitter = new Subject<void>();
    private formChangeSubscription;

    @ViewChild('additionalInformationForm') additionalInformationForm: NgForm;

    @Input()
    set additionalInformationList(value: FormElement[]) {
        this._additionalInformationList = orderBy(value, ['index'], ['asc']);
    }

    @Input() submitOnChange: boolean = false;
    @Input() packSubscription: PackSubscription;
    @Input() additionalInformationAnswers: AdditionalInformationAnswers = {};

    get valid(): boolean {
        return !!this.additionalInformationForm && !this.additionalInformationForm.invalid;
    }

    constructor(private additionalInformationService: AdditionalInformationService,
        private packSubscriptionService: FrontPackSubscriptionHttpService,
        private route: ActivatedRoute,
        private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        if (this.submitOnChange) {
            this.route.data.subscribe(data => this.connectedUser = data.user.user);
            this.formChangeSubscription = this.formChangedEventEmitter.pipe(
                debounceTime(500)
            ).subscribe(
                () => this.submitForm()
            );
        }
    }

    ngOnDestroy(): void {
        if (this.submitOnChange) {
            this.formChangeSubscription.unsubscribe();
        }
    }

    formChanged() {
        if (this.submitOnChange) {
            this.formChangedEventEmitter.next(null);
        }
    }

    submitForm() {
        if (this.additionalInformationForm.valid) {
            this.additionalInformationService.handleAdditionalInformationFiles(
                this.additionalInformationList,
                this.additionalInformationAnswers
            ).pipe(
                switchMap(
                    filteredAdditionalInformation => this.packSubscriptionService.updatePackSubscriptionAdditionalInformation(
                        this.connectedUser._id,
                        this.packSubscription._id,
                        filteredAdditionalInformation
                    )
                )
            ).subscribe(
                data => this.notificationService.success('subscription.additionalInformation.alert.updated'),
                error => this.notificationService.error('subscription.additionalInformation.alert.error')
            );
        }
    }

    get additionalInformationList(): FormElement[] {
        return this._additionalInformationList;
    }

    isSection(additionalInformation: FormElement) {
        return additionalInformation && additionalInformation.type === FormElementType.section;
    }


}
