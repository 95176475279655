import {Component, Input} from '@angular/core';
import {StepComponent} from './step/step.component';

@Component({
    selector: 'step-indicator',
    templateUrl: './step-indicator.component.html',
    styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent {
    private steps = [];

    @Input() public current: number;

    public get size() {
        return this.steps.length;
    }

    constructor() { }

    public addStep(step: StepComponent) {
        this.steps.push(step);
        step.number = this.steps.length;
    }
}
