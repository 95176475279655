import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    buildRole,
    ConnectedUser,
    CurrentSubscriberRolesService,
    DetailedCollaboratorDto,
    DisableReason,
    FleetImportRequestReportProcessing,
    FrontFoAccountHttpService,
    FrontInvoiceHttpService,
    FrontMediaHttpService,
    FrontPackSubscriptionHttpService,
    FrontPersonHttpService,
    FrontVehicleHttpService,
    IdLabel,
    MediaActivateInfoDto,
    MediaCanOrderDto,
    MediaData,
    MediaFamilyType,
    MediaFleetItemDto,
    MediaOrderContext,
    MediaRequest,
    MediaSearchCriteria, MediaStatus,
    PackSubscription,
    PaginationParams,
    SubscriberRoleLevel,
    SubscriberRoleType,
    User,
    Vehicle
} from 'lib-front';
import {AlertService} from '../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../services/utils/notification.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {CurrentUserContextService} from '../../../../services/business/currentUserContext.service';
import {
    AbstractHasRoleActionComponent
} from '../../../../components/has-role-action/abstract-has-role-action.component';
import {MediaDeactivation} from './media-lost/media-deactivation.component';

@Component({
    selector: 'media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class MediaComponent extends AbstractHasRoleActionComponent implements OnInit {
    public DisableReason = DisableReason;

    @ViewChild('deactivateMediaPopup') mediaPopup;
    public user: User;
    private isSuspended: boolean;

    public foAccountRef: string;
    public LIMITBYPAGE: number = 10;
    public LIMIT: number = 100;
    public withInactivePass: boolean;
    public containBlockingInvoice: boolean;
    public lastPage: number;
    public medias: MediaFleetItemDto[] = [];
    public mediasDisplayed: MediaFleetItemDto[] = [];
    public criteria: MediaSearchCriteria = {
        mediaFamilyTypes: [MediaFamilyType.DEFAULT]
    };
    public displayLimitMessage: boolean = false;
    public mediaData: MediaData = {activate: 0, order: 0, disabled: 0};
    public inputStatus: MediaInputStatus[];
    public subscribers: DetailedCollaboratorDto[];
    public subscribersSearch: string;
    public vehicles: Vehicle[];
    public vehiclesSearch: string;
    public _mediasPage: number;
    public mediaIndexForPage: number;
    public subscriptionPacksLoading = false;
    public mediaToDeactivate: MediaFleetItemDto;
    public hasPassWriteRole: boolean;
    public hasCollaboratorsWriteRole: boolean;
    public savingSubscriber: boolean;
    public savingVehicle: boolean;
    public savingSubscriptionPacks: boolean;
    public searching: boolean;
    public activatingMedia: boolean;
    public subscriberIdLabels: IdLabel[] = [];
    public vehicleIdLabels: IdLabel[] = [];
    public packSubscriptionIdLabels: IdLabel[] = [];
    public userHasPackSubscriptions: boolean = false;

    private mediaCanOrderDtoList: MediaCanOrderDto[];

    private loadingBlockingInvoice: boolean;
    private loadingIfIsSuspended: boolean;
    private loadingMediaCanOrder: boolean;

    public set mediasPage(mediasPage: number) {
        this._mediasPage = mediasPage;
        this.mediaIndexForPage = (mediasPage - 1) * this.LIMITBYPAGE;
    }

    constructor(private readonly mediaService: FrontMediaHttpService,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly alertService: AlertService,
        protected readonly notificationService: NotificationService,
        private readonly renderer: Renderer2,
        private readonly foAccountHttpService: FrontFoAccountHttpService,
        private readonly personHttpService: FrontPersonHttpService,
        private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly currentUserContextService: CurrentUserContextService,
        readonly route: ActivatedRoute,
        readonly invoiceService: FrontInvoiceHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly packSubscriptionService: FrontPackSubscriptionHttpService) {
        super(notificationService);
    }

    public ngOnInit(): void {
        this.withInactivePass = false;
        this.lastPage = 1;
        this.inputStatus = [];
        this.mediasPage = 1;

        this.loadingMediaCanOrder = true;
        this.loadingIfIsSuspended = true;
        this.loadingBlockingInvoice = true;

        this.route.data.pipe(
            tap(data => {
                this.user = data.user.user;
                this.isSuspended = ConnectedUser.isSuspended(data.user);
                this.loadingIfIsSuspended = false;
            }),
            tap(() => {
                this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId();
            }),
            tap(() => this.loadMediasData()),
            switchMap(() => this.searchSubscribers(null)),
            tap((subscribers) => {
                this.subscribers = subscribers;
                this.subscriberIdLabels = subscribers.map(subscriber => {
                    return {id: subscriber.subscriberId, name: subscriber.firstName + ' ' + subscriber.lastName};
                });
            }),
            switchMap(() => this.searchVehicles(null)),
            tap((vehicles) => {
                this.vehicles = vehicles;
                this.vehicleIdLabels = vehicles.map(vehicle => {
                    return {id: vehicle.id, name: vehicle.licenseNumber};
                });
            }),
            tap(() => this.loadPackSubscription()),
            tap(() => this.findBlockingInvoices()),
            switchMap(() => this.route.queryParams),
            tap((data) => {
                if (!!data['fullName']) {
                    this.criteria.subscriberRef = this.subscriberIdLabels.find(subscriber => subscriber.name === data['fullName'])?.id;
                }
                if (!!data['licenseNumber']) {
                    this.criteria.vehicleRef = this.vehicleIdLabels.find(vehicle => vehicle.name === data['licenseNumber'])?.id;
                }
            })
        ).subscribe(_ => {
            this.searchMedias();
        });

        this.mediaService.fetchOrderable(MediaOrderContext.ACCOUNT)
            .subscribe(mediaCanOrderDtoList => {
                this.mediaCanOrderDtoList = mediaCanOrderDtoList;
                this.loadingMediaCanOrder = false;
            });

        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.PASS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasPassWriteRole = hasRole);

        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.COLLABORATORS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasCollaboratorsWriteRole = hasRole);
    }

    public mediaDeactivated(mediaDeactivationEvent: MediaDeactivation): void {
        this.mediaPopup.close();
        if (mediaDeactivationEvent.disableReason === DisableReason.LOST_OR_STOLEN) {
            this.alertService.confirm(this.translateService.instant('media.deactivatePopup.order.message'),
                null,
                this.translateService.instant('media.deactivatePopup.order.confirm'),
                this.translateService.instant('media.deactivatePopup.order.cancel')
            ).subscribe(result => {
                if (result) {
                    this.router.navigate(['/main/media/order']).then();
                } else {
                    this.loadMedias();
                }
            });
        } else {
            this.loadMedias();
        }
    }

    public saveAlias(mediaId: string, index: number) {
        this.inputStatus[index].isAliasChange = false;
        this.mediaService.updateMediaAlias(this.foAccountRef, mediaId, this.medias[index].alias)
            .subscribe({
                next: () => {
                    this.notificationService.success('media.table.detail.success');
                    this.loadMedias();
                },
                error: () => this.notificationService.error('media.table.detail.error')
            });
    }

    public switchInactivePass(isInactivePass) {
        this.withInactivePass = isInactivePass;
        this.searchMedias();
    }

    public saveSubscriber(mediaId: string, index: number) {
        this.savingSubscriber = true;

        const subscriptionPackRefs = this.inputStatus[index].activatedOnSubscriptionPacks
            .map(subscriptionPack => subscriptionPack.id);
        this.mediaService.addMediaToSubscriber(this.foAccountRef,
            this.inputStatus[index].personRef,
            mediaId,
            this.medias[index].personRef,
            subscriptionPackRefs)
            .pipe(
                finalize(() => {
                    this.savingSubscriber = false;
                    this.inputStatus[index].isSubscriberChange = false;
                })
            )
            .subscribe(() => {
                this.notificationService.success('media.table.subscription.success');
                this.loadMedias();
            },
            () => this.notificationService.error('media.table.detail.error')
            );
    }

    public cancelSubscriberEdition(index: number) {
        this.inputStatus[index].isSubscriberChange = false;
    }

    public showInput(index): void {
        this.doActionIfHasRole(
            () => {
                this.inputStatus[index].isAliasChange = true;
                setTimeout(() => this.renderer.selectRootElement('#alias' + index).focus(), 0);
            },
            this.hasPassWriteRole
        );

    }

    public getQueryParamsFromCriteria(): string {
        let queryParams = '';
        if (!!this.criteria.code ||
            !!this.criteria.subscriberRef ||
            !!this.criteria.vehicleRef ||
            !!this.criteria.subscriptionPackRefs ||
            !!this.criteria.mediaFamilyTypes ||
            this.withInactivePass) {
            queryParams += '?';
        } else {
            return queryParams;
        }
        if (!!this.criteria.code) {
            queryParams += 'code=' + this.criteria.code + '&';
        }
        if (!!this.criteria.subscriberRef) {
            queryParams += 'subscriberRef=' + this.criteria.subscriberRef + '&';
        }
        if (!!this.criteria.vehicleRef) {
            queryParams += 'vehicleRef=' + this.criteria.vehicleRef + '&';
        }
        if (!!this.criteria.subscriptionPackRefs) {
            queryParams += 'subscriptionPackRefs=' + this.criteria.subscriptionPackRefs + '&';
        }
        if(!!this.criteria.mediaFamilyTypes) {
            queryParams += 'mediaFamilyTypes=' + this.criteria.mediaFamilyTypes + '&';
        }
        if (this.withInactivePass) {
            queryParams += 'withInactive=true';
        }
        return queryParams;
    }

    public loadMedias(): void {
        this.loadMediasData();

        this.criteria = {};
        this.searchMedias();
    }

    public searchMedias() {
        this.searching = true;

        const paginationParams: PaginationParams = {
            limit: this.LIMIT + 1
        };

        if (this.criteria.subscriptionPackRefs?.length === 0) {
            this.criteria = {
                code: this.criteria.code,
                subscriberRef: this.criteria.subscriberRef,
                vehicleRef: this.criteria.vehicleRef,
                mediaAlias: this.criteria.mediaAlias
            };
        }

        this.mediaService.findFleetMediasOptimized(
            this.foAccountRef,
            this.withInactivePass,
            this.criteria,
            paginationParams,
            true
        )
            .pipe(
                tap(medias => this.medias = medias),
                tap(() => this.handleLimitation()),
                tap(() => this.computePageNumberAndSetToFirst()),
                tap(() => this.loadMediaInputStatus()),
                finalize(() => this.searching = false)
            )
            .subscribe();
    }

    public computeLastPage(): void {
        this.lastPage = Math.ceil(this.medias.length / this.LIMITBYPAGE) || 1;
    }

    public plural(nbr, label) {
        return nbr > 1 ? this.translateService.instant(label + '.plural') : this.translateService.instant(label + '.one');
    }

    public cannotOrderMedia() {
        return this.loadingMediaCanOrder
            || this.loadingIfIsSuspended
            || this.loadingBlockingInvoice
            || this.isSuspended
            || (this.mediaCanOrderDtoList?.length || 0) === 0;
    }

    public getSubscriberId(subscriber: DetailedCollaboratorDto): string {
        return subscriber.id;
    }

    public getSubscriberName(subscriber: DetailedCollaboratorDto): string {
        return subscriber.firstName + ' ' + subscriber.lastName;
    }

    private loadMediasData() {
        this.mediaService.findMediaData(this.user._id, this.foAccountRef).subscribe(value => {
            this.mediaData = value;
        });
    }

    public searchSubscribers(search: string): Observable<DetailedCollaboratorDto[]> {
        this.subscribersSearch = search;
        return this.foAccountHttpService.findCollaborators(this.foAccountRef, null, true, false, {});
    }

    public searchAndSetSubscribers(search: string): void {
        this.searchSubscribers(search)
            .subscribe(subscribers => {
                this.subscribers = subscribers;
            });
    }

    private loadMediaInputStatus() {
        this.inputStatus = [];
        this.medias.forEach(media => {
            this.inputStatus.push(
                {
                    isAliasChange: false,
                    isSubscriberChange: false,
                    isVehicleChange: false,
                    isSubscriptionPacksChange: false,
                    acceptedSubscriptionPacks: null,
                    activatedOnSubscriptionPacks: media.activatedOnSubscriptionPacks,
                    personRef: media.personRef,
                    vehicleRef: media.vehicleRef,
                    mediaId: media.id,
                    alias: media.alias
                }
            );
        });
    }

    private findBlockingInvoices(): void {
        this.invoiceService.fetchBlockingInvoiceIds(this.user._id).subscribe(invoices => {
            this.containBlockingInvoice = invoices.length > 0;
            this.loadingBlockingInvoice = false;
        });
    }

    private clearSubscriberChangeInput(): void {
        this.inputStatus.forEach(input => input.isSubscriberChange = false);
    }

    public selectSubscriberChangeInput(index: number): void {
        this.doActionIfHasRole(
            () => {
                this.clearSubscriberChangeInput();
                this.inputStatus[index].isSubscriberChange = true;

                if (this.subscribersSearch) {
                    this.searchAndSetSubscribers(null);
                }
            },
            this.hasPassWriteRole
        );

    }

    public selectSubscriptionPacksChangeInput(index: number): void {
        this.doActionIfHasRole(
            () => {
                this.inputStatus.forEach(input => input.isSubscriptionPacksChange = false);
                this.inputStatus[index].isSubscriptionPacksChange = true;

                if (!this.inputStatus[index].acceptedSubscriptionPacks) {
                    this.subscriptionPacksLoading = true;
                    this.mediaService.findActivateById(this.inputStatus[index].mediaId)
                        .subscribe((mediaActivateInfoDto: MediaActivateInfoDto) => {
                            if (mediaActivateInfoDto.acceptedSubscriptionPacks?.length) {
                                this.inputStatus[index].acceptedSubscriptionPacks = mediaActivateInfoDto.acceptedSubscriptionPacks;
                                this.subscriptionPacksLoading = false;
                            } else {
                                mediaActivateInfoDto.acceptedSubscriptionPacks = [];
                                this.subscriptionPacksLoading = false;
                            }
                        });
                }
            },
            this.hasPassWriteRole
        );

    }

    public cancelSubscriptionPacksEdition(index: number) {
        this.inputStatus[index].isSubscriptionPacksChange = false;
        this.inputStatus[index].acceptedSubscriptionPacks = null;
        this.inputStatus[index].activatedOnSubscriptionPacks = this.medias[index].activatedOnSubscriptionPacks;
    }

    public saveSubscriptionPacks(id: string, index: number) {
        this.savingSubscriptionPacks = true;
        const mediaRequest: MediaRequest = {
            subscriptionPackRefs: this.inputStatus[index].activatedOnSubscriptionPacks
                .map(subscriptionPack => subscriptionPack.id)
        };

        this.mediaService.activeMediaWithIdAndPerson(
            this.inputStatus[index].personRef,
            this.inputStatus[index].mediaId,
            mediaRequest
        )
            .pipe(
                finalize(() => {
                    this.savingSubscriptionPacks = false;
                    this.inputStatus[index].isSubscriptionPacksChange = false;
                    this.inputStatus[index].acceptedSubscriptionPacks = null;
                })
            )
            .subscribe({
                next: () => {
                    this.medias[index].activatedOnSubscriptionPacks = this.inputStatus[index].activatedOnSubscriptionPacks;
                    this.notificationService.success('media.subscriptionPacks.change.success');
                },
                error: (error: HttpErrorResponse) => this.handleMediaChangeError(error, index)
            });
    }

    private clearVehicleChangeInput(): void {
        this.inputStatus.forEach(input => input.isVehicleChange = false);
    }

    public selectVehicleChangeInput(index: number): void {
        this.doActionIfHasRole(
            () => {
                this.clearVehicleChangeInput();
                this.inputStatus[index].isVehicleChange = true;

                if (this.vehiclesSearch) {
                    this.searchAndSetVehicles(null);
                }
            },
            this.hasPassWriteRole
        );

    }

    public getVehicleId(vehicle: Vehicle): string {
        return vehicle.id;
    }

    public getVehicleLicenseNumber(vehicle: Vehicle): string {
        return vehicle.licenseNumber;
    }

    public searchVehicles(search: string): Observable<Vehicle[]> {
        this.vehiclesSearch = search;
        return this.vehicleHttpService.findVehicles({query: search});
    }

    public searchAndSetVehicles(search: string): void {
        this.searchVehicles(search)
            .subscribe(vehicles => {
                this.vehicles = vehicles;
            });
    }

    public saveVehicle(mediaId: string, index: number) {
        this.savingVehicle = true;
        let httpCallObservable: Observable<void>;
        if (!!this.medias[index].vehicleRef) {
            httpCallObservable = this.vehicleHttpService.addMediaToVehicle(this.medias[index].vehicleRef, mediaId)
                .pipe(
                    finalize(() => {
                        this.savingVehicle = false;
                        this.inputStatus[index].isVehicleChange = false;
                    })
                );
        } else {
            httpCallObservable = this.vehicleHttpService.removeMediaFromVehicle(this.medias[index].vehicleRef, mediaId)
                .pipe(
                    finalize(() => {
                        this.savingVehicle = false;
                        this.inputStatus[index].isVehicleChange = false;
                    })
                );
        }

        httpCallObservable.subscribe(() => {
            this.notificationService.success('media.table.vehicle.success');
            this.loadMedias();
        },
        () => this.notificationService.error('media.table.vehicle.error')
        );
    }

    public cancelVehicleEdition(index: number) {
        this.inputStatus[index].isVehicleChange = false;
    }

    private handleMediaChangeError(error: HttpErrorResponse, index: number) {
        const errorKey = this.getErrorKeyForError(error?.error);
        this.notificationService.error(errorKey);
        this.inputStatus[index].activatedOnSubscriptionPacks = this.medias[index].activatedOnSubscriptionPacks;
    }

    private getErrorKeyForError(error): string {
        if (error?.labelKey === 'error.media.activate.tooManyExpenseReportSubscriptionPacks') {
            return 'error.media.activate.tooManyExpenseReportSubscriptionPacks';
        } else {
            return 'media.subscriptionPacks.change.error';
        }
    }

    public formatSubscriptionPack(media: MediaFleetItemDto): string {
        return media && media.activatedOnSubscriptionPacks.length
            ? media.activatedOnSubscriptionPacks.map(subscriptionPacks => subscriptionPacks.name).join(', ')
            : '';
    }

    // Import media
    createMediaImportRequestReportObs: (string, number) => Observable<string> =
        (fileId, maxErrorToReport) => this.mediaService.createMediaImportRequestReport(this.foAccountRef, fileId, maxErrorToReport);

    fetchImportRequestReportObs: (string) => Observable<FleetImportRequestReportProcessing> =
        (importId) => this.mediaService.fetchImportRequestReportObs(this.foAccountRef, importId);

    importRequestObs: (string) => Observable<void> =
        mediaImportRequestId => this.mediaService.importMedias(this.foAccountRef, mediaImportRequestId);

    trackByMediaItemId(index: number, mediaFleetItem: MediaFleetItemDto) {
        return mediaFleetItem?.id ?? index;
    }

    openDeactivateMediaPopup(media: MediaFleetItemDto) {
        this.mediaToDeactivate = media;
        this.mediaPopup.open();
    }

    activateMedia(media: MediaFleetItemDto) {
        this.activatingMedia = true;
        this.mediaService.activeMediaWithIdAndPerson(this.user._id, media.id, {subscriptionPackRefs: []})
            .pipe(
                finalize(() => this.activatingMedia = false)
            )
            .subscribe(() => {
                this.notificationService.success('media.table.button.notification.activation.success');
                this.loadMedias();
            },
            error => {
                this.notificationService.error(error.error.labelKey || 'media.table.button.notification.activation.error');
            });
    }

    goToMediaActivation() {
        this.doActionIfHasRole(
            () => this.router.navigate(['/main/media/activate']),
            this.hasPassWriteRole
        );
    }

    goToMediaOrder() {
        this.doActionIfHasRole(
            () => this.router.navigate(['/main/media/order']),
            this.hasPassWriteRole
        );
    }

    private loadPackSubscription(): void {
        this.packSubscriptionService.findPackSubscription(
            this.user._id,
            true,
            true,
            false,
            false
        )
            .subscribe((packSubscriptions: PackSubscription[]) => {
                this.packSubscriptionIdLabels = packSubscriptions.map(pack => {
                    return {id: pack.subscriptionPackRef, name: pack.subscriptionPackName};
                });
                this.userHasPackSubscriptions = packSubscriptions.length > 0;
            });

    }

    public getMediasFromPage(page: number): void {
        this.mediasPage = page;
        this.mediasDisplayed = this.medias.slice((page - 1) * this.LIMITBYPAGE, page * this.LIMITBYPAGE);
    }

    private computePageNumberAndSetToFirst(): void {
        this.mediasPage = 1;
        this.computeLastPage();
        this.getMediasFromPage(this._mediasPage);
    }

    private handleLimitation(): void {
        if (this.medias && this.medias.length > this.LIMIT) { // so length will be LIMIT + 1
            this.medias.pop();
            this.displayLimitMessage = true;
        } else {
            this.displayLimitMessage = false;
        }
    }

    protected readonly MediaStatus = MediaStatus;
}

export interface MediaInputStatus {
    mediaId: string;
    isAliasChange: boolean;
    isSubscriberChange: boolean;
    isVehicleChange: boolean;
    isSubscriptionPacksChange: boolean;
    acceptedSubscriptionPacks: Array<IdLabel>;
    activatedOnSubscriptionPacks: Array<IdLabel>;
    personRef: string;
    vehicleRef: string;
    alias: string;
}
