import {Component, OnInit} from '@angular/core';
import {
    FrontSessionBusinessService,
} from 'lib-front';
@Component({
    selector: 'register-pending-success',
    templateUrl: './register-creation-pending.component.html',
    styleUrls: ['./register-creation-pending.component.scss'],
    host : {'class' : 'grid-x cell auto'}
})
export class RegisterCreationPendingComponent implements OnInit {

    constructor(private readonly sessionBusinessService: FrontSessionBusinessService) {
    }

    ngOnInit() {
        this.sessionBusinessService.logout().subscribe();
    }

}
