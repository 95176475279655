import {Component, Input, OnInit} from '@angular/core';
import {EmailDto, FrontEmailHttpService, FrontSessionBusinessService, PortalSupportConfig, SupportContact, SupportMailSubject} from 'lib-front';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    @Input() supportContacts: SupportContact[];

    @Input() fleetSupportConfig: PortalSupportConfig;

    email: EmailDto;
    sendingMail: boolean;
    private currentConnectedUserId: string;
    selectedSubject: SupportMailSubject;

    private setEmailSender(): void {
        this.email.senderRef = this.currentConnectedUserId;
    }

    private resetEmail(): void {
        this.email = new EmailDto();
        this.setEmailSender();
    }

    constructor(private readonly emailHttpService: FrontEmailHttpService,
        private readonly sessionService: FrontSessionBusinessService,
        private readonly notificationService: NotificationService) {
        this.resetEmail();
    }

    ngOnInit(): void {
        this.sessionService.currentConnectedUser$.subscribe(currentConnectedUser => {
            this.currentConnectedUserId = currentConnectedUser.user._id;
            this.setEmailSender();
        });
    }

    trackBySupportContactId(index: number, supportContact: SupportContact) {
        return supportContact?.id ?? index;
    }

    sendMail(): void {
        this.sendingMail = true;

        this.email.subject = this.selectedSubject.subject;
        this.email.subjectId = this.selectedSubject.subjectCode;
        this.emailHttpService.sendEmail(this.email)
            .pipe(
                finalize(() => this.sendingMail = false)
            )
            .subscribe(
                () => {
                    this.notificationService.success('config.support.sendSuccess');
                    this.resetEmail();
                },
                () => this.notificationService.error('config.support.sendError')
            );
    }
}
