import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    CurrentSubscriberRolesService,
    FrontVehicleHttpService,
    Vehicle,
    VehicleSearchCriteria,
    SubscriberRoleType,
    SubscriberRoleLevel,
    buildRole
} from 'lib-front';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'fleet-configuration',
    templateUrl: './fleet-configuration.component.html',
    styleUrls: ['./fleet-configuration.component.scss'],
    host: {class: 'cell auto cell-block-y'}
})
export class FleetConfigurationComponent implements OnInit, OnDestroy {
    public hasFleetWriteRole: boolean;

    vehicles: Array<Vehicle> = [];
    vehicle: Vehicle;
    nbrVehicles: number = 0;
    pageNumber: number = 1;
    nbrPageMax: number = 0;
    searching: boolean;
    criteria: VehicleSearchCriteria;
    displayLimitMessage: boolean = false;

    private nbVehiclesLimit = 100;

    constructor(private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly route: ActivatedRoute,
        private readonly location: Location,
        private readonly router: Router) {
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.VEHICLES, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasFleetWriteRole = hasRole);

        // As we use location.go() in tabs, router is not aware of url changes
        // Instead of passing undefined as vehicle data from configuration route,
        // we need to subscriber to url change from Location
        this.location.onUrlChange((url) => {
            if (url.endsWith('fleet')) {
                this.vehicle = undefined;
                this.search(this.criteria, 1, true);
            }
        });
    }

    ngOnInit() {
        this.criteria = this.vehicleHttpService.getCriteria();
        this.searching = true;
        this.route.data.subscribe((data) => {
            this.vehicle = data['vehicle'];
        });
        this.search(this.criteria, 1, true);
    }

    ngOnDestroy(): void {
        this.vehicleHttpService.resetCriteria();
    }

    public search(criteria: VehicleSearchCriteria, pageNumber, resetPage: boolean = false) {
        criteria.skip = (pageNumber - 1) * this.criteria.limit;
        this.pageNumber = pageNumber;
        this.searchByText(criteria, resetPage);
    }

    public searchByText(criteria: VehicleSearchCriteria, resetPage: boolean = true) {
        this.searching = true;

        if (criteria.version === 'initial') {
            criteria.version = undefined;
        }

        if (resetPage) {
            this.pageNumber = 1;
            this.vehicleHttpService.findVehiclesStats(criteria)
                .subscribe((vehicleStats) => {
                    this.nbrVehicles = vehicleStats.fleetVehicleSize;
                    this.updateDisplayLimitMessage(this.nbrVehicles, this.nbVehiclesLimit);
                    this.recomputeNbrPageMax(this.nbrVehicles);
                });
        }

        this.vehicleHttpService.findVehicles(criteria)
            .pipe(
                finalize(() => this.searching = false)
            )
            .subscribe(vehicles => this.vehicles = vehicles);
    }

    public updateNbrVehicles(updatedValue: number) {
        this.nbrVehicles = updatedValue;
    }

    public recomputeNbrPageMax(nbrVehicles: number) {
        this.nbrPageMax = nbrVehicles > this.criteria.limit ?
            Math.min(Math.ceil(nbrVehicles / this.criteria.limit), 10) : 1;
    }

    private updateDisplayLimitMessage(nbOfVehicles: number, nbOfVehiclesLimit: number) {
        if (nbOfVehicles > nbOfVehiclesLimit) {
            this.displayLimitMessage = true;
        } else {
            this.displayLimitMessage = false;
        }
    }
}
