import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {formatNumber} from '@angular/common';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

@Pipe({
    name: 'fleetDuration'
})
export class FleetDurationPipe implements PipeTransform {

    transform(value: number, param: DurationPipeParam = new DurationPipeParam()): string {
        const duration = moment.duration(value, param.entryUnit || 's');
        const hours = Math.trunc(duration.as('hours'));
        const minutes = duration.minutes();
        let formattedString: string = '';


        if (hours > 0) {
            formattedString = formatNumber(hours, 'fr', '1.0-0') + 'h ';
        }
        if ((param.minutes && hours < 1000 && (minutes !== 0 || hours < 1)) || param.report && hours < 1) {
            let minuteString: string;
            if (minutes < 10) {
                minuteString = '0' + minutes;
            } else {
                minuteString = String(minutes);
            }

            formattedString = formattedString + minuteString + 'min ';
        }

        if ((param.seconds) && hours < 1000 || param.report && hours < 1 && minutes < 1) {
            let secondString: string;
            if (duration.seconds() < 10) {
                secondString = '0' + duration.seconds();
            } else {
                secondString = String(duration.seconds());
            }
            formattedString = formattedString + secondString + 'sec';
        }

        return formattedString || '0h';
    }
}

class DurationPipeParam {
    minutes?: boolean = true;
    seconds?: boolean = true;
    report?: boolean = false;
    entryUnit?: DurationConstructor = 's';
}
