import {Component, Input, OnInit} from '@angular/core';
import {FaqNodeDetailDto} from 'lib-front';

@Component({
    selector: 'faq-icon',
    templateUrl: './faq-icon.component.html',
    styleUrls: ['./faq-icon.component.scss']
})
export class FaqIconComponent {

    @Input()
    public faqNode: FaqNodeDetailDto;

    constructor() {
    }
}
