import {Component, OnInit, ViewChild} from '@angular/core';
import {
    buildRole,
    CurrentSubscriberRolesService,
    FrontEndFleetConfig,
    FrontMediaHttpService,
    FrontMediaOrderHttpService,
    Invoice,
    MediaOrder,
    SubscriberRoleLevel,
    SubscriberRoleType,
    User
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {map} from 'rxjs/operators';
import {FrontEndService} from '../../../../../services/frontEnd.service';
import {IziviaPopupComponent} from '../../../../../components/izivia-popup/izivia-popup.component';
import {
    AbstractHasRoleActionComponent
} from '../../../../../components/has-role-action/abstract-has-role-action.component';

@Component({
    selector: 'media-follow-order',
    templateUrl: './media-follow-order.component.html',
    styleUrls: ['./media-follow-order.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class MediaFollowOrderComponent extends AbstractHasRoleActionComponent implements OnInit {
    private user: User;

    public medias: MediaOrder[];
    fleetConfig: FrontEndFleetConfig;
    mediaOrderToLink: MediaOrder;
    hasInvoiceReadRole: boolean;
    private hasPassWriteRole: boolean;

    @ViewChild('subscriptionPacksSelectionPopup') subscriptionPacksSelectionPopup: IziviaPopupComponent;


    constructor(private mediaService: FrontMediaHttpService,
        private mediaOrderService: FrontMediaOrderHttpService,
        protected notificationService: NotificationService,
        private frontEndService: FrontEndService,
        route: ActivatedRoute,
        private currentSubscriberRolesService: CurrentSubscriberRolesService) {
        super(notificationService);
        route.data.subscribe(data => {
            this.user = data.user.user;
            this.loadMediaOrders();
        });
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.PASS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasPassWriteRole = hasRole);
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.INVOICES, SubscriberRoleLevel.READ)
        ).subscribe(hasRole => this.hasInvoiceReadRole = hasRole);

    }


    ngOnInit(): void {
        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => this.fleetConfig = fleetConfig);
    }

    public getInvoiceUrl(invoice: Invoice): string {
        if (!invoice) {
            return null;
        }
        return `api/customer/${invoice.customerRef}/invoice/${invoice._id}-${invoice.uuid}`;
    }

    public linkMediaFromMediaOrder(subscriptionPackRefs: string[]): void {
        this.doActionIfHasRole(
            () => {
                this.mediaOrderService.linkMediasFromMediaOrder(this.mediaOrderToLink._id, subscriptionPackRefs)
                    .subscribe({
                        next: _ => {
                            this.notificationService.success('mediaOrder.linkAllMedias.success');
                            this.loadMediaOrders();
                            this.subscriptionPacksSelectionPopup.close();
                        },
                        error: e => {
                            this.notificationService.error(e.error.labelKey || 'mediaOrder.linkAllMedias.error');
                            this.loadMediaOrders();
                            this.subscriptionPacksSelectionPopup.close();
                        }
                    });
            },
            this.hasPassWriteRole
        );

    }

    public canLinkMediaFromMediaOrder(mediaOrder: MediaOrder): boolean {
        return 'SENT' === mediaOrder.currentStatus.status
            && mediaOrder.quantity !== (mediaOrder.otherMediaCount + mediaOrder.cancelledMediaCount)
            && mediaOrder?.mediaFamilyActivatedOnCustomerAccount;
    }

    private loadMediaOrders() {
        this.mediaService.findMediaOrder(this.user._id).subscribe(mediaOrders => {
            this.medias = mediaOrders;
        });
    }

    trackByMediaOrderId(index: number, mediaOrder: MediaOrder) {
        return mediaOrder?._id ?? index;
    }

    openSelectionPopup(mediaOrder: MediaOrder) {
        this.doActionIfHasRole(
            () => {
                this.mediaOrderToLink = mediaOrder;
                this.subscriptionPacksSelectionPopup.open();
            },
            this.hasPassWriteRole
        );
    }

    onSubscriptionPacksSelect(subscriptionPackRefs: string[]): void {
        this.linkMediaFromMediaOrder(subscriptionPackRefs);
    }
}
