import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {FrontStationHttpService, StationDto} from 'lib-front';
import {debounceTime, filter, switchMap} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../services/utils/notification.service';

@Component({
    selector: 'station-edit',
    templateUrl: './station-edit.component.html',
    styleUrls: ['./station-edit.component.scss']
})
export class StationEditComponent implements AfterViewInit, OnDestroy {

    @Input() station: StationDto;
    @Input() public hasStationWriteRole: boolean;
    @ViewChild('stationForm') ngForm: NgForm;
    formIsInit: boolean;
    private formChangesSubscription: Subscription;

    constructor(private readonly stationHttpService: FrontStationHttpService,
        private readonly notificationService: NotificationService) {
    }

    ngAfterViewInit(): void {
        this.formChangesSubscription = this.ngForm.form.valueChanges.pipe(
            filter(() => !!this.ngForm.valid),
            debounceTime(1200),
            filter(() => {
                if (!this.formIsInit) {
                    this.formIsInit = true;
                    return false;
                }
                return this.formIsInit;
            }),
            switchMap(() => this.stationHttpService.updateStation(this.station))
        ).subscribe(() => this.notificationService.success('stations.edit.update.success'),
            () => this.notificationService.error('stations.edit.update.error'));
    }

    ngOnDestroy(): void {
        if (!!this.formChangesSubscription) {
            this.formChangesSubscription.unsubscribe();
        }
    }

}
