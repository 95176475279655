import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'subscription-pack-item',
    templateUrl: './subscription-pack-item.component.html',
    styleUrls: ['./subscription-pack-item.component.scss'],
})
export class SubscriptionPackItemComponent {

    @Input() title: string;
    @Input() subtitle?: string;
    @Input() overloadedFixPriceLabel?: string;
    @Input() fixPriceWithVat?: number;
    @Input() currencyUnit?: string;
    @Input() engagementDuration?: number;
    @Input() selected: boolean;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() select: EventEmitter<void> = new EventEmitter();
}
