import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {HomeModule} from './home/home.module';
import {MainModule} from './main/main.module';
import {ComponentsModule} from '../../components/components.module';
import {PaymentConfRemindersComponent} from './payment-conf-reminders/payment-conf-reminders.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from '../../directives/directives.module';

@NgModule({
    declarations: [
        AppComponent,
        PaymentConfRemindersComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        HomeModule,
        MainModule,
        ComponentsModule,
        DirectivesModule
    ]
})
export class AppModule {

}
