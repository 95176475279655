import {Route} from '@angular/router';
import {SubscriptionsComponent} from './subscriptions.component';
import {AddOptionsComponent} from './add-options/add-options.component';
import {RevokeOptionsComponent} from './revoke-options/revoke-options.component';
import {updateSubscriptionRoutes} from './update-subscription/update-subscription.route';
import {NewSubscriptionComponent} from './update-subscription/new-subscription/new-subscription.component';
import {SubscriptionsResolver} from './subscriptions.resolver';
import {SubscriptionsGuard} from './subscriptions.guard';
import {SuspendedAccountGuard} from '../../../suspendedAccount.guard';
import {SubscriptionsReadRoleGuard} from './subscriptions-read-role.guard';
import {SubscriptionsWriteRoleGuard} from './subscriptions-write-role.guard';
import {BlockingInvoicesGuard} from '../../blockingInvoices.guard';

export const subscriptionsRoutes: Array<Route> = [
    {
        path: '',
        canActivate: [SubscriptionsReadRoleGuard],
        component: SubscriptionsComponent
    },
    {
        path: 'add-options/:packSubscriptionId',
        component: AddOptionsComponent,
        resolve: {
            packSubscription: SubscriptionsResolver
        },
        canActivate: [SuspendedAccountGuard, SubscriptionsGuard, BlockingInvoicesGuard, SubscriptionsWriteRoleGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'revoke-options/:packSubscriptionId',
        component: RevokeOptionsComponent,
        resolve: {
            packSubscription: SubscriptionsResolver
        },
        canActivate: [SuspendedAccountGuard, SubscriptionsGuard, BlockingInvoicesGuard, SubscriptionsWriteRoleGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'new-subscription',
        canActivate: [SuspendedAccountGuard, SubscriptionsWriteRoleGuard, BlockingInvoicesGuard],
        component: NewSubscriptionComponent
    },
    {
        path: 'update/:packSubscriptionId',
        children: updateSubscriptionRoutes,
        resolve: {
            packSubscription: SubscriptionsResolver
        },
        canActivate: [SubscriptionsGuard, SubscriptionsWriteRoleGuard],
        runGuardsAndResolvers: 'always'
    }
];
