<h5 class="tariff-station">
    <span (click)="openStationsModal()"
          [ngClass]="{clickable: isClickable()}"
          [ngPlural]="tariff.linkedStationRefs?.length">
        <ng-template ngPluralCase="=0">
            {{ 'config.tariff.stationUsages.no'|translate }}
        </ng-template>
        <ng-template ngPluralCase="=1">
            {{ 'config.tariff.stationUsages.singular'|translate }}
        </ng-template>
        <ng-template ngPluralCase="other">
            {{ 'config.tariff.stationUsages.plural'|translate:{nbStations: (tariff.linkedStationRefs || []).length} }}
        </ng-template>
    </span>
</h5>

<izivia-popup #stationsModal [size]="'unset'">
    <div class="iziviaPopup">
        <div class="iziviaPopup-header">
            <i class="fas fa-exclamation iziviaPopup-header_icon"></i>
            <h2 class="iziviaPopup-header_title">{{ 'config.tariff.stationPopup.title' | translate:{tariffName: this.tariff.name} }}</h2>
        </div>
        <div class="iziviaPopup-content">
            <div>
                <p *ngFor="let station of stations">
                    {{'stations.visualId' | translate:{visualId: station.visualId} }}
                    <ng-container *ngIf="station.alias">
                        ( {{'stations.alias' | translate: {alias: station.alias} }} )
                    </ng-container>
                </p>
            </div>
        </div>
        <div class="iziviaPopup-buttons">
            <button type="button"
                    (click)="stationsModal.close()"
                    class="button color">
                {{ 'alert.ok' | translate }}
            </button>
        </div>
    </div>
</izivia-popup>
