<div *ngIf="chargeRecord" class="grid-x align-middle align-center">
    <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
    <div class="grid-y cell shrink charge-detail-block-info border-grey">
        <div class="cell grid-x space-bottom-8">
            <span class="cell auto charge-detail-block-info-label">{{'chargeConsumptions.detail.startDate' | translate}} :</span>
            <span class="cell auto charge-detail-block-info-text">{{chargeRecord.startDate | date: 'short'}}</span>
        </div>
        <div class="cell grid-x space-bottom-8">
            <span class="cell auto charge-detail-block-info-label">{{'chargeConsumptions.detail.pass' | translate}} :</span>
            <span class="cell auto charge-detail-block-info-text">{{chargeRecord.startingMediumPublicCode}}</span>
        </div>
        <div class="space-bottom-8">
            <div class="cell grid-x">
                <span class="cell auto charge-detail-block-info-label">{{'chargeConsumptions.detail.time' | translate}}
                    :</span>
                <span
                    class="cell auto charge-detail-block-info-text">{{chargeRecord.duration | fleetDuration}}</span>
            </div>
            <span class="cell note">{{'chargeConsumptions.detail.timeInd' | translate}}</span>
        </div>
        <div class="cell grid-x">
            <span class="cell auto charge-detail-block-info-label">{{'chargeConsumptions.detail.energy' | translate}} :</span>
            <span class="cell auto charge-detail-block-info-text">{{chargeRecord.energyConsumedInWh || 0 | energy}}</span>
        </div>
    </div>
    <charge-record-chart
        class="cell auto"
        [chargeRecordId]="chargeRecord.id"
        [startDate]="chargeRecord.startDate"
        [stopDate]="chargeRecord.stopDate"></charge-record-chart>

    <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
</div>
