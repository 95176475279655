import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {MetaConsumptionDto} from '../../domain/meta/metaConsumptionDto';
import {ConsumptionCriteria} from '../../domain/consumptionCriteria';
import { HttpParamsUtil } from 'lib-front';
import {MetaTotalConsumption} from '../../domain/meta/total/metaTotalConsumption';
import {MetaConsumptionsKpi} from '../../domain/meta/kpi/metaConsumptionsKpi';

@Injectable()
export class ProConsumptionHttpService {
    private proConsumptionBaseUrl: string = '/v2/pro/consumptions';

    constructor(private http: HttpClient) {
    }

    public findConsumptions(consumptionCriteria: ConsumptionCriteria): Observable<MetaConsumptionDto[]> {
        const params = HttpParamsUtil.toHttpParams(consumptionCriteria);
        return this.http.get<MetaConsumptionDto[]>(this.proConsumptionBaseUrl, {params: params});
    }

    public findConsumptionsKpi(consumptionCriteria: ConsumptionCriteria): Observable<MetaConsumptionsKpi> {
        const params = HttpParamsUtil.toHttpParams(consumptionCriteria);
        return this.http.get<MetaConsumptionsKpi>('/v2/pro/consumptionsKpi', {params: params});
    }

    public findNewTotalConsumptions(): Observable<MetaTotalConsumption[]> {
        return this.http.get<MetaTotalConsumption[]>('/v2/pro/totalConsumptions');
    }
}
