import {Component, Input} from '@angular/core';
import {MetaConsumptionDto} from '../../../domain/meta/metaConsumptionDto';

@Component({
    selector: 'consumption-details-data[consumption]',
    templateUrl: './consumption-details-data.component.html',
    styleUrls: ['./consumption-details-data.component.scss']
})

export class ConsumptionDetailsDataComponent {
    @Input() consumption: MetaConsumptionDto;

    constructor() {
    }
}
