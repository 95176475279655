<div class="tariff-application-list">
    <div class="grid-x grid-padding-x tariff-application align-middle"
         [class.currently-selected]="selectedTariffId === tariff.id"
         (click)="select(tariff)">
        <div class="cell auto">
            {{'config.tariff.modification.applyUntil' | translate:{applicationDate: untilDate | fleetMomentToStringDatePipe} }}
        </div>
        <div class="cell shrink align-right active-status">
            {{'config.tariff.status.active' | translate}}
        </div>
    </div>
    <div class="grid-x tariff-application"
         [class.currently-selected]="selectedTariffId === tariff?.modification?.newTariff?.id"
         (click)="select(tariff.modification.newTariff)">
        <div class="cell auto">
            {{'config.tariff.modification.applyStarting' | translate:{applicationDate: tariff.modification.applicationDate | fleetMomentToStringDatePipe} }}
        </div>

        <div class="cell shrink">
            <div *ngIf="tariff.modification.status === TariffModificationStatus.DRAFT" class="grid-x">
                <div class="cell shrink align-right inner-space-right-5">
                    <button (click)="editNewTariff(); $event.stopImmediatePropagation()"
                            [iziviaLoading]="false"
                            class="cell shrink button icon-btn secondary"><i class="fas fa-pen"></i></button>
                </div>

                <div class="cell shrink align-right">
                    <button (click)="deleteNewTariff(); $event.stopImmediatePropagation()"
                            [iziviaLoading]="false"
                            class="cell shrink button icon-btn secondary"><i class="fas fa-trash-alt"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>
