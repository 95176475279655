<ng-container *ngIf="consumption" [ngSwitch]="consumption.kind">
    <charge-consumption-details *ngSwitchCase="ConsumptionKind.CHARGE"
                                [consumption]="consumption">
    </charge-consumption-details>

    <reservation-consumption-details *ngSwitchCase="ConsumptionKind.RESERVATION"
                                     [consumption]="consumption">
    </reservation-consumption-details>

    <otp-consumption-details *ngSwitchCase="ConsumptionKind.OTP"
                             [consumption]="consumption">
    </otp-consumption-details>

    <service-consumption-details *ngSwitchCase="ConsumptionKind.SERVICE"
                                 [consumption]="consumption">
    </service-consumption-details>

    <div *ngSwitchDefault
         class="grid-x align-middle align-center">
        <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
        <div class="grid-y cell shrink consumption-detail-block-info border-grey">
            <consumption-details-data [consumption]="consumption"></consumption-details-data>
        </div>
        <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
    </div>
</ng-container>
