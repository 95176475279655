import {NgModule} from '@angular/core';
import {FleetImportHttpService} from './fleetImportHttp.service';
import {FleetTokenHttpService} from './tokenHttp.service';
import {ProConsumptionHttpService} from './proConsumptionHttp.service';

const SERVICES = [
    FleetImportHttpService,
    FleetTokenHttpService,
    ProConsumptionHttpService
];

@NgModule({
    providers: SERVICES
})
export class HttpServicesModule { }
