import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {of, Subscription} from 'rxjs';
import {
    ChargeFilterKind,
    ChargeRecordCriteria,
    ChargeRecordDto,
    ChargeRecordDtoStatus,
    ChargeRecordSortingField,
    ChargeRecordStats,
    ConsumptionDisplayMode,
    FrontChargeRecordHttpService,
    FrontEndFleetConfig,
    FrontInvoiceBusinessService,
    FrontInvoiceHttpService,
    FrontSessionBusinessService,
    Reporting
} from 'lib-front';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {groupBy} from 'lodash-es';
import {VehicleChargeData} from 'lib-front';
import {AlertService} from '../../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {FrontEndService} from '../../../../../services/frontEnd.service';

@Component({
    selector: 'charge-tab',
    templateUrl: './chargeTab.component.html',
    styleUrls: ['./chargeTab.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ChargeTabComponent implements OnInit {

    private _chargeRecords: Array<ChargeRecordDto>;
    public chargeRecordsGroupedBy: { [groupedByValue: string]: ChargeRecordDto[] };
    public chargeRecordGroupedByValues: string[];
    public Reporting = Reporting;

    chargeRecordInvoicePdfUrls = {};

    @Input()
    set chargeRecords(chargeRecordDtos: Array<ChargeRecordDto>) {
        if (chargeRecordDtos) {
            this._chargeRecords = chargeRecordDtos;
            this.getInvoiceUrlsForChargeRecords(chargeRecordDtos);
            this.groupChargeRecordsByGroupCriteria();
        }
    }

    public get chargeRecords(): Array<ChargeRecordDto> {
        return this._chargeRecords;
    }

    @Input()
    set statsResult(chargeRecordStats: ChargeRecordStats) {
        if (chargeRecordStats) {
            this._statsResult = chargeRecordStats;
            this.maxPage = Math.max(Math.ceil(this._statsResult.nbCharge / this.criteria.limit), 1);
        }
    }

    get statsResult(): ChargeRecordStats {
        return this._statsResult;
    }
    private _statsResult: ChargeRecordStats;

    @Input() searchChargeSubscription: Subscription;
    @Input() searchStatsSubscription: Subscription;
    @Input() mode: ChargeFilterKind;
    @Input() fleetConfig: FrontEndFleetConfig;
    @Input() chargeStatus: Array<ChargeRecordDtoStatus>;
    @Output() searchChargeRecords = new EventEmitter<ChargeRecordCriteria>(true);
    @Output() searchStats = new EventEmitter<ChargeRecordCriteria>(true);

    public criteria: ChargeRecordCriteria;

    // pass enum to template
    public ChargeRecordSortingFieldEnum = ChargeRecordSortingField;

    page: number = 1;
    maxPage: number;

    chargeRecordSelectedId: string;
    status: ChargeRecordDtoStatus = null;
    frontEndConfig: FrontEndFleetConfig;
    consumptionDisplayMode = ConsumptionDisplayMode;

    public constructor(private readonly chargeService: FrontChargeRecordHttpService,
        private readonly invoiceService: FrontInvoiceHttpService,
        private readonly invoiceBusiness: FrontInvoiceBusinessService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly frontEndService: FrontEndService) {

    }
    public ngOnInit(): void {
        this.criteria = {
            filterKind: this.mode,
            sort: this.getStatusSort(),
            from: moment().startOf('month'),
            to: moment(),
            skip: 0,
            limit: 10,
        };
        this.search(this.criteria);
        this.frontEndService.currentFrontEndInfo$.subscribe(config => this.frontEndConfig = config.fleetConfig);
    }

    public search(criteria: ChargeRecordCriteria, page = 1, onlySearchCharges: boolean = false): void {
        this.criteria.skip = (page - 1) * this.criteria.limit;
        this.page = page;
        this.searchChargeRecords.emit(this.criteria);
        if (!onlySearchCharges) {
            this.searchStats.emit(this.criteria);
        }
    }

    public onGroupingChange(grouping: ChargeRecordSortingField): void {
        this.criteria.sort = grouping;
        this.search(this.criteria, 1, true);
    }

    public getStatusSort(): ChargeRecordSortingField {
        return this.mode === 'FLEET' ?
            this.ChargeRecordSortingFieldEnum.STATUS :
            this.ChargeRecordSortingFieldEnum.FINANCIAL_STATUS;
    }

    public export(criteria: ChargeRecordCriteria): void {
        const params = this.chargeService.getParamsForCriteria(criteria);
        window.open(`/api/chargeRecord/data/export/csv?${params.toString()}`);
    }

    public selectChargeDetails(chargeRecord: ChargeRecordDto) {
        if (chargeRecord.id !== this.chargeRecordSelectedId) {
            if (this.fleetConfig.displayChargeDetailDisplayWarning) {
                this.alertService.confirm(
                    this.translateService.instant('chargeConsumptions.chargeDetailDisplayWarning.message'),
                    this.translateService.instant('chargeConsumptions.chargeDetailDisplayWarning.title'),
                    this.translateService.instant('chargeConsumptions.chargeDetailDisplayWarning.confirm'),
                    this.translateService.instant('chargeConsumptions.chargeDetailDisplayWarning.cancel')
                ).pipe(
                    filter(accepted => accepted),
                ).subscribe(_ => {
                    this.chargeRecordSelectedId = chargeRecord.id;
                });
            } else {
                this.chargeRecordSelectedId = chargeRecord.id;
            }
        } else {
            this.chargeRecordSelectedId = null;
        }
    }

    changeStatus(newStatus: ChargeRecordDtoStatus) {
        this.status = newStatus;
        if (!newStatus) {
            this.criteria.statuses = [];
        } else {
            this.criteria.statuses = [newStatus];
        }
    }

    private getInvoiceUrlsForChargeRecords(chargeRecordDtos: Array<ChargeRecordDto>) {
        this.chargeRecordInvoicePdfUrls = {};

        this.sessionBusinessService.currentConnectedUser$.pipe(
            map(connectedUser => connectedUser?.user._id),
            tap(
                userId =>
                    chargeRecordDtos.filter(
                        chargeRecord => chargeRecord.chargeRecordDtoStatus  === ChargeRecordDtoStatus.INVOICED
                    ).forEach(
                        chargeRecord => this.loadInvoiceForChargeRecord(userId, chargeRecord)
                    )
            )
        ).subscribe();

    }

    private loadInvoiceForChargeRecord(userId: string, chargeRecord: ChargeRecordDto) {
        if (this.mode === ChargeFilterKind.FLEET) {
            this.invoiceService
                .findInvoiceById(userId, chargeRecord.invoiceRef)
                .pipe(
                    switchMap(
                        invoice => of(this.invoiceBusiness.getInvoicePdfUrlFromInvoiceWrapper(invoice))
                    )
                ).subscribe(
                    url => this.chargeRecordInvoicePdfUrls[chargeRecord.id] = url
                );
        }
    }

    private groupChargeRecordsByGroupCriteria(): void {
        let groupByField: string;

        switch (this.criteria.sort) {
            case 'STATUS':
                groupByField = 'chargeRecordDtoStatus';
                break;
            case 'CHARGE_AREA':
                groupByField = 'infraChargeDto.chargeArea.name';
                break;
            case 'MEDIA':
                groupByField = 'startingMediumPublicCode';
                break;
            case 'MEDIA_OWNERS':
                groupByField = 'startingMediumOwners[0].name';
                break;
            default:
                break;
        }

        this.chargeRecordsGroupedBy = groupBy(this._chargeRecords, groupByField);
        this.chargeRecordGroupedByValues = Object.keys(this.chargeRecordsGroupedBy);
    }

    public trackByVehicleId(index: number, vehicleChargeData: VehicleChargeData) {
        return vehicleChargeData?.vehicleId ?? index;
    }
}

