<div
    *ngIf="rolesLoaded"
    class="cell auto app-view-content">
    <div class="callout warning">
        <i class="fas fa-exclamation-circle callout-icon"></i>
        <p>{{'home.screenTooSmall' | translate}}</p>
    </div>

    <div class="grid-x grid-padding-x grid-margin-y cell auto home-container" *ngIf="!isStationManager">
        <div class="grid-y grid-margin-y cell auto">
            <home-invoice *ngIf="fleetConfig?.showInvoiceSection && canReadResource(SubscriberRoleType.INVOICES)"
                          [isStationManager]="isStationManager"
                          [refundManager]="isRefundManager"
                          class="fleet-manager"></home-invoice>
            <home-map *ngIf="!hiddenMap" class="fleet-manager"></home-map>
        </div>
        <div class="grid-y grid-margin-y cell auto">
            <home-charge-consumptions
                *ngIf="canReadResource(SubscriberRoleType.CHARGES)"
                class="fleet-manager" [routerLink]="'/main/chargeConsumptions'"></home-charge-consumptions>
            <home-media *ngIf="canReadResource(SubscriberRoleType.PASS)"
                        class="fleet-manager" [routerLink]="'/main/media'"></home-media>
            <home-fleet-collaborators
                *ngIf="canReadResource(SubscriberRoleType.CONFIGURATION_TAB)"
                class="fleet-manager" [routerLink]="'/main/configuration/collaborators'"></home-fleet-collaborators>
        </div>
        <div class="grid-y grid-margin-y cell auto ">
            <home-subscription class="fleet-manager"
                               *ngIf="canReadResource(SubscriberRoleType.SUBSCRIPTIONS)"
                               [routerLink]="'/main/subscriptions'"></home-subscription>
            <home-station-management class="fleet-manager" [routerLink]="'/main/chargeAreas'"
                                     *ngIf="isStationManager && canReadResource(SubscriberRoleType.CHARGING_POINTS) else contactCard;"></home-station-management>
            <ng-template #contactCard>
                <div *ngIf="fleetConfig.stationInstallationProposed"
                     class=" cell shrink">
                    <a class="grid-x align-middle align-center home-card home-card-content contact min-height-250 mailto"
                       [href]="'mailto:contact.commercial@izivia.com'">
                        <img class="cell shrink" src="/assets/png/car.png">
                        <div class="cell auto grid-x align-center">
                            <span class="contact-text dspl-block">{{'home.contactUs.title' | translate}}</span>
                        </div>
                    </a>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="grid-x grid-padding-x grid-margin-y cell auto home-container" *ngIf="isStationManager">
        <div class="grid-y grid-margin-y cell auto">
            <home-charge-consumptions
                *ngIf="canReadResource(SubscriberRoleType.CHARGES)"
                class="fleet-n-station-manager" [routerLink]="'/main/chargeConsumptions'"></home-charge-consumptions>
            <home-station-management
                *ngIf="canReadResource(SubscriberRoleType.CHARGING_POINTS)"
                class="fleet-n-station-manager" [routerLink]="'/main/chargeAreas'"></home-station-management>
        </div>
        <div class="grid-y grid-margin-y cell auto">
            <home-media *ngIf="canReadResource(SubscriberRoleType.PASS)"
                        class="fleet-n-station-manager" [routerLink]="'/main/media'"></home-media>
            <home-fleet-collaborators
                *ngIf="canReadResource(SubscriberRoleType.CONFIGURATION_TAB) && canReadResource(SubscriberRoleType.COLLABORATORS) && canReadResource(SubscriberRoleType.CHARGING_POINTS)"
                class="fleet-n-station-manager"
                [routerLink]="'/main/configuration/collaborators'"></home-fleet-collaborators>
        </div>
        <div class="grid-y grid-margin-y cell auto">
            <home-subscription class="fleet-n-station-manager"
                               *ngIf="canReadResource(SubscriberRoleType.SUBSCRIPTIONS)"
                               [routerLink]="'/main/subscriptions'"></home-subscription>
            <home-invoice *ngIf="fleetConfig?.showInvoiceSection && canReadResource(SubscriberRoleType.INVOICES)"
                          [isStationManager]="isStationManager"
                          [refundManager]="isRefundManager"
                          class="fleet-n-station-manager"></home-invoice>
        </div>
    </div>

</div>
