import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PromoTokenWithSubscriptionPack} from '../../domain/promoTokenWithSubscriptionPack';
import {FrontPackSubscriptionHttpService, SubscriptionMode} from 'lib-front';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../../services/utils/notification.service';

@Component({
    selector: 'promo-token-input',
    templateUrl: './promo-token-input.component.html',
    styleUrls: ['./promo-token-input.component.scss']
})
export class PromoTokenInputComponent {
    promoToken: string;
    lastPromoToken: string;
    promoTokenProcessing: boolean = false;
    promoTokenValid: boolean;
    promoTokenErrorMessage: string;

    @Input() otherSubscriptionPackRefs: string[];
    @Input() subscriptionMode: SubscriptionMode;
    @Input() email: string;
    @Input() canCancelToken: boolean = true;

    @Output() promoTokenChange: EventEmitter<PromoTokenWithSubscriptionPack> = new EventEmitter<PromoTokenWithSubscriptionPack>();

    constructor(private packSubscriptionService: FrontPackSubscriptionHttpService,
        private notificationService: NotificationService) {
    }

    resetValidity() {
        this.promoTokenValid = null;
    }

    removePromoToken() {
        this.promoToken = '';
        this.lastPromoToken = '';
        this.resetValidity();
        const promoTokenWithSubscriptionPack = new PromoTokenWithSubscriptionPack(false,
            this.promoToken,
            []);
        this.promoTokenChange.emit(promoTokenWithSubscriptionPack);
    }

    showPromoTokenError(): boolean {
        return this.promoTokenValid === false ||
            (this.promoTokenValid === null &&
                !!this.promoToken &&
                this.promoToken === this.lastPromoToken &&
                !!this.promoTokenErrorMessage);
    }

    validatePromoToken() {
        this.promoTokenProcessing = true;
        this.lastPromoToken = this.promoToken;
        this.promoTokenErrorMessage = null;
        this.packSubscriptionService.checkPromoSubscription(this.promoToken, this.subscriptionMode,
            this.otherSubscriptionPackRefs, this.email)
            .pipe(finalize(() => this.promoTokenProcessing = false))
            .subscribe(
                subscriptionPackWithCguDto => {
                    this.promoTokenValid = true;
                    const promoTokenWithSubscriptionPack = new PromoTokenWithSubscriptionPack(true,
                        this.promoToken,
                        subscriptionPackWithCguDto);
                    this.promoTokenChange.emit(promoTokenWithSubscriptionPack);
                    this.notificationService.success('token.promoToken.success');
                },
                httpError => {
                    this.promoTokenValid = false;
                    let errorKey = 'token.promoToken.error';
                    if (httpError.error?.labelKey) {
                        errorKey = httpError.error.labelKey;
                    }
                    this.promoTokenErrorMessage = errorKey;

                    const promoTokenWithSubscriptionPack = new PromoTokenWithSubscriptionPack(false,
                        this.promoToken,
                        []);
                    this.promoTokenChange.emit(promoTokenWithSubscriptionPack);
                    this.notificationService.error('token.promoToken.error');
                });
    }
}
