import {Route} from '@angular/router';
import {MediaComponent} from './media.component';
import {MediaActivateComponent} from './media-activate/media-activate.component';
import {MainMediaOrderComponent} from './main-media-order/main-media-order.component';
import {MediaFollowOrderComponent} from './media-follow-order/media-follow-order.component';
import {SuspendedAccountGuard} from '../../../suspendedAccount.guard';
import {MediaReadRoleGuard} from './media-read-role.guard';
import {MediaWriteRoleGuard} from './media-write-role.guard';
import {BlockingInvoicesGuard} from '../../blockingInvoices.guard';

export const mediaRoutes: Array<Route> = [
    {path: '', canActivate: [MediaReadRoleGuard], component: MediaComponent},
    {path: 'activate', canActivate: [MediaWriteRoleGuard], component: MediaActivateComponent},
    {path: 'order', canActivate: [SuspendedAccountGuard, MediaWriteRoleGuard, BlockingInvoicesGuard],  component: MainMediaOrderComponent},
    {path: 'followOrder', canActivate: [MediaReadRoleGuard], component: MediaFollowOrderComponent}
];
