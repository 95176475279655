import {CalendarNativeDateFormatter, DateFormatterParams} from 'angular-calendar';
import {Injectable} from '@angular/core';

@Injectable()
export class FleetCalendarUtils extends CalendarNativeDateFormatter {
    public weekViewHour({ date, locale }: DateFormatterParams): string {
        return new Intl.DateTimeFormat(locale, {
            hour: 'numeric',
            minute: 'numeric',
        }).format(date);
    }

    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return new Intl.DateTimeFormat(locale, {
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }
}
