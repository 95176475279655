<div class="cell shrink grid-y align-middle align-center login-container">
    <div class="common-block">
        <h2>{{'forgottenPwd.title' | translate}}</h2>
        <h4 *ngIf="!isTokenValid">{{'forgottenPwd.token' | translate}}</h4>
        <form *ngIf="isTokenValid" #passwordForm='ngForm' class="grid-x align-middle">
            <label class="cell grid-y label-login secondary-password">
                <span class="label-container">{{'informations.administrator.password.title' | translate}}</span>

                <show-hide-password-input
                    [(ngModel)]="password"
                    [autocomplete]="'new-password'"
                    name="pwd"
                    #pwd='ngModel'
                ></show-hide-password-input>

            </label>
            <div class="cell grid-y padding-top-3">
                <password-validation-errors
                    [errors]="pwd.control.errors"
                    [definitions]="passwordValidatorDefinitions"
                >
                </password-validation-errors>
            </div>
            <div class="submitForm grid-x align-center">
                <button
                    class="button"
                    type="submit"
                    (click)="resetPassword()"
                    [iziviaLoading]="resettingPassword"
                    [disabled]="passwordForm.invalid">
                    {{'forgottenPwd.validate' | translate}}
                </button>
            </div>

        </form>
    </div>
</div>
