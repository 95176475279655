import {Injectable} from '@angular/core';
import {noop, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';

@Injectable()
export class NotificationService {
    private translateService: TranslateService;
    private toastrService: ToastrService;

    constructor(translateService: TranslateService, toastrService: ToastrService) {
        this.translateService = translateService;
        this.toastrService = toastrService;
    }

    public error(key: string, params?: Object): void {
        this.translateService.get(key, params)
            .pipe(
                map(message => this.toastrService.error(message)),
                map(() => noop())).subscribe();
    }

    public success(key: string, params?: Object): void {
        this.translateService.get(key, params)
            .pipe(
                map(message => this.toastrService.success(message)),
                map(() => noop())).subscribe();
    }

    public warning(key: string, params?: Object): void {
        this.translateService.get(key, params)
            .pipe(
                map(message => this.toastrService.warning(message)),
                map(() => noop())).subscribe();
    }
}
