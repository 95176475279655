<div class="cell auto has-space">
    <div class="grid-y space-bottom-32" [hidden]="invoicesBlocking.length === 0">
        <h3 class="grid-x secondary block-title space-bottom-16">
            {{'invoice.blockingWaitForPayment.title' | translate}}
        </h3>
        <div class="cell small-24">
            <table>
                <thead>
                <tr>
                    <th>{{'invoice.waitForPayment.table.date' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.number' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.type' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.totalHT' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.totalTTC' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.operator' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.status' | translate}}</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody class="border">
                <tr *ngFor="let invoiceBlocking of invoicesBlocking">
                    <td>{{invoiceBlocking.invoiceDate | date : 'short'}}</td>
                    <td>{{invoiceBlocking.invoiceId}}</td>
                    <td>
                        <div *ngFor="let row of invoiceBlocking.rows">{{row.label | translate}}</div>
                    </td>
                    <td>{{invoiceBlocking.grossAmount | currency:'EUR': invoiceBlocking?.currencyUnit || ''}}</td>
                    <td>{{invoiceBlocking.netAmount | currency:'EUR': invoiceBlocking?.currencyUnit || ''}}</td>
                    <td>{{invoiceBlocking.providerName}}</td>
                    <td [innerHTML]="invoiceBlocking | fleetInvoiceStatus"></td>
                    <td>
                        <a class="button round eye" target="_blank" rel="noopener noreferrer"
                           [href]="showInvoice(invoiceBlocking)"><i class="fas fa-eye"></i></a>
                    </td>
                    <td>
                        <button *ngIf="canPay(invoiceBlocking)"
                                class="button secondary color" (click)="redirectPayment(invoiceBlocking._id)">
                            {{'invoice.waitForPayment.table.pay' | translate}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="grid-y space-bottom-32" [hidden]="invoices.length === 0">
        <h3 class="grid-x align-middle secondary block-title space-bottom-16">
            {{'invoice.waitForPayment.title' | translate}}
        </h3>
        <div class="cell small-24">
            <table>
                <thead>
                <tr>
                    <th>{{'invoice.waitForPayment.table.date' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.number' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.type' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.totalHT' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.totalTTC' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.operator' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.status' | translate}}</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody class="border">
                <tr *ngFor="let invoice of invoices">
                    <td>{{invoice.invoiceDate | date : 'short'}}</td>
                    <td>{{invoice.invoiceId}}</td>
                    <td>
                        <div *ngFor="let row of invoice.rows">{{row.label | translate}}</div>
                    </td>
                    <td>{{invoice.grossAmount | currency:'EUR': invoice?.currencyUnit || ''}}</td>
                    <td>{{invoice.netAmount | currency:'EUR': invoice?.currencyUnit || ''}}</td>
                    <td>{{invoice.providerName}}</td>
                    <td [innerHTML]="invoice | fleetInvoiceStatus"></td>
                    <td>
                        <a class="button round eye" target="_blank" rel="noopener noreferrer"
                           [href]="showInvoice(invoice)"><i class="fas fa-eye"></i></a>
                    </td>
                    <td>
                        <button *ngIf="canPay(invoice)"
                                class="button secondary color" (click)="redirectPayment(invoice._id)">
                            {{'invoice.waitForPayment.table.pay' | translate}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="grid-y space-bottom-32">
        <h3 class="grid-x align-middle secondary block-title space-bottom-16">
            <ng-container *ngIf="totalConsumptionsPriceWithVat == null">
                {{ 'invoice.consumptions.loadingTitle' | translate }}
            </ng-container>

            <ng-container *ngIf="totalConsumptionsPriceWithVat != null">
                {{'invoice.consumptions.title' | translate: { totalTTC: totalConsumptionsPriceWithVat | currency:'EUR', date: nextInvoiceScheduledDate | date: 'dd/MM/yy' } }}
            </ng-container>
        </h3>

        <total-consumption-table *ngIf="!loadingConsumptions else spinner"
                                 [totalConsumptions]="totalConsumptions"></total-consumption-table>

        <div>
            <a class="grid-x shrink align-justify button secondary" [routerLink]="'history'">
                {{'invoice.payment.button' | translate}}
            </a>
        </div>
    </div>

    <div class="grid-y" *ngIf="fleetConfig?.showPaymentSection">
        <div class="space-bottom-16 space-top-32">
            <h3 class="grid-x align-middle secondary block-title">
                {{'invoice.paymentConf.title' | translate}}
            </h3>
            <div class="cell small-24 space-top-16" [hidden]="paymentConfs.length > 0 || managedAccount">
                <div class="info cell auto common-block force-space-bottom-0">
                    {{'invoice.paymentConf.empty' | translate}}
                </div>
            </div>
            <div class="cell small-24 space-top-16" *ngIf="paymentConfs.length === 0 && managedAccount">
                <div class="info cell auto common-block force-space-bottom-0">
                    {{'invoice.paymentConf.accountManager' | translate}}
                </div>
            </div>
        </div>

        <div class="cell small-24 grid-x grid-margin-x align-middle payment-mode-choice" *ngFor="let paymentConf of paymentConfs">
            <div class="cell shrink">
                <img class="payment-block-img" src="/assets/png/credit-card.png" width="100"
                     *ngIf="paymentConf.paymentType === 'CREDIT_CARD_AUTHORIZATION'|| paymentConf.paymentType === 'CREDIT_CARD_RECURRING'"/>
                <img class="payment-block-img" *ngIf="paymentConf.paymentType === 'SEPA'" src="/assets/png/sepa.png" width="100"/>
            </div>

            <div class="cell auto grid-x">
                <label class="cell small-24">{{'invoice.paymentConf.operator' | translate}}</label>
                <h6 class="cell small-24">{{paymentConf.emoName}}</h6>
            </div>

            <div class="cell auto grid-x">
                <label class="cell small-24">{{'payment.' + paymentConf.paymentType | translate}}</label>
                <h6 class="cell small-24">{{paymentConf.name}}</h6>
                <div class="cell small-24" *ngIf="'CREDIT_CARD_RECURRING' === paymentConf.paymentType && !!paymentConf.specificData">
                    {{'invoice.paymentConf.specificData.cb' | translate:{lastCardNumber:paymentConf.specificData.lastCardNumber, expirationDate: paymentConf.specificData.expirationDate | date:'MM/yy'} }}
                </div>
            </div>

            <div class="cell shrink" *ngIf="canDeletePaymentConf(paymentConf)">
                <button class="cell shrink button secondary round" type="button"
                        *ngIf="!fleetConfig.recurringPaymentMandatoryByEmo[paymentConf.emoRef]"
                        (click)="deleteSavedPayment(paymentConf)"
                        [iziviaLoading]="deletingPaymentConf">
                    <i class="fas fa-trash"></i>
                </button>
                <button class="cell shrink button secondary round" type="button"
                        *ngIf="fleetConfig.recurringPaymentMandatoryByEmo[paymentConf.emoRef]"
                        (click)="replacePayment(paymentConf)"
                        [iziviaLoading]="deletingPaymentConf">
                    <i class="fas fa-sync"></i>
                </button>
            </div>
        </div>

        <div class="grid-x space-top-16" *ngIf="!managedAccount">
            <h5 class="cell small-24 text-center">{{'invoice.paymentConf.config.title' | translate}}</h5>
            <div class="cell small-24 grid-x small-up-4 align-center">
                <div class="grid-y cell text-center payment-available-block space-bottom-0"
                     *ngFor="let paymentConfAvailable of paymentConfsAvailable">
                    <div class="cell shrink operator-name">{{'invoice.paymentConf.operator' | translate}} :
                        {{paymentConfAvailable.emoName}}
                    </div>
                    <div class="cell shrink payment-by">{{'payment.' + paymentConfAvailable.paymentType | translate}}
                    </div>
                    <div class="cell auto"></div>
                    <button class="cell shrink button color" type="button"
                            (click)="openAddSavedPaymentModal(paymentConfAvailable)">
                        {{'invoice.paymentConf.add' | translate}}
                    </button>
                </div>
            </div>
            <div class="cell small-24 medium-12 space-top-16 element-centered" [hidden]="paymentConfsAvailable.length > 0">
                <div class="info cell auto common-block force-space-bottom-0">
                    {{'invoice.paymentConf.config.empty' | translate}}
                </div>
            </div>
        </div>
    </div>

    <payment-options *ngIf="invoiceForm$ && fleetConfig?.showPaymentSection"
                     [payment-request-infos]="invoiceForm$ | async"></payment-options>

</div>

<izivia-popup #addSavedPaymentModal [size]="'unset'">
    <add-payment-popup *ngIf="selectedPaymentConf"
                       [selectedPaymentConf]="selectedPaymentConf"
                       (modalConfirm)="addSavedPayment($event)"
                       (modalClose)="addSavedPaymentModal.close()"></add-payment-popup>
</izivia-popup>

<ng-template #spinner>
    <fleet-spinner></fleet-spinner>
</ng-template>
