import {NgModule} from '@angular/core';
import {ExpenseReportSearchComponent} from './expense-report-search.component';
import {ExpenseReportDetailComponent} from './expense-report-detail/expense-report-detail.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExpenseReportGroupCardComponent} from '../../../../components/expense-report-group-card/expense-report-group-card.component';
import {CommonModule} from '@angular/common';
import {LibFrontModule} from 'lib-front';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../../../../components/components.module';
import {ExpenseReportRgpdAcceptanceComponent} from './expense-report-rgpd-acceptance/expense-report-rgpd-acceptance.component';
import {ExpenseReportReadRoleGuard} from './expense-report-read-role.guard';
import {DirectivesModule} from '../../../../directives/directives.module';

@NgModule({
    declarations: [
        ExpenseReportSearchComponent,
        ExpenseReportDetailComponent,
        ExpenseReportGroupCardComponent,
        ExpenseReportRgpdAcceptanceComponent
    ],
    exports: [
        ExpenseReportRgpdAcceptanceComponent
    ],
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
        LibFrontModule,
        RouterModule,
        FormsModule,
        ComponentsModule,
        DirectivesModule
    ],
    providers: [
        ExpenseReportReadRoleGuard
    ]
})
export class ExpenseReportModule {

}
