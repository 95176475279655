import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {MediaSummaryDto} from 'lib-front';

@Component({
    selector: 'suggest',
    templateUrl: './suggest.component.html',
    styleUrls: ['./suggest.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SuggestComponent),
            multi: true
        }
    ]
})
export class SuggestComponent implements ControlValueAccessor, OnInit {
    @Input() placeholder: string;
    @Input() autofocus: boolean = false;
    @Input() iconTime: boolean = false;
    @Input() itemValueGetter: (any) => any;
    @Input() itemNameGetter: (any) => any;
    @Input() valueToNameGetter: (any) => any;
    @Output() searchChange: EventEmitter<string> = new EventEmitter();

    @Input() set results(value: any[]) {
        if (value && value.length === 1 && value[0] === this.selectedValue) {
            this._results = [];
        } else {
            this._results = value;
        }
    }

    @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

    public queryField: UntypedFormControl = new UntypedFormControl();

    private onChange: (_: MediaSummaryDto) => void;
    private onTouched: () => void;
    private selectedValue: any;

    private _results: any[] = [];

    constructor() {}

    ngOnInit() {
        this.queryField.valueChanges.pipe(
            filter(value => !!value),
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe( query => this.searchItems(query));
    }

    searchItems(query) {
        if (query == null) {
            query = '';
        }

        this.searchChange.emit(query);
    }

    selectValue(item: any) {
        this.selectedValue = this.itemValueGetter(item);
        this.queryField.setValue(this.itemNameGetter(item));
        this.onTouched();
        this.onChange(this.selectedValue);
        this.cleanResults();
    }

    cleanResults() {
        this.results = [];
    }

    public registerOnChange(onChange: (_: MediaSummaryDto) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public writeValue(selectedValue: any): void {
        this.selectedValue = selectedValue;

        if (this.valueToNameGetter) {
            this.queryField.setValue(this.valueToNameGetter(selectedValue));
        }
    }

    get results() {
        return this._results;
    }
}
