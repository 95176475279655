<div class="iziviaPopup"
     role="dialog"
     tabindex="-1">
    <div class="iziviaPopup-header">
        <i class="fas fa-exclamation iziviaPopup-header_icon"></i>
        <div class="iziviaPopup-header_title">
            {{'media.deactivatePopup.title' | translate:{
            mediaCode: mediaToDeactivate.code,
            owner: mediaToDeactivate.userFirstName + ' ' + mediaToDeactivate.userLastName
        } }}
        </div>
    </div>
    <div class="iziviaPopup-content">
        <div class="popup-content_message"
             innerHTML="{{'media.deactivatePopup.message' | translate }}"></div>
    </div>

    <div class="iziviaPopup-buttons grid-x">
        <div class="cell auto">
            <div class="grid-x grid-margin-x">
                <button class="cell shrink button"
                        (click)="deactivateMedia(DisableReason.OTHER)"
                        [iziviaLoading]="deactivatingMedia === DisableReason.OTHER"
                        [disabled]="deactivatingMedia === DisableReason.LOST_OR_STOLEN">
                    {{'media.deactivatePopup.button.deactivate' | translate}}</button>
                <button class="cell shrink button secondary"
                        (click)="deactivateMedia(DisableReason.LOST_OR_STOLEN)"
                        [iziviaLoading]="deactivatingMedia === DisableReason.LOST_OR_STOLEN"
                        [disabled]="deactivatingMedia === DisableReason.OTHER">
                    {{'media.deactivatePopup.button.declareLostOrStolen' | translate}}</button>
            </div>
        </div>
        <button class="cell shrink button secondary loseButton"
                (click)="onCancel()"
                [disabled]="deactivatingMedia">
            {{'media.deactivatePopup.button.cancel' | translate}}</button>
    </div>
</div>
