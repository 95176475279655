import {Component, Input} from '@angular/core';
import { InfraStatus } from 'lib-front';

@Component({
    selector: 'station-status-indicator',
    templateUrl: './station-status-indicator.component.html',
    styleUrls: ['./station-status-indicator.component.scss']
})
export class InfraStatusIndicatorComponent {
    @Input() status: InfraStatus;
    @Input() number: number;

    constructor() {
    }


}
