import {NgModule} from '@angular/core';
import {ConfigurationTabGuard} from './configuration-tab.guard';

const GUARD = [
    ConfigurationTabGuard
];

@NgModule({
    declarations: [],
    exports: [],
    providers: [GUARD]
})
export class GuardModule {}
