<form #updateSubscriptionMediasForm="ngForm">
    <h3 class="cell small-24 secondary block-title space-bottom-8">
        {{'update.medias.title' | translate}}
    </h3>
    <h5 *ngIf="!isOrderAllow && !isOrderAllowfromAccount" class="info cell auto common-block force-space-bottom-0">
        {{'update.subscription.pass.order.notAllow' | translate}}
    </h5>
    <h5 *ngIf="!isOrderAllow && isOrderAllowfromAccount" class="info cell auto common-block force-space-bottom-0">
        {{'update.subscription.pass.order.account' | translate}}
    </h5>
    <h5 *ngIf="isOrderAllow && !isOrderAllowfromAccount" class="info cell auto common-block force-space-bottom-0">
        {{'update.subscription.pass.order.subscription' | translate}}
    </h5>

    <div *ngIf="mediaOrderForm && canLinkAllMedia()" class="cell grid-x block-container space-top-16 space-bottom-16">
        <h3 class="cell small-24 secondary block-title">
            {{'update.subscription.pass.link.title' | translate}}
        </h3>

        <media-linking-form [numberCompatiblePass]="numberCompatiblePass"
                            [(ngModel)]="mediaOrderForm.linkMedia"
                            name="mediaLink">
        </media-linking-form>
    </div>

    <div class="cell grid-x block-container space-top-16 space-bottom-16">
        <h3 class="cell small-24 secondary block-title space-bottom-16">
            {{'update.subscription.pass.order.title' | translate}}
        </h3>

        <media-order class="cell auto" *ngIf="mediaCanOrderDtoList && mediaCanOrderDtoList.length"
                     [(ngModel)]="mediaOrderForm"
                     (ngModelChange)="updateMediaOrderForm($event)"
                     [promoTokenAllowed]="mode === 'update'"
                     name="mediaOrder"
                     #mediaOrder="ngModel"
                     [mediaOrderContext]="mediaOrderContext"
                     [mediaCanOrderDtoList]="mediaCanOrderDtoList">
        </media-order>
    </div>

    <div [hidden]="!isOrderAllow || !mediaOrderForm || mediaOrderForm.quantity === 0" class="grid-y space-bottom-24">
        <div class="cell">
            <label class="checkbox">
                <input name="changeDeliveryAddress" type="checkbox" [(ngModel)]="keepAddress"
                       (click)="keepDeliveryAddress()">
                <span class="custom-checkbox"></span>
                <span class="label-choice">{{'update.delivery.address' | translate}}</span>
            </label>
        </div>
    </div>
    <address-form [foAccount]="_foAccount"
                  *ngIf="isOrderAllow"
                  [hidden]="!mediaOrderForm || mediaOrderForm.quantity === 0"
                  [mode]="keepAddress? ModeAddressForm.DELIVERY_ENVELOPE : ModeAddressForm.DELIVERY"
                  (isFormAddressValid)="onFormAddressChange($event)">
    </address-form>
    <div class="grid-y space-bottom-24"
         *ngIf="mediaOrderForm &&
          frontEndInfo.fleetConfig.returnCostsDisplayed">
        <div>
            <h6>{{'media.order.returnCosts' | translate: {returnCosts: frontEndInfo.fleetConfig.returnCosts} }}</h6>
        </div>
    </div>

</form>
