import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FrontSessionBusinessService} from 'lib-front';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sessionBusinessService.connectedUser$
            .pipe(
                filter(user => user !== undefined),
                tap(user => {
                    if (user === null || user.anonymous) {
                        this.router.navigate(['/login']);
                    }
                }),
                map(user => user !== null && !user.anonymous));
    }
}
