import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'media-linking-form',
    templateUrl: './media-linking-form.component.html',
    styleUrls: ['./media-linking-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MediaLinkingFormComponent),
            multi: true
        }
    ]
})
export class MediaLinkingFormComponent implements ControlValueAccessor {
    @Input()
    public numberCompatiblePass = 0;

    private onTouch: () => void;
    public onChange: (opening: boolean) => void;
    public linkMedia: boolean = false;

    writeValue(obj: boolean): void {
        this.linkMedia = obj;
    }

    registerOnChange(fn: (opening: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {

    }

    onLinkMediaChange(linkMedia: boolean) {
        this.onChange(linkMedia);
        this.linkMedia = linkMedia;
    }
}
