import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FrontPersonPortalContextService} from 'lib-front';
import {tap} from 'rxjs/operators';

@Injectable()
export class StationManagerGuard implements CanActivate {

    constructor(private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return  this.personPortalContextService.isStationManager().pipe(
            tap(isStationManager => {
                if(!isStationManager) {
                    this.router.navigate(['/home']).then();
                }
            })
        );
    }
}
