import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {CurrentSubscriberRolesService, SubscriberRoles} from 'lib-front';
import {Observable} from 'rxjs';

@Injectable()
export class CurrentSubscriberRolesResolver implements Resolve<SubscriberRoles> {
    constructor(private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    resolve(): Observable<SubscriberRoles> {
        return this.currentSubscriberRolesService.currentSubscriberRoles$;
    }
}
