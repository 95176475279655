<div class="block-container space-bottom-24">
    <div class="grid-x align-middle align-justify space-bottom-16">
        <h3 class="grid-x align-middle cell shrink secondary block-title">
            {{'payment.summary.title' | translate}}
        </h3>
        <h5 class="cell shrink"> {{'payment.summary.info.title' | translate}}</h5>
    </div>

    <div class="light-grey-block">
        <div class="grid-x align-spaced align-middle">
            <div class="cell shrink">
                <h5 class="black number-block-title"
                    *ngFor="let subscriptionPack of subscriptionPacks; trackBy: trackBySubscriptionPackId"
                    [innerHTML]="'payment.businessSubscription'| translate:{
                name : subscriptionPack?.name}"></h5>
                <div class="cell shrink number-block price"
                     *ngIf="showSubscriptionPacksTotalPrice(); else showOverloadedPrice">
                    {{subscriptionPacksTotalPrice| currency:'EUR':subscriptionPacks[0]?.currencyUnit || ''}}
                </div>
                <ng-template #showOverloadedPrice>
                    <div class="cell shrink overloaded-number-block price"
                         *ngIf="subscriptionPacks[0]?.overloadedFixPriceLabel !== ''">
                        <span [innerHTML]="subscriptionPacks[0]?.overloadedFixPriceLabel"></span>
                    </div>
                </ng-template>
            </div>

            <div class="cell shrink note black text-center" [hidden]="!subscriptionPacks[0]?.engagementDuration"
                 [innerHTML]="'payment.businessSubscriptionWithEngagement'| translate:{
            month : subscriptionPacks[0]?.engagementDuration}">
            </div>
            <div class="cell shrink force-space-top-35">
                <i class="fas fa-plus-circle icon-operation"></i>
            </div>
            <div class="cell shrink">
                <h5 class="black number-block-title">{{'payment.totalOptions' |translate}}</h5>
                <div
                    class="number-block price">{{totalOptions | currency:'EUR':subscriptionPacks[0]?.currencyUnit || ''}}</div>
            </div>
            <div class="cell shrink force-space-top-35" *ngIf="showSubscriptionPacksTotalPrice()">
                <i class="fas fa-chevron-circle-right icon-operation"></i>
            </div>
            <div class="cell shrink" *ngIf="showSubscriptionPacksTotalPrice()">
                <h5 class="black number-block-title">{{'payment.totalTTC' |translate}}</h5>
                <div
                    class="number-block color price">{{subscriptionPacksTotalPrice + totalOptions | currency:'EUR':subscriptionPacks[0]?.currencyUnit || ''}}
                </div>
            </div>
            <div class="grid-x align-spaced align-middle" *ngIf="promoToken?.valid">
                <ng-container *ngFor="let subscriptionPack of promoToken.subscriptionPacks">
                    <div class="cell shrink">
                        <h5 class="black number-block-title">{{subscriptionPack.name}}</h5>
                        <div class="number-block price">{{subscriptionPack.overloadedFixPriceLabel | translate}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>


<div class="block-container space-bottom-24" [hidden]="(mediaOrder?.emoMediaOrderPrice?.bestPrice || 0) === 0">
    <div class="grid-x align-middle align-justify space-bottom-16">
        <h3 class="grid-x align-middle cell shrink secondary block-title">
            {{'payment.order.title' | translate}}
        </h3>
        <h5 class="cell shrink"> {{'payment.order.info.title' | translate}}</h5>
    </div>
    <div class="light-grey-block">
        <div class="grid-x align-spaced align-middle">
            <div class="cell shrink">
                <h5 class="black number-block-title">
                    {{mediaOrder?.quantity || 0}}&nbsp;{{'payment.order.table.pass' | translate}}
                </h5>
                <div class="number-block price">
                    {{mediaOrder?.emoMediaOrderPrice?.price | currency:'EUR': mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                </div>
            </div>
            <div class="cell shrink force-space-top-35"
                 *ngIf="mediaOrder?.emoMediaOrderPrice?.bestPrice < mediaOrder?.emoMediaOrderPrice?.price">
                <i class="fas fa-minus-circle icon-operation"></i>
            </div>
            <div class="cell shrink"
                 [hidden]="mediaOrder?.emoMediaOrderPrice?.bestPrice >= mediaOrder?.emoMediaOrderPrice?.price">
                <h5 class="black number-block-title">{{'payment.order.table.discount' | translate}}</h5>
                <div class="number-block price">
                    {{mediaOrder?.emoMediaOrderPrice?.discount || 0 | currency:'EUR':mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                </div>
            </div>
            <div class="cell shrink" *ngFor="let bonusPenalty of bonusPenaltyWithVats">
                <i class="fas fa-minus-circle icon-operation force-space-top-35"></i>
                <div>
                    <h5 class="black number-block-title">{{bonusPenalty.reason}}</h5>
                    <div class="number-block price">
                        {{bonusPenalty.priceWithVat | currency:'EUR':mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                    </div>
                </div>
            </div>
            <div class="cell shrink force-space-top-35">
                <i class="fas fa-chevron-circle-right icon-operation"></i>
            </div>
            <div class="cell shrink">
                <h5 class="black number-block-title">{{'payment.totalTTC' | translate}}</h5>
                <div class="number-block color price">
                    {{totalMediaPrice | currency:'EUR':mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid-x block-container">
    <promo-token-input class="cell auto"
                       [otherSubscriptionPackRefs]="subscriptionPackRefs"
                       [subscriptionMode]="SubscriptionMode.client"
                       [email]="user.email"
                       (promoTokenChange)="promoTokenChanged($event)"></promo-token-input>
</div>

