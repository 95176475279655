import {NgModule} from '@angular/core';
import {CurrentUserContextService} from './currentUserContext.service';
import {ReportingService} from './reporting.service';

const SERVICES = [
    CurrentUserContextService,
    ReportingService
];


@NgModule({
    imports: [
    ],
    providers: [
        SERVICES
    ]
})
export class BusinessServiceModule {
}
