import {Component} from '@angular/core';

@Component({
    selector: 'forms-guide',
    templateUrl: './forms-guide.component.html',
    styleUrls: ['./forms-guide.component.scss']
})
export class FormsGuideComponent {

    public items: Array<String> = ['MDE', 'CAI', 'RLZ'];
    public selectedSimpleItem: String;
}
