import {Language} from 'lib-front';

export const enum ServerLanguage {
    FR = 'fr',
    EN = 'en'
}

export const DEFAULT_APP_LANGUAGE: string = Language.FR;

// cannot rely on translateService.getLangs() in order to find out available languages because
// languages are async loaded with TranslateHttpLoader
// LOCALE_ID and language interceptor need to know available languages right after app initialization
export const AVAILABLE_APP_LANGUAGES: Language[] = [Language.FR, Language.EN_GB];
export const AVAILABLE_SERVER_LANGUAGES: string[] = [ServerLanguage.FR, ServerLanguage.EN];

