<div *ngFor="let def of definitions">
    <div class="passwordValidator valid" *ngIf="defIsSatisfied(def)">
        <i class="passwordValidator-icon fas fa-check"></i>
        <span>{{'passwordCriteria.' + def.type.valueOf() | translate: def.options}}</span>
    </div>
    <div class="passwordValidator invalid" *ngIf="!defIsSatisfied(def)">
        <i class="passwordValidator-icon fas fa-times"></i>
        <span>{{'passwordCriteria.' + def.type.valueOf() | translate: def.options}}</span>
    </div>
</div>
