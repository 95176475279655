<!--[placeholder]="isIE ? '' : placeholder" : placeholder loose focus on datepicker, need 2 click to select a date : bug IE11-->
<dp-date-picker #datePicker
                [ngModel]="realDate"
                [mode]="mode"
                [disabled]="disabled"
                (open)="onOpen()"
                (ngModelChange)="onChange($event)"
                (onGoToCurrent)="selectCurrentDate()"
                [config]="config"
                [displayDate]="realDate"
                [placeholder]="placeholder">
</dp-date-picker>
