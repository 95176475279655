<div class="cell auto app-view-content">
    <div class="cell auto grid-x">
        <div class="tabs cell shrink clear-padding vertical focus-block">
            <div class="tabs-block">
                <div *ngFor="let routedTab of routedTabs; trackBy: trackByTabPath"
                     [class.is-active]="router.isActive(routedTab.path,false)"
                     class="tabs-title">
                    <a [routerLink]="[routedTab.path]"
                       [attr.aria-selected]="router.isActive(routedTab.path,false)">
                        {{routedTab.label | translate}}
                    </a>
                </div>
            </div>
        </div>

        <div class="tabs-content main-container cell auto">
            <div class="tabs-panel is-active">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
