import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, NgModel,
    ValidationErrors
} from '@angular/forms';
import {CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat} from 'ngx-intl-tel-input';

@Component({
    selector: 'int-phone-number-input',
    templateUrl: './int-phone-number-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IntPhoneNumberInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IntPhoneNumberInputComponent),
            multi: true
        }
    ],
    styleUrls: ['./int-phone-number-input.component.scss']
})

export class IntPhoneNumberInputComponent implements ControlValueAccessor {
    @Input('inputId') inputId?: string;
    @Input('name') name: string;
    @Input('required') required: boolean = false;

    @Input('maxLength') maxLength?: number;

    @ViewChild('intPhoneNumberInput') public intPhoneNumberInput: NgxIntlTelInputComponent;
    @ViewChild('intPhoneNumberModel') public intPhoneNumberModel: NgModel;

    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.Belgium, CountryISO.UnitedKingdom, CountryISO.Italy];

    set intPhoneNumber(value: string) {
        //Workaround of the ngx input that set a null value at init
        if (value != null || (value == null && this.hasIntPhoneNumberBeenTouched)) {
            const intPhoneNumberData = this.getIntPhoneNumberData(value);
            this._intPhoneNumber = intPhoneNumberData.withoutCountryCode;

            //Workaround for this issue https://github.com/webcat12345/ngx-intl-tel-input/issues/340 (dirty from start)
            if (this.hasIntPhoneNumberBeenTouched) {
                this.onNgTouched();
                this.onNgChange(intPhoneNumberData.withCountryCode);
            }
            if (this._intPhoneNumber && !this.hasIntPhoneNumberBeenTouched) {
                this.hasIntPhoneNumberBeenTouched = true;
            }
        }
        this.validate(this.intPhoneNumberModel.control);
    }

    get intPhoneNumber(): string {
        return this._intPhoneNumber;
    }

    //International phone number (ex: +33671440612)
    private _intPhoneNumber: string;
    hasIntPhoneNumberBeenTouched: boolean = false;

    private onNgChange: (m: string) => void;
    private onNgTouched: () => void;

    private getIntPhoneNumberData(value) : {withCountryCode: string|null, withoutCountryCode: string|null}{
        let data = {
            withCountryCode: null,
            withoutCountryCode: null
        };

        if (value) {
            data = {
                withCountryCode: (value.dialCode + value.number).replace(/\s/g, ''),
                withoutCountryCode: value.number
            };
        }

        return data;
    }

    public writeValue(obj: any): void {
        this._intPhoneNumber = obj;
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    public validate(control: AbstractControl): ValidationErrors {
        return this.intPhoneNumberModel.control.errors;
    }
}

