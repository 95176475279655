import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CurrentUserContextService} from '../../../../../services/business/currentUserContext.service';
import {ReportingService} from '../../../../../services/business/reporting.service';
import {switchMap, tap} from 'rxjs/operators';
import {ReportingTab} from '../../../../../domain/reportingTab';
import {Subscription} from 'rxjs';

@Component({
    selector: 'reporting-panel',
    templateUrl: './reporting-panel.component.html',
    styleUrls: ['./reporting-panel.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ReportingPanelComponent implements OnInit, OnDestroy {

    public currentReportingTab?: ReportingTab;
    private currentTabParamPath?: string;
    private connectedUserId: string;
    private reportingSubscription: Subscription;

    constructor(private currentUserContextService: CurrentUserContextService,
        private reportingService: ReportingService,
        private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.reportingSubscription = this.route.params.pipe(
            tap(routeParams => this.currentTabParamPath = routeParams.reportingType),
            tap(() => this.connectedUserId = this.currentUserContextService.getCurrentPersonId()),
            switchMap(() => this.reportingService.activeReportingTabs()),
        ).subscribe(tabs =>
            this.currentReportingTab = tabs.find(tab => tab.path.includes(this.currentTabParamPath))
        );
    }

    ngOnDestroy(): void {
        this.reportingSubscription.unsubscribe();
    }

}
