<div class="grid-y cell auto app-view-content">
    <img src="assets/svg/rgpd.svg" alt="">
    <div *ngIf="subscriberAcceptance && subscriberAcceptance.subscriberAcceptanceStatus === RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTANCE_REQUIRED"
         class="rgpdText">
        <span [innerHTML]="'expenseReport.rgpd.pendingAgreement' | translate: {documentUrl: subscriberAcceptance.documentUrl}"></span>
        <a (click)="acceptancePopup.open()">{{'expenseReport.rgpd.displayPopup' | translate}}</a>
    </div>
</div>


<izivia-popup #acceptancePopup [size]="'medium'">
    <div *ngIf="subscriberAcceptance">
        <h2 class="popup-title">{{'expenseReport.rgpd.popup.title' | translate}}</h2>

        <div [style]="{align: 'left'}">
            <div class="space-bottom-24 space-top-16"
                 [innerHTML]="'expenseReport.rgpd.popup.text' | translate: {documentUrl: subscriberAcceptance.documentUrl}">
            </div>
        </div>

        <div class="grid-x cell auto align-right  add-space-block">
            <a class="cell shrink button secondary "
               (click)="acceptancePopup.close()">
                {{'common.close' | translate}}</a>
            <a class="cell shrink button validate" style="margin-left: 20px" (click)="acceptRGPDDocument()">
                {{'expenseReport.rgpd.popup.validate' | translate}}
            </a>
        </div>
    </div>
</izivia-popup>
