import {Component} from '@angular/core';

@Component({
    selector: 'home-map',
    templateUrl: './home-map.component.html',
    styleUrls: ['./home-map.component.scss'],
    host: {'class': 'cell auto home-card'}

})
export class HomeMapComponent {

    constructor() {
    }

}
