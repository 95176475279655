import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {Moment} from 'moment';
import {DateFormatPipe} from 'ngx-moment';

@Pipe({
    name: 'fleetMomentToStringDatePipe'
})
export class FleetMomentToStringDatePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) public readonly locale: string) {}

    transform(moment: Moment | undefined): string {
        if (moment) {
            return new DateFormatPipe().transform(moment, this.getFormat());
        } else {
            return '';
        }
    }

    private getFormat(): string {
        return this.locale != '' ? (this.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY') : 'DD/MM/YYYY';
    }

}
