import {Component, Input} from '@angular/core';
import {Consumption} from 'lib-front';
import {MetaTotalConsumption} from '../../../domain/meta/total/metaTotalConsumption';

@Component({
    selector: 'total-consumption-table[totalConsumptions]',
    templateUrl: './total-consumption-table.component.html',
    styleUrls: ['./total-consumption-table.component.scss']
})
export class TotalConsumptionTableComponent {

    @Input() totalConsumptions: MetaTotalConsumption[] = [];

}
