<form supportingDocumentFrom="ngForm">
    <div class="cell auto">&nbsp;</div>

    <div class="grid-x">
        <div class="grid-y cell small-24 medium-12">
            <div class="register-form-part business">
                <label class="cell small required">
                    <span class="labelName">{{'informations.supportingDocuments.kbis' | translate}}
                        &nbsp;
                        <i class="fa fa-question-circle"
                           [title]="'informations.supportingDocuments.kbisTooltip' | translate">
                        </i>
                    </span>
                </label>
                <drag-and-drop [accept]="fileExtensions"
                               [required]="true"
                               (fileChange)="kbisChanged($event)">
                </drag-and-drop>
            </div>
        </div>
        <div class="grid-y cell small-24 medium-12">
            <div class="register-form-part business">
                <label class="cell small required">
                    <span class="labelName">{{'informations.supportingDocuments.identityCard' | translate}}
                        &nbsp;
                        <i class="fa fa-question-circle"
                           [title]="'informations.supportingDocuments.identityCardTooltip' | translate">
                        </i>
                    </span>
                </label>
                <drag-and-drop [accept]="fileExtensions"
                               [required]="true"
                               (fileChange)="identityCardChanged($event)">
                </drag-and-drop>
            </div>
        </div>
        <div class="grid-y cell small-24 medium-12">
            <div class="register-form-part business">
                <label class="cell small required">
                    <span class="labelName">{{'informations.supportingDocuments.rib' | translate}}</span>
                </label>
                <drag-and-drop [accept]="fileExtensions"
                               [required]="true"
                               (fileChange)="ribChanged($event)">
                </drag-and-drop>
            </div>
        </div>
    </div>
</form>
