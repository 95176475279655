import {Component, Input} from '@angular/core';
import {SubscriptionPackDto} from 'lib-front';

@Component({
    selector: 'media-order-promo',
    templateUrl: './media-order-promo.component.html',
    styleUrls: ['./media-order-promo.component.scss']
})
export class MediaOrderPromoComponent {


    @Input() promoSubscriptionPack: SubscriptionPackDto;

    constructor() { }

}
