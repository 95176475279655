<ng-container *ngIf="!file">
    <div class="dropzone" iziviaDragAndDrop (fileDropped)="onFileDropped($event)">
        <input type="file"
               id="file"
               (change)="fileUploaded($event)"
               [accept]="_accept"
               [required]="required" />
        <h3>{{'informations.supportingDocuments.dragAndDrop' | translate}}</h3>
        <label class="button" for="file">{{'informations.supportingDocuments.import' | translate}}</label>
    </div>
    <p class="no-margin">{{'informations.supportingDocuments.maxSize' | translate: {maxSize} }}</p>
    <p class="no-margin">{{'informations.supportingDocuments.filesFormat' | translate }}</p>
</ng-container>
<ng-container *ngIf="file">
    <div class="grid-x cell file-container align-middle grid-padding-x">
        <div class="cell shrink">
            <i class="far fa-file-alt file-icon"></i>
        </div>
        <div class="grid-y cell align-middle align-center auto">
            <div class="grid-x cell file-name">{{file.name}}</div>
            <div class="grid-x cell file-size">{{computeSize(file.size)}} Mo</div>
        </div>
        <div class="grid-y cell align-middle align-center shrink">
            <button type="button" class="button round secondary"
                    (click)="deleteFile($event)">
                <i class="fas fa-trash"></i>
            </button>
        </div>
    </div>
</ng-container>

<izivia-popup #popup [size]="'tiny'">
    <div class="text-center">
        <i class="popup-icon fas fa-exclamation"></i>
        <h3 class="popup-title">{{'informations.supportingDocuments.popupTitle' | translate }}</h3>

        <div class="popup-content">
            <div>{{popupMessageKey | translate : {filename: unsupportedFile?.name} }}</div>
        </div>
        <div class="iziviaPopup-buttons">
            <button type="button"
                    (click)="popup.close()"
                    class="button color">
                {{ 'common.close' | translate }}
            </button>
        </div>
    </div>
</izivia-popup>
