import moment from 'moment';
import {Component, OnInit, ViewChild} from '@angular/core';
import {
    FinancialReportAction,
    FinancialReportDto,
    FinancialReportSelfCareHttpService,
    FinancialReportSelfCareSearchRequest,
    FinancialReportStatus,
    FinancialReportStatusType,
    InvoiceStatusEnum,
    PersonFleetPortalContext,
    SelfCareConsumptionDto,
    SelfCareConsumptionResultsDto,
    SelfCareFinancialReportInfoDto
} from 'lib-front';
import {ConsumptionSearchComponent} from '../../../../../components/consumptions/consumption-search/consumption-search.component';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'incomes',
    templateUrl: './incomes.view.html',
    styleUrls: ['./incomes.view.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class IncomesView implements OnInit {
    public FinancialReportStatusType = FinancialReportStatusType;
    public consumptions: SelfCareConsumptionDto[] = [];
    public lastFinancialReports: FinancialReportDto[] = [];

    public loading: boolean;
    public currencyUnit: string;
    public info: SelfCareFinancialReportInfoDto;
    public consumptionUntil: Date;
    public isSelfcareInvoiceActivated = false;

    @ViewChild('consumptionSearchComponent')
    private consumptionSearchComponent: ConsumptionSearchComponent;

    constructor(private readonly financialReportService: FinancialReportSelfCareHttpService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.consumptionUntil = moment().toDate();
        this.financialReportService.findFinancialReportInfo(this.consumptionUntil)
            .pipe(
                finalize(() => this.reloadReports())
            )
            .subscribe(info => {
                this.info = info;
                this.consumptionSearchComponent.search({
                    from: moment().subtract(1, 'years').startOf('month'),
                    to: moment().endOf('day'),
                    skip: 0,
                    limit: 10
                });
            });

        this.isSelfcareInvoiceActivated = this.route.snapshot.data && this.route.snapshot.data.userContext
            && ((this.route.snapshot.data.userContext) as PersonFleetPortalContext).selfcareInvoiceActivated;
    }

    reloadReports() {
        this.financialReportService.findFinancialReportInfo(this.consumptionUntil)
            .subscribe(info => this.info = info);

        this.financialReportService
            .searchLastFinancialReports()
            .subscribe(financialReports => this.lastFinancialReports = financialReports.financialReports);

        this.consumptionSearchComponent.search(this.consumptionSearchComponent.criteria);
    }

    updateConsumptions(consumptions: SelfCareConsumptionResultsDto) {
        this.consumptions = consumptions.consumptions;
        this.currencyUnit = consumptions.currencyUnit;
    }

    updateLoadingState(loading: boolean) {
        this.loading = loading;
    }

}

