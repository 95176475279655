import {inject} from '@angular/core';
import {FrontEndService} from '../services/frontEnd.service';
import {map} from 'rxjs/operators';

export const AllowToSignUpGuard = () => {
    const frontEndFleetConfig = inject(FrontEndService);
    return frontEndFleetConfig.currentFrontEndInfo$
        .pipe(
            map(config => config.fleetConfig?.allowToSignUp ?? true)
        );
};
