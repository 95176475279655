<div class="cell auto app-view-content">

    <div class="grid-x space-bottom-8 space-top-8">
        <div class="cell shrink align-justify">
            <h3 class="grid-x align-middle secondary block-title">
                <i class="fas fa-receipt space-right-8"></i>
                {{'expenseReport.detail.title' | translate}}
            </h3>
        </div>

        <div class="cell auto"></div>

        <div class="cell shrink">
            <button type="button"
                    (click)="backToSearch()"
                    class="button secondary">
                {{'expenseReport.detail.backToSearch' | translate}}
            </button>
        </div>
    </div>

    <ng-container *ngIf="expenseReportDetail">
        <div class="grid-y space-bottom-8">
            <div class="grid-x grid-margin-x">
                <div class="cell shrink align-justify">
                    <h4 class="grid-x align-middle secondary subTitle">
                        {{'expenseReport.detail.generalInfo' | translate}}
                    </h4>
                </div>
            </div>
            <div class="cell shrink collapseContainer-status space-bottom-8">
                <span class="status status-color-{{expenseReportDetail.status.type}}">
                    {{'expenseReport.statuses.' + expenseReportDetail.status.type | translate}}
                </span>
            </div>
            <div class="cell shrink">
                <h4 *ngIf="expenseReportDetail.status.type === expenseReportStatusType.CANCELLED">
                    {{'expenseReport.detail.cancelledReason' | translate}} : {{expenseReportDetail.status.comment}}
                </h4>
            </div>
        </div>

        <div class="expenseDetail">
            <div class="grid-x grid-margin-x grid-margin-y expenseDetail-row">
                <div class="cell small-8">
                    <span class="expenseDetail-label">{{'expenseReport.detail.type' | translate}} : <span
                            class="expenseDetail-info">{{'expenseReport.detail.types.' + expenseReportDetail.type | translate}}</span></span>
                </div>

                <div class="cell small-8">
                    <span class="expenseDetail-label">{{'expenseReport.detail.collaborator' | translate}} :
                        <span class="expenseDetail-info">{{expenseReportDetail.collaborator?.name}}</span></span>
                </div>

                <div class="cell small-8">
                    <span class="expenseDetail-label">
                        {{'expenseReport.detail.date' | translate}} :
                    <span class="expenseDetail-info">{{expenseReportDetail.date | date: 'dd/MM/YYYY'}}</span>
                    </span>

                </div>
            </div>

            <div class="grid-x grid-margin-x grid-margin-y">
                <div class="cell small-8">
                    <span class="expenseDetail-label">
                        {{'expenseReport.detail.energy' | translate}} :  <span
                            class="expenseDetail-info">{{expenseReportDetail.energyInkWh*1000 | energy}}</span></span>

                </div>

                <div class="cell small-8">
                    <span class="expenseDetail-label">
                        {{'expenseReport.detail.costWithVat' | translate}} : <span
                        class="expenseDetail-info">
                        {{expenseReportDetail.amountWithVat | currency: 'EUR': expenseReportDetail.currencyUnit}}
                    </span>
                    </span>

                </div>
            </div>

        </div>

        <div class="space-bottom-16"
             *ngIf="expenseReportDetail.type === expenseReportType.INDEX_READING">
            <p class="expenseDetail-link">{{'expenseReport.detail.attachments' | translate}} :
                <a [href]="expenseReportIndexReading.photoLink"
                    target="_blank">{{'expenseReport.detail.indexReading' | translate}}</a>
            </p>
        </div>

        <div class="grid-x">
            <div class="cell auto">
                <table>
                    <thead>
                    <tr>
                        <td>{{'expenseReport.table.header.date' | translate}}</td>
                        <td>{{'expenseReport.table.header.type' | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">{{'expenseReport.detail.table.header.mediaCode' | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">{{'expenseReport.detail.table.header.startDate' | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">{{'expenseReport.detail.table.header.endDate' | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">{{'expenseReport.detail.table.header.duration' | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.INDEX_READING">{{'expenseReport.detail.table.header.previousIndex' | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.INDEX_READING">{{'expenseReport.detail.table.header.currentIndex' | translate}}</td>
                        <td>{{'expenseReport.detail.table.header.energy' | translate}}</td>
                        <td>{{'expenseReport.detail.table.header.indemnity' | translate}}</td>
                        <td>{{'expenseReport.table.header.costWithVat' | translate}}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{expenseReportDetail.date | date: 'dd/MM/YYYY'}}</td>
                        <td>{{'expenseReport.detail.types.' + expenseReportDetail.type | translate}}</td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">
                            <span *ngIf="expenseReportCharge">
                            {{expenseReportCharge.mediaCode}}
                            </span>
                        </td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">
                            <span *ngIf="expenseReportCharge">
                            {{expenseReportCharge.startDate | date: 'dd/MM/YYYY HH:mm'}}
                            </span>
                        </td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">
                            <span *ngIf="expenseReportCharge">
                            {{expenseReportCharge.endDate | date: 'dd/MM/YYYY HH:mm'}}
                            </span>
                        </td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.CHARGE">
                            <span *ngIf="expenseReportCharge">
                            {{expenseReportCharge.durationInSecond | duration}}
                            </span>
                        </td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.INDEX_READING">
                            <span *ngIf="expenseReportIndexReading">
                            {{expenseReportIndexReading.previousIndexInkWh | number}}
                            </span>
                        </td>
                        <td *ngIf="expenseReportDetail.type === expenseReportType.INDEX_READING">
                            <span *ngIf="expenseReportIndexReading">
                            {{expenseReportIndexReading.currentIndexInkWh | number}}
                            </span>
                        </td>
                        <td>{{expenseReportDetail.energyInkWh | number}}</td>
                        <td>{{expenseReportDetail.pricePerkWh | currency:'EUR':expenseReportDetail.currencyUnit}}/KWh</td>
                        <td>{{expenseReportDetail.amountWithVat | currency:'EUR':expenseReportDetail.currencyUnit}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>
