import {Pipe, PipeTransform} from '@angular/core';
import {MetaChargeConsumptionDto} from '../domain/meta/metaChargeConsumptionDto';
import {MetaReservationConsumptionDto} from '../domain/meta/metaReservationConsumptionDto';
import {MetaOtpConsumptionDto} from '../domain/meta/metaOtpConsumptionDto';
import {MetaServiceConsumptionDto} from '../domain/meta/metaServiceConsumptionDto';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'fleetMetaConsumptionMedia'
})
export class FleetMetaConsumptionMediaPipe implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {}

    transform(metaConsumption: MetaChargeConsumptionDto | MetaReservationConsumptionDto | MetaOtpConsumptionDto | MetaServiceConsumptionDto): string {
        if (!metaConsumption.mediaPublicCode) {
            return this.translateService.instant('common.unavailable');
        }

        return metaConsumption.mediaPublicCode + (metaConsumption.mediaAlias ? ' - ' + metaConsumption.mediaAlias : '');
    }
}
