import {Component, Input} from '@angular/core';
import {MetaConsumptionDto} from '../../domain/meta/metaConsumptionDto';
import {ConsumptionKind} from 'lib-front';

@Component({
    selector: 'consumption-details[consumption]',
    templateUrl: './consumption-details.component.html',
    styleUrls: ['./consumption-details.component.scss']
})

export class ConsumptionDetailsComponent {
    ConsumptionKind = ConsumptionKind;

    @Input() consumption: MetaConsumptionDto;

    constructor() {
    }
}
