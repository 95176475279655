import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {range} from 'lodash-es';


@Component({
    selector: 'time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true
        }
    ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {

    @Input() public required = false;

    public time: string;

    public disabled: boolean = false;
    private minutesInterval: number = 15;

    public timeOptions: string[] = [];

    // suggest time picker
    searchTimeResult: string[] = [];

    public onChange: (val: any) => void;
    public onTouched: () => void;

    searchTariffTime(searchTerm: string) {
        if (!searchTerm) {
            this.searchTimeResult = this.timeOptions;
        }

        this.searchTimeResult = this.timeOptions.filter(timeOption => timeOption.startsWith(searchTerm));
    }

    showTariffTime(time: string) {
        return time;
    }

    ngOnInit(): void {
        const hours = range(0, 24, 1);
        const minutes = range(0, 60, this.minutesInterval);

        // Fill timeOptions
        hours.forEach(hour => minutes.forEach(minute => this.timeOptions.push(this.formatTime(hour, minute))));
    }

    writeValue(obj?: any): void {
        if(!obj) {
            return;
        } else {
            const timeFormat = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
            if (obj instanceof Date) {
                this.time = this.formatTime(obj.getHours().toString(), obj.getMinutes().toString());
            } else if (typeof obj === 'string' && timeFormat.test(obj)) {
                this.time = obj;
            }
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    updateSelectedTime(): void {
        this.onChange(this.time);
    }

    private formatTime(hour: number | string, minute: number | string) {
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    }
}
