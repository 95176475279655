<div class="grid-x cell shrink align-justify align-bottom grid-padding-x space-bottom-8">
    <label class="cell auto">
        <span>{{'chargeConsumptions.criteria.startDate' | translate}}</span>
        <date-picker class="full-input" [(ngModel)]="criteria.from" name="fromDate"></date-picker>
    </label>

    <label class="cell auto">
        <span>{{'chargeConsumptions.criteria.endDate' | translate}}</span>
        <date-picker class="full-input" [(ngModel)]="criteria.to" name="toDate"></date-picker>
    </label>

    <div class="cell shrink">
        <button type="submit" class="button primary responsiveButton force-space-bottom-8" (click)="search(criteria)"
                [iziviaLoading]="loading">
            {{'chargeConsumptions.search.button' | translate}}
        </button>
    </div>
</div>
<div class="grid-x cell shrink align-justify align-middle space-bottom-8 paginationBlock" *ngIf="maxPage">
    <div class="pagination">
        <button class="pagination-button" (click)="search(criteria, page - 1)"
                [disabled]="page <= 1 || loading">
            <i class="fas fa-angle-left"></i>
        </button>
        <span class="pagination-label">{{page}}&nbsp;/&nbsp;{{maxPage}}</span>
        <button class="pagination-button" (click)="search(criteria, page + 1)"
                [disabled]="page >= maxPage || loading">
            <i class="fas fa-angle-right"></i>
        </button>
    </div>
</div>
