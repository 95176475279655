import {HttpClient} from '@angular/common/http';
import {AVAILABLE_APP_LANGUAGES, ServerLanguage} from './i18n.config';
import {FrontI18nResource, Language, MultiTranslateHttpLoader} from 'lib-front';

export function createTranslateLoader(httpClient: HttpClient) {
    return new MultiTranslateHttpLoader(httpClient, [
        new FrontI18nResource('./assets/i18n/lib-front-', '.json', 0),
        new FrontI18nResource('./assets/i18n/', '.json', 1),
        new FrontI18nResource('./assets/i18n/theme-labels-', '.json', 2)
    ]);
}

export function browserLanguageToAppLanguage(browserCultureLang: string): Language {
    if (AVAILABLE_APP_LANGUAGES.map(availableAppLanguage => availableAppLanguage.valueOf()).includes(browserCultureLang)) {
        return browserCultureLang as Language;
    } else if (browserCultureLang.startsWith(Language.FR)) {
        return Language.FR;
    } else if (browserCultureLang.startsWith(Language.EN)) {
        // The only EN language handle is Britain
        return Language.EN_GB;
    }

    return null;
}

export function appLanguageToServerLanguage(language: Language): ServerLanguage {
    if (language == null) {
        return null;
    }

    switch (language) {
        case Language.FR:
            return ServerLanguage.FR;
        case Language.EN:
        case Language.EN_GB:
            return ServerLanguage.EN;
    }

    console.warn(`Cannot handle language ${language}`);
    return null;
}

export function serverLanguageToAppLanguage(serverLanguage: ServerLanguage): Language {
    if (serverLanguage == null) {
        return null;
    }

    switch (serverLanguage) {
        case ServerLanguage.FR:
            return Language.FR;
        case ServerLanguage.EN:
            return Language.EN_GB;
    }

    console.warn(`Cannot handle server language ${serverLanguage}`);
    return null;
}
