<section class="style-guide-block">
  <div class="row">
    <h1 class="small-24 columns style-guide-title">Blocks</h1>

    <div class="small-24 columns style-guide-section-content">
      <div class="row">
        <div class="small-24 column">
          <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Retrouvez les styles de base des tableaux sur la feuille de style ce trouvant dans <strong>styles/components/tables.scss</strong>
          </div>

          <h4 class="style-guide-sub-title">Bases styles</h4>
          <!-- START / Table Structure -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <table>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Label</th>
                      <th>Label</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>value</td>
                      <td>
                        <button class="button icon-btn left"><i class="fas fa-pencil-alt"></i></button>
                        Label
                      </td>
                      <td>Value</td>
                      <td>
                        Label
                        <button class="button icon-btn right"><i class="fas fa-pencil-alt"></i></button>
                      </td>
                    </tr>
                    <tr>
                      <td>value</td>
                      <td>value</td>
                      <td>Value</td>
                      <td>Value</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><span>Total</span></td>
                      <td><span>Value</span></td>
                      <td><span>Value</span></td>
                      <td><span>Value</span></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                <code iziviaPrism class="language-html">
                       <table>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Label</th>
                      <th>Label</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr>
                      <td>value</td>
                      <td>
                        <button class="button icon-btn left"><i class="fas fa-pencil-alt"></i></button>
                        Label
                      </td>
                      <td>Value</td>
                      <td>
                        Label
                        <button class="button icon-btn right"><i class="fas fa-pencil-alt"></i></button>
                      </td>
                    </tr>
                    <tr>
                      <td>value</td>
                      <td>value</td>
                      <td>Value</td>
                      <td>Value</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><span>Total</span></td>
                      <td><span>Value</span></td>
                      <td><span>Value</span></td>
                      <td><span>Value</span></td>
                    </tr>
                  </tfoot>
                </table>
                </code>
              </pre>
            </div>
          </div>
          <!-- END / Table Structure -->

          <h4 class="style-guide-sub-title">Tables retractable</h4>
          <!-- START / Table Structure -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <table>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>value</td>
                      <td class="text-right">
                        <button *ngIf="true" class="button round eye">
                          <i class="fas fa-eye"></i>
                        </button>

                        <button *ngIf="false" class="button round eye white-text">
                          <i class="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>

                    <tr class="selected">
                      <td>value</td>
                      <td class="text-right">
                        <button *ngIf="false" class="button round eye">
                          <i class="fas fa-eye"></i>
                        </button>

                        <button *ngIf="true" class="button round eye white-text">
                          <i class="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="retractable" [class.retracted]="false">
                      <td colspan="11">
                        <div class="grid-y cell auto main-container force-space-bottom-0">
                          Test
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                <code iziviaPrism class="language-html">
                  <table>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>value</td>
                      <td class="text-right">
                        <button *ngIf="true" class="button round eye">
                          <i class="fas fa-eye"></i>
                        </button>

                        <button *ngIf="false" class="button round eye white-text">
                          <i class="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>

                    <tr class="selected">
                      <td>value</td>
                      <td class="text-right">
                        <button *ngIf="false" class="button round eye">
                          <i class="fas fa-eye"></i>
                        </button>

                        <button *ngIf="true" class="button round eye white-text">
                          <i class="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="retractable" [class.retracted]="false">
                      <td colspan="11">
                        <div class="grid-y cell auto main-container force-space-bottom-0">
                          Test
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </code>
              </pre>
            </div>
          </div>
          <!-- END / Table Structure -->
        </div>
      </div>
    </div>
  </div>
</section>
