import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {FOAccountWrapper, FrontFoAccountHttpService} from 'lib-front';

@Component({
    selector: 'fo-account-switcher',
    templateUrl: './fo-account-switcher.component.html',
    styleUrls: ['./fo-account-switcher.component.scss']
})
export class FoAccountSwitcherComponent implements OnInit, OnDestroy {

    @ViewChild('accountSwitcher')
    private switcher: ElementRef;
    private _foAccounts: FOAccountWrapper[] = [];
    foAccountNameFilter: string;

    @Input() public finalClientConfigurable: boolean = false;

    @Input()
    set foAccounts(foAccounts: FOAccountWrapper[]) {
        this._foAccounts = foAccounts.sort((first, second) => {
            return this.buildCompleteSwitcherName(first) < this.buildCompleteSwitcherName(second) ? -1 : 1;
        });
        this.filterFoAccounts();
    }

    private buildCompleteSwitcherName(foAccount: FOAccountWrapper) {
        return this.buildDisplayedName(foAccount) + this.buildFinalClientName(foAccount);
    }

    @Input()
    set currentFoAccount(currentFoAccount: FOAccountWrapper) {
        this.filteredFoAccounts = this.foAccounts.filter(foAccount => foAccount !== currentFoAccount);

        this._currentFoAccount = currentFoAccount;
    }

    get currentFoAccount(): FOAccountWrapper {
        return this._currentFoAccount;
    }

    get foAccounts(): FOAccountWrapper[] {
        return this._foAccounts;
    }

    private _currentFoAccount: FOAccountWrapper;

    @Output()
    public logoutClicked: EventEmitter<void> = new EventEmitter<void>();

    public filteredFoAccounts: FOAccountWrapper[] = [];
    public isFoAccountPanelOpen: boolean = false;

    private unlistenClickOut;

    constructor(private foAccountService: FrontFoAccountHttpService,
        private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.unlistenClickOut = this.renderer.listen('window', 'click', (e: Event) => {
            if (e.target !== this.switcher.nativeElement) {
                this.isFoAccountPanelOpen = false;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.unlistenClickOut) {
            this.unlistenClickOut();
        }
    }

    openCloseFoAccountPanel(event) {
        this.isFoAccountPanelOpen = !this.isFoAccountPanelOpen;
        event.stopPropagation();
    }

    changeCurrentFoAccount(foAccount: FOAccountWrapper) {
        this.foAccountService.changeCurrentFoAccount(foAccount.foAccountRef).subscribe(() => {
            window.location.reload();
        });
    }

    buildDisplayedName(foAccount: FOAccountWrapper) {
        return foAccount.companyName + ' ' + (foAccount.siteName ? '- ' + foAccount.siteName : '');
    }

    public buildFinalClientName(foAccount: FOAccountWrapper): string {
        return ' - ' + foAccount.socialReason + ' - ' + foAccount.finalClientRef;
    }

    matchFilter(s: string): boolean {
        return s && !!s.match(new RegExp('^'+this.foAccountNameFilter, 'i'));
    }

    filterFoAccounts() {
        this.filteredFoAccounts =
            this._foAccounts
                .filter(foAccount =>
                    foAccount !== this.currentFoAccount
                    && (this.matchFilter(foAccount.companyName)
                        || this.matchFilter(foAccount.siteName)
                        || this.matchFilter(this.buildDisplayedName(foAccount)))
                );
    }

    logout() {
        this._foAccounts = [];
        this._currentFoAccount = null;
        this.logoutClicked.emit();
    }
}
