import {Component, Input} from '@angular/core';
import {Contact} from 'lib-front';

@Component({
    selector: 'contact-card[contact]',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent {
    @Input() contact: Contact;
    @Input() mailToSubject?: string;

    constructor() {
    }

}
