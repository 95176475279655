import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import moment, {Moment} from 'moment';
import {ITimeSelectConfig} from 'ng2-date-picker/lib/time-select/time-select-config.model';
import {Dayjs} from 'dayjs';
import {ToDayjsPipe} from '../../pipes/toDayjs.pipe';

@Component({
    selector: 'fleet-time-picker',
    templateUrl: './fleet-time-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FleetTimePickerComponent),
            multi: true
        }
    ]
})
export class FleetTimePickerComponent implements ControlValueAccessor, OnInit {
    @Input() public set minTime(minTime: string | Dayjs | moment.Moment) {
        this._minTime = this.toDayjsPipe.transform(minTime);
        this.config = {
            ...this.config,
            minTime: this._minTime
        };
    }

    public config: ITimeSelectConfig;
    private onNgChange: (m: Moment) => void;
    private onNgTouched: () => void;
    private _minTime: Dayjs;
    public realTime: Dayjs;

    constructor(private readonly toDayjsPipe: ToDayjsPipe) {
    }

    ngOnInit(): void {
        this.config = {
            showTwentyFourHours: true,
            minTime: this.minTime,
        };
    }

    public onChange(time: Dayjs): void {
        if (!time) {
            this.onNgChange(undefined);
        } else if (time.isValid()) {
            this.onNgChange(moment(time.toISOString()));
        }
    }

    public writeValue(obj: Dayjs): void {
        if (obj !== null) {
            this.realTime = obj;
        }
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    public get minTime(): Dayjs {
        return this._minTime;
    }
}
