import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FrontTariffHttpService, TariffDetailDto, TariffDto} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {cloneDeep, differenceBy, remove} from 'lodash-es';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';
import {OthrTariffDetailDto} from 'lib-front/lib/domain/tariff/othrTariffDetailDto';

@Component({
    selector: 'station-tariff-links',
    templateUrl: './station-tariff-links.component.html',
    styleUrls: ['./station-tariff-links.component.scss']
})
export class StationTariffLinksComponent extends AbstractHasRoleActionComponent {


    @Input() public stationId: string;
    @Input() public othrTariff: OthrTariffDetailDto;
    @Input() public hasStationWriteRole: boolean;
    @Input() public tariffsAllowSubscriberToDeclareStation: boolean;
    @Input() public canCreatePaynowTariffs: boolean;
    @Output() public linkedTariffEmitter: EventEmitter<void> = new EventEmitter<void>();

    private _linkedTariffs: TariffDto[];
    public linkedInscriptionCodeTariffs: TariffDto[];
    public linkedQRCodeTariffs: TariffDto[];

    public unLinkedInscriptionCodeTariffs: TariffDto[];
    public inscriptionCodeTariffIdToLink: string;

    public unLinkedQRCodeTariffs: TariffDto[];
    public qrCodeTariffIdToLink: string;

    public loadingTariff: boolean = true;
    public unlinkingTariff: boolean;
    public linkingTariff: boolean;

    public selectedTariff: TariffDetailDto;
    private tariffs: TariffDto[]; // List of all station manager's tariff (includes this.linkedTariffs)

    @Input()
    get linkedTariffs(): TariffDto[] {
        return this._linkedTariffs;
    }

    set linkedTariffs(tariffs: TariffDto[]) {
        if (!!tariffs) {
            this._linkedTariffs = tariffs;
            this.linkedInscriptionCodeTariffs = tariffs.filter(tariff => !tariff.applyByQRCode);
            this.linkedQRCodeTariffs = tariffs.filter(tariff => tariff.applyByQRCode);
            this.updateUnLinkedTariffs();
            if (tariffs.length > 0) {
                this.selectTariff(tariffs[0].id);
            }
        } else {
            this.linkedInscriptionCodeTariffs = [];
            this.linkedQRCodeTariffs = [];
        }
    }

    constructor(private readonly tariffHttpService: FrontTariffHttpService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        protected readonly notificationService: NotificationService) {
        super(notificationService);
        this.tariffHttpService.fetchTariffs({
            withAllowTariffSubscriberToDeclareStation: false
        })
            .subscribe(tariffs => {
                this.tariffs = tariffs;
                this.updateUnLinkedTariffs();
            });
    }

    selectTariff(tariffId: string) {
        if (this.selectedTariff?.id === tariffId) {
            return;
        }
        this.loadingTariff = true;
        this.selectedTariff = undefined;
        this.tariffHttpService.findTariffById(tariffId)
            .pipe(
                finalize(() => this.loadingTariff = false)
            )
            .subscribe(tariffDetail => this.selectedTariff = tariffDetail,
                () => {
                    this.notificationService.error('config.tariff.fetchError');
                });
    }

    public unlinkTariff(tariffId: string): void {
        this.doActionIfHasRole(
            () => {
                this.alertService.confirm(
                    this.translateService.instant('stations.edit.tariff.unlink.confirm.message'),
                    this.translateService.instant('stations.edit.tariff.unlink.confirm.title')
                ).pipe(
                    filter(response => response),
                    tap(() => this.unlinkingTariff = true),
                    switchMap(() => this.tariffHttpService.unlinkStation(tariffId, this.stationId)),
                    finalize(() => this.unlinkingTariff = false)
                ).subscribe(() => {
                    const newLinkedTariffs = cloneDeep(this._linkedTariffs);
                    remove(newLinkedTariffs, tariff => tariff.id === tariffId);
                    this.linkedTariffs = newLinkedTariffs;
                    this.notificationService.success('stations.edit.tariff.unlink.success');
                    this.linkedTariffEmitter.next();
                }, () => this.notificationService.error('stations.edit.tariff.unlink.error'));
            },
            this.hasStationWriteRole
        );
    }

    public linkTariff(tariffIdToLink: string): void {
        this.doActionIfHasRole(
            () => {
                this.linkingTariff = true;
                this.tariffHttpService.linkStation(tariffIdToLink, this.stationId)
                    .pipe(
                        finalize(() => this.linkingTariff = false)
                    )
                    .subscribe(
                        () => {
                            const newLinkedTariffs = cloneDeep(this._linkedTariffs);
                            newLinkedTariffs.push(this.tariffs.find(tariff => tariff.id === tariffIdToLink));
                            this.linkedTariffs = newLinkedTariffs;
                            this.notificationService.success('stations.edit.tariff.link.success');
                            this.linkedTariffEmitter.next();
                        },
                        () => this.notificationService.error('stations.edit.tariff.link.error')
                    );
            },
            this.hasStationWriteRole
        );
    }

    private updateUnLinkedTariffs() {
        const unLinkedTariffs = differenceBy(this.tariffs, this._linkedTariffs, 'id');
        this.unLinkedInscriptionCodeTariffs = (unLinkedTariffs || []).filter(tariff => !tariff.applyByQRCode);
        this.inscriptionCodeTariffIdToLink = this.unLinkedInscriptionCodeTariffs.length > 0 ? this.unLinkedInscriptionCodeTariffs[0].id : null;
        this.unLinkedQRCodeTariffs = (unLinkedTariffs || []).filter(tariff => tariff.applyByQRCode);
        this.qrCodeTariffIdToLink = this.unLinkedQRCodeTariffs.length > 0 ? this.unLinkedQRCodeTariffs[0].id : null;
    }

    public trackByTariffId(index: number, tariff: TariffDto) {
        return tariff?.id ?? index;
    }
}
