<collaborator-list-form *ngIf="collaborators"
                        [(ngModel)]="collaborators"
                        [nbrTotalCollaborators]="nbrCollaborators"
                        [isRefundManager]="isRefundManager"
                        [searching]="searching"
                        [hasCollaboratorsWriteRole]="hasCollaboratorsWriteRole"
                        [hasSubscriberRolesReadRole]="hasSubscriberRolesReadRole"
                        [searchLimit]="searchLimit"
                        [pageNumber]="pageNumber"
                        [nbrPageMax]="nbrPageMax"
                        (searchChange)="searchWithCriteria($event)"
                        (refreshCollaborator)="refreshCollaborator()"
                        (navigateToPermissionTabByName)="navigateToPermissionTabByName.emit($event)"
                        (pageChange)="search($event)"
                        name="accounts"></collaborator-list-form>
