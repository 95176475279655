import {Component, OnInit} from '@angular/core';
import {
    CBLoginUpdateRequest,
    ConnectedUser,
    DetailedPersonDto,
    FleetUser,
    FOAccountWrapper,
    FrontAccountHttpService,
    FrontFoAccountHttpService,
    FrontMediaOrderHttpService,
    FrontSessionBusinessService,
    UserRef
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {AccountFormValue} from '../../../../../components/informations/account-informations.component';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {cloneDeep} from 'lodash-es';
import {AlertService} from '../../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {AbstractMediaOrderDeliveryChangeWorkflowComponent} from '../../../../../components/abstract-media-order-delivery-change-workflow.component';

@Component({
    selector: 'account-configuration',
    templateUrl: './configuration-account.component.html',
    styleUrls: ['./configuration-account.component.scss']
})
export class AccountConfigurationComponent extends AbstractMediaOrderDeliveryChangeWorkflowComponent implements OnInit {

    public user: FleetUser;
    public login: string;
    public workflowId: string;

    public saving: boolean;

    private initialFoAccount: FOAccountWrapper;

    public constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        protected readonly mediaOrderHttpService: FrontMediaOrderHttpService,
        private readonly accountHttpService: FrontAccountHttpService,
        protected readonly foAccountHttpService: FrontFoAccountHttpService,
        protected readonly notificationService: NotificationService,
        protected readonly alertService: AlertService,
        protected readonly translateService: TranslateService,
        private readonly route: ActivatedRoute) {

        super(notificationService, mediaOrderHttpService, alertService, translateService);
    }

    ngOnInit(): void {
        this.foAccountHttpService.findFOAccount().subscribe(foAccount => this.initialFoAccount = foAccount);
        this.route.data.subscribe((data: { user: ConnectedUser, detailedPerson: DetailedPersonDto }) =>
            this.initFromRouteData(data.user, data.detailedPerson));
    }

    private initFromRouteData(connectedUser: ConnectedUser, detailedPerson: DetailedPersonDto): void {
        this.user = cloneDeep(connectedUser.user) as FleetUser;
        this.user.stationManager = connectedUser.stationManager;
        this.login = detailedPerson.login;
        this.workflowId =
            detailedPerson
                ?.foAccounts
                ?.find(foAccount => !!foAccount.splitbillingRGPDWorkflowRef)?.splitbillingRGPDWorkflowRef;
    }

    public saveAccount(accountFormValue: AccountFormValue): void {
        this.saving = true;
        accountFormValue.user['@type'] = 'fleet';
        this.accountHttpService.accountSave(accountFormValue.user).pipe(
            tap((fleetUser: FleetUser) => {
                const isStationManager = this.user.stationManager;
                this.user = fleetUser;
                this.user.stationManager = isStationManager;
            }),
            switchMap(() => this.foAccountHttpService.foAccountSave(accountFormValue.foAccount)),
            finalize(() => this.saving = false)
        ).subscribe(
            () => this.checkDeliveryChanged(accountFormValue.foAccount, this.initialFoAccount?.delivery),
            () => this.notificationService.error('config.error')
        );

        if (!!accountFormValue.password) {
            this.savePassword(accountFormValue);
        }
    }

    private savePassword(accountFormValue: AccountFormValue): void {
        const cBLoginUpdateRequest: CBLoginUpdateRequest = {

            passwordHash: accountFormValue.password,
            userRef: UserRef.PERSON + '/' + accountFormValue.user._id
        };
        this.accountHttpService.changePassword(cBLoginUpdateRequest).subscribe(
            () => {},
            () => this.notificationService.error('config.errorPwd')
        );
    }

    protected mediaOrderDeliveryCommonCallback(foAccount: FOAccountWrapper): void {
        this.sessionBusinessService.reloadUser();
        this.initialFoAccount = cloneDeep(foAccount);
        this.notificationService.success('config.saved');
        this.orderingMedia = false;
    }
}
