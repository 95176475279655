import {Component, OnInit} from '@angular/core';
import {
    ConnectedUser,
    FOAccountWrapper,
    FrontAccountHttpService,
    FrontEndConfig,
    FrontFoAccountHttpService,
    FrontPersonHttpService,
    FrontSessionBusinessService,
    NotificationsConfiguration
} from 'lib-front';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {FrontEndService} from '../../../../../services/frontEnd.service';
import {map, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'notification-configuration',
    templateUrl: './notification-configuration.component.html',
    styleUrls: ['./notification-configuration.component.scss'],
})
export class NotificationConfigurationComponent implements OnInit {

    public frontEndConfig = {} as FrontEndConfig;
    public userNotifications;

    public accountAndMediaTriggers = ['MEDIA_ORDER', 'SENT_MEDIA_ORDER', 'PACK_SUBSCRIPTION', 'SUBSCRIBE_OPTION', 'SUSPENDED_ACCOUNT', 'CLOSED_ACCOUNT', 'CLOSE_ACCOUNT_BY_REFERENT'];
    public favoriteTriggers = ['FAVORITE_IN_WORK', 'FAVORITE_OPENING', 'FAVORITE_AVAILABILITY', 'FAVORITE_NEWS'];
    public chargeTriggers = ['AUTHORIZE', 'START_CHARGE', 'INTERRUPTED_CHARGE', 'INPROGRESS_CHARGE', 'SUSPENDED_CHARGE', 'STOP_CHARGE', 'STATION_RESERVED'];
    public invoiceTriggers = ['NEW_INVOICE', 'INVOICE_STATUS', 'FAILED_PAYMENT', 'FAILED_DIRECT_DEBIT',
        'REMOVED_PAYMENT_CONFIGURATION', 'NEW_CREDIT_NOTE'];
    public editableChannels = ['EMAIL', 'SMS'];

    public displayAccountAndMediaTriggers: boolean;
    public displayChargeTriggers: boolean;
    public displayInvoiceTriggers: boolean;

    private connectedUser: ConnectedUser;


    public constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly accountHttpService: FrontAccountHttpService,
        private readonly personHttpService: FrontPersonHttpService,
        private readonly foAccountHttpService: FrontFoAccountHttpService,
        private readonly notificationService: NotificationService,
        private readonly frontEndService: FrontEndService) {
    }

    ngOnInit(): void {
        this.initializeUserNotifications();

        this.sessionBusinessService.currentConnectedUser$
            .pipe(
                tap(connectedUser => this.connectedUser = connectedUser),
                map(connectedUser => connectedUser?.user._id),
                switchMap(userRef => this.personHttpService.findNotificationsConfiguration(userRef))
            )
            .subscribe(notifications => this.setUserConfiguration(notifications));

        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndConfig => {
            this.frontEndConfig.notificationTriggers = frontEndConfig.config.notificationTriggers;
            this.frontEndConfig.availableNotificationChannels = frontEndConfig.config.availableNotificationChannels;
        });
    }

    private initializeUserNotifications() {
        this.userNotifications = {
            notificationChannel: {},
            accountAndMediaTriggers: {},
            favoriteTriggers: {},
            chargeTriggers: {},
            invoiceTriggers: {},
            unalternable: {},
            uneditable: {},
        };
    }


    public getAlterableByUser(notificationConfig) {
        const editableConfig: { [index: string]: boolean } = {};
        for (const property in this.frontEndConfig.notificationTriggers) {
            if (!this.favoriteTriggers.includes(property) && this.frontEndConfig.notificationTriggers[property].alterableByUser) {
                editableConfig[property] = notificationConfig.notificationTriggers[property];
            } else {
                this.userNotifications.unalternable[property] = this.frontEndConfig.notificationTriggers[property].defaultValue;
            }
        }
        return editableConfig;
    }

    public getAvailableForUser(notificationConfig) {
        const editableConfig: { [index: string]: boolean } = {};
        for (const property in this.frontEndConfig.availableNotificationChannels) {
            if (this.editableChannels.includes(property) && this.frontEndConfig.availableNotificationChannels[property]) {
                editableConfig[property] = notificationConfig.notificationChannels[property];
            } else {
                this.userNotifications.uneditable[property] = this.frontEndConfig.availableNotificationChannels[property];
            }
        }
        return editableConfig;
    }

    public setUserConfiguration(notifications) {

        const alterableNotifications = this.getAlterableByUser(notifications);
        const availableNotifications = this.getAvailableForUser(notifications);
        this.userNotifications.notificationChannel = availableNotifications;
        this.userNotifications.accountAndMediaTriggers = this.getNotificationByCategory(alterableNotifications,
            this.accountAndMediaTriggers);
        this.userNotifications.chargeTriggers = this.getNotificationByCategory(alterableNotifications, this.chargeTriggers);
        this.userNotifications.favoriteTriggers = this.getNotificationByCategory(alterableNotifications, this.favoriteTriggers);
        this.userNotifications.invoiceTriggers = this.getNotificationByCategory(alterableNotifications, this.invoiceTriggers);
    }

    public getNotificationByCategory(notifications, category) {
        return Object.keys(notifications)
            .filter(key => category.includes(key))
            .reduce((obj, key) => {
                obj[key] = notifications[key];
                return obj;
            }, {});
    }

    public save(event) {
        const notificationConfiguration = {} as NotificationsConfiguration;
        notificationConfiguration.notificationChannels = Object.assign({}, this.userNotifications.notificationChannel,
            this.userNotifications.uneditable);
        notificationConfiguration.notificationTriggers = Object.assign({}, this.userNotifications.accountAndMediaTriggers,
            this.userNotifications.chargeTriggers, this.userNotifications.invoiceTriggers, this.userNotifications.unalternable);
        this.personHttpService.updateNotificationsConfiguration(this.connectedUser?.user?._id, notificationConfiguration).subscribe(() => {
            this.notificationService.success('config.saved');
        });
    }

    public isEmptyConfiguration(configuration) {
        return JSON.stringify(configuration) === '{}';
    }

}
