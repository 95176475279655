import {Component, EventEmitter, Input,  Output} from '@angular/core';
import {KeyValue} from '@angular/common';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
    public _notificationsConfig: {[index: string]: boolean };

    @Output() notificationsConfigChange = new EventEmitter<{[index: string]: boolean }>();

    @Input()
    get notificationsConfig() {
        return this._notificationsConfig;
    }

    set notificationsConfig(val) {
        this._notificationsConfig = val;
    }
    public save() {
        this.notificationsConfigChange.emit(this._notificationsConfig);
    }
    constructor() { }

    trackByKey(index: number, keyValue: KeyValue<string, any>) {
        return keyValue?.key ?? index;
    }
}
