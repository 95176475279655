    <form class="grid-y cell marginBottom" #chargeAreaForm="ngForm">
        <ng-container *ngIf="!!chargeArea">
            <div class="grid-x cell shrink space-bottom-16 cardContainer">
                <div class="cell small-10 grid-y">
                    <label class="cell space-right-16 margin-label required"
                           [ngClass]="{validate : !!name.valid}">
                        <span>{{'chargeAreas.edit.name' | translate}}</span>
                        <input type="text" [(ngModel)]="chargeArea.name" name="name"
                               #name="ngModel" required>
                    </label>
                    <div class="cell grid-x space-top-16">
                        <label class="cell auto space-right-16 radio">
                            <input type="radio" [(ngModel)]="chargeArea.geolocation.locationType"
                                   [value]="LocationType.OUTDOOR"
                                   name="locationType"
                                   #locationType="ngModel">
                            <span class="custom-radio"></span>
                            <span class="label-choice">{{'chargeAreas.edit.outdoor' | translate}}</span>
                        </label>
                        <label class="cell auto space-right-16 radio">
                            <input type="radio" [(ngModel)]="chargeArea.geolocation.locationType"
                                   [value]="LocationType.INDOOR"
                                   name="locationType"
                                   #locationType="ngModel">
                            <span class="custom-radio"></span>
                            <span class="label-choice">{{'chargeAreas.edit.indoor' | translate}}</span>
                        </label>
                    </div>
                    <label class="cell space-right-16 space-top-16">
                        <span>{{'chargeAreas.edit.description' | translate}}</span>
                        <textarea [(ngModel)]="chargeArea.description" name="description"
                                  #description="ngModel"></textarea>
                    </label>
                </div>
                <div *ngIf="stationOpeningConfigurable"
                     class="cell small-14">
                    <label class="openLabel">{{'chargeAreas.edit.opening.label' | translate}}</label>
                    <div class="cardContainer force-inner-space-bottom-0">
                        <label class="cell auto checkbox">
                            <input type="checkbox" [ngModel]="chargeArea.opening.open247"
                                   (ngModelChange)="toggleOpen247($event)"
                                   name="open247"
                                   #open247="ngModel">
                            <span class="custom-checkbox"></span>
                            <span class="label-choice">{{'chargeAreas.edit.opening.247' | translate}}</span>
                        </label>
                        <div>
                            <opening-day-list-selector
                                [(ngModel)]="chargeArea.opening"
                                (change)="openingDaysChange()"
                                name="opening" class="grid-x cell small-24"></opening-day-list-selector>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid-x cell">
                <charge-area-geolocation-edit class="cell small-24"
                                              [hiddenMap]="hiddenMap"
                                              [geolocation]="chargeArea.geolocation"
                                              (geolocationChange)="updateChargeArea()"></charge-area-geolocation-edit>
            </div>

        </ng-container>
    </form>
