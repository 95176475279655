import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AuthenticateOptions,
    AuthenticationMethod,
    ConnectedUser,
    Credentials,
    FoAccountStatusEnum,
    FrontFoAccountHttpService,
    FrontPackSubscriptionHttpService,
    FrontSessionBusinessService,
    HOST_THIRD_PARTY_ID,
    PackSubscriptionCguAcceptationDto
} from 'lib-front';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../services/utils/notification.service';
import {Observable, of, Subscription} from 'rxjs';
import {finalize, first, switchMap, tap} from 'rxjs/operators';
import {
    AcceptTermsAndConditionsPopupComponent
} from '../../components/accept-terms-and-conditions-popup/accept-terms-and-conditions-popup.component';
import {
    AcceptPackSubscriptionsConditionsComponent
} from '../../components/accept-pack-subscriptions-conditions/accept-pack-subscriptions-conditions.component';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    host: {'class': 'cell grid-y auto align-middle'}
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscriptionConnectedUser: Subscription;

    public credentials: Credentials = {
        login: '',
        password: '',
        adminLogin: '',
        ignoreTwinningRelation: true,
        rememberMe: false
    };

    public isAdminLogin: boolean;
    @ViewChild('termsAndConditionsOfUsePopup') termsAndConditionsOfUsePopup: AcceptTermsAndConditionsPopupComponent;
    @ViewChild('conditionsOfSubscriptionPacks') conditionsOfSubscriptionPacks: AcceptPackSubscriptionsConditionsComponent;

    public authenticating: boolean;
    public allowToSignUp: boolean = true;
    public proLoginPageNotice: string | undefined;

    public constructor(
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly notificationService: NotificationService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly packSubscriptionService: FrontPackSubscriptionHttpService,
        private readonly frontEndService: FrontEndService,
        private readonly foAccountService: FrontFoAccountHttpService
    ) {

        this.isAdminLogin = false;
        route.queryParams.subscribe(data => {
            if (!!data && !!data.adm) {
                this.isAdminLogin = true;
                this.credentials.adminLogin = data.adm;
                this.credentials.login = data.u;
            }
        });
    }

    ngOnInit() {
        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => {
                this.allowToSignUp = frontEndInfo.fleetConfig?.allowToSignUp ?? true;
                if (frontEndInfo.fleetConfig?.displayProLoginPageNotice) {
                    this.proLoginPageNotice = frontEndInfo.fleetConfig?.proLoginPageNotice;
                } else {
                    this.proLoginPageNotice = null;
                }
            }
            );
    }

    ngAfterViewInit(): void {
        // check if user is connected : filter undefined => subject has initial value and does not yet the http response
        this.subscriptionConnectedUser = this.sessionBusinessService.connectedUser$
            .pipe(
                first(user => user !== undefined),
                switchMap(user => this.checkCurrentUserTermsAndConditionsOfUse(user))
            ).subscribe((user: ConnectedUser) => {
                if (user && user.user.termsAndConditionsOfUseAccepted) {
                    this.checkPackSubscriptionsConditions();
                }
            });
    }

    public authenticate(credentials: Credentials): void {
        this.authenticating = true;

        credentials.login = credentials.login.trim();

        const authenticateOptions: AuthenticateOptions = {
            shouldHashPassword: true,
            preferredAuthenticationMethod: AuthenticationMethod.COOKIE
        };
        this.subscriptionConnectedUser = this.sessionBusinessService
            .authenticate(credentials, authenticateOptions)
            .pipe(
                switchMap((connectedUsers) => this.checkFoAccountStatus(connectedUsers)),
                switchMap((connectedUsers) => this.checkCurrentUserTermsAndConditionsOfUse(connectedUsers[0]))
            )
            .subscribe(
                (user: ConnectedUser) => {
                    if (user && user.user.termsAndConditionsOfUseAccepted) {
                        this.checkPackSubscriptionsConditions();
                    }
                },
                () => {
                    this.notificationService.error('login.error');
                    this.authenticating = false;
                }
            );
    }

    public ngOnDestroy(): void {
        this.subscriptionConnectedUser?.unsubscribe();
    }


    private checkCurrentUserTermsAndConditionsOfUse(user: ConnectedUser): Observable<ConnectedUser> {
        if (!!user) {
            if (!user.user.termsAndConditionsOfUseAccepted) {
                this.termsAndConditionsOfUsePopup.askConfirmTermsAndConditions(user.user._id);
            }
        }
        return of(user);
    }

    termsAndConditionsOfUseAccepted() {
        this.sessionBusinessService.reloadUser$()
            .pipe(
                first(user => user !== undefined),
            ).subscribe(_ => {
                this.checkPackSubscriptionsConditions();
            });
    }

    logout(): void {
        this.sessionBusinessService
            .logout()
            .pipe(
                finalize(() => this.authenticating = false)
            )
            .subscribe();
    }

    private checkPackSubscriptionsConditions() {
        this.packSubscriptionService.findPackSubscriptionByFoAccountAndCguNotAccepted()
            .subscribe((packSubscriptions) => {
                if (packSubscriptions.length) {
                    this.conditionsOfSubscriptionPacks.askConfirmPackSubscriptionsConditions(packSubscriptions);
                } else {
                    this.goToAuthenticatedSpace();
                }
            });
    }

    packSubscriptionsConditionsAccepted(packSubscription: PackSubscriptionCguAcceptationDto[]) {
        this.packSubscriptionService.acceptCgu(packSubscription)
            .subscribe(() => {
                this.goToAuthenticatedSpace();
            });
    }

    private goToAuthenticatedSpace(): void {
        of(this.router.navigate(['home']))
            .pipe(
                finalize(() => this.authenticating = false)
            )
            .subscribe();
    }

    private checkFoAccountStatus(connectedUsers: ConnectedUser[]): Observable<ConnectedUser[]> {
        return this.foAccountService.findFOAccount()
            .pipe(
                switchMap(foAccount => {
                    if (foAccount.currentStatus.status == FoAccountStatusEnum.VALIDATED) {
                        return of(connectedUsers);
                    }
                    this.logout();
                })
            );
    }
}
