<div class="cell grid-x">
    <h2 class="cell small-24 secondary block-title space-bottom-16">
        {{'update.summary.title' | translate}}
    </h2>
    <!-- SUBSCRIPTION -->
    <div class="cell grid-x grid-margin-x space-bottom-50 block-container">
        <div class="cell small-12">
            <h3>{{'update.subscription.summary.before' | translate}}</h3>
            <h5 class="margin-8">{{'update.subscription.summary.offer' | translate}}</h5>
            <subscription-offer-view [mode]="ModeSubscriptionOfferView.UPDATE_OLD" [packSubscription]="packSubscription">
            </subscription-offer-view>
        </div>
        <div class="cell small-12">
            <h3>{{'update.subscription.summary.now' | translate}}</h3>
            <h5 class="margin-8">{{'update.subscription.summary.offer' | translate}}</h5>
            <subscription-offer-view [mode]="ModeSubscriptionOfferView.UPDATE_NEW" [subscriptionPack]="subscriptionPack"
                                     [compatibleMediaQuantity]="compatibleMedia"
                                     [mediasOrderQuantity]="orderMedias?.quantity">
            </subscription-offer-view>
        </div>
    </div>
    <!-- OPTION -->
    <div class="cell grid-x grid-margin-x space-bottom-24 block-container">
        <div class="cell small-12">
            <h5 class="margin-8">{{'update.options.title' | translate}}</h5>
            <subscription-options-result-view [totalPrice]="optionResult?.currentTotalPrice"
                                              [totalHours]="optionResult?.currentTotalHours"
                                              [options]="optionResult?.options">
            </subscription-options-result-view>
        </div>
        <div class="cell small-12">
            <h5 class="margin-8">{{'update.options.title' | translate}}</h5>
            <subscription-options-result-view [totalPrice]="optionResult?.totalPrice"
                                              [totalHours]="optionResult?.totalHours"
                                              [options]="optionResult?.options">
            </subscription-options-result-view>
        </div>
    </div>
    <!-- PASS -->
    <div class="cell grid-x" *ngIf="orderMedias && orderMedias.quantity">
        <div class="cell small-24">
            <subscription-media-order [subscriptionPack]="subscriptionPack" [mediaOrderForm]="orderMedias">
            </subscription-media-order>
        </div>
    </div>
    <!-- INFO -->
    <h5 class="info cell auto common-block force-space-bottom-0" style="margin-top: 8px;">
        {{'update.subscription.summary.information' | translate}}
    </h5>
</div>

