import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {isMetaChargeConsumption, MetaConsumptionDto} from '../domain/meta/metaConsumptionDto';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'fleetMetaConsumptionDate'
})
export class FleetMetaConsumptionDatePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) public readonly locale: string) {}

    transform(metaConsumption: MetaConsumptionDto, format?: string): string | null {
        return new DatePipe(this.locale)
            .transform(isMetaChargeConsumption(metaConsumption) ? metaConsumption.end : metaConsumption.consumptionDate, format);
    }
}
