<label class="grid-y cell shrink">
    <span class="priceInfo-content_label">{{'common.price'|translate}}</span>
    <div class="priceInfo" *ngIf="mediaOrderInfo">
        <div class="grid-x priceInfo-content">
            <div class="cell auto">{{mediaOrderInfo.quantity}} {{mediaOrderInfo.mediaFamily.name}} :</div>
            <div class="priceInfo-space cell shrink">
                <span class="cell auto media-order-price large">{{priceInfo?.price | currency:'EUR':''}}</span>
                <span
                    class="cell shrink media-order-euro large">{{priceInfo?.currencyUnit}} {{'common.ttc'| translate}}</span>
            </div>
        </div>
        <div class="priceInfo-content grid-x priceInfo-content" *ngIf="promoSubscriptionPack">
            <div class="cell auto">{{promoSubscriptionPack.name}} :</div>
            <div class="priceInfo-space cell shrink">
                <span class="cell small-8 media-order-price large">-{{priceInfo?.discount | currency:'EUR':''}}</span>
                <span
                    class="cell shrink media-order-euro large">{{priceInfo?.currencyUnit}} {{'common.ttc'| translate}}</span>
            </div>
        </div>
        <div class="priceInfo-content border grid-x">
            <div class="cell auto"><b>{{'common.total'|translate}} :</b></div>
            <div class="cell shrink priceInfo-total priceInfo-space">
                <span
                    class="cell small-8 media-order-price large"><b>{{priceInfo?.bestPrice | currency:'EUR':''}}</b></span>
                <span
                    class="cell shrink media-order-euro large"><b>{{priceInfo?.currencyUnit}} {{'common.ttc'| translate}}</b></span>
            </div>
        </div>
    </div>
    <div class="priceInfo" *ngIf="!mediaOrderInfo">
        -
    </div>
</label>
