<div class="cell auto grid-y">
    <div *ngIf="!hideHeads"
         class="tabs cell shrink clear-padding">
        <div  class="tabs-round-block">
            <div *ngFor="let item of items; trackBy: trackByTabTitle"
                 class="tabs-title"
                 [class.is-active]="item.selected"
                 [hidden]="item.hidden">
                <a (click)="selectItem(item)" [attr.aria-selected]="item.selected"
                   *ngIf="!item.hidden">{{item.title}}</a>
            </div>
        </div>
        <ng-content select="[buttons-section]"></ng-content>
    </div>

    <div class="tabs-content main-container cell auto">
        <div class="tabs-panel is-active">
            <ng-content select="[tab-section]"></ng-content>
        </div>
    </div>
</div>
