<div class="grid-x cell">
    <div *ngIf="!hiddenMap"
         class="cell small-24 grid-y space-top-24 space-bottom-16">
        <label class="cell">
            <span class="label-choice">{{'chargeAreas.edit.location.label'|translate}}</span>
            <input class="address input" type="text" ngx-google-places-autocomplete
                   (onAddressChange)="handleAddressChange($event)"/>
        </label>
    </div>

    <div class="grid-x cell small-24 space-bottom-24">
        <div class="cell small-7 space-right-16">
            <fieldset class=" parcAddress">
                <legend>{{'chargeAreas.edit.location.parcAddress'|translate}}</legend>
                <div class="cell space-top-8">
                    <h5>{{'chargeAreas.edit.location.address'|translate}}</h5>
                    <h6>{{geolocation|fleetLocation:LocationType.ROUTE}}</h6>
                    <h6>{{geolocation|fleetLocation:LocationType.CITY}}</h6>
                    <h6>{{geolocation.country}}</h6>
                </div>
                <div class="cell space-top-48">
                    <h5>{{'chargeAreas.edit.location.gps'|translate}}</h5>
                    <h6>{{'chargeAreas.edit.location.lng'|translate:{lng: coordinates.lng} }}</h6>
                    <h6>{{'chargeAreas.edit.location.lat'|translate:{lat: coordinates.lat} }}</h6>
                </div>
            </fieldset>
        </div>
        <div *ngIf="!hiddenMap"
             class="cell auto">
            <google-map height="400px"
                        width="100%"
                        [center]="coordinates">
                <map-marker #marker
                            [options]="mapMarkerOptions"
                            [position]="coordinates"
                            (mapDragend)="updateGeolocationCoordinates($event)"></map-marker>
            </google-map>
        </div>
    </div>
</div>
