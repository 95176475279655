import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'blocks-guide',
    templateUrl: './blocks-guide.component.html',
    styleUrls: ['./blocks-guide.component.scss']
})
export class BlocksGuideComponent {

    constructor() {
    }
}
