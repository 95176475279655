<div class="grid-x align-center align-middle light-grey-block" *ngIf="_mediaOrderForm">
    <div class="cell small-10">
        <img *ngIf="mediaFamilyLogoRef" class="media-order-img space-right-32"
             [src]="'/api/download/' + mediaFamilyLogoRef" alt="media visual">
    </div>
    <div class="grid-x cell auto align-top align-left">
        <div class="cell shrink space-right-32 media-order-quantity">
            <div>
                <label>
                    <span>{{'media.order.quantity.title' | translate}}</span>
                    <input name="quantity" [(ngModel)]="_mediaOrderForm.quantity" [disabled]="true">
                </label>
            </div>
        </div>
        <div class="grid-y cell shrink">
            <div class="grid-x cell align-middle align-justify">
                <label class="cell shrink">{{'media.order.price.title' | translate}}</label>
                <div class="grid-x cell small-8 media-order-price-container">
                    <span
                        class="cell auto media-order-price large">{{_mediaOrderForm.emoMediaOrderPrice.bestPrice | currency:'EUR':''}}</span>
                    <span class="cell shrink media-order-euro large">{{_mediaOrderForm.emoMediaOrderPrice.currencyUnit}}
                        TTC</span>
                </div>
            </div>
        </div>
    </div>
</div>
