<div class="cell auto">
    <div class="grid-x grid-margin-x grid-margin-y small-up-3 options-container">
        <div class="subscription-option-block"
             *ngFor="let option of options; trackBy: trackBySubscriptionId">
            <div class="subscription-option-block-background-parent">
                <div class="grid-x align-middle fix-z-index" *ngIf="option.subscription?.freeTime">
                        <span
                            class="cell shrink subscription-option-block-time">{{option.subscription.freeTime | fleetDuration:{} }}</span>
                    <div class="grid-y cell auto space-left-8 subscription-option-block-title">
                        <span *ngIf="option.subscription.freeTime < 7200"
                              class="cell shrink">{{'register.subscription.options.freeTime.one' | translate}}</span>
                        <span *ngIf="option.subscription.freeTime >= 7200"
                              class="cell shrink">{{'register.subscription.options.freeTime.multiple' | translate}}</span>
                        <span
                                class="cell shrink subscription-option-block-subtitle">{{option.subscription.subscriptionDefName}}</span>
                    </div>
                </div>

                <div *ngIf="option.subscription?.subscriptionDef"
                     class="grid-x align-middle align-center space-top-16 fix-z-index">
                    <div class="grid-y text-center subscription-option-block-price space-left-16">
                                <h5 class="cell subscription-option-block-price-value">
                                    {{option.subscription.subscriptionDef.fixAmountWithVAT | currency:'EUR':option.subscription.subscriptionDef.currencyUnit || '' }}
                                </h5>
                        <span
                                class="cell force-font-bold">{{'register.subscription.pack.by.month'| translate}}</span>
                    </div>
                </div>
                <label class="cell note small text-center space-top-16"
                       *ngIf="option.subscription?.subscriptionDef?.engagementDuration">
                    {{'register.subscription.options.commitment' | translate:{ month: option.subscription.subscriptionDef.engagementDuration} }}
                </label>
                <div *ngIf="option.subscription?.subscriptionDef"
                     class="text-center">
                     <span class="subscription-option-block-background"
                           [ngClass]="{smallBackground:smallBackground}">{{'register.subscription.options.price' | translate:{price: option.subscription.subscriptionDef.fixAmountWithVAT | currency:'EUR':option.subscription.subscriptionDef.currencyUnit || ''} }}</span>
                </div>
            </div>
            <span class="cell shrink subscription-option-block-number">x{{option.number}}</span>
        </div>
    </div>
</div>
