import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {
    FILE_API_PATH,
    PackSubscriptionCguAcceptationDto
} from 'lib-front';
import {IziviaPopupComponent} from '../izivia-popup/izivia-popup.component';
@Component({
    selector: 'accept-pack-subscriptions-conditions',
    templateUrl: './accept-pack-subscriptions-conditions.component.html',
    styleUrls: ['./accept-pack-subscriptions-conditions.component.scss']
})
export class AcceptPackSubscriptionsConditionsComponent {

    public packSubscriptions: PackSubscriptionCguAcceptationDto[];
    @Output() accepted = new EventEmitter<PackSubscriptionCguAcceptationDto[]>();
    @Output() refused = new EventEmitter<void>();
    @ViewChild('popup') popup: IziviaPopupComponent;

    validPackSubscriptionsConditions(acceptConditions: boolean) {
        this.popup.close();
        if (acceptConditions) {
            this.accepted.next(this.packSubscriptions);
        } else {
            this.refused.next();
        }
    }

    askConfirmPackSubscriptionsConditions(packSubscriptions: PackSubscriptionCguAcceptationDto[]) {
        this.packSubscriptions = packSubscriptions;
        this.popup.open();
    }

    getCGUUrl(termsAndConditionsI18nCode: string): string {
        return FILE_API_PATH + `${termsAndConditionsI18nCode}?lang=fr`;
    }

    allCguAreNotAccepted(): boolean {
        return !!this.packSubscriptions &&
            !!this.packSubscriptions
                .map(pack => pack.cguAccepted)
                .filter(cguAccepted => !cguAccepted)
                .length;
    }
}
