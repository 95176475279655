import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {isArray} from 'lodash-es';
import {IziviaPopupComponent} from '../izivia-popup/izivia-popup.component';

@Component({
    selector: 'drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent {

    @Input() set accept(value: string[]) {
        this._accept = isArray(value) ? value.join(',') : value;
    }
    @Input() required: boolean;
    @Output() fileChange: EventEmitter<File> = new EventEmitter<File>();
    @ViewChild('popup') popup: IziviaPopupComponent;

    _accept: string;
    file: File;
    unsupportedFile: File;
    maxSize: number = 50;
    popupMessageKey: string;

    fileUploaded(event: Event) {
        let file = (event.target as HTMLInputElement).files[0];
        if (!this._accept.split(',').includes(file.type)) {
            this.unsupportedFile = file;
            this.popupMessageKey = 'informations.supportingDocuments.formatError';
            this.popup.open();
            return;
        }
        if(this.computeSize(file.size) > this.maxSize) {
            this.unsupportedFile = file;
            this.popupMessageKey = 'informations.supportingDocuments.sizeError';
            this.popup.open();
            return;
        }
        this.file = file;
        this.fileChange.next(this.file);
    }

    onFileDropped(files: File[]) {
        if (this._accept.split(',').includes(files[0].type) && this.computeSize(files[0].size) <= this.maxSize) {
            this.file = files[0];
            this.fileChange.next(this.file);
        }
    }

    computeSize(size: number) {
        return Math.round((size / 1024 / 1024) * 1000) / 1000;
    }

    deleteFile(event: MouseEvent) {
        this.file = null;
        this.fileChange.next(this.file);

        // Prevent to open file upload popup
        event.preventDefault();
        event.stopPropagation();
    }
}
