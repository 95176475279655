import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, NgModel,
    ValidationErrors,
    Validator
} from '@angular/forms';

import {IziviaPasswordValidatorBuilder, IziviaPasswordValidatorDefinition} from 'lib-front';

@Component({
    selector: 'show-hide-password-input',
    templateUrl: './show-hide-password-input.component.html',
    styleUrls: ['./show-hide-password-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ShowHidePasswordInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ShowHidePasswordInputComponent),
            multi: true,
        }
    ]
})
export class ShowHidePasswordInputComponent implements ControlValueAccessor, Validator {

    @ViewChild('pwd') pwd: NgModel;

    private validatorChange: () => void;
    onChange: (_: string) => void;
    onTouched: () => void;


    @Input() required?: boolean;
    @Input() autocomplete?: 'new-password' | 'current-password';

    showPassword: boolean = false;
    password: string;
    disabled: boolean;

    private validators: Validator[] = [];


    @Input()
    set passwordValidators(defs: IziviaPasswordValidatorDefinition[]) {
        this.validators = defs.map(IziviaPasswordValidatorBuilder.fromDefinition);
    };


    constructor() {
    }

    toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    public registerOnChange(onChange: (_: string) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(value: string): void {
        this.password = value;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.validatorChange = fn;
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        const control: AbstractControl = this.pwd.control;
        let validationErrors: ValidationErrors = {};


        this.validators
            .map(validator => validator.validate(control))
            .filter(error => !!error)
            .forEach(error => validationErrors = Object.assign(validationErrors, error));

        const customAndControlErrors = Object.assign(control.errors ? control.errors : {}, validationErrors);

        return Object.keys(customAndControlErrors).length > 0 ? customAndControlErrors : null;

    }

}
