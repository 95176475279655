<section class="grid-y style-guide-block">
    <h1 class="style-guide-title">Couleurs</h1>

    <div class="cell style-guide-section-content">
        <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Vous retrouverez les différentes variables de couleurs sur la feuille de style dans <strong>styles/utils/variables.scss</strong>
            </p>
        </div>

        <!-- Global Colors -->
        <h1 class="style-guide-sub-title">Global</h1>

        <div class="grid-x small-up-2 medium-up-4 large-up-5 style-guide-colors-container">
            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-primary"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-primary-hover"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-secondary"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-color"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-color-light"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-color-dark"></span></div>
            </div>
        </div>

        <!-- Status Colors -->

        <h1 class="style-guide-sub-title">Status</h1>

        <div class="grid-x small-up-2 medium-up-4 large-up-5 style-guide-colors-container">
            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-success"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-success-dark"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-warning"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-error"></span></div>
            </div>
        </div>


        <!-- Nuances de gris -->

        <h1 class="style-guide-sub-title">Nuances de gris</h1>

        <div class="grid-x small-up-2 medium-up-4 large-up-5 style-guide-colors-container">
            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--white"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--white-grey"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--light-grey"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--medium-grey"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--dark-grey"></span></div>
            </div>
        </div>


        <!-- Effets -->

        <h1 class="style-guide-sub-title">Effets</h1>

        <div class="grid-x small-up-2 medium-up-4 large-up-5 style-guide-colors-container">
            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--gradient-transparent"></span></div>
            </div>

            <div class="cell">
                <div class="style-guide-colors"><span class="sg__colour--app-color-gradient"></span></div>
            </div>
        </div>
    </div>
</section>
