import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AdditionalInformationAnswers,
    addVehicleVersionToAdditionalInformationAnswers,
    buildVehicleVersionFromAdditionalInformationAnswers,
    CityDto,
    computeAdditionalInformationOrderCodeKey,
    CountryDto,
    FileUploadKeyPrefix,
    FormElement,
    FormElementType,
    FrontReferenceHttpService,
    PostalCodeDto,
    UploadFile,
    VehicleVersionDto
} from 'lib-front';
import {ControlContainer, NgForm} from '@angular/forms';
import {of, Subject, Subscription} from 'rxjs';
import {debounceTime, switchMap, take} from 'rxjs/operators';
import {AddressFormInputElem} from '../address-form-wrapper/manual-address-form/manual-address-form.component';
import {CitySearchRequest} from '../address-form-wrapper/address.directive';


@Component({
    selector: 'additional-information-field',
    templateUrl: './additional-information-field.component.html',
    styleUrls: ['./additional-information-field.component.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class AdditionalInformationFieldComponent implements OnInit, OnDestroy {

    formElementType = FormElementType;
    additionalInformationOrderCodeKey: string;
    @Input() additionalInformationAnswers: AdditionalInformationAnswers = {};
    @Input() additionalInformation: FormElement;

    @Output() public fieldChange = new EventEmitter<void>();

    vehicleVersion: VehicleVersionDto;

    private readonly SUGGESTION_LIMIT: number = 5;

    postalCodeSuggestions: PostalCodeDto[] = [];
    citySuggestions: CityDto[] = [];
    countries: CountryDto[] = [];

    private inputFocusByAddressFormInputElem: Map<AddressFormInputElem, boolean> = new Map([['address', false], ['postalCode', false], ['city', false]]);

    private postalCodeSuggestionSubject: Subject<string> = new Subject();
    private citySuggestionSubject: Subject<CitySearchRequest> = new Subject();

    private postalCodeSuggestionSubscription: Subscription;
    private citySuggestionSubscription: Subscription;

    constructor(private readonly referenceService: FrontReferenceHttpService) {
    }

    ngOnInit(): void {
        this.referenceService.findCountries().pipe(take(1)).subscribe(countries => {
            this.countries = countries;
            if (!this.additionalInformationAnswers['country_' + this.additionalInformation.label]) {
                this.additionalInformationAnswers['country_' + this.additionalInformation.label] = 'FR';
            }
        });

        if (this.additionalInformation && this.additionalInformation.type === FormElementType.vehicle) {
            this.createVehicle();
        }
        if (this.additionalInformation?.type === FormElementType.upload) {
            this.additionalInformationAnswers[this.additionalInformation.label] = new UploadFile();
            this.additionalInformationAnswers[this.additionalInformation.label].fileList =
                this.additionalInformationAnswers[FileUploadKeyPrefix.FILENAME + this.additionalInformation.label] ? [
                    {name: this.additionalInformationAnswers[FileUploadKeyPrefix.FILENAME + this.additionalInformation.label]}
                ] : [];
        } else if (this.additionalInformation?.type === FormElementType.orderCode) {
            this.additionalInformationOrderCodeKey = computeAdditionalInformationOrderCodeKey(this.additionalInformation.label);
        }

        this.postalCodeSuggestionSubscription = this.postalCodeSuggestionSubject.pipe(
            debounceTime(500),
            switchMap((postalCode) => {
                if (!!postalCode) {
                    return this.referenceService.findPostalCodes(postalCode, this.SUGGESTION_LIMIT);
                } else {
                    return of([]);
                }
            })
        ).subscribe(postalCodes => this.postalCodeSuggestions = postalCodes);

        this.citySuggestionSubscription = this.citySuggestionSubject.pipe(
            debounceTime(500),
            switchMap((request: CitySearchRequest) =>
                this.referenceService.findCities(request.city, request.postalCode, this.SUGGESTION_LIMIT)
            )
        ).subscribe(cities => this.citySuggestions = cities);
    }

    ngOnDestroy() {
        this.postalCodeSuggestionSubscription.unsubscribe();
        this.citySuggestionSubscription.unsubscribe();
    }

    clearPostalCodeSuggestions() {
        this.postalCodeSuggestions = [];
    }

    clearCitySuggestions() {
        this.citySuggestions = [];
    }

    pushNewPostalCode(event: {term: string, items: any[]}) {
        let postalCode = event.term;
        this.additionalInformationAnswers['zipCode_' + this.additionalInformation.label] = postalCode;
        if (this.additionalInformationAnswers['country_' + this.additionalInformation.label] == 'FR') {
            this.postalCodeSuggestionSubject.next(postalCode);
        }
    }

    pushNewCity(event: {term: string, items: any[]}) {
        let cityName = event.term;
        this.additionalInformationAnswers['city_' + this.additionalInformation.label] = cityName;
        if (this.additionalInformationAnswers['country_' + this.additionalInformation.label] == 'FR') {
            this.citySuggestionSubject.next({city: cityName, postalCode: this.additionalInformationAnswers['zipCode_' + this.additionalInformation.label]});
        }
    }

    focusOn(elem: AddressFormInputElem) {
        this.inputFocusByAddressFormInputElem.set(elem, true);
    }

    focusOut(elem: AddressFormInputElem) {
        this.inputFocusByAddressFormInputElem.set(elem, false);
    }

    shouldShowPostalCodeSuggestions(): boolean {
        return this.inputFocusByAddressFormInputElem.get('postalCode')
            && this.postalCodeSuggestions.length > 0
            && this.additionalInformationAnswers['country_' + this.additionalInformation.label] == 'FR';
    }

    shouldShowCitySuggestions(): boolean {
        return this.inputFocusByAddressFormInputElem.get('city')
            && this.citySuggestions.length > 0
            && this.additionalInformationAnswers['country_' + this.additionalInformation.label] == 'FR';
    }

    validPostalCodeSuggestion(postalCode: string) {
        this.additionalInformationAnswers['zipCode_' + this.additionalInformation.label] = postalCode;
        this.clearPostalCodeSuggestions();
        this.referenceService.findCities(this.additionalInformationAnswers['city_' + this.additionalInformation.label], postalCode, this.SUGGESTION_LIMIT)
            .subscribe(cities => {
                if (cities.length == 1) {
                    this.clearCitySuggestions();
                    this.additionalInformationAnswers['city_' + this.additionalInformation.label] = cities[0].name;
                } else {
                    this.citySuggestions = cities;
                }
            });
    }

    validCitySuggestion(cityName: string) {
        this.additionalInformationAnswers['city_' + this.additionalInformation.label] = cityName;
        this.clearCitySuggestions();
    }

    notifyFieldChange() {
        if (this.fieldChange) {
            this.fieldChange.emit();
        }
    }

    createVehicle() {
        this.vehicleVersion = buildVehicleVersionFromAdditionalInformationAnswers(
            this.additionalInformationAnswers,
            this.additionalInformation.label
        );
    }


    addVehicleToAdditionalInformation() {
        addVehicleVersionToAdditionalInformationAnswers(
            this.additionalInformationAnswers,
            this.vehicleVersion,
            this.additionalInformation.label
        );
    }

    addFile() {
        this.additionalInformationAnswers[this.additionalInformation.label].needToBeSave =
            !!this.additionalInformationAnswers[this.additionalInformation.label].fileList;
    }

    trackByPostalCode(index: number, postalCodeSuggestion: PostalCodeDto) {
        return postalCodeSuggestion?.postalCode ?? index;
    }

    trackByCityName(index: number, citySuggestion: CityDto) {
        return citySuggestion?.name ?? index;
    }

    // By default (a, b) => a === b but if it returns
    // true you can't select the option.
    compareAlwaysFalse(a, b) {
        return false;
    }
}
