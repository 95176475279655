import {Component, OnInit} from '@angular/core';
import {
    CustomerAccount,
    ExpenseReportExportSearchRequest,
    ExpenseReportGroupingField,
    ExpenseReportGroupItemDto,
    ExpenseReportGroupPaginatedSearchRequest,
    ExpenseReportHttpService,
    ExpenseReportStatusType,
    FrontEndFleetConfig,
    FrontFoAccountHttpService,
    FrontInvoiceHttpService,
    FrontPersonHttpService,
    HttpParamsUtil, InvoiceForm,
    isRecurringPaymentType, PaymentParams,
    PaymentSource, PaymentType,
    RefundedExpensesConfiguration,
    RefundedExpensesMethodType,
    RefundedExpensesType,
    Reporting,
    User
} from 'lib-front';
import moment, {Moment} from 'moment';
import {remove} from 'lodash-es';
import {HttpParams} from '@angular/common/http';
import {FleetCalendarMode} from '../../../../domain/FleetCalendarMode';
import {catchError, finalize, first} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {AlertService} from '../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentUserContextService} from '../../../../services/business/currentUserContext.service';
import {Observable, of} from 'rxjs';
import {NotificationService} from '../../../../services/utils/notification.service';

@Component({
    selector: 'app-expense-report-search',
    templateUrl: './expense-report-search.component.html',
    styleUrls: ['./expense-report-search.component.scss'],
    host: {'class': 'scroll-container'}
})
export class ExpenseReportSearchComponent implements OnInit {

    public page: number;

    public searchRequest: ExpenseReportGroupPaginatedSearchRequest;
    public expenseReportGroups: ExpenseReportGroupItemDto[] = [];
    public statuses: ExpenseReportStatusType[] = [];
    public selectedStatus: ExpenseReportStatusType = null;
    public fromSearch = moment();
    public toSearch = moment();
    public expenseReportGroupingField = ExpenseReportGroupingField;

    public calendarMode = FleetCalendarMode.MONTH;
    public refundedExpensesConfiguration: RefundedExpensesConfiguration;
    public activeStationCount: number = 0;

    public resultsPerPage = 5;

    public searching: boolean;
    public generatingInvoice: boolean;

    public Reporting = Reporting;
    public fleetConfig: FrontEndFleetConfig;
    invoiceForm$: Observable<InvoiceForm | null>;
    private user: User;
    private isRecurringPaymentAvailable: boolean = false;

    constructor(private expenseReportHttpService: ExpenseReportHttpService,
        private frontFoAccountService: FrontFoAccountHttpService,
        private frontEndService: FrontEndService,
        private currentUserContextService: CurrentUserContextService,
        private personService: FrontPersonHttpService,
        private invoiceService: FrontInvoiceHttpService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit(): void {
        this.frontFoAccountService.findFOAccount().subscribe(foAccountWrapper => {
            console.log(foAccountWrapper);
            this.activeStationCount = foAccountWrapper.activeStationCount;
            this.refundedExpensesConfiguration = foAccountWrapper.refundedExpensesConfiguration;
        }
        );
        this.buildResearchRequest();
        this.buildStatusesList();
        this.activatedRoute.queryParamMap
            .pipe(first())
            .subscribe(paramMap => this.searchFromParams(paramMap));
        this.activatedRoute.data.subscribe(data =>
            this.user = data.user.user
        );
        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);
        this.loadPaymentConfs();
    }

    public search(page: number): void {
        this.page = page;
        this.searchRequest.skip = (this.page - 1) * this.searchRequest.limit;
        this.addSearchRequestToQueryParams();
        this.searchGroups();
    }

    private addSearchRequestToQueryParams() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: this.searchRequest,
            queryParamsHandling: 'merge'
        });
    }

    public switchGroupBy(groupByField: string): void {
        switch (groupByField) {
            case ExpenseReportGroupingField.COLLABORATOR:
                this.removeIfGroupByIsInListElseAddIt(this.searchRequest.groupBy,
                    ExpenseReportGroupingField.COLLABORATOR);
                break;
            case ExpenseReportGroupingField.MONTH:
                this.removeIfGroupByIsInListElseAddIt(this.searchRequest.groupBy,
                    ExpenseReportGroupingField.MONTH);
                break;
        }

        this.search(1);
    }

    public getExpenseReportReport(): void {
        const expenseReportGroupRequest = this.getExpenseReportGroupSearchRequest(this.searchRequest);
        const params: HttpParams = HttpParamsUtil.toHttpParams(expenseReportGroupRequest);
        window.open(`api/v2/expenseReports/export/csv?${params.toString()}`);
    }

    public updateFromSearchRequest(date: Moment): void {
        this.searchRequest.from = moment(date).startOf('month').toISOString();
    }

    public updateToSearchRequest(date: Moment): void {
        this.searchRequest.to = moment(date).endOf('month').toISOString();
    }

    public updateStatusSearchRequest(status: ExpenseReportStatusType): void {
        this.searchRequest.statuses = status ? [status] : null;
    }

    private removeIfGroupByIsInListElseAddIt(groupByList: ExpenseReportGroupingField[],
        groupBy: ExpenseReportGroupingField): void {
        if (groupByList.includes(groupBy)) {
            remove(groupByList, (group) => group === groupBy);
        } else {
            groupByList.push(groupBy);
        }
    }

    private searchGroups(): void {
        this.searching = true;
        this.expenseReportHttpService.searchGroups(this.searchRequest)
            .pipe(
                finalize(() => this.searching = false)
            )
            .subscribe(expenseReportGroups => this.expenseReportGroups = expenseReportGroups);
    }

    private buildResearchRequest(): void {
        this.searchRequest = {
            from: moment().startOf('month').toISOString(),
            to: moment().endOf('month').toISOString(),
            statuses: null,
            excludedStatus: ExpenseReportStatusType.DRAFT,
            q: undefined,
            groupBy: [ExpenseReportGroupingField.COLLABORATOR, ExpenseReportGroupingField.MONTH],
            limit: this.resultsPerPage,
            skip: undefined
        };
    }

    private buildStatusesList(): void {
        this.statuses = Object.keys(ExpenseReportStatusType).map(value => ExpenseReportStatusType[value]);
        const draftStatusIndexToRemove = this.statuses
            .findIndex(status => status === ExpenseReportStatusType.DRAFT);

        // Delete element at index. Here draft status
        this.statuses.splice(draftStatusIndexToRemove, 1);
    }

    private getExpenseReportGroupSearchRequest(paginatedSearch: ExpenseReportGroupPaginatedSearchRequest)
        : ExpenseReportExportSearchRequest {
        return {
            from: paginatedSearch.from,
            to: paginatedSearch.to,
            status: paginatedSearch.statuses && paginatedSearch.statuses[0] ? paginatedSearch.statuses[0] : null,
            q: paginatedSearch.q,
            firstName: paginatedSearch.collaboratorFirstName,
            lastName: paginatedSearch.collaboratorLastName
        };
    }

    public isChecked(group: ExpenseReportGroupingField) {
        return this.searchRequest.groupBy.includes(group);
    }

    private searchFromParams(paramMap: ParamMap) {
        if (!paramMap.has('from')) {
            this.search(1);
        } else {
            this.searchRequest = this.buildSearchRequestFromParamMap(paramMap);
            this.updateFormElementsFromSearchRequest();
            this.searchGroups();
        }
    }

    private updateFormElementsFromSearchRequest() {
        this.fromSearch = moment(this.searchRequest.from);
        this.toSearch = moment(this.searchRequest.to);
        this.selectedStatus = this.searchRequest.statuses && this.searchRequest.statuses.length > 0 ?
            this.searchRequest.statuses[0] :
            null;
        this.page = Math.floor(this.searchRequest.skip / this.resultsPerPage) + 1;
    }

    private buildSearchRequestFromParamMap(paramMap: ParamMap): ExpenseReportGroupPaginatedSearchRequest {
        return {
            from: paramMap.get('from'),
            to: paramMap.get('to'),
            statuses: paramMap.getAll('statuses').map(status => ExpenseReportStatusType[status]),
            excludedStatus: paramMap.get('excludedStatus') ? ExpenseReportStatusType[paramMap.get('excludedStatus')] : null,
            collaboratorFirstName: paramMap.get('collaboratorFirstName'),
            collaboratorLastName: paramMap.get('collaboratorLastName'),
            groupBy: paramMap.getAll('groupBy').map(field => ExpenseReportGroupingField[field]),
            limit: paramMap.get('limit') ? Number(paramMap.get('limit')) : this.resultsPerPage,
            skip: paramMap.get('skip') ? Number(paramMap.get('skip')) : 0
        };

    }

    public hasExpenseReportManualGenerationEnabled(): boolean {
        return this.refundedExpensesConfiguration?.automatically && this.refundedExpensesConfiguration.allowManualGeneration
            && this.expenseReportGroups.some(expenseReport => expenseReport.expenseReportStatusType === ExpenseReportStatusType.WAITING_REFUND);
    }

    public regulateExpenseReports(): void {
        this.alertService.confirm(
            this.translateService.instant(this.getExpenseReportsPopupMessage()),
            this.translateService.instant('expenseReport.regulate.popup.title'),
            this.translateService.instant('expenseReport.regulate.popup.validate'),
            this.translateService.instant('expenseReport.regulate.popup.cancel')).subscribe(
            response => {
                if (response) {
                    this.generatingInvoice = true;
                    this.expenseReportHttpService.regulateExpenseReports()
                        .pipe(
                            finalize(() => this.generatingInvoice = false)
                        )
                        .subscribe(invoice => {
                            if (!this.isRecurringPaymentAvailable) {
                                const paymentParams: PaymentParams = {
                                    paymentType: PaymentType.CREDIT_CARD,
                                    paymentConfigName : invoice.paymentContext.paymentConfigName
                                };

                                this.invoiceForm$ = this.invoiceService.pay(invoice.id, paymentParams, PaymentSource.FLEET)
                                    .pipe(
                                        catchError(err => {
                                            if (err && err.error && err.error.labelKey) {
                                                this.notificationService.warning(err.error.labelKey);
                                            } else {
                                                this.notificationService.warning('paymentStatusCause.ERROR');
                                            }
                                            return of(null);
                                        })
                                    );
                            } else {
                                this.notificationService.success('expenseReport.regulate.success');
                            }
                        }, error => {
                            if (error.status === 404) {
                                this.notificationService.warning('expenseReport.regulate.nothing');
                            } else {
                                this.notificationService.error('expenseReport.regulate.error');
                            }
                        });
                }
            });
    }

    private getExpenseReportsPopupMessage(): string {
        let message = '';
        switch (this.refundedExpensesConfiguration.methodType) {
            case RefundedExpensesMethodType.AFTER_INVOICE_GENERATION:
                message = 'expenseReport.regulate.popup.textRefundAtInvoiceGeneration';
                break;
            case RefundedExpensesMethodType.AFTER_INVOICE_PAYMENT:
                message = 'expenseReport.regulate.popup.textRefundAtInvoicePayment';
                break;
            case RefundedExpensesMethodType.ON_EXPENSE_REPORT_VALIDATION:
                message = 'expenseReport.regulate.popup.textImmediateRefundWithoutCondition';
                break;
        }
        return message;
    }

    private loadPaymentConfs(): void {
        if (this.user) {
            this.personService
                .findDetailedPerson(this.currentUserContextService.getCurrentPersonId())
                .subscribe(person => this.checkRecurringPaymentAvailability(person.customerAccounts));
        }
    }

    private checkRecurringPaymentAvailability(customerAccounts: CustomerAccount[]): void {
        this.isRecurringPaymentAvailable = customerAccounts.some(account =>
            account.paymentConfs.some(conf => !conf.disabled && isRecurringPaymentType(conf.paymentType))
        );
    }
}
