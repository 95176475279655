import {Route} from '@angular/router';
import {RegisterComponent} from './register.component';
import {RegisterCreationSuccessComponent} from './creationSuccess/register-creation-success.component';
import {CurrentUserResolver} from '../../resolver/currentUser.resolver';
import {AuthenticatedGuard} from '../authenticated.guard';
import {RegisterCreationPendingComponent} from './creationPending/register-creation-pending.component';

export const registerRoutes: Array<Route> = [
    {
        path: '', component: RegisterComponent
    },
    {
        path: 'success', component: RegisterCreationSuccessComponent, canActivate: [AuthenticatedGuard],
        resolve: {user: CurrentUserResolver}, runGuardsAndResolvers: 'always'
    },
    {
        path: 'pending', component: RegisterCreationPendingComponent
    }
];
