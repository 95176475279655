<table *ngIf="chargeRecords?.length > 0 else noCharge">
    <thead>
    <tr>
        <th>{{'chargeConsumptions.status' | translate}}</th>
        <th>{{'chargeConsumptions.chargePoint' | translate}}</th>
        <th>{{'chargeConsumptions.plug' | translate}}</th>
<!--        <th>{{'chargeConsumptions.tariff' | translate}}</th>-->
        <th>{{'chargeConsumptions.media' | translate}}</th>
        <th>{{'chargeConsumptions.startDate' | translate}}</th>
        <th>{{'chargeConsumptions.endDate' | translate}}</th>
        <th>{{'chargeConsumptions.duration' | translate}}</th>
        <th>{{'chargeConsumptions.energy' | translate}}</th>
        <th>{{'chargeConsumptions.price' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let chargeRecord of chargeRecords; trackBy: trackByChargeRecordId">
        <td>
            <span class="status status-color-{{chargeRecord.chargeRecordDtoStatus}}">
            {{'chargeRecordDtoStatus.' + chargeRecord.chargeRecordDtoStatus | translate}}
            </span>
        </td>
        <td>{{chargeRecord.infraChargeDto?.chargePoint?.name}}</td>
        <td>{{'plugType.' + chargeRecord.infraChargeDto?.plug?.outletModel | translate}}</td>
<!--        <td>{{chargeRecord.pricing}}</td>-->
        <td>{{chargeRecord.startingMediumPublicCode}}</td>
        <td>{{chargeRecord.startDate | date: 'short'}}</td>
        <td>{{(chargeRecord.stopDate || undefined) | date: 'short'}}</td>
        <td>{{chargeRecord.duration | fleetDuration}}</td>
        <td>{{(chargeRecord.energyConsumedInWh / 1000 || 0) | number:'1.0-2'}}</td>
        <td>
            {{chargeRecord.customerPriceWithVat || 0 | currency:'EUR': currencyUnit || ''}}
        </td>
    </tr>
    </tbody>
</table>
<ng-template #noCharge>
    <div class="cell">
        <div class="info cell auto common-block">{{'chargeRecordDtoStatus.empty' | translate}}</div>
    </div>
</ng-template>

