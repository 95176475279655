<section class="style-guide-block">
  <div class="row">
    <h1 class="small-24 columns style-guide-title">Blocks</h1>

    <div class="small-24 columns style-guide-section-content">
      <div class="row">
        <div class="small-24 column">
          <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>
              Pas de note
            </p>
          </div>

          <h4 class="style-guide-sub-title">Bases styles</h4>
          <!-- START / Callout  Message -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <div class="info cell auto common-block">
                  Curabitur blandit tempus porttitor.
                </div>

                <div class="info alert-info cell auto common-block">
                  Curabitur blandit tempus porttitor.
                </div>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html"><code iziviaPrism class="language-html">
               <div class="info cell auto common-block">
                  Curabitur blandit tempus porttitor.
                </div>
              </code></pre>

              <pre class="language-html"><code iziviaPrism class="language-html">
               <div class="info alert-info cell auto common-block">
                  Curabitur blandit tempus porttitor.
                </div>
               </code></pre>s
            </div>
          </div>
          <!-- END / Callout Message -->
        </div>
      </div>
    </div>
  </div>
</section>
