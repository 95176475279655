import {Component, Input, ViewChild, AfterViewInit, OnInit, Inject} from '@angular/core';
import {
    ChargeAreaDetailDto,
    FrontChargeAreaHttpService, FrontEndHttpService, HOST_THIRD_PARTY_ID,
    LocationEnvironmentType,
    OpeningDayDto,
    OpeningType
} from 'lib-front';

import {NgForm} from '@angular/forms';
import {debounceTime, filter} from 'rxjs/operators';
import {NotificationService} from '../../services/utils/notification.service';
import {find} from 'lodash-es';

@Component({
    selector: 'charge-area-edit',
    templateUrl: './charge-area-edit.component.html',
    styleUrls: ['./charge-area-edit.component.scss']
})
export class ChargeAreaEditComponent implements OnInit, AfterViewInit {
    @Input()
    get chargeArea(): ChargeAreaDetailDto {
        return this._chargeArea;
    }

    set chargeArea(value: ChargeAreaDetailDto) {
        if (!!value) {
            this._chargeArea = value;
            if (!!this._chargeArea.description) {
                this._chargeArea.description = this._chargeArea.description.replace(new RegExp('<br>', 'g'), '\n');
            }
            if (this._chargeArea.opening.open247) {
                this.toggleOpen247(this._chargeArea.opening.open247);
            }
        }
    }

    public LocationType = LocationEnvironmentType;

    public stationOpeningConfigurable: boolean;
    public hiddenMap: boolean;

    private _chargeArea: ChargeAreaDetailDto;
    @ViewChild('chargeAreaForm') ngForm: NgForm;


    constructor(private readonly chargeAreaHttpService: FrontChargeAreaHttpService,
        private notificationService: NotificationService,
        private readonly frontEndHttpService: FrontEndHttpService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string
    ) {
    }

    ngOnInit(): void {
        this.frontEndHttpService.findFrontInfo(this.thirdPartyId).subscribe(result => {
            this.stationOpeningConfigurable = result.fleetConfig?.stationOpeningConfigurable ?? true;
            this.hiddenMap = result.config.hiddenMap;
        });
    }

    ngAfterViewInit() {
        this.ngForm.form.valueChanges.pipe(
            filter(() => this.ngForm.valid && this.ngForm.dirty),
            debounceTime(1200)
        ).subscribe(() => this.updateChargeArea());

    }

    public updateChargeArea(): void {
        if (this.ngForm.valid && !!this.chargeArea) {
            this.chargeAreaHttpService.updateChargeArea(this._chargeArea)
                .subscribe(() => {
                    this.notificationService.success('config.saved');
                });
        }
    }

    toggleOpen247(open247: boolean): void {
        this._chargeArea.opening.open247 = open247;
        const openingType: OpeningType = this._chargeArea.opening.open247 ? OpeningType.OPEN_2424 : undefined;
        Object.values(this._chargeArea.opening.openingDays)
            .forEach((openingDay: OpeningDayDto) => {
                openingDay.open = this._chargeArea.opening.open247;
                openingDay.openingType = openingType;
            });
    }

    openingDaysChange(): void {
        this._chargeArea.opening.open247 = !find(this._chargeArea.opening.openingDays, (openingDay: OpeningDayDto) => {
            return !(openingDay.open && openingDay.openingType === OpeningType.OPEN_2424);
        });
    }
}
