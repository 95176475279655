import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PackTokenWrapper} from 'lib-front';

@Injectable({
    providedIn: 'root'
})
export class FleetTokenHttpService {
    constructor(private http: HttpClient) {
    }

    checkPackToken(packToken: string, mode?: string, email?: string): Observable<PackTokenWrapper> {
        let params = new HttpParams();
        params = params.set('used', mode);
        if (!!email) {
            params = params.set('email', email);
        }
        return this.http.get<PackTokenWrapper>(`/front/user/fleet/packTokens/${packToken}`,
            {params});
    }
}
