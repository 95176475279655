<div class="grid-y cell auto cell-block-y">
    <div class="register-header">
        <h2 class="cell shrink">{{'register.title' | translate}}</h2>
        <step-indicator class="cell shrink" [current]="5">
            <step class="text-uppercase cell shrink">{{'register.informations.title' | translate}}</step>
            <step class="text-uppercase cell shrink">{{'register.subscription.title' | translate}}</step>
            <step class="text-uppercase cell shrink">{{'register.fleet.title' | translate}}</step>
            <step class="text-uppercase cell shrink"
                  *ngIf="isPaymentStepActivated">{{'register.payment.title' | translate}}</step>
        </step-indicator>
    </div>
    <div class="register-block creation-success">
        <h3 class="grid-x align-middle cell secondary block-title">
            <i class="fas fa-check-square block-title-icon"></i>
            {{'register.success.title' | translate}}
        </h3>
        <div class="grid-x align-center align-middle">
            <div class="grid-y cell small-12 text-center creation-success-block">
                <p class="creation-success-message">{{'register.success.subtitle' | translate}}</p>
                <div class="grid-x cell align-spaced">
                    <a class="cell shrink button secondary color logout" (click)="logout()">
                        {{'register.success.logout' | translate}}
                    </a>
                    <a class="cell shrink button color next" routerLink="/home">{{'register.success.mySpace' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
