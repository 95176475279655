import {Component} from '@angular/core';
import {FrontPackSubscriptionHttpService, PackSubscription} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap, tap} from 'rxjs/operators';
import {getOptionsFromPackSubscription} from '../../../../utils/packSubscriptionUtils.service';
import {calculateProgressBarOptions, ProgressBarOptions} from '../../../../utils/progressBarUtils.service';

import * as moment from 'moment';

@Component({
    selector: 'home-subscription',
    templateUrl: './home-subscription.component.html',
    styleUrls: ['./home-subscription.component.scss'],
    host: {'class': 'cell small-auto medium-shrink large-auto home-card'}

})
export class HomeSubscriptionComponent {
    private readonly MAX_SUBSCRIPTIONS = 1;

    resetDate: moment.Moment;
    packSubscriptionsWithOptionBar: Array<PackSubscriptionWithOptionBar>;
    packSubscriptionsNumber: number;

    constructor(route: ActivatedRoute, packSubscriptionHttpService: FrontPackSubscriptionHttpService) {
        route.data.pipe(
            map(data => data.user.user),
            switchMap(user => packSubscriptionHttpService.findPackSubscription(user._id, false))
        ).subscribe(
            packSubscriptions => {
                this.packSubscriptionsNumber = Math.max(0, packSubscriptions.length - this.MAX_SUBSCRIPTIONS);
                if (packSubscriptions.length > this.MAX_SUBSCRIPTIONS) {
                    packSubscriptions = packSubscriptions.slice(0, this.MAX_SUBSCRIPTIONS);
                }

                this.packSubscriptionsWithOptionBar = packSubscriptions
                    .map(packSubscription => {
                        const options = getOptionsFromPackSubscription(packSubscription);
                        let progressBarOptions: ProgressBarOptions;

                        if (options && options.length) {
                            progressBarOptions = calculateProgressBarOptions(options, true, true);
                        }

                        const packSubscriptionWithOptionBar: PackSubscriptionWithOptionBar = {
                            packSubscription,
                            progressBarOptions
                        };

                        return packSubscriptionWithOptionBar;
                    });
            }
        );
        this.resetDate = moment().add(1, 'month').startOf('month');
    }

    trackBySubscriptionId(index: number, packSubscription: PackSubscriptionWithOptionBar) {
        return packSubscription?.packSubscription?._id ?? index;
    }
}

interface PackSubscriptionWithOptionBar {
    packSubscription: PackSubscription;
    progressBarOptions: ProgressBarOptions;
}
