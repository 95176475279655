<div [formGroup]="form" class="grid-x grid-margin-x align-top small-auto medium-auto large-auto">
    <div [ngClass]="{'small-4': showResponsibility || isRefundManager, 'small-6': !showResponsibility && !isRefundManager,
                         'small-5': !showResponsibility || !isRefundManager}"
         class="cell">
        <label class="required" [ngClass]="{validate : form.controls.name.valid, invalid : form.controls.name.invalid && form.controls.name?.dirty}">
                <input class="full-input" formControlName="name" type="text"
                       [placeholder]="'config.collaborator.name.title' | translate">
            <span class="error note"
                  [hidden]="!(form.controls.name.invalid && form.controls.name.dirty)">
                  {{'config.collaborator.name.required' | translate}}
            </span>
        </label>
    </div>

    <div [ngClass]="{'small-4': showResponsibility || isRefundManager, 'small-6': !showResponsibility && !isRefundManager,
                         'small-5': !showResponsibility || !isRefundManager}"

         class="cell">
        <label class="required" [ngClass]="{validate : form.controls.lastName.valid, invalid : form.controls.lastName.invalid && form.controls.lastName?.dirty}">
                <input class="full-input" formControlName="lastName" type="text"
                       [placeholder]="'config.collaborator.lastname.title' | translate">
            <span class="error note"
                  [hidden]="!(form.controls.lastName.invalid && form.controls.lastName.dirty)">
                        {{'config.collaborator.lastname.required' | translate}}</span>
        </label>
    </div>


    <div [ngClass]="{'small-4': showResponsibility || isRefundManager, 'small-6': !showResponsibility && !isRefundManager,
                        'small-5': !showResponsibility || !isRefundManager}"
         class="cell">
        <label [ngClass]="{required : mode === Mode.CREATE, validate : form.controls.email.valid, pending : form.controls.email.pending, invalid : form.controls.email.invalid && form.controls.email?.dirty}">
                    <input class="full-input" formControlName="email" type="text"
                           [placeholder]="'config.collaborator.email.title' | translate">
            <div
                [hidden]="!(form.controls.email.invalid && (form.controls.email.touched || form.controls.email.dirty))">
                    <div class="error note"
                          [hidden]="!form.controls.email.errors">
                                {{'config.collaborator.email.error' | translate}}
                    </div>
            </div>
        </label>
    </div>

    <div [ngClass]="{'small-4': showResponsibility || isRefundManager, 'small-6': !showResponsibility && !isRefundManager,
                         'small-5': !showResponsibility || !isRefundManager}"
         class="cell">
        <label *ngIf="mode === Mode.CREATE; else updateMode">
            <ng-container *ngIf="!mediaSearchLimitReached; else tooManyMedia">
                <ng-container *ngIf="mediasCanActivateObs | async; else noActivateMedia; let mediasCanActivate">
                    <ng-container *ngIf="mediasCanActivate.length; else noActivateMedia;">
                        <select formControlName="media">
                            <option value="">{{'config.collaborator.media.title' | translate}}</option>
                            <option [ngValue]="media"
                                    *ngFor="let media of mediasCanActivate; trackBy: trackByMediaId">
                                {{createLabelMedia(media)}}
                            </option>
                        </select>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #noActivateMedia>
                <p class="input-read-only">{{'config.collaborator.no.media.to.activate' | translate}}</p>
            </ng-template>
            <ng-template #tooManyMedia>
                <p class="input-read-only">{{'config.collaborator.tooManyMedia' | translate}}</p>
            </ng-template>
        </label>
    </div>

    <div *ngIf="isRefundManager"
         class="cell small-4">
        <label *ngIf="isRefundCollaborator"
               class="cell checkbox">
            <input type="checkbox"
                   formControlName="withSupervisedStation">
            <span class="custom-checkbox"></span>
        </label>
    </div>

    <div *ngIf="showResponsibility"
         class="cell small-4">
        <label>
            <select formControlName="responsibility">
                <option [ngValue]="responsibility"
                        *ngFor="let responsibility of responsibilities">
                    {{'config.collaborator.responsibility.' + responsibility | translate}}
                </option>
            </select>
        </label>
    </div>

    <ng-template #updateMode>
        <div *ngIf="medias; let mediasOwned">
            <label>
                <ng-container *ngIf="mediasOwned.length; else noMedia">
                    <p class="input-read-only">
                        <a [routerLink]="'/main/media'"
                           [queryParams]="{fullName: form.get('name').value +' '+form.get('lastName').value}">
                            <ng-container *ngIf="mediasOwned.length === 1">
                                {{mediasOwned[0].code}}
                            </ng-container>
                            <ng-container *ngIf="mediasOwned.length > 1">
                                {{'config.collaborator.media.owned' | translate: {numberPass: mediasOwned.length} }}
                            </ng-container>
                        </a>
                    </p>
                </ng-container>
                <ng-template #noMedia>
                    <p class="input-read-only">{{'config.collaborator.media.not.owned' | translate}}</p>
                </ng-template>
            </label>
        </div>
    </ng-template>
</div>
