import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CalendarView, CalendarWeekViewBeforeRenderEvent} from 'angular-calendar';
import {ActivatedRoute, ParamMap} from '@angular/router';
import moment from 'moment-timezone';
import {Estimate, FrontVehiclePlanningService, SocEventType, SocCalendarEvent, FLEET_CALENDAR_EVENT_DATE_FORMAT} from 'lib-front';
import {Subject, zip} from 'rxjs';
import { CalendarViewPeriod } from 'angular-calendar';
import { CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import {FleetCalendarOneTimeEventTitle} from '../../pipes/fleetCalendarOneTimeEventTitle';
import RRule from 'rrule';
import {SocUtils} from '../socUtils';
import {FleetCalendarRecurringEventTitle} from '../../pipes/fleetCalendarRecurringEventTitle';
import {delay} from 'rxjs/operators';

@Component({
    selector: 'fleet-calendar',
    templateUrl: './fleet-calendar.component.html',
    styleUrls: ['./fleet-calendar.component.scss'],
    host: {'class': 'tabs-content main-container cell auto'},
})
export class FleetCalendarComponent implements OnInit {
    @ViewChild('addProgramPopup') addProgramPopup;
    @ViewChild('monthTab') monthTab;
    @ViewChild('weekTab') weekTab;
    @ViewChild('dayTab') dayTab;

    vehicleId: string;
    vehicleLicenseNumber: string;
    vehicleTimeZone: string;

    view: CalendarView = CalendarView.Month;
    viewDate: Date = new Date();
    viewPeriod: CalendarViewPeriod;
    events: SocCalendarEvent[] = [];
    existingEventsDates: string[] = [];
    refresh: Subject<void> = new Subject();
    needToRefresh: boolean = false;

    socEventType: SocEventType;
    applicationDay: moment.Moment;
    recurringFrom: moment.Moment;
    recurringTo: moment.Moment;
    applicationTime: moment.Moment;
    estimates: Estimate[];
    desiredChargeIndex: number = 0;
    daysOfWeek: string[] = [];
    chargingProgramId: string;
    chargingProgramDate: moment.Moment;

    constructor(private route: ActivatedRoute,
        private readonly vehiclePlanningService: FrontVehiclePlanningService,
        private readonly fleetCalendarOneTimeEventTitle: FleetCalendarOneTimeEventTitle,
        private readonly fleetCalendarRecurringEventTitle: FleetCalendarRecurringEventTitle,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(FLEET_CALENDAR_EVENT_DATE_FORMAT) public readonly fleetCalendarEventDateFormat: string) { }

    ngOnInit(): void {
        zip(this.route.paramMap, this.route.queryParamMap).subscribe(
            ([params, queryParams]) => {
                this.vehicleId = params.get('vehicleId');
                this.vehicleTimeZone = queryParams.get('timezone');
                this.vehicleLicenseNumber = queryParams.get('licenseNumber');

                const from = moment().tz(this.vehicleTimeZone).startOf('month').subtract(1, 'month').toISOString();
                const to = moment().tz(this.vehicleTimeZone).startOf('month').add(1, 'month').toISOString();
                this.initializeVehicleSocRangeEstimates();
                this.needToRefresh = true;
                this.refresh.next();
            }
        );
    }

    private initializeVehicleSocRangeEstimates(): void {
        this.vehiclePlanningService.fetchVehiclesChargeDistance(this.vehicleId).subscribe((socRangeEstimate) => {
            this.estimates = socRangeEstimate.estimates;
            this.estimates.forEach((charge, index) => {
                if (charge.soc === 50) {
                    this.desiredChargeIndex = index;
                }
            });
        });
    }

    private initializeOneTimeEvents(from: string, to: string): void {
        this.vehiclePlanningService.fetchVehiclesChargePlanningOneTime(this.vehicleId, from, to)
            .pipe(delay(0))
            .subscribe((socResponseOneTime) => {
                socResponseOneTime.requirements.forEach((socResponseOneTimeRequirements) => {
                    const start = moment.tz(socResponseOneTimeRequirements.period.from, this.vehicleTimeZone);
                    this.events.push({
                        id: socResponseOneTimeRequirements.id,
                        socEventType: SocEventType.ONE_TIME,
                        soc: socResponseOneTimeRequirements.soc,
                        start: start.toDate(),
                        title: this.fleetCalendarOneTimeEventTitle.transform(start, socResponseOneTimeRequirements.soc)
                    });
                    this.existingEventsDates.push(start.format(this.fleetCalendarEventDateFormat));
                });
                this.refresh.next();
            });
    }

    private initializeRecurringEvents(from: string, to: string): void {
        this.vehiclePlanningService.fetchVehiclesChargePlanningRecurring(this.vehicleId, from, to)
            .pipe(delay(0))
            .subscribe((socResponseRecurring) => {
                socResponseRecurring.requirements.forEach((socResponseRecurringRequirements) => {
                    const rule: RRule = new RRule(SocUtils.getRecurringRuleOptions(socResponseRecurringRequirements, this.vehicleTimeZone));
                    rule.all().forEach((date) => {
                        const periodFrom: string = socResponseRecurringRequirements.period.from;
                        const applicationDate: moment.Moment = SocUtils.createDateFromApplicationDayAndSocTime(
                            moment.tz(date, this.vehicleTimeZone),
                            periodFrom,
                            this.vehicleTimeZone);

                        this.events.push({
                            id: socResponseRecurringRequirements.id,
                            socEventType: SocEventType.RECURRING,
                            soc: socResponseRecurringRequirements.soc,
                            socTime: socResponseRecurringRequirements.period.from,
                            recurringFrom: moment.tz(socResponseRecurringRequirements.recurrence.from, this.vehicleTimeZone),
                            recurringTo: moment.tz(socResponseRecurringRequirements.recurrence.to, this.vehicleTimeZone),
                            daysOfWeek: socResponseRecurringRequirements.daysOfWeek,
                            // periodFrom needs to be in format HH:mm instead of HH:mm:ss
                            start: applicationDate.toDate(),
                            title: this.fleetCalendarRecurringEventTitle.transform(periodFrom, socResponseRecurringRequirements.soc)
                        });
                        this.existingEventsDates.push(applicationDate.format(this.fleetCalendarEventDateFormat));
                    });
                });
                this.refresh.next();
            });
    }

    public setView(): void {
        if (this.monthTab.selected) {
            this.view = CalendarView.Month;
        } else if (this.weekTab.selected) {
            this.view = CalendarView.Week;
        } else if (this.dayTab.selected) {
            this.view = CalendarView.Day;
        }
    }

    public beforeMonthViewRender(event: CalendarMonthViewBeforeRenderEvent): void {
        if (this.view === CalendarView.Month) {
            this.loadProgram(event);
        }
    }

    public beforeWeekViewRender(event: CalendarWeekViewBeforeRenderEvent): void {
        if (this.view === CalendarView.Week) {
            this.loadProgram(event);
        }
    }

    public beforeDayViewRender(event: CalendarWeekViewBeforeRenderEvent): void {
        if (this.view === CalendarView.Day) {
            this.loadProgram(event);
        }
    }

    private loadProgram(event: CalendarMonthViewBeforeRenderEvent | CalendarWeekViewBeforeRenderEvent): void {
        if (!this.viewPeriod || this.needToRefresh ||
                !moment(this.viewPeriod.start).isSame(event.period.start) ||
                !moment(this.viewPeriod.end).isSame(event.period.end)) {
            this.viewPeriod = event.period;
            this.events = [];
            if (!this.needToRefresh) {
                this.changeDetectorRef.detectChanges();
            }
            this.existingEventsDates = [];
            this.needToRefresh = false;

            const from = moment.tz(event.period.start, this.vehicleTimeZone).toISOString();
            const to = moment.tz(event.period.end, this.vehicleTimeZone).toISOString();
            this.initializeOneTimeEvents(from, to);
            this.initializeRecurringEvents(from, to);
        }
    }

    public addNewProgram(event): void {
        this.chargingProgramId = null;
        this.socEventType = SocEventType.ONE_TIME;

        // according to the view (month, week, day), event can be launched by a click on a day or an hour segment
        let selectedDate;
        if (event.day) {
            selectedDate = moment.tz(event.day.date, this.vehicleTimeZone);
            this.applicationTime = selectedDate
                .add(moment().tz(this.vehicleTimeZone).hour(), 'hour')
                .add(moment().tz(this.vehicleTimeZone).minute(), 'minute');
        } else {
            selectedDate = moment.tz(event.date, this.vehicleTimeZone);
            this.applicationTime = selectedDate;
        }

        if (this.isDateBeforeToday(selectedDate)) {
            return;
        }

        this.applicationDay = selectedDate;

        // for recurring events
        this.daysOfWeek = [];
        this.recurringFrom = selectedDate;
        this.recurringTo = selectedDate.clone().add(1, 'months');

        this.addProgramPopup.open();
    }

    private isDateBeforeToday(date: moment.Moment): boolean {
        return date.isBefore(moment().tz(this.vehicleTimeZone).subtract(1, 'days'));
    }

    public updateCalendar(): void {
        this.needToRefresh = true;
        this.refresh.next();
    }

    public openExistingProgram(program): void {
        this.chargingProgramId = program.event.id;
        const chargingProgramStartDate = moment.tz(program.event.start, this.vehicleTimeZone);
        this.chargingProgramDate = moment.tz(program.event.start, this.vehicleTimeZone);
        this.socEventType = program.event.socEventType;
        this.desiredChargeIndex = this.estimates.map((estimate) => estimate.soc).indexOf(program.event.soc);

        if (program.event.socEventType === SocEventType.ONE_TIME) {
            this.applicationDay = chargingProgramStartDate;
            this.applicationTime = chargingProgramStartDate;
        } else {
            this.recurringFrom = program.event.recurringFrom;
            this.recurringTo = program.event.recurringTo;
            this.applicationTime = chargingProgramStartDate;
            this.daysOfWeek = program.event.daysOfWeek;
        }

        this.addProgramPopup.open();
    }
}
