import {Pipe, PipeTransform} from '@angular/core';
import {VehicleModelDto} from 'lib-front';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'fleetVehicleModelFormatter'
})
export class FleetVehicleModelFormatterPipe implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {
    }

    transform(vehicleModel: VehicleModelDto): string {
        return vehicleModel.name;
    }
}

