import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {MediaActivateForm} from '../media-activate.component';
import {FrontMediaHttpService, IdLabel, MediaActivateInfoDto, MediaFamily, User, Vehicle} from 'lib-front';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {reduceFormGroupErrors} from '../../../../../../utils/formGroupUtils.service';
import {NotificationService} from '../../../../../../services/utils/notification.service';
import {chain} from '../../../../../../../libs/lodash-es/chain';

@Component({
    selector: 'media-activate-form',
    templateUrl: './media-activate-form.component.html',
    styleUrls: ['./media-activate-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MediaActivateFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MediaActivateFormComponent),
            multi: true
        }]
})
export class MediaActivateFormComponent implements ControlValueAccessor, Validator, OnInit {
    @Input('subscribers') set subscriberInput(subscribers: User[]) {
        this.subscribers = subscribers;
        if (this.subscribers && subscribers.length) {
            this.mediaForm.get('colaborator').patchValue(this.subscribers[0]._id);
        }
    }
    @Input() vehicles: Vehicle[];
    @Input() userId: string;
    @Input() logoRef: string;
    @Input() showValidateError: boolean;
    @Input() last: boolean;
    @Output() validateOutput: EventEmitter<boolean> = new EventEmitter();

    subscribers: User[];
    mediaFamilies: Array<IdLabel> = [];
    availableSubscriptionPacks: Array<IdLabel> = [];

    availableMediaFamilies: MediaFamily[] = [];

    mediasCanBeActivate: Array<MediaActivateInfoDto> = [];

    mediaForm: UntypedFormGroup;

    onChangeCallback = (mediaActivateForm: MediaActivateForm) => {};
    onTouchedCallback = () => {};

    get enteringCodeForAutoGeneratedMediaFamily(): boolean {
        return this._enteringCodeForAutoGeneratedMediaFamily;
    }

    set enteringCodeForAutoGeneratedMediaFamily(value: boolean) {
        if (value) {
            this.mediaForm.get('validationCode').setValue(null);
            this.mediaForm.get('validationCode').disable();
            this.mediaForm.get('validationCode').setValidators([]);
        } else {
            this.mediaForm.get('validationCode').enable();
            this.mediaForm.get('validationCode').setValidators([Validators.required]);
        }
        this._enteringCodeForAutoGeneratedMediaFamily = value;
    }

    private _enteringCodeForAutoGeneratedMediaFamily: boolean;

    constructor(private mediaHttpService: FrontMediaHttpService,
        private readonly notificationService: NotificationService,
        fb: UntypedFormBuilder) {
        this.mediaHttpService = mediaHttpService;
        this.mediaForm = fb.group(
            {
                validationCode: [null, Validators.required],
                code: [null, Validators.required],
                colaborator: [null, Validators.required],
                vehicle: [''],
                mediaFamily: [null, Validators.required],
                subscriptionPacks: [null]
            });

        this.mediaForm.valueChanges.subscribe(value => {
            this.onChangeCallback(value);
        });

        this.mediaForm.get('code').valueChanges
            .pipe(
                debounceTime(500)
            )
            .subscribe(code => {
                const validationCode: string = this.mediaForm.get('validationCode').value;
                if (validationCode && !this.enteringCodeForAutoGeneratedMediaFamily) {
                    this.fetchMediaByCodeAndValidationCode(code, validationCode);
                }

                if (this.enteringCodeForAutoGeneratedMediaFamily) {
                    this.fetchAutoGeneratedMediaByCodeAndMediaFamily(code, this.mediaForm.get('mediaFamily').value);
                }

            });

        this.mediaForm.get('validationCode').valueChanges
            .pipe(
                debounceTime(500)
            )
            .subscribe(validationCode => {
                const code: string = this.mediaForm.get('code').value;
                if (code) {
                    this.fetchMediaByCodeAndValidationCode(code, validationCode);
                } else {
                    this.fetchMediaByValidationCodeInOrdered(validationCode);
                }
            });

        this.mediaForm.get('mediaFamily').valueChanges
            .pipe(
                debounceTime(100)
            )
            .subscribe(mediaFamily => this.updateFormForMediaFamily(mediaFamily));

        this.logoRef = '';
    }

    private updateFormForMediaFamily(mediaFamily) {
        let availableMediaFamily = mediaFamily && this.availableMediaFamilies?.find(family => family._id === mediaFamily);
        if (mediaFamily && availableMediaFamily?.mediaGenerationConfig && availableMediaFamily?.mediaGenerationConfig.enabled) {
            this.enteringCodeForAutoGeneratedMediaFamily = true;
        } else {
            this.enteringCodeForAutoGeneratedMediaFamily = false;
        }

        if (mediaFamily == null) {
            this.mediaForm.get('subscriptionPacks').setValue(null);
        } else {
            const mediaActivateInfoDto = this.mediasCanBeActivate?.find(media => mediaFamily === media.family.id);

            if (mediaActivateInfoDto) {
                this.updateSubscriptionPacksFromMediaInfo(mediaActivateInfoDto);
            }
        }
    }

    private updateSubscriptionPacksFromMediaInfo(mediaActivateInfoDto: MediaActivateInfoDto) {
        this.availableSubscriptionPacks = mediaActivateInfoDto.acceptedSubscriptionPacks;

        if (mediaActivateInfoDto.acceptedSubscriptionPacks.length === 1) {
            const acceptedSubscriptionPackRefs = mediaActivateInfoDto.acceptedSubscriptionPacks
                .map(acceptedSubscriptionPacks => acceptedSubscriptionPacks.id);

            this.mediaForm.get('subscriptionPacks').setValue(acceptedSubscriptionPackRefs);
        } else {
            if (mediaActivateInfoDto.acceptedSubscriptionPacks.length === 0) {
                this.notificationService.warning('media.activate.noAcceptedSubscriptionPacks');
                this.mediaForm.get('subscriptionPacks').setValue(null);
            } else {
                let acceptedSubscriptionPackWithNoLimitRefs = null;
                if (mediaActivateInfoDto.subscriptionPacksWithNoLimit.length > 0) {
                    acceptedSubscriptionPackWithNoLimitRefs = mediaActivateInfoDto.subscriptionPacksWithNoLimit
                        .map(subscriptionPack => subscriptionPack.id);
                }

                this.mediaForm.get('subscriptionPacks').setValue(acceptedSubscriptionPackWithNoLimitRefs);
            }
        }
    }

    ngOnInit(): void {
        this.mediaHttpService.findActivatableMediaFamilies().subscribe(families => {
            this.availableMediaFamilies = families;
            this.setCurrentMediaFamilyFromAvailable(families);
        });
    }

    private setCurrentMediaFamilyFromAvailable(families: MediaFamily[]) {
        if (!families) {
            return;
        }

        let proFamily = families.find(family => family.name === 'Pass Izivia Pro');
        if (proFamily){
            this.mediaForm.get('mediaFamily').setValue(proFamily._id);
        }else{
            this.mediaForm.get('mediaFamily').setValue(families[0]._id);
        }

        this.updateFormForMediaFamily(this.mediaForm.get('mediaFamily').value);
    }

    public validatePass() {
        const mediaFormSubscriptionPacks = (this.mediaForm.get('subscriptionPacks').value) ?
            (this.mediaForm.get('subscriptionPacks').value) : [];

        this.mediaForm.get('subscriptionPacks').setValue(mediaFormSubscriptionPacks);

        this.validateOutput.emit(false);

        this.mediaForm.get('subscriptionPacks').setValue(mediaFormSubscriptionPacks);
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    writeValue(obj: any): void {
        this.mediaForm.patchValue(obj || {});
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.mediaForm.invalid ? {mediaError: reduceFormGroupErrors(this.mediaForm)} : null;
    }

    private fetchMediaByValidationCodeInOrdered(validationCode: string): void {
        if (!this.mediaForm.disabled) {
            if (this.isValidationCodeHasValidLength(validationCode)) {
                this.mediaHttpService.findByValidationCodeInOrderedMedia(validationCode)
                    .subscribe((medias: Array<MediaActivateInfoDto>) => {
                        if (medias.length) {
                            this.updateFormByMediaActivateInfoDto(medias);
                        } else {
                            this.updateFormByMediaActivateInfoDto();
                        }
                    });
            }
        }
    }

    private fetchMediaByCodeAndValidationCode(code: string, validationCode: string): void {
        if (!this.mediaForm.disabled) {
            if (this.isValidationCodeHasValidLength(validationCode)) {
                this.mediaHttpService.findAllUnexpiredByPublicCodeAndValidationCode(code, validationCode)
                    .subscribe((medias: Array<MediaActivateInfoDto>) => {
                        if (medias.length) {
                            this.updateFormByMediaActivateInfoDto(medias);
                        } else {
                            this.updateFormByMediaActivateInfoDto();
                        }
                    });
            }
        }
    }

    private fetchAutoGeneratedMediaByCodeAndMediaFamily(code: string, mediaFamilyRef: string): void {
        if (!this.mediaForm.disabled) {
            this.mediaHttpService.getAutoGeneratedMediaByCodeAndMediaFamily(code, mediaFamilyRef)
                .subscribe((medias: Array<MediaActivateInfoDto>) => {
                    if (medias.length) {
                        this.updateFormByMediaActivateInfoDto(medias);
                    }
                });
        }
    }


    private updateFormByMediaActivateInfoDto(medias?: Array<MediaActivateInfoDto>) {
        this.mediasCanBeActivate = medias;
        const subscriptionPacksFormControl = this.mediaForm.get('subscriptionPacks');

        if (!medias || !medias.length) {

            subscriptionPacksFormControl.setValue(null);
        } else {
            this.mediaFamilies = chain(medias)
                .map(media => media.family)
                .flatten()
                .value();

            if (medias.length === 1) {
                const mediaActivateInfoDto = medias[0];
                if (!this.mediaForm.get('code').value) {
                    this.mediaForm.get('code').setValue(mediaActivateInfoDto.code);
                }

            }

            if (medias.length) {
                this.updateSubscriptionPacksFromMediaInfo(medias[0]);
            }
        }
    }

    private isValidationCodeHasValidLength(validationCode: string) {
        return validationCode?.length > 3;
    }

    trackByUserId(index: number, user: User) {
        return user?._id ?? index;
    }
}
