import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConnectedUser, FrontSessionBusinessService} from 'lib-front';
import {filter, map, tap} from 'rxjs/operators';

@Injectable()
export class SuspendedAccountGuard implements CanActivate, CanActivateChild {

    constructor(private readonly router: Router,
        private readonly sessionBusinessService: FrontSessionBusinessService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkSuspendedAccount()
            .pipe(map(suspended => !suspended));
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkSuspendedAccount().pipe()
            .pipe(map(suspended => !suspended));
    }

    private checkSuspendedAccount(): Observable<boolean> {
        return this.sessionBusinessService.connectedUser$.pipe(
            filter(user => !!user),
            map((user: ConnectedUser) => ConnectedUser.isSuspended(user)),
            tap(isSuspended => {
                if (isSuspended) {
                    this.router.navigate(['/home']);
                }
            })
        );
    }
}

