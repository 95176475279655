import {Component, Input, OnInit} from '@angular/core';
import {OptionSubscription} from 'lib-front';

@Component({
    selector: 'subscription-options-result-view',
    templateUrl: './subscription-options-result-view.component.html',
    styleUrls: ['./subscription-options-result-view.component.scss'],
})
export class SubscriptionOptionsResultViewComponent {

    @Input() set options(options) {
        this._options = options;
    }

    @Input() set totalHours(totalHours) {
        this._totalHours = totalHours;
    }

    @Input() set totalPrice(totalPrice) {
        this._totalPrice = totalPrice;
    }

    _totalHours: number;
    _totalPrice: number;
    _options: Array<OptionSubscription>;

    constructor() {
    }
}
