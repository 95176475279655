import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {
    buildRole,
    CurrentSubscriberRolesService,
    FrontEndFleetConfig,
    FrontFoAccountHttpService,
    FrontPersonHttpService,
    FrontPersonPortalContextService,
    FrontSessionBusinessService,
    HelpfulDocumentHttpService,
    IziviaLocationService,
    PortalSupportConfig,
    RGPDWorkflowHttpService,
    RGPDWorkflowSubscriberAcceptanceStatus,
    SubscriberRoleLevel,
    SubscriberRoleType,
    SupportContact
} from 'lib-front';
import {defaultIfEmpty, filter, map, switchMap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {forkJoin, Subscription} from 'rxjs';
import {TabsDirection} from '../../../../components/tabs/tabs-direction';

@Component({
    selector: 'configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ConfigurationComponent implements OnInit, OnDestroy {
    TabsDirection = TabsDirection;

    public tabActivate: number = 0;
    public fleetConfig: FrontEndFleetConfig;
    public supportContacts: SupportContact[] = [];
    public fleetSupportConfig: PortalSupportConfig;
    public isStationManager: boolean;
    public isInfraManagementTariffCreationEnabled: boolean;
    public faqVisible: boolean = false;
    public helpfulDocumentsVisible: boolean = false;
    public isRefundManager: boolean = false;
    public needToAcceptRGPDWorkflow: boolean = false;
    public indemnityTabSelected: boolean;

    public hasManagerInfosReadRole: boolean;
    public hasFleetReadRole: boolean;
    public hasCollaboratorsReadRole: boolean;
    public hasSubscriberRolesReadRole: boolean;
    public hasNotificationsReadRole: boolean;
    public hasTariffsReadRole: boolean;
    public hasIndemnityReadRole: boolean;
    public hasSupportsReadRole: boolean;
    public hasFaqReadRole: boolean;
    public hasHelpfulDocumentsReadRole: boolean;

    public isRigeUser: boolean = false;
    public permissionFilter: string = undefined;

    private locationServiceSubscription: Subscription;

    constructor(private readonly router: Router,
        private readonly locationService: IziviaLocationService,
        private readonly location: Location,
        private route: ActivatedRoute,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly personHttpService: FrontPersonHttpService,
        private readonly frontEndService: FrontEndService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly frontFoAccountService: FrontFoAccountHttpService,
        private readonly rgpdWorkflowService: RGPDWorkflowHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly helpfulDocumentHttpService: HelpfulDocumentHttpService) {
        this.personPortalContextService.isStationManager()
            .pipe(
                map(isStationManager => this.isStationManager = isStationManager),
                switchMap(() => route.url)
            )
            .subscribe(param => {
                if (param && param[0] && param[0].path) {
                    switch (param[0].path) {
                        case 'fleet':
                            this.tabActivate = 1;
                            break;
                        case 'collaborators':
                            this.tabActivate = 2;
                            break;
                        case 'permission':
                            this.tabActivate = 3;
                            break;
                        case 'notifications':
                            this.tabActivate = 4;
                            break;
                        case 'tariff':
                            this.tabActivate = this.isStationManager ? 5 : 0;
                            break;
                        case 'indemnity':
                            this.tabActivate = 6;
                            break;
                        case 'support':
                            this.tabActivate = 7;
                            break;
                        case 'faq':
                            this.tabActivate = 8;
                            break;
                        case 'helpfulDocuments':
                            this.tabActivate = 9;
                            break;
                        case 'smart-charging':
                            this.tabActivate = 10;
                            break;
                        default:
                            this.tabActivate = 0;
                            break;
                    }
                }
            });
    }

    ngOnInit(): void {
        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => {
                this.fleetConfig = frontEndInfo.fleetConfig;
                this.supportContacts = frontEndInfo.config.supportConfig.supportContacts;
                this.fleetSupportConfig = frontEndInfo.config.supportConfig.fleetSupportConfig;
                this.faqVisible = frontEndInfo.config.availableFaq;
            });

        this.personPortalContextService.isRefundManager().subscribe(isRefundManager => this.isRefundManager = isRefundManager);
        this.personPortalContextService.isInfraManagementTariffCreationEnabled()
            .subscribe(isInfraManagementTariffCreationEnabled => this.isInfraManagementTariffCreationEnabled = isInfraManagementTariffCreationEnabled);
        this.personPortalContextService.isRigeUser().subscribe(isRigeUser => this.isRigeUser = isRigeUser);


        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.indemnityTabSelected = event.url.includes('/main/configuration/indemnity');
            }
        });

        this.locationServiceSubscription = this.locationService.urlChanged.subscribe(event => {
            this.indemnityTabSelected = event.url.includes('/main/configuration/indemnity');
        });

        this.helpfulDocumentHttpService.hasDocuments()
            .subscribe(hasDocumentResponse => this.helpfulDocumentsVisible = hasDocumentResponse.hasVisibleDocuments);

        this.computeNeedToAcceptRGPDWorkflow();
        this.computeRoleAuthorizations();
    }

    public computeNeedToAcceptRGPDWorkflow() {
        this.frontFoAccountService.findFOAccount().pipe(
            filter(foAccount => !!foAccount.splitbillingRGPDWorkflowRef),
            switchMap(foAccount => this.rgpdWorkflowService.getSubscriberAcceptanceByWorkflowId(foAccount.splitbillingRGPDWorkflowRef)),
            map(subscriberAcceptance => {
                if (!subscriberAcceptance || subscriberAcceptance.subscriberAcceptanceStatus === RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTED) {
                    return null;
                } else if (subscriberAcceptance.subscriberAcceptanceStatus === RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTANCE_REQUIRED) {
                    return subscriberAcceptance.workflowRef;
                }
            }),
            defaultIfEmpty(null)
        ).subscribe(workflowRef => {
            if (!!workflowRef) {
                this.router.navigate(['.'], {
                    relativeTo: this.route,
                    queryParams: {'workflowRef': workflowRef},
                    queryParamsHandling: 'merge',
                    skipLocationChange: true
                }).then(_ => {
                    this.needToAcceptRGPDWorkflow = true;
                });
            } else {
                this.needToAcceptRGPDWorkflow = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.locationServiceSubscription.unsubscribe();
    }

    private computeRoleAuthorizations() {
        forkJoin({
            hasManagerInfosReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.MANAGER_INFOS, SubscriberRoleLevel.READ)
            ),
            hasFleetReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.VEHICLES, SubscriberRoleLevel.READ)
            ),
            hasSubscriberRolesReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.SUBSCRIBER_ROLES, SubscriberRoleLevel.READ)
            ),
            hasCollaboratorsReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.COLLABORATORS, SubscriberRoleLevel.READ)
            ),
            hasNotificationsReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.NOTIFICATIONS, SubscriberRoleLevel.READ)
            ),
            hasTariffsReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.TARIFFS, SubscriberRoleLevel.READ)
            ),
            hasIndemnityReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.INDEMNITY, SubscriberRoleLevel.READ)
            ),
            hasSupportsReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.SUPPORTS, SubscriberRoleLevel.READ)
            ),
            hasFaqReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.FAQ, SubscriberRoleLevel.READ)
            ),
            hasHelpfulDocumentsReadRole: this.currentSubscriberRolesService.hasRole(
                buildRole(SubscriberRoleType.HELPFUL_DOCUMENTS, SubscriberRoleLevel.READ)
            )
        }).subscribe((roles) => {
            this.hasManagerInfosReadRole = roles.hasManagerInfosReadRole;
            this.hasFleetReadRole = roles.hasFleetReadRole;
            this.hasSubscriberRolesReadRole = roles.hasSubscriberRolesReadRole;
            this.hasCollaboratorsReadRole = roles.hasCollaboratorsReadRole;
            this.hasNotificationsReadRole = roles.hasNotificationsReadRole;
            this.hasTariffsReadRole = roles.hasTariffsReadRole;
            this.hasIndemnityReadRole = roles.hasIndemnityReadRole;
            this.hasSupportsReadRole = roles.hasSupportsReadRole;
            this.hasFaqReadRole = roles.hasFaqReadRole;
            this.hasHelpfulDocumentsReadRole = roles.hasHelpfulDocumentsReadRole;
        });
    }

    public changeTabToPermissionWithFilter(permissionFilter: string): void {
        this.permissionFilter = permissionFilter;
        this.tabActivate = 3;
    }
}
