import {Component, forwardRef} from '@angular/core';
import {OpeningDto} from 'lib-front';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'opening-day-list-selector',
    templateUrl: './opening-day-list-selector.component.html',
    styleUrls: ['./opening-day-list-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OpeningDayListSelectorComponent),
            multi: true
        }
    ]
})
export class OpeningDayListSelectorComponent implements ControlValueAccessor {
    private onTouch: () => void;
    public opening: OpeningDto;
    public disabled: boolean;
    public onChange: (opening: OpeningDto) => void;
    readonly sortedDays = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
    ];

    constructor() {
    }

    writeValue(obj: OpeningDto): void {
        if (!!obj) {
            this.opening = obj;
        }
    }
    registerOnChange(fn: (opening: OpeningDto) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    openingDayChange() {
        this.onChange(this.opening);
    }
}
