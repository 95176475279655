<div class="notificationBg">
    <div *ngFor="let config of _notificationsConfig  | keyvalue; trackBy: trackByKey"
         class="grid-x notificationWrapper">
        <label>
            <input type="checkbox" name="collaboratorsDeclarePass" [(ngModel)]="_notificationsConfig[config.key]"
                   (ngModelChange)="save()">
            <span class="custom-checkbox"></span>
            <span class="label-choice checkbox-margin">{{'config.notification.' + config.key | translate}}</span>
        </label>
    </div>
</div>

