import {Pipe, PipeTransform} from '@angular/core';
import {MediaItem} from 'lib-front';

@Pipe({
    name: 'fleetMediaItemLabel'
})
export class FleetMediaItemLabelPipe implements PipeTransform {

    constructor() {}

    transform(media: MediaItem): string {
        return !!media.alias ? media.alias + ' : ' + media.code : media.code;
    }
}

