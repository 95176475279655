<div>
    <div class="popup-icon">
        <i class="fas fa-battery-three-quarters popup-icon_i"></i>
    </div>
    <h2 *ngIf="!chargingProgramId" class="popup-title">
        {{'fleet.program.new' | translate}}
    </h2>
    <h2 *ngIf="chargingProgramId" class="popup-title">
        {{'fleet.program.existing' | translate : {programDate: chargingProgram.date, programTime: chargingProgram.time} }}
    </h2>
    <hr>
    <div class="popup-content">
        <fieldset class="soc-event-type-fieldset" [disabled]="chargingProgramId">
            <h5>{{'fleet.program.event.type' | translate}} :</h5>
            <div class="grid-x">
                <label class="radio">
                    <span class="label-choice no-marginLeft">  {{'fleet.program.event.oneTime' | translate}}</span>
                    <input type="radio"
                           name="socEventType"
                           [(ngModel)]="socEventType"
                           [value]="'ONE_TIME'">
                    <span class="custom-radio"></span>
                </label>
                <label class="radio">
                    <span class="label-choice">{{'fleet.program.event.recurring' | translate}}</span>
                    <input type="radio"
                           name="socEventType"
                           [(ngModel)]="socEventType"
                           [value]="'RECURRING'">
                    <span class="custom-radio"></span>
                </label>
            </div>
        </fieldset>

        <div *ngIf="socEventType === SocEventType.ONE_TIME">
            <h5>{{'fleet.program.application.day' | translate}} :</h5>
            <date-picker name="applicationDay"
                         [(ngModel)]="applicationDay"
                         [minDate]="minSelectableDate"
                         [disabled]="passedEvent">
            </date-picker>
        </div>

        <div *ngIf="socEventType === SocEventType.RECURRING">
            <div class="grid-x grid-margin-x align-middle popup-content_date">
                <div
                        class="cell shrink popup-content_date-label">{{'fleet.program.application.recurringFrom' | translate}}</div>
                <date-picker class="cell shrink"
                             name="recurringFrom"
                             [(ngModel)]="recurringFrom"
                             [minDate]="minSelectableDate"
                             [disabled]="passedEvent">
                </date-picker>
                <div
                        class="cell shrink popup-content_date">{{'fleet.program.application.recurringTo' | translate}}</div>
                <date-picker class="cell shrink"
                             name="recurringTo"
                             [(ngModel)]="recurringTo"
                             [minDate]="recurringFrom"></date-picker>
            </div>
            <div class="popup-content">
                <h5>{{'fleet.program.application.days.title' | translate}}</h5>
                <div class="grid-x grid-margin-x">
                    <ng-container *ngFor="let day of activeWeekDays">
                        <label class="cell shrink popup-content_days"
                               [ngClass]="{ active: day.active }">
                            <input type="checkbox" id="{{day.key}}"
                                   [value]="day.key"
                                   [disabled]="passedEvent"
                                   (change)="onCheckboxChange(day, $event)">
                            {{day.label | slice:0:2 }}
                        </label>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="!chargingProgramId || (chargingProgramId && !passedEvent)">
            <div>
                <h5>{{'fleet.program.application.time' | translate}}</h5>
                <span class="popup-content_message">{{'fleet.program.application.label' | translate}}</span>
            </div>
            <div>
                <fleet-time-picker name="applicationTime"
                                   [(ngModel)]="applicationTime">
                </fleet-time-picker>
            </div>
        </div>

        <div>
            <h5>{{'fleet.program.desiredCharge' | translate : {
                desiredCharge: estimates[desiredChargeIndex].soc,
                kmEstimated: estimates[desiredChargeIndex].estimatedRange.km
            } }}</h5>
            <ngx-slider name="desiredCharge"
                        [options]="{floor: 0, ceil: sliderMax(), disabled: passedEvent}"
                        [(ngModel)]="desiredChargeIndex"></ngx-slider>
        </div>

        <div class="grid-x grid-margin-x align-right action-bar">
            <div class="cell auto">
                <button class="button orange"
                        *ngIf="chargingProgramId"
                        [disabled]="passedEvent"
                        (click)="showDeleteProgramPopup.open()">
                    {{'fleet.program.delete.title' | translate}}</button>
            </div>
            <button class="cell shrink button secondary loseButton"
                    (click)="cancelProgram()">
                {{'fleet.program.cancel' | translate}}
            </button>
            <button class="cell shrink button"
                    *ngIf="!chargingProgramId"
                    (click)="addProgram()"
                    [iziviaLoading]="addingProgram"
                    [disabled]="!canAddCharge()">
                {{ 'fleet.program.add.title' | translate}}
            </button>
            <button class="cell shrink button"
                    *ngIf="chargingProgramId"
                    [disabled]="passedEvent"
                    (click)="showUpdateProgramPopup.open()">
                {{ 'fleet.program.update.title' | translate}}
            </button>
        </div>
        <div class="error note grid-x align-right" *ngIf="!isUpdating && hasError(ChargingProgramError.BEFORE_ERROR)">
            {{'fleet.program.add.beforeError' | translate}}
        </div>
        <div class="error note grid-x align-right" *ngIf="!isUpdating && hasError(ChargingProgramError.ALREADY_EXISTS_ERROR)">
            {{'fleet.program.add.alreadyExistsError' | translate}}
        </div>
    </div>
</div>

<izivia-popup #showDeleteProgramPopup [size]="'medium'">
    <fleet-charging-program-delete *ngIf="showDeleteProgramPopup.isOpened()"
                                   [vehicleId]="vehicleId"
                                   [chargingProgram]="chargingProgram"
                                   [socEventType]="socEventType"
                                   (cancel)="showDeleteProgramPopup.close()"
                                   (delete)="updateCalendarAfterDelete()"></fleet-charging-program-delete>
</izivia-popup>

<izivia-popup #showUpdateProgramPopup [size]="'medium'">
    <fleet-charging-program-update *ngIf="showUpdateProgramPopup.isOpened()"
                                   [chargingProgram]="chargingProgram"
                                   [chargingProgramErrors]="chargingProgramErrors"
                                   [socEventType]="socEventType"
                                   (cancel)="cancelUpdate()"
                                   (update)="updateProgram(chargingProgram.id)">
    </fleet-charging-program-update>
</izivia-popup>
