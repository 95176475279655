<ngx-intl-tel-input
    [inputId]="inputId"
    [name]="name"
    [(ngModel)]="intPhoneNumber"
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true"
    [searchCountryFlag]="true"
    [searchCountryPlaceholder]="'informations.administrator.phoneNumber.search' | translate"
    [selectFirstCountry]="false"
    [selectedCountryISO]="CountryISO.France"
    [maxLength]="maxLength"
    [phoneValidation]="true"
    [separateDialCode]="true"
    [numberFormat]="PhoneNumberFormat.National"
    [required]="required"
    #intPhoneNumberInput
    #intPhoneNumberModel="ngModel">
</ngx-intl-tel-input>
<div class="cell note error"
     [ngClass]="{'hiddenError': (intPhoneNumberModel.valid || !(intPhoneNumberModel.control?.touched || hasIntPhoneNumberBeenTouched))}">
    {{'informations.administrator.phoneNumber.error' | translate}}
</div>
