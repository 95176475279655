import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
    ExpenseReportActionRequestDto,
    ExpenseReportDto,
    ExpenseReportGroupItemDto,
    ExpenseReportHttpService,
    ExpenseReportPaginatedSearchRequest,
    ExpenseReportStatusType,
    ExpenseReportType,
    RefundedExpensesConfiguration,
    RefundedExpensesMethodType, RefundedExpensesType
} from 'lib-front';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {delay, filter, finalize, switchMap, tap} from 'rxjs/operators';
import moment from 'moment';

@Component({
    selector: 'expense-report-group-card',
    templateUrl: './expense-report-group-card.component.html',
    styleUrls: ['./expense-report-group-card.component.scss']
})
export class ExpenseReportGroupCardComponent {

    @ViewChild('rejectionModal') rejectionModal;

    @Input() fromSearch: Date;

    @Input() toSearch: Date;

    @Input() firstNameSearch: string;
    @Input() lastNameSearch: string;

    @Input() hasAutomaticallyRefundConfiguration: boolean;
    @Input() refundedExpensesConfiguration: RefundedExpensesConfiguration;

    public expenseReportType = ExpenseReportType;

    @Input()
    public set expenseReportGroup(value: ExpenseReportGroupItemDto) {
        this._expenseReportGroup = value;
        this.collaboratorId = this._expenseReportGroup.collaborator ? this._expenseReportGroup.collaborator.id : null;
    }

    @Output()
    public groupUpdated: EventEmitter<void> = new EventEmitter<void>();

    public expenseReports: ExpenseReportDto[] = [];
    public detailsShown = false;
    public expenseReportStatusType = ExpenseReportStatusType;
    public validateLoading = false;
    public refuseLoading = false;

    private _expenseReportGroup: ExpenseReportGroupItemDto;
    private expenseReportsLoaded = false;
    private collaboratorId: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private expenseReportHttpService: ExpenseReportHttpService,
        private alertService: AlertService,
        private translateService: TranslateService) {
    }

    public switchDetailsShown(): void {
        if (!this.detailsShown && !this.expenseReportsLoaded) {
            this.alertService.confirm(
                this.translateService.instant('expenseReport.popup.personalDate.message'),
                this.translateService.instant('expenseReport.popup.personalDate.title'),
                this.translateService.instant('expenseReport.popup.personalDate.confirm'),
                this.translateService.instant('expenseReport.popup.personalDate.cancel')
            ).pipe(
                tap(accepted => this.detailsShown = accepted),
                filter(detailsShown => detailsShown),
                switchMap(() => this.expenseReportHttpService.search(this.buildSearchRequest()))
            ).subscribe(expenseReports => {
                this.expenseReports = expenseReports;
                this.expenseReportsLoaded = true;
            });
        } else {
            this.detailsShown = !this.detailsShown;
        }
    }

    public redirectToExpenseReportDetail(id: string): void {
        this.router.navigate(['detail', id], {relativeTo: this.route, queryParamsHandling: 'preserve'});
    }

    public acceptGroup(): void {
        this.alertService.confirm(this.getAcceptPopupMessage(), null,
            this.translateService.instant('expenseReport.popup.button.accept'))
            .subscribe(accepted => {
                if (accepted) {
                    const expenseReportAction: ExpenseReportActionRequestDto = {
                        statusType: this.expenseReportGroup.expenseReportStatusType,
                        month: this.expenseReportGroup.month,
                        collaboratorId: this.collaboratorId
                    };

                    this.validateLoading = true;
                    this.expenseReportHttpService.acceptExpenseReports(expenseReportAction)
                        .pipe(
                            // Update is asynchronous, use delay to ensure it has been updated
                            delay(1500),
                            finalize(() => this.validateLoading = false)
                        )
                        .subscribe(() => {
                            this.groupUpdated.emit();
                        });
                }
            });
    }

    public rejectGroup(refuseReason: string): void {
        if (refuseReason != null) {
            this.rejectionModal.close();

            const expenseReportAction: ExpenseReportActionRequestDto = {
                statusType: this.expenseReportGroup.expenseReportStatusType,
                month: this.expenseReportGroup.month,
                collaboratorId: this.collaboratorId,
                reason: refuseReason
            };

            this.refuseLoading = true;
            this.expenseReportHttpService.rejectExpenseReports(expenseReportAction)
                .pipe(
                    // Update is asynchronous, use delay to ensure it has been updated
                    delay(1500),
                    finalize(() => this.refuseLoading = false)
                )
                .subscribe(() => {
                    this.groupUpdated.emit();
                });
        }
    }

    public refundGroup(): void {
        this.alertService.confirm(this.getRefundPopupMessage(), null,
            this.translateService.instant('expenseReport.popup.button.pay'))
            .subscribe(accepted => {
                if (accepted) {
                    const expenseReportAction: ExpenseReportActionRequestDto = {
                        statusType: this.expenseReportGroup.expenseReportStatusType,
                        month: this.expenseReportGroup.month,
                        collaboratorId: this.collaboratorId
                    };

                    this.validateLoading = true;
                    this.expenseReportHttpService.refundExpenseReports(expenseReportAction)
                        .pipe(
                            // Update is asynchronous, use delay to ensure it has been updated
                            delay(1500),
                            finalize(() => this.validateLoading = false)
                        )
                        .subscribe(() => {
                            this.groupUpdated.emit();
                        });
                }
            });
    }

    public get expenseReportGroup(): ExpenseReportGroupItemDto {
        return this._expenseReportGroup;
    }

    private buildSearchRequest(): ExpenseReportPaginatedSearchRequest {
        let fromDate: Date;
        let toDate: Date;

        if (this.expenseReportGroup && this.expenseReportGroup.month) {
            fromDate = new Date(this.expenseReportGroup.month);
            toDate = new Date(this.expenseReportGroup.month);
        } else {
            fromDate = this.fromSearch ? new Date(this.fromSearch) : null;
            toDate = this.toSearch ? new Date(this.toSearch) : null;
        }

        return {
            from: fromDate ? new Date(fromDate.getFullYear(), fromDate.getMonth(), 1).toISOString() : null,
            to: toDate ? new Date(toDate.getFullYear(), toDate.getMonth() + 1, 1).toISOString() : null,
            statusType: this.expenseReportGroup ? this.expenseReportGroup.expenseReportStatusType : null,
            collaboratorId: this.expenseReportGroup.collaborator ? this.expenseReportGroup.collaborator.id : null,
            collaboratorFirstName: this.firstNameSearch,
            collaboratorLastName: this.lastNameSearch,
            skip: 0,
            limit: null
        };
    }

    private getAcceptPopupMessage(): string {
        return '<p>' + this.translateService.instant('expenseReport.popup.accept',
            {expenseReportNumberMessage: this.getExpenseReportNumberMessage()})
            + ' ' + this.getCollaboratorNameMessage() + '</p>'
            + '<p>' + this.translateService.instant('expenseReport.popup.confirmAccept') + '</p>';
    }

    public getExpenseReportNumberMessage(): string {
        let expenseReportNumberMessage: string;

        if (this.expenseReportGroup.expenseReportCount > 1) {
            expenseReportNumberMessage = this.translateService.instant('expenseReport.popup.multipleExpenseReports',
                {numberExpenseReport: this.expenseReportGroup.expenseReportCount});
        } else {
            expenseReportNumberMessage = this.translateService.instant('expenseReport.popup.oneExpenseReport');
        }

        return expenseReportNumberMessage;
    }

    private getRefundPopupMessage(): string {
        return '<p>' + this.translateService.instant('expenseReport.popup.markAsPaid',
            {expenseReportNumberMessage: this.getExpenseReportNumberMessage()})
            + ' ' + this.getCollaboratorNameMessage() + '</p>'
            + '<p>' + this.translateService.instant('expenseReport.popup.confirmMarkAsPaid') + '</p>';
    }

    private getCollaboratorNameMessage(): string {
        return this.collaboratorId ?
            this.translateService.instant('expenseReport.popup.forCollaborator',
                {name: this.expenseReportGroup.collaborator.name})
            : '';
    }

    checkPlannedRefund(): boolean {
        return (this.refundedExpensesConfiguration.methodType === RefundedExpensesMethodType.AFTER_INVOICE_GENERATION ||
            this.refundedExpensesConfiguration.methodType === RefundedExpensesMethodType.ON_EXPENSE_REPORT_VALIDATION)
        && this.refundedExpensesConfiguration.type === RefundedExpensesType.PLANNED
            && this.expenseReportGroup.expenseReportStatusType === ExpenseReportStatusType.WAITING_REFUND ;
    }

    computeExpectedRefundDate() {
        let date = this.expenseReportGroup.month + '-' + this.refundedExpensesConfiguration.plannedMonthDay;
        return moment(date, 'YYYY-MM-DD')
            .format('DD/MM/YYYY');
    }
}
