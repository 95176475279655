import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FaqHttpService, FaqNodeDetailDto, FaqNodeType, FaqPageDetailDto, FaqSearchParams, isFaqFolderDetail, isFaqPageDetail} from 'lib-front';
import {debounceTime, distinctUntilChanged, filter, finalize, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {fromEvent, Observable} from 'rxjs';


@Component({
    selector: 'faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

    private readonly baseUrl = '/main/configuration/faq';

    @ViewChild('searchField') searchField: ElementRef;

    private _currentFaqNode: FaqNodeDetailDto;

    public faqNodesToDisplay = [];
    public faqSearchText: string = '';
    public minFaqSearchTextLength: number = 3;

    private searchResultsLimit: number = 10;
    public loading: boolean = true;

    isFaqPageDetail = isFaqPageDetail;

    get currentFaqNode(): FaqNodeDetailDto {
        return this._currentFaqNode;
    }

    set currentFaqNode(value: FaqNodeDetailDto) {
        if (isFaqFolderDetail(value)) {
            this.faqNodesToDisplay = value.children;
        } else {
            this.faqNodesToDisplay = [];
        }
        this._currentFaqNode = value;
    }

    constructor(private faqHttpService: FaqHttpService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
    }

    ngAfterViewInit(): void {
        fromEvent(this.searchField.nativeElement, 'keyup')
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => {
                    this.searchFaq(this.searchField.nativeElement.value);
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(
            filter(queryParams => this.shouldReloadFromQueryParams(queryParams)),
            tap(queryParams => {
                this.loadFaqNodesFromQueryParams(queryParams);
            })
        ).subscribe();
    }

    loadFaqNodesFromQueryParams(queryParams): void {
        if (queryParams.faqNodeId) {
            this.loadFaqNode(queryParams.faqNodeId, false);
        } else if (queryParams.faqNodeSearchText) {
            this.searchFaq(queryParams.faqNodeSearchText);
        } else {
            this.loadFaqNode(queryParams.faqNodeId, false);
        }
    }

    private shouldReloadFromQueryParams(queryParams): boolean {
        return this.currentFaqNodeIsIncoherentWithQueryParams(queryParams)
            || this.currentSearchIsIncoherentWithQueryParams(queryParams);
    }

    private currentSearchIsIncoherentWithQueryParams(queryParams): boolean {
        return queryParams.faqNodeSearchText && this.faqSearchText !== queryParams.faqNodeSearchText
            || !queryParams.faqNodeSearchText && !this.currentFaqNode;
    }

    private currentFaqNodeIsIncoherentWithQueryParams(queryParams): boolean {
        return queryParams.faqNodeId && (
            !this.currentFaqNode || this.currentFaqNode.id !== queryParams.faqNodeId
        )
            || this.currentFaqNode && this.currentFaqNode.ancestors.length > 0 && !queryParams.faqNodeId;
    }

    loadFaqNode(faqNodeId: string, changeUrl: boolean = true): void {
        this.loading = true;
        const faqNodeObservable: Observable<FaqNodeDetailDto> = faqNodeId ?
            this.faqHttpService.findNode(faqNodeId) :
            this.faqHttpService.findRootNode();
        this.faqSearchText = '';

        faqNodeObservable.pipe(
            tap(faqNode => changeUrl && this.changeUrl(faqNode)),
            finalize(() => this.loading = false)
        ).subscribe(
            faqNode => this.currentFaqNode = faqNode
        );
    }

    private changeUrl(faqNode: FaqNodeDetailDto): void {

        if (!faqNode) {
            return;
        }
        const params = {faqNodeId: faqNode.ancestors.length > 0 ? faqNode.id : null};

        this.router.navigate(
            [this.baseUrl],
            {
                queryParams: params
            }).then();
    }

    searchFaq(searchText: string): void {
        this.loading = true;
        if (searchText.length === 0) {
            this.loadFaqNode(null);
            return;
        }
        if (searchText.length < this.minFaqSearchTextLength) {
            return;
        }
        const searchParams: FaqSearchParams = {searchText: searchText, limit: this.searchResultsLimit};
        this.faqHttpService.search(searchParams).pipe(
            finalize(() => this.loading = false)
        ).subscribe(
            faqNodes => {
                this._currentFaqNode = null;
                this.faqNodesToDisplay = faqNodes;
                this.addSearchTextToUrl(searchText);
                this.faqSearchText = searchText;
            }
        );
    }

    private addSearchTextToUrl(searchText: string): void {
        const params = {faqNodeSearchText: searchText};

        this.router.navigate(
            [this.baseUrl],
            {
                queryParams: params
            }).then();
    }
}
