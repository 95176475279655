<label class="customFile inputContainer"
       [class._noMargin]="noMargin"
       [ngClass]="{disabled:isDisabled, validate:showBin}"
       (click)="removeFile()"
       [class.showBin]="showBin">

    <input #input id="input-file" type="file" class="customFile-input"
           [disabled]="isDisabled || showBin"
           [accept]="accept"
           [ngClass]="{disabled:isDisabled}"
    >

    <label #label class=" customFile-label"
           for="input-file"
           (click)="$event.stopPropagation()"
           [ngClass]="{disabled:isDisabled || showBin}">
        {{inputText}}
    </label>
</label>
