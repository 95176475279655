<div class="cell auto app-view-content">
    <form #form="ngForm" class="grid-y cell auto grid-margin-x align-justify main-container has-space">
        <div class="cell shrink grid-x align-left space-all-8">
            <h3 class="grid-x align-middle secondary block-title">
                {{'register.fleet.media.title' | translate}}
            </h3>
        </div>

        <media-order *ngIf="mediaCanOrderDtoList && mediaCanOrderDtoList.length > 0"
                     class="cell shrink space-all-8"
                     [(ngModel)]="orderForm"
                     [promoTokenAllowed]="true"
                     name="mediaOrder"
                     #mediaOrder="ngModel"
                     [frontEndInfo]="frontEndInfo"
                     [mediaOrderContext]="mediaOrderContext"
                     [mediaCanOrderDtoList]="mediaCanOrderDtoList">
        </media-order>

        <!--Check Delivery Address-->
        <div class="cell shrink space-all-8">
            <div class="grid-x align-middle">
                <h5 class="cell auto secondary block-title">
                    {{'informations.delivery.checkAddress' | translate}}
                </h5>
            </div>
            <address-form *ngIf="foAccount" [foAccount]="foAccount" [mode]="ModeAddressForm.DELIVERY"
                          (isFormAddressValid)="onFormAddressChange($event)">
            </address-form>
        </div>
        <div class="cell shrink grid-x align-left space-all-8">
            <div *ngIf="orderForm?.mediaCanOrderDto &&
              frontEndInfo.fleetConfig.returnCostsDisplayed">
                <h6>{{'media.order.returnCosts' | translate: {returnCosts: frontEndInfo.fleetConfig.returnCosts} }}</h6>
            </div>
        </div>


        <div class="cell auto grid-x align-bottom align-justify space-bottom-10 fix-action-bar">
            <div class="grid-x cell auto align-justify align-middle action-bar add-space-block">
                <button type="button" class="cell shrink button secondary force-space-right-8" routerLink="/main/media">
                    {{'common.cancel' | translate}}
                </button>
                <button type="submit" class="cell shrink button color force-space-right-8"
                        (click)="commandPass()"
                        [iziviaLoading]="orderingMedia"
                        [disabled]="isQuantityInvalid() || !isValidAddress">
                    {{'media.order.command' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>
