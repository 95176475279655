<form #updateSubscriptionOfferForm="ngForm">
    <div>
        <subscription-pack-choice name="subscriptionsToken"
                            [mode]="SubscriptionMode.client"
                            [subscriptionPacksChoice]="updateOffer?.subscriptionPacks"
                            [showPrivateOffers]="showToken"
                            [(ngModel)]="subscriptionPackOrTokenWrapper"
                            (ngModelChange)="onSubscriptionPackOrTokenWrapperChange()"
                            (acceptTerms)="acceptTerms($event)">
        </subscription-pack-choice>
        <div class="grid-y padding-left-16">
            <div class="cell space-bottom-32">
                <label class="checkbox">
                    <input name="changeBillingAddress" type="checkbox" [(ngModel)]="keepAddress"
                           (click)="keepBillingAddress()">
                    <span class="custom-checkbox"></span>
                    <span class="label-choice">{{'update.billing.address' | translate}}</span>
                </label>
            </div>
        </div>

    </div>
    <address-form [foAccount]="_foAccount"
                  *ngIf="_foAccount"
                  [mode]="keepAddress ? ModeAddressForm.BILLING_ENVELOPE : ModeAddressForm.BILLING_COMPANY"
                  (isFormAddressValid)="onFormAddressChange($event)">
    </address-form>

</form>
