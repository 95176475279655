import {Component, ElementRef, forwardRef, Input} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {replace} from 'lodash-es';

@Component({
    selector: 'siret-input',
    templateUrl: './siret-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SiretInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SiretInputComponent),
            multi: true
        }
    ]
})

export class SiretInputComponent implements ControlValueAccessor, Validator {

    @Input() required?: boolean;

    constructor(private el: ElementRef) { }

    get siretValid(): boolean {
        return this._siretValid;
    }
    set siretValid(value: boolean) {
        this._siretValid = value;
    }

    set siret(value: string) {
        const formattedSiret = this.format(value);
        if (formattedSiret !== undefined) {
            this._siret = formattedSiret;
            this.writeValue(formattedSiret);
            this.onNgTouched();
            this.onNgChange(formattedSiret && formattedSiret.replace(/\D+/g, ''));
        }
    }

    get siret(): string {
        return this._siret;
    }
    private _siret: string;
    private _siretValid: boolean;

    private onNgChange: (m: string) => void;
    private onNgTouched: () => void;

    private format(value): string {

        if (value) {

            let trimmed = value.replace(/\D+/g, '');
            trimmed = trimmed.substr(0, 14);

            const numbers = [];
            for (let i = 0; i < trimmed.length; i += 9) {
                numbers.push(trimmed.substr(i, 9));
            }
            value = numbers.join('  ');

            return value;
        } else {
            return null;
        }
    }

    public writeValue(obj: any): void {
        if (obj !== null) {
            const formattedSiret = this.format(obj);

            this._siret = formattedSiret;
            const inputElement = this.getInputChildElement();

            let pos = inputElement.selectionStart;
            if (pos === 10 || pos === 11) {
                pos = 12;
            }
            inputElement.value = formattedSiret;
            inputElement.selectionStart = pos;
            inputElement.selectionEnd = pos;
        }
    }

    private getInputChildElement() {
        return this.el.nativeElement.children[0].children[1];
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    public validate(siret: AbstractControl): ValidationErrors {
        let valid = null;
        if (!this.required && !siret.value) {
            this._siretValid = true;
        } else {
            this._siretValid = this.isSiretValid(replace(siret.value, '  ', ''));
            if (!this._siretValid) {
                valid = {
                    siretPattern: false
                };
            }
        }
        return valid;
    }

    private isSiretValid(siret) {
        const siretRegexp = /^\d{9}\s*\d{5}$/;
        if (!siretRegexp.test(siret)) {
            return false;
        }

        siret = siret.replace(/\D+/g, '');
        let isValid;
        if ( (siret.length !== 14) || (isNaN(siret)) ) {
            isValid = false;
        } else {
            let sum = 0;
            let tmp;
            for (let cpt = 0; cpt < siret.length; cpt++) {
                if ((cpt % 2) === 0) {
                    tmp = siret.charAt(cpt) * 2;
                    if (tmp > 9) {
                        tmp -= 9;
                    }
                } else {
                    tmp = siret.charAt(cpt);
                }
                sum += parseInt(tmp, 10);
            }
            isValid = (sum % 10) === 0;
        }
        return isValid;
    }
}
