import {Component, Inject, ViewChild} from '@angular/core';
import {
    FrontPasswordHttpService,
    IziviaPasswordValidatorBuilder,
    IziviaPasswordValidatorDefinition,
    PASSWORD_VALIDATORS
} from 'lib-front';
import {NotificationService} from '../../../services/utils/notification.service';
import {Md5} from 'ts-md5';
import {ActivatedRoute, Router} from '@angular/router';
import {NgModel, Validator} from '@angular/forms';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-reset-pwd',
    templateUrl: './reset-pwd.component.html',
    styleUrls: ['./reset-pwd.component.scss'],
    host: {'class': 'cell grid-y auto align-center align-middle'}
})
export class ResetPwdComponent{

    @ViewChild('pwd', {static: false}) set pwd(pwd: NgModel) {
        if (pwd) {
            this._pwd = pwd;
            this._pwd.control.addValidators(this.passwordValidators.map(validator => validator.validate.bind(validator)));
        }
    }

    public password: string;
    public isTokenValid: boolean;
    public passwordValidators: Validator[];
    public _pwd: NgModel;
    public resettingPassword: boolean;

    private token: string;

    constructor(private router: Router,
        private passwordService: FrontPasswordHttpService,
        private notificationService: NotificationService,
        route: ActivatedRoute,
        @Inject(PASSWORD_VALIDATORS) public passwordValidatorDefinitions: IziviaPasswordValidatorDefinition[]) {
        this.password = '';
        route.queryParams.subscribe(data => {
            this.token = data.token;
            this.passwordService.isResetTokenValid(this.token).subscribe(isValid => {
                this.isTokenValid = !!isValid;
            });
        });
        this.passwordValidators = passwordValidatorDefinitions.map(def => IziviaPasswordValidatorBuilder.fromDefinition(def));
    }

    public resetPassword() {
        this.resettingPassword = true;
        this.passwordService.resetPassword(Md5.hashStr(this.password), this.token)
            .pipe(
                finalize(() => this.resettingPassword = false)
            )
            .subscribe(
                () => {
                    this.notificationService.success('forgottenPwd.resetPwdSucceed');
                    this.router.navigate(['/login']);
                },
                () => this.notificationService.success('forgottenPwd.errorWithForgottenPwdRequest')
            );
    }
}
