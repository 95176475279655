import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    FrontFoAccountHttpService,
    FrontMediaHttpService,
    FrontPackSubscriptionHttpService,
    FrontPersonHttpService,
    MediaRequest,
    User,
    FrontVehicleHttpService, Vehicle
} from 'lib-front';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CurrentUserContextService} from '../../../../../services/business/currentUserContext.service';
import {NotificationService} from '../../../../../services/utils/notification.service';

@Component({
    selector: 'media-activate',
    templateUrl: './media-activate.component.html',
    styleUrls: ['./media-activate.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class MediaActivateComponent {
    public form: UntypedFormGroup;
    public subscribers: User[];
    public vehicles: Vehicle[];
    public foAccountRef: string;
    public user: User;
    public logoRefs: string[];
    public showValidateError: boolean;
    public validateError: boolean;
    public validateErrorDisabled: boolean;
    public validateErrorUsed: boolean;
    public hasPackSubscriptionWithNoLimit: boolean = true;
    public hasSelectedSubscriptionPacks: boolean;


    constructor(private readonly personHttpService: FrontPersonHttpService,
        private readonly mediaHttpService: FrontMediaHttpService,
        private readonly frontPackSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private readonly router: Router,
        private readonly foAccountHttpService: FrontFoAccountHttpService,
        private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly notificationService: NotificationService,
        readonly route: ActivatedRoute,
        readonly fb: UntypedFormBuilder) {
        this.logoRefs = [];
        this.showValidateError = false;
        this.disableErrorMessage();
        route.data.subscribe(data => {
            this.user = data.user.user;
            this.loadSubscribers();
            this.loadVehicles();
            this.frontPackSubscriptionHttpService.findPackSubscription(
                this.user._id,
                false,
                true,
                false
            )
                .subscribe(packSubscriptions => {
                    if (packSubscriptions.length > 0) {
                        this.hasPackSubscriptionWithNoLimit = (Math.min(...packSubscriptions
                            .map(packSubscription => packSubscription.maxMediasByAccount)) === 0);
                    }
                });
        });
        this.form = fb.group({
            medias: fb.array([])
        });
        (this.form.get('medias') as UntypedFormArray).push(new UntypedFormControl());
        this.form.valueChanges.subscribe(event => this.checkAndUpdateHasSelectSubscriptionPack(event));
    }

    public get mediaFormArray(): UntypedFormArray {
        return this.form.get('medias') as UntypedFormArray;
    }

    validatePass(redirect = false) {
        if (!this.form.invalid) {
            this.showValidateError = false;
            const mediaForm = this.form.get('medias').value[this.form.get('medias').value.length - 1];
            const mediaRequest: MediaRequest = {
                validationCode: mediaForm.validationCode,
                code: mediaForm.code,
                mediaFamilyRef: mediaForm.mediaFamily,
                subscriptionPackRefs: mediaForm.subscriptionPacks
            };
            this.mediaHttpService.activeMedia(this.foAccountRef,
                mediaForm.colaborator,
                mediaRequest).subscribe(media => {

                const vehicleId = mediaForm.vehicle;
                if (vehicleId) {
                    this.vehicleHttpService.addMediaToVehicle(vehicleId, media.id).subscribe();
                }

                if (redirect) {
                    this.router.navigate(['/main/media']);
                } else {
                    this.logoRefs.push(media.visual.logoRef);
                    this.disableErrorMessage();
                    (this.form.get('medias') as UntypedFormArray).disable();
                    (this.form.get('medias') as UntypedFormArray).push(new UntypedFormControl());
                }
            }, err => {
                if (err.status === 403 && err.error.includes('DISABLED')) {
                    this.disableErrorMessage();
                    this.validateErrorDisabled = true;
                } else if (err.status === 403 && err.error.includes('USED')) {
                    this.disableErrorMessage();
                    this.validateErrorUsed = true;
                } else {
                    this.disableErrorMessage();
                    if (err.error.labelKey === 'error.media.activate.tooManyExpenseReportSubscriptionPacks') {
                        this.notificationService.error(err.error.labelKey);
                    }
                    this.validateError = true;
                }
            });

        } else {
            this.showValidateError = true;

        }
    }

    private disableErrorMessage(): void {
        this.validateError = false;
        this.validateErrorDisabled = false;
        this.validateErrorUsed = false;
    }

    private loadSubscribers(): void {
        this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId();
        this.foAccountHttpService.findSubscribers(this.currentUserContextService.getCurrentFoAccountId()).subscribe(subscribers => {
            this.subscribers = subscribers;
        });
    }

    private loadVehicles(): void {
        this.vehicleHttpService.findVehicles({}).subscribe(vehicles => {
            this.vehicles = vehicles;
        });
    }

    private checkAndUpdateHasSelectSubscriptionPack(event: any): void {
        this.hasSelectedSubscriptionPacks =
            !!event.medias
                .find(media => !!media && !!media.subscriptionPacks && media.subscriptionPacks.length > 0);
    }

    trackByIndex(index: number, elem: any) {
        return index;
    }
}

export interface MediaActivateForm {
    validationCode: string;
    code: string;
    colaborator: string;
}
