import {Component, Input} from '@angular/core';
import {ChargeRecordDto} from 'lib-front';
import {LocationType} from '../../pipes/fleetLocation.pipe';

@Component({
    selector: 'charge-record-table',
    templateUrl: './charge-record-table.component.html',
    styleUrls: ['./charge-record-table.component.scss']
})
export class ChargeRecordTableComponent {
    public LocationType = LocationType;
    @Input()
    public chargeRecords: Array<ChargeRecordDto>;
    @Input()
    public currencyUnit: string;

    constructor() {
    }

    trackByChargeRecordId(index: number, chargeRecordDto: ChargeRecordDto) {
        return chargeRecordDto?.id ?? index;
    }
}
