<div class="grid-y has-space">
    <h3 class="grid-x align-middle secondary block-title">
        {{'config.permission.collaboratorsRights' | translate}}
    </h3>

    <div *ngIf="foAccount"
         class="cell shrink margin-rgpd">
<!-- TODO SMBP-27761/SMBP-27762 -->
<!-- Hidden feature since v55 (some questions need to be answered before enabling this feature again) -->
<!--        <label>-->
<!--            <input type="checkbox" name="collaboratorsActivatePass"-->
<!--                   [(ngModel)]="foAccount.collaboratorPassConfiguration.collaboratorsActivatePass"-->
<!--                   [disabled]="!hasSubscriberRolesWriteRole"-->
<!--                   (change)="updateFOAccount()">-->
<!--            <span class="custom-checkbox"></span>-->
<!--            <span class="label-choice checkbox-margin ">{{'config.permission.activatePass' | translate}}</span>-->
<!--        </label>-->
        <label>
            <input type="checkbox" name="collaboratorsDeclarePass"
                   [(ngModel)]="foAccount.collaboratorPassConfiguration.collaboratorsDeclarePass"
                   [disabled]="!hasSubscriberRolesWriteRole"
                   (change)="updateFOAccount()">
            <span class="custom-checkbox"></span>
            <span class="label-choice checkbox-margin ">{{'config.permission.declarePass' | translate}}</span>
        </label>
        <label>
            <input type="checkbox" name="collaboratorsChangePassAlias"
                   [(ngModel)]="foAccount.collaboratorPassConfiguration.collaboratorsChangePassAlias"
                   [disabled]="!hasSubscriberRolesWriteRole"
                   (change)="updateFOAccount()">
            <span class="custom-checkbox"></span>
            <span class="label-choice checkbox-margin ">{{'config.permission.changePassAlias' | translate}}</span>
        </label>
    </div>

    <div>
        <h3 class="grid-x align-middle secondary block-title">
            {{'config.permission.managersRoles' | translate}}
        </h3>
        <manager-roles-form [hasSubscriberRolesWriteRole]="hasSubscriberRolesWriteRole"
                            [hasSubscriberRolesReadRole]="hasSubscriberRolesReadRole"
                            [filter]="filter">
        </manager-roles-form>

    </div>
</div>
