import {
    Component,
    DoCheck,
    forwardRef,
    Input,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {FrontEndService} from '../../services/frontEnd.service';
import {map, tap} from 'rxjs/operators';
import {PersonAddress} from 'lib-front';

@Component({
    selector: 'envelope',
    templateUrl: './envelope.component.html',
    styleUrls: ['./envelope.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnvelopeComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EnvelopeComponent),
            multi: true
        }
    ]
})

export class EnvelopeComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {
    @Input('person-address') set personAddressInput(personAddress: PersonAddress) {
        this.personAddress = personAddress;
        this.updateEnvelopeIfPersonChanged();
    }

    @Input('social-reason') set socialReasonInput(socialReason: string | undefined) {
        this.socialReason = socialReason;
        this.updateEnvelopeIfPersonChanged();
    }

    needValidEnvelope: boolean;
    viewEnvelope: boolean;
    envelopeValidated: boolean;
    isAddressValid: boolean;
    showSocialReason: boolean;

    returnCostsDisplayed: boolean;
    returnCosts: number;

    personAddress: PersonAddress;
    previousPersonAddress: PersonAddress;
    socialReason?: string;
    previousSocialReason?: string;

    onChange: (v: boolean) => void;
    onTouched: (v: boolean) => void;

    constructor(private readonly frontEndService: FrontEndService) {
    }

    public ngOnInit(): void {
        this.frontEndService.currentFrontEndInfo$
            .pipe(
                tap(frontEndInfo => this.needValidEnvelope = frontEndInfo.config.validEnvelope),
                tap(frontEndInfo => this.viewEnvelope = frontEndInfo.config.viewEnvelope),
                tap(frontEndInfo =>
                    this.showSocialReason = frontEndInfo.fleetConfig.usingFinalClientSocialReasonInDeliveryAddress
                ),
                tap(frontEndConfig => this.returnCostsDisplayed = frontEndConfig.fleetConfig.returnCostsDisplayed),
                tap(frontEndConfig => this.returnCosts = frontEndConfig.fleetConfig.returnCosts)
            )
            .subscribe();
    }

    /**
     * Implements our own change detection
     * The purpose is to check address properties has change (Angular only check object ref)
     */
    public ngDoCheck(): void {
        this.updateEnvelopeIfPersonChanged();
        this.checkAddressValidity();
    }

    public writeValue(envelopeValidated: boolean): void {
        this.envelopeValidated = envelopeValidated ?? this.envelopeValidated;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        let valid = null;
        if (this.needValidEnvelope) {
            if (!this.envelopeValidated) {
                valid = {envelopeValidated: false};
            }
        }

        return valid;
    }

    private isPersonChanged(personAddress: PersonAddress) {
        if (!this.previousPersonAddress && personAddress || this.previousPersonAddress && !personAddress) {
            return true;
        }

        if (!this.previousPersonAddress && !personAddress) {
            return false;
        }

        return this.previousPersonAddress.firstName !== personAddress.firstName
            || this.previousPersonAddress.lastName !== personAddress.lastName
            || this.previousPersonAddress.address.extra !== personAddress.address.extra
            || this.previousPersonAddress.address.streetNumber !== personAddress.address.streetNumber
            || this.previousPersonAddress.address.route !== personAddress.address.route
            || this.previousPersonAddress.address.city !== personAddress.address.city
            || this.previousPersonAddress.address.postalCode !== personAddress.address.postalCode;
    }

    private isSocialReasonChanged(socialReason: string) {
        return this.showSocialReason && this.previousSocialReason !== socialReason;
    }

    private updatePreviousAddress() {
        this.previousPersonAddress = {
            firstName: this.personAddress.firstName,
            lastName: this.personAddress.lastName,
            address: {
                extra: this.personAddress.address?.extra,
                streetNumber: this.personAddress.address?.streetNumber,
                route: this.personAddress.address?.route,
                city: this.personAddress.address?.city,
                postalCode: this.personAddress.address?.postalCode
            },
            phoneNumber: null
        };
        this.previousSocialReason = this.socialReason;
    }

    private updateEnvelopeIfPersonChanged(): void {
        if (this.isPersonChanged(this.personAddress)
            || this.isSocialReasonChanged(this.socialReason)
        ) {
            if (this.previousPersonAddress) {
                // force a tick
                setTimeout(() => {
                    this.envelopeValidated = false;
                    this.validAddress();
                }, 1);
            }
            this.updatePreviousAddress();
        }
    }

    public validAddress(): void {
        // Call validate method
        this.onChange(this.envelopeValidated);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public isAddressEmpty(): boolean {
        return !this.personAddress.firstName &&
            !this.personAddress.lastName &&
            !this.personAddress.address.city &&
            !this.personAddress.address.postalCode &&
            !this.personAddress.address.route &&
            !this.personAddress.address.extra;
    }

    public checkAddressValidity(): void {
        this.isAddressValid = !!this.personAddress.firstName &&
            !!this.personAddress.lastName &&
            (!!this.personAddress.address.country || !!this.personAddress.address.countryCode) &&
            !!this.personAddress.address.city &&
            !!this.personAddress.address.postalCode &&
            !!this.personAddress.address.route;
    }
}
