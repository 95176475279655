<div class="grid-x cell auto align-center subscription-result-block small-space">
    <div class="cell shrink subscription-result-block-item time">
        {{_totalHours | fleetDuration:{seconds: false, minutes: true} }}
    </div>
    <div class="grid-x shrink subscription-result-block-item">
        <span class="price">
            {{_totalPrice | currency:'EUR': (_options?.length && _options[0].subscription?.subscriptionDef?.currencyUnit) || ''}}
        </span>
        <span class="force-font-bold ttcText price">TTC</span>
    </div>
</div>
