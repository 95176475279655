<input class="switch-input" type="checkbox" [ngModel]="selected" (ngModelChange)="onChange($event)"
       [disabled]="disabled">
<div class="switch-paddle">
    <span class="switch-active" [hidden]="!selected">
        <ng-container *ngIf="trueText">{{trueText}}</ng-container>
        <ng-container *ngIf="!trueText">{{'yes' | translate}}</ng-container>
    </span>
    <span class="switch-inactive" [hidden]="selected">
        <ng-container *ngIf="falseText">{{falseText}}</ng-container>
        <ng-container *ngIf="!falseText">{{'no' | translate}}</ng-container>
    </span>
</div>
