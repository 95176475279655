<div class="cell auto app-view-content">
    <div class="grid-y common-block">
        <h3 class="grid-x align-middle secondary block-title space-bottom-16">
            {{'invoice.waitForPayment.title' | translate}}
        </h3>
        <div class="cell small-24">
            <table>
                <thead>
                <tr>
                    <th>{{'invoice.waitForPayment.table.date' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.number' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.type' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.totalHT' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.totalTTC' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.operator' | translate}}</th>
                    <th>{{'invoice.waitForPayment.table.status' | translate}}</th>
                </tr>
                </thead>
                <tbody class="border">
                <tr>
                    <td>{{invoice?.invoiceDate | date : 'short'}}</td>
                    <td>{{invoice?.invoiceId}}</td>
                    <td>
                        <div *ngFor="let row of invoice?.rows">{{row.label | translate}}</div>
                    </td>
                    <td>{{invoice?.grossAmount}} {{invoice?.currencyUnit}}</td>
                    <td>{{invoice?.netAmount}} {{invoice?.currencyUnit}}</td>
                    <td>{{invoice?.providerName}}</td>
                    <td [hidden]="!invoice?.currentStatus?.status" [innerHTML]="invoice | fleetInvoiceStatus"></td>
                    <td [hidden]="!!invoice?.currentStatus?.status"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="grid-y common-block">
        <h5 class="grid-x align-middle secondary block-title space-bottom-16">
            {{'invoice.waitForPayment.choosePayment' |translate}}
        </h5>
        <payment-type class="cell"
                      [paymentTypesAvailables]="filteredPaymentTypes"
                      [paymentConf]="paymentConf"
                      (paymentTypeEmitter)="selectPayment($event)"
                      (currentPaymentEmitter)="selectCurrentPayment($event)"
                      (paymentConfigNameEmitter)="setPaymentConfigName($event)">
        </payment-type>

        <div class="grid-x">
            <div class="cell auto"></div>
            <button class="cell shrink button color validate" (click)="startPaymentProcess()" type="button" [disabled]="(!paymentSelected && !currentPaymentConfSelected) || !configNameIsValid()">
                {{'invoice.waitForPayment.table.pay' | translate}}
            </button>
        </div>
    </div>


    <payment-options *ngIf="invoiceForm$" [payment-request-infos]="invoiceForm$ | async"></payment-options>
</div>
