import {NgModule} from '@angular/core';
import {InvoicesComponent} from './invoices.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {InvoicePaymentComponent} from './invoice-payment/invoice-payment.component';
import {ComponentsModule} from '../../../../components/components.module';
import {InvoiceHistoricComponent} from './invoice-historic/invoice-historic.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../../../../pipes/pipes.module';
import {InvoicesGuard} from './invoices.guard';
import {PaymentGuard} from './payment.guard';
import {InvoicesView} from './invoices.view';
import {InvoiceReadRoleGuard} from './invoice-read-role.guard';
import {DirectivesModule} from '../../../../directives/directives.module';

@NgModule({
    declarations: [
        InvoicesView,
        InvoicesComponent,
        InvoicePaymentComponent,
        InvoiceHistoricComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ComponentsModule,
        PipesModule,
        DirectivesModule
    ],
    providers: [
        InvoicesGuard,
        PaymentGuard,
        InvoiceReadRoleGuard
    ],
    exports: [
        InvoicesComponent
    ]
})
export class InvoicesModule {

}
