import {Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output} from '@angular/core';
import {ConnectedUser, FrontOfferHttpService, HOST_THIRD_PARTY_ID, SubscriptionDef} from 'lib-front';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'subscription-options',
    templateUrl: './subscription-options.component.html',
    styleUrls: ['./subscription-options.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SubscriptionOptionsComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SubscriptionOptionsComponent),
            multi: true
        }
    ]
})
export class SubscriptionOptionsComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() mode: ModeSubscriptionOption = ModeSubscriptionOption.ADD_OPTION;
    @Input() set offerId(offerId) {
        if (this._offerId !== offerId) {
            this._offerId = offerId;
            if (offerId !== null) {
                this.setOptions();
            }
        }
    }

    @Input() set subscriptionPackId(subscriptionPackId) {
        if (this._subscriptionPackId !== subscriptionPackId) {
            this._subscriptionPackId = subscriptionPackId;
            this.setOptions();
        }
    }

    @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
    @Output() currency: EventEmitter<string> = new EventEmitter<string>();

    private user: ConnectedUser;

    _offerId: string;
    _subscriptionPackId: string;
    options: Array<SubscriptionDef | SubscriptionDefRevocable> = [];
    totalHours: number = 0;
    totalPrice: number = 0;
    currencyUnit: string;

    form: UntypedFormGroup;

    onChangeCallback = (options: OptionForm[]) => {};
    onTouchedCallback = () => {};

    constructor(private offerHttpService: FrontOfferHttpService,
        private fb: UntypedFormBuilder,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string,
        route: ActivatedRoute) {
        route.data.subscribe(data => this.user = data.user);
    }

    ngOnInit() {
        this.form = this.fb.group({
            options : this.fb.array([])
        });
        this.currencyUnit = null;
        this.form.controls.options.valueChanges
            .subscribe(values => {
                this.form.updateValueAndValidity();
                const optionsForm = (this.form.controls.options as UntypedFormArray);
                const value: Array<OptionForm> = [];

                let totalH: number = 0;
                let totalP: number = 0;

                if (this.options.length !== optionsForm.length) {
                    return;
                }

                this.options.forEach((option, index) => {
                    const optionNumber = optionsForm.at(index).value;
                    value.push({subscription: option, number: optionNumber});
                    if (!this.currencyUnit && !!option.currencyUnit) {
                        this.currencyUnit = option.currencyUnit;
                    }
                    if (optionNumber && optionNumber > 0) {
                        totalH += option.freeTime * optionNumber;
                        totalP += option.fixAmountWithVAT * optionNumber;
                    }
                });
                this.currency.emit(this.currencyUnit);
                this.onChangeCallback(value);
                this.totalHours = totalH;
                this.totalPrice = totalP;
            });
    }

    setOptions(optionsToRevoke?: Array<OptionForm>) {
        if (this.mode !== ModeSubscriptionOption.REVOKE) {
            let fetchOptions$ = null;
            if (this._subscriptionPackId && !this._offerId) {
                fetchOptions$ = this.offerHttpService.findOptionsBySubscriptionPack(this.thirdPartyId, this._subscriptionPackId);
            } else if (this._subscriptionPackId && this._offerId) {
                fetchOptions$ = this.offerHttpService
                    .findOptionsByOffer(this.thirdPartyId, this._offerId, this._subscriptionPackId);
            } else {
                return;
            }

            fetchOptions$.subscribe(value => {
                const optionsForm = (this.form.controls.options as UntypedFormArray);
                while (optionsForm.length) {
                    optionsForm.removeAt(0);
                    this.options.pop();
                }
                this.options = value;
                this.options.forEach(option => optionsForm.push(this.fb.control(String(0), Validators.min(0))));
            });
        } else if (this.mode === ModeSubscriptionOption.REVOKE) {
            this.options = optionsToRevoke.map(optionRevoke => optionRevoke.subscription);
            const optionsForm = (this.form.controls.options as UntypedFormArray);
            while (optionsForm.length) {
                optionsForm.removeAt(0);
            }

            this.options.forEach(optionRevoke => optionsForm.push(
                this.fb.control(String(0),
                    [Validators.min(0),
                        Validators.max((optionRevoke as SubscriptionDefRevocable).maxRevocable)]
                )));
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
    }

    public writeValue(obj: OptionForm[]): void {
        if (obj && this.mode !== ModeSubscriptionOption.REVOKE) {
            this.form.patchValue(obj);
        } else if (obj && this.mode === ModeSubscriptionOption.REVOKE) {
            this.setOptions(obj);
        }
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        return this.form.invalid ? {'options' : 'invalid'} : null;
    }

    public onSubscriptionSelected(subscriptionDefId: string) {
        if (subscriptionDefId) {
            this.optionSelected.emit(subscriptionDefId);
        }
    }

    public asSubscriptionDefRevocable(option: SubscriptionDef | SubscriptionDefRevocable): SubscriptionDefRevocable {
        return option as SubscriptionDefRevocable;
    }
}

export class OptionForm {
    subscription: SubscriptionDef | SubscriptionDefRevocable;
    number: number;
}

export interface SubscriptionDefRevocable extends SubscriptionDef {
    maxRevocable: number;
}

export enum ModeSubscriptionOption {
    REGISTER = 'REGISTER',
    ADD_OPTION = 'ADD_OPTION',
    REVOKE = 'REVOKE',
    UPDATE = 'UPDATE'
}
