import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ConnectedUser,
    CurrentSubscriberRolesService,
    FrontInvoiceHttpService,
    FrontMediaHttpService,
    FrontPackSubscriptionHttpService,
    FrontPersonHttpService,
    FrontSubscriptionPackHttpService,
    OptionSubscription,
    PackSubscription,
    StdUser,
    SubscriberRoleLevel,
    SubscriberRoleType,
    SubscriptionMode,
    SubscriptionRequestDto,
    User,
    buildRole
} from 'lib-front';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {getOptionsFromPackSubscription} from '../../../../utils/packSubscriptionUtils.service';
import {AlertService} from '../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../services/utils/notification.service';
import {NgForm} from '@angular/forms';
import {PromoTokenWithSubscriptionPack} from '../../../../domain/promoTokenWithSubscriptionPack';
import {CurrentUserContextService} from '../../../../services/business/currentUserContext.service';
import {AbstractHasRoleActionComponent} from '../../../../components/has-role-action/abstract-has-role-action.component';
import {ModeSubscriptionOfferView} from '../../../../components/subscription-offer-view/subscription-offer-view.component';

@Component({
    selector: 'subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class SubscriptionsComponent extends AbstractHasRoleActionComponent implements OnInit {
    ModeSubscriptionOfferView = ModeSubscriptionOfferView;
    public subscriptionPackRefs: Array<string> = [];
    public showTokenInput: boolean;
    public hasSubscriptionsWriteRole: boolean;

    currentUser: StdUser;
    isSuspended: boolean;
    foAccountRef: string;
    packSubscriptions: Array<PackSubscriptionWrapper>;
    packSubscriptionsSelected: PackSubscriptionWrapper;
    hasBlockingInvoices: boolean;
    waitingPackSubscriptions: boolean;
    schedulingTermination: boolean;
    revokingSubscription: boolean;

    @ViewChild('additionalInformationForm')
    public additionalInformationForm: NgForm;

    private promoToken: PromoTokenWithSubscriptionPack;
    private packSubscriptionOrderHashesInProgress: string[];

    SubscriptionMode = SubscriptionMode;

    constructor(private readonly packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private readonly mediaHttpService: FrontMediaHttpService,
        private readonly invoiceHttpService: FrontInvoiceHttpService,
        private readonly personHttpService: FrontPersonHttpService,
        private readonly subscriptionPackHttpService: FrontSubscriptionPackHttpService,
        private readonly translateService: TranslateService,
        private readonly alertService: AlertService,
        private readonly route: ActivatedRoute,
        protected readonly notificationService: NotificationService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly router: Router) {
        super(notificationService);
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.SUBSCRIPTIONS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasSubscriptionsWriteRole = hasRole);
    }

    ngOnInit(): void {
        this.waitingPackSubscriptions = true;
        this.route.data
            .pipe(
                tap(data => {
                    this.currentUser = data.user.user;
                    this.isSuspended = ConnectedUser.isSuspended(data.user);
                })
            ).subscribe(
                () => {
                    this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId();

                    this.loadPackSubscriptions();

                    this.invoiceHttpService.fetchBlockingInvoiceIds(this.currentUser._id)
                        .subscribe(invoices => this.hasBlockingInvoices = invoices && invoices.length > 0);
                });
    }


    private createSubscriptionRequestDtoFromToken(token: PromoTokenWithSubscriptionPack, person: User): SubscriptionRequestDto {
        const subscriptionRequestDto: SubscriptionRequestDto = new SubscriptionRequestDto();
        subscriptionRequestDto.personAddress = {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email
        };

        subscriptionRequestDto.packSubscriptionRequest = {additionalInformations: {}};
        subscriptionRequestDto.promoToken = token.token;

        return subscriptionRequestDto;
    }

    private loadPackSubscriptions() {
        this.packSubscriptionHttpService.findPackSubscriptionForFleet(this.currentUser._id, false)
            .pipe(
                tap(packSubscriptions => this.subscriptionPackRefs = packSubscriptions.map(packSubscription => packSubscription.subscriptionPack._id)),
                map(packSubscriptions => packSubscriptions.map(packSubscription => this.toSubscriptionWrapper(packSubscription))),
                tap(packSubscriptions => this.packSubscriptions = packSubscriptions),
                map(packSubscriptions => packSubscriptions.map(packSubscription =>
                    packSubscription.packSubscription.orderHash)),
                switchMap(packSubscriptionOrderHashes =>
                    this.packSubscriptionHttpService.findAsynchronousSubscriptionsInProgress(packSubscriptionOrderHashes)),
                finalize(() => this.waitingPackSubscriptions = false)
            )
            .subscribe(packSubscriptionOrderHashesInProgress =>
                this.packSubscriptionOrderHashesInProgress = packSubscriptionOrderHashesInProgress);
    }

    private toSubscriptionWrapper(packSubscription: PackSubscription): PackSubscriptionWrapper {
        const subscriptionPackWrapper: PackSubscriptionWrapper = new PackSubscriptionWrapper();
        subscriptionPackWrapper.packSubscription = packSubscription;
        subscriptionPackWrapper.options = getOptionsFromPackSubscription(packSubscription);

        return subscriptionPackWrapper;
    }

    public promoTokenChanged(promoToken: PromoTokenWithSubscriptionPack) {
        this.promoToken = promoToken;
        if (this.promoToken.valid) {
            const subscriptionRequest = this.createSubscriptionRequestDtoFromToken(promoToken, this.currentUser);
            this.packSubscriptionHttpService.subscribe(subscriptionRequest).subscribe(
                success => this.loadPackSubscriptions()
            );
        }
    }

    private revokeSubscription(packSubscriptionId: string): void {
        this.alertService.confirm(
            '<i class="fas fa-exclamation-circle space-right-8"></i>' +
            this.translateService.instant('subscription.revoke.message'),
            this.translateService.instant('subscription.revoke.title'),
            this.translateService.instant('subscription.revoke.confirm'),
            this.translateService.instant('common.cancel'),
        ).subscribe(confirm => {
            if (confirm) {
                this.revokingSubscription = true;
                this.packSubscriptionHttpService.revoke(packSubscriptionId)
                    .pipe(
                        finalize(() => this.revokingSubscription = false)
                    )
                    .subscribe(() => {
                        this.notificationService.success('subscriptions.revoke.success');
                        this.packSubscriptions = this.packSubscriptions.filter((subscription) => {
                            return subscription.packSubscription._id !== packSubscriptionId;
                        });
                        this.onPackSubscriptionRemoveClick();
                    }, () => this.notificationService.error('subscriptions.revoke.error'));
            }
        });
    }

    private scheduleSubscriptionTermination(packSubscription: PackSubscription): void {
        const packSubscriptionId = packSubscription._id;
        const engagementEndDate = new Date(Date.parse(packSubscription.engagementEndDate));
        const engagementEndLocalDateString = engagementEndDate.toLocaleDateString();
        engagementEndDate.setDate(engagementEndDate.getDate() + 1);
        this.alertService.confirm(
            '<i class="fas fa-exclamation-circle space-right-8"></i>' +
            this.translateService.instant(
                'subscription.terminationSchedule.message',
                {
                    packSubscriptionName: packSubscription.subscriptionPackName,
                    engagementDate: engagementEndLocalDateString,
                    terminationDate: engagementEndDate.toLocaleDateString()
                }
            ),
            this.translateService.instant('subscription.terminationSchedule.title'),
            this.translateService.instant('subscription.revoke.confirm'),
            this.translateService.instant('common.cancel'),
        ).subscribe(confirm => {
            if (confirm) {
                this.schedulingTermination = true;
                this.packSubscriptionHttpService.scheduleTermination(packSubscriptionId)
                    .pipe(
                        finalize(() => this.schedulingTermination = false)
                    )
                    .subscribe(() => {
                        this.loadPackSubscriptions();
                        this.onPackSubscriptionRemoveClick();
                        this.notificationService.success('subscriptions.revoke.success');
                    }, () => this.notificationService.error('subscriptions.revoke.error'));
            }
        });
    }

    public askForSubscriptionRevocation(packSubscription: PackSubscription): void {
        this.doActionIfHasRole(
            () => {
                if (!!packSubscription.engagementEndDate && new Date(Date.parse(packSubscription.engagementEndDate)) >
                    new Date()) {
                    this.scheduleSubscriptionTermination(packSubscription);
                } else {
                    this.revokeSubscription(packSubscription._id);
                }
            },
            this.hasSubscriptionsWriteRole
        );
    }

    onPackSubscriptionClick(packSubscriptionsWrapper: PackSubscriptionWrapper) {
        this.packSubscriptionsSelected = packSubscriptionsWrapper;

        if (packSubscriptionsWrapper.canBeUpgrade === undefined) {
            this.subscriptionPackHttpService.findUpdateOfSubscriptionPack(
                packSubscriptionsWrapper.packSubscription.subscriptionPackRef,
                packSubscriptionsWrapper.packSubscription._id
            )
                .subscribe(updateOffers => packSubscriptionsWrapper.canBeUpgrade = updateOffers
                    && !!updateOffers.length
                    && updateOffers[0].subscriptionPacks
                    && !!updateOffers[0].subscriptionPacks.length);
        }
    }

    onPackSubscriptionRemoveClick() {
        this.packSubscriptionsSelected = null;
    }

    trackByPackSubscriptionId(index: number, packSubscription: PackSubscriptionWrapper) {
        return packSubscription?.packSubscription?._id ?? index;
    }

    getAdditionalInformationList() {
        return this.packSubscriptionsSelected.packSubscription.subscriptionPack.additionalInformations;
    }

    getAdditionalInformationAnswers() {
        return this.packSubscriptionsSelected.packSubscription.additionalInformations ?
            this.packSubscriptionsSelected.packSubscription.additionalInformations : {};
    }

    shouldShowAdditionalInformationForm() {
        return !!this.packSubscriptionsSelected
            && !!(this.packSubscriptionsSelected.packSubscription)
            && !!(this.packSubscriptionsSelected.packSubscription.subscriptionPack)
            && !!(this.packSubscriptionsSelected.packSubscription.subscriptionPack.additionalInformations);
    }

    isRevocationDisabled(): boolean {
        return !!this.packSubscriptionsSelected?.hasEngagedOptions ||
        (this.packSubscriptionsSelected?.hasEndEngagement && this.packSubscriptionsSelected?.packSubscription?.terminationScheduled) ||
            this.isPackSubscriptionInProgress();
    }

    isRevocationFromAccountDisabled(): boolean {
        return !this.packSubscriptionsSelected?.packSubscription?.subscriptionPack?.subscriptionCancellationAllowedFromAccount;
    }

    public isPackSubscriptionInProgress(): boolean {
        return !!this.packSubscriptionsSelected &&
            this.packSubscriptionOrderHashesInProgress.includes(this.packSubscriptionsSelected.packSubscription.orderHash);
    }

    goToRevoke(packSubscriptionId: string) {
        this.doActionIfHasRole(
            () => this.router.navigate([`/main/subscriptions/revoke-options/${packSubscriptionId}`]),
            this.hasSubscriptionsWriteRole
        );
    }

    addOption(packSubscriptionId: string) {
        this.doActionIfHasRole(
            () => this.router.navigate([`/main/subscriptions/add-options/${packSubscriptionId}`]),
            this.hasSubscriptionsWriteRole
        );
    }

    newSubscription() {
        this.doActionIfHasRole(
            () => this.router.navigate([`/main/subscriptions/new-subscription`]),
            this.hasSubscriptionsWriteRole
        );
    }

    checkRoleForTokenInput() {
        this.doActionIfHasRole(
            () => this.showTokenInput = true,
            this.hasSubscriptionsWriteRole
        );
    }

    upgradePackSubscription(packSubscriptionId: string) {
        this.doActionIfHasRole(
            () => this.router.navigate([`/main/subscriptions/update/${packSubscriptionId}`]),
            this.hasSubscriptionsWriteRole
        );
    }
}

class PackSubscriptionWrapper {
    private _hasEndEngagement: boolean = undefined;

    packSubscription: PackSubscription;
    options: OptionSubscription[];
    canBeUpgrade: boolean;

    get hasEndEngagement(): boolean {
        if (this._hasEndEngagement === undefined) {
            this._hasEndEngagement = this.packSubscription
                && this.packSubscription.engagementEndDate
                && moment(this.packSubscription.engagementEndDate).isAfter(moment());
        }

        return this._hasEndEngagement;
    }

    get hasEngagedOptions(): number {
        return Object.values(this.packSubscription.subscriptionsEngagementEndDate).map(date => moment(date).isAfter(moment())
        ).length;
    }
}
