import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {FrontAccountHttpService} from 'lib-front';
import {catchError, map} from 'rxjs/operators';

export class EmailUniquenessValidator implements AsyncValidator {
    constructor(protected frontAccountHttpService: FrontAccountHttpService) {}

    validate(email: AbstractControl): Observable<ValidationErrors> {
        return this.frontAccountHttpService.loginExist(email.value).pipe(map((isUnique) => {
            return  isUnique ? null : {'emailAlreadyExists': true} ;
        }), catchError(() => {
            return of({'emailAlreadyExists': true});
        }));
    }
}
