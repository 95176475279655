import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-table-guide',
    templateUrl: './table-guide.component.html',
    styleUrls: ['./table-guide.component.scss']
})
export class TableGuideComponent {

    constructor() { }
}
