import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ExpenseReportChargeDto,
    ExpenseReportDetailDto,
    ExpenseReportHttpService,
    ExpenseReportIndexReadingDto,
    ExpenseReportStatusType,
    ExpenseReportType
} from 'lib-front';

@Component({
    selector: 'app-expense-report-detail',
    templateUrl: './expense-report-detail.component.html',
    styleUrls: ['./expense-report-detail.component.scss']
})
export class ExpenseReportDetailComponent implements OnInit {

    public expenseReportDetail: ExpenseReportDetailDto;
    public expenseReportCharge: ExpenseReportChargeDto;
    public expenseReportIndexReading: ExpenseReportIndexReadingDto;
    public expenseReportType = ExpenseReportType;
    public expenseReportStatusType = ExpenseReportStatusType;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private expenseReportHttpService: ExpenseReportHttpService) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(paramMap => {
            const expenseReportId = paramMap.get('id');

            if (expenseReportId) {
                this.getExpenseReportDetail(expenseReportId);
            }
        });
    }

    public backToSearch(): void {
        this.router.navigate(['main', 'expenseReport'], {queryParamsHandling: 'preserve'});
    }

    private getExpenseReportDetail(id: string): void {
        this.expenseReportHttpService.findById(id)
            .subscribe(expenseReportDetail => {
                this.expenseReportDetail = expenseReportDetail;
                this.setExpenseReportDetailSubClass();
            });
    }

    private setExpenseReportDetailSubClass(): void {
        this.expenseReportCharge = null;
        this.expenseReportIndexReading = null;

        switch (this.expenseReportDetail.type) {
            case ExpenseReportType.CHARGE:
                this.expenseReportCharge = this.expenseReportDetail as ExpenseReportChargeDto;
                break;
            case ExpenseReportType.INDEX_READING:
                this.expenseReportIndexReading = this.expenseReportDetail as ExpenseReportIndexReadingDto;
                break;
        }
    }
}
