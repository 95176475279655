<div class="grid-y has-space">
    <h3 class="grid-x align-middle secondary block-title notif">
        {{'config.permission.alertsAndNotificationsTitle' | translate}}
    </h3>
    <div *ngIf="!isEmptyConfiguration(userNotifications.notificationChannel)">
        <div>
            <div class="grid-x grid-margin-x channel-title">
                <notification class="cell small-12 grid-y grid-margin-x"
                              [(notificationsConfig)]="userNotifications.notificationChannel"
                              (notificationsConfigChange)="save($event)">
                </notification>
            </div>

            <div class="grid-x grid-margin-x triggers-container">
                <span class="cell small-12">
                    <div *ngIf="!isEmptyConfiguration(userNotifications.accountAndMediaTriggers)"
                         class="notification-group">
                        <div class="grid-x align-middle align-justify notification-group-label">
                             <label>
                                <span
                                    class="small-title dark-title">{{'config.permission.accountAndMediaNotifications' | translate}}</span>
                            </label>
                         <div class="grid-x align-right"
                              (click)="displayAccountAndMediaTriggers = !displayAccountAndMediaTriggers">
                             <div class="cell text-right" [class.hidden]="displayAccountAndMediaTriggers">
                                 <div class="moreDetail">
                                     <a>{{'stations.moreDetails' | translate}}</a>
                                     <i class="moreDetail-arrow fas fa-arrow-down"></i>
                                 </div>
                              </div>
                              <div class="cell text-right" [class.hidden]="!displayAccountAndMediaTriggers">
                                 <div class="moreDetail">
                                     <a>{{'stations.lessDetails' | translate}}</a>
                                     <i class="moreDetail-arrow fas fa-arrow-up"></i>
                                 </div>
                              </div>
                        </div>
                        </div>
                        <notification *ngIf="displayAccountAndMediaTriggers"
                                      class="notification-height"
                                      [(notificationsConfig)]="userNotifications.accountAndMediaTriggers"
                                      (notificationsConfigChange)="save($event)"
                        ></notification>
                    </div>
                    <div *ngIf="!isEmptyConfiguration(userNotifications.invoiceTriggers)" class="notification-group">
                        <div class="grid-x align-justify align-middle notification-group-label">
                              <label>
                                <span
                                    class="small-title dark-title">{{'config.permission.invoiceNotifications' | translate}}</span>
                            </label>
                          <div class="grid-x align-right"
                               (click)="displayInvoiceTriggers = !displayInvoiceTriggers">
                              <div class="cell text-right" [class.hidden]="displayInvoiceTriggers">
                                 <div class="moreDetail">
                                     <a>{{'stations.moreDetails' | translate}}</a>
                                     <i class="moreDetail-arrow fas fa-arrow-down"></i>
                                 </div>
                              </div>
                              <div class="cell text-right" [class.hidden]="!displayInvoiceTriggers">
                                   <div class="moreDetail">
                                     <a>{{'stations.lessDetails' | translate}}</a>
                                     <i class="moreDetail-arrow fas fa-arrow-up"></i>
                                 </div>
                              </div>
                        </div>
                        </div>
                        <notification *ngIf="displayInvoiceTriggers"
                                      [(notificationsConfig)]="userNotifications.invoiceTriggers"
                                      (notificationsConfigChange)="save($event)"
                                      class="notification-height"
                        ></notification>
                    </div>
                </span>
                <span class="cell small-12">
                    <div *ngIf="!isEmptyConfiguration(userNotifications.chargeTriggers)" class="notification-group">
                        <div class="grid-x align-justify align-middle notification-group-label">
                             <label>
                            <span
                                class="small-title dark-title">{{'config.permission.chargeNotifications' | translate}}</span>
                        </label>
                         <div class="grid-x align-right"
                              (click)="displayChargeTriggers = !displayChargeTriggers">
                             <div class="cell text-right" [class.hidden]="displayChargeTriggers">
                                     <div class="moreDetail">
                                     <a>{{'stations.moreDetails' | translate}}</a>
                                     <i class="moreDetail-arrow fas fa-arrow-down"></i>
                                 </div>
                              </div>
                              <div class="cell text-right" [class.hidden]="!displayChargeTriggers">
                                   <div class="moreDetail">
                                     <a>{{'stations.lessDetails' | translate}}</a>
                                     <i class="moreDetail-arrow fas fa-arrow-up"></i>
                                 </div>
                              </div>
                        </div>
                        </div>
                        <notification *ngIf="displayChargeTriggers"
                                      [(notificationsConfig)]="userNotifications.chargeTriggers"
                                      (notificationsConfigChange)="save($event)"
                                      class="notification-height"
                        ></notification>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="isEmptyConfiguration(userNotifications.notificationChannel)">
        {{'config.notifications.none' | translate}}
    </div>
</div>
