<div class="cell">
    <span *ngIf="sumCarryOverTime > 0" class="note">
        {{'home.subscriptions.carryovertime' | translate: {
        carryOverTime: sumCarryOverTime | fleetDuration:{
            seconds: false,
            minutes: true
        }
    } }}
    </span>
    <progress-bar [isBarAlert]="isBarAlert" [fillBar]="isBarAlert" [consume]="consumePercent" [color]="isBarAlert ? 'red' : 'green'"></progress-bar>
    <p [hidden]="!isBarAlert" class="progress-alert">{{'subscription.pack.option.conso.alert' | translate}}</p>
    <div *ngIf="resetDate" class="note">
        {{'home.subscriptions.reset' | translate: {date: resetDate | date : 'shortDate'} }}
    </div>
</div>
