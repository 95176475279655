import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import Swal, {SweetAlertIcon, SweetAlertInput} from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AlertService {
    private translateService: TranslateService;

    constructor(translateService: TranslateService) {
        this.translateService = translateService;
    }

    public confirm(message: string, title?: string, confirmText?: string, cancelText?: string): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            Swal.fire({
                titleText: title,
                html: message,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: confirmText ? confirmText : this.translateService.instant('alert.ok'),
                cancelButtonText: cancelText ? cancelText : this.translateService.instant('common.cancel')
            }).then(result => {
                observer.next(!!result.value);
                observer.complete();
            });
        });
    }

    public confirmWithWarning(message: string,
        warningMessage?: string,
        title?: string,
        confirmText?: string,
        cancelText?: string
    ): Observable<boolean> {

        const fullMessage = warningMessage ?
            `<div class="sweet-content">${message}</div><div class="sweet-content-alert">${warningMessage}</div>` :
            message;

        return this.confirm(fullMessage, title, confirmText, cancelText);
    }

    public inputConfirm(message: string, title?: string, confirmText?: string, placeHolder?: string, cancelText?: string,
        errorText?: string, type?: SweetAlertIcon, inputType?: SweetAlertInput, maxTextSize?: number,
        errorMaxSizeReached?: string, inputCustomClass?: string): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            Swal.fire({
                title: title,
                html: message,
                input: inputType ? inputType : 'text',
                inputAttributes: {
                    maxlength: maxTextSize ? (maxTextSize + 1).toString() : null
                },
                icon: type,
                inputPlaceholder: placeHolder,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: confirmText ? confirmText : this.translateService.instant('alert.ok'),
                cancelButtonText: cancelText ? cancelText : this.translateService.instant('common.cancel'),
                customClass: {
                    input: inputCustomClass
                },
                inputValidator: (value) => {
                    if (!value) {
                        return errorText;
                    } else if (value && maxTextSize && errorMaxSizeReached && value.length > maxTextSize) {
                        return errorMaxSizeReached;
                    }
                }
            }).then(result => {
                observer.next(result.value);
                observer.complete();
            });
        });
    }

    public show(message: string, title?: string, confirmText?: string): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            Swal.fire({
                titleText: title,
                html: message,
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: confirmText ? confirmText : this.translateService.instant('alert.ok')
            }).then(() => {
                observer.next();
                observer.complete();
            });
        });
    }
}

