import {Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {debounceTime, map} from 'rxjs/operators';
import {fromEvent, merge} from 'rxjs';

@Component({
    selector: 'input-number-button',
    templateUrl: './input-number-button.component.html',
    styleUrls: ['./input-number-button.component.scss'],
    host : {class : 'cell grid-x'},
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputNumberButtonComponent),
        multi: true
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => InputNumberButtonComponent),
        multi: true
    }]
})
export class InputNumberButtonComponent implements ControlValueAccessor, Validator, OnInit {
    // Beware of the use of static: true
    // #input must NOT be used within *ngIf or *ngFor in the template otherwise you should change static to false
    // and wait for ngAfterViewInit to use it
    @ViewChild('input', { static: true }) input: ElementRef;

    @Input() min: number;
    @Input() max: number;
    @Input() placeholder: string;

    idDisabled: boolean;

    onChange: (v: number) => void;
    onTouched: (v: number) => void;

    constructor() { }

    public ngOnInit(): void {
        merge(fromEvent(this.input.nativeElement, 'input'),
            fromEvent(this.input.nativeElement, 'wheel'))
            .pipe(map(event => ((event as Event).currentTarget) as HTMLInputElement),
                map(element => element.value),
                debounceTime(200))
            .subscribe(value => {
                let valueNumber: number = Number(value);
                if (value === '' || isNaN(valueNumber)) {
                    value = null;
                } else if (value || valueNumber === 0) {
                    if ((this.min || this.min === 0) && valueNumber < this.min) {
                        this.input.nativeElement.value = this.min;
                        valueNumber = this.min;
                    } else if ((this.max || this.max === 0) && valueNumber > this.max) {
                        this.input.nativeElement.value = this.max;
                        valueNumber = this.max;
                    }
                }

                this.onChange(valueNumber);
            });
    }

    writeValue(value: number): void {
        if (value === 0) {
            this.input.nativeElement.value = '0';
        } else {
            this.input.nativeElement.value = value || null;
        }
    }

    registerOnChange(fn: (v: number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.idDisabled = isDisabled;
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        const value = this.input.nativeElement.value;
        const errors: ValidationErrors = {};
        let isError = false;

        if (this.min !== undefined && this.min !== null && value < this.min) {
            isError = true;
            errors.min = true;
        }

        if (this.max !== undefined && this.max !== null && this.max && value > this.max) {
            isError = true;
            errors.max = true;
        }

        return isError ? errors : null;
    }

    plus() {
        if (this.input.nativeElement.value && (this.max === undefined || (+this.input.nativeElement.value) + 1 <= this.max)) {
            this.input.nativeElement.value++;
            this.onChange(Number(this.input.nativeElement.value));
        } else if (!this.input.nativeElement.value) {
            this.input.nativeElement.value = this.min || 0;
            this.onChange(Number(this.input.nativeElement.value));
        }
    }

    minus() {
        if (this.input.nativeElement.value && (this.min === undefined || (+this.input.nativeElement.value) - 1 >= this.min)) {
            this.input.nativeElement.value--;
            this.onChange(Number(this.input.nativeElement.value));
        } else if (!this.input.nativeElement.value) {
            this.input.nativeElement.value = this.min || 0;
            this.onChange(Number(this.input.nativeElement.value));
        }
    }

    isValid() {
        return this.input.nativeElement.value > this.min;
    }
}
