import {Component, Input} from '@angular/core';
import {PlugTypeDto, StationDto} from 'lib-front';

@Component({
    selector: 'charge-points-card',
    templateUrl: './charge-points-card.component.html',
    styleUrls: ['./charge-points-card.component.scss']
})
export class ChargePointsCardComponent {
    @Input() station: StationDto;
    @Input() selected: boolean;

    constructor() {
    }

    trackByPlugTypeName(index: number, plugType: PlugTypeDto) {
        return plugType?.name ?? index;
    }
}
