import {Component, Input, OnInit} from '@angular/core';
import {FILE_API_PATH, OptionSubscription, PackSubscription, SubscriptionPack} from 'lib-front';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'subscription-offer-view',
    templateUrl: './subscription-offer-view.component.html',
    styleUrls: ['./subscription-offer-view.component.scss'],
})
export class SubscriptionOfferViewComponent {

    @Input() mode: ModeSubscriptionOfferView;
    @Input() showEndEngagement: boolean;
    @Input() mediasOrderQuantity: number = 0;
    @Input() compatibleMediaQuantity: number = 0;
    public showSubscriptionPackPrice: boolean = true;

    @Input('packSubscription') set packSubscriptionInput(packSubscription) {
        this.packSubscription = packSubscription;
        this.subscriptionPack = packSubscription?.subscriptionPack;
        this.showSubscriptionPackPrice = this.isFieldNullOrUndefined(packSubscription?.subscriptionPack?.overloadedFixPriceLabel);
        this.computeTerminationDate();
    }

    @Input('subscriptionPack') set subscriptionPackInput(subscriptionPack) {
        this.subscriptionPack = subscriptionPack;
        this.calculEngagementEndDate();
        this.showSubscriptionPackPrice = this.isFieldNullOrUndefined(subscriptionPack.overloadedFixPriceLabel);
    }

    @Input() options: OptionSubscription[];

    packSubscription: PackSubscription;
    subscriptionPack: SubscriptionPack;
    engagementEndDate: string;
    terminationDate: string | undefined = undefined;

    constructor(private translateService: TranslateService) {
    }

    public passRetportedAndOrdered(): String {
        const numberPassReported = this.subscriptionPack.maxMediasByAccount === 0
        || (this.compatibleMediaQuantity <= this.subscriptionPack.maxMediasByAccount)
            ? this.compatibleMediaQuantity
            : this.subscriptionPack.maxMediasByAccount;
        if (!!this.mediasOrderQuantity) {
            return this.translateService.instant('subscription.pack.pass.number.report',
                {passReport: !!numberPassReported ? numberPassReported : 0, passOrder: this.mediasOrderQuantity});
        } else {
            return this.translateService.instant('subscription.pack.pass.number.reportNoOrder',
                {passReport: !!numberPassReported ? numberPassReported : 0});
        }
    }

    calculEngagementEndDate() {
        this.engagementEndDate = null;
        if (this.subscriptionPack && this.subscriptionPack.engagementDuration
            && this.subscriptionPack.engagementDuration !== 0) {
            const date = new Date();
            date.setMonth(date.getMonth() + this.subscriptionPack.engagementDuration);
            this.engagementEndDate = date.toDateString();
        }
    }

    getCGUUrl(termsAndConditionsI18nCode: string): string {
        return FILE_API_PATH + `${termsAndConditionsI18nCode}?lang=fr`;
    }

    private isFieldNullOrUndefined(field: any): boolean {
        return field === null || field === undefined;
    }

    private computeTerminationDate(): void {
        if (this.packSubscription?.terminationScheduled) {
            const terminationDate = new Date(Date.parse(this.packSubscription.engagementEndDate));
            terminationDate.setDate(terminationDate.getDate() + 1);
            this.terminationDate = terminationDate.toDateString();
        }
    }
}

export enum ModeSubscriptionOfferView {
    SUBSCRIPTION = 'SUBSCRIPTION',
    UPDATE_OLD = 'UPDATE_OLD',
    UPDATE_NEW = 'UPDATE_NEW'
}
