import {NgModule} from '@angular/core';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationService} from './notification.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AlertService} from './alert.service';
import {InactivityService} from './inactivity.service';
import {RepeatService} from './repeat.service';

@NgModule({
    imports: [
        TranslateModule,
        ToastrModule,
        BrowserAnimationsModule
    ],
    providers: [
        NotificationService,
        AlertService,
        InactivityService,
        RepeatService
    ]
})
export class UtilsModule {
}
