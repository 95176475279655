import {Route} from '@angular/router';
import {ChargeAreasComponent} from './charge-areas.component';
import {StationComponent} from './station/station.component';
import {ChargeAreaEditView} from './charge-area-edit/charge-area-edit.view';
import {ChargeAreaReadRoleGuard} from './charge-area-read-role.guard';
import {ChargeAreaWriteRoleGuard} from './charge-area-write-role.guard';

export const chargeAreasRoutes: Array<Route> = [
    {path: '', canActivate: [ChargeAreaReadRoleGuard], component: ChargeAreasComponent},
    {path: ':chargeAreaId', component: ChargeAreasComponent},
    {path: ':chargeAreaId/edit', canActivate: [ChargeAreaWriteRoleGuard], component: ChargeAreaEditView},
    {path: ':chargeAreaId/stations/:stationId', component: StationComponent}
];
