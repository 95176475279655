import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {DetailedPersonDto, FrontPersonHttpService, FrontSessionBusinessService} from 'lib-front';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

@Injectable()
export class CurrentDetailedPersonResolver implements Resolve<DetailedPersonDto> {
    constructor(private readonly frontPersonHttpService: FrontPersonHttpService,
        private readonly sessionBusinessService: FrontSessionBusinessService) {
    }

    resolve(): Observable<DetailedPersonDto> {
        return this.sessionBusinessService.currentConnectedUser$.pipe(
            filter(connectedUser => !!connectedUser),
            map(user => user.user._id),
            switchMap(userId => this.frontPersonHttpService.findDetailedPerson(userId))
        );
    }
}
