import {Component, Input, OnInit} from '@angular/core';
import {OptionsResult} from '../update-subscription.component';
import {PackSubscription, SubscriptionPack} from 'lib-front';
import {MediaOrderDto} from '../../../../../../domain/mediaOrderDto';
import {ModeSubscriptionOfferView} from '../../../../../../components/subscription-offer-view/subscription-offer-view.component';

@Component({
    selector: 'update-subscription-summary',
    templateUrl: './update-subscription-summary.component.html',
    styleUrls: ['./update-subscription-summary.component.scss']
})
export class UpdateSubscriptionSummaryComponent {
    ModeSubscriptionOfferView = ModeSubscriptionOfferView;

    @Input() optionResult: OptionsResult;

    @Input() subscriptionPack: SubscriptionPack;

    @Input() orderMedias: MediaOrderDto;

    @Input() packSubscription: PackSubscription;

    @Input() compatibleMedia: number;

    constructor() {
    }
}
