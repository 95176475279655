import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function carRegistrationFileExtensionValidator(fileFormats: string[]): ValidatorFn {
    return (fileUploadControl: AbstractControl): ValidationErrors | null => {
        const files = fileUploadControl.value;
        if (files && files.length) {
            const fileExtension = files[0].type;
            return fileFormats.includes(fileExtension) ? null : {fileExtensionInvalid: true};
        }
        return null;
    };
}
