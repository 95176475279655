import {Component, Input, OnInit} from '@angular/core';
import {TabsComponent} from './tabs.component';

@Component({
    selector: 'tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss']
})
export class TabComponent {
    @Input()
    public title: string;

    @Input()
    public hidden: boolean;

    @Input()
    public changeUrl: string;

    public selected: boolean = false;

    constructor(tabs: TabsComponent) {
        tabs.registerTab(this);
    }
}
