<ng-select
    [items]="iconOptions"
           [ngModel]="icon"
           (ngModelChange)="onChange($event)"
           [disabled]="disabled"
           bindLabel="class"
           bindValue="value"
           [searchable]="false"
           [clearable]="false"
           (open)="onOpen()"
           (close)="onClose()"
           [required]="required">
    <ng-template ng-label-tmp let-item="item">
        <ng-container *ngIf="item">
            <i [class]="item.class"></i>
        </ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
        <i [class]="'icon ' + item.class"></i>
        {{ "config.permission.roles.level." + item.value | translate}}
    </ng-template>
</ng-select>
