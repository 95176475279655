<div class="cell auto app-view-content">
    <div class="grid-y main-container">
        <h2 class="cell space-all-16 space-bottom-8">{{'subscription.pack.options.revoke.title' | translate}}</h2>
        <form class="grid-x cell space-left-16 space-right-16">
            <subscription-options [mode]="ModeSubscriptionOption.REVOKE" name="revokeOption"
                                  [(ngModel)]="optionsRevocable" (ngModelChange)="calculateTotal()"
                                  (optionSelected)="viewSubscription($event)"></subscription-options>
        </form>
        <div class="grid-y cell space-left-30 space-right-30 grid-padding-x space-bottom-30 card-divider"
             [hidden]="!(optionsOfTab && optionsOfTab.length)">
            <div class="grid-x align-justify grid-margin-x grid-padding-x align-middle space-bottom-8">
                <h5 class="cell shrink" *ngIf="optionsOfTab && optionsOfTab.length">{{'option.history' | translate:{name : optionsOfTab[0].subscriptionDef.name} }}</h5>
                <div class="cell shrink">
                    <button type="button" class="small button secondary" (click)="closeTab()">
                        {{'option.close' | translate}}
                    </button>
                </div>
            </div>
            <div class="cell">
                <table>
                    <thead>
                    <tr>
                        <th>{{'option.start' | translate}}</th>
                        <th>{{'option.quantity' | translate}}</th>
                        <th>{{'options.endEngagement' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody class="border">
                    <tr *ngFor="let option of optionsOfTab; trackBy: trackBySubscriptionId">
                        <td>{{option.startDate | date : 'short'}}</td>
                        <td>{{option.quantity}}</td>
                        <td [hidden]="!option.engagementEndDate">{{option.engagementEndDate | date : 'shortDate'}}</td>
                        <td [hidden]="option.engagementEndDate">{{'option.no.engagement' | translate}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="cell shrink grid-x space-bottom-32 space-left-16 space-right-16">
            <h3 class="cell secondary block-title space-all-8">
                <i class="fas fa-tag block-title-icon"></i>
                {{'options.revoke.total' | translate}}
            </h3>
            <div class="cell grid-x">
                <div class="grid-x cell auto align-center subscription-result-block">
                    <div class="grid-y shrink subscription-result-block-item time align-middle">
                        {{totalHours | fleetDuration:{seconds: false, minutes: true} }}
                    </div>
                    <div class="grid-y shrink subscription-result-block-item price align-middle" *ngIf="!!options">
                        <span class="cell">{{totalPrice | currency:'EUR': options[0]?.subscription?.subscriptionDef?.currencyUnit || ''}}</span>
                        <span class="cell text-center force-font-bold font-size-14">{{'register.subscription.pack.by.month'| translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="cell auto grid-x align-bottom align-justify space-bottom-10 fix-action-bar">
            <div class="grid-x cell auto align-right align-middle action-bar add-space-block">
                <div class="grid-x">
                    <a class="button secondary cell shrink force-space-right-10" [routerLink]="'/main/subscriptions'"
                       [ngClass]="{disabled: loading}">
                        {{'common.cancel' | translate}}
                    </a>
                    <button class="button primary cell shrink force-space-right-15"
                            [iziviaLoading]="loading"
                            [disabled]="!hasRevocableOptions"
                            (click)="revokeOptions()">{{'option.revoke.button.validate' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
