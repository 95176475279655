<form #form="ngForm" (keydown.enter)="$event.preventDefault()">
    <div class="grid-x align-middle">
        <img *ngIf="mediaOrderForm?.mediaCanOrderDto !== null"
             class="cell shrink media-order-img space-right-32"
             [src]="'/api/download/' + mediaOrderForm.mediaCanOrderDto.mediaOrderSubscriptionPackDtos[0].visual.logoRef"
             alt="media visual">
        <div class="cell auto grid-x">
            <label class="cell auto space-right-32"
                   *ngIf="mediaOrderForm?.mediaCanOrderDto && mediaCanOrderDtoList?.length > 1">
                <span>{{'media.order.type' | translate}}</span>
                <select [ngModel]="mediaOrderForm.mediaCanOrderDto"
                        (ngModelChange)="onMediaTypeChange($event)"
                        name="mediaCanOrderDto"
                        (select)="setMediaCanOrderDto(mediaOrderForm.mediaCanOrderDto)">
                    <option *ngFor="let mediaCanOrderDto of mediaCanOrderDtoList"
                            [ngValue]="mediaCanOrderDto">
                        {{mediaCanOrderDto.mediaVisualName}}
                    </option>
                </select>
            </label>

            <ng-container *ngIf="mediaOrderForm">
                <label class="cell small-4 xxxlarge-shrink space-right-60 inputContainer"
                       [ngClass]="{required : minProduced > 0, validate : minProduced > 0 && form.controls.quantity?.valid}">
                    <span>{{'media.order.quantity.title' | translate}}</span>
                    <input-number-button name="quantity" placeholder="0"
                                         [min]="minProduced || 0"
                                         [max]="maxProduced || undefined"
                                         [(ngModel)]="mediaOrderForm.quantity" #quantityCtrl="ngModel"
                                         (ngModelChange)="computePrice()"
                                         [required]="true"></input-number-button>
                    <div [hidden]="!(quantityCtrl.invalid && quantityCtrl.dirty)">
                        <span [hidden]="!quantityCtrl.errors?.required"
                              class="error note">{{'media.order.quantity.required' | translate}}</span>
                        <span [hidden]="!quantityCtrl?.errors?.min"
                              class="error note">{{'media.order.quantity.min' | translate:{min: minProduced || 0} }}</span>
                        <span [hidden]="!quantityCtrl?.errors?.max"
                              class="error note">{{'media.order.quantity.max' | translate:{max: maxProduced} }}</span>
                    </div>
                    <span class="error note" [hidden]="(quantityCtrl.dirty && quantityCtrl?.errors?.min)
                                          || !minProduced
                                          || mediaOrderContext !== MediaOrderContext.REGISTER">
                            {{'media.order.min' | translate:{min: minProduced} }}
                    </span>
                    <span class="error note" [hidden]="quantityCtrl.dirty && quantityCtrl?.errors?.max">
                            {{'media.order.max' | translate:{max: maxProduced} }}
                    </span>
                </label>
            </ng-container>

            <div class="grid-y cell shrink" *ngIf="mediaOrderContext === MediaOrderContext.REGISTER">
                <div class="grid-x cell align-middle align-justify">
                    <label
                        class="cell shrink inner-space-bottom-0">{{'media.order.price.offer' | translate}}</label>
                    <div class="grid-x cell small-8 media-order-price-container">
                            <span
                                class="cell auto media-order-price large">{{mediaOrderForm?.emoMediaOrderPrice?.bestPrice | currency:'EUR':''}}</span>
                        <span
                            class="cell shrink media-order-euro large">{{mediaOrderForm?.emoMediaOrderPrice?.currencyUnit}}
                            TTC</span>
                    </div>
                </div>
                <div class="grid-x cell align-middle align-justify space-top-8" [hidden]="mediaOrderForm?.emoMediaOrderPrice?.bestPrice === 0
    || mediaOrderForm?.emoMediaOrderPrice?.bestPrice >= mediaOrderForm?.emoMediaOrderPrice?.price">
                    <label
                        class="cell shrink small">{{'media.order.price.without.offer' | translate}}</label>
                    <div class="grid-x cell small-8 media-order-price-container">
                            <span
                                class="cell auto media-order-price disabled">{{mediaOrderForm?.emoMediaOrderPrice?.price | currency:'EUR':''}}</span>
                        <span
                            class="cell shrink media-order-euro disabled">{{mediaOrderForm?.emoMediaOrderPrice?.currencyUnit}}
                            TTC</span>
                    </div>
                </div>
            </div>
            <media-order-price-info class="cell small-12 xxlarge-10 xxxlarge-8 priceInfo"
                                    *ngIf="mediaOrderContext !== MediaOrderContext.REGISTER"
                                    [mediaOrderInfo]="mediaOrderInfo"
                                    [promoSubscriptionPack]="promoSubscriptionPack"
                                    [priceInfo]="mediaOrderForm?.emoMediaOrderPrice">

            </media-order-price-info>
        </div>
    </div>
    <div *ngIf="mediaOrderContext === MediaOrderContext.REGISTER &&
    mediaOrderForm?.mediaCanOrderDto &&
    frontEndInfo.fleetConfig.returnCostsDisplayed">
        <h6>{{'media.order.returnCosts' | translate: {returnCosts: frontEndInfo.fleetConfig.returnCosts} }}</h6>
    </div>

    <promo-token-input
        *ngIf="promoTokenAllowed"
        class="cell"
        [canCancelToken]="false"
        [otherSubscriptionPackRefs]="getMediaOrderSubscriptionPackIds()"
        [subscriptionMode]="SubscriptionMode.client_media_order"
        [email]="user?.email"
        (promoTokenChange)="promoTokenChanged($event)"></promo-token-input>

    <media-order-promo *ngIf="promoSubscriptionPack"
                       [promoSubscriptionPack]="promoSubscriptionPack">

    </media-order-promo>
</form>
