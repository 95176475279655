<div class="grid-y align-center-middle cell auto cell-block-y">
    <div #scrollTop id="registerTop" class="grid-y app-view-content">
        <div class="cell shrink register-header">
            <h2 class="cell shrink text-center">{{'register.title' | translate}}</h2>
            <step-indicator class="cell shrink" [current]="currentStep">
                <step class="text-uppercase cell shrink">{{'register.informations.title' | translate}}</step>
                <step class="text-uppercase cell shrink">{{'register.subscription.title' | translate}}</step>
                <step class="text-uppercase cell shrink">{{'register.media.title' | translate}}</step>
                <step class="text-uppercase cell shrink"
                      *ngIf="isPaymentStepActivated">{{'register.payment.title' | translate}}</step>
            </step-indicator>
        </div>
        <register-information class="cell shrink" *ngIf="currentStep"
                              [hidden]="currentStep !== 1" (userEvent)="onInformation($event)"></register-information>
        <register-subscription class="cell shrink" *ngIf="currentStep"
                               [hidden]="currentStep !== 2"
                               (subscriptionEvent)="onSubscription($event)" (back)="goToBack()"></register-subscription>
        <register-fleet class="cell shrink"
                        *ngIf="currentStep && subscriptionsInformation?.subscriptionPackOrTokenWrapper"
                        [hidden]="currentStep !== 3"
                        [subscriptionPack]="subscriptionsInformation?.subscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder"
                        (fleetEvent)="onFleet($event)"
                        (back)="goToBack()"></register-fleet>
        <register-payment class="cell shrink" *ngIf="currentStep && isPaymentStepActivated"
                          [hidden]="currentStep !== 4"
                          [account]="accountFormValue"
                          [subscription]="subscriptionsInformation"
                          [mediaOrder]="fleetInformation?.mediaOrder"
                          [emoRef]="fleetInformation?.mediaOrder?.emoMediaOrderPrice?.emoRef"
                          [disabled]="disablePaymentComponent"
                          [isAccountCreationProcessSecured]="isAccountCreationProcessSecured"
                          [isCurrentStep]="currentStep === 4"
                          (back)="goToBack()"
                          (promoTokenChange)="onPromoToken($event)"
                          (paymentEvent)="onPayment($event)"
                          (paymentConfigEvent)="onPaymentConfig($event)"></register-payment>
        <payment-options class="cell shrink" *ngIf="invoiceForm"
                         [payment-request-infos]="invoiceForm"></payment-options>
    </div>
</div>
