import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable, of, timer} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {FrontAccountHttpService} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {EmailUniquenessValidator} from './emailUniquenessValidator';

@Directive({
    selector: '[iziviaEmailUniquenessValidatorDirective]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: EmailUniquenessValidatorCurrentUserDirective,
        multi: true
    }]
})
export class EmailUniquenessValidatorCurrentUserDirective extends EmailUniquenessValidator {
    @Input() sameEmail: boolean;

    private isAnonymous;
    private email;
    private previousEmail;

    constructor(protected frontAccountHttpService: FrontAccountHttpService, route: ActivatedRoute ) {
        super(frontAccountHttpService);
        route.data.subscribe(data => {
            this.isAnonymous = !data.user;
            if (!this.isAnonymous) {
                this.email = data.user.user.email;
            }
        });
    }

    validate(email: AbstractControl): Observable<ValidationErrors> {
        if (this.email !== email.value) {
            // we must use a timer instead of "debounceTime" because we need an observable source, and we cannot use
            // the AbstractControl observables due to them being used during the validation (potential deadlock)
            return timer(500).pipe(
                filter(() => email.value !== this.previousEmail),
                switchMap(() => super.validate(email)),
                tap(() => this.previousEmail = email.value)
            );
        } else {
            return of(null);
        }
    }
}
