<form #addressForm="ngForm">
    <!--Check Billing Address-->
    <div class="cell shrink" *ngIf="foAccount && (mode === 'BILLING' || mode === 'BILLING_COMPANY')">
        <div class="grid-x grid-padding-x" *ngIf="mode === 'BILLING_COMPANY'">
            <label class="cell small-24 xxlarge-12 margin-label bottom required"
                   [ngClass]="{validate : !!companyName.valid}">
                <span class="labelName">{{'informations.administrator.companyName' | translate}}</span>
                <input type="text" [(ngModel)]="foAccount.companyName" name="companyName"
                       #companyName="ngModel"
                       [required]="true">
            </label>
            <siret-input class="cell small-24 xxlarge-12 grid-y margin-label bottom"
                        [(ngModel)]="foAccount.companySiret"
                         name="siret"
                         [required]="siretMandatory">
            </siret-input>
        </div>

        <div class="grid-x">
            <div class="grid-y cell small-24 medium-12">
                <address-form-wrapper [address]="foAccount.billing?.address"
                                         [required]="true"
                                         (formAddressValidChange)="onAddressValidityChange($event)"
                                         class="register-form-part business">
                </address-form-wrapper>
            </div>
            <div class="grid-y cell  small-24 medium-12">
                <div class="register-form-part profile">
                    <div class="grid-x cell margin-label bottom">
                        <div class="cell small-12">
                            <label class="margin-label right required"
                                   [ngClass]="{validate : !!billingFirstName.valid}">
                                <span class="labelName">{{'informations.billing.firstName' | translate}}</span>
                                <input type="text" [(ngModel)]="foAccount?.billing.firstName"
                                       name="billingFirstName"
                                       #billingFirstName="ngModel"
                                       [required]="true">
                            </label>
                        </div>
                        <label class="cell small-12 required" [ngClass]="{validate : !!billingLastName.valid}">
                            <span>{{'informations.billing.lastName' | translate}}</span>
                            <input type="text" [(ngModel)]="foAccount?.billing.lastName" name="billingLastName"
                                   #billingLastName="ngModel"
                                   [required]="true">
                        </label>
                    </div>
                    <div class="grid-x cell margin-label bottom">
                        <div class="cell small-12">
                            <label class="margin-label right required"
                                   [ngClass]="{validate : !!billingEmail.valid, invalid : !billingEmail.valid && billingEmail?.dirty}">
                                <span class="cell shrink labelName">{{'informations.billing.email' | translate}}</span>
                                <input type="email" iziviaEmailValidator="billingEmail"
                                       [(ngModel)]="foAccount?.billing.email"
                                       name="billingEmail"
                                       #billingEmail="ngModel" [required]="true">
                                <div class="cell error note"
                                     [hidden]="!(billingEmail.invalid && billingEmail?.dirty)">
                                    {{'informations.administrator.email.incorrect' | translate}}
                                </div>
                            </label>
                        </div>
                        <div class="cell small-12">
                            <label class="required"
                                   [ngClass]="{validate : !!billingPhoneNumber.valid, invalid : !billingPhoneNumber.valid && billingPhoneNumber?.dirty}">
                                <span class="labelName">{{'informations.billing.phoneNumber' | translate}}</span>

                                <int-phone-number-input
                                    inputId="billingPhoneNumber"
                                    [(ngModel)]="foAccount?.billing.phoneNumber"
                                    [required]="true"
                                    name="billingPhoneNumber"
                                    #billingPhoneNumber="ngModel"
                                ></int-phone-number-input>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <envelope
        *ngIf="foAccount?.billing && (mode === ModeAddressForm.BILLING || mode === ModeAddressForm.BILLING_COMPANY || mode === ModeAddressForm.BILLING_ENVELOPE)"
        [(ngModel)]="envelopeValidated"
        [person-address]="foAccount.billing"
        name="envelopeBilling">
    </envelope>

    <!--Check Delivery Address-->
    <div class="cell shrink" *ngIf="foAccount && mode === 'DELIVERY'">

        <div class="grid-x">
            <div class="grid-y cell  small-24 medium-12">
                <address-form-wrapper [address]="foAccount.delivery?.address"
                                         [required]="true"
                                         (formAddressValidChange)="onAddressValidityChange($event)"
                                         class="register-form-part business">
                </address-form-wrapper>
            </div>
            <div class="grid-y cell  small-24 medium-12">
                <div class="register-form-part profile">
                    <div class="grid-x cell margin-label bottom">
                        <div class="cell small-12">
                            <label class="margin-label right required"
                                   [ngClass]="{validate : !!deliveryFirstName.valid}">
                                <span class="labelName">{{'informations.delivery.firstName' | translate}}</span>
                                <input type="text" [(ngModel)]="foAccount?.delivery.firstName"
                                       name="deliveryFirstName"
                                       #deliveryFirstName="ngModel"
                                       [required]="true">
                            </label>
                        </div>
                        <label class="cell small-12 required" [ngClass]="{validate : !!deliveryLastName.valid}">
                            <span class="labelName">{{'informations.delivery.lastName' | translate}}</span>
                            <input type="text" [(ngModel)]="foAccount?.delivery.lastName" name="deliveryLastName"
                                   #deliveryLastName="ngModel"
                                   [required]="true">
                        </label>
                    </div>
                    <div class="grid-x cell margin-label bottom">
                        <div class="cell small-12">
                            <label class="margin-label right required"
                                   [ngClass]="{validate : !!deliveryEmail.valid, invalid : !deliveryEmail.valid && deliveryEmail?.dirty}">
                                <span class="cell shrink labelName">{{'informations.delivery.email' | translate}}</span>
                                <input type="email" iziviaEmailValidator="deliveryEmail"
                                       [(ngModel)]="foAccount?.delivery.email"
                                       name="deliveryEmail"
                                       #deliveryEmail="ngModel" [required]="true">
                                <div class="cell error note"
                                     [hidden]="!(deliveryEmail.invalid && deliveryEmail?.dirty)">
                                    {{'informations.administrator.email.incorrect' | translate}}
                                </div>
                            </label>
                        </div>
                        <div class="cell small-12">
                            <label class="required labelName"
                                   [ngClass]="{validate : !!deliveryPhoneNumber.valid, invalid : !deliveryPhoneNumber.valid && deliveryPhoneNumber?.touched}">
                                <span>{{'informations.delivery.phoneNumber' | translate}}</span>
                                <int-phone-number-input
                                    inputId="deliveryPhoneNumber"
                                    name="deliveryPhoneNumber"
                                    #deliveryPhoneNumber="ngModel"
                                    [(ngModel)]="foAccount?.delivery.phoneNumber"
                                    [required]="true"
                                ></int-phone-number-input>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <envelope
        *ngIf="foAccount?.delivery && (mode === ModeAddressForm.DELIVERY || mode === ModeAddressForm.DELIVERY_ENVELOPE)"
        [person-address]="foAccount.delivery"
        [(ngModel)]="envelopeValidated"
        [social-reason]="foAccount.socialReason"
        name="envelopeBilling">
    </envelope>
</form>
