<div class="grid-y has-space">
    <div class="grid-x align-middle secondary block-title">
        <h3 class="secondary">
            {{'config.faq.header' | translate}}
        </h3>
    </div>

    <div class=" cell search-label space-top-16 space-bottom-24">
        <input #searchField
               class="search cell auto force-space-right-16" type="text"
               placeholder="{{'config.faq.search.placeholder' | translate}}"
               [(ngModel)]="faqSearchText">
        <i class="fas fa-search search-icon"></i>
    </div>

    <faq-breadcrumbs
        *ngIf="currentFaqNode"
        [faqNode]="currentFaqNode"
        (faqNodeClicked)="loadFaqNode($event)"></faq-breadcrumbs>

    <div class="cell small-24 space-top-8">
        <faq-icon class="cell"
                  *ngFor="let faqNode of faqNodesToDisplay"
                  [faqNode]="faqNode"
                  (click)="loadFaqNode(faqNode.id)"></faq-icon>
    </div>

    <faq-page class="space-left-16" *ngIf="!loading && isFaqPageDetail(currentFaqNode)"
              [faqPage]="currentFaqNode"></faq-page>

    <div class="grid-y cell auto align-center align-middle message-empty"
         *ngIf="!loading && !currentFaqNode && faqNodesToDisplay.length === 0">
        <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
        <span class="message-empty-label">{{'config.faq.search.noResult' | translate}}</span>
    </div>

    <div *ngIf="loading"
         class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>

</div>

