<div class="text-center">
    <i class="popup-icon fas fa-exclamation"></i>
    <h3 class="popup-title">{{'config.tariff.'+ (deletion ? 'deletePopup' : 'modification.popup.applicationDate') +'.title' | translate:{tariffName: tariffName} }}</h3>

    <div class="popup-content">


        <div *ngIf="deletion">
            <div *ngIf="isPrimoTariff || nbSubscribers === 0">

                <h6 *ngIf="linkedStations && linkedStations.length && linkedStations.length < 5"
                    class="popup-content_text space-bottom-24 space-top-16">
                    {{('config.tariff.deletePopup.textWithStations' | translate: {
                    stationNb: linkedStations.length,
                    stationListString: getStationListString()
                })}}
                </h6>

                <h6 *ngIf="linkedStations && linkedStations.length ===0 || linkedStations.length > 4"
                    class="popup-content_text space-bottom-24 space-top-16">
                    {{('config.tariff.deletePopup.textWithoutStations' | translate: {
                    stationNb: linkedStations.length
                })}}
                </h6>

            </div>

            <div *ngIf="!isPrimoTariff && nbSubscribers > 0">

                <h6 *ngIf="linkedStations && linkedStations.length && linkedStations.length < 5"
                    class="popup-content_text space-bottom-24 space-top-16">
                    {{('config.tariff.deletePopup.textWithStationsAndUsers' | translate: {
                    stationNb: linkedStations.length,
                    stationListString: getStationListString(),
                    nbSubscribers: nbSubscribers
                })}}
                </h6>

                <h6 *ngIf="linkedStations && linkedStations.length ===0 || linkedStations.length > 4"
                    class="popup-content_text space-bottom-24 space-top-16">
                    {{('config.tariff.deletePopup.textWithUsersWithoutStations' | translate: {
                    stationNb: linkedStations.length,
                    nbSubscribers: nbSubscribers
                })}}
                </h6>

            </div>
        </div>


        <div *ngIf="!deletion && nbSubscribers > 0 && !isPrimoTariff">
            <h6 class="popup-content_text space-bottom-24 space-top-16">
                {{('config.tariff.modification.popup.applicationDate.text' | translate)}}
            </h6>
        </div>
        <div class="popup-content_soft-message">
            <h6 class="space-bottom-24 space-top-16 _text">{{'config.tariff.' + (deletion ? 'deletePopup' : 'modification.popup.applicationDate') + '.question' | translate:{applicationDate: applicationDate | fleetMomentToStringDatePipe} }}</h6>
            <date-picker
                *ngIf="!deletion"
                [(ngModel)]="applicationDate"
                name="applicationDate"
                [minDate]="minApplicationDate"></date-picker>
        </div>
    </div>

    <div class="grid-x grid-margin-x align-center">
        <button class="cell shrink button validate"
                (click)="submit()">
            {{'common.validate' | translate}}
        </button>
        <button class="cell shrink button secondary"
                (click)="emitCancel()">
            {{'common.cancel' | translate}}</button>
    </div>
</div>
