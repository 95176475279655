import {Component, Input, OnInit} from '@angular/core';
import {MediaFamilyConfiguration, SubscriptionPack} from 'lib-front';
import {MediaOrderDto} from '../../domain/mediaOrderDto';

@Component({
    selector: 'subscription-media-order',
    templateUrl: './subscription-media-order.component.html',
    styleUrls: ['./subscription-media-order.component.scss'],
})
export class SubscriptionMediaOrderComponent {

    @Input() set subscriptionPack(subscriptionPack: SubscriptionPack) {
        this._subscriptionPack = subscriptionPack;
        this.setMediaConfiguration();
    }

    @Input() set mediaOrderForm(mediaOrder: MediaOrderDto) {
        this._mediaOrderForm = mediaOrder;
        this.setMediaConfiguration();
    }

    _subscriptionPack: SubscriptionPack;
    _mediaOrderForm: MediaOrderDto;
    mediaFamilyConfiguration: MediaFamilyConfiguration;
    mediaFamilyLogoRef: string;

    constructor() {
    }

    setMediaConfiguration() {
        if (!this._subscriptionPack || !this._mediaOrderForm) {
            return;
        }

        const mediaFamilyConfigurations = Object.keys(this._subscriptionPack.mediaFamilyConfigurations)
            .filter(mediaFamilyRef => this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].activatedOnCustomerAccount
                && this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].maxProducedFromRegister
                && this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyRef].minProducedFromRegister !== undefined);

        if (!mediaFamilyConfigurations || !mediaFamilyConfigurations.length) {
            return;
        }

        this.mediaFamilyConfiguration = this._subscriptionPack.mediaFamilyConfigurations[mediaFamilyConfigurations[0]];

        if (this._mediaOrderForm
            && this._mediaOrderForm.mediaCanOrderDto
            && this._mediaOrderForm.mediaCanOrderDto.mediaOrderSubscriptionPackDtos
            && this._subscriptionPack) {
            const mediaOrderSubscriptionPackDto = this._mediaOrderForm.mediaCanOrderDto.mediaOrderSubscriptionPackDtos
                .find(mediaOrderSubscriptionPack => mediaOrderSubscriptionPack.id === this._subscriptionPack._id);

            if (mediaOrderSubscriptionPackDto) {
                this.mediaFamilyLogoRef = mediaOrderSubscriptionPackDto.visual.logoRef;
            }
        }
    }
}
