import {Component, Input} from '@angular/core';
import {MetaChargeConsumptionDto} from '../../../domain/meta/metaChargeConsumptionDto';
import {isMetaChargeConsumption, MetaConsumptionDto} from '../../../domain/meta/metaConsumptionDto';

@Component({
    selector: 'charge-consumption-details[consumption]',
    templateUrl: './charge-consumption-details.component.html',
    styleUrls: ['./charge-consumption-details.component.scss']
})

export class ChargeConsumptionDetailsComponent {
    private _consumption: MetaChargeConsumptionDto;

    @Input()
    set consumption(consumption: MetaConsumptionDto) {
        if (isMetaChargeConsumption(consumption)) {
            this._consumption = consumption;
        } else {
            this._consumption = undefined;
        }
    }

    get consumption(): MetaChargeConsumptionDto {
        return this._consumption;
    }

    constructor() {
    }
}
