<div [class.register-block]="mode === 'REGISTER'"
     [hidden]="!$any(form?.get('options'))?.controls || !$any(form?.get('options'))?.controls.length">
    <h3 class="grid-x align-middle cell secondary block-title space-top-40 space-bottom-10" [hidden]="mode !== 'REGISTER'">
        {{'register.subscription.options.title' | translate}}
    </h3>
    <h3 class="grid-x align-middle cell secondary block-title space-top-40 space-bottom-10" [hidden]="mode !== 'ADD_OPTION'">
        {{'register.subscription.options.add.title' | translate}}
    </h3>
    <h3 class="grid-x align-middle cell secondary block-title space-top-40 space-bottom-10" [hidden]="mode !== 'REVOKE'">
        {{'subscription.options.revocable.title' | translate}}
    </h3>
    <h3 class="grid-x align-middle cell secondary block-title" [hidden]="mode !== 'UPDATE'">
        {{'update.subscription.options.add.title' | translate}}
    </h3>
    <span class="info space-top-16 space-bottom-16" *ngIf="mode === 'REGISTER'">
  {{'register.subscription.options.i' | translate}}
</span>
    <form class="cell grid-y" [formGroup]="form">
        <div>
            <h5 class="cell shrink space-bottom-12" *ngIf="options?.length && options[0].subscriptionDefParentName">
                {{'options.freeHours.name' | translate:{name: options[0].subscriptionDefParentName | fleetSubstring:'Client "':'"'} }}
            </h5>
        </div>
        <div formArrayName="options" class="grid-x grid-margin-x grid-margin-y small-up-3 padding-12 options-container">
            <div class="subscription-option-block"
                 *ngFor="let optionCtrl of $any(form?.get('options'))?.controls;let indexOption = index;">
                <div class="grid-x align-middle fix-z-index" [hidden]="!options[indexOption].freeTime">
                    <span class="cell shrink subscription-option-block-time">{{options[indexOption].freeTime / 3600}}
                        h</span>
                    <div class="grid-y cell auto space-left-8 subscription-option-block-title">
                        <span [hidden]="!(options[indexOption].freeTime / 3600 > 0 && options[indexOption].freeTime / 3600 < 2)"
                              class="cell shrink">{{'register.subscription.options.freeTime.one' | translate}}</span>
                        <span [hidden]="!(options[indexOption].freeTime / 3600 >= 2)"
                              class="cell shrink">{{'register.subscription.options.freeTime.multiple' | translate}}</span>
                        <span class="cell shrink subscription-option-block-subtitle">{{options[indexOption].name}}</span>
                    </div>
                </div>
                <div class="grid-y align-middle space-top-16 fix-z-index" [hidden]="mode !== 'REVOKE'" *ngIf="asSubscriptionDefRevocable(options[indexOption]) as option">
                    <span class="options-revocable" [hidden]="option?.maxRevocable >= 2">
                        {{'option.max.revoke.one' | translate:{max: option?.maxRevocable} }}
                    </span>
                    <span class="options-revocable" [hidden]="option?.maxRevocable < 2">
                        {{'option.max.revoke.plural' | translate:{max: option?.maxRevocable} }}
                    </span>
                    <a class="cell shrink"
                       (click)="onSubscriptionSelected(options[indexOption]._id)">{{'show.details' | translate}}</a>
                </div>
                <div class="grid-x align-middle align-center space-top-16 fix-z-index inputContainer">
                    <input-number-button class="cell small-10 xlarge-7"
                                         [formControlName]="indexOption"
                                         placeholder="0" [min]="0"
                                         *ngIf="asSubscriptionDefRevocable(options[indexOption]) as option"
                                         [max]="option?.maxRevocable"></input-number-button>
                    <div class="grid-y text-center subscription-option-block-price space-left-16">
                        <h5 class="cell subscription-option-block-price-value">
                            {{options[indexOption].fixAmountWithVAT | currency:'EUR':options[indexOption].currencyUnit}}
                        </h5>
                        <span class="cell force-font-bold">{{'register.subscription.pack.by.month'| translate}}</span>
                    </div>
                </div>
                <label class="cell note small text-center space-top-16"
                       [hidden]="!options[indexOption].engagementDuration">
                    {{'register.subscription.options.commitment' | translate:{
                    month:
                    options[indexOption].engagementDuration
                } }}
                </label>
                <div class="cell error note"
                     [hidden]="!(optionCtrl.invalid && optionCtrl?.dirty)">
                </div>
            </div>
        </div>
        <div class="grid-x small-up-5" [hidden]="mode !== 'REGISTER'">
            <div class="grid-x cell auto align-center subscription-result-block">
                <div class="grid-y shrink subscription-result-block-item time align-middle">
                    {{totalHours | fleetDuration:{seconds: false} }}
                </div>
                <div class="grid-y shrink subscription-result-block-item align-middle text-center">
                    <span class="cell price">{{totalPrice | currency:'EUR': currencyUnit}}</span>
                    <span class="cell text-center force-font-bold font-size-14">{{'register.subscription.pack.by.month'| translate}}</span>
                </div>

            </div>
        </div>
    </form>
</div>
