import {Component, Input} from '@angular/core';
import {SelectableStation} from '../collapsible-stations-selects/collapsible-stations-selects.component';


@Component({
    selector: 'collapsible-stations-select',
    templateUrl: './collapsible-stations-select.component.html',
    styleUrls: ['./collapsible-stations-select.component.scss']
})
export class CollapsibleStationsSelectComponent {

    @Input()
    public disabledStationRefs: string[] = [];

    @Input()
    public disabledReason: string;

    @Input()
    get stations(): SelectableStation[] {
        return this._stations;
    }

    set stations(value: SelectableStation[]) {
        this._stations = value;
        this.selectedCount = value
            .filter(station => station.selected)
            .length;
        this.isAllSelected = this.selectedCount === this._stations.length;
        this.isIndeterminate = !this.isAllSelected && this.selectedCount > 0;
    }
    private _stations: SelectableStation[];

    isCollapsed: boolean;
    isAllSelected: boolean;
    isIndeterminate: boolean;
    selectedCount: number;

    toggleSelectAll() {
        if (this.isAllSelected) {
            this._stations.filter(station => !this.disabledStationRefs.includes(station.id)).forEach(station => station.selected = true);
            this.selectedCount = this.stations.filter(station => station.selected).length;
            this.isIndeterminate = this.stations.filter(station => station.selected).length !== this.stations.length;
        } else {
            this._stations.forEach(station => station.selected = false);
            this.selectedCount = 0;
            this.isIndeterminate = false;
        }
    }

    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
    }

    changeStation(selected: boolean) {
        if (selected) {
            this.selectedCount++;
        } else {
            this.selectedCount--;
        }
        this.isAllSelected = this.selectedCount === this._stations.length;
        this.isIndeterminate = !this.isAllSelected && this.selectedCount > 0;
    }

    trackByStationId(index: number, station: SelectableStation) {
        return station?.id ?? index;
    }

    public allStationsDisabled(): boolean {
        return this._stations.filter(station => !this.disabledStationRefs.includes(station.id)).length === 0;
    }
}
