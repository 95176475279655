import {Component, Input, ViewChild} from '@angular/core';
import {FrontTariffHttpService, StationDto, TariffDto} from 'lib-front';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'tariff-station-usage',
    templateUrl: './tariff-station-usage.component.html',
    styleUrls: ['./tariff-station-usage.component.scss']
})
export class TariffStationUsageComponent {
    @ViewChild('stationsModal') stationsModal;
    @Input() tariff: TariffDto;
    public stations: Array<StationDto>;

    constructor(private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly tariffHttpService: FrontTariffHttpService) {
    }

    isClickable(): boolean {
        return this.tariff.linkedStationRefs?.length > 0;
    }

    openStationsModal(): void {
        if (this.isClickable()) {
            this.getStations().subscribe(() => this.stationsModal.open());
        }
    }

    private getStations(): Observable<StationDto[]> {
        if (this.stations) {
            return of(this.stations);
        } else {
            return this.tariffHttpService.findLinkedStations(this.tariff.id).pipe(
                tap((stations: StationDto[]) => this.stations = stations)
            );
        }
    }
}
