import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isRecurringPaymentType, PaymentType, PaymentConf} from 'lib-front';

@Component({
    selector: 'payment-type',
    templateUrl: './payment-type.component.html',
    styleUrls: ['./payment-type.component.scss']
})
export class PaymentTypeComponent {

    @Input() public paymentTypesAvailables: PaymentType[];
    @Input() public get paymentConf(): PaymentConf {
        return this._paymentConf;
    };
    set paymentConf(paymentConf: PaymentConf) {
        if (paymentConf) {
            this._paymentConf = paymentConf;
            this.selectCurrentPayment();
        }
    }
    @Output() paymentTypeEmitter: EventEmitter<PaymentType> = new EventEmitter<PaymentType>();
    @Output() currentPaymentEmitter: EventEmitter<PaymentConf> = new EventEmitter<PaymentConf>();
    @Output() paymentConfigNameEmitter: EventEmitter<string> = new EventEmitter<string>();

    public PaymentType = PaymentType;
    public paymentTypeSelected: PaymentType;
    public paymentConfigName: string;
    public isCurrentPaymentConfSelected: boolean = false;

    private _paymentConf: PaymentConf;
    public selectPayment(paymentType: PaymentType): void {
        this.paymentTypeSelected = paymentType;
        this.isCurrentPaymentConfSelected = false;
        this.paymentTypeEmitter.emit(paymentType);
        this.currentPaymentEmitter.emit(null);
    }

    public isAvailable(paymentType: PaymentType): boolean {
        return !!this.paymentTypesAvailables.find(value => value === paymentType) &&
            !(this.paymentConf && this.paymentConf.paymentType === PaymentType.ACCOUNT_MANAGER);
    }

    public setPaymentConfigName(): void {
        this.paymentConfigNameEmitter.emit(this.paymentConfigName);
    }

    public isConfigNameRequired(): boolean {
        return (!!this.paymentTypeSelected && isRecurringPaymentType(this.paymentTypeSelected)) || this.isCurrentPaymentConfSelected;
    }

    selectCurrentPayment() {
        this.isCurrentPaymentConfSelected = true;
        this.paymentTypeSelected = null;
        this.paymentTypeEmitter.emit(null);
        this.currentPaymentEmitter.emit(this.paymentConf);
    }
}
