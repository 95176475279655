<section class="style-guide-block" style="height: 100%;">
    <div class="row" style="height: 100%;">
        <h1 class="small-24 columns style-guide-title">Icônes</h1>

        <div class="small-24 columns style-guide-section-content" style="height: 100%;">
            <div class="style-guide-note">
                <h1 class="style-guide-note-title">Note</h1>
                <p>La librairie d'icones utilisé pour le projet est la version gratuite de Fontawesome version 5.1.1</p>
            </div>

            <iframe src="https://fontawesome.com/icons?d=gallery&m=free"
                    style="border:0px #ffffff none;"
                    name="myiFrame"
                    scrolling="yes"
                    frameborder="1"
                    marginheight="0px"
                    marginwidth="0px"
                    width="100%"
                    height="100%"
                    allowfullscreen>
            </iframe>
        </div>
    </div>
</section>
