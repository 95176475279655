import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    FrontMediaHttpService,
    IdLabel, MediaOrder,
    PackSubscriptionActivateInfoDto
} from 'lib-front';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'subscription-packs-to-link-select[mediaOrder]',
    templateUrl: './subscription-packs-to-link-select.component.html',
    styleUrls: ['./subscription-packs-to-link-select.component.scss']
})
export class SubscriptionPacksToLinkSelectComponent {
    public loadingPackSubscriptions: boolean;
    subscriptionPackRefs: string[] = [];
    private packSubscriptionActivateInfoDtos: PackSubscriptionActivateInfoDto[] = [];
    subscribedPacksAvailableForLink: IdLabel[] = [];

    private _mediaOrder: MediaOrder;

    @Input() set mediaOrder(mediaOrder: MediaOrder) {
        if (!!mediaOrder) {
            this._mediaOrder = mediaOrder;
            this.loadActivatablePackSubscription();
        }
    };

    get mediaOrder(): MediaOrder {
        return this._mediaOrder;
    }

    @Output() popupClosed = new EventEmitter<void>();

    @Output() selectedSubscriptionPackRefs = new EventEmitter<string[]>();

    constructor(private readonly frontMediaHttpService: FrontMediaHttpService) {}

    private loadActivatablePackSubscription(): void {
        this.loadingPackSubscriptions = true;
        this.frontMediaHttpService.findActivatablePackSubscriptionsByMediaFamily(this.mediaOrder.mediaFamilyRef)
            .pipe(
                finalize(() => this.loadingPackSubscriptions = false)
            )
            .subscribe(packSubscriptionActivateInfoDtos => {
                this.packSubscriptionActivateInfoDtos = packSubscriptionActivateInfoDtos;
                this.reloadSubscribedPacksAvailableForLink(this.mediaOrder.quantity);
            });
    }

    private reloadSubscribedPacksAvailableForLink(mediaQuantity: number): void {
        this.subscribedPacksAvailableForLink = this.filterSubscriptionsWithMaxMediaTooLow(mediaQuantity, this.packSubscriptionActivateInfoDtos)
            .map(packSubscriptionActivateInfoDto => packSubscriptionActivateInfoDto.subscriptionPackIdLabel);
    }

    /**
     * Subscribed packs available for link depend on media quantity: only packs to which all medias can be activated
     * are available for the linking.
     */
    private filterSubscriptionsWithMaxMediaTooLow(mediaQuantity: number, packSubscriptionActivateInfoDtos: PackSubscriptionActivateInfoDto[]) {
        return packSubscriptionActivateInfoDtos.filter(packSubscriptionActivateInfoDto =>
            SubscriptionPacksToLinkSelectComponent.canLinkMediasToPackSubscription(packSubscriptionActivateInfoDto, mediaQuantity));
    }

    private static canLinkMediasToPackSubscription(packSubscriptionActivateInfoDto: PackSubscriptionActivateInfoDto, mediaQuantity: number) {
        if(!packSubscriptionActivateInfoDto.maxMedias || packSubscriptionActivateInfoDto.maxMedias === 0) {
            return true;
        }

        return packSubscriptionActivateInfoDto.maxMedias >= mediaQuantity + packSubscriptionActivateInfoDto.nbOfMediaLinkedToPackSubscription;
    }

    close(): void {
        this.clearSelect();
        this.popupClosed.emit();
    }

    linkToSubscriptionPackRefs(): void {
        this.selectedSubscriptionPackRefs.emit(this.subscriptionPackRefs);
        this.clearSelect();
    }

    clearSelect() {
        this.subscriptionPackRefs = [];
    }

    trackById(index: number, pack: IdLabel): string {
        return pack.id;
    }
}
