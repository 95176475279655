import {NgModule} from '@angular/core';
import {FleetDurationPipe} from './fleetDuration.pipe';
import {FleetSubstringPipe} from './fleetSubstring.pipe';
import {FleetInvoiceStatusLabelPipe} from './fleetInvoiceStatusLabel.pipe';
import {FleetVehicleModelFormatterPipe} from './fleetVehicleModelFormatter.pipe';
import {FleetVehicleVersionFormatterPipe} from './fleetVehicleVersionFormatter.pipe';
import {FleetLocationPipe} from './fleetLocation.pipe';
import {FleetMediaItemLabelPipe} from './fleetMediaItemLabel.pipe';
import {FleetCalendarOneTimeEventTitle} from './fleetCalendarOneTimeEventTitle';
import {FleetCalendarRecurringEventTitle} from './fleetCalendarRecurringEventTitle';
import {FleetMetaConsumptionDatePipe} from './fleetMetaConsumptionDate.pipe';
import {FleetMetaConsumptionMediaPublicCodePipe} from './fleetMetaConsumptionMediaPublicCode.pipe';
import {FleetMetaConsumptionStationPipe} from './fleetMetaConsumptionStation.pipe';
import {FleetMetaConsumptionMediaPipe} from './fleetMetaConsumptionMedia.pipe';
import {FleetMetaConsumptionEnergyPipe} from './fleetMetaConsumptionEnergy.pipe';
import {FleetMergeTotalConsumptionsByTypePipe} from './fleetMergeTotalConsumptionByType.pipe';
import {FleetMetaConsumptionLicenseNumberPipe} from './fleetMetaConsumptionLicenseNumber.pipe';
import {BypassSecurityTrustResourceUrlPipe} from './bypassSecurityTrustResourceUrl.pipe';
import {AbstractControlToFormControlPipe} from './abstractControlToFormControl.pipe';
import {ToDayjsPipe} from './toDayjs.pipe';
import {FleetMomentToStringDatePipe} from './fleetMomentToStringDate.pipe';
import {TariffPricingInfoPipe} from './tariffPricingInfo.pipe';

const PIPES = [
    AbstractControlToFormControlPipe,
    BypassSecurityTrustResourceUrlPipe,
    FleetDurationPipe,
    FleetSubstringPipe,
    FleetInvoiceStatusLabelPipe,
    FleetLocationPipe,
    FleetVehicleModelFormatterPipe,
    FleetVehicleVersionFormatterPipe,
    FleetMediaItemLabelPipe,
    FleetCalendarOneTimeEventTitle,
    FleetCalendarRecurringEventTitle,
    FleetMetaConsumptionDatePipe,
    FleetMetaConsumptionEnergyPipe,
    FleetMetaConsumptionLicenseNumberPipe,
    FleetMetaConsumptionMediaPipe,
    FleetMetaConsumptionMediaPublicCodePipe,
    FleetMetaConsumptionStationPipe,
    FleetMergeTotalConsumptionsByTypePipe,
    ToDayjsPipe,
    FleetMomentToStringDatePipe,
    TariffPricingInfoPipe
];

@NgModule({
    declarations: PIPES,
    exports: PIPES,
    providers: PIPES
})
export class PipesModule {

}
