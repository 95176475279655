<div class="indicator-container infraStatus" [ngClass]="'infraStatus-'+ chargePoint.infraStatus">
    <div class="grid-y cell space-bottom-8" [ngClass]="'infraStatus-'+ chargePoint.infraStatus">
        <div class="cell station-status" [ngClass]="'background-'+ chargePoint.infraStatus">
            <i class="fas fa-circle station-status-color"
               [ngClass]="'color-'+ chargePoint.infraStatus"></i>
            {{'stations.status.' + chargePoint.infraStatus | translate}}
        </div>
    </div>
    <div class="grid-x grid-margin-x cell shrink align-bottom space-bottom-8">
        <form class="grid-y cell small-6" #chargePointForm="ngForm">
            <label class="cell bottom">
                <span>{{'chargePoint.visualId' | translate}}</span>
                <input type="text" [(ngModel)]="chargePoint.visualId" name="visualId" #visualId="ngModel" required>
                <div *ngIf="visualId.invalid && (visualId.dirty || visualId.touched)"
                     class="cell note error">
                    <div *ngIf="visualId.errors.required">
                        {{'chargePoint.update.visualIdRequired' | translate}}
                    </div>
                </div>
            </label>
        </form>
        <label class="grid-y cell auto bottom charge force-space-left-32">
            <span>{{'chargePoint.power' | translate : {power: chargePoint.maxPowerInW/1000} }}</span>
        </label>

        <div class="grid-x cell auto checkboxContainer">
            <label class="cell space-left-20 space-top-8 checkbox plugType-content"
                   *ngFor="let plug of chargePoint.plugs; trackBy: trackByPlugId"
                   (click)="selectPlug(plug)"
                   [class.selected]="isSelected(plug)">
                <div class="cell plugType-content-style">
                    <img [src]="'/api/download/' + plug?.plugTypeDto?.iconRef" [alt]="''"
                         *ngIf="plug?.plugTypeDto?.iconRef"/>
                </div>
                <span class="label-choice">{{'plugType.' + plug.plugType | translate}}</span>
            </label>
        </div>
        <div class="grid-x cell shrink">
            <plug-remote-action [hasStationWriteRole]="hasStationWriteRole" [chargePoint]="chargePoint" [plug]="selectedPlug"></plug-remote-action>
        </div>
    </div>
</div>
