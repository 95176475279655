<label class="cell space-top-16">
    <h3 class="space-bottom-16">{{'token.promoToken.label' | translate}}</h3>
    <div class="light-grey-block">
        <div class="grid-x">
            <label class="cell small-8 inputContainer"
                 [ngClass]="{validate : promoTokenInput?.valid && promoToken && (promoTokenValid === null || promoTokenValid), invalid : showPromoTokenError() && (promoTokenInput?.touched || promoTokenInput?.dirty)}">
                <input type="text"
                       placeholder="{{'token.promoToken.label' | translate}}"
                       [(ngModel)]='promoToken'
                       [disabled]="promoTokenProcessing "
                       (keyup.enter)="!promoTokenInput?.pristine && !promoTokenProcessing && lastPromoToken !== promoToken && validatePromoToken()"
               (ngModelChange)="resetValidity()"
                       #promoTokenInput='ngModel'>
                <div class="cell note error"
                     [ngClass]="{hidden: !(showPromoTokenError() && (promoTokenInput?.touched || promoTokenInput?.dirty))}">
                    {{promoTokenErrorMessage | translate}}
                </div>
            </label>
            <div class="cell shrink token-button">
                <button type="button"
                        class="button color round"
                        (click)="validatePromoToken()"
                        [iziviaLoading]="promoTokenProcessing"
                        [disabled]="promoTokenInput?.pristine || lastPromoToken === promoToken"
                        [hidden]="promoTokenValid">
                    <i class="fas fa-check"></i>
                </button>
                <button type="button"
                        class="cell shrink button color round"
                        [hidden]="!promoTokenValid || canCancelToken === false"
                        (click)="removePromoToken()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>
</label>
