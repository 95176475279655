<form [formGroup]="form">
    <div class="space-top-16 space-bottom-16 register-block" [hidden]="!mediaCanOrderDtoList?.length">
        <h3 class="cell small-24 grid-x align-title cell secondary block-title space-bottom-16">
            {{'register.fleet.media.title' | translate}}
        </h3>
        <div class="info">
            {{'register.fleet.media.infos' | translate:{firstMediaOffer: 50, maxMediaOffer: 100} }}
        </div>
        <media-order class="cell auto" *ngIf="mediaCanOrderDtoList?.length"
                     formControlName="mediaOrder"
                     (setCalculatingMediaCost)="setCalculatingMediaCost($event)"
                     [frontEndInfo]="frontEndInfo"
                     [promoTokenAllowed]="false"
                     [mediaOrderContext]="mediaOrderContext"
                     [mediaCanOrderDtoList]="mediaCanOrderDtoList">
        </media-order>
    </div>
    <div class="grid-x align-center align-justify register-block">
        <button class="button color previous" type="button" (click)="stepBack()">{{'register.back' | translate}}
        </button>
        <div class="cell auto">&nbsp;</div>
        <button class="button color next" type="button" (click)="onSubmit()"
                [disabled]="mediaCanOrderDtoList?.length && (form.pristine || form.invalid || calculatingMediaCost)">{{'register.next' | translate}}
        </button>
    </div>
</form>
