import {Component, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ChargeAreaDetailDto, FrontChargeAreaHttpService} from 'lib-front';
import {LocationType} from '../../../../../pipes/fleetLocation.pipe';

@Component({
    selector: 'charge-area-edit-view',
    templateUrl: './charge-area-edit.view.html',
    styleUrls: ['./charge-area-edit.view.scss'],
    host : {'class' : 'cell auto scroll-container'}
})
export class ChargeAreaEditView {
    public LocationType = LocationType;
    public selectedTemplate: TemplateRef<any>;
    public chargeArea: ChargeAreaDetailDto;
    public chargeAreaId: string;

    constructor(private readonly route: ActivatedRoute,
        private readonly chargeAreaHttpService: FrontChargeAreaHttpService) {
        this.chargeAreaId = route.snapshot.paramMap.get('chargeAreaId');
        this.chargeAreaHttpService.findChargeAreaById(this.chargeAreaId, [])
            .subscribe(chargeArea => this.chargeArea = chargeArea);
    }

}
