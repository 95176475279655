import {AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgForm,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    FrontEndInfo,
    FrontMediaHttpService,
    FrontSubscriptionPackHttpService,
    MediaCanOrderDto,
    MediaData,
    MediaOrder,
    MediaOrderContext,
    MediaOrderInfo,
    SubscriptionMode,
    SubscriptionPackDto,
    User
} from 'lib-front';
import {reduceFormGroupErrors} from '../../utils/formGroupUtils.service';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../services/utils/notification.service';
import {MediaOrderDto} from '../../domain/mediaOrderDto';
import {PromoTokenWithSubscriptionPack} from '../../domain/promoTokenWithSubscriptionPack';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';

@Component({
    selector: 'media-order',
    templateUrl: './media-order.component.html',
    styleUrls: ['./media-order.component.scss'],
    host: {'class': 'light-grey-block'},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MediaOrderComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MediaOrderComponent),
            multi: true
        }
    ]
})
export class MediaOrderComponent implements AfterViewInit, Validator, ControlValueAccessor {
    @Input() mediaOrderContext: MediaOrderContext;
    @Input() promoTokenAllowed: boolean;
    @Input() frontEndInfo: FrontEndInfo;

    @Input()
    public set mediaCanOrderDtoList(mediaCanOrderDtoList: MediaCanOrderDto[]) {
        this._mediaCanOrderDtoList = mediaCanOrderDtoList;
    }

    @Output() setCalculatingMediaCost: EventEmitter<boolean> = new EventEmitter<boolean>();

    public user: User;
    public mediaOrderInfo: MediaOrderInfo;

    MediaOrderContext = MediaOrderContext;
    SubscriptionMode = SubscriptionMode;

    public get mediaCanOrderDtoList(): MediaCanOrderDto[] {
        return this._mediaCanOrderDtoList;
    }

    private foAccountRef: string;
    private _mediaCanOrderDtoList: MediaCanOrderDto[];

    maxProduced: number;
    minProduced: number;
    public mediaData: MediaData;
    public nbrAuthorizedBadges: number;
    public promoSubscriptionPack: SubscriptionPackDto;
    public noLimitPass: boolean;
    private promoToken: PromoTokenWithSubscriptionPack;

    // Beware of the use of static: true
    // #form must NOT be used within *ngIf or *ngFor in the template otherwise you should change static to false
    // and wait for ngAfterViewInit to use it
    @ViewChild('form', {static: true}) form: NgForm;

    mediaOrderForm: MediaOrderDto;

    onChangeCallback = (value: MediaOrderDto) => {
    };
    onTouchedCallback = () => {
    };

    constructor(private readonly subscriptionPackHttpService: FrontSubscriptionPackHttpService,
        private readonly mediaService: FrontMediaHttpService,
        private readonly currentUserContextService: CurrentUserContextService,
        readonly route: ActivatedRoute,
        private readonly notificationService: NotificationService) {
        route.data.pipe(
            filter(data => !!data.user && !!data.user.user),
            tap(data => this.foAccountRef = currentUserContextService.getCurrentFoAccountId()),
            switchMap(data => this.mediaService.findMediaData(data.user.user._id, this.foAccountRef))
        ).subscribe(value => {
            this.mediaData = value;
        });
    }

    public ngAfterViewInit(): void {
        this.form.valueChanges.subscribe(value => {
            this.onChangeCallback(this.mediaOrderForm);
        });
    }

    public computePrice(): void {
        const quantity = this.form.controls.quantity.value;
        this.setCalculatingMediaCost.emit(true);
        this.checkAuthorizationActivateBadges(quantity);

        if (this.form.controls.quantity.valid
            && quantity !== null
            && quantity !== undefined
            && quantity > 0) {
            let subscriptionPackIds: string[] = this.getMediaOrderSubscriptionPackIds();

            subscriptionPackIds = subscriptionPackIds.concat(this.getPromoTokenSubscriptionPackRefs());

            const mediaOrder: MediaOrder = {
                mediaFamilyRef: this.mediaOrderForm.mediaCanOrderDto.mediaFamily.id,
                quantity: quantity
            };

            this.mediaOrderInfo = {
                quantity: quantity,
                mediaFamily: this.mediaOrderForm.mediaCanOrderDto.mediaFamily
            };

            this.subscriptionPackHttpService.findMediaOrderBestPrices(subscriptionPackIds, mediaOrder)
                .pipe(
                    finalize(() => this.setCalculatingMediaCost.emit(false))
                )
                .subscribe(emoMediaOrderPrice => {
                    this.mediaOrderForm.emoMediaOrderPrice = emoMediaOrderPrice;
                    this.onChangeCallback(this.mediaOrderForm);
                });
        } else {
            if (this.mediaOrderForm.emoMediaOrderPrice) {
                this.mediaOrderForm.emoMediaOrderPrice.price = 0;
                this.mediaOrderForm.emoMediaOrderPrice.bestPrice = 0;
                this.mediaOrderForm.emoMediaOrderPrice.emoRef = null;
            }

            this.setCalculatingMediaCost.emit(false);
            this.onChangeCallback(this.mediaOrderForm);
        }
    }

    private getPromoTokenSubscriptionPackRefs() {
        return this.promoToken && this.promoToken.valid ? this.promoToken.subscriptionPacks.map(sp => sp.id) : [];
    }

    public getMediaOrderSubscriptionPackIds(): string[] {
        if(!this.mediaOrderForm || !this.mediaOrderForm.mediaCanOrderDto){
            return [];
        }
        return this.mediaOrderForm.mediaCanOrderDto.mediaOrderSubscriptionPackDtos
            .map(mediaOrderSubscriptionPackDto => mediaOrderSubscriptionPackDto.id);
    }

    private checkAuthorizationActivateBadges(quantity: string) {
        if (!this.noLimitPass && parseInt(quantity, 10) === this.nbrAuthorizedBadges + 1) {
            this.notificationService.warning('media.order.limit.authorized', {
                max: this.mediaOrderForm.mediaCanOrderDto.maxOrderableFromAccount,
                activate: this.mediaData.activate,
                order: this.mediaData.order
            });
        }
    }

    private setMediaConfiguration() {
        this.maxProduced = this.selectMaxProduced();
        this.minProduced = this.selectMinProduced();

        if (!this.form.controls.quantity) {
            return;
        }

        this.form.controls.quantity.setValue(this.minProduced || 0);
        this.computePrice();
    }

    private selectMaxProduced(): number {
        if (this.mediaOrderContext === MediaOrderContext.REGISTER) {
            return this.mediaOrderForm.mediaCanOrderDto.maxOrderableFromRegister;
        }

        if (this.mediaOrderContext === MediaOrderContext.SUBSCRIPTION) {
            return this.mediaOrderForm.mediaCanOrderDto.maxOrderableFromSubscription;
        }

        return this.mediaOrderForm.mediaCanOrderDto.maxOrderableFromAccount;
    }

    private selectMinProduced(): number {
        if (this.mediaOrderContext === MediaOrderContext.REGISTER) {
            return this.mediaOrderForm.mediaCanOrderDto.minOrderableFromRegister;
        }

        if (this.mediaOrderContext === MediaOrderContext.SUBSCRIPTION) {
            return this.mediaOrderForm.mediaCanOrderDto.minOrderableFromSubscription;
        }

        return 0;
    }

    public writeValue(newMediaOrderForm: MediaOrderDto): void {
        if (!newMediaOrderForm) {
            return;
        }

        this.form.reset();

        this.mediaOrderForm = newMediaOrderForm;
        this.setMediaConfiguration();

        if (this.form.controls.quantity) {
            this.form.controls.quantity.patchValue(newMediaOrderForm.quantity, {emitEvent: false});
        }
    }

    public setMediaCanOrderDto(mediaCanOrderDto: MediaCanOrderDto) {
        this.noLimitPass = mediaCanOrderDto.noLimitPass;
        const totalOfBadge = mediaCanOrderDto.maxOrderableFromAccount - (this.mediaData.activate + this.mediaData.order);
        this.nbrAuthorizedBadges = totalOfBadge < 0 ? 0 : totalOfBadge;
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        return this.form.invalid ? {'media-order': reduceFormGroupErrors(this.form)} : null;
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public setDisabledState(isDisabled: boolean): void {

    }

    public promoTokenChanged(promoToken: PromoTokenWithSubscriptionPack) {
        this.promoToken = promoToken;

        if (this.promoToken.valid) {
            this.mediaOrderForm.token = promoToken.token;
            this.promoSubscriptionPack = promoToken.subscriptionPacks[0] as SubscriptionPackDto;
            this.computePrice();
        } else {
            this.mediaOrderForm.token = null;
            this.promoSubscriptionPack = null;
        }
    }

    public onMediaTypeChange(media: MediaCanOrderDto) {
        this.mediaOrderForm.mediaCanOrderDto = media;
        this.setMediaConfiguration();
    }
}

