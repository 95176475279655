<div class="cell shrink grid-y login-container align-center align-middle">
    <div class="common-block">
        <h2>{{'forgottenPwd.title' | translate}}</h2>
        <form #forgottenPasswordForm="ngForm" class="grid-y align-middle">
            <label class="cell grid-y label-login primary login fixWidth">
                <span class="label-container">{{'forgottenPwd.email' | translate}}</span>
                <input class="form-control input-login" name="email" [(ngModel)]="email" type="text"
                       #emailInput="ngModel"
                       iziviaEmailValidator="emailInput"
                       required>
            </label>
            <div class="cell note error"
                 [hidden]="!(emailInput?.invalid && (emailInput?.touched || emailInput?.dirty))">
                {{'informations.administrator.email.incorrect' | translate}}
            </div>
            <button class="cell button validate"
                    type="submit"
                    (click)="forgottenPassword()"
                    [iziviaLoading]="sendingEmail"
                    [disabled]="forgottenPasswordForm.invalid">
                {{'forgottenPwd.validate' | translate}}
            </button>
        </form>
    </div>
</div>

