import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FrontInvoiceHttpService, FrontSessionBusinessService, InvoiceWrapper, switchTap} from 'lib-front';
import {Observable, of} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {isEmpty} from 'lodash-es';

/**
 * This guard prevents user from navigating to a subset of routes until he has paid all of his blocking invoices.
 *
 * @see https://izivia.atlassian.net/wiki/spaces/SODMBP/pages/3076104493/BR-CORE-Facture+bloquante
 * @see https://izivia.atlassian.net/wiki/spaces/SODMBP/pages/3069261197/BR+-+Pro+-+Incidence+d+une+facture+bloquante+non+pay+e+pour+les+options
 *
 * There is also a guard {@link FirstBlockingInvoiceGuard} that is way more restrictive: it prevents user from navigating to any authenticated route
 * (except invoices)
 */
@Injectable()
export class BlockingInvoicesGuard implements CanActivate {

    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly invoiceHttpService: FrontInvoiceHttpService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sessionBusinessService.connectedUser$
            .pipe(
                filter(user => user !== undefined),
                switchTap(user => {
                    if (user !== null) {
                        return this.fetchBlockingInvoices(user.user._id)
                            .pipe(
                                tap(blockingInvoiceIds => this.redirectToInvoicesRouteIfAnyBlocking(state, blockingInvoiceIds))
                            );
                    } else {
                        return of([]);
                    }
                }),
                map(user => user !== null));
    }

    private fetchBlockingInvoices(userId: string): Observable<string[]> {
        return this.invoiceHttpService.fetchBlockingInvoiceIds(userId);
    }

    private redirectToInvoicesRouteIfAnyBlocking(state: RouterStateSnapshot, blockingInvoiceIds: string[]): void {
        if (!isEmpty(blockingInvoiceIds)) {
            if (!state.url.includes('/main/invoices') && !state.url.includes('/main/finances/expenses')) {
                this.router.navigate(['/main/invoices']);
            }
        }
    }
}
