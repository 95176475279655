import {AfterContentInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalDirective} from '../../directives/modal.directive';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'izivia-popup',
    styleUrls: ['./izivia-popup.component.scss'],
    templateUrl: './izivia-popup.component.html'
})
export class IziviaPopupComponent implements AfterContentInit {

    @Input() size?: 'full' | 'large' | 'medium' | 'small' | 'tiny' | 'unset' = 'large';
    @Output() closed = new EventEmitter();
    @ViewChild(ModalDirective) private iziviaModal: ModalDirective;

    private opened: boolean = false;

    constructor(private router: Router) {
    }

    ngAfterContentInit(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                filter((event: NavigationStart) => this.getPath(this.router.url) !== this.getPath(event.url)),
                filter((event: NavigationStart) => this.opened)
            )
            .subscribe(() => this.close());
    }

    private getPath(url: string): string {
        const separatorIndex = url.indexOf('?');
        if (url && separatorIndex > 0) {
            return url.substring(0, separatorIndex);
        }
        return url;
    }

    public open(): void {
        this.iziviaModal.open();
        this.opened = true;
    }

    public close(): void {
        this.iziviaModal.close();
        this.opened = false;
        this.closed.emit();
    }

    public isOpened(): boolean {
        return this.opened;
    }
}
