<div class="grid-y has-space">
    <div class="grid-x block-title space-bottom-16">
        <div>
            <h3 class="grid-x align-middle secondary" *ngIf="editMode">
                {{'config.indemnity.headerCreate' | translate}}
            </h3>
            <h3 class="grid-x align-middle secondary" *ngIf="!editMode">
                {{'config.indemnity.header' | translate}}
            </h3>
        </div>

        <div *ngIf="editMode"
             class="cell shrink text-right">
            <button (click)="switchListMode()"
                    [iziviaLoading]="loadingIndemnities"
                    class="button secondary">
                <span>{{'config.indemnity.backToList' | translate}}</span>
            </button>
        </div>

        <button (click)="switchEditMode()"
                class="button"
                *ngIf="!editMode">
            {{'config.indemnity.createTitle' | translate}}
        </button>
    </div>

    <ng-container *ngIf="!editMode">
        <div class="grid-y cell auto align-center align-middle message-empty"
             *ngIf="!indemnities || indemnities.length === 0">
            <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
            <span class="message-empty-label">{{'config.indemnity.noIndemnityFound' | translate}}</span>
        </div>

        <div class="grid-x grid-margin-x">
            <div class="cell small-8">
                <indemnity-card *ngFor="let indemnity of indemnities"
                                (click)="showIndemnityDetails(indemnity)"
                                [indemnity]="indemnity"
                                [selected]="selectedIndemnity.id === indemnity.id"></indemnity-card>
            </div>

            <div *ngIf="selectedIndemnity && selectedIndemnity.id"
                 class="cell auto">
                <indemnity-form [indemnity]="selectedIndemnity"
                                (indemnityUpdated)="onIndemnityUpdate($event)"></indemnity-form>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="editMode">
        <indemnity-form [indemnity]="selectedIndemnity"></indemnity-form>
    </ng-container>

</div>
