import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    buildRole,
    CurrentSubscriberRolesService,
    FrontTariffHttpService,
    SubscriberRoleLevel,
    SubscriberRoleType,
    TariffDto
} from 'lib-front';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../services/utils/notification.service';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';

@Component({
    selector: 'tariff-card',
    templateUrl: './tariff-card.component.html',
    styleUrls: ['./tariff-card.component.scss']
})
export class TariffCardComponent extends AbstractHasRoleActionComponent implements OnInit {
    private hasChargingPointWriteRole: boolean;
    @Input() tariff: TariffDto;
    @Input() selected: boolean;
    @Input() deleting: boolean;
    @Input() canDelete: boolean;
    @Output() delete = new EventEmitter<TariffDto>();

    constructor(private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        protected readonly notificationService: NotificationService,
        private readonly tariffHttpService: FrontTariffHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasChargingPointWriteRole = hasRole);
    }

    deleteTariff(): void {
        this.doActionIfHasRole(
            () => {
                this.deleting = true;
                this.delete.emit(this.tariff);
            },
            this.hasChargingPointWriteRole
        );

    }

    tariffWasUpdated(): boolean {
        return TariffDto.wasUpdated(this.tariff);
    }
}
