import {Component, Input} from '@angular/core';

@Component({
    selector: 'fleet-spinner',
    templateUrl: './fleet-spinner.component.html',
    styleUrls: ['./fleet-spinner.component.scss']
})
export class FleetSpinnerComponent {
    @Input() size: 'small' | 'medium' | 'large' = 'small';
    @Input() align: 'left' | 'center' = 'left';
}
