<suggest name="timePicker"
         [iconTime]="true"
         [(ngModel)]="time"
         (ngModelChange)="updateSelectedTime()"
         placeholder='--:--'
         [results]="searchTimeResult"
         [itemValueGetter]="showTariffTime"
         [itemNameGetter]="showTariffTime"
         [valueToNameGetter]="showTariffTime"
         (searchChange)="searchTariffTime($event)">
    <ng-template let-time="item">
        {{time}}
    </ng-template>
</suggest>
