import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {ConnectedUser, FrontPersonHttpService, FrontSessionBusinessService, StdUserDto} from 'lib-front';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class TermsAndConditionsOfUseGuard implements CanActivate, CanActivateChild {


    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly personService: FrontPersonHttpService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.hasAcceptedTermsAndConditionsOfUse();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.hasAcceptedTermsAndConditionsOfUse();
    }

    private hasAcceptedTermsAndConditionsOfUse(): Observable<boolean> {
        return this.sessionBusinessService.connectedUser$
            .pipe(
                filter(user => user !== undefined),
                switchMap((user: ConnectedUser | null) => {
                    if (user !== null) {
                        return this.personService.findPersonSummary(user.user._id);
                    } else {
                        return of(null);
                    }
                }),
                tap((user: StdUserDto | null) => {
                    if (!user?.termsAndConditionsOfUseAccepted) {
                        this.router.navigate(['/login']);
                    }
                }),
                map((user: StdUserDto | null) => user?.termsAndConditionsOfUseAccepted));
    }
}
