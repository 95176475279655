<div *ngIf="consumption" class="grid-x align-middle align-center">
    <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
    <div class="grid-y cell shrink consumption-detail-block-info border-grey">
        <div class="cell grid-x grid-padding-x space-bottom-8">
            <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.charge.start' | translate}}</span>
            <span class="cell shrink consumption-detail-block-info-text">{{consumption.start | date: 'short'}}</span>
        </div>
        <div class="cell grid-x grid-padding-x space-bottom-8">
            <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.charge.end' | translate}}</span>
            <span class="cell shrink consumption-detail-block-info-text">{{consumption.end | date: 'short'}}</span>
        </div>
        <div class="space-bottom-8">
            <div class="cell grid-x grid-padding-x">
                <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.duration' | translate}}</span>
                <span class="cell shrink consumption-detail-block-info-text">{{consumption.durationInSec | fleetDuration}}</span>
            </div>
            <span class="cell note">{{'consumptions.detail.durationIndication' | translate}}</span>
        </div>
        <div class="cell grid-x grid-padding-x space-bottom-8">
            <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.station' | translate}}</span>
            <span class="cell shrink consumption-detail-block-info-text">{{consumption | fleetMetaConsumptionStation}}</span>
        </div>
        <div class="cell grid-x grid-padding-x space-bottom-8">
            <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.charge.pass' | translate}}</span>
            <span class="cell shrink consumption-detail-block-info-text">{{consumption | fleetMetaConsumptionMedia}}</span>
        </div>
        <div class="cell grid-x grid-padding-x space-bottom-8">
            <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.charge.energy' | translate}}</span>
            <span class="cell shrink consumption-detail-block-info-text">{{consumption | fleetMetaConsumptionEnergy}}</span>
        </div>
        <consumption-details-data [consumption]="consumption"></consumption-details-data>
    </div>
    <charge-record-chart
        class="cell auto"
        [chargeRecordId]="consumption.chargeRecordRef"
        [startDate]="consumption.start"
        [stopDate]="consumption.end"></charge-record-chart>

    <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
</div>
