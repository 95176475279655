<div class="faqContent">
    <h5 class="faqTitle">{{faqPage.title}}</h5>
    <div [innerHtml]="content"></div>
</div>

<div class="space-top-24 space-left-16" *ngIf="faqPage?.attachedFile">
    <faq-file [faqFile]="faqPage?.attachedFile"></faq-file>
</div>

<div class="space-top-24 space-left-16" *ngIf="faqPage.contact">
    <contact-card [contact]="faqPage.contact"
                  [mailToSubject]="faqPage | faqMailSubject"></contact-card>
</div>
