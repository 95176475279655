<div class="progress"
     [ngClass]="{alert : isBarAlert, secondary : color === 'secondary', green : color === 'green', red : color === 'red', grey : color === 'grey'}">
    <div class="progress-meter"
         [ngStyle]="{width : consumePercent + '%'}"
         [ngClass]="{fillBar : fillBar}">
        <div>
            <div class="progress-number" *ngIf="showConsume">{{consume}}/{{consumeMax}}</div>
        </div>
    </div>
</div>
