<form #addressForm="ngForm">
    <div>
        <label class="cell margin-label bottom required" [ngClass]="{validate : !!route.valid}">
            <span class="labelName">{{'informations.billing.route' | translate}}</span>
            <ng-select name="route"
                       #route="ngModel"
                       [ngModel]="address.route"
                       [editableSearchTerm]="true"
                       (search)="pushNewRoute($event)"
                       (focus)="focusOn('address')"
                       (blur)="focusOut('address')"
                       [required]="required"
                       (change)="validAddressSuggestion($event)"
                       [isOpen]="shouldShowAddressSuggestions()"
                       (clear)="removeRouteAndClearAddressSuggestions()">
                <ng-option *ngFor="let suggestion of addressSuggestions; trackBy:trackByPlaceId"
                           [value]="suggestion.placeId"
                >{{suggestion.description}}</ng-option>
            </ng-select>
        </label>
        <label class="cell margin-label bottom" [ngClass]="{validate : !!extra.valid}">
            <span class="labelName">{{'common.address.additionalAddress.label' | translate}}</span>
            <input type="text"
                   [(ngModel)]="address.extra"
                   name="extra"
                   [placeholder]="'common.address.additionalAddress.placeholder' | translate"
                   #extra="ngModel">
        </label>
        <div class="grid-x cell">
            <label class="grid-y align-justify cell small-24 xxlarge-8 required margin-label bottom"
                   [ngClass]="{validate : !!country.valid}">
                <span class="labelName">{{'informations.billing.country' | translate}}</span>
                <select [(ngModel)]="address.countryCode"
                        name="country"
                        #country="ngModel"
                        [required]="required"
                        (change)="onCountryChange()">
                    <option *ngFor="let country of countries; trackBy:trackByCountryCode"
                            [value]="country.code">{{country.name}}
                    </option>
                </select>
            </label>
            <div class="cell small-24 xxlarge-8 margin-label bottom">
                <label class="margin-label right required"
                       [ngClass]="{validate : !!postalCode.valid}">
                    <span class="labelName">{{'informations.billing.postalCode' | translate}}</span>
                    <ng-select name="postalCode"
                               #postalCode="ngModel"
                               [editableSearchTerm]="true"
                               [ngModel]="address.postalCode"
                               (search)="pushNewPostalCodeAndClearCity($event)"
                               (focus)="focusOn('postalCode')"
                               (blur)="focusOut('postalCode')"
                               [required]="required"
                               (change)="validPostalCodeSuggestion($event)"
                               [isOpen]="shouldShowPostalCodeSuggestions()"
                               [compareWith]="compareAlwaysFalse"
                               (clear)="onPostalCodeClear()">
                        <ng-option *ngFor="let suggestion of postalCodeSuggestions; trackBy:trackByPostalCode"
                                   [value]="suggestion.postalCode"
                        >{{suggestion.postalCode}}</ng-option>
                    </ng-select>
                </label>
            </div>
            <label class="grid-y align-justify cell small-24 xxlarge-8 required margin-label bottom"
                   [ngClass]="{validate : !!city.valid}">
                <span class="labelName">{{'informations.billing.city' | translate}}</span>
                <ng-select name="city"
                           #city="ngModel"
                           [ngModel]="address.city"
                           [editableSearchTerm]="true"
                           (search)="pushNewCity($event)"
                           (focus)="focusOn('city')"
                           (blur)="focusOut('city')"
                           [required]="required"
                           (change)="validCitySuggestion($event)"
                           [compareWith]="compareAlwaysFalse"
                           [isOpen]="shouldShowCitySuggestions()"
                           (clear)="clearCitySuggestions()">
                    <ng-option *ngFor="let suggestion of citySuggestions; trackBy:trackByCityName"
                               [value]="suggestion.name"
                    >{{suggestion.name}}</ng-option>
                </ng-select>
            </label>
        </div>
    </div>
</form>
