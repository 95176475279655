import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CustomerAccount,
    FrontInvoiceHttpService,
    MultipleOptions,
    OfferCriteria,
    FrontOfferHttpService,
    OptionSubscription,
    PackSubscription,
    FrontPackSubscriptionHttpService,
    StdUser,
    Subscription, HOST_THIRD_PARTY_ID
} from 'lib-front';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {ModeSubscriptionOption, OptionForm} from '../../../../../components/subscription-options/subscription-options.component';
import {AlertService} from '../../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {getOptionsFromPackSubscription} from '../../../../../utils/packSubscriptionUtils.service';

@Component({
    selector: 'add-options',
    templateUrl: './add-options.component.html',
    styleUrls: ['./add-options.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class AddOptionsComponent implements OnInit {
    ModeSubscriptionOption = ModeSubscriptionOption;

    connectedUser: StdUser;
    packSubscription: PackSubscription;
    packSubscriptionId: string;
    offerId: string;
    options: Array<OptionSubscription>;
    newOptions: Array<OptionForm>;
    optionsToSubscribe: Array<OptionForm>;
    totalHours: number;
    totalPrice: number;
    currencyUnit: string;
    loading: boolean = false;
    canOrderOption: boolean = false;

    constructor(private route: ActivatedRoute,
        private packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private offerHttpService: FrontOfferHttpService,
        private invoiceHttpService: FrontInvoiceHttpService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private alertService: AlertService,
        private router: Router,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {
    }

    ngOnInit(): void {
        this.currencyUnit = '';

        this.route.data
            .pipe(
                tap(data => this.connectedUser = data.user.user)
            )
            .subscribe(data => {
                this.invoiceHttpService.findUnpaidInvoice(this.connectedUser._id)
                    .subscribe(invoices => this.canOrderOption = !invoices.filter(invoice => invoice.blocking).length);
            });

        this.offerHttpService.find(this.thirdPartyId, new OfferCriteria())
            .subscribe(offer => {
                if (!!offer) {
                    this.route.data.subscribe(data => {
                        const packSubscription = data.packSubscription;

                        if (offer.subscriptionPackRefs.find(value => value === packSubscription._id)) {
                            this.offerId = offer._id;
                        }

                        this.packSubscription = packSubscription;
                        this.options = getOptionsFromPackSubscription(this.packSubscription);
                        this.calculateTotal();
                    });
                } else {
                    this.notificationService.error('offers.error');
                }
            }, () => this.notificationService.error('offers.error'));
    }

    setCurrencyUnit(currency: string): void {
        if (!!currency) {
            this.currencyUnit = currency;
        }
    }

    calculateTotal() {
        this.totalHours = 0;
        this.totalPrice = 0;

        if (this.options) {
            this.options.forEach(option => {
                this.totalHours += option.subscription.freeTime * option.number;
                this.totalPrice += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
            });
        }

        if (this.newOptions) {
            this.optionsToSubscribe = this.newOptions.filter(option => option && option.number && option.number > 0);
            this.optionsToSubscribe.forEach(option => {

                this.totalHours += option.subscription.freeTime * option.number;
                this.totalPrice += option.subscription.fixAmountWithVAT * option.number;
            });
        } else {
            this.optionsToSubscribe = [];
        }
    }

    addOptions() {
        if (!this.canOrderOption) {
            return;
        }

        this.alertService.confirm(this.translateService.instant('options.subscribe.alert')).subscribe(confirm => {
            if (!confirm) {
                return;
            }

            this.loading = true;

            const optionsPost: Array<MultipleOptions> = this.optionsToSubscribe
                .filter(option => option.number > 0)
                .map(option => {
                    let customerSubscriptionParent: {customerAccount: CustomerAccount, subscription: Subscription};
                    const customerAccounts = this.packSubscription.customerAccounts
                        .filter(customerAccount => customerAccount.subscriptions
                            .find(subscription => subscription.subscriptionDefRef === option.subscription.subscriptionDefParentRef));

                    if (customerAccounts.length) {
                        customerSubscriptionParent = {
                            customerAccount : customerAccounts[0],
                            subscription : customerAccounts[0].subscriptions
                                .find(subscription => subscription.subscriptionDefRef === option.subscription.subscriptionDefParentRef)
                        };
                    }

                    if (customerSubscriptionParent
                        && customerSubscriptionParent.customerAccount
                        && customerSubscriptionParent.subscription) {
                        return {
                            numberOfSubscription : option.number,
                            customerId : customerSubscriptionParent.customerAccount._id,
                            parentSubscriptionId : customerSubscriptionParent.subscription.id,
                            subscription : {
                                targetRef: customerSubscriptionParent.subscription.targetRef,
                                subscriptionDefRef: option.subscription._id
                            }
                        };
                    } else {
                        return null;
                    }
                })
                .filter(value => value);

            this.packSubscriptionHttpService.subscribeMultipleOption(optionsPost)
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe(() => {
                    this.notificationService.success('options.subscribe.success');
                    this.router.navigate(['/main/subscriptions']);
                });
        });
    }
}
