<div class="grid-x cell auto" [formGroup]="mediaForm">

    <label class="grid-y cell auto space-right-24 form-group" [hidden]="availableMediaFamilies?.length <= 1">
        <span class="cell">{{'media.activate.mediaFamily' | translate}}</span>
        <select class="cell" name="mediaFamily" formControlName="mediaFamily" [required]="true">
            <option value="{{mediaFamily._id}}"
                    *ngFor="let mediaFamily of availableMediaFamilies">
                {{mediaFamily.name}}
            </option>
        </select>
    </label>


    <label [hidden]="enteringCodeForAutoGeneratedMediaFamily"
           class="grid-y cell shrink space-right-24 form-group max-width-150">
        <span
            class="cell">{{'media.activate.validationCode' | translate}}</span>
        <span class="cell shrink align-middle activate-code-input">
                <input type="text"
                       name="validationCode"
                       formControlName="validationCode"/>
                <i class="cell shrink force-space-left-8 fas fa-exclamation-circle"
                   title="{{'media.activate.infobulle' | translate}}"></i>
            </span>
        <span class="note error" [hidden]="(!(mediaForm.get('validationCode').invalid  &&
         mediaForm.get('validationCode')?.dirty ||mediaForm.get('validationCode').invalid &&
         showValidateError))">{{'media.activate.required' | translate}}</span>
    </label>


    <label class="grid-y cell small-7 space-right-24 form-group max-width-200">
        <span class="cell">{{'media.activate.code' | translate}}</span>
        <input class="cell" type="text" name="code" formControlName="code"/>
        <span class="note error" [hidden]="(!(mediaForm.get('code').invalid  && mediaForm.get('code')?.dirty || mediaForm.get('code').invalid && showValidateError))">
            {{'media.activate.required' | translate}}</span>
    </label>
    <label class="grid-y cell auto space-right-24 form-group">
        <span class="cell">{{'media.activate.colaborator' | translate}}</span>
        <select formControlName="colaborator">
            <option value="{{subscriber._id}}" *ngFor="let subscriber of subscribers; trackBy: trackByUserId">
                {{subscriber.firstName}} {{subscriber.lastName}}
            </option>
        </select>
        <span class="note error" [hidden]="(!(mediaForm.get('colaborator').invalid  && mediaForm.get('colaborator')?.dirty ||mediaForm.get('colaborator').invalid &&
          showValidateError))">{{'media.activate.required' | translate}}</span>
    </label>
    <label class="grid-y cell auto space-right-24 form-group">
        <span class="cell">{{'media.activate.vehicle.title' | translate}}</span>
        <ng-select class="custom vehicle-select"
                   name="vehicle"
                   formControlName="vehicle"
                   [multiple]="false"
                   [closeOnSelect]="true"
                   [bindLabel]="'licenseNumber'"
                   [bindValue]="'id'"
                   [notFoundText]="'media.activate.vehicle.select.notFoundText' | translate"
                   [items]="vehicles">
        </ng-select>
    </label>
    <label class="grid-y cell auto space-right-24 form-group" [hidden]="availableSubscriptionPacks?.length <= 0">
        <span class="cell">{{'media.activate.subscriptionPacks' | translate}}</span>
        <ng-select class="custom"
                   name="subscriptionPacks"
                   formControlName="subscriptionPacks"
                   [multiple]="true"
                   [closeOnSelect]="false"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [notFoundText]="'media.subscriptionPack.select.notFoundText' | translate"
                   [required]="false"
                   [items]="availableSubscriptionPacks"></ng-select>
    </label>

    <div class="cell grid-x align-right small-2 alignBtn">
        <button class="cell shrink button round" type="button" (click)="validatePass()"
                [disabled]="mediaForm.invalid"
                [ngClass]="{disabled : mediaForm.invalid}"
                [hidden]="!last">
            <i class="fas fa-check"></i>
        </button>
        <img class="imgHeight" [src]="'/api/download/'+logoRef" [hidden]="last">
    </div>
</div>
