import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'reject-expense-report-popup',
    templateUrl: './reject-expense-report-popup.component.html',
    styleUrls: ['./reject-expense-report-popup.component.scss']
})
export class RejectExpenseReportPopupComponent {

    @Input() expenseReportNumberMessage: string;
    @Output() modalConfirm = new EventEmitter<string>();
    @Output() modalClose = new EventEmitter<void>();
    public refuseReason: string;
    public refuseReasonMissing = false;

    closeModal() {
        delete this.refuseReason;
        this.refuseReasonMissing = false;
        this.modalClose.emit();
    }

    rejectGroup() {
        if (!this.refuseReason) {
            this.refuseReasonMissing = true;
            return;
        }

        this.modalConfirm.emit(this.refuseReason);
    }
}
