<div class="input-item ion-no-padding cell auto force-space-right-0" [class.ghost]="waitingMake">
    <label class="inner-space-all-0 inner-space-right-8"
           [ngClass]="{validate : !!vehicleMake.valid, invalid : !vehicleMake.valid && vehicleMake?.dirty}">
        <span class="labelName">{{'vehicleSelector.vehicleMake' | translate}}</span>
        <select class="xlarge-ghost-size mb-5"
                [(ngModel)]="currentVehicle.make"
                (ngModelChange)="onMakeChange()"
                #vehicleMake="ngModel">

            <ng-container *ngIf="makes">
                <option *ngFor="let vehicleMake of makes; trackBy: trackByName"
                        [value]="vehicleMake.name">{{vehicleMake.name}}</option>
            </ng-container>

        </select>
    </label>


</div>

<div class="input-item ion-no-padding cell auto force-space-right-0 force-space-left-0"
     [class.ghost]="waitingMake || waitingModel">
    <label class="inner-space-all-0 inner-space-right-8"
           [ngClass]="{validate : !!vehicleModel.valid, invalid : !vehicleModel.valid && vehicleModel?.dirty}">
        <span class="labelName">{{'vehicleSelector.vehicleModel' | translate}}</span>
        <select class="xlarge-ghost-size mb-5"
                [(ngModel)]="currentVehicle.model"
                (ngModelChange)="onModelChange()"
                [disabled]="isModelDisabled()"
                #vehicleModel="ngModel">


            <ng-container *ngIf="models">
                <option *ngFor="let vehicleModel of models; trackBy: trackByName"
                        [value]="vehicleModel.name">{{vehicleModel | fleetVehicleModelFormatter}}</option>
            </ng-container>
        </select>
    </label>


</div>

<div class="input-item ion-no-padding cell auto force-space-right-0 force-space-left-0"
     [class.ghost]="waitingMake || waitingModel || waitingVersion">
    <label class="inner-space-all-0"
           [ngClass]="{validate : !!vehicleVersion.valid, invalid : !vehicleVersion.valid && vehicleVersion?.dirty}">
        <span class="labelName">{{'vehicleSelector.vehicleVersion' | translate}}</span>
        <select class="xlarge-ghost-size mb-5"
                [(ngModel)]="currentVehicle.version"
                (ngModelChange)="onVersionChange()"
                [disabled]="isVersionDisabled()"
                #vehicleVersion="ngModel">


            <ng-container *ngIf="!waitingVersion && versions">
                <option *ngFor="let vehicleVersion of versions; trackBy: trackById"
                        [value]="vehicleVersion.version">{{vehicleVersion | fleetVehicleVersionFormatter}}</option>
            </ng-container>
        </select>
    </label>

</div>
