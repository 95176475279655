import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FrontEndService} from '../../../../services/frontEnd.service';

@Injectable()
export class PaymentGuard implements CanActivate {
    constructor(private readonly frontEndService: FrontEndService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig),
                tap(fleetConfig => {
                    if (!fleetConfig.showPaymentSection) {
                        this.router.navigate(['/home']);
                    }
                }),
                map(_ => true));
    }
}
