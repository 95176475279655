<div class="subscriptionContainer">
    <div class="offer-block image fix-height subscriptionContainer-block">
        <div class="subscriptionContainer-block-wrapper">
            <div>
                <div class="grid-y offer-block-title" *ngIf="mode !== 'UPDATE_NEW'">
                    <h4 class="cell offer-block-title-color text-center"
                        [innerHTML]="packSubscription?.subscriptionPackName"></h4>
                    <h5 class="cell offer-block-subtitle-color space-top-10 text-center"
                          [innerHTML]="subscriptionPack?.subtitle"></h5>
                </div>
                <div class="grid-y offer-block-title" *ngIf="mode === 'UPDATE_NEW'">
                    <h4 class="cell offer-block-title-color"
                          [innerHTML]="subscriptionPack?.name"></h4>
                    <h5 class="cell offer-block-subtitle-color space-top-8"
                          [innerHTML]="subscriptionPack?.subtitle"></h5>
                </div>

                <div class="priceContainer">
                    <!-- PRICE for all mode -->
                    <div class="grid-x align-bottom"
                         *ngIf="showSubscriptionPackPrice; else showOverloadedPrice">
                        <h6 class="cell small-24 text-center">
                            {{
                            (subscriptionPack?.fixPriceWithVat
                                | currency:'EUR': subscriptionPack?.currencyUnit || packSubscription?.currencyUnit || '')
                            }}
                            TTC
                        </h6>
                    </div>

                    <ng-template #showOverloadedPrice>
                        <h6 class="cell small-24 text-center">
                            {{ subscriptionPack?.overloadedFixPriceLabel }}
                        </h6>
                    </ng-template>

                    <!-- ENGAGEMENT for SUBSCRIPTION -->
                    <div *ngIf="mode === 'SUBSCRIPTION' && !!subscriptionPack?.engagementDuration ">
                        <h6 class="cell small-24"
                            [hidden]="showEndEngagement">
                            {{'subscription.pack.engagement.title' | translate:{
                            month: subscriptionPack?.engagementDuration
                        }
                            }}
                        </h6>
                        <h6 class="cell small-24 offer-block-content"
                            [hidden]="!showEndEngagement">
                            {{'subscription.pack.engagement.end' | translate:{
                            month: subscriptionPack?.engagementDuration,
                            endDate: packSubscription?.engagementEndDate | date : "shortDate"
                        }
                            }}
                        </h6>
                        <h6 class="cell small-24 offer-block-content"
                            [hidden]="!showEndEngagement || !packSubscription?.terminationScheduled">
                            {{'subscription.pack.termination.date' | translate:{
                            terminationDate: terminationDate | date : "shortDate"
                        }
                            }}
                        </h6>
                    </div>

                    <!-- ENGAGEMENT and MEDIA for UPDATE_OLD -->
                    <div *ngIf="mode === 'UPDATE_OLD'">
                        <div class="grid-x align-bottom space-top-16">
                            <h6 class="cell small-24" [hidden]="packSubscription?.engagementEndDate">
                                {{'subscription.pack.engagement.title' | translate:{
                                month: subscriptionPack?.engagementDuration
                            }
                                }}
                            </h6>
                            <h6 class="cell small-24 offer-block-content"
                                [hidden]="!packSubscription?.engagementEndDate">
                                {{'subscription.pack.engagement.end' | translate:{
                                month: subscriptionPack?.engagementDuration,
                                endDate: packSubscription?.engagementEndDate | date : "shortDate"
                            }
                                }}
                            </h6>
                        </div>
                        <div class="grid-x align-bottom space-top-16">
                            <h6 class="cell small-24 offer-block-content">
                                {{'subscription.pack.pass.number.title' | translate:{passNumber: packSubscription?.nbMedias || 0} }}
                            </h6>
                        </div>
                    </div>

                    <!-- ENGAGEMENT and MEDIA for UPDATE_NEW -->
                    <div *ngIf="mode === 'UPDATE_NEW'">
                        <div class="grid-x align-bottom space-top-16">
                            <h6 class="cell small-24" [hidden]="engagementEndDate">
                                {{'subscription.pack.engagement.title' | translate:{
                                month: subscriptionPack?.engagementDuration
                            }
                                }}
                            </h6>
                            <h6 class="cell small-24 offer-block-content" [hidden]="!engagementEndDate">
                                {{'subscription.pack.engagement.end' | translate:{
                                month: subscriptionPack?.engagementDuration + 1,
                                endDate: engagementEndDate | date : "shortDate"
                            }
                                }}
                            </h6>
                            <h6 class="cell small-24 offer-block-content" [hidden]="!terminationDate">
                                {{'subscription.pack.termination.date' | translate:{
                                terminationDate: terminationDate | date : "shortDate"
                            }
                                }}
                            </h6>
                        </div>
                        <div class="grid-x align-bottom space-top-16">
                            <h6 class="cell small-24 offer-block-content" *ngIf="!!subscriptionPack">
                                {{passRetportedAndOrdered()}}
                            </h6>
                        </div>
                    </div>

                    <div *ngIf="options && options.length">
                        <subscription-options-view class="cell shrink"
                                                   [options]="options"
                                                   [smallBackground]="true"
                                                   [smallTitle]="true"
                                                   [showBar]="true"
                                                   [showOptionTiles]="false"
                                                   [showTotal]="false"></subscription-options-view>
                    </div>
                </div>
            </div>
            <!-- TITLE for all mode -->

            <!-- CGU for SUBSCRIPTION -->
            <div class="grid-x align-center cards-bottom small-24 space-top-15">
                <a class="cell shrink" target="_blank"
                   href="{{getCGUUrl(subscriptionPack?.termsAndConditionsI18nCode)}}"
                   *ngIf="mode === 'SUBSCRIPTION'">
                    {{'subscription.pack.cgu' | translate}}</a>
                <div class="moreDetail" *ngIf="mode === 'SUBSCRIPTION'">
                    <a class="cell shrink" [routerLink]="[]"></a>
                    <i class="moreDetail-arrow fas fa-arrow-right"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="grid-y cell shrink align-middle text-center" *ngIf="mode === 'SUBSCRIPTION'">
    <label class="cell shrink subscription-label">{{'subscription.pack.start' | translate:{
        startDate:
            packSubscription?.subscriptionStartDate | date : "shortDate"
    } }} -
        <span>{{'subscription.pack.pass.number.title' | translate:{passNumber: packSubscription?.nbMedias || 0} }}</span>
    </label>
    <small class="cell shrink note" [class.hiddenPass]="!subscriptionPack?.maxMediasByAccount">
        ({{'subscription.pack.pass.max' | translate:{max: subscriptionPack?.maxMediasByAccount} }})
    </small>
</div>
