import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FrontTariffHttpService, TariffDetailDto, TariffDto, TariffElementDto, TariffType} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'indemnity-form',
    templateUrl: './indemnity-form.component.html',
    styleUrls: ['./indemnity-form.component.scss']
})
export class IndemnityFormComponent {

    private _indemnity: TariffDto;
    public indemnityDetails: TariffDetailDto;

    get indemnity(): TariffDto {
        return this._indemnity;
    }

    @Input()
    set indemnity(value: TariffDto) {
        this._indemnity = value;

        if (this._indemnity.id) {
            this.tariffHttpService.findTariffById(this._indemnity.id)
                .subscribe(indemnityDetail => this.indemnityDetails = indemnityDetail);
        } else {
            this.buildEmptyIndemnityDetails();
        }
    }

    @Output() indemnityUpdated = new EventEmitter<TariffDto>();

    savingTariff: boolean;

    constructor(private tariffHttpService: FrontTariffHttpService,
        private notificationService: NotificationService) {
    }

    public save(): void {
        if (!this.indemnity.id) {
            this.createTariff();
        } else {
            this.updateTariff();
        }
    }

    public isIndemnityEnergyZero(): boolean {
        const energyPrice = this.indemnityDetails?.tariffElements?.[0]?.energyPrice;
        return energyPrice != null ? energyPrice === 0 : true;
    }

    private createTariff(): void {
        this.savingTariff = true;
        this.tariffHttpService.createTariff(this.indemnityDetails)
            .pipe(
                finalize(() => this.savingTariff = false)
            )
            .subscribe(createdIndemnity => {
                this.notificationService.success('config.indemnity.create.success',
                    {name: createdIndemnity.name});
                this.indemnity = createdIndemnity;
            },
            (error) => {
                let errorKey = 'config.indemnity.create.error';

                if (error.error?.labelKey) {
                    errorKey = error.error.labelKey;
                }

                this.notificationService.error(errorKey,
                    {name: this.indemnity.name});
            });
    }

    private updateTariff(): void {
        this.savingTariff = true;
        this.tariffHttpService.updateTariff(this.indemnityDetails)
            .pipe(
                finalize(() => this.savingTariff = false)
            )
            .subscribe(updatedIndemnity => {
                this.notificationService.success('config.indemnity.update.success',
                    {name: updatedIndemnity.name});
                this.indemnityDetails = updatedIndemnity;
                this.indemnityUpdated.emit(updatedIndemnity);
            },
            () => this.notificationService.error('config.indemnity.update.error',
                {name: this.indemnityDetails.name}));
    }

    private buildEmptyIndemnityDetails(): void {
        this.indemnityDetails = new TariffDetailDto();
        this.indemnityDetails.type = TariffType.INDEMNITY;
        const tariffElem = new TariffElementDto();
        tariffElem.startTime = null;
        tariffElem.endTime = null;

        this.indemnityDetails.tariffElements = [tariffElem];
    }
}
