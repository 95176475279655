import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
    AdditionalInformationAnswers, FrontEndInfo,
    FrontMediaHttpService,
    FrontReferenceHttpService, MediaCanOrderDto,
    MediaOrderContext,
    SubscriptionPack,
} from 'lib-front';
import {FrontEndService} from '../../../services/frontEnd.service';
import {map} from 'rxjs/operators';
import {MediaOrderDto} from '../../../domain/mediaOrderDto';
import {Subscription} from 'rxjs';

/**
 * Component in reactive form.
 */
@Component({
    selector: 'register-fleet',
    templateUrl: './register-fleet.component.html',
    styleUrls: ['./register-fleet.component.scss'],
    host: {'class': 'cell grid-y'}
})
export class RegisterFleetComponent implements OnChanges, OnInit, OnDestroy {
    @Input() subscriptionPack: SubscriptionPack;
    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() fleetEvent: EventEmitter<FleetFormValue> = new EventEmitter<FleetFormValue>();

    form: UntypedFormGroup;

    public mediaOrderContext: MediaOrderContext;
    public mediaCanOrderDtoList: MediaCanOrderDto[];
    public additionalInformationAnswers: AdditionalInformationAnswers = {};
    public calculatingMediaCost: boolean = false;
    public frontEndInfo: FrontEndInfo;

    private frontEndSubscription: Subscription;

    constructor(private referenceService: FrontReferenceHttpService,
        private fb: UntypedFormBuilder,
        private readonly frontEndService: FrontEndService,
        private mediaHttpService: FrontMediaHttpService) {
    }

    ngOnInit() {
        this.frontEndSubscription = this.frontEndService.currentFrontEndInfo$.pipe(
            map(frontEndInfo => this.frontEndInfo = frontEndInfo)
        ).subscribe();
    }

    ngOnDestroy() {
        this.frontEndSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.subscriptionPack && changes.subscriptionPack.currentValue) {
            this.mediaOrderContext = MediaOrderContext.REGISTER;
            this.form = this.fb.group(
                {
                    vehicles: [],
                    mediaOrder: []
                });

            this.frontEndService.currentFrontEndInfo$
                .pipe(
                    map(frontEndInfo => frontEndInfo.fleetConfig)
                )
                .subscribe(fleetConfig => {
                    this.mediaHttpService.fetchOrderableBySubscriptionPackId(this.subscriptionPack._id,
                        this.mediaOrderContext)
                        .subscribe(mediaCanOrderDtoList => {
                            this.mediaCanOrderDtoList = mediaCanOrderDtoList;

                            if (mediaCanOrderDtoList.length) {
                                const mediaOrderFormValue: MediaOrderDto = new MediaOrderDto();
                                mediaOrderFormValue.mediaCanOrderDto = mediaCanOrderDtoList[0];
                                this.form.get('mediaOrder').setValue(mediaOrderFormValue);
                            } else {
                                this.form.get('mediaOrder').disable();
                            }
                        });
                });
        }
    }

    public stepBack() {
        this.back.emit();
    }

    setCalculatingMediaCost(status: boolean) {
        this.calculatingMediaCost = status;
    }

    onSubmit() {
        if (this.form.valid) {
            const formValue: FleetFormValue = this.form.value;
            this.fleetEvent.emit(formValue);
        }
    }
}

export class FleetFormValue {
    mediaOrder: MediaOrderDto;
}
