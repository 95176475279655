import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'bypassSecurityTrustResourceUrl'
})
export class BypassSecurityTrustResourceUrlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    /**
     * Trust the given url and bypass security
     * @see https://angular.io/api/platform-browser/DomSanitizer#bypasssecuritytrustresourceurl
     */
    transform(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

}
