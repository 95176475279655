import {NgModule} from '@angular/core';
import {MainComponent} from './main.component';
import {CommonModule} from '@angular/common';
import {ChargeConsumptionsModule} from './chargeConsumptions/chargeConsumptions.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {InvoicesModule} from './invoices/invoices.module';
import {SubscriptionsModule} from './subscriptions/subscriptions.module';
import {MediaModule} from './media/media.module';
import {ConfigurationModule} from './configuration/configuration.module';
import {ImportsModule} from './imports/imports.module';
import {ChargeAreasModule} from './chargeAreas/charge-areas.module';
import {ExpenseReportModule} from './expenseReport/expense-report.module';
import {FinancesModule} from './finances/finances.module';
import {CurrentSubscriberRolesGuard} from './currentSubscriberRoles.guard';
import {GuardModule} from '../../../guard/guard.module';

@NgModule({
    declarations: [
        MainComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FinancesModule,
        InvoicesModule,
        ChargeConsumptionsModule,
        ChargeAreasModule,
        SubscriptionsModule,
        MediaModule,
        ExpenseReportModule,
        ConfigurationModule,
        ImportsModule,
        GuardModule
    ],
    providers: [
        CurrentSubscriberRolesGuard
    ]
})
export class MainModule {
}
