import {Pipe, PipeTransform} from '@angular/core';
import {MetaChargeConsumptionDto} from '../domain/meta/metaChargeConsumptionDto';
import {MetaReservationConsumptionDto} from '../domain/meta/metaReservationConsumptionDto';
import {MetaOtpConsumptionDto} from '../domain/meta/metaOtpConsumptionDto';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'fleetMetaConsumptionStation'
})
export class FleetMetaConsumptionStationPipe implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {}

    transform(metaConsumption: MetaChargeConsumptionDto | MetaReservationConsumptionDto | MetaOtpConsumptionDto): string {
        if (!metaConsumption.chargeAreaName) {
            return this.translateService.instant('common.unavailable');
        }

        return metaConsumption.chargeAreaName + (metaConsumption.stationName ? ' - ' + metaConsumption.stationName : '');
    }
}
