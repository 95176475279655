import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChargingProgram, FrontVehiclePlanningService, SocEventType} from 'lib-front';
import {NotificationService} from '../../../services/utils/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'fleet-charging-program-delete',
    templateUrl: './fleet-charging-program-delete.component.html',
    styleUrls: ['./fleet-charging-program-delete.component.scss']
})
export class FleetChargingProgramDeleteComponent {
    @Input() vehicleId: string;
    @Input() chargingProgram: ChargingProgram;
    @Input() socEventType: SocEventType;

    @Output() readonly cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly delete: EventEmitter<void> = new EventEmitter<void>();

    SocEventType = SocEventType;

    deletingProgram: boolean;

    constructor(private readonly vehiclePlanningService: FrontVehiclePlanningService,
        private readonly notificationService: NotificationService) {
    }

    public onCancel(): void {
        this.cancel.emit();
    }

    public deleteProgram(chargingProgramId: string): void {
        if (this.socEventType === SocEventType.ONE_TIME) {
            this.deleteOneTimeProgram(this.vehicleId, chargingProgramId);
        } else {
            this.deleteRecurringProgram(this.vehicleId, chargingProgramId);
        }
    }

    private deleteOneTimeProgram(vehicleId: string, chargingProgramId: string): void {
        this.deletingProgram = true;
        this.vehiclePlanningService.deleteRequirementOneTime(vehicleId, chargingProgramId)
            .pipe(
                finalize(() => this.deletingProgram = false)
            )
            .subscribe(
                (data) => {
                    this.delete.emit();
                    this.notificationService.success('fleet.program.delete.oneTime.notificationSuccess');
                },
                () => {
                    this.notificationService.error('fleet.program.delete.oneTime.notificationError');
                }
            );
    }

    private deleteRecurringProgram(vehicleId: string, chargingProgramId: string): void {
        this.deletingProgram = true;
        this.vehiclePlanningService.deleteRequirementRecurring(vehicleId, chargingProgramId)
            .pipe(
                finalize(() => this.deletingProgram = false)
            )
            .subscribe(
                () => {
                    this.delete.emit();
                    this.notificationService.success('fleet.program.delete.recurring.notificationSuccess');
                },
                () => {
                    this.notificationService.error('fleet.program.delete.recurring.notificationError');
                }
            );
    }
}
