<div class="grid-y cell auto has-space">
    <div class="grid-y cell auto main-container">

        <div class="space-bottom-32">
            <div class="grid-x cell shrink align-justify align-middle">
                <h3 class="grid-x align-middle secondary block-title space-bottom-16">
                    {{'invoice.payment.title' | translate}}
                </h3>
                <div class="cell auto"></div>
                <a class="cell shrink button secondary color force-space-right-8" [href]="getExportResultsUrl()">
                    {{'invoice.payment.export' | translate}}
                </a>
                <a type="button" class="cell shrink button secondary" [routerLink]="'..'">
                    {{'invoice.back' | translate}}
                </a>
            </div>

            <form class="grid-x align-bottom grid-padding-x cell small-24 xlarge-6">
                <label class="cell auto">
                    <span>{{'invoice.payment.search.invoiceId' | translate}}</span>
                    <input class="cell auto" type="text" [(ngModel)]="searchInvoiceForm.invoiceId" name="invoiceId"
                           [placeholder]="'invoice.payment.search.number.placeholder' | translate">
                </label>
                <label class="cell auto">
                    <span>{{'invoice.payment.search.startDate.title' | translate}}</span>
                    <date-picker class="full-input" [(ngModel)]="searchInvoiceForm.startDate" name="startDate"
                                 [placeholder]="'invoice.payment.search.startDate.placeholder' | translate"></date-picker>
                </label>
                <label class="cell auto">
                    <span>{{'invoice.payment.search.endDate.title' | translate}}</span>
                    <date-picker class="full-input" [(ngModel)]="searchInvoiceForm.endDate" name="endDate"
                                 [placeholder]="'invoice.payment.search.endDate.placeholder' | translate"></date-picker>
                </label>
                <div class="cell shrink space-bottom-8">
                    <button class="button" type="submit"
                            (click)="invoiceSearch()"
                            [iziviaLoading]="searchingInvoices">
                        {{'invoice.payment.search.button' | translate}}
                    </button>
                </div>
            </form>

        </div>

        <div class="grid-x cell shrink space-bottom-8 space-top-16 align-middle">
            <next-last-page class="cell shrink" [pageNumber]="invoicesPage" [maxPage]="nbrPageMax"
                            (changePage)="getInvoices($event)"></next-last-page>
        </div>

        <div class="cell small-24">
            <table>
                <thead>
                <tr>
                    <th>{{'invoice.payment.table.date' | translate}}</th>
                    <th>{{'invoice.payment.table.number' | translate}}</th>
                    <th>{{'invoice.payment.table.type' | translate}}</th>
                    <th>{{'invoice.payment.table.totalHT' | translate}}</th>
                    <th>{{'invoice.payment.table.totalTTC' | translate}}</th>
                    <th>{{'invoice.payment.table.operator' | translate}}</th>
                    <th>{{'invoice.payment.table.status' | translate}}</th>
                    <th>{{'invoice.payment.table.paymentMeans' | translate}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody class="border">
                <tr *ngFor="let invoice of invoices; trackBy: trackByInvoiceId">
                    <td>{{invoice.invoiceDate | date : 'short'}}</td>
                    <td>{{invoice.invoiceId}}</td>
                    <td>
                        <div *ngFor="let row of invoice.rows">{{row.label | translate}}</div>
                    </td>
                    <td>{{invoice.grossAmount | currency:'EUR': invoice.currencyUnit}}</td>
                    <td>{{invoice.netAmount | currency:'EUR' : invoice.currencyUnit}}</td>
                    <td>{{invoice.providerName}}</td>
                    <td [innerHTML]="invoice | fleetInvoiceStatus"></td>
                    <!-- amount = 0 payée automatiquement -->
                    <td *ngIf="invoice.paymentContext.paymentType === null">{{'invoice.payment.table.paymentType.' + PaymentType.OTHER | translate}}</td>
                    <td *ngIf="invoice.paymentContext.paymentType
                    && invoice.paymentContext.paymentType !== PaymentType.CREDIT_NOTE">
                        {{'invoice.payment.table.paymentType.' + invoice.paymentContext.paymentType | translate}}</td>
                    <td *ngIf="invoice.paymentContext.paymentType === PaymentType.CREDIT_NOTE">{{'invoice.payment.table.paymentType.' + invoice.paymentContext.paymentType | translate }} {{'invoice.payment.table.creditNoteAV' | translate}}{{invoice.invoiceId}}</td>
                    <td>
                        <a class="button round eye" [href]="showInvoice(invoice)"
                           target="_blank" rel="noopener noreferrer"><i class="fas fa-eye"></i></a>
                    </td>
                </tr>
                </tbody>
            </table>
            <next-last-page class="cell shrink" [pageNumber]="invoicesPage" [maxPage]="nbrPageMax"
                            (changePage)="getInvoices($event)"></next-last-page>
        </div>

        <div class="grid-y cell auto align-center align-middle message-empty" *ngIf="!invoices?.length">
            <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
            <span class="message-empty-label">{{'chargeConsumptions.no.result' | translate}}</span>
        </div>
    </div>
</div>
