import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ReportingTab} from '../../../../domain/reportingTab';
import {ReportingService} from '../../../../services/business/reporting.service';

@Component({
    selector: 'reporting',
    templateUrl: './reporting.view.html',
    styleUrls: ['./reporting.view.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ReportingView implements OnInit {

    public accessibleTabs: ReportingTab[];

    constructor(private readonly reportingService: ReportingService,
        public router: Router) {
    }

    ngOnInit(): void {
        this.reportingService.activeReportingTabs().subscribe(tabs => this.accessibleTabs = tabs);
    }

    trackByTabPath(index: number, tab: ReportingTab) {
        return tab?.path ?? index;
    }

}
