import {Pipe, PipeTransform} from '@angular/core';
import {Location} from 'lib-front';

@Pipe({
    name: 'fleetLocation',
    pure: false
})
export class FleetLocationPipe implements PipeTransform {
    transform(location: Location, locationType: LocationType = LocationType.ALL): string {
        if (!location) {
            return '';
        }

        const address: Array<string> = [];

        if (locationType === LocationType.ALL || locationType === LocationType.ROUTE) {
            if (location.streetNumber) {
                address.push(location.streetNumber);
            }
            if (location.route) {
                address.push(location.route);
            }
            if (location.extra) {
                address.push(location.extra);
            }
        }
        if (locationType === LocationType.ALL || locationType === LocationType.CITY) {
            if (location.postalCode) {
                address.push(location.postalCode);
            }
            if (location.city) {
                address.push(location.city);
            }
        }

        return address.join(' ');
    }
}

export enum LocationType {
    ALL = 'ALL',
    ROUTE = 'ROUTE',
    CITY = 'CITY'
}
