import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'messages-guide',
    templateUrl: './messages-guide.component.html',
    styleUrls: ['./messages-guide.component.scss']
})
export class MessagesGuideComponent {

    constructor() {
    }
}
