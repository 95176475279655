import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart, registerables, TooltipItem} from 'chart.js';
import 'chartjs-adapter-moment';
import {DataEntry, FrontChargeRecordHttpService} from 'lib-front';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'charge-record-chart',
    templateUrl: './charge-record-chart.component.html',
    styleUrls: ['./charge-record-chart.component.scss']
})
export class ChargeRecordChartComponent implements OnInit {
    @Input()
    set chargeRecordId(chargeRecordId: string) {
        if (!!chargeRecordId) {
            this.loading = true;
            this.chargeRecordService.findPowerConsumptions(chargeRecordId)
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe(powerConsumptions => this.initChart(powerConsumptions));
        }
    }

    @Input() startDate: string;
    @Input() stopDate: string;

    // Beware of the use of static: true
    // #canvas must NOT be used within *ngIf or *ngFor in the template otherwise you should change static to false
    // and wait for ngAfterViewInit to use it
    @ViewChild('canvas', {static: true}) canvas: ElementRef;

    chargeRecordCharts: Chart;
    public loading: boolean;

    constructor(private readonly chargeRecordService: FrontChargeRecordHttpService) {
    }

    ngOnInit(): void {
        Chart.register(...registerables);
    }

    private initChart(powerConsumptions: DataEntry[]) {
        this.chargeRecordCharts = new Chart(this.canvas.nativeElement, {
            data: undefined,
            type: 'line',
            options: {
                scales: {
                    energy: {
                        axis: 'y',
                        type: 'linear',
                        position: 'left',
                        title: {
                            display: true,
                            text: 'kWh'
                        }
                    },
                    power: {
                        axis: 'y',
                        type: 'linear',
                        position: 'right',
                        title: {
                            display: true,
                            text: 'kW'
                        }
                    },
                    time: {
                        axis: 'x',
                        type: 'time',
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                minute: 'HH:mm'
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(tooltipItems: TooltipItem<any>[]): string | string[] {
                                if (tooltipItems && tooltipItems.length && tooltipItems[0].label) {
                                    const chartTooltipItem = tooltipItems[0];
                                    const date = moment(chartTooltipItem.parsed.x);
                                    return date.format('HH:mm');
                                } else {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        });

        if (powerConsumptions && !!this.startDate) {
            const stopDate = !!this.stopDate ? new Date(this.stopDate) : new Date();
            const sanitizedPowerConsumptions = powerConsumptions
                ? this.sanitizePowerConsumptions(powerConsumptions,
                    new Date(this.startDate).getTime() / 1000,
                    stopDate.getTime() / 1000) : [];
            // /1000 to convert ms to second
            this.chargeRecordCharts.data = {
                labels: sanitizedPowerConsumptions.map(dataEntry => {
                    return new Date(dataEntry.ts * 1000).toISOString();
                }),
                datasets: [
                    {
                        label: 'Énergie (kWh)',
                        borderColor: '#ffa02f',
                        backgroundColor: '#ffa02f',
                        fill: false,
                        data: sanitizedPowerConsumptions.map(dataEntry => {
                            return (dataEntry.v).toFixed(2) as number;
                        }),
                        yAxisID: 'energy'
                    },
                    {
                        label: 'Puissance (kW)',
                        borderColor: '#005BBB',
                        backgroundColor: '#005BBB',
                        fill: false,
                        data: this.toPower(sanitizedPowerConsumptions).map((dataEntry) => {
                            return (dataEntry.v).toFixed(2) as number;
                        }),
                        yAxisID: 'power',
                        tension: 0
                    }
                ]
            };
        }

        this.chargeRecordCharts.update();
    }

    private sanitizePowerConsumptions(powerConsumptions: DataEntry[], startDate: number, endDate: number) {
        powerConsumptions = powerConsumptions.sort((a, b) => a.ts - b.ts);
        let previousEndTime = startDate;
        let previousValue = 0;
        const newPowerConsumptionList = [];
        for (let i = 0; i < powerConsumptions.length; i++) {
            const powerConsumptionCopy = JSON.parse(JSON.stringify(powerConsumptions[i]));
            if (previousEndTime < powerConsumptionCopy.ts) {
                newPowerConsumptionList.splice(newPowerConsumptionList.length, 0, {
                    ts: previousEndTime + 1,
                    d: powerConsumptionCopy.ts - previousEndTime,
                    v: previousValue
                });
            }
            powerConsumptionCopy.v /= 1000;

            if (powerConsumptionCopy.ts > previousEndTime) {
                const startPowerConsumption = JSON.parse(JSON.stringify(powerConsumptionCopy));
                startPowerConsumption.v = previousValue;
                newPowerConsumptionList.splice(newPowerConsumptionList.length, 0, startPowerConsumption);
            }

            const endPowerConsumption = JSON.parse(JSON.stringify(powerConsumptionCopy));
            endPowerConsumption.ts = powerConsumptionCopy.ts + powerConsumptionCopy.d;
            newPowerConsumptionList.splice(newPowerConsumptionList.length, 0, endPowerConsumption);

            previousEndTime = endPowerConsumption.ts;
            previousValue = endPowerConsumption.v;
        }
        if (previousEndTime < endDate) {
            newPowerConsumptionList.splice(newPowerConsumptionList.length, 0, {
                ts: endDate,
                d: 0,
                v: previousValue
            });
        }
        return newPowerConsumptionList;
    }

    private toPower(powerConsumptions: DataEntry[]) {
        const powerList = [];

        powerConsumptions.forEach((dataEntry, index) => {
            const nextDataEntry = index < powerConsumptions.length ? powerConsumptions[index + 1] : null;
            if (nextDataEntry) {
                const energy = nextDataEntry.v - dataEntry.v;
                const power = energy === 0 || dataEntry.d === 0 ? 0 : energy / (dataEntry.d / 3600);
                powerList.splice(powerList.length, 0, {ts: dataEntry.ts, v: power});
                powerList.splice(powerList.length, 0, {ts: nextDataEntry.ts, v: power});
            } else {
                powerList.splice(powerList.length, 0, {ts: dataEntry.ts, v: 0});
            }
        });

        return powerList;

    }
}
