<div class="cell auto app-view-content">
    <div class="grid-y cell auto main-container has-space force-space-bottom-0">
        <div class="grid-x cell shrink align-justify space-bottom-8 space-top-8">
            <h3 class="grid-x align-middle secondary block-title">
                <i class="fas fa-charging-station space-right-8"></i>
                {{'chargeAreas.edit.title' | translate:{chargeAreaName: chargeArea?.name} }}
            </h3>

            <a type="button" class="cell shrink button secondary" [routerLink]="['/main/chargeAreas', chargeAreaId]">
                {{'chargeAreas.edit.back' | translate}}
            </a>
        </div>
        <charge-area-edit [chargeArea]="chargeArea"></charge-area-edit>
    </div>
</div>
