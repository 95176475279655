import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FinancesView} from './finances.view';
import {InvoicesModule} from '../invoices/invoices.module';
import {ComponentsModule} from '../../../../components/components.module';
import {RouterModule} from '@angular/router';
import {IncomesView} from './incomes/incomes.view';
import {MomentModule} from 'ngx-moment';
import {IncomeHistoryComponent} from './income-history/income-history.component';
import {InfraManagementTariffCreationEnabledGuard} from './infra-management-tariff-creation-enabled.guard';

@NgModule({
    declarations: [
        FinancesView,
        IncomesView,
        IncomeHistoryComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        InvoicesModule,
        ComponentsModule,
        RouterModule,
        MomentModule
    ],
    providers: [
        InfraManagementTariffCreationEnabledGuard
    ]
})
export class FinancesModule {

}
