import {Component, OnInit} from '@angular/core';
import {FrontTariffHttpService, TariffDto, TariffType} from 'lib-front';
import {cloneDeep} from 'lodash-es';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'indemnity-configuration',
    templateUrl: './indemnity-configuration.component.html',
    styleUrls: ['./indemnity-configuration.component.scss']
})
export class IndemnityConfigurationComponent implements OnInit {

    public editMode = false;

    public indemnities: TariffDto[] = [];
    public selectedIndemnity: TariffDto;
    public loadingIndemnities: boolean;

    constructor(private tariffHttpService: FrontTariffHttpService) {
    }

    ngOnInit(): void {
        this.loadIndemnities();
    }

    public switchEditMode(): void {
        this.buildEmptyIndemnity();
        this.editMode = !this.editMode;
    }

    public switchListMode(): void {
        this.loadIndemnities();
        this.editMode = !this.editMode;
    }

    public showIndemnityDetails(indemnity: TariffDto): void {
        this.selectedIndemnity = cloneDeep(indemnity);
    }

    private loadIndemnities(): void {
        this.loadingIndemnities = true;
        this.tariffHttpService.fetchTariffs({type: TariffType.INDEMNITY})
            .pipe(
                finalize(() => this.loadingIndemnities = false)
            )
            .subscribe(indemnities => {
                this.indemnities = indemnities;

                if (this.indemnities.length > 0) {
                    this.selectedIndemnity = this.indemnities[0];
                }
            });
    }

    private buildEmptyIndemnity(): void {
        this.selectedIndemnity = new TariffDto();
    }

    onIndemnityUpdate(indemnityUpdated: TariffDto): void {
        const index = this.indemnities.findIndex(tariff => tariff.id === indemnityUpdated.id);
        this.indemnities[index] = indemnityUpdated;
    }
}
