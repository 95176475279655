<div class="grid-y calendar">
    <div class="cell shrink">
        <div class="grid-x grid-padding-x align-middle calendar-header">
            <div class="cell shrink">
                <div class="grid-x grid-padding-x align-middle">
                    <div class="cell shrink">
                        <div class="btn-group">
                            <div class="btn btn-outline-secondary calendar-header_day"
                                 mwlCalendarToday
                                 [(viewDate)]="viewDate">
                                {{'fleet.calendar.today' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="cell shrink">
                        <div class="grid-x grid-padding-x">
                            <div class="button primary small margin-right-8"
                                 mwlCalendarPreviousView
                                 [view]="view"
                                 [(viewDate)]="viewDate">
                                <i class="fas fa-chevron-left"></i>
                            </div>
                            <div class="button primary small"
                                 mwlCalendarNextView
                                 [view]="view"
                                 [(viewDate)]="viewDate">
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="cell shrink">
                        <h3 class="primary">{{ viewDate | calendarDate:(view + 'ViewTitle'):'fr' }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-x grid-padding-x align-middle">
            <div class="cell auto">
                <tabs-round (click)="setView()">
                    <tab-round #monthTab [title]="'fleet.calendar.month' | translate" tab-section>
                        <mwl-calendar-month-view
                            [viewDate]="viewDate"
                            [events]="events"
                            [refresh]="refresh"
                            [cellTemplate]="customCellTemplate"
                            (beforeViewRender)="beforeMonthViewRender($event)"
                            (dayClicked)="addNewProgram($event)"
                            (eventClicked)="openExistingProgram($event)">
                        </mwl-calendar-month-view>
                    </tab-round>
                    <tab-round #weekTab [title]="'fleet.calendar.week' | translate" tab-section>
                        <mwl-calendar-week-view
                            [viewDate]="viewDate"
                            [events]="events"
                            [refresh]="refresh"
                            (beforeViewRender)="beforeWeekViewRender($event)"
                            (dayHeaderClicked)="addNewProgram($event)"
                            (hourSegmentClicked)="addNewProgram($event)"
                            (eventClicked)="openExistingProgram($event)">
                        </mwl-calendar-week-view>
                    </tab-round>
                    <tab-round #dayTab [title]="'fleet.calendar.day' | translate" tab-section>
                        <mwl-calendar-day-view
                            [viewDate]="viewDate"
                            [events]="events"
                            [refresh]="refresh"
                            (beforeViewRender)="beforeDayViewRender($event)"
                            (hourSegmentClicked)="addNewProgram($event)"
                            (eventClicked)="openExistingProgram($event)">
                        </mwl-calendar-day-view>
                    </tab-round>
                </tabs-round>
            </div>
        </div>
    </div>

    <ng-template #customCellTemplate let-day="day" let-locale="locale"
                 let-tooltipPlacement="tooltipPlacement"
                 let-eventClicked="eventClicked"
                 let-tooltipTemplate="tooltipTemplate"
                 let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDelay="tooltipDelay">
        <div class="cal-cell-top">
        <span class="cal-day-number">
            {{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
        <div *ngIf="day.events.length > 0">
            <div *ngFor="let event of day.events; index as i">
                <div class="cal-events"
                     (mwlClick)="eventClicked.emit({event: event})"
                     [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
                     [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
                     [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay">
                    {{event.title}}
                </div>
            </div>
        </div>
    </ng-template>
</div>

<izivia-popup #addProgramPopup [size]="'medium'">
    <fleet-charging-program *ngIf="addProgramPopup.isOpened()"
                            [vehicleId]="vehicleId"
                            [vehicleTimeZone]="vehicleTimeZone"
                            [vehicleLicenseNumber]="vehicleLicenseNumber"
                            [chargingProgramId]="chargingProgramId"
                            [chargingProgramDate]="chargingProgramDate"
                            [socEventType]="socEventType"
                            [existingEventDates]="existingEventsDates"
                            [applicationDay]="applicationDay"
                            [applicationTime]="applicationTime"
                            [estimates]="estimates"
                            [desiredChargeIndex]="desiredChargeIndex"
                            [daysOfWeek]="daysOfWeek"
                            [recurringFrom]="recurringFrom"
                            [recurringTo]="recurringTo"
                            (closeAddProgramPopup)="addProgramPopup.close()"
                            (updateCalendar)="updateCalendar()">
    </fleet-charging-program>
</izivia-popup>
