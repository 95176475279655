import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import moment, {Moment} from 'moment';
import {DatePickerComponent as NgDatePickerComponent, IDatePickerConfig, SingleCalendarValue} from 'ng2-date-picker';
import {assignIn} from 'lodash-es';
import {FleetCalendarMode} from '../../domain/FleetCalendarMode';
import dayjs, {Dayjs} from 'dayjs';
import {ToDayjsPipe} from '../../pipes/toDayjs.pipe';

@Component({
    selector: 'date-picker',
    templateUrl: './datePicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        }
    ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {

    @Input() mode: FleetCalendarMode = FleetCalendarMode.DAY;
    @Input() placeholder?: string;
    @Input() disabled?: boolean;

    @Input() set minDate(minDate: string | Dayjs | moment.Moment) {
        this._minDate = this.toDayjsPipe.transform(minDate);
        this.config = {
            ...this.config,
            min: this._minDate
        };
    }

    @Input() set maxDate(maxDate: string | Dayjs | moment.Moment) {
        this._maxDate = this.toDayjsPipe.transform(maxDate);
        this.config = {
            ...this.config,
            max: this._maxDate
        };
    }

    @Input() set locale(locale: string) {
        this._locale = locale ? locale : 'en';
        this.config = assignIn({locale: this.locale}, this.config);
    }

    @ViewChild('datePicker') datePicker: NgDatePickerComponent;

    public config: IDatePickerConfig;

    private onNgChange: (m: Moment) => void;
    private onNgTouched: () => void;
    private _maxDate: SingleCalendarValue;
    private _minDate: SingleCalendarValue;

    private _locale: string = 'en';
    public realDate: Dayjs;


    constructor(private readonly toDayjsPipe: ToDayjsPipe) {
    }

    ngOnInit(): void {
        this.config = {
            openOnFocus: false,
            firstDayOfWeek: 'mo',
            unSelectOnClick: false,
            closeOnSelectDelay: 0,
            min: this.minDate,
            max: this.maxDate,
            drops: 'down'
        };
    }

    /**
     * This event will receive every value (valid and invalid)
     */
    public onChange(date: any): void {
        if (!date) {
            this.onNgChange(undefined);
        } else if (dayjs.isDayjs(date) && date.isValid()) {
            this.onNgChange(moment(date.toISOString()));
        }
    }

    public onOpen(): void {
        this.onNgTouched();
    }

    public writeValue(obj: Dayjs): void {
        if (obj !== null) {
            this.realDate = obj;
        }
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    public selectCurrentDate(): void {
        this.realDate = dayjs();
        setTimeout(() => {
            this.datePicker.api.close();
        }, 0);
    }

    public get minDate(): SingleCalendarValue {
        return this._minDate;
    }

    public get maxDate(): SingleCalendarValue {
        return this._maxDate;
    }

    get locale(): string {
        return this._locale;
    }
}
