<div class="contactContainer">
    <h4>{{contact.name}}</h4>
    <div>
        <div class="contactContainer-description" [innerHTML]="contact.description"></div>
        <div class="space-top-16" *ngIf="contact.mail">
            <h6>{{'config.support.mail' | translate}} :
                <span><a href="mailto:{{contact.mail}}{{mailToSubject ? '?subject=' + mailToSubject : ''}}">
                    {{contact.mail}}
                </a></span>
            </h6>
        </div>
    </div>
    <div class="space-top-8 space-bottom-8" *ngIf="contact.phoneNumber">
        <h6>
            {{'config.support.phone' | translate}} :
            <span>
                {{contact.phoneNumber}}
            </span>
        </h6>
    </div>
    <div *ngIf="contact.otherContactName && contact.otherContactValue">
        <h6>
            {{contact.otherContactName}} :
            <span>
                {{contact.otherContactValue}}
            </span>
        </h6>
    </div>
</div>
