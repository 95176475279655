import {Component, Input} from '@angular/core';
import {Dictionary, StationDto} from 'lib-front';
import {KeyValue} from '@angular/common';

@Component({
    selector: 'collapsible-stations-selects',
    templateUrl: './collapsible-stations-selects.component.html',
    styleUrls: ['./collapsible-stations-selects.component.scss']
})
export class CollapsibleStationsSelectsComponent {

    @Input()
    public stationsByChargeArea: Dictionary<SelectableStation[]>;

    @Input()
    public disabledStationRefs: string[];

    @Input()
    public disabledReason: string;

    trackByKey(index: number, keyValue: KeyValue<string, any>) {
        return keyValue?.key ?? index;
    }
}

export interface SelectableStation extends StationDto {
    selected?: boolean;
}
