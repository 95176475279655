<div class="grid-x cell auto wrapper">
    <div class="columns cell auto">
        <h5 class="station-title">{{'stations.edit.chargePoint.title' | translate}}</h5>
        <div class="cell grid-x">
            <div class="cell auto plugType-content"
                 *ngFor="let plug of station?.plugTypes; trackBy: trackByPlugTypeName">
                        <span class="plugType-content-style">
                            <img [src]="'/api/download/' + plug.iconRef" [alt]="''" *ngIf="plug.iconRef"/>
                        </span>
                <span class="">{{'plugType.' + plug.name | translate}}</span>
            </div>
        </div>
    </div>

    <div class="columns shrink wrapper-buttons">
        <button type="button" class="button round color" [class.hidden]="selected">
            <i class="fas fa-chevron-down"></i>
        </button>
        <button type="button" class="button round color" [class.hidden]="!selected">
            <i class="fas fa-chevron-up"></i>
        </button>
    </div>
</div>
