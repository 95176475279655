<form *ngIf="!!tariff else emptyTariff"
      #tariffForm="ngForm"
      (submit)="confirmSave()"
      class="grid-x cell auto tariffEdit">
    <ng-container *ngIf="!savingTariff && !loading else spinner">
        <div class="grid-x align-middle small-24 tariffEdit-title">
            <div class="cell auto">
                <div class="grid-x">
                    <div class="cell auto">
                        <h4 *ngIf="!editMode else editingTitle"
                            class="tariffEdit-title-name">{{'tariff.name'|translate:{name: tariff.name} }}
                        </h4>
                    </div>
                    <div *ngIf="tariffCanBeModified(tariff)" class="cell shrink">
                        <button (click)="openApplicationDateSelectionPopup(); $event.preventDefault()"
                                [iziviaLoading]="isLoadingNbOfSubscribers"
                                class="cell shrink button tariffEdit-iconButton icon-btn secondary round"><i class="fas fa-pen"></i></button>
                    </div>
                </div>
                    <ng-template #editingTitle>
                        <h3 class="tariffEdit-title-name">{{'config.tariff.editing'|translate:{name: tariff.name} }}</h3>
                        <p class="tariffElementEdit-description" *ngIf="vatRate && fleetConfig.emoMention">
                            {{'config.tariff.subtitle' | translate:{vatRate: vatRate} }}
                        </p>
                        <p class="tariffElementEdit-description" *ngIf="vatRate && !fleetConfig.emoMention">
                            {{'config.tariff.subtitleWithoutEmoMention' | translate:{vatRate: vatRate} }}
                        </p>
                    </ng-template>
                <div [hidden]="editMode">
                    <h6 class="tariffEdit-ref-title">
                        {{'config.tariff.reference'|translate}} :
                        <span class="tariffEdit-ref-title-number">{{tariff.privateName}}</span>
                    </h6>
                </div>
            </div>
            <!--fix SMBP-12380 : desactivate edition-->
<!--            <div class="cell shrink">-->
<!--                <button [disabled]="true" (click)="toggleEditMode()" [hidden]="editMode" type="button"-->
<!--                        class="cell shrink button round eye disabled" >-->
<!--                    <i class="fas fa-pen"></i>-->
<!--                </button>-->
<!--            </div>-->
        </div>

        <div *ngIf="!editMode" class="cell pricingInfoContainer">
            <p *ngIf="fleetConfig.emoMention">{{'config.tariff.pricingInfo'|translate}} :</p>
            <p *ngIf="!fleetConfig.emoMention">{{'config.tariff.pricingInfoWithoutEmoMention'|translate}} :</p>
            <span [innerHtml]="tariff.pricingInfo | tariffPricingInfo"></span>
        </div>

        <div [hidden]="!editMode" class="grid-x cell small-24 align-middle">
            <h5 class="tariffEdit-code-title">{{'config.tariff.name'|translate}}</h5>
            <input class="cell small-12 tariffEdit-code-input" [(ngModel)]="tariff.name" name="name" required
                   type="text">
        </div>
        <div [hidden]="editMode" [class.tariffEdit-code]="!tariff.applyByQRCode">
            <ng-container *ngIf="!tariff.applyByQRCode; else applyByQRCodeTmpl">
                <div>
                    <div class="grid-x grid-margin-x align-middle">
                        <h5 class="cell shrink tariffEdit-code-title">
                            {{'config.tariff.token'|translate}} :
                            <span class="tariffEdit-code-title-number">{{tariff.tokenValue}}</span>
                        </h5>
                        <button type="button" ngxClipboard
                                (click)="showSuccessCopySubscriptionTokenToClipboard()"
                                [cbContent]="tariff.tokenValue"
                                title="{{'config.tariff.copyToken' | translate}}"
                                class="cell shrink button round secondary"><i class="fas fa-copy"></i></button>
                        <button type="button" ngxClipboard
                                (click)="showSuccessCopySubscriptionLinkToClipboard()"
                                [cbContent]="tariff.subscriptionUrl"
                                title="{{'config.tariff.copyLink' | translate}}"
                                class="cell shrink button round secondary"><i class="fas fa-link"></i></button>
                    </div>
                </div>
            </ng-container>

            <ng-template #applyByQRCodeTmpl>
                <h4 class="tariffEdit-code-title">{{'config.tariff.type.applyByQRCode'|translate}}</h4>
                <p class="tariffElementEdit-description">{{'config.tariff.type.paynow.readDescription'|translate}}</p>
            </ng-template>
        </div>

        <div class="cell" *ngIf="canCreatePaynowTariffs && tariff.status !== TariffStatus.UPCOMING">
            <ng-container >
                <div class="grid-x align-middle grid-padding-x">
                    <label class="grid-x align-middle label-margin">
                        <switch trueText=" "
                                falseText=" "
                                [ngModel]="tariff.applyByQRCode"
                                (ngModelChange)="setApplyMode($event)"
                                [class.disabled]="!editMode"
                                [disabled]="!editMode"
                                name="applyByQRCode">
                        </switch>
                        <span class="label-choice space-left-10 ">
                        {{'config.tariff.type.applyByQRCode' | translate}}
                            <i class="fas fa-question-circle"
                               [title]="'config.tariff.type.paynow.readDescription' | translate"></i>
                    </span>
                    </label>
                </div>
            </ng-container>
        </div>

        <div class="grid-y cell" *ngIf="!tariff.applyByQRCode">
            <div class="grid-x cell">
                <div class="cell shrink">
                    <label class="checkbox">
                        <input [(ngModel)]="remoteStartAllowed" (change)="remoteStartChanged()" [disabled]="!editMode"
                               name="remoteStart"
                               type="checkbox">
                        <span class="custom-checkbox"></span>
                        <span class="label-choice">{{'config.tariff.allowedStart'|translate}}</span>
                    </label>
                </div>
            </div>
            <div class="grid-x cell">
                <div class="cell shrink">
                    <label class="checkbox">
                        <input [(ngModel)]="remoteStopAllowed" (change)="remoteStopChanged()" [disabled]="!editMode"
                               name="remoteStop"
                               type="checkbox">
                        <span class="custom-checkbox"></span>
                        <span class="label-choice">{{'config.tariff.allowedStop'|translate}}</span>
                    </label>
                </div>
            </div>
        </div>

        <div
            *ngIf="tariffTradeAgreementOtherEmo2CcSubscriptionDefs?.length > 1 && (tariff.id == null || !tariff.linkedTariffTradeAgreementOtherEmo2CcSubscriptionDefRef)"
            class="grid-x align-middle small-24 tariffEdit-code">
            <div class="cell auto">
                <h5 class="tariffEdit-code-title">{{ 'config.tariff.subscription.title' | translate }}</h5>
                <p class="tariffElementEdit-description">{{ 'config.tariff.subscription.subtitle' | translate }}</p>
                <select [disabled]="!!tariff.id || !!tariff.lastEditDate"
                        [ngModel]="subscriptionDefSelected"
                        (ngModelChange)="updateTariffAfterSubscriptionDefChange($event)"
                        name="subscriptionDef"
                        required>
                    <option [ngValue]="subscriptionDef"
                            *ngFor="let subscriptionDef of tariffTradeAgreementOtherEmo2CcSubscriptionDefs">
                        {{ subscriptionDef?.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="cell tariffElementEdit">
            <h4 class="tariffElementEdit-title">{{'config.tariff.fixedPrice'|translate}}</h4>
            <p class="tariffElementEdit-description">{{'config.tariff.fixedPriceExplanation'|translate}}</p>
            <div class="grid-x align-middle grid-padding-x">
                <label class="grid-x align-middle">
                    <switch trueText=" " falseText=" "
                            [(ngModel)]="paidCharge" (change)="freeChargePriceChange()"
                            [class.disabled]="!editMode" [disabled]="!editMode"
                            name="freePower"></switch>
                </label>
                <ng-container *ngIf="paidCharge else freeCost">
                    <div class="input-group cell small-3 force-inner-space-right-0">
                        <input class="input-group-field cell shrink"
                               iziviaNumeric [decimals]="4"
                               [(ngModel)]="tariff.chargePrice"
                               min="0"
                               step="0.01" type="text" [disabled]="!editMode" [required]="paidCharge"
                               name="chargePrice"
                               [iziviaTariffPrice]="!paidCharge">
                        <span
                            class="input-group-label">{{'config.tariff.currencyUnitByConnection'|translate:{currencyUnit: '€'} }}</span>
                    </div>
                </ng-container>
            </div>

            <ng-template #freeCost>
                <div class="grid-x align-middle grid-padding-x tariffElementEdit-datas">
                    <span class="tariffElementEdit-datas-number">{{'config.tariff.element.free'|translate}}</span>
                </div>
            </ng-template>
        </div>

        <div class="cell tariffElementEdit space-bottom-24">
            <div [hidden]="!editMode" class="grid-x small-24 align-center">
                <div class="grid-x grid-padding-x tariffElementEdit-editing tariffElementEdit-datas small-24">
                    <label class="grid-x">
                        <switch trueText=" " falseText=" "
                                [(ngModel)]="isOneSlot"
                                (change)="isOneSlotChanged()"
                                name="isOneSlot"></switch>
                    </label>
                    <span class="label-choice space-left-10 ">{{'config.tariff.timeSlot.timeSlotMode' | translate}}</span>
                </div>
            </div>
            <div class="grid-x small-24 align-center" [hidden]="isOneSlot">
                <div class="cell small-24">
                    <h4 class="tariffElementEdit-title">{{'config.tariff.timeSlot.label'|translate}}</h4>
                    <p class="tariffElementEdit-description">{{'config.tariff.timeSlot.explanation'|translate}}</p>
                </div>
            </div>
            <div *ngIf="editMode" [hidden]="isOneSlot" class="grid-x small-24">
                <div class="cell small-24" *ngIf="allowedToDefineMoreRangesInTariff">
                    <button class="button"
                            type='button'
                            [disabled]="tariff.tariffElements.length === 4"
                            *ngIf="editMode"
                            (click)="addTariffElement()">{{'config.tariff.addTariffElement' | translate}}
                    </button>
                </div>

                <ng-container *ngFor="let tariffElement of tariff.tariffElements; let first = first; let index = index; trackBy: trackByIndex">
                    <div class="cell small-6 tariffElementEdit-timeSlot">
                        <hr>
                        <div class="tariffElementEdit-timeSlot_container">
                            <h6 class="space-16">
                                {{'config.tariff.timeSlot.number'|translate:{number: index + 1} }}
                                <button *ngIf="editMode && tariff.tariffElements.length > 2"
                                        (click)="removeTariffElement(index)"
                                        type="button"
                                        class="button icon-btn secondary">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </h6>
                            <div class="grid-x grid-margin-x align-middle">
                                <div class="cell small-24 medium-24">
                                    <label class="grid-x align-middle">
                                        <span class="cell small-10" style="margin-right: 8px">{{'config.tariff.element.startTime'|translate}}</span>
                                        <time-picker class="cell auto"
                                                     [(ngModel)]="tariffElement.startTime"
                                                     (ngModelChange)="startTimeChange(index)"
                                                     [disabled]="!editMode || (!first && onlyOneTimeSlot())"
                                                     [name]="'fromTime' + index"
                                                     [required]="!isOneSlot"
                                        ></time-picker>
                                    </label>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x align-middle"
                                 *ngIf="!!subscriptionDefSelected?.allowedToDefinePeakAndOffPeakHours && !isOneSlot">
                                <div class="cell small-24 medium-24">
                                    <label class="grid-x align-middle">
                                        <span class="cell small-10" style="margin-right: 8px">{{'config.tariff.element.hourType'|translate}}</span>
                                        <select class="cell auto"
                                                [disabled]="!editMode"
                                                [(ngModel)]="tariffElement.hourType"
                                                [name]="'hourType' + index"
                                                required>
                                            <option [ngValue]="hourType"
                                                    *ngFor="let hourType of Object.values(HourType)">
                                                {{ 'common.hourType.' + hourType | translate }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="cell small-24 tariffElementEdit _tabs">
                <div class="tariffElementEdit-hours">
                    <h6 *ngFor="let tariffElement of tariff.tariffElements; let first = first; let index = index"
                        (click)="selectTariffElement(index)"
                        [hidden]="isOneSlot"
                        [class.selected]="selectedTariffElementIndex === index"
                        class="tariffElementTab">
                        {{'config.tariff.element.start' | translate}} {{tariffElement.startTime ? tariffElement.startTime : '--:--'}}
                        {{'config.tariff.element.end'|translate}} {{tariffElement.endTime ? tariffElement.endTime : '--:--'}}
                        <br>
                        {{!!tariffElement.hourType ? ('common.hourType.' + tariffElement.hourType | translate) : ''}}
                    </h6>
                </div>
                <ng-container *ngFor="let tariffElement of tariff.tariffElements; let first = first; let index = index"
                              [ngModelGroup]="getIdOfTariffElement(tariffElement)">
                    <tariff-element-edit [editMode]="editMode" [tariffElement]="tariffElement"
                                         [hidden]="isHiddenTariff(index)"></tariff-element-edit>
                </ng-container>
            </div>
        </div>

        <button class="button"
                type='button'
                [disabled]="!tariffForm.form.valid"
                *ngIf="!_tariff.id && !isFreeTariff()"
                (click)="simulateCharge()"
                [iziviaLoading]="fetchingPricingInfoPreview">{{'config.tariff.simulation.simulate' | translate}}
        </button>

        <div class="cell">
            <tariff-charge-simulator *ngIf="!isFreeTariff()"
                                     [hidden]="!_tariff.id && !simulationHasBeenLaunched"
                                     [tariff]="tariff"></tariff-charge-simulator>
        </div>
        <div *ngIf="editMode && !!pricingInfoPreview" class="cell space-top-24 space-bottom-12 pricingInfoContainer">
            <p *ngIf="fleetConfig.emoMention">{{'config.tariff.pricingInfo'|translate}} :</p>
            <p *ngIf="!fleetConfig.emoMention">{{'config.tariff.pricingInfoWithoutEmoMention'|translate}} :</p>
            <span [innerHtml]="pricingInfoPreview | tariffPricingInfo"></span>
        </div>
        <div [hidden]="doNotShowStation()"
             class="cell tariffElementEdit">
            <h4 class="tariffElementEdit-title">{{'config.tariff.link.label'|translate}}</h4>
            <p *ngIf="canCreatePaynowTariffs"
               class="tariffElementEdit-description">{{'config.tariff.link.description'|translate}}</p>

            <div class="cell">
                <collapsible-stations-selects [stationsByChargeArea]="stationsByChargeArea"
                                              [disabledStationRefs]="disabledStationRefs"
                                              [disabledReason]="'stations.disabled.haveQrCodeTariff' | translate">
                </collapsible-stations-selects>
            </div>

        </div>
        <div [hidden]="!editMode" class="grid-x cell auto align-justify align-middle action-bar add-space-block">
            <div class="cell text-right">
                <button type="submit"
                        [iziviaLoading]="savingTariff"
                        [disabled]="!tariffForm.form.valid || !tariffForm.dirty"
                        class="button">{{'config.tariff.save'|translate}}</button>
            </div>
        </div>
        <tariff-station-usage *ngIf="readonlyMode" [tariff]="tariff" class="cell"></tariff-station-usage>
    </ng-container>
</form>
<ng-template #emptyTariff>
    <div class="emptyTariff">
        <h4>{{'config.tariff.selectOne'|translate}}</h4>
    </div>
</ng-template>

<ng-template #spinner>
    <div class="loader">
        <span class="spinner"></span>
    </div>
</ng-template>
