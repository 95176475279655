import {NgModule} from '@angular/core';
import {CurrentUserResolver} from './currentUser.resolver';
import {CurrentDetailedPersonResolver} from './currentDetailedPerson.resolver';
import {CurrentUserContextResolver} from './currentUserContext.resolver';
import {CurrentSubscriberRolesResolver} from './currentSubscriberRoles.resolver';
import {FleetResolver} from './fleet.resolver';

@NgModule({
    providers: [
        CurrentUserResolver,
        CurrentDetailedPersonResolver,
        CurrentUserContextResolver,
        CurrentDetailedPersonResolver,
        CurrentSubscriberRolesResolver,
        FleetResolver
    ]
})
export class ResolverModule {
}
