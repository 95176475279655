<div class="grid-x cell small-24 align-middle HourContainer" *ngIf="openingDay">
    <label class="cell small-5 checkbox">
        <input [(ngModel)]="openingDay.open"
               (change)="openingChanges()"
               type="checkbox">
        <span class="custom-checkbox"></span>
        <span class="label-choice">{{openingDay.day|translate}}</span>
    </label>
    <label [hidden]="!openingDay.open" class="cell small-5 radio">
        <input [(ngModel)]="openingDay.openingType"
               (change)="openingChanges()"
               [disabled]="!openingDay.open"
               [required]="openingDay.open"
               [name]="openingDay.day"
               type="radio"
               [value]="OpeningType.OPEN_2424">
        <span class="custom-radio"></span>
        <span class="label-choice">{{'chargeAreas.edit.opening.open2424'|translate}}</span>
    </label>
    <label [hidden]="!openingDay.open" class="cell small-8 radio">
        <input [(ngModel)]="openingDay.openingType"
               (change)="openingChanges()"
               [disabled]="!openingDay.open"
               [required]="openingDay.open"
               [name]="openingDay.day"
               type="radio"
               [value]="OpeningType.SCHEDULED">
        <span class="custom-radio checkEdit"></span>
        <span class="labelEdit">{{'chargeAreas.edit.opening.from'|translate}}</span>
        <input [(ngModel)]="openingDay.from"
               [disabled]="!openingDay.open || openingDay.openingType !== OpeningType.SCHEDULED"
               [required]="openingDay.openingType === OpeningType.SCHEDULED"
               type="time">
        <span class="">{{'chargeAreas.edit.opening.to'|translate}}</span>
        <input [(ngModel)]="openingDay.to"
               [disabled]="!openingDay.open || openingDay.openingType !== OpeningType.SCHEDULED"
               [required]="openingDay.openingType === OpeningType.SCHEDULED"
               type="time">
    </label>
</div>
