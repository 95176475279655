import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChargingProgram, ChargingProgramError, SocEventType} from 'lib-front';

@Component({
    selector: 'fleet-charging-program-update',
    templateUrl: './fleet-charging-program-update.component.html',
    styleUrls: ['./fleet-charging-program-update.component.scss']
})
export class FleetChargingProgramUpdateComponent {
    @Input() chargingProgram: ChargingProgram;
    @Input() socEventType: SocEventType;
    @Input() chargingProgramErrors;

    @Output() readonly cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly update: EventEmitter<void> = new EventEmitter<void>();

    SocEventType = SocEventType;
    ChargingProgramError = ChargingProgramError;

    constructor() {}

    public onCancel(): void {
        this.cancel.emit();
    }

    public onUpdate(): void {
        this.update.emit();
    }

    public hasError(error: ChargingProgramError): boolean {
        return this.chargingProgramErrors.includes(error);
    }
}
