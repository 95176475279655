import {OptionSubscription} from 'lib-front';

export function calculateProgressBarOptions(options: OptionSubscription[], showTotal:  boolean, showBar: boolean): ProgressBarOptions {
    const progressBarOptions = new ProgressBarOptions(0, 0, 0, 0, 0, null, false);
    if (options && options.length > 0) {
        options = options.sort((a, b) => a.subscription.subscriptionDef.priority - b.subscription.subscriptionDef.priority);
        options.forEach(option => {
            if (!progressBarOptions.currencyUnit && !!option.subscription.subscriptionDef.currencyUnit) {
                progressBarOptions.currencyUnit = option.subscription.subscriptionDef.currencyUnit;
            }
            progressBarOptions.consumeMax += (option.subscription.subscriptionDef.freeTime * option.number)
                + option.subscription.currentPeriod.carriedOverFreeTime;
            progressBarOptions.consume += option.subscription.context.consumedFreeTime;
            if (showTotal) {
                progressBarOptions.totalHours += option.subscription.freeTime * option.number;
                progressBarOptions.totalPrice += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
            }

            if (progressBarOptions.consume > 0 && progressBarOptions.consumeMax > 0 && showBar) {
                progressBarOptions.consumePercent = ((progressBarOptions.consume / progressBarOptions.consumeMax) * 100);

                if (progressBarOptions.consumePercent > 100) {
                    progressBarOptions.consumePercent = 100;
                }

                progressBarOptions.isBarAlert = progressBarOptions.consumePercent >= 70;
            }
        });
    }
    return progressBarOptions;
}

export class ProgressBarOptions {
    totalHours: number;
    totalPrice: number;
    currencyUnit: string;
    consumeMax: number;
    consume: number;
    consumePercent: number;
    isBarAlert: boolean;

    constructor(totalHours: number, totalPrice: number, consumeMax: number, consume: number, consumePercent: number,
        currencyUnit: string, isBarAlert: boolean) {
        this.totalHours = totalHours;
        this.totalPrice = totalPrice;
        this.currencyUnit = currencyUnit;
        this.consumeMax = consumeMax;
        this.consume = consume;
        this.consumePercent = consumePercent;
        this.isBarAlert = isBarAlert;
    }
}
