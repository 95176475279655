import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    ChargeAreaDto,
    ChargePointDto,
    CurrentSubscriberRolesService,
    FrontStatHttpService,
    FrontStationHttpService,
    getNbStations,
    getNbStationWithStatus,
    InfraStatus,
    infraStatusValuesWithoutReserved,
    MonthStats,
    StationDto,
    StationLazyCategory,
    StationSearchCriteria,
    StationStatusStats, SubscriberRoleLevel, SubscriberRoleType,
    buildRole
} from 'lib-front';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NotificationService} from '../../services/utils/notification.service';

@Component({
    selector: 'charge-area[hasStationWriteRole]',
    templateUrl: './charge-area.component.html',
    styleUrls: ['./charge-area.component.scss']
})
export class ChargeAreaComponent implements OnInit {
    private readonly baseUrl: string = '/main/chargeAreas';
    private _chargeArea: ChargeAreaDto;
    @Input() stationsCriteria: StationSearchCriteria;
    @Input() public hasStationWriteRole: boolean;
    @ViewChild('collapsibleCardComponent') collapsibleCard;

    @Input() set chargeArea(chargeAreaDto: ChargeAreaDto) {
        this._chargeArea = chargeAreaDto;
        this.statService.findChargeAreaMonthStats(chargeAreaDto.id)
            .subscribe(value => this.monthStats = value);

        this.stations = [];

        if (this.collapsibleCard && !this.collapsibleCard.collapsed) {
            this.loadStations();
        }
    }

    public hasChargeAreasWriteRole: boolean;

    get chargeArea(): ChargeAreaDto {
        return this._chargeArea;
    }

    waitingStations: boolean;
    stations: StationDto[];
    infraStatusValues: InfraStatus[] = infraStatusValuesWithoutReserved();
    public monthStats: MonthStats;

    constructor(
        private readonly stationService: FrontStationHttpService,
        private readonly statService: FrontStatHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly location: Location,
        private readonly route: ActivatedRoute) {


    }

    ngOnInit(): void {
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasChargeAreasWriteRole = hasRole);
    }

    editChargeArea() {
        this.router.navigate([`${this.baseUrl}/${this._chargeArea.id}/edit`]);
    }

    loadStations() {
        if (!this.stations || this.stations.length === 0) {
            this.waitingStations = true;
            const fleetStationCriteria = this.stationsCriteria;

            fleetStationCriteria.chargeAreaRef = this._chargeArea.id;
            fleetStationCriteria.categories = [StationLazyCategory.CHARGE_POINTS_WITH_LICENSE_NUMBER];
            this.stationService.searchStations(fleetStationCriteria)
                .pipe(finalize(() => this.waitingStations = false))
                .subscribe(stations => this.stations = stations);
        }

        this.location.go(`${this.baseUrl}/${this._chargeArea.id}`);
    }

    public getNbStationWithStatus(stationStats: StationStatusStats, infraStatus: InfraStatus): number {
        return getNbStationWithStatus(stationStats, infraStatus);
    }

    public getNbStations(stationStats: StationStatusStats): number {
        return getNbStations(stationStats);
    }

    trackByStationId(index: number, stationDto: StationDto) {
        return stationDto?.id ?? index;
    }

    public isSelected(): boolean {
        return this._chargeArea.id === this.route.snapshot.paramMap.get('chargeAreaId');
    }
}
