<div *ngIf="consumption" class="grid-x align-middle align-center">
    <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
    <div class="grid-y cell shrink consumption-detail-block-info border-grey">
        <div class="cell grid-x grid-padding-x space-bottom-8">
            <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.charge.pass' | translate}}</span>
            <span class="cell shrink consumption-detail-block-info-text">{{consumption | fleetMetaConsumptionMedia}}</span>
        </div>
        <consumption-details-data [consumption]="consumption"></consumption-details-data>
    </div>

    <div class="cell large-1 xxlarge-2 xxxlarge-4">&nbsp;</div>
</div>
