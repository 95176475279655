import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {InvoiceForm} from 'lib-front';
import {KeyValue, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {NotificationService} from '../../services/utils/notification.service';

@Component({
    selector: 'payment-options',
    templateUrl: './payment-options.component.html',
    styleUrls: ['./payment-options.component.scss'],
    providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class PaymentOptionsComponent {
    @Input('payment-request-infos') set paymentInput(payment: InvoiceForm | null) {
        this.paymentRequestInfos = payment;

        if (payment === null) {
            return;
        }
        if (payment.url === null
      || payment.method === null) {
            setTimeout(() => {
                this.notificationService.error('error.invoice.mpmError');
            }, 10);
            return;
        }

        if (this.paymentRequestInfos.method === 'POST') {
            setTimeout(() => {
                this.form.nativeElement.submit();
            }, 1000);
        } else {
            let redirectUrl: string = this.paymentRequestInfos.url;
            if (this.paymentRequestInfos.params) {
                const queryParam: string = Object.keys(this.paymentRequestInfos.params)
                    .map(key => `${key}=${this.paymentRequestInfos.params[key]}`).join('&');
                redirectUrl += '?' + queryParam;
            }

            window.location.href = redirectUrl;
        }
    }

    paymentRequestInfos: InvoiceForm;

    // Beware of the use of static: true
    // #f must NOT be used within *ngIf or *ngFor in the template otherwise you should change static to false
    // and wait for ngAfterViewInit to use it
    @ViewChild('f', { static: true }) form;

    constructor(private notificationService: NotificationService) { }

    trackByKey(index: number, keyValue: KeyValue<string, any>) {
        return keyValue?.key ?? index;
    }
}
