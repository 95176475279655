import {Component, Input} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {IziviaPasswordValidatorDefinition} from 'lib-front';

@Component({
    selector: 'password-validation-errors [errors][definitions]',
    templateUrl: './password-validation-errors.component.html',
    styleUrls: ['./password-validation-errors.component.scss']
})
export class PasswordValidationErrorsComponent {

    @Input() errors: ValidationErrors;
    @Input() definitions: IziviaPasswordValidatorDefinition[] = [];

    defIsSatisfied(def: IziviaPasswordValidatorDefinition): boolean {
        return def && this.errors ? !Object.keys(this.errors).includes(def.type.valueOf()) : true;
    }
}
