<app-root-header class="grid-x align-middle app-header"></app-root-header>

<div class="grid-y app-content" [class.app-content-login]="onLoginOrRegister()">

    <div class="cell shrink text-center" [hidden]="!isSuspendedAccount() || isSuspendedByMissingPaymentConf()">
        <h5 class="headerAlert">
            <span>{{'header.alert.suspended.suspended' | translate}} </span>
            <a href="#/main/invoices">{{'header.alert.suspended.invoices' | translate}}</a>
            <br>
            <span>{{'header.alert.suspended.help' | translate}} </span>
            <a href="mailto:service-client@izivia.com">{{'header.alert.suspended.contact' | translate}}</a>
        </h5>
    </div>

    <div class="cell shrink text-center" [hidden]="!isSuspendedAccount() || !isSuspendedByMissingPaymentConf()">
        <h5 class="headerAlert">
            <span>{{'header.alert.suspended.suspendedByMissingConf' | translate}} </span>
            <br>
            <span>{{'header.alert.suspended.help' | translate}} </span>
            <a href="mailto:service-client@izivia.com">{{'header.alert.suspended.contact' | translate}}</a>
        </h5>
    </div>

    <router-outlet></router-outlet>
</div>
