import {Component, OnInit, ViewChild} from '@angular/core';
import {
    buildRole,
    CurrentSubscriberRolesService,
    CustomerAccount,
    FrontCurrentPersonBusinessService,
    FrontEndFleetConfig,
    FrontInvoiceBusinessService,
    FrontInvoiceHttpService,
    Invoice,
    InvoiceForm,
    InvoiceStatusEnum,
    InvoiceWrapper,
    PaymentConf,
    PaymentType,
    SubscriberRoleLevel,
    SubscriberRoleType,
    User
} from 'lib-front';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../services/utils/notification.service';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as moment from 'moment';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {CurrentUserContextService} from '../../../../services/business/currentUserContext.service';
import {AbstractHasRoleActionComponent} from '../../../../components/has-role-action/abstract-has-role-action.component';
import {ProConsumptionHttpService} from '../../../../services/http/proConsumptionHttp.service';
import {MetaTotalConsumption} from '../../../../domain/meta/total/metaTotalConsumption';

@Component({
    selector: 'invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class InvoicesComponent extends AbstractHasRoleActionComponent implements OnInit {

    @ViewChild('addSavedPaymentModal') addSavedPaymentModal;

    public selectedPaymentConf: PaymentConf;
    public invoices: InvoiceWrapper[] = [];
    public invoicesBlocking: InvoiceWrapper[] = [];
    public paymentConfs: PaymentConf[] = [];
    public paymentConfsAvailable: PaymentConf[] = [];

    public totalConsumptions: MetaTotalConsumption[];
    public totalConsumptionsPriceWithVat: number;
    public nextInvoiceScheduledDate: Date;
    public loadingConsumptions: boolean;
    public deletingPaymentConf: boolean;

    public fleetConfig: FrontEndFleetConfig;
    public hasInvoicesWriteRole: boolean;

    public managedAccount = false;

    private user: User;
    invoiceForm$: Observable<InvoiceForm | null>;

    constructor(private readonly invoiceHttpService: FrontInvoiceHttpService,
        private readonly proConsumptionHttpService: ProConsumptionHttpService,
        private readonly invoiceBusinessService: FrontInvoiceBusinessService,
        protected readonly notificationService: NotificationService,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly frontEndService: FrontEndService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly currentPersonBusinessService: FrontCurrentPersonBusinessService,
        private readonly route: ActivatedRoute,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.user = data.user.user;
            this.loadUnpaidInvoices();
            this.loadCustomerAccount();
            this.loadTotalConsumptions();
        });

        this.nextInvoiceScheduledDate = this.computeNextInvoiceDate();

        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.INVOICES, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasInvoicesWriteRole = hasRole);

        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => this.fleetConfig = fleetConfig);
    }

    public showInvoice(invoice: Invoice): string {
        return `api/customer/${invoice.customerRef}/invoice/${invoice._id}-${invoice.uuid}`;
    }

    public deleteSavedPayment(paymentConf: PaymentConf): void {
        this.doActionIfHasRole(
            () => {
                this.deletingPaymentConf = true;
                const templateDeletePayment = '<div style="color:black">' + this.translateService.instant('invoice.popup.delete') +
                    '</div><div  style="color:#d33404">' + this.translateService.instant('invoice.popup.payment.title') + '</div>';
                this.alertService
                    .confirm(
                        templateDeletePayment,
                        '',
                        this.translateService.instant('invoice.popup.confirmText'),
                        this.translateService.instant('invoice.popup.cancelText'))
                    .subscribe(yes => {
                        if (yes) {
                            this.invoiceHttpService.setPaymentType(this.user._id, PaymentType.CREDIT_CARD, paymentConf.emoRef)
                                .pipe(
                                    switchMap(() => this.currentPersonBusinessService.reloadDetailedPerson$()),
                                    finalize(() => this.deletingPaymentConf = false)
                                )
                                .subscribe(() => {
                                    paymentConf.paymentType = PaymentType.CREDIT_CARD;
                                    paymentConf.name = null;
                                    this.notificationService.success('payment.creditCardConf.success');
                                    this.paymentConfs.length = 0;
                                    this.loadCustomerAccount();
                                });
                        } else {
                            this.deletingPaymentConf = false;
                        }
                    });
            },
            this.hasInvoicesWriteRole
        );
    }

    public addSavedPayment(name: string): void {
        if (name) {
            this.invoiceForm$ = this.invoiceHttpService.setPaymentType(this.user._id,
                this.selectedPaymentConf.paymentType,
                this.selectedPaymentConf.emoRef,
                name,
                !this.selectedPaymentConf.createDate)
                .pipe(
                    catchError(err => {
                        if (err && err.error && err.error.labelKey) {
                            this.notificationService.error(err.error.labelKey);
                        }
                        return of(null);
                    }),
                    finalize(() => this.addSavedPaymentModal.close())
                );
        }
    }

    public openAddSavedPaymentModal(paymentConfAvailable: PaymentConf) {
        this.doActionIfHasRole(
            () => {
                this.selectedPaymentConf = paymentConfAvailable;
                this.addSavedPaymentModal.open();
            },
            this.hasInvoicesWriteRole
        );
    }

    public canPay(invoice) {
        return this.fleetConfig && this.fleetConfig.showPaymentSection && this.invoiceBusinessService.canPay(invoice);
    }

    public redirectPayment(id) {
        this.router.navigate(['/main/invoices/' + id]);
    }

    private paymentConfAvailable(customerAccounts: CustomerAccount[]): void {
        customerAccounts.forEach((customerAccount) => {
            customerAccount.paymentConfs.forEach((paymentConf) => {
                if (!paymentConf.disabled && (paymentConf.paymentType === 'CREDIT_CARD_AUTHORIZATION' ||
                    paymentConf.paymentType === 'SEPA' || paymentConf.paymentType === 'CREDIT_CARD_RECURRING')) {
                    this.paymentConfs.push(paymentConf);
                } else if (!paymentConf.disabled && paymentConf.paymentType == 'ACCOUNT_MANAGER') {
                    this.managedAccount = true;
                }
            });
        });
    }

    private loadPaymentConfsAvailable(foAccount: string): void {
        this.invoiceHttpService.findPaymentConfAvailables(foAccount).subscribe((payments) => {
            this.paymentConfsAvailable = payments;
        });
    }

    private loadCustomerAccount(): void {
        this.currentPersonBusinessService.reloadDetailedPerson$()
            .pipe(
                switchMap(_ => this.currentPersonBusinessService.currentDetailedPerson$)
            )
            .subscribe(person => {
                this.paymentConfAvailable(person.customerAccounts);
                this.loadPaymentConfsAvailable(this.currentUserContextService.getCurrentFoAccountId());
            });
    }

    private loadTotalConsumptions(): void {
        this.loadingConsumptions = true;

        this.proConsumptionHttpService.findNewTotalConsumptions()
            .subscribe(
                totalConsumptions => {
                    this.totalConsumptions = totalConsumptions;
                    this.totalConsumptionsPriceWithVat = totalConsumptions
                        .map(totalConsumption => totalConsumption.priceWithVat)
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                    this.loadingConsumptions = false;
                }
            );
    }

    private computeNextInvoiceDate(): Date {
        const date = moment();
        if (date.date() !== 1) {
            date.date(2);
            date.add(1, 'month');
        } else {
            date.date(2);
        }
        return date.toDate();
    }

    private loadUnpaidInvoices(): void {
        this.invoiceHttpService.findUnpaidInvoice(this.user._id).subscribe((unpaidInvoices) => {
            this.invoices = unpaidInvoices.filter(invoice => !invoice.blocking);
            this.invoicesBlocking = unpaidInvoices.filter(invoice => invoice.blocking);
        });
    }

    replacePayment(paymentConf: PaymentConf) {
        this.doActionIfHasRole(
            () => {
                this.invoiceHttpService.getReplacementPaymentConfByEmo(
                    this.currentUserContextService.getCurrentFoAccountId(),
                    paymentConf.emoRef
                ).subscribe((payments) => {
                    this.paymentConfsAvailable = payments;
                });
            },
            this.hasInvoicesWriteRole
        );
    }

    canDeletePaymentConf(paymentConf: PaymentConf) {
        return [...this.invoices, ...this.invoicesBlocking]
            .filter((invoice) => invoice.providerRef === `EMO/${paymentConf.emoRef}` &&
                invoice.currentStatus?.status === InvoiceStatusEnum.WAITING_FOR_PAYMENT)
            .length === 0;
    }
}

