<div class="grid-y grid-margin-y cell auto" *ngIf="stations">
    <div (click)="toggleCollapsed()" class="grid-x cell shrink collapseContainer">
        <label class="cell auto checkbox">
            <input (change)="toggleSelectAll()"
                   [(ngModel)]="isAllSelected"
                   [disabled]="allStationsDisabled()"
                   [indeterminate]="isIndeterminate"
                   type="checkbox">
            <span class="custom-checkbox"></span>
            <span class="label-choice">{{stations[0].chargeAreaName}}</span>
        </label>
        <div class="collapseContainer-number">
            <div class="cell shrink">
                {{'config.tariff.chargeAreas.stationCount'|translate:
                {
                    selectedCount: selectedCount,
                    total: stations?.length
                } }}
            </div>
            <div [hidden]="!isCollapsed" class="cell shrink">
                <i class="fas fa-chevron-up"></i>
            </div>
            <div [hidden]="isCollapsed" class="cell shrink">
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
    </div>
    <div [hidden]="!isCollapsed" class="grid-x grid-padding-x cell shrink collapseContainer-content">
        <div *ngFor="let station of stations; trackBy: trackByStationId" class="cell shrink">
            <label class="checkbox">
                <input (change)="changeStation(station.selected)"
                       [disabled]="disabledStationRefs.includes(station.id)"
                       [(ngModel)]="station.selected"
                       type="checkbox">
                <span class="custom-checkbox"></span>
                <span class="label-choice">
                    {{'stations.visualId'|translate:{visualId: station.visualId} }}
                    <ng-container *ngIf="disabledStationRefs.includes(station.id)">
                        ({{disabledReason}})
                    </ng-container>
                </span>
            </label>
        </div>
    </div>
</div>
