import {Component, Input} from '@angular/core';

@Component({
    selector: 'indicator',
    templateUrl: './indicator.component.html',
    styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent {

    @Input() img: string;

    public selectClass(img) {
        const result = {};
        result[img] = true;
        return result;
    }

    public color(img) {
        switch (img) {
            case 'availableStations' :
                return {success: true};
            case 'chargingStations' :
                return {secondary: true};
            case 'reservedStations' :
                return {primary: true};
            case 'servicingStations' :
                return {primarydark: true};
            case 'unavailableStations' :
                return {error: true};
            case 'passActivated' :
                return {success: true};
            case 'passOrders' :
                return {warning: true};
            case 'passDisabled' :
                return {error: true};
            default : return {color: false};
        }
    }
}
