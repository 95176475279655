import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'collapsible-card',
    templateUrl: './collapsible-card.component.html',
    styleUrls: ['./collapsible-card.component.scss']
})
export class CollapsibleCardComponent {
    isCollapsed: boolean;
    @Input() colorClass: string;
    @Output() collapse: EventEmitter<void> = new EventEmitter();
    @Input() set open(openCard: boolean) {
        if (openCard !== !!this.isCollapsed) {
            this.toggleCollapsed();
        }
    }
    private _parentId: string;
    @Input() set parentId(id: string) {
        this._parentId = id;
    }

    constructor() {
    }

    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
        if (!!this.isCollapsed) {
            if (this._parentId && document.getElementById(this._parentId)) {
                document.getElementById(this._parentId).scrollIntoView({behavior: 'smooth', block: 'center'});
            }
            this.collapse.emit();
        }
    }
}
