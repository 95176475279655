<div class="home-component center-container">
    <h2 class="grid-x home-card-title">
        {{'home.stations' | translate}}
    </h2>
    <div class="home-card-content">
        <div class="grid-y center-container-card home-content-center">
            <div class=" large-content grid-x large-up-1 xlarge-up-3 align-middle align-center grid-margin-x grid-padding-x center-container-width margin-bottom-16">
                <indicator class="cell indicator-vrt" [img]="'availableStations'">
                    <ng-container value *ngIf="stationStats">{{getNbStationWithStatus(stationStats, InfraStatus.AVAILABLE)}}
                    </ng-container>
                </indicator>
                <indicator class="cell indicator-vrt" [img]="'chargingStations'">
                    <ng-container value *ngIf="stationStats">{{getNbStationWithStatus(stationStats, InfraStatus.CHARGING)}}
                    </ng-container>
                </indicator>
                <indicator class="cell indicator-vrt" [img]="'servicingStations'">
                    <ng-container value *ngIf="stationStats">{{getNbStationWithStatus(stationStats, InfraStatus.SERVICING)}}
                    </ng-container>
                </indicator>
            </div>

            <div *ngIf="stationChargeStats" class="large-content grid-y align-center">
                <indicator class="cell shrink sm-block color" [img]="'chargeNow'">
                    <ng-container value>{{stationChargeStats.nbCharge}}</ng-container>
                    <ng-container label [ngPlural]="stationChargeStats.nbCharge">
                        <ng-template ngPluralCase="=0">
                            {{'home.stationChargeStats.nbCharge.singular' | translate}}
                        </ng-template>
                        <ng-template ngPluralCase="=1">
                            {{'home.stationChargeStats.nbCharge.singular' | translate}}
                        </ng-template>
                        <ng-template ngPluralCase="other">
                            {{'home.stationChargeStats.nbCharge.plural' | translate}}
                        </ng-template>
                    </ng-container>
                </indicator>
                <indicator class="cell shrink sm-block color" [img]="'price'">
                    <ng-container value>{{stationChargeStats?.amountWithVat|currency:'EUR':'€'}}</ng-container>
                    <ng-container label>{{'home.stationChargeStats.amount' | translate}}</ng-container>
                </indicator>
            </div>
        </div>

        <div class="grid-x align-right align-middle moreDetail">
            <a>{{'home.details' | translate}}</a>
            <i class="moreDetail-arrow fas fa-arrow-right"></i>
        </div>
    </div>
</div>
