import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'fleetCalendarEventTitle'
})
export class FleetCalendarOneTimeEventTitle implements PipeTransform {

    constructor() {}

    transform(startDate: moment.Moment, desiredCharge: number): string {
        const calendarEventStartDate: string = startDate != null ? startDate.format('HH:mm') : '';
        const calendarEventDesiredCharge: string = desiredCharge != null ? desiredCharge + '%' : '';
        return calendarEventStartDate.concat(' ', calendarEventDesiredCharge);
    }
}

