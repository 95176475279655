import {Component, Input, OnInit} from '@angular/core';
import {OptionSubscription} from 'lib-front';

@Component({
    selector: 'subscription-options-blocks',
    templateUrl: './subscription-options-blocks.component.html',
    styleUrls: ['./subscription-options-blocks.component.scss'],
})
export class SubscriptionOptionsBlocksComponent {

    @Input() options: Array<OptionSubscription>;
    @Input() smallBackground = false;


    constructor() {
    }

    trackBySubscriptionId(index: number, option: OptionSubscription) {
        return option?.subscription?.id ?? index;
    }
}
