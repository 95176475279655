import {Component, Input, OnInit} from '@angular/core';
import {FaqPageDetailDto} from 'lib-front';
import {Router} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent {

    private readonly supportPageUrl = '/main/configuration/support';
    private _faqPage: FaqPageDetailDto;
    content: SafeHtml = '';
    @Input()
    set faqPage(faqPage: FaqPageDetailDto) {
        this._faqPage = faqPage;
        this.content = this.trustHtml(faqPage.content);
    }

    get faqPage(): FaqPageDetailDto {
        return this._faqPage;
    }

    constructor(private router: Router,
        private sanitizer: DomSanitizer) {
    }

    private trustHtml(htmlString): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(htmlString.replace(/\\"/g, '\"'));
    }
}
