<div class="account-title-bar grid-x">
    <div class="grid-x align-middle grid-margin-x small-16">
        <div class="grid-y cell auto search-label force-inner-space-left-0">
            <input class="search cell auto force-space-right-16" type="text"
                   placeholder="{{'config.permission.search.placeholder' | translate}}"
                   name="search" [(ngModel)]="textToSearch"
                   (keyup.enter)="searchManagersRoles()">
            <i class="fas fa-search search-icon"></i>
        </div>

        <button class="cell shrink button primary" type="button" (click)="searchManagersRoles()">
            {{'collaborator.search.title' | translate}}
        </button>
    </div>
    <div class="cell small-8 align-bottom align-right">
        <indicator [img]="'users'">
            <ng-container value>{{managerRoles?.length | number}}</ng-container>
        </indicator>
    </div>
</div>

<div *ngIf="displayLimitMessage"
     class="info cell auto common-block force-space-bottom-0 space-top-25">
    {{'config.permission.search.limit' | translate}}
</div>

<div class="table-scroll space-top-25" *ngIf="!loadingTable && hasSubscriberRolesReadRole">
    <h4 class="table-title">{{'config.permission.mainRolesTable.title' | translate}}</h4>

    <next-last-page [pageNumber]="rolePage" [maxPage]="lastPage"
                    (changePage)="getRolesFromPage($event)"></next-last-page>
    <table>
        <thead>
        <tr>
            <th>{{'config.permission.roles.table.manager' | translate}}</th>
            <th>{{'config.permission.roles.table.allRoles' | translate}}</th>
            <th *ngFor="let role of availableMainRoles">
                {{'config.permission.roles.type.' + role.type + (role.type === SubscriberRoleType.INVOICES ? (isStationManager ? '.stationManager' : '.notStationManager') : '') | translate}}
            </th>
            <th [style]="{'width':'150px'}" *ngIf="hasSubscriberRolesWriteRole"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let managerRoles of managerRolesDisplayed;">
            <td>{{managerRoles.firstName + ' ' + managerRoles.lastName}}</td>

            <!-- ALL ROLES -->

            <td>

                <!-- Edit mode -->
                <role-select *ngIf="subscriberMainRolesUpdateEntries[managerRoles.subscriberId]"
                             [roleLevels]="availableRolesMap[ALL_ROLES]"
                             (ngModelChange)="updateMainRolesFromAllRolesType($event, managerRoles.subscriberId)"
                             [(ngModel)]="subscriberMainRolesUpdateEntries[managerRoles.subscriberId][ALL_ROLES]">
                </role-select>


                <!-- Display mode -->
                <div
                    *ngIf="!subscriberMainRolesUpdateEntries[managerRoles.subscriberId] && subscriberMainRolesEntries[managerRoles.subscriberId][ALL_ROLES]">
                    <ng-container [ngSwitch]="subscriberMainRolesEntries[managerRoles.subscriberId][ALL_ROLES]">
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.READ"><i class="fas fa-eye"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.WRITE"><i class="fas fa-pen"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="NONE_LEVEL"><i class="fas fa-eye-slash"></i></ng-container>
                    </ng-container>
                </div>
            </td>


            <!-- INDIVIDUAL ROLES -->

            <td *ngFor="let role of availableMainRoles">
                <!-- Edit mode -->
                <role-select *ngIf="subscriberMainRolesUpdateEntries[managerRoles.subscriberId]"
                             [roleLevels]="availableRolesMap[role.type]"
                             (ngModelChange)="setMainRolesAllRolesTypeUpdateEntryToUnknown(managerRoles.subscriberId)"
                             [(ngModel)]="subscriberMainRolesUpdateEntries[managerRoles.subscriberId][role.type]">
                </role-select>

                <!-- Display mode -->
                <div *ngIf="!subscriberMainRolesUpdateEntries[managerRoles.subscriberId]">
                    <ng-container [ngSwitch]="subscriberMainRolesEntries[managerRoles.subscriberId][role.type]">
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.READ"><i class="fas fa-eye"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.WRITE"><i class="fas fa-pen"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="NONE_LEVEL"><i class="fas fa-eye-slash"></i></ng-container>
                    </ng-container>
                </div>


            </td>


            <!-- Modification buttons -->
            <td *ngIf="hasSubscriberRolesWriteRole">

                <!-- Edit  -->
                <div class="grid-x grid-margin-x align-center">
                    <div class="cell shrink">
                        <button *ngIf="!subscriberMainRolesUpdateEntries[managerRoles.subscriberId]"
                                (click)="startSubscriberMainRolesEdit(managerRoles.subscriberId)"
                                type="button"
                                class="button round primary small">
                            <i class="fas fa-pen"></i>
                        </button>
                    </div>
                </div>


                <!-- Validate or cancel -->
                <div class="grid-x grid-margin-x" *ngIf="subscriberMainRolesUpdateEntries[managerRoles.subscriberId]">
                    <div class="cell auto">
                        <button type="button" class="button round primary small"
                                *ngIf="subscriberMainRolesUpdateEntries[managerRoles.subscriberId]"
                                (click)="validateSubscriberMainRolesEdit(managerRoles.subscriberId)"
                                [iziviaLoading]="validatingSubscriberEdit">
                            <i class="fas fa-check"></i>
                        </button>
                        <button type="button" class="button round secondary small"
                                [style]="{'margin-left': '8px'}"
                                (click)="cancelSubscriberMainRolesEdit(managerRoles.subscriberId)">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="table-scroll space-top-25" *ngIf="!loadingTable && hasSubscriberRolesReadRole">
    <h4 class="table-title">{{'config.permission.configurationTabSubRolesTable.title' | translate}}</h4>
    <table>
        <thead>
        <tr>
            <th>{{'config.permission.roles.table.manager' | translate}}</th>
            <th>{{'config.permission.roles.table.allRoles' | translate}}</th>
            <th *ngFor="let role of availableConfigurationTabSubRoles">{{'config.permission.roles.type.' + role.type | translate}}</th>
            <th [style]="{'width':'150px'}" *ngIf="hasSubscriberRolesWriteRole"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let managerRoles of managerRolesDisplayed;">
            <td>{{managerRoles.firstName + ' ' + managerRoles.lastName}}</td>

            <!-- ALL ROLES -->

            <td>

                <!-- Edit mode -->
                <role-select *ngIf="subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId]"
                             [roleLevels]="availableRolesMap[ALL_ROLES]"
                             (ngModelChange)="updateConfigurationTabSubRolesFromAllRolesType($event, managerRoles.subscriberId)"
                             [(ngModel)]="subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId][ALL_ROLES]">
                </role-select>


                <!-- Display mode -->
                <div
                        *ngIf="!subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId] && subscriberConfigurationTabSubRolesEntries[managerRoles.subscriberId][ALL_ROLES]">
                    <ng-container [ngSwitch]="subscriberConfigurationTabSubRolesEntries[managerRoles.subscriberId][ALL_ROLES]">
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.READ"><i class="fas fa-eye"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.WRITE"><i class="fas fa-pen"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="NONE_LEVEL"><i class="fas fa-eye-slash"></i></ng-container>
                    </ng-container>
                </div>
            </td>


            <!-- INDIVIDUAL ROLES -->

            <td *ngFor="let role of availableConfigurationTabSubRoles">
                <!-- Edit mode -->
                <role-select *ngIf="subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId]"
                             [roleLevels]="availableRolesMap[role.type]"
                             (ngModelChange)="setConfigurationTabSubRolesAllRoleUpdateEntryToUnknown(managerRoles.subscriberId)"
                             [(ngModel)]="subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId][role.type]">
                </role-select>

                <!-- Display mode -->
                <div *ngIf="!subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId]">
                    <ng-container [ngSwitch]="subscriberConfigurationTabSubRolesEntries[managerRoles.subscriberId][role.type]">
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.READ"><i class="fas fa-eye"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="SubscriberRoleLevel.WRITE"><i class="fas fa-pen"></i>
                        </ng-container>
                        <ng-container *ngSwitchCase="NONE_LEVEL"><i class="fas fa-eye-slash"></i></ng-container>
                    </ng-container>
                </div>


            </td>


            <!-- Modification buttons -->
            <td *ngIf="hasSubscriberRolesWriteRole">

                <!-- Edit  -->
                <div class="grid-x grid-margin-x align-center">
                    <div class="cell auto">
                        <button
                                *ngIf="!subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId]"
                                (click)="startSubscriberConfigurationTabSubRoleEdit(managerRoles.subscriberId)"
                                type="button"
                                class="button round primary small">
                            <i class="fas fa-pen"></i>
                        </button>
                    </div>
                </div>


                <!-- Validate or cancel -->
                <div class="grid-x grid-margin-x table-actionButtons" *ngIf="subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId]">
                    <div class="cell auto">
                        <button type="button" class="button round primary small"
                                *ngIf="subscriberConfigurationTabSubRolesUpdateEntries[managerRoles.subscriberId]"
                                (click)="validateSubscriberConfigurationTabSubRolesEdit(managerRoles.subscriberId)"
                                [iziviaLoading]="validatingSubscriberEdit">
                            <i class="fas fa-check"></i>
                        </button>
                        <button type="button" class="button round secondary small"
                                [style]="{'margin-left': '8px'}"
                                (click)="cancelSubscriberConfigurationTabSubRolesEdit(managerRoles.subscriberId)">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
    <next-last-page [pageNumber]="rolePage" [maxPage]="lastPage"
                    (changePage)="getRolesFromPage($event)"></next-last-page>
</div>
