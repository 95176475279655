import {PackTokenWrapper, SubscriptionPack} from 'lib-front';

/**
 * On many view, a subscription to a subscriptionPack can be done with one subscriptionPack OR a token that is an array of subscription pack
 * This class wrap the two for help the view implementation
 */
export class SubscriptionPackOrTokenWrapper {

    private _packTokenWrapper: PackTokenWrapper;

    // user can subscribe to ONE subscriptionPack at time
    // but keep an array over recreate one over and over from angular detect change is an optimal option
    private _subscriptionPacksToSubscribe: Array<SubscriptionPack>;

    get packTokenWrapper(): PackTokenWrapper {
        return this._packTokenWrapper;
    }

    set packTokenWrapper(packTokenWrapper: PackTokenWrapper) {
        this._packTokenWrapper = packTokenWrapper;
        this._subscriptionPacksToSubscribe = this._packTokenWrapper.subscriptionPacks;
    }
    isPackTokenMode(): boolean {
        return !!this._packTokenWrapper;
    }

    isSubscriptionPackMode(): boolean {
        return !this.isPackTokenMode();
    }

    // Proxy for subscriptionPack mode (only one subscriptionPack can be subcribe)
    get subscriptionPackToSubscribe() {
        return this._subscriptionPacksToSubscribe && this._subscriptionPacksToSubscribe.length
            ? this._subscriptionPacksToSubscribe[0]
            : null;
    }

    // Proxy for subscriptionPack mode (only one subscriptionPack can be subcribe)
    set subscriptionPackToSubscribe(subscriptionPack: SubscriptionPack) {
        if (subscriptionPack !== this.subscriptionPackToSubscribe) {
            this._subscriptionPacksToSubscribe = [subscriptionPack];
        }
    }

    // proxy of subscriptionPacks token or a subscriptionPack
    get subscriptionPacks(): Array<SubscriptionPack> {
        return this.isPackTokenMode() ? this._packTokenWrapper.subscriptionPacks : this._subscriptionPacksToSubscribe;
    }

    get subscriptionPackRefs(): Array<string> {
        return this.isPackTokenMode() ? this._packTokenWrapper.subscriptionPacks.map(pack => pack._id) :
            this._subscriptionPacksToSubscribe.map(pack => pack._id);
    }

    set subscriptionPacks(subscriptionPacks: Array<SubscriptionPack>) {
        if (this.isSubscriptionPackMode() && subscriptionPacks.length === 1) {
            this._subscriptionPacksToSubscribe = subscriptionPacks;
        }
    }

    // If isPackToken then return the token code
    get tokenValue(): string {
        return this.isPackTokenMode() ? this._packTokenWrapper.packToken : null;
    }

    // When a token is use a subscriptionPack is selected for the media order
    get subscriptionPackForMediaOrder(): SubscriptionPack {
        if (this.isSubscriptionPackMode()) {
            return this.subscriptionPackToSubscribe;
        } else {
            return this._packTokenWrapper.subscriptionPacks
                .find(subscriptionPack => subscriptionPack._id === this._packTokenWrapper.subscriptionPackForMediaConfigRef);
        }
    }

    // When a token is use a subscriptionPack is selected for options
    get subscriptionPackForOptions(): SubscriptionPack {
        if (this.isSubscriptionPackMode()) {
            return this.subscriptionPackToSubscribe;
        } else {
            return this._packTokenWrapper.subscriptionPacks
                .find(subscriptionPack => subscriptionPack._id === this._packTokenWrapper.subscriptionPackForOptionsConfigRef);
        }
    }
}
