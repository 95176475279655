import {Component} from '@angular/core';
import {FrontInvoiceHttpService, Invoice, InvoiceWrapper, PaymentType, User} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {Moment} from 'moment';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'invoice-historic',
    templateUrl: './invoice-historic.component.html',
    styleUrls: ['./invoice-historic.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class InvoiceHistoricComponent {
    PaymentType = PaymentType;
    public invoices: InvoiceWrapper[];
    public nbrInvoicesByPage: number = 10;
    private exportResults: string;
    public invoicesPage: number;
    public nbrPageMax: number;

    public searchInvoiceForm: SearchInvoiceForm;
    public searchingInvoices: boolean;
    private user: User;


    constructor(private invoiceService: FrontInvoiceHttpService,
        route: ActivatedRoute) {
        this.invoicesPage = 1;
        this.invoices = [];
        this.searchInvoiceForm = {
            startDate: null,
            endDate: null,
            invoiceId: null,
        };
        route.data.subscribe(data => {
            this.user = data.user.user;
            this.exportResults = '/api/front/users/' + this.user._id + '/invoice/export/csv';
            this.invoiceService.findNbrTotalPaidInvoices(this.user._id, this.searchInvoiceForm.invoiceId,
                this.searchInvoiceForm.startDate, this.searchInvoiceForm.endDate).subscribe((nbrTotalInvoices) => {
                this.nbrPageMax = nbrTotalInvoices > this.nbrInvoicesByPage
                    ? Math.ceil(<number>nbrTotalInvoices / this.nbrInvoicesByPage) : 1;
            });
            this.getInvoices();
        });
    }

    public getExportResultsUrl() {
        let url = this.exportResults;
        if (!!this.searchInvoiceForm.startDate || !!this.searchInvoiceForm.endDate ||
            !!this.searchInvoiceForm.invoiceId) {
            url += '?';
            if (!!this.searchInvoiceForm.startDate) {
                url += 'from=' + this.searchInvoiceForm.startDate + '&';
            }
            if (!!this.searchInvoiceForm.endDate) {
                url += 'to=' + this.searchInvoiceForm.endDate + '&';
            }
            if (!!this.searchInvoiceForm.invoiceId) {
                url += 'invoiceId=' + this.searchInvoiceForm.invoiceId;
            }
        }
        return url;
    }

    public showInvoice(invoice: Invoice): string {
        return `api/customer/${invoice.customerRef}/invoice/${invoice._id}-${invoice.uuid}`;
    }

    public invoiceSearch(): void {
        this.getInvoices();
    }

    public getInvoices(page: number = this.invoicesPage): void {
        this.invoicesPage = page;
        this.searchingInvoices = true;

        const skip = (page - 1) * this.nbrInvoicesByPage;

        this.invoiceService.findPaidInvoices(this.user._id, this.nbrInvoicesByPage, skip, this.searchInvoiceForm.invoiceId,
            this.searchInvoiceForm.startDate, this.searchInvoiceForm.endDate)
            .pipe(
                finalize(() => this.searchingInvoices = false)
            )
            .subscribe((invoices) => this.invoices = invoices);
    }

    trackByInvoiceId(index: number, invoiceWrapper: InvoiceWrapper) {
        return invoiceWrapper?._id ?? index;
    }
}

interface SearchInvoiceForm {
    startDate?: Moment;
    endDate?: Moment;
    invoiceId?: string;
}
