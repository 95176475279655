<div class="space-top-16">
    <div class="grid-x grid-margin-x align-center">
        <div class="grid-y cell auto payment-block text-center"  *ngIf="paymentConf"
             [ngClass]="{selected : isCurrentPaymentConfSelected}"
             (click)="selectCurrentPayment()">
            <i class="icon-credit payment-block_icon text-center"
               *ngIf="paymentConf.paymentType === PaymentType.CREDIT_CARD"></i>
            <i class="icon-credit payment-block_icon text-center"
               *ngIf="paymentConf.paymentType === PaymentType.CREDIT_CARD_RECURRING"></i>
            <i class="icon-invoice payment-block_icon text-center"
               *ngIf="paymentConf.paymentType === PaymentType.SEPA"></i>
            <i class="icon-invoice payment-block_icon text-center"
               *ngIf="paymentConf.paymentType === PaymentType.ACCOUNT_MANAGER"></i>
            <h6 class="payment-block_title white">{{'payment.current.' + paymentConf.paymentType |translate}}</h6>
        </div>
        <div class="grid-y cell auto payment-block text-center" *ngFor="let paymentType of paymentTypesAvailables"
             [ngClass]="{selected : paymentTypeSelected === paymentType}"
             (click)="selectPayment(paymentType)" [hidden]="!isAvailable(paymentType)">
            <i class="icon-credit payment-block_icon text-center"
               *ngIf="paymentType === PaymentType.CREDIT_CARD"></i>
            <i class="icon-credit payment-block_icon text-center"
               *ngIf="paymentType === PaymentType.CREDIT_CARD_RECURRING"></i>
            <i class="icon-invoice payment-block_icon text-center"
               *ngIf="paymentType === PaymentType.SEPA"></i>
            <h6 class="payment-block_title white">{{'payment.' + paymentType |translate}}</h6>
        </div>
    </div>
    <div class="grid-x" [hidden]="paymentConf && paymentConf.paymentType === PaymentType.ACCOUNT_MANAGER">
        <label class="cell small-8 margin-label bottom"
               [class.required]="isConfigNameRequired()"
               [ngClass]="{validate : !isConfigNameRequired || !!paymentConfigName}">
            <span>{{'invoice.popup.label' | translate}}</span>
            <input type="text"
                   [(ngModel)]="paymentConfigName"
                   name="configName"
                   #configName="ngModel"
                   (keyup)="setPaymentConfigName()"
                   [required]="isConfigNameRequired()">
        </label>
    </div>
</div>
