import {ErrorHandler, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ViewsModule} from './views/views.module';
import {AppRootComponent} from './views/appRoot.component';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEnGb from '@angular/common/locales/en-GB';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import {DefaultUrlSerializer, UrlSerializer} from '@angular/router';
import {
    ACCEPTED_FILE_FORMATS,
    ApiInterceptor,
    CHARGING_PROGRAM_DURATION,
    DEFAULT_PASSWORD_VALIDATORS,
    DeviceInterceptor,
    FLEET_CALENDAR_EVENT_DATE_FORMAT,
    FRONT_ORIGIN,
    FrontLocalStorageService,
    FrontOrigin,
    FrontOriginInterceptor,
    FrontSessionBusinessService,
    FrontStorageService,
    HOST_THIRD_PARTY_ID,
    Language,
    LibFrontModule,
    PASSWORD_VALIDATORS,
    THEME
} from 'lib-front';
import {theme} from '../theme';
import {createTranslateLoader} from './services/i18n/i18n.function';
import {LanguageInterceptor} from './interceptors/language.interceptor';
import {ComponentsModule} from './components/components.module';
import {AnonymousInterceptor} from './interceptors/anonymous.interceptor';
import * as Sentry from '@sentry/angular';
import {fileFormats} from '../fileFormats';
import {chargingProgramDuration} from '../chargingProgramDuration';
import {fleetCalendarEventDateFormat} from '../fleetCalendarEventDateFormat';
import {CollaboratorRoleInterceptor} from './interceptors/collaboratorRole.interceptor';
import {MatomoInitializationMode, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';

registerLocaleData(localeFr, Language.FR);
registerLocaleData(localeEnGb, Language.EN_GB);

export class DynamicLocaleId extends String {
    constructor(private translateService: TranslateService) {
        super();
    }

    toString() {
        return this.translateService.currentLang;
    }
}

/**
 * Http interceptors are declared by priority order (first is called before last)
 * @see https://angular.io/guide/http#interceptor-order
 */
const httpInterceptorProviders: Provider[] = [
    // URL
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    // Standard headers
    {provide: HTTP_INTERCEPTORS, useClass: AnonymousInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    // Custom headers
    {provide: HTTP_INTERCEPTORS, useClass: FrontOriginInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DeviceInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CollaboratorRoleInterceptor, multi: true}
];

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),
        ViewsModule,
        ComponentsModule,
        LibFrontModule,
        NgxMatomoTrackerModule.forRoot({
            mode: MatomoInitializationMode.AUTO_DEFERRED,
            runOutsideAngularZone: true
        }),
        NgxMatomoRouterModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useClass: DynamicLocaleId,
            deps: [TranslateService]
        },
        {
            provide: PASSWORD_VALIDATORS,
            useValue: DEFAULT_PASSWORD_VALIDATORS
        },
        {provide: FRONT_ORIGIN, useValue: FrontOrigin.FLEET},
        {provide: HOST_THIRD_PARTY_ID, useValue: theme.thirdPartyId},
        {provide: THEME, useValue: theme},
        {provide: UrlSerializer, useClass: DefaultUrlSerializer},
        {provide: FrontStorageService, useClass: FrontLocalStorageService},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        httpInterceptorProviders,
        {provide: ACCEPTED_FILE_FORMATS, useValue: fileFormats},
        {provide: CHARGING_PROGRAM_DURATION, useValue: chargingProgramDuration},
        {provide: FLEET_CALENDAR_EVENT_DATE_FORMAT, useValue: fleetCalendarEventDateFormat},
    ],
    bootstrap: [AppRootComponent]
})
export class AppModule {
    constructor(sessionBusinessService: FrontSessionBusinessService) {
        sessionBusinessService.reloadUser();
    }
}
