import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TabComponent} from './tab.component';
import {Location} from '@angular/common';
import {TabsDirection} from './tabs-direction';

@Component({
    selector: 'tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    host: {'class': 'grid-x cell auto'}
})
export class TabsComponent {
    @Input() public direction: TabsDirection = TabsDirection.HORIZONTAL;
    @Input() public hideHeads: boolean;
    @Input() set tabSelected (tabSelected: number) {
        this.selectItem(this.items[tabSelected]);
    }
    @Output() public tabSelectedChange: EventEmitter<number> = new EventEmitter<number>();

    constructor(private location: Location) {}

    public items: Array<TabComponent> = [];
    public selectedItem: TabComponent;

    public registerTab(item: TabComponent): void {
        if (this.items.length === 0) {
            item.selected = true;
            this.selectedItem = item;
        }
        this.items.push(item);
    }

    public selectItem(item: TabComponent): void {
        if (!!item) {
            this.selectedItem.selected = false;
            item.selected = true;
            this.selectedItem = item;

            if (item.changeUrl && item.changeUrl != this.location.path()) {
                const index = this.items.findIndex(tab => tab === item);
                this.tabSelectedChange.emit(index);
                this.location.go(item.changeUrl);
            }
        }
    }

    public isVertical(): boolean {
        return this.direction === TabsDirection.VERTICAL;
    }

    trackByTabTitle(index: number, tab: TabComponent) {
        return tab?.title ?? index;
    }
}
