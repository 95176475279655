import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StationDto } from 'lib-front';

@Component({
    selector: 'station-report-incident-popup',
    templateUrl: './station-report-incident-popup.component.html',
    styleUrls: ['./station-report-incident-popup.component.scss']
})
export class StationReportIncidentPopupComponent {

    @Input() station: StationDto;
    @Output() modalConfirm = new EventEmitter<ReportIncidentModalResponse>();
    @Output() modalClose = new EventEmitter<void>();
    public modalResponse: ReportIncidentModalResponse = {
        checkbox: false,
        reason: ''
    };
    public createIncidentReasonMissing = false;

    closeCreateIncidentModal() {
        this.modalResponse = {
            checkbox: false,
            reason: ''
        };
        this.createIncidentReasonMissing = false;
        this.modalClose.emit();
    }

    confirmModal() {
        if (!this.modalResponse.reason) {
            this.createIncidentReasonMissing = true;
            return;
        }

        this.modalConfirm.emit(this.modalResponse);
    }
}

export interface ReportIncidentModalResponse {
    checkbox: boolean,
    reason: string
}
