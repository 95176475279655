<div class="subscriptionContainer noHand">
    <div class="offer-block image fix-height subscriptionContainer-block">
        <div class="subscriptionContainer-block-wrapper">
            <div class="grid-y offer-block-title">
                <h3 class="cell offer-block-title-color"
                    [innerHTML]="packSubscription?.subscriptionPackName"></h3>
                <h5 class="cell offer-block-subtitle-color space-top-8 text-center"
                     [innerHTML]="subscriptionPack?.subtitle">
                </h5>
                <div class="promoTag">
                    <i class="fas fa-tag"></i>
                    {{'subscription.pack.promo.tagTitle' | translate}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid-y cell shrink align-middle">
    <label class="cell shrink subscription-label">
        {{'subscription.pack.start'| translate:
        {startDate: packSubscription?.subscriptionStartDate | date : "shortDate"}
        }}
    </label>
    <label class="cell shrink subscription-label"
           *ngIf="!!packSubscription?.subscriptionEndDate">
        {{
        'subscription.pack.promo.validUntil' | translate:
            {endDate: packSubscription?.subscriptionEndDate | date : "shortDate"}
        }}
    </label>
    <small>&nbsp;</small>
</div>
