<div class="register-block">
    <h3 class="cell small-24 secondary block-title">
        {{'register.subscription.additionalInformation.title' | translate}}
    </h3>

    <div class="border-content">
        <form class="grid-x grid-padding-x has-space" #additionalInformationForm="ngForm">
            <ng-container *ngFor="let additionalInformation of additionalInformationList">
                <additional-information-field [class.sectionTitle]="isSection(additionalInformation)"
                                              [class.small-12]="!isSection(additionalInformation)"
                                              class="cell"
                                              (fieldChange)="formChanged()"
                                              [additionalInformation]="additionalInformation"
                                              [additionalInformationAnswers]="additionalInformationAnswers">
                </additional-information-field>
            </ng-container>
        </form>
    </div>
</div>

