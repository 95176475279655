<div class="collapseContainer">
    <div (click)="toggleCollapsed()">
        <ng-content select="[fixed]"></ng-content>
    </div>
    <div [hidden]="!isCollapsed">
        <ng-content select="[collapsible]"></ng-content>
    </div>
    <div class="grid-x align-right align-middle moreDetail space-bottom-8" [class.hidden]="!isCollapsed"
         (click)="toggleCollapsed()">
        <a>{{'chargeAreas.lessDetails' |translate}}</a>
        <i class="moreDetail-arrow fas fa-arrow-up"></i>
    </div>
    <div class="grid-x align-right align-middle moreDetail space-bottom-8" [class.hidden]="isCollapsed"
         (click)="toggleCollapsed()">
        <a>{{'chargeAreas.moreDetails' |translate}}</a>
        <i class="moreDetail-arrow fas fa-arrow-down"></i>
    </div>
</div>
