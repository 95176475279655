import {Component, OnInit} from '@angular/core';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {
    CurrentSubscriberRolesService,
    FrontEndFleetConfig,
    FrontPersonPortalContextService,
    SubscriberRoleLevel,
    SubscriberRoles,
    SubscriberRoleType
} from 'lib-front';
import {FrontEndService} from '../../../services/frontEnd.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    host: {'class': 'cell auto scroll-container home'}
})
export class HomeComponent implements OnInit {
    public fleetConfig: FrontEndFleetConfig;
    public isStationManager: boolean;
    public isRefundManager: boolean;
    public hiddenMap: boolean = false;
    public subscriberRoles: SubscriberRoles;

    public rolesLoaded: boolean;

    public SubscriberRoleType = SubscriberRoleType;

    public constructor(
        private readonly frontEndService: FrontEndService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly personPortalContextService: FrontPersonPortalContextService) {
    }

    ngOnInit(): void {
        this.personPortalContextService.isStationManager()
            .pipe(
                tap(isStationManager => this.isStationManager = isStationManager),
                switchMap(() => this.frontEndService.currentFrontEndInfo$),
                tap(frontEndInfo => this.hiddenMap = frontEndInfo.config.hiddenMap),
                tap(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig),
                switchMap(() => this.personPortalContextService.isRigeUser()),
                tap(isRefundManager => this.isRefundManager = isRefundManager),
                switchMap(() => this.currentSubscriberRolesService.getCurrentSubscriberRoles()),
                finalize(() => this.rolesLoaded = true)
            )
            .subscribe(
                roles => this.subscriberRoles = roles
            );
    }

    public canReadResource(type: SubscriberRoleType): boolean {
        if (!this.subscriberRoles) {
            return false;
        }
        return this.currentSubscriberRolesService.rolesContain(this.subscriberRoles, {
            type: type,
            level: SubscriberRoleLevel.READ
        });
    }
}
