<div class="grid-y cell main-container">
    <form class="grid-y cell marginBottom" #stationForm="ngForm">
        <div class="grid-x cell shrink small-up-3 space-bottom-16">
            <label class="cell small-4 margin-label bottom space-right-16">
                <span>{{'stations.edit.chargeAreaName' | translate}}</span>
                <input type="text" [(ngModel)]="station.chargeAreaName" name="chargeAreaName"
                       #chargeAreaName="ngModel" disabled>
            </label>
            <label class="cell small-4 margin-label bottom">
                <span>{{'stations.edit.visualId' | translate}}</span>
                <input type="text" [(ngModel)]="station.visualId" name="visualId"
                       #visualId="ngModel" required>
            </label>
        </div>
        <label class="cell space-bottom-8" *ngFor="let userAction of station.userActions">
            <span class="">{{'stations.edit.userActions.' + userAction | translate}}</span>
        </label>
    </form>
    <station-remote-action [station]="station" [hasStationWriteRole]="hasStationWriteRole"></station-remote-action>
</div>
