import {Component, Input} from '@angular/core';
import {MetaReservationConsumptionDto} from '../../../domain/meta/metaReservationConsumptionDto';
import {
    isMetaReservationConsumption,
    MetaConsumptionDto
} from '../../../domain/meta/metaConsumptionDto';

@Component({
    selector: 'reservation-consumption-details[consumption]',
    templateUrl: './reservation-consumption-details.component.html',
    styleUrls: ['./reservation-consumption-details.component.scss']
})

export class ReservationConsumptionDetailsComponent {
    private _consumption: MetaReservationConsumptionDto;

    @Input()
    set consumption(consumption: MetaConsumptionDto) {
        if (isMetaReservationConsumption(consumption)) {
            this._consumption = consumption;
        } else {
            this._consumption = undefined;
        }
    }

    get consumption(): MetaReservationConsumptionDto {
        return this._consumption;
    }

    constructor() {
    }
}
