<div *ngIf="!!tariff" [class.selected]="selected" class="grid-x cell auto tariff">
    <ng-template #createOn class="cell">
        <p class="tariff-date">
            {{'config.tariff.createOn' | translate:{date: tariff.createDate | date : "shortDate"} }}
        </p>
    </ng-template>

    <h4 class="cell small-21 tariff-name">
        <i class="fas fa-lock" *ngIf="!tariff.applyByQRCode"></i>
        <i class="fas fa-lock-open" *ngIf="tariff.applyByQRCode"></i>
        {{'tariff.name'|translate:{name: tariff.name} }}
    </h4>

    <button (click)="deleteTariff(); $event.preventDefault()"
            [iziviaLoading]="deleting"
            [hidden]="!selected || !canDelete"
            class="cell shrink button round secondary"><i class="fas fa-trash-alt"></i></button>

    <tariff-station-usage class="cell" [tariff]="tariff"></tariff-station-usage>

    <div class="grid-x align-middle align-justify small-24">
        <div class="cell shrink" *ngIf="tariffWasUpdated() else createOn">
            <p class="tariff-date">{{'config.tariff.updateOn' | translate:{date: tariff.lastEditDate | date :
                    "shortDate"} }}</p>
        </div>
    </div>

    <div class="grid-x align-right moreDetail small-24 space-top-10">
        <div class="cell text-right">
            <a class="tariff-detail" [class.selected]="selected">Plus de détail</a>
            <i class="moreDetail-arrow fas fa-arrow-right"></i>
        </div>
    </div>
</div>
