<fleet-list-form *ngIf="!vehicle" [vehicles]="vehicles"
                 [nbrVehicles]="nbrVehicles"
                 [searching]="searching"
                 [hasFleetWriteRole]="hasFleetWriteRole"
                 [pageNumber]="pageNumber"
                 [nbrPageMax]="nbrPageMax"
                 [displayLimitMessage]="displayLimitMessage"
                 (searchChange)="searchByText($event)"
                 (nbrVehiclesChange)="updateNbrVehicles($event)"
                 (pageChange)="search(criteria, $event)">
</fleet-list-form>
<fleet-vehicle-form *ngIf="vehicle"
                    [vehicle]="vehicle">
</fleet-vehicle-form>

