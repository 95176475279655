import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ConnectedUser, FrontVehicleHttpService, Vehicle} from 'lib-front';
import {Observable} from 'rxjs';

@Injectable()
export class FleetResolver implements Resolve<Vehicle> {
    constructor(private readonly vehicleService: FrontVehicleHttpService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Vehicle> | Vehicle {
        let vehicleId = route.paramMap.get('id');
        if (vehicleId === 'new') {
            return this.vehicleService.buildEmptyVehicle();
        } else {
            return this.vehicleService.findById(vehicleId, false);
        }
    }
}
