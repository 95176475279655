import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServicesModule} from '../../../../../services/services.module';
import {UpdateSubscriptionComponent} from './update-subscription.component';
import {UpdateSubscriptionOffersComponent} from './update-subscription-offers/update-subscription-offers.component';
import {UpdateSubscriptionOptionsComponent} from './update-subscription-options/update-subscription-options.component';
import {UpdateSubscriptionMediasComponent} from './update-subscription-medias/update-subscription-medias.component';
import {UpdateSubscriptionSummaryComponent} from './update-subscription-summary/update-subscription-summary.component';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {NewSubscriptionComponent} from './new-subscription/new-subscription.component';
import {NewSubscriptionSummaryComponent} from './new-subscription-summary/new-subscription-summary.component';
import {ComponentsModule} from '../../../../../components/components.module';
import { MediaLinkingFormComponent } from './update-subscription-medias/media-linking-form/media-linking-form.component';
import {DirectivesModule} from '../../../../../directives/directives.module';

@NgModule({

    declarations: [
        UpdateSubscriptionComponent,
        NewSubscriptionComponent,
        UpdateSubscriptionOffersComponent,
        UpdateSubscriptionOptionsComponent,
        UpdateSubscriptionMediasComponent,
        UpdateSubscriptionSummaryComponent,
        NewSubscriptionSummaryComponent,
        MediaLinkingFormComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ServicesModule,
        ComponentsModule,
        PipesModule,
        DirectivesModule
    ]
})
export class UpdateSubscriptionModule {
}
