import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {FrontEndFleetConfig, FrontVehicleHttpService, Reporting, Vehicle, VehicleSearchCriteria} from 'lib-front';
import {filter} from 'rxjs/operators';
import {FleetVehicleUtils} from '../fleetVehicleUtils';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'app-fleet-planning',
    templateUrl: './fleet-planning.component.html',
    styleUrls: ['./fleet-planning.component.scss'],
    host: {'class': 'grid-x grid-padding-x grid-padding-y app-view-content'},
})
export class FleetPlanningComponent implements OnInit {
    private readonly noSelectedVehiclebaseUrl: string = '/main/configuration/fleet/planning';

    vehicles: Vehicle[] = [];
    noVehicleSelected: boolean;
    pageNumber: number = 1;
    nbrPageMax: number = 0;
    criteria: VehicleSearchCriteria = { skip: 0, limit: 10 };
    Reporting = Reporting;
    fleetConfig: FrontEndFleetConfig;

    constructor(
        private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly frontEndService: FrontEndService,
        private readonly router: Router) {
    }

    ngOnInit(): void {
        if (this.router.url === this.noSelectedVehiclebaseUrl) {
            this.noVehicleSelected = true;
        }

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                if (event.url === this.noSelectedVehiclebaseUrl) {
                    this.noVehicleSelected = true;
                } else {
                    this.noVehicleSelected = false;
                }
            });

        this.vehicleHttpService.findVehiclesStats({})
            .subscribe((vehicleStats) => {
                this.recomputeNbrPageMax(vehicleStats.fleetVehicleSize);
            });

        this.search(this.criteria, 1);

        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);
    }

    public search(criteria: VehicleSearchCriteria, pageNumber) {
        criteria.skip = (pageNumber - 1) * this.criteria.limit;
        this.pageNumber = pageNumber;

        this.vehicleHttpService.findVehicles(this.criteria)
            .subscribe((vehicles) => {
                this.vehicles = vehicles;
            });

    }

    private recomputeNbrPageMax(nbrVehicles: number) {
        this.nbrPageMax = nbrVehicles > this.criteria.limit ?
            Math.ceil(nbrVehicles / this.criteria.limit) : 1;
    }

    public backToFleet() {
        this.router.navigate(['/main/configuration/fleet']);
    }

    public trackyByVehicleId(index: number, vehicle: Vehicle) {
        return vehicle?.id ?? index;
    }

    public isVehicleCreatedInDreevProvider(vehicle: Vehicle): boolean {
        return FleetVehicleUtils.isVehicleCreatedInDreevProvider(vehicle);
    }
}
