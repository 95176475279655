import {Component, OnInit} from '@angular/core';
import {
    FrontPackSubscriptionHttpService,
    OptionSubscription,
    PackSubscription,
    StdUser,
    Subscription,
    SubscriptionDef,
    SubscriptionRevocation
} from 'lib-front';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../../../services/utils/alert.service';
import {finalize, tap} from 'rxjs/operators';
import {ModeSubscriptionOption, OptionForm} from '../../../../../components/subscription-options/subscription-options.component';
import * as moment from 'moment';
import {getOptionsFromPackSubscription} from '../../../../../utils/packSubscriptionUtils.service';
import {chain} from '../../../../../../libs/lodash-es/chain';

@Component({
    selector: 'revoke-options',
    templateUrl: './revoke-options.component.html',
    styleUrls: ['./revoke-options.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class RevokeOptionsComponent implements OnInit {
    ModeSubscriptionOption = ModeSubscriptionOption;

    connectedUser: StdUser;
    packSubscription: PackSubscription;
    packSubscriptionId: string;

    options: Array<OptionSubscription>;
    optionsRevocable: Array<OptionForm>;
    optionsByDef: Map<String, Array<Subscription>> = new Map();

    optionsOfTab: Array<Option>;

    totalHours;
    totalPrice;

    loading: boolean = false;

    constructor(private route: ActivatedRoute,
        private packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private alertService: AlertService,
        private router: Router) {
    }

    ngOnInit() {
        this.route.data
            .pipe(
                tap(data => this.connectedUser = data.user.user),
                tap(data => this.packSubscription = data.packSubscription)
            )
            .subscribe(() => {
                this.options = getOptionsFromPackSubscription(this.packSubscription);

                const optionsBySubscriptionDefRef: { [subscriptionDefRef: string]: Array<Subscription> } =
                    chain(this.packSubscription.customerAccounts)
                        .map(customerAccount => customerAccount.subscriptions)
                        .flatten()
                        .filter(subscription => !!subscription?.options?.length)
                        .map(subscription => subscription as Subscription)
                        .map(subscription => subscription.options)
                        .flatten()
                        .groupBy('subscriptionDefRef')
                        .value();

                // Transform groupBy result to Map structure (optionsByDef type might be refactorable to avoid this step)
                Object.keys(optionsBySubscriptionDefRef).forEach(subscriptionDefRef => {
                    this.optionsByDef.set(subscriptionDefRef, optionsBySubscriptionDefRef[subscriptionDefRef]);
                });

                this.optionsRevocable = Object.values(optionsBySubscriptionDefRef).map(options => {
                    const now = moment();
                    const subscriptionDef: SubscriptionDef = options[0].subscriptionDef as SubscriptionDef;
                    const maxRevocable = options
                        .filter(option => !option.engagementEndDate || moment(option.engagementEndDate).isBefore(now))
                        .length;

                    return {
                        subscription: {...subscriptionDef, maxRevocable},
                    } as OptionForm;
                });
            });
    }

    viewSubscription(subscriptionDefId) {
        // ?? What's the point of grouping by startDate with moment?
        const optionsBySubscriptionDay: { [subscriptionDay: string]: Array<Subscription> } = chain(this.optionsByDef.get(subscriptionDefId))
            .map(option => ({
                ...option,
                subscriptionDay: moment(option.startDate).toString()
            }))
            .groupBy('subscriptionDay')
            .value();

        this.optionsOfTab = Object.values(optionsBySubscriptionDay).map((options: Array<Subscription>) => ({
            ...options[0],
            quantity: options.length
        }));
    }

    calculateTotal() {
        this.totalHours = 0;
        this.totalPrice = 0;
        if (this.options) {
            this.options.forEach(option => {
                this.totalHours += option.subscription.freeTime * option.number;
                this.totalPrice += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
            });

            if (this.optionsRevocable) {
                this.optionsRevocable.forEach(option => {
                    this.totalHours -= option.subscription.freeTime * option.number;
                    this.totalPrice -= option.subscription.fixAmountWithVAT * option.number;
                });
            }
        }
    }

    closeTab() {
        this.optionsOfTab = null;
    }

    get hasRevocableOptions() {
        return this.optionsRevocable && this.optionsRevocable
            .filter(optionRevocable => +optionRevocable.number)
            .length;
    }

    revokeOptions() {
        this.alertService.confirm(this.translateService.instant('options.revoke.alert')).subscribe(confirm => {
            if (!confirm) {
                return;
            }
            this.loading = true;
            const subscriptionRevocations: Array<SubscriptionRevocation> = [];
            const now = moment();

            this.optionsRevocable
                .filter(optionRevocable => +optionRevocable.number)
                .forEach(optionRevocable => {
                    const subscriptions = this.optionsByDef.get(optionRevocable.subscription._id)
                        .filter(subscription => !subscription.engagementEndDate || moment(subscription.engagementEndDate).isBefore(now));

                    if (subscriptions.length > optionRevocable.number) {
                        subscriptions.length = +optionRevocable.number;
                    }

                    subscriptions.forEach(subscription =>
                        subscriptionRevocations.push({targetRef: subscription.id, clientRef: subscription.customerRef}));
                });

            if (subscriptionRevocations.length) {
                this.packSubscriptionHttpService.revokeMultipleOption(subscriptionRevocations)
                    .pipe(
                        finalize(() => this.loading = false)
                    )
                    .subscribe(() => {
                        this.notificationService.success('options.revoke.success');
                        this.router.navigate(['/main/subscriptions']);
                    });
            }
        });
    }

    trackBySubscriptionId(index: number, subscription: Subscription) {
        return subscription?.id ?? index;
    }
}

export type Option = Subscription & {
    quantity: number
};
