import {Route} from '@angular/router';
import {ReportingView} from './reporting.view';
import {ReportingPanelComponent} from './reporting-panel/reporting-panel.component';
import {ReportingPanelGuard} from './reporting-panel.guard';
import {Reporting} from 'lib-front';

export const reportingRoutes: Route[] = [
    {
        path: '', component: ReportingView, children: [
            {path: ':reportingType', component: ReportingPanelComponent, canActivate: [ReportingPanelGuard]}
        ]
    },
];

export const reportingPanelRoutes: Record<Reporting, { path: string, order: number }> = {
    [Reporting.CHARGING_POINT]: {
        path: '/main/reporting/chargingStation',
        order: 1
    },
    [Reporting.CHARGES_EVOLUTION]: {
        path: '/main/reporting/chargesEvolution',
        order: 2
    },
    [Reporting.FLEET]: {
        path: '/main/reporting/fleet',
        order: 3
    },
    [Reporting.EXPENSES_REPORTS]: {
        path: '/main/reporting/reportExpenses',
        order: 4
    },
    [Reporting.RIGE]: {
        path: '/main/reporting/rige',
        order: 5
    },
    [Reporting.SERVICES]: {
        path: '/main/reporting/services',
        order: 6
    },
    [Reporting.SOBRIETY]: {
        path: '/main/reporting/sobriety',
        order: 7
    }
};
