import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CurrentUserContextService} from '../services/business/currentUserContext.service';
import {PersonPortalContext} from 'lib-front';

@Injectable()
export class CurrentUserContextResolver implements Resolve<PersonPortalContext> {
    constructor(private readonly currentUserContextService: CurrentUserContextService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersonPortalContext> {
        return this.currentUserContextService.compute();
    }
}
