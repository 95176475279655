<div class="cell">
    <div (click)="select.emit()"
         [ngClass]="{'selected' : selected}"
         class="grid-y align-middle align-center offer-block">
        <h4 [innerHTML]="'register.subscription.pack.name'  | translate:{subscriptionPack : title}"
             class="cell offer-block-title-color text-center">
        </h4>
        <h5 *ngIf="subtitle"
             [innerHTML]="subtitle  | translate"
             class="cell offer-block-subtitle-color space-top-10 text-center">
        </h5>
        <ng-container *ngIf="fixPriceWithVat || overloadedFixPriceLabel">
            <div *ngIf="overloadedFixPriceLabel === null; else showOverloadedFixPrice"
                 class="grid-x align-bottom space-top-16 align-middle priceContainer">
                <h6 class="cell auto text-center">
                    {{fixPriceWithVat | currency: 'EUR': currencyUnit || ''}}</h6>
                <h6 class="cell shrink space-left-8">{{'register.subscription.pack.by.month'| translate}}</h6>
            </div>
        </ng-container>
        <ng-template #showOverloadedFixPrice>
            <div class="priceContainer">
                <h6 [innerHTML]="overloadedFixPriceLabel"
                    class="cell space-top-10">
                </h6>
            </div>
        </ng-template>
        <div [hidden]="!engagementDuration" class="note small space-top-8">
            {{'register.subscription.pack.engagement' | translate:{month: engagementDuration} }}
        </div>
    </div>
</div>
