<div class="cell auto app-view-content">
    <div class="grid-y cell auto main-container has-space force-space-bottom-0">
        <form class="grid-x cell shrink align-justify align-bottom grid-padding-x space-bottom-8 filter-bloc">
            <label class="cell auto">
                <span>{{'imports.criteria.startDate' | translate}}</span>
                <date-picker class="full-input" name="fromDate"
                             [(ngModel)]="criteria.startDate"></date-picker>
            </label>

            <label class="cell auto">
                <span>{{'imports.criteria.endDate' | translate}}</span>
                <date-picker class="full-input" name="toDate"
                             [(ngModel)]="criteria.endDate"></date-picker>
            </label>

            <label class="cell auto">
                <span>{{'imports.criteria.status' | translate}}</span>
                <select [(ngModel)]="criteria.status" name="status">
                    <option value="">{{'imports.status.all' | translate}}</option>
                    <option [value]="status" *ngFor="let status of importStatusList">
                        {{'imports.status.' + status | translate}}
                    </option>
                </select>
            </label>

            <button type="submit" class="cell shrink button primary force-space-bottom-8" (click)="search()"
                    [iziviaLoading]="!searchSubscription?.closed">
                {{'imports.search.title' | translate}}
            </button>
        </form>
    </div>
    <div class="grid-y cell shrink">
        <div class="grid-x cell shrink align-justify align-middle space-bottom-8" *ngIf="imports.length > 0">
            <div class="pagination">
                <button class="pagination-button" (click)="previousPage()"
                        [disabled]="this.criteria.skip === 0 || !searchSubscription?.closed">
                    <i class="fas fa-angle-left"></i>
                </button>
                <button class="pagination-button"
                        (click)="nextPage()" [disabled]="!hasNextPage || !searchSubscription?.closed">
                    <i class="fas fa-angle-right"></i>
                </button>
            </div>
        </div>
        <div class="cell shrink">
            <table>
                <thead>
                <tr>
                    <th>{{'imports.thead.status' | translate}}</th>
                    <th>{{'imports.thead.file' | translate}}</th>
                    <th>{{'imports.thead.progress' | translate}}</th>
                    <th>{{'imports.thead.startDate' | translate}}</th>
                    <th>{{'imports.thead.endDate' | translate}}</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody *ngIf="imports.length > 0">
                <ng-container *ngFor="let importData of imports; last as last; trackBy: trackByImportId">
                    <tr [class.selected]="importSelectedId === importData.id">
                        <td>{{('imports.status.' + importData.status) | translate}}</td>
                        <td><a class="status-link"
                               [href]="'/api/download/' + importData.fileRef"
                               target="_blank"><i class="fa fa-link"></i> {{importData.filename}}</a></td>
                        <td>
                            <progress-bar *ngIf="importData.status !== 'INITIAL'"
                                          [consume]="importData.executedTasks"
                                          [consumeMax]="importData.size"
                                          [color]="importData.progressBarColor"
                                          [showConsume]="true">
                            </progress-bar>
                        </td>
                        <td>{{importData.treatmentStartDate | date: 'short'}}</td>
                        <td>{{(importData.treatmentEndDate || undefined) | date: 'short'}}</td>
                        <td>
                            <button *ngIf="importSelectedId !== importData.id" class="button round eye"
                                    (click)="selectImportDetails(importData)">
                                <i class="fas fa-eye"></i>
                            </button>
                            <button *ngIf="importSelectedId === importData.id" class="button round eye white-text"
                                    (click)="selectImportDetails(importData)">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </tr>
                    <tr class="retractable retracted"
                        [class.retracted]="importSelectedId !== importData.id">
                        <td colspan="11">
                            <div class="grid-x cell shrink">
                                <label class="grid-y cell auto subMenuContainer"
                                       *ngIf="importData.fleetImportRequestReport; let report">
                                    <span class="cell subMenuTitle">{{'imports.report.title' | translate}}</span>
                                    <div>
                                    <span class="cell subMenu">
                                        <i class="fas fa-check-square subMenu-add"></i> {{'imports.report.create' | translate:{count: report.nbItemsToCreate} }}</span>
                                        <span class="cell subMenu">
                                        <i class="fas fa-pen-square subMenu-edit"></i> {{'imports.report.update' | translate:{count: report.nbItemsToUpdate} }}</span>
                                        <span class="cell subMenu">
                                        <i class="fas fa-minus-square subMenu-delete"></i> {{'imports.report.delete' | translate:{count: report.nbItemsToDelete} }}</span>
                                    </div>
                                </label>
                                <label class="grid-y cell auto subMenuContainer">
                                    <span class="cell subMenuTitle">{{'imports.duration' | translate}}</span>
                                    <span class="cell subMenu">
                                        <i class="fas fa-clock subMenu-clock"></i>{{importData.duration | fleetDuration}}</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!last" class="separator">
                        <td colspan="11">
                            <hr>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
