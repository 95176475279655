import {Component, Input} from '@angular/core';
import {
    isMetaOtpConsumption,
    MetaConsumptionDto
} from '../../../domain/meta/metaConsumptionDto';
import {MetaOtpConsumptionDto} from '../../../domain/meta/metaOtpConsumptionDto';

@Component({
    selector: 'otp-consumption-details[consumption]',
    templateUrl: './otp-consumption-details.component.html',
    styleUrls: ['./otp-consumption-details.component.scss']
})

export class OtpConsumptionDetailsComponent {
    private _consumption: MetaOtpConsumptionDto;

    @Input()
    set consumption(consumption: MetaConsumptionDto) {
        if (isMetaOtpConsumption(consumption)) {
            this._consumption = consumption;
        } else {
            this._consumption = undefined;
        }
    }

    get consumption(): MetaOtpConsumptionDto {
        return this._consumption;
    }

    constructor() {
    }
}
