import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    FrontCurrentPersonBusinessService,
    FrontCustomerAccountHttpService,
    FrontPaymentConfHttpService,
    PaymentConfExpirationReminderDto
} from 'lib-front';
import {filter, finalize, map, switchMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {isEmpty} from 'lodash-es';

@Component({
    selector: 'payment-conf-reminders',
    templateUrl: './payment-conf-reminders.component.html',
    styleUrls: ['./payment-conf-reminders.component.scss'],
    host: {'class': 'reminder'}
})
export class PaymentConfRemindersComponent implements OnInit, OnDestroy {
    public reminders: PaymentConfExpirationReminderDto[] = [];

    public loading: boolean;

    private loadPaymentConfRemindersSubscription: Subscription;

    constructor(private readonly frontPaymentConfService: FrontPaymentConfHttpService,
        private readonly currentPersonService: FrontCurrentPersonBusinessService,
        private readonly customerAccountService: FrontCustomerAccountHttpService) {
    }

    ngOnInit(): void {
        this.loadPaymentConfReminders();
    }

    ngOnDestroy(): void {
        this.loadPaymentConfRemindersSubscription?.unsubscribe();
    }

    private static computeMessageLabelKey(reminder: PaymentConfExpirationReminderDto): string {
        if (reminder.cardExpired) {
            if (!!reminder.cardNumberLastDigits) {
                return 'paymentConfExpirationReminder.expired.message';
            }

            return 'paymentConfExpirationReminder.expired.withoutLastDigits.message';
        }

        if (!!reminder.cardNumberLastDigits) {
            return 'paymentConfExpirationReminder.notExpired.message';

        }

        return 'paymentConfExpirationReminder.notExpired.withoutLastDigits.message';
    }

    private loadPaymentConfReminders(): void {
        this.loadPaymentConfRemindersSubscription = this.currentPersonService.currentDetailedPerson$.pipe(
            filter(currentDetailedPerson => currentDetailedPerson !== null),
            filter(currentDetailedPerson => !isEmpty(currentDetailedPerson.customerAccounts)),
            map(currentDetailedPerson => currentDetailedPerson.customerAccounts),
            map(customerAccounts => customerAccounts.map(customerAccount => customerAccount.customerAccountRef)),
            switchMap(customerAccountsRefs => this.frontPaymentConfService.getPaymentConfReminders(customerAccountsRefs)),
            map(reminders => {
                reminders.forEach(reminder => reminder.messageLabelKey = PaymentConfRemindersComponent.computeMessageLabelKey(reminder));
                return reminders;
            }),
            finalize(() => this.loading = false)
        ).subscribe(reminders => this.reminders = reminders);
    }

    dismissReminder(reminder: PaymentConfExpirationReminderDto): void {
        this.loading = true;

        if (reminder.paymentConfId) {
            this.customerAccountService.dismissReminderByPaymentConfId(reminder.customerAccountId, reminder.paymentConfId)
                .subscribe(() => this.loadPaymentConfReminders());
        }
    }
}
