<div class="home-component">
    <h2 class="grid-x cell home-card-title"
        *ngIf="!isStationManager"
        [routerLink]="INVOICES_URL">
        {{'home.invoices' | translate}}
    </h2>

    <h2 class="grid-x cell home-card-title"
        *ngIf="isStationManager"
        [routerLink]="EXPENSES_URL">
        {{'home.finances' | translate}}
    </h2>



    <div class="home-card-content">
        <div class="grid-y center-container-card home-content-center">
            <div class="grid-x cell" *ngIf="invoice">
                <div class="grid-y cell">
                    <div class="grid-x cell home-card-content-alignment">
                        <div class="cell small-8 text-center">
                            <img class="home-card-content-img" src="/assets/svg/credit-card.svg"/>
                        </div>

                        <div class="cell auto home-card-content-alignment">
                            <div class="grid-y inner-space-left-30 inner-space-right-30 text-center">
                                <h6 class="cell space-top-8" *ngIf="invoice.blocking">{{'home.invoice.blockingToPay' | translate}}</h6>
                                <h6 class="cell space-top-8" *ngIf="!invoice.blocking">{{'home.invoice.toPay.title' | translate}}</h6>
                                <h6 class="cell space-top-8"> {{'home.invoice.toPay.bill' | translate}}
                                    {{invoice.invoiceId}} {{'home.invoice.toPay.of' | translate}}
                                    {{invoice.invoiceDate | date: 'short'}}
                                </h6>
                                <h5 class="cell home-invoice-price space-top-16"> {{invoice.netAmount | currency: "EUR": invoice?.currencyUnit}}
                                    <span class="home-invoice-price-label">{{'home.invoice.toPay.TTC' | translate}}</span>
                                </h5>

                                <div class="cell space-top-16">
                                    <a *ngIf="canPay(invoice)"
                                       [routerLink]="getInvoicesUrl() + '/' + invoice?._id"
                                       class="button secondary color responsiveButton">
                                        {{'home.invoice.toPay.pay' | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid-y" *ngIf="!invoice">
                <div class="grid-y align-center home-content-center">
                    <indicator class="cell shrink sm-block" [img]="'lastMonthPayed'">
                        <ng-container
                            value>{{invoiceHomeStat?.lastMonthPayed | currency:'EUR': invoiceHomeStat?.currencyUnit || undefined}}
                        </ng-container>
                        <ng-container label>
                            {{'home.lastMonthPayed' | translate }}
                        </ng-container>
                    </indicator>
                    <indicator class="cell shrink sm-block" [img]="'thisMonthPayed'">
                        <ng-container
                            value>{{invoiceHomeStat?.thisMonthPayed | currency:'EUR': invoiceHomeStat?.currencyUnit || undefined}}
                        </ng-container>
                        <ng-container label>
                            {{'home.thisMonthPayed' | translate}}
                        </ng-container>
                    </indicator>
                    <indicator class="cell shrink sm-block" [img]="'thisMonthToPay'">
                        <ng-container
                            value>{{invoiceHomeStat?.thisMonthToPay | currency:'EUR': invoiceHomeStat?.currencyUnit || undefined}}
                        </ng-container>
                        <ng-container label>
                            {{'home.thisMonthToPay' | translate}}
                        </ng-container>
                    </indicator>
                    <indicator *ngIf="refundManager"
                               (click)="redirectToExpenseReportPage()"
                               class="cell shrink sm-block" [img]="'expenseReportToTreat'">
                        <ng-container
                            value>{{invoiceHomeStat?.expenseReportAmountToRefund | currency:'EUR':invoiceHomeStat?.currencyUnit || undefined}}
                        </ng-container>
                        <ng-container label>
                            {{'home.expenseReport.amountToTreat' | translate}}
                        </ng-container>
                    </indicator>
                </div>
            </div>
        </div>
        <div class="cell text-right align-middle moreDetail">
            <a [routerLink]="getInvoicesUrl()">{{'home.details' |translate}}</a>
            <i class="moreDetail-arrow fas fa-arrow-right"></i>
        </div>
    </div>
</div>
