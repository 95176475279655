import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    host: {'class': 'switch'},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchComponent),
            multi: true
        }
    ]
})
export class SwitchComponent implements ControlValueAccessor {

    public selected: boolean = true;
    private onNgChange: (b: boolean) => void;
    private onNgTouched: () => void;
    public disabled: boolean;
    @Input()
    public trueText: string;
    @Input()
    public falseText: string;


    public writeValue(obj: boolean): void {
        this.selected = obj;
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    public onChange(b: boolean): void {
        this.selected = b;
        this.onNgChange(b);
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
