<div class="grid-x">
    <div class="cell auto">
        <h1 class="style-guide-sub-title"><i class="ion-ionic"></i>Référentiel Atoms</h1>
    </div>
</div>

<div class="grid-x cell auto grid-margin-x grid-margin-y small-up-2 medium-up-2 large-up-3">
    <div class="cell" routerLink="../colors">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-colors.svg"></span>
            <h1 class="style-guide-title">Couleurs</h1>
        </div>
    </div>
    <div class="cell" routerLink="../typography">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-typo.svg"></span>
            <h1 class="style-guide-title">Typography</h1>
        </div>
    </div>
    <div class="cell" routerLink="../icons">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-icone.svg"></span>
            <h1 class="style-guide-title">Icônes</h1>
        </div>
    </div>
    <div class="cell" routerLink="../buttons">
        <div class="style-guide-dashboard-block">
            <span class="illu-block btn"><img src="../../../assets/style-guide/illu-btn.svg"></span>
            <h1 class="style-guide-title">Boutons</h1>
        </div>
    </div>
    <div class="cell" routerLink="../forms">
        <div class="style-guide-dashboard-block">
            <span class="illu-block form"><img src="../../../assets/style-guide/illu-form.svg"></span>
            <h1 class="style-guide-title">Formulaires</h1>
        </div>
    </div>
    <div class="cell" routerLink="../status">
        <div class="style-guide-dashboard-block">
            <span class="illu-block form"><img src="../../../assets/style-guide/illu-status.svg"></span>
            <h1 class="style-guide-title">Status</h1>
        </div>
    </div>
</div>

<div class="grid-x space-top-20">
    <div class="cell auto">
        <h1 class="style-guide-sub-title"><i class="ion-ionic"></i>Référentiel Molécules</h1>
    </div>
</div>

<div class="grid-x cell auto grid-margin-x grid-margin-y small-up-2 medium-up-2 large-up-3">
    <div class="cell" routerLink="../blocks">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-block.svg"></span>
            <h1 class="style-guide-title">Blocks</h1>
        </div>
    </div>
    <div class="cell" routerLink="../messages">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-message.svg"></span>
            <h1 class="style-guide-title">Messages</h1>
        </div>
    </div>

    <div class="cell disabled" routerLink="../tabs">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-tabs.svg"></span>
            <h1 class="style-guide-title">Onglets</h1>
        </div>
    </div>

    <div class="cell" routerLink="../tables">
        <div class="style-guide-dashboard-block">
            <span class="illu-block"><img src="../../../assets/style-guide/illu-tables.svg"></span>
            <h1 class="style-guide-title">Tableaux</h1>
        </div>
    </div>
</div>
