import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'dashboard-guide',
    templateUrl: './dashboard-guide.component.html',
    styleUrls: ['./dashboard-guide.component.scss']
})
export class DashboardGuideComponent {

    constructor() {
    }
}
