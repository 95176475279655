import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FrontInvoiceBusinessService, FrontSessionBusinessService, InvoiceWrapper, switchTap} from 'lib-front';
import {Observable, of} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

/**
 * This guard prevents user from navigating to any authenticated routes (except invoices) until he has paid his first blocking invoice
 * @see https://izivia.atlassian.net/wiki/spaces/SODMBP/pages/3089080442/SCR+-+Pro+-+Inscription+-+R+capitulatif+paiement
 *
 * There is also a guard {@link BlockingInvoicesGuard} that is less restrictive: it only prevents user from navigating to a subset of routes.
 */
@Injectable()
export class FirstBlockingInvoiceGuard implements CanActivate {

    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly invoiceBusinessService: FrontInvoiceBusinessService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sessionBusinessService.connectedUser$
            .pipe(
                filter(user => user !== undefined),
                switchTap(user => {
                    if (user !== null) {
                        return this.invoiceBusinessService.hasPaidFirstInvoice(user.user._id)
                            .pipe(
                                tap(hasPaidFirstInvoice => this.redirectToInvoicesRouteIfFirstBlockingInvoice(state, hasPaidFirstInvoice))
                            );
                    } else {
                        return of([]);
                    }
                }),
                map(user => user !== null));
    }

    private redirectToInvoicesRouteIfFirstBlockingInvoice(state: RouterStateSnapshot, hasPaidFirstInvoice: boolean): void {
        if (!hasPaidFirstInvoice) {
            if (!state.url.includes('/main/invoices') && !state.url.includes('/main/finances/expenses')) {
                this.router.navigate(['/main/invoices']);
            }
        }
    }
}
