<div class="home-component center-container">
    <h2 class="grid-x home-card-title">
        {{(fleetConfig?.showVehicleSection ? 'home.fleetAndCollaborators' : 'home.collaborators') | translate}}
    </h2>

    <div class="home-card-content">
        <div class="grid-y center-container-card home-content-center">
            <div class="grid-x align-justify align-middle small-up-2 center-container-card-width">
                <indicator class="cell" [img]="'car'" [routerLink]="'/main/configuration/fleet'" (click)="$event.stopPropagation()"
                           [hidden]="!fleetConfig?.showVehicleSection">
                    <ng-container value>{{fleetHomeStat?.vehicules || 0}}</ng-container>
                </indicator>
                <indicator class="cell" [img]="'users'">
                    <ng-container value>{{fleetHomeStat?.subscribers || 0}}</ng-container>
                </indicator>
            </div>
        </div>
        <div class="grid-x align-right align-middle moreDetail">
            <a>{{'home.details' | translate}}</a>
            <i class="moreDetail-arrow fas fa-arrow-right"></i>
        </div>
    </div>

</div>
