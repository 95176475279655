<div class="text-center">
    <i class="popup-icon fas fa-exclamation"></i>

    <h2 class="popup-title">{{'fleet.program.update.popupTitle' | translate : {programDate: chargingProgram.date, programTime: chargingProgram.time} }}</h2>

    <div *ngIf="socEventType === SocEventType.RECURRING" class="popup-content">
        <div  class="popup-content_message space-bottom-24">{{'fleet.program.update.message' | translate }}</div>
    </div>

    <div class="grid-x cell auto align-justify align-middle action-bar add-space-block">
        <button class="cell shrink button validate"
                (click)="onUpdate()">
            {{'fleet.program.update.confirm' | translate}}</button>
        <button class="cell shrink button secondary loseButton"
                (click)="onCancel()">
            {{'fleet.program.update.cancel' | translate}}</button>
    </div>
    <div class="error note grid-x align-right" *ngIf="hasError(ChargingProgramError.BEFORE_ERROR)">
        {{'fleet.program.add.beforeError' | translate}}
    </div>
    <div class="error note grid-x align-right" *ngIf="hasError(ChargingProgramError.ALREADY_EXISTS_ERROR)">
        {{'fleet.program.add.alreadyExistsError' | translate}}
    </div>
</div>
