import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FrontEndService} from '../../../services/frontEnd.service';
import {FrontSessionBusinessService} from 'lib-front';
import {map, switchMap} from 'rxjs/operators';
import {ReportingService} from '../../../services/business/reporting.service';

@Injectable()
export class HomeGuard implements CanActivate {

    constructor(
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly router: Router,
        private readonly frontEndService: FrontEndService,
        private readonly reportingService: ReportingService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo?.fleetConfig),
                map(fleetConfig => fleetConfig?.reportingEnabled),
                switchMap(reportingEnabled => {
                    if (!reportingEnabled) {
                        return of(true);
                    } else {
                        return this.reportingService.activeReportingTabs().pipe(
                            map(tabs => {
                                if (tabs.length === 0) {
                                    return true;
                                }

                                return this.router.createUrlTree(['/main/reporting']);
                            })
                        );
                    }
                })
            );
    }

}
