<div class="text-center">
    <i class="popup-icon fas fa-exclamation"></i>

    <h2 class="popup-title">{{'fleet.program.delete.popupTitle' | translate : {programDate: chargingProgram.date, programTime: chargingProgram.time} }}</h2>

    <div *ngIf="socEventType === SocEventType.RECURRING" class="popup-content">
        <div class="popup-content_message space-bottom-24">{{'fleet.program.delete.message' | translate }}</div>
    </div>

    <div class="grid-x cell auto align-justify align-middle action-bar add-space-block">
        <button class="cell shrink button validate"
                (click)="deleteProgram(chargingProgram.id)"
                [iziviaLoading]="deletingProgram">
            {{'fleet.program.delete.confirm' | translate}}</button>
        <button class="cell shrink button secondary loseButton"
                (click)="onCancel()">
            {{'fleet.program.delete.cancel' | translate}}</button>
    </div>
</div>
