import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fleetSubstring'
})
export class FleetSubstringPipe implements PipeTransform {

    transform(value: string, start: string | number, end: string | number): string {
        let startStr: number;
        let endStr: number;

        if (value) {
            if (typeof start === 'number') {
                startStr = start;
            } else if (typeof start === 'string') {
                const startIndex = value.indexOf(start);
                startStr = startIndex > -1 ? startIndex + start.length : 0;
            } else {
                startStr = 0;
            }

            if (typeof end === 'number') {
                endStr = end;
            } else if (typeof end === 'string') {
                const endIndex = value.lastIndexOf(end);
                endStr = endIndex > -1 ? endIndex : value.length;
            } else {
                endStr = value.length;
            }

            if (startStr <= endStr) {
                return value.substring(startStr, endStr);
            } else {
                return value;
            }
        }

        return value;
    }
}
