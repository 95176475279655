<div class="grid-y has-space">
    <div class="block-title space-bottom-16 align-middle" [hidden]="editMode">
        <div>
            <h3 class="grid-x align-middle secondary">
                {{'config.tariff.header' | translate}}
            </h3>
        </div>
        <button class="button" (click)="newTariff()">{{'config.tariff.addNew' | translate}}</button>

    </div>
    <div class="cell shrink text-right space-bottom-16" [class.buttonHidden]="!editMode">
        <button (click)="toggleEditMode()"
                [disabled]="isSaving"
                class="button secondary">{{'config.tariff.back'|translate}}</button>
    </div>

    <div class="grid-x cell grid-margin-x">
        <div class="cell small-8 margin-rgpd" [hidden]="editMode">
            <div *ngFor="let tariff of tariffs; trackBy: trackByTariffId">
                <tariff-card [tariff]="tariff"
                             [selected]="tariff.id === selectedTariff?.id"
                             [deleting]="deleting"
                             [canDelete]="!selectedTariff?.deletion"
                             (delete)="setDeletingAndOpenDatePickerPopup($event)"
                             (click)="selectTariff(tariff, true)"></tariff-card>
                <tariff-modification-dropdown *ngIf="canShowTariffModificationDropdown(tariff)"
                                              [tariff]="selectedTariff"
                                              [selectedTariffId]="shownTariff.id"
                                              (selectTariff)="setShownTariffAndSetEditMode($event[0], $event[1])"
                                              (deleteTariff)="deleteTariffModification($event)">
                </tariff-modification-dropdown>
            </div>
        </div>
        <div class="cell auto tariffInfo" *ngIf="tariffs?.length > 0 || editMode">
            <div
                *ngIf="selectedTariffWillBeModifiedWithoutSubscribers()"
                class="callout warning">
                <i class="fas fa-exclamation-circle callout-icon"></i>
                <p>{{'config.tariff.modification.noSubscribersWarning' | translate:{applicationDate: getApplicationDate() | fleetMomentToStringDatePipe} }}</p>
            </div>

            <div
                *ngIf="selectedTariffWillBeModifiedWithSubscribers()"
                class="callout warning">
                <i class="fas fa-exclamation-circle callout-icon"></i>
                <p>{{'config.tariff.modification.warning' | translate:{creationDate: getApplicationDate() | amSubtract:30:'day' | fleetMomentToStringDatePipe} }}</p>
            </div>

            <div
                *ngIf="selectedTariffWillBeDeleted()"
                class="callout warning">
                <i class="fas fa-exclamation-circle callout-icon"></i>
                <p>{{'config.tariff.deletion.warning' | translate:{deletionDate: getDeletionDate() | fleetMomentToStringDatePipe} }}</p>
            </div>


            <tariff-form [tariff]="shownTariff"
                         [tariffApplicationDate]="applicationDate"
                         [nbOfLinkedStation]="selectedTariff?.linkedStationRefs?.length"
                         (tariffCreated)="createTariff($event)"
                         (tariffUpdated)="updateTariffs($event)"
                         (isSaving)="isSaving = $event"
                         [loading]="isLoading"
                         [tariffTradeAgreementOtherEmo2CcSubscriptionDefs]="tariffTradeAgreementOtherEmo2CcSubscriptionDefs"
                         (tariffEditionApplicationDate)="openDatePickerPopup($event)"
                         [stationWithQrCodeRefs]="stationWithQrCodeRefs"
                         [(editMode)]="editMode"
                         (editModeChange)="editModeChange()"></tariff-form>
        </div>
    </div>
    <div class="grid-y cell auto align-center align-middle message-empty"
         *ngIf="!editMode && !tariffs?.length">
        <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
        <span class="message-empty-label">{{'config.tariff.no.result' | translate}}</span>
    </div>
</div>
<div class="loader-overlay" *ngIf="deleting">
    <div class="loader">
        <span class="spinner"></span>
    </div>
</div>

<izivia-popup #tariffModificationPopup [size]="'small'">
    <tariff-modification-popup *ngIf="tariffModificationPopup.isOpened()"
                               [applicationDate]="applicationDate"
                               [minApplicationDate]="minApplicationDate"
                               [nbSubscribers]="nbOfSubscribers"
                               [linkedStations]="selectedTariffStations"
                               [deletion]="deleting"
                               [isPrimoTariff]="selectedTariff.applyByQRCode"
                               (popupValidated)="dateSelected($event)"
                               (cancel)="closePopup()"
    ></tariff-modification-popup>
</izivia-popup>

