<section class="style-guide-block">
  <div class="row">
    <h1 class="small-24 columns style-guide-title">Blocks</h1>

    <div class="small-24 columns style-guide-section-content">
      <div class="row">
        <div class="small-24 column">
          <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Vous retrouverez les styles de bases dans le fichier <strong>styles/layout/main-layout.scss</strong>
            </p>
          </div>

          <h4 class="style-guide-sub-title">Focus Block (shadow)</h4>
          <!-- START / Focus Block -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <div class="focus-block">
                  Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum.
                </div>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                <code iziviaPrism class="language-html">
                  <div class="focus-block"></div>
                </code>
              </pre>
            </div>
          </div>
          <!-- END / Focus Block -->

          <h4 class="style-guide-sub-title">Block Number</h4>
          <!-- START / Block Number -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <div class="grid-x grid-margin-y">
                  <div class="cell small-24 number-block small price color">
                    0.00 €
                  </div>

                  <div class="cell small-24 number-block small time color">
                    00:00:00
                  </div>

                  <div class="cell small-24 number-block price">
                    0.00 €
                  </div>

                  <div class="cell small-24 number-block time">
                    00:00:00
                  </div>
                </div>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                <code iziviaPrism class="language-html">
                   <div class="cell shrink number-block [size] [color] [type]">
                         0.00 €
                   </div>
                </code>
              </pre>
            </div>
          </div>
          <!-- END / Block Number  -->

          <!-- START / Size block number -->
          <div class="style-guide-element options">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                [size]
              </div>
            </div>
            <div class="style-guide-code">
              <ul class="style-guide-list-options">
                <li>size</li>
              </ul>
            </div>
          </div>
          <!-- END / Size block number  -->

          <!-- START / Color block number -->
          <div class="style-guide-element options">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                [color]
              </div>
            </div>
            <div class="style-guide-code">
              <ul class="style-guide-list-options">
                <li>color</li>
              </ul>
            </div>
          </div>
          <!-- END / Color block number  -->

          <!-- START / Color block number -->
          <div class="style-guide-element options">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                [type]
              </div>
            </div>
            <div class="style-guide-code">
              <ul class="style-guide-list-options">
                <li>time</li>
                <li>price</li>
              </ul>
            </div>
          </div>
          <!-- END / Color block number  -->

          <h4 class="style-guide-sub-title space-top-16">Action Bar</h4>
          <!-- START / Action Bar -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <div class="grid-x">
                  <div class="cell auto grid-x align-bottom align-justify">
                    <div class="grid-x cell auto align-right action-bar">
                      <a class="cell shrink button secondary">Extra</a>

                      <div class="cell auto grid-x align-right">
                        <div class="cell shrink">
                          <a class="button secondary force-space-right-16">Annuler</a>
                        </div>

                        <div class="cell shrink">
                          <a class="button add">Ajouter</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                <code iziviaPrism class="language-html">
                   <div class="grid-x">
                      <div class="cell auto grid-x align-bottom align-justify">
                        <div class="grid-x cell auto align-right action-bar">
                          <a class="cell shrink button secondary">Extra</a>

                          <div class="cell auto grid-x align-right">
                            <div class="cell shrink">
                              <a class="button secondary force-space-right-16">Annuler</a>
                            </div>

                            <div class="cell shrink">
                              <a class="button add">Ajouter</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </code>
              </pre>
            </div>
          </div>
          <!-- END / Action Bar  -->
        </div>
      </div>
    </div>
  </div>
</section>
