<div class="home-component">
    <h2 class="grid-x home-card-title">
        {{'home.subscriptions.title' | translate}}
    </h2>
    <div class="home-card-content">
        <div class="grid-y home-content-center">
            <div class="wrapper"
                 *ngFor="let packSubscriptionWithOptionBar of packSubscriptionsWithOptionBar; trackBy: trackBySubscriptionId">
                <div class="grid-x home-card-content offer inner-space-all-16">
                    <h5 class="cell auto  offer-title"
                        [innerHTML]="packSubscriptionWithOptionBar?.packSubscription?.subscriptionPackName"></h5>
                    <div class="grid-y small-10">
                <span class="cell"><i class="far fa-calendar-alt offer-icon"></i>
                    {{packSubscriptionWithOptionBar?.packSubscription?.subscriptionStartDate | date: 'shortDate'}}
                </span>
                        <span class="cell"
                              [hidden]="!packSubscriptionWithOptionBar?.packSubscription?.subscriptionPack.engagementDuration">
                                <i class="far fa-handshake offer-icon"></i>
                            {{'home.subscriptions.engagement' | translate:
                            {month: packSubscriptionWithOptionBar?.packSubscription?.subscriptionPack.engagementDuration} }}
                </span>
                    </div>
                </div>
                <subscription-progress-bar *ngIf="packSubscriptionWithOptionBar?.progressBarOptions"
                                           [consumePercent]="packSubscriptionWithOptionBar?.progressBarOptions?.consumePercent"
                                           [isBarAlert]="packSubscriptionWithOptionBar?.progressBarOptions?.isBarAlert"
                                           [resetDate]="resetDate">
                </subscription-progress-bar>
            </div>
            <div class="note text-center" [hidden]="packSubscriptionsNumber == null">
                {{'home.subscriptions.total' | translate: {numberPack: packSubscriptionsNumber} }}
            </div>
        </div>
    <div class="grid-x align-right align-middle moreDetail">
        <a>{{'home.details' | translate}}</a>
        <i class="moreDetail-arrow fas fa-arrow-right"></i>
    </div>
    </div>
</div>
