import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs';
import {FrontPackSubscriptionHttpService, FrontSessionBusinessService, PackSubscription} from 'lib-front';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class SubscriptionsResolver implements Resolve<PackSubscription> {

    constructor(private readonly packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private readonly sessionBusinessService: FrontSessionBusinessService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PackSubscription> {
        return this.sessionBusinessService.currentConnectedUser$
            .pipe(
                map(connectedUser => connectedUser?.user._id),
                switchMap(connectedUserId => this.packSubscriptionHttpService
                    .findPackSubscriptionById(connectedUserId, route.paramMap.get('packSubscriptionId'))),
            );
    }
}
