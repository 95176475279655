<div class="cell auto app-view-content">
    <tabs [direction]="TabsDirection.VERTICAL" [tabSelected]="tabActivate" (tabSelectedChange)="tabActivate = $event">
      <tab [hidden]="!hasManagerInfosReadRole"
           [changeUrl]="'/main/configuration/account'"
           [title]="'config.account.title' | translate"
           tab-section>
        <account-configuration *ngIf="hasManagerInfosReadRole"></account-configuration>
      </tab>
      <tab [hidden]="!this.fleetConfig || !this.fleetConfig.showVehicleSection || !hasFleetReadRole"
           [changeUrl]="'/main/configuration/fleet'"
           [title]="'config.fleet.title' | translate"
           tab-section>
        <fleet-configuration *ngIf="fleetConfig && fleetConfig.showVehicleSection && hasFleetReadRole"></fleet-configuration>
      </tab>
      <tab [hidden]="!hasCollaboratorsReadRole"
           [changeUrl]="'/main/configuration/collaborators'"
           [title]="'config.collaborators.title' | translate"
           tab-section>
          <collaborator-configuration *ngIf="hasCollaboratorsReadRole"
                                      [isRefundManager]="isRefundManager"
                                      (navigateToPermissionTabByName)="changeTabToPermissionWithFilter($event)"></collaborator-configuration>
      </tab>
      <tab [hidden]="!hasSubscriberRolesReadRole"
           [changeUrl]="'/main/configuration/permission'"
           [title]="'config.permission.title' | translate"
           tab-section>
          <permission-configuration *ngIf="hasSubscriberRolesReadRole"
                                    [filter]="permissionFilter">
          </permission-configuration>
      </tab>
      <tab [hidden]="!hasNotificationsReadRole"
           [changeUrl]="'/main/configuration/notifications'"
           [title]="'config.notifications.title' | translate"
           tab-section>
        <notification-configuration *ngIf="hasNotificationsReadRole"></notification-configuration>
      </tab>
      <tab [hidden]="!this.isStationManager || !this.isInfraManagementTariffCreationEnabled || !hasTariffsReadRole"
           [changeUrl]="'/main/configuration/tariff'"
           [title]="'config.tariff.title' | translate"
           tab-section>
        <tariff-configuration *ngIf="isStationManager && this.isInfraManagementTariffCreationEnabled && hasTariffsReadRole">
        </tariff-configuration>
      </tab>
      <tab [hidden]="!this.isRefundManager || !hasIndemnityReadRole"
           [changeUrl]="'/main/configuration/indemnity'"
           [title]="'config.indemnity.title' | translate"
           tab-section>
        <indemnity-configuration *ngIf="isRefundManager && !needToAcceptRGPDWorkflow && hasIndemnityReadRole">
        </indemnity-configuration>
        <expense-report-rgpd-acceptance (accepted)="computeNeedToAcceptRGPDWorkflow()"
                                        *ngIf="indemnityTabSelected && needToAcceptRGPDWorkflow && hasIndemnityReadRole">
        </expense-report-rgpd-acceptance>
      </tab>
      <tab [hidden]="!hasSupportsReadRole || supportContacts.length === 0"
           [changeUrl]="'/main/configuration/support'"
           [title]="'config.support.title' | translate"
           tab-section>
        <!--            add *ngIf in addition to hidden in order to prevent "no contact" blinking message on loading -->
        <support *ngIf="supportContacts.length > 0 && hasSupportsReadRole"
                 [fleetSupportConfig]="fleetSupportConfig"
                 [supportContacts]="supportContacts"></support>
      </tab>
      <tab [hidden]="!hasFaqReadRole || !faqVisible"
           [title]="'config.faq.title' | translate"
           changeUrl="/main/configuration/faq"
           tab-section>
        <faq *ngIf="hasFaqReadRole"></faq>
      </tab>
      <tab [hidden]="!hasHelpfulDocumentsReadRole || !helpfulDocumentsVisible"
           [title]="'config.helpfulDocuments.title' | translate"
           changeUrl="/main/configuration/helpfulDocument"
           tab-section>
          <helpfulDocuments *ngIf="hasHelpfulDocumentsReadRole"></helpfulDocuments>
      </tab>
      <tab [hidden]="!this.fleetConfig || !this.fleetConfig.showVehicleSection || !hasFleetReadRole || !isRigeUser"
           [changeUrl]="'/main/configuration/smart-charging'"
           [title]="'config.smartCharging.title' | translate"
           tab-section>
        <smart-charging *ngIf="hasFleetReadRole"></smart-charging>
      </tab>
      <a *ngIf="fleetConfig?.stationInstallationProposed ?? true"
         buttons-section
         class="grid-x align-middle align-center pass-management"
         href="mailto:contact.commercial@izivia.com">
        <img class="cell shrink" src="/assets/png/car.png">
        <h6 class="cell auto pass-management-title text-center">
          <b>{{'config.buttons.installStation' | translate}}</b>
        </h6>
      </a>
    </tabs>
</div>
