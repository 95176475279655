<section class="style-guide-block">
  <div class="row">
    <h1 class="small-24 columns style-guide-title">Status</h1>

    <div class="small-24 columns style-guide-section-content">
      <div class="row">
        <div class="small-24 column">
          <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Fonctionnement et rendu des status. Vous retrouverez les bases de style dans le fichier
              <strong>styles/components/status.scss</strong>
            </p>
          </div>

          <h4 class="style-guide-sub-title">Bases Colors</h4>
          <!-- START / Base Status -->
          <div class="style-guide-element">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <span class="status status-color-CHARGING">Charging</span>
                  </div>

                  <div class="cell auto">
                    <span class="status status-color-INVOICED">Invoiced</span>
                  </div>

                  <div class="cell auto">
                    <span class="status status-color-TO_INVOICE">To invoice</span>
                  </div>
                </div>
                <div class="grid-x grid-margin-x space-top-20">
                  <div class="cell auto">
                    <span class="status has-link status-color-CHARGING">
                      Invoiced
                      <a class="status-link" href="#"><i class="fa fa-link"></i></a>
                    </span>
                  </div>

                  <div class="cell auto">
                    <span class="status has-link status-color-INVOICED">
                      Invoiced
                      <a class="status-link" href="#"><i class="fa fa-link"></i></a>
                    </span>
                  </div>

                  <div class="cell auto">
                    <span class="status has-link status-color-TO_INVOICE">
                      Invoiced
                      <a class="status-link" href="#"><i class="fa fa-link"></i></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                <code iziviaPrism class="language-html">
                    <span class="status status-color-[status]">Label</span>
                </code>
              </pre>

              <pre class="language-html">
                <code iziviaPrism class="language-html">
                   <span class="status has-link status-color-TO_INVOICE">
                      Invoiced
                      <a class="status-link" href="#"><i class="fa fa-link"></i></a>
                    </span>
                </code>
              </pre>
            </div>
          </div>
          <!-- END / Base Status-->


          <!-- START / Options Name Status -->
          <div class="style-guide-element options">
            <div class="style-guide-preview-container">
              <div class="style-guide-preview-content">
                [status]
              </div>
            </div>
            <div class="style-guide-code">
              <ul class="style-guide-list-options">
                <li>Nom des status dans la feuille variables.scss</li>
              </ul>
            </div>
          </div>
          <!-- END / Options Name Status -->
        </div>
      </div>
    </div>
  </div>
</section>
