import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DisableReason, FrontMediaHttpService, MediaFleetItemDto, MediaItem} from 'lib-front';
import {Router} from '@angular/router';
import {AlertService} from '../../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'media-deactivation',
    templateUrl: './media-deactivation.component.html',
    styleUrls: ['./media-deactivation.component.scss']
})
export class MediaDeactivationComponent {
    public DisableReason = DisableReason;

    @Input() foAccountRef: string;
    @Input() mediaToDeactivate: MediaFleetItemDto;
    @Output() mediaDeactivated: EventEmitter<MediaDeactivation> = new EventEmitter<MediaDeactivation>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    deactivatingMedia: DisableReason;

    constructor(private mediaService: FrontMediaHttpService,
        private router: Router,
        private translateService: TranslateService,
        private alertService: AlertService,
        private readonly notificationService: NotificationService) {
    }

    public deactivateMedia(disableReason: DisableReason): void {
        if (!!this.mediaToDeactivate?.id) {
            this.deactivatingMedia = disableReason;

            this.mediaService.desactivateFleetMedia(this.foAccountRef, this.mediaToDeactivate.id,
                {disableReason: disableReason})
                .pipe(
                    finalize(() => this.deactivatingMedia = undefined)
                )
                .subscribe(
                    () => {
                        if (disableReason === DisableReason.LOST_OR_STOLEN) {
                            this.notificationService.success('media.table.button.notification.lostOrStolen.success');
                        } else {
                            this.notificationService.success('media.table.button.notification.deactivation.success');
                        }
                        this.mediaDeactivated.emit({mediaId: this.mediaToDeactivate.id, disableReason: disableReason});
                    },
                    () => {
                        if (disableReason === DisableReason.LOST_OR_STOLEN) {
                            this.notificationService.error('media.table.button.notification.deactivation.error');
                        } else {
                            this.notificationService.error('media.table.button.notification.lostOrStolen.error');
                        }
                    }
                );
        }
    }

    public onCancel(): void {
        this.cancel.emit();
    }
}

export interface MediaDeactivation {
    mediaId: string;
    disableReason: DisableReason;
}
