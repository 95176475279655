<div class="envelopContainer"
     *ngIf="viewEnvelope"
     [class.envelopeValidated]="needValidEnvelope && envelopeValidated"
     [class.envelopeInvalid]="needValidEnvelope && !envelopeValidated">
    <div *ngIf="isAddressEmpty(); else envelope">
        <h6>{{'address.emptyEnvelope' | translate}}</h6>
    </div>
    <ng-template #envelope>
        <h6 *ngIf="showSocialReason && socialReason"
            class="envelopContainer-name">
            {{ socialReason }}
        </h6>
        <h6 class="envelopContainer-name">{{ personAddress.firstName }} {{ personAddress.lastName }}</h6>
        <h6>{{ personAddress.address.route }}</h6>
        <h6>{{ personAddress.address.extra }}</h6>
        <h6>{{ personAddress.address.postalCode }} {{ personAddress.address.city }}</h6>
    </ng-template>
</div>
<form class="cell shrink envelopContainer-check flex" *ngIf="needValidEnvelope">
    <label class="checkbox align-center required">
        <input type="checkbox"
               [(ngModel)]="envelopeValidated"
               (ngModelChange)="validAddress()"
               #envelopeCheck="ngModel"
               [disabled]="!isAddressValid"
               name="envelopeValidated">
        <span class="custom-checkbox"></span>
        <span class="label-choice">{{'address.validEnvelope.label' | translate}}</span>
    </label>
</form>
