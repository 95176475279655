<div class="cell auto app-view-content">
    <div class="has-space">
        <div class="grid-x cell shrink align-justify" [hidden]="!hasBlockingInvoices">
            <div class="info alert-info cell auto common-block force-space-bottom-0">
                {{'subscription.invoiceUnpaid' | translate}}
            </div>
        </div>

        <div class="grid-x cell small-24 space-top-8 subscriptionsTitle"
             [hidden]="!packSubscriptionsSelected">
            <h3 class="cell shrink secondary block-title">
                {{'subscription.pack.title' | translate}}
            </h3>
            <div class="cell shrink">
                <button class="button secondary force-space-right-8" (click)="onPackSubscriptionRemoveClick()">
                    {{'pack.subscription.menu.button' | translate}}
                </button>
            </div>
        </div>

        <div class="grid-x cell small-24 space-top-8 subscriptionsTitle"
             [hidden]="packSubscriptionsSelected">
            <h3 class="cell shrink secondary block-title">
                {{'subscription.pack.title' | translate}}
            </h3>
            <div class="cell shrink">
                <button class="button force-space-right-8 add"
                        (click)="newSubscription()"
                        [disabled]="hasBlockingInvoices || isSuspended">
                    {{'subscription.pack.button.new.subscription' | translate}}
                </button>
            </div>
        </div>

        <div class="grid-x cell grid-margin-x align-justify main-container has-space"
             [hidden]="packSubscriptionsSelected">

            <div
                    class="cell auto common-block ">
                <button *ngIf="!showTokenInput"
                        (click)="checkRoleForTokenInput()"
                        class="button force-space-right-8 ">
                    {{'token.promoToken.label' | translate}}
                </button>
                <div *ngIf="showTokenInput"
                     class="light-grey-block">
                    <promo-token-input

                            class="cell"
                            [canCancelToken]="false"
                            [otherSubscriptionPackRefs]="subscriptionPackRefs"
                            [subscriptionMode]="SubscriptionMode.client"
                            [email]="currentUser.email"
                            (promoTokenChange)="promoTokenChanged($event)"></promo-token-input>
                </div>
            </div>

            <div class="cell grid-y">
                <div class="grid-x small-up-3 grid-margin-x">
                    <div class="cell space-bottom-24"
                         *ngFor="let packSubscriptionsWrapper of packSubscriptions; trackBy: trackByPackSubscriptionId">
                        <subscription-offer-view *ngIf="!packSubscriptionsWrapper?.packSubscription.promoSubscription"
                                                 (click)="onPackSubscriptionClick(packSubscriptionsWrapper)"
                                                 [mode]="ModeSubscriptionOfferView.SUBSCRIPTION"
                                                 [packSubscription]="packSubscriptionsWrapper?.packSubscription"
                                                 [showEndEngagement]="packSubscriptionsWrapper?.hasEndEngagement"
                                                 [options]="packSubscriptionsWrapper.options">
                        </subscription-offer-view>

                        <promo-subscription-offer-view *ngIf="packSubscriptionsWrapper?.packSubscription.promoSubscription"
                                                       [packSubscription]="packSubscriptionsWrapper?.packSubscription">
                        </promo-subscription-offer-view>
                    </div>
                </div>
                <div class="grid-x cell grid-margin-x" *ngIf="!waitingPackSubscriptions && !packSubscriptions?.length">
                    <h5 class="info alert-info cell auto common-block force-space-bottom-0">
                        {{'no.subscription.pack.title' | translate}}
                    </h5>
                </div>
                <div class="grid-x cell grid-margin-x" *ngIf="waitingPackSubscriptions">
                    <div class="app-loader">
                        <span class="spinner"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-x cell grid-margin-x align-justify main-container has-space shrink"
             [hidden]="!packSubscriptionsSelected">
            <div class="cell small-9 grid-y">
                <subscription-offer-view [mode]="ModeSubscriptionOfferView.SUBSCRIPTION"
                                         [packSubscription]="packSubscriptionsSelected?.packSubscription"
                                         [showEndEngagement]="packSubscriptionsSelected?.hasEndEngagement">
                </subscription-offer-view>
                <div class="grid-y cell shrink align-middle">
                    <div class="grid-x align-middle space-top-32">
                        <button *ngIf="this.packSubscriptionsSelected?.packSubscription?.subscriptionPack?.subscriptionCancellationAllowedFromAccount"
                                class="button secondary color cell small-24 force-space-bottom-16"
                                [disabled]="isRevocationDisabled()"
                                [iziviaLoading]="schedulingTermination || revokingSubscription"
                                (click)="askForSubscriptionRevocation(packSubscriptionsSelected?.packSubscription)">
                            {{'subscription.pack.button.revoke' | translate}}
                        </button>
                        <a class="button validate cell small-24 force-space-bottom-16"
                           [routerLink]="'/main/media/activate'">
                            {{'media.button.activate' | translate}}
                        </a>
                        <button class="button validate cell small-24 force-space-bottom-16"
                                *ngIf="packSubscriptionsSelected?.packSubscription?._id !== null && this.packSubscriptionsSelected?.packSubscription?.subscriptionPack?.subscriptionCancellationAllowedFromAccount"
                                [hidden]="!packSubscriptionsSelected?.canBeUpgrade"
                                [disabled]="isSuspended || packSubscriptionsSelected?.packSubscription?.terminationScheduled"
                                (click)="upgradePackSubscription(packSubscriptionsSelected?.packSubscription?._id)">
                            {{'subscription.pack.button.update' | translate}}</button>
                        <label *ngIf="!this.packSubscriptionsSelected?.packSubscription?.subscriptionPack?.subscriptionCancellationAllowedFromAccount"
                               class="cell auto">
                            {{'subscription.pack.termination.notAllowedFromAccount' | translate}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="cell small-15 grid-y focus-block space-bottom-16">
                <subscription-options-view class="cell shrink" [options]="packSubscriptionsSelected?.options"
                                           [smallBackground]="true"></subscription-options-view>
                <div class="cell auto grid-x align-bottom align-justify fix-action-bar">
                    <div class="grid-x cell auto align-right action-bar">
                        <div class="cell shrink" *ngIf="packSubscriptionsSelected?.packSubscription?._id !== null">
                            <a class="button secondary color force-space-right-8"
                               (click)="goToRevoke(packSubscriptionsSelected?.packSubscription?._id)"
                               [hidden]="!(packSubscriptionsSelected?.options && packSubscriptionsSelected?.options.length) ||
                                    isPackSubscriptionInProgress()">
                                {{'subscription.pack.option.button.revoke' | translate}}
                            </a>
                        </div>

                        <div class="cell shrink">
                            <a class="button add" *ngIf="packSubscriptionsSelected?.packSubscription?._id !== null"
                               (click)="addOption(packSubscriptionsSelected?.packSubscription?._id)"
                               [ngClass]="{disabled : isSuspended || hasBlockingInvoices}">
                                {{'subscription.pack.option.button.add' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-view-content"
         *ngIf="shouldShowAdditionalInformationForm()">
        <additional-information
                [additionalInformationList]="getAdditionalInformationList()"
                [additionalInformationAnswers]="getAdditionalInformationAnswers()"
                [submitOnChange]="true"
                [packSubscription]="packSubscriptionsSelected.packSubscription">
        </additional-information>
    </div>
</div>
