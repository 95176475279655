import {Pipe, PipeTransform} from '@angular/core';
import {
    isMetaChargeConsumption,
    isMetaOtpConsumption,
    isMetaReservationConsumption,
    isMetaServiceConsumption,
    MetaConsumptionDto
} from '../domain/meta/metaConsumptionDto';

@Pipe({
    name: 'fleetMetaConsumptionMediaPublicCode'
})
export class FleetMetaConsumptionMediaPublicCodePipe implements PipeTransform {

    constructor() {}

    transform(metaConsumption: MetaConsumptionDto): string | null {
        if (isMetaChargeConsumption(metaConsumption)
            || isMetaReservationConsumption(metaConsumption)
            || isMetaOtpConsumption(metaConsumption)
            || isMetaServiceConsumption(metaConsumption)) {
            return metaConsumption.mediaPublicCode;
        } else {
            return null;
        }
    }
}
