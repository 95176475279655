import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AdditionalInformationAnswers,
    AdditionalInformationService,
    FOAccountWrapper,
    FrontFoAccountHttpService,
    FrontMediaHttpService,
    FrontOfferHttpService,
    FrontPackSubscriptionHttpService, FrontSessionBusinessService,
    FrontSubscriptionPackHttpService, HOST_THIRD_PARTY_ID,
    MediaCanOrderDto,
    MediaItem,
    MediaOrderContext,
    Offer,
    OfferCriteria,
    PackSubscriptionRequest, SubscribeAnswerDto,
    SubscriptionDef,
    SubscriptionOptionRequestDto,
    SubscriptionRequestDto
} from 'lib-front';
import {OptionForm} from '../../../../../../components/subscription-options/subscription-options.component';
import {AlertService} from '../../../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../../../services/utils/notification.service';
import {OptionsResult} from '../update-subscription.component';
import {SubscriptionPackOrTokenWrapper} from '../../../../../../domain/subscriptionPackOrTokenWrapper';
import {MediaOrderDto} from '../../../../../../domain/mediaOrderDto';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {clone, cloneDeep, groupBy, isEmpty} from 'lodash-es';

@Component({
    selector: 'new-subscription',
    templateUrl: './new-subscription.component.html',
    styleUrls: ['./new-subscription.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class NewSubscriptionComponent implements OnInit {
    @ViewChild('scrollTop') private scrollTop;
    currentStep: number;

    @ViewChild('additionalInformationComponent') additionalInformationComponent;

    userRef: string;
    offer: Offer;
    activateNextStep: boolean;
    foAccount: FOAccountWrapper;
    subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper = new SubscriptionPackOrTokenWrapper();
    optionsToSubscribe: Array<OptionForm>;
    optionsResult: OptionsResult;
    saveOfFoAccount: FOAccountWrapper;
    availableOptions: Array<SubscriptionDef>;

    additionalInformationAnswers: AdditionalInformationAnswers = {};

    mediaRefsEligibleForActivation: Array<string> = [];

    mediaOrderForm: MediaOrderDto;
    mediaCanOrderDtoList: MediaCanOrderDto[];
    readonly mediaOrderContext: MediaOrderContext = MediaOrderContext.SUBSCRIPTION;
    promoToken: string;
    loading: boolean;

    private skipStepThree: boolean;

    constructor(private packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private mediaHttpService: FrontMediaHttpService,
        private route: ActivatedRoute,
        private subscriptionPackHttpService: FrontSubscriptionPackHttpService,
        private foAccountHttpService: FrontFoAccountHttpService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private additionalInformationService: AdditionalInformationService,
        private router: Router,
        private offerHttpService: FrontOfferHttpService,
        private sessionService: FrontSessionBusinessService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {
    }

    ngOnInit() {
        this.currentStep = 1;
        this.activateNextStep = false;
        this.skipStepThree = false;

        this.route.data.subscribe(data => this.userRef = data.user.user._id);

        // Fetch offer
        this.offerHttpService.find(this.thirdPartyId, new OfferCriteria()).subscribe(offer => {
            this.offer = offer;
            const subscriptionPackOrTokenWrapper = new SubscriptionPackOrTokenWrapper();
            if (this.offer) {
                subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe = this.offer.subscriptionPacks[0];
            }
            this.subscriptionPackOrTokenWrapper = subscriptionPackOrTokenWrapper;
        });

        // Fetch FoAccount
        this.foAccountHttpService.findFOAccount()
            .subscribe(foAccount => {
                // Do a cloneDeep to discard board effect
                this.saveOfFoAccount = cloneDeep(foAccount);
                this.foAccount = foAccount;
            });
    }

    updateOffersForm(isChildrenFormValid: boolean) {
        if (this.currentStep === 1) {
            this.activateNextStep = isChildrenFormValid;
        }
    }

    updateMediasForm(isChildrenFormValid: boolean) {
        if (this.currentStep === 3) {
            this.activateNextStep = isChildrenFormValid;
        }
    }

    /** If the customer checks the functionality to keep the billing address after changing it,
     *  the original version must be returned to the object. */
    updateBillingAddress(keepBilling: boolean) {
        if (keepBilling) {
            this.foAccount.companyName = clone(this.saveOfFoAccount.companyName);
            this.foAccount.companySiret = clone(this.saveOfFoAccount.companySiret);
            this.foAccount.billing = cloneDeep(this.saveOfFoAccount.billing);
        }
    }

    updateDeliveryAddress(keepDelivery: boolean) {
        if (keepDelivery) {
            this.foAccount.delivery = cloneDeep(this.saveOfFoAccount?.delivery);
        }
    }

    public updateNewSubscriptionPackOrTokenWrapper(newSubscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper) {
        this.subscriptionPackOrTokenWrapper = newSubscriptionPackOrTokenWrapper;
        if(this.subscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder) {
            this.additionalInformationAnswers = {};
            this.mediaHttpService.fetchOrderableBySubscriptionPackId(this.subscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder._id,
                this.mediaOrderContext)
                .subscribe(mediaCanOrderDtoList => {
                    if (!!mediaCanOrderDtoList && mediaCanOrderDtoList.length) {
                        this.mediaOrderForm = new MediaOrderDto();
                        this.mediaOrderForm.mediaCanOrderDto = mediaCanOrderDtoList[0];
                        this.mediaCanOrderDtoList = mediaCanOrderDtoList;
                    } else {
                        this.mediaOrderForm = null;
                        this.mediaCanOrderDtoList = null;
                    }
                });
        }
        if (this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe) {
            this.offerHttpService.findOptionsBySubscriptionPack(this.thirdPartyId, this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id)
                .subscribe(options => this.availableOptions = options);
        }
    }

    updateNewOffer(newOffer: Offer) {
        this.offer = newOffer;
    }

    private calcPassCarryOver() {
        const subscriptionPackForMediaOrder = this.subscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder;
        if (subscriptionPackForMediaOrder.acceptedMediaFamilies.length) {
            this.mediaHttpService.findFleetMedias(this.userRef, this.foAccount.foAccountRef, false)
                .subscribe(medias => {
                    const mediaByFamilyRef: { string?: MediaItem[] } = groupBy(medias, 'family._id');

                    for (const acceptedMediaFamily of subscriptionPackForMediaOrder.acceptedMediaFamilies) {
                        const mediasAccepted: Array<MediaItem> = mediaByFamilyRef[acceptedMediaFamily._id];
                        if (mediasAccepted && mediasAccepted.length) {
                            this.mediaRefsEligibleForActivation.push(...(mediasAccepted.map(mediaAccepted => mediaAccepted.id)));
                        }
                    }
                });
        }
    }

    updateOptionsToSubscribe(optionForms: Array<OptionForm>) {
        this.optionsToSubscribe = optionForms;
    }

    updateOptionsResult(optionsResult: OptionsResult) {
        this.optionsResult = optionsResult;
    }

    updateMediasOrder(mediaOrderFormValue: MediaOrderDto) {
        this.mediaOrderForm = mediaOrderFormValue;
    }

    // Options components take a ref over an array of options
    // When a token is used, there is no offer
    // FIXME option component need to take an array of option over subscriptionPack or offer
    public get offerRefForOptions() {
        return this.subscriptionPackOrTokenWrapper.isSubscriptionPackMode() && this.offer ? this.offer._id : null;
    }

    /**
     * The navigating parent’s buttons must be reset unless the next child doesn't have a form to validate.
     */
    goToNext() {
        if (this.currentStep >= 1 && this.currentStep < 4) {
            if (this.currentStep === 1 && !this.areOptionAvailableOnSubscription()) {
                this.currentStep += 2;
            } else {
                this.currentStep++;
                if (this.currentStep === 2) {
                    this.calcPassCarryOver();
                } else if (this.currentStep === 3) {
                    this.activateNextStep = this.checkDeliveryAddress();
                }
            }
            this.scrollTop.nativeElement.scrollIntoView();
        }
    }

    /**
     * No matter what happens when you go back, the previously completed forms are considered valid without change.
     */
    goToBack() {
        this.activateNextStep = true;
        if (this.currentStep > 1 && this.currentStep <= 4) {
            if (this.currentStep === 3 && !this.areOptionAvailableOnSubscription()) {
                this.currentStep = 1;
            } else {
                this.currentStep -= 1;
            }
            this.scrollTop.nativeElement.scrollIntoView();
        }
    }

    areOptionAvailableOnSubscription() {
        return this.availableOptions && this.availableOptions.length > 0;
    }

    checkDeliveryAddress() {
        return this.foAccount && !isEmpty(this.foAccount.companyName) && !isEmpty(this.foAccount.companySiret)
            && this.foAccount.delivery && !isEmpty(this.foAccount.delivery.firstName)
            && !isEmpty(this.foAccount.delivery.lastName) && !isEmpty(this.foAccount.delivery.phoneNumber)
            && !isEmpty(this.foAccount.delivery.email) && this.foAccount.delivery.address
            && !isEmpty(this.foAccount.delivery.address.route) && !isEmpty(this.foAccount.delivery.address.postalCode)
            && !isEmpty(this.foAccount.delivery.address.city);
    }

    subscribeToPack() {
        this.alertService.confirm(this.translateService.instant('new.subscription.confirm.alert'))
            .pipe(
                filter(confirm => confirm),
                tap(() => this.loading = true),
                switchMap(() => this.foAccountHttpService.foAccountSave(this.foAccount)),
                switchMap(() =>
                    this.additionalInformationService.handleAdditionalInformationFiles(
                        this.getAdditionalInformationList(),
                        this.additionalInformationAnswers)
                )
            ).subscribe(
                filteredAdditionalInformation => {
                // mediaOrder
                    let mediaOrder = null;
                    if (this.mediaOrderForm) {
                        if (this.mediaOrderForm.quantity > 0) {
                            mediaOrder = {
                                mediaFamilyRef: this.mediaOrderForm.mediaCanOrderDto.mediaFamily.id,
                                quantity: this.mediaOrderForm.quantity
                            };
                        }
                    }

                    const packSubscriptionRequest: PackSubscriptionRequest = {
                        mediaRefs: this.mediaOrderForm?.linkMedia ? this.mediaRefsEligibleForActivation : [],
                        additionalInformations: filteredAdditionalInformation
                    };

                    const options: Array<SubscriptionOptionRequestDto> = !this.optionsToSubscribe ? [] :
                        this.optionsToSubscribe.map(option => {
                            const subscriptionOptionRequestDto = new SubscriptionOptionRequestDto();
                            subscriptionOptionRequestDto.numberOfSubscription = option.number;
                            subscriptionOptionRequestDto.subscriptionRef = option.subscription._id;
                            return subscriptionOptionRequestDto;
                        });

                    const subscriptionRequestDto: SubscriptionRequestDto = {
                        mediaOrder,
                        packSubscriptionRequest,
                        personAddress: this.foAccount.delivery,
                        options
                    };

                    if (this.subscriptionPackOrTokenWrapper.isPackTokenMode()) {
                        subscriptionRequestDto.packToken = this.subscriptionPackOrTokenWrapper.tokenValue;
                    } else {
                        subscriptionRequestDto.subscriptionPackRef = this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id;
                    }

                    if (this.promoToken) {
                        subscriptionRequestDto.promoToken = this.promoToken;
                    }

                    this.packSubscriptionHttpService.subscribe(subscriptionRequestDto)
                        .pipe(
                            finalize(() => this.loading = false)
                        )
                        .subscribe(
                            subscribeAnswerDto => this.handleAnswer(subscribeAnswerDto),
                            error => this.handleError()
                        );
                },
                error => this.loading = false
            );
    }

    private handleError() {
        this.notificationService.error('new.subscribe.error');
        this.router.navigate(['/main/subscriptions']);
    }

    private handleAnswer(subscribeAnswerDto: SubscribeAnswerDto) {
        this.notificationService.success('new.subscribe.success');
        if (subscribeAnswerDto
            && subscribeAnswerDto.invoiceRef
            && !subscribeAnswerDto.paymentIsGuaranteed
            && subscribeAnswerDto.amountIsPositive
        ) {
            this.router.navigate(['/main/invoices/' + subscribeAnswerDto.invoiceRef]);
        } else {
            this.router.navigate(['/main/subscriptions']).then(() =>
                this.sessionService.reloadUser()
            );
        }
    }

    getAdditionalInformationList() {
        return this.subscriptionPackOrTokenWrapper &&
            this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe &&
            this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations;
    }

    shouldShowAdditionalInformationForm() {
        return this.currentStep === 1
            && !!this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe
            && !!this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations
            && this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations.length > 0;
    }

    onPromoToken(promoToken: string) {
        this.promoToken = promoToken;
    }
}
