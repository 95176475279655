<div class="tariffChargeSimulator" *ngFor="let tariffSimulationResponse of tariffSimulationResponses">
    <p>{{'config.tariff.simulation.charge' | translate}} <span>{{energyConsumptionInKWh*1000 | energy}}</span>
        {{'config.tariff.simulation.between' | translate}}
        <span>{{startTime|amDateFormat:'HH:mm'}}</span> {{'config.tariff.simulation.and' | translate}}
        <span>{{endTime|amDateFormat:'HH:mm'}}</span>
        {{'config.tariff.simulation.subscriptionName' | translate}}
        <span *ngIf="tariffSimulationResponse.subscriptionName">{{tariffSimulationResponse.subscriptionName}}</span> :
    </p>

    <ul [hidden]="loading">
        <li><span>{{'config.tariff.simulation.user.cost' | translate}} <span *ngIf="fleetConfig.emoMention">{{'config.tariff.simulation.to.izivia' | translate}}</span> {{tariffSimulationResponse.userTotalPrice | currency:'EUR'}}
            {{'config.tariff.simulation.without.taxes' | translate}}
            <span *ngIf="fleetConfig.emoMention">
                ({{'config.tariff.simulation.including.commission' | translate}} {{tariffSimulationResponse.iziviaCommission | currency:'EUR'}})
            </span>
        </span></li>
        <li *ngIf="fleetConfig.emoMention"><span>{{'config.tariff.simulation.received' | translate}} {{tariffSimulationResponse.totalPrice | currency:'EUR'}}
            {{'config.tariff.simulation.without.taxes' | translate}} :
            {{tariffSimulationResponse.fixedCost | currency:'EUR'}} ({{'config.tariff.simulation.fixed.cost' | translate}}) +
            {{tariffSimulationResponse.energyCost | currency:'EUR'}} ({{'config.tariff.simulation.energy.cost' | translate}}) +
            {{tariffSimulationResponse.parkingCost | currency:'EUR'}} ({{'config.tariff.simulation.parking.cost' | translate}})</span></li>
    </ul>
    <ul [hidden]="!loading">
        <span>{{'config.tariff.simulation.loading'|translate}}</span>
    </ul>
</div>
