<div *ngIf="address.locationInputMode === LocationInputMode.MANUAL">
    <manual-address-form [address]="address"
                         [required]="true"
                         (formAddressValidChange)="onAddressValidityChange($event)">

    </manual-address-form>
    <span><a class="addressSearch" (click)="changeMode(LocationInputMode.AUTOMATIC)">{{'common.address.searchAddress' | translate}}</a></span>
</div>
<div *ngIf="address.locationInputMode === LocationInputMode.AUTOMATIC">
    <automatic-address-suggestion-form [address]="address"
                            [required]="true"
                            (formAddressValidChange)="onAddressValidityChange($event)">
    </automatic-address-suggestion-form>
    <span><a class="addressSearch" (click)="changeMode(LocationInputMode.MANUAL)">{{'common.address.addressNotFound' | translate}}</a></span>
</div>
