<form #form="ngForm" (submit)="onSubmit()">
    <subscription-pack-choice name="subscriptionsToken"
                        [showPrivateOffers]="packTokensAvailable"
                        [mode]="SubscriptionMode.register"
                        [subscriptionPacksChoice]="offer?.subscriptionPacks"
                        [(ngModel)]="subscriptionPackOrTokenWrapper"
                        (ngModelChange)="flushAdditionalInformationAnswers()"
                        (acceptTerms)="acceptTerms($event)">
    </subscription-pack-choice>
    <subscription-options [hidden]="!subscriptionPackOrTokenWrapper"
                          class="grid-y cell auto"
                          name="options"
                          [offerId]="offerRefForOptions"
                          [subscriptionPackId]="subscriptionPackOptionsSelectedRef"
                          [mode]="ModeSubscriptionOption.REGISTER"
                          [(ngModel)]="options">
    </subscription-options>

    <additional-information
        *ngIf="shouldShowAdditionalInformationForm()"
        [additionalInformationList]="getAdditionalInformationList()"
        [additionalInformationAnswers]="additionalInformationAnswers"
        #additionalInformationComponent>
    </additional-information>

    <div class="register-block">
        <div *ngIf="displayOptInAgreement"
             class="register-checkbox">
            <div class="cell">
                <label class="checkbox">
                    <input type="checkbox" name="informSodetrel" [(ngModel)]="optIn">
                    <span class="custom-checkbox"></span>
                    <span class="label-choice">{{'register.subscription.options.infos' | translate}}</span>
                </label>
            </div>
        </div>

        <div class="grid-x align-center align-justify space-top-16">
            <button class="cell shrink button color previous" type="button" (click)="stepBack()">{{'register.back' |
                translate}}
            </button>
            <div class="cell auto">&nbsp;</div>
            <button class=" cell shrink button color next" type="submit"
                    [disabled]="form.invalid
                    || !isCguAccepted
                    || (shouldShowAdditionalInformationForm() && !additionalInformationComponent?.valid)">{{'register.next' | translate}}
            </button>
        </div>
    </div>
</form>
