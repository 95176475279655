import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {
    FrontFoAccountHttpService,
    RGPDWorkflowHttpService,
    RGPDWorkflowSubscriberAcceptanceDto,
    RGPDWorkflowSubscriberAcceptanceStatus
} from 'lib-front';

import {defaultIfEmpty, filter, map, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExpenseReportGuard implements CanActivate, CanActivateChild {

    constructor(private readonly frontFoAccountService: FrontFoAccountHttpService,
        private readonly rgpdWorkflowService: RGPDWorkflowHttpService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.checkRgpdAcceptance();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkRgpdAcceptance();
    }

    private checkRgpdAcceptance(): Observable<boolean> {
        return this.frontFoAccountService.findFOAccount().pipe(
            filter(foAccount => !!foAccount.splitbillingRGPDWorkflowRef),
            switchMap(foAccount => this.rgpdWorkflowService.getSubscriberAcceptanceByWorkflowId(foAccount.splitbillingRGPDWorkflowRef)),
            map(acceptanceStatus => this.getResponseFromAcceptanceStatus(acceptanceStatus)),
            defaultIfEmpty(true)
        );
    }

    private getResponseFromAcceptanceStatus(subscriberAcceptance: RGPDWorkflowSubscriberAcceptanceDto): boolean {
        if (!subscriberAcceptance) {
            return true;
        }

        switch (subscriberAcceptance.subscriberAcceptanceStatus) {
            case RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTED:
                return true;
            case RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTANCE_REQUIRED:
            case RGPDWorkflowSubscriberAcceptanceStatus.NOT_READY_FOR_ACCEPTANCE:
                const navigationExtras = {queryParams: {workflowRef: subscriberAcceptance.workflowRef}};

                this.router.navigate(['/main/expenseReport/rgpdAcceptance'], navigationExtras)
                    .then();
                return false;
        }

        return false;
    }

}
