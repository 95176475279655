import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {StationDto} from 'lib-front';

@Component({
    selector: 'tariff-modification-popup',
    templateUrl: './tariff-modification-popup.component.html',
    styleUrls: ['./tariff-modification-popup.component.scss']
})
export class TariffModificationPopupComponent {

    constructor(private readonly translateService: TranslateService) {
    }

    @Input() tariffName: string = '';
    @Input() deletion: boolean = false;

    @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() popupValidated: EventEmitter<Moment> = new EventEmitter<Moment>();

    @Input() applicationDate: Moment;
    @Input() minApplicationDate: Moment;

    @Input() nbSubscribers: number = 0;
    @Input() linkedStations: StationDto[] = [];

    @Input() isPrimoTariff: boolean = false;

    emitCancel() {
        this.cancel.emit(true);
    }

    getStationListString(): string {
        if (!this.linkedStations) {
            return '';
        }
        let stationString = this.translateService.instant('config.tariff.modification.popup.warning.station');
        return this.linkedStations
            .map(station => stationString + ' ' + station.visualId +
                (station.alias ? ' ( ' + this.translateService.instant('stations.alias', {alias: station.alias}) + ' )' : '')
            )
            .join(', ');
    }


    submit() {
        this.popupValidated.emit(this.applicationDate);
    }
}
