<ng-container *ngIf="!isAccountCreationProcessSecured">
    <div class="register-block">
        <div class="grid-x align-middle align-justify space-bottom-16">
            <h3 class="grid-x align-middle cell shrink secondary block-title">
                {{'payment.summary.title' | translate}}
            </h3>
            <h5 class="cell shrink"> {{'payment.summary.info.title' | translate}}</h5>
        </div>
        <div class="grid-x align-spaced align-middle light-grey-block">
            <div class="grid-y align-justify">
                <h5 class="black number-block-title"
                    *ngFor="let subscriptionPack of subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks;
                        trackBy: trackBySubscriptionPackId"
                    [innerHTML]="'payment.businessSubscription'| translate:{
                name : subscriptionPack?.name}"></h5>
                <div class="number-block price" *ngIf="showSubscriptionPacksPrice()">
                    {{subscriptionPacksPrice() | currency:'EUR': subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0]?.currencyUnit || ''}}
                </div>
                <div class="overloaded-number-block price" *ngIf="!showSubscriptionPacksPrice()
                && subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0].overloadedFixPriceLabel !== ''">
                    <span [innerHTML]="subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0].overloadedFixPriceLabel"></span>
                </div>
                <span class="note black text-center"
                      [hidden]="!subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0]?.engagementDuration"
                      [innerHTML]="'payment.businessSubscriptionWithEngagement'| translate:{
            month : subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0]?.engagementDuration}"></span>
            </div>
            <div class="grid-y align-justify">
                <i class="fas fa-plus-circle icon-operation"></i>
            </div>
            <div class="grid-y align-justify">
                <h5 class="black number-block-title">{{'payment.totalOptions' |translate}}</h5>
                <div class="number-block price">{{totalOptions | currency:'EUR':subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0]?.currencyUnit || ''}}</div>
            </div>
            <div class="grid-y align-justify" *ngIf="showSubscriptionPacksPrice()">
                <i class="fas fa-chevron-circle-right icon-operation"></i>
            </div>
            <div class="grid-y align-justify" *ngIf="showSubscriptionPacksPrice()">
                <h5 class="black number-block-title">{{'payment.totalTTC' |translate}}</h5>
                <div class="number-block color price">{{subscriptionPacksPrice() + totalOptions | currency:'EUR':subscription?.subscriptionPackOrTokenWrapper.subscriptionPacks[0]?.currencyUnit || ''}}
                </div>
            </div>
            <div class="grid-x align-spaced align-middle" *ngIf="promoToken?.valid">
                <ng-container *ngFor="let subscriptionPack of promoToken.subscriptionPacks">
                    <div class="grid-y align-justify">
                        <h5 class="black number-block-title">{{subscriptionPack.name}}</h5>
                        <div class="number-block price">{{subscriptionPack.overloadedFixPriceLabel | translate}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="register-block" [hidden]="(mediaOrder?.emoMediaOrderPrice?.bestPrice || 0) === 0">
        <div class="grid-x align-middle align-justify space-bottom-16">
            <h3 class="grid-x align-middle cell shrink secondary block-title">
                {{'payment.order.title' | translate}}
            </h3>
            <h5 class="cell shrink"> {{'payment.order.info.title' | translate}}</h5>
        </div>
        <div class="grid-x align-spaced align-middle light-grey-block">
            <div class="grid-y align-justify">
                <h5 class="black number-block-title">
                    {{mediaOrder?.quantity || 0}}&nbsp;{{'payment.order.table.pass' | translate}}
                </h5>
                <div class="number-block price">
                    {{mediaOrder?.emoMediaOrderPrice?.price | currency:'EUR': mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                </div>
            </div>
            <div class="grid-y align-justify"
                 *ngIf="mediaOrder?.emoMediaOrderPrice?.bestPrice < mediaOrder?.emoMediaOrderPrice?.price">
                <i class="fas fa-minus-circle icon-operation"></i>
            </div>
            <div class="grid-y align-justify"
                 [hidden]="mediaOrder?.emoMediaOrderPrice?.bestPrice >= mediaOrder?.emoMediaOrderPrice?.price">
                <h5 class="black number-block-title">{{'payment.order.table.discount' | translate}}</h5>
                <div class="number-block price">
                    {{mediaOrder?.emoMediaOrderPrice?.discount || 0 | currency:'EUR':mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                </div>
            </div>
            <ng-container *ngFor="let bonusPenalty of bonusPenaltyWithVats">
                <div class="grid-y align-justify">
                    <i class="fas fa-minus-circle icon-operation"></i>
                </div>
                <div class="grid-y align-justify">
                    <h5 class="black number-block-title">{{bonusPenalty.reason}}</h5>
                    <div class="number-block price">
                        {{bonusPenalty.priceWithVat | currency:'EUR':mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}
                    </div>
                </div>
            </ng-container>
            <div class="grid-y align-justify">
                <i class="fas fa-chevron-circle-right icon-operation"></i>
            </div>
            <div class="grid-y align-justify">
                <h5 class="black number-block-title">{{'payment.totalTTC' | translate}}</h5>
                <div class="number-block color price">{{totalMediaPrice | currency:'EUR':mediaOrder?.emoMediaOrderPrice?.currencyUnit || ''}}</div>
            </div>
        </div>
    </div>

    <div class="grid-x register-block">
        <promo-token-input class="cell"
                           [otherSubscriptionPackRefs]="subscription?.subscriptionPackOrTokenWrapper.subscriptionPackRefs"
                           [subscriptionMode]="SubscriptionMode.register"
                           [email]="account?.user?.email"
                           (promoTokenChange)="promoTokenChanged($event)"></promo-token-input>
    </div>

    <div class="grid-y register-block"
         [hidden]="totalMediaPrice <= 0">
        <h3 class="space-top-16">{{'payment.choice.title' | translate}}</h3>
        <payment-type [paymentTypesAvailables]="paymentTypesAvailables"
                      (paymentTypeEmitter)="selectPayment($event)"
                      (paymentConfigNameEmitter)="setPaymentConfigName($event)"></payment-type>
    </div>

    <div class="cell grid-x register-block">
        <button class="cell shrink button color previous" type="button" (click)="stepBack()">{{'register.back' | translate}}
        </button>
        <div class="cell auto"></div>
        <button class="cell shrink button color validate" type="button" (click)="onPayment()"
                [disabled]="!paymentSelected || !configNameIsValid() || disabled"
                *ngIf="(totalMediaPrice || 0) > 0">{{'register.pay' | translate}}
        </button>
        <button class="cell shrink button color validate" type="button" (click)="onInscription()"
                [disabled]="disabled"
                *ngIf="(totalMediaPrice || 0) <= 0">{{'register.register' | translate}}
        </button>
    </div>

</ng-container>

<ng-container *ngIf="isAccountCreationProcessSecured && paymentConfsAvailable">
    <div class="register-block">
        <div class="grid-x space-left-24 grid-x space-top-24 space-bottom-16">
            <h5 class="cell small-24 space-bottom-16">{{'invoice.paymentConf.config.titleRegister' | translate}}</h5>
            <div class="cell small-24 force-font-bold space-bottom-8">{{'invoice.paymentConf.config.subtitle' | translate}}</div>
            <div class="cell small-24 space-bottom-8">
                <i class="fa fa-info-circle"></i>
                <span class="space-left-8">{{'invoice.paymentConf.config.info' | translate}}</span>
            </div>
            <div class="cell small-24 grid-x small-up-4">
                <div class="grid-y cell text-center payment-available-block space-bottom-0"
                     *ngFor="let paymentConfAvailable of paymentConfsAvailable">
                    <div class="cell shrink operator-name">{{'invoice.paymentConf.operator' | translate}} :
                        {{paymentConfAvailable.emoName}}
                    </div>
                    <div class="cell shrink payment-by">{{'payment.' + paymentConfAvailable.paymentType | translate}}
                    </div>
                    <div class="cell auto"></div>
                    <button class="cell shrink button color" type="button"
                            (click)="openAddSavedPaymentModal(paymentConfAvailable)">
                        {{'invoice.paymentConf.add' | translate}}
                    </button>
                </div>
            </div>
            <div class="cell small-24 medium-12 space-top-16 element-centered" [hidden]="paymentConfsAvailable.length > 0">
                <div class="info cell auto common-block force-space-bottom-0">
                    {{'invoice.paymentConf.config.empty' | translate}}
                </div>
            </div>
        </div>
        <payment-options *ngIf="invoiceForm$" [payment-request-infos]="invoiceForm$ | async"></payment-options>
    </div>

    <izivia-popup #addSavedPaymentModal [size]="'unset'">
        <add-payment-popup *ngIf="selectedPaymentConf"
                           [selectedPaymentConf]="selectedPaymentConf"
                           (modalConfirm)="addSavedPayment($event)"
                           (modalClose)="addSavedPaymentModal.close()"></add-payment-popup>
    </izivia-popup>
</ng-container>
