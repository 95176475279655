import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    ChargeAreaDto,
    Dictionary,
    DurationPriceDto,
    FrontCurrentPersonBusinessService,
    FrontCustomerAccountHttpService,
    FrontEndFleetConfig,
    FrontFoAccountHttpService,
    FrontStationHttpService,
    FrontTariffHttpService,
    HourType,
    ParkingTimePriceDto,
    PersonFleetPortalContext,
    StationDto,
    StationSearchCriteria,
    SubscriptionDef,
    switchTap,
    TariffDetailDto,
    TariffElementDto,
    TariffModificationCreationRequest,
    TariffPricingInfoPreviewRequestDto,
    TariffStatus,
    TradeAgreement,
    UserAction
} from 'lib-front';
import {catchError, filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {SelectableStation} from '../collapsible-stations-selects/collapsible-stations-selects.component';
import {Observable, of, Subscription} from 'rxjs';
import {NotificationService} from '../../services/utils/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {cloneDeep, flatMap, groupBy, includes} from 'lodash-es';
import {TariffChargeSimulatorComponent} from '../tariff-charge-simulator/tariff-charge-simulator.component';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../services/utils/alert.service';
import moment, {Moment} from 'moment';
import {FleetMomentToStringDatePipe} from '../../pipes/fleetMomentToStringDate.pipe';
import {FrontEndService} from '../../services/frontEnd.service';

@Component({
    selector: 'tariff-form',
    templateUrl: './tariff-form.component.html',
    styleUrls: ['./tariff-form.component.scss']
})
export class TariffFormComponent implements OnInit, OnDestroy, AfterViewChecked {
    public HourType = HourType;
    public Object = Object;

    isLoadingNbOfSubscribers: boolean = false;

    TariffStatus = TariffStatus;

    @ViewChild(TariffChargeSimulatorComponent) chargeSimulatorComponent: TariffChargeSimulatorComponent;

    @Input() stationWithQrCodeRefs: string[] = [];
    public disabledStationRefs: string[] = [];

    @Input()
    get editMode(): boolean {
        return this._editMode;
    }

    set editMode(value: boolean) {
        this._editMode = value;
        if (!this._editMode) {
            this.restoreTariff();
        } else {
            this.newEditPage();
        }
    }

    isOneSlot: boolean = true;

    vatRate: number | undefined = undefined;
    @Input()
    get tariff(): TariffDetailDto {
        return this._tariff;
    }

    @Input() nbOfLinkedStation: number = 0;

    set tariff(value: TariffDetailDto) {
        if (!!value) {
            value = this.addTariffElementIfNecessary(value);
            this.parseLinkedStations(value.linkedStationRefs || []);
            this.originalTariff = cloneDeep(value);
            this._tariff = cloneDeep(value);
            this.parseUserActions(value);
            this.isOneSlot = this.onlyOneTimeSlot();
            this.paidCharge = value?.chargePrice > 0;
            this.findOrInitializeTariffTradeAgreementEmo2CcSubscriptionDefRefLink();
        } else {
            this.originalTariff = undefined;
            this._tariff = undefined;
        }
    }

    @Input() tariffApplicationDate: Moment;

    // Keep a tariff copy to restore values on discard edit
    // You only should edit originalTariff after update/create
    private originalTariff: TariffDetailDto;
    private stations: StationDto[];
    private _editMode: boolean;
    @Input() readonlyMode: boolean;

    @Input() loading: boolean;
    @Input() disableTariffModification: boolean = false;
    @Input()
    get tariffTradeAgreementOtherEmo2CcSubscriptionDefs(): SubscriptionDef[] {
        return this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs;
    }

    set tariffTradeAgreementOtherEmo2CcSubscriptionDefs(value: SubscriptionDef[]) {
        if (!!value) {
            this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs = value;
            this.findOrInitializeTariffTradeAgreementEmo2CcSubscriptionDefRefLink();
        }
    }

    @Output() editModeChange = new EventEmitter<boolean>();
    @Output() isSaving = new EventEmitter<boolean>();
    @Output() tariffCreated = new EventEmitter<TariffDetailDto>();
    @Output() tariffUpdated = new EventEmitter<TariffDetailDto>();
    @Output() tariffEditionApplicationDate = new EventEmitter<Moment>();
    public chargeAreas: ChargeAreaDto[];
    public remoteStartAllowed: boolean;
    public remoteStopAllowed: boolean;
    public pricingInfoPreview: string;
    public subscriptionDefSelected: SubscriptionDef | undefined = undefined;
    public tradeAgreementLinkedToOtherEmo2CcSubscriptionDef: TradeAgreement;
    private _savingTariff: boolean;
    set savingTariff(value: boolean) {
        this._savingTariff = value;
        this.isSaving.emit(this._savingTariff);
    }
    get savingTariff(): boolean {
        return this._savingTariff;
    }
    public _tariff: TariffDetailDto;
    public fleetConfig: FrontEndFleetConfig;
    private _tariffTradeAgreementOtherEmo2CcSubscriptionDefs: SubscriptionDef[];
    selectedTariffElementIndex: number = 0;
    stationsByChargeArea: Dictionary<SelectableStation[]>;
    paidCharge: boolean;
    chargePrice: number;
    simulationHasBeenLaunched: boolean;
    canCreatePaynowTariffs: boolean;
    fetchingPricingInfoPreview: boolean;

    private fetchStationsSubscription: Subscription;
    private fetchCurrentFoAccountPaynowTariffStateSubscription: Subscription;
    private watchCurrentPersonSubscription: Subscription;
    private foAccountId: string;

    public allowedToDefineMoreRangesInTariff: boolean;
    public allowedToDefinePeakAndOffPeakHours: boolean;


    constructor(private readonly stationHttpService: FrontStationHttpService,
        private readonly tariffHttpService: FrontTariffHttpService,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly foAccountService: FrontFoAccountHttpService,
        private readonly currentPersonBusinessService: FrontCurrentPersonBusinessService,
        private readonly customerAccountHttpService: FrontCustomerAccountHttpService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        @Inject(LOCALE_ID) public readonly locale: string,
        private readonly route: ActivatedRoute,
        private readonly frontEndService: FrontEndService
    ) {
    }


    ngOnInit(): void {
        this.allowedToDefineMoreRangesInTariff = this.route.snapshot.data && this.route.snapshot.data.userContext
        && ((this.route.snapshot.data.userContext) as PersonFleetPortalContext).allowedToDefineMoreRangesInTariff;

        const criteria: StationSearchCriteria = {};
        criteria.inGeneratedGroup = false;
        this.fetchStationsSubscription = this.stationHttpService.searchStations(criteria)
            .subscribe(stations => {
                this.stations = stations.filter(station => !station.ownedBySomeone);
                this.parseLinkedStations(this.tariff?.linkedStationRefs || []);
            });

        this.fetchCurrentFoAccountPaynowTariffStateSubscription = this.foAccountService.findCurrentFoAccountPaynowTariffState()
            .subscribe(paynowTariffState => this.canCreatePaynowTariffs = paynowTariffState.creationAllowed);

        this.watchCurrentPersonSubscription = this.currentPersonBusinessService.currentDetailedPerson$
            .pipe(
                map(detailedPerson => detailedPerson.customerAccounts[0].customerAccountRef),
                switchMap(id => this.customerAccountHttpService.getCustomerAccountVatRate(id))
            )
            .subscribe(vatRateDto => this.vatRate = vatRateDto.vatRate);
        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => this.fleetConfig = frontEndInfo?.fleetConfig);
    }

    ngOnDestroy(): void {
        this.fetchStationsSubscription?.unsubscribe();
        this.fetchCurrentFoAccountPaynowTariffStateSubscription?.unsubscribe();
        this.watchCurrentPersonSubscription?.unsubscribe();
    }


    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    toggleEditMode() {
        this.isOneSlot = this.onlyOneTimeSlot() ;
        if (this.readonlyMode) {
            this.router.navigate([`/main/configuration/tariff/${this._tariff.id}/edit`]);
        } else {
            this.editMode = !this.editMode;
            if (!this.editMode) {
                this.restoreTariff();
            }
            this.editModeChange.emit(this.editMode);
        }
    }

    confirmSave(): void {
        if (!this._tariff.id && this._tariff.status !== TariffStatus.UPCOMING) {
            const popupTitle = this.translateService.instant('config.tariff.popup.save');
            const popupApprovalLabel = this.translateService.instant('yes');
            this.previewTariffPricingInfo().pipe(
                tap(pricingInfo => this.pricingInfoPreview = pricingInfo),
                switchMap(() => this.alertService.confirm(this.getSaveConfirmationPopupMessage(), popupTitle, popupApprovalLabel))
            )
                .subscribe(response => !!response && this.save());
        } else {
            const popupTitle = this.translateService.instant('config.tariff.modification.popup.warning.title', {tariffName: this._tariff.name});
            const warningMessage = this.translateService.instant('config.tariff.modification.popup.warning.question', {applicationDate: new FleetMomentToStringDatePipe(this.locale).transform(this.tariffApplicationDate)});
            const popupApprovalLabel = this.translateService.instant('yes');
            this.previewTariffPricingInfo().pipe(
                tap(pricingInfo => this._tariff.pricingInfo = pricingInfo),
                tap(pricingInfo => this.pricingInfoPreview = pricingInfo),
                switchMap(() => this.tariffHttpService.countTariffSubscribers((!this._tariff.status || this._tariff.status === TariffStatus.ACTIVE) ? this._tariff.id : this._tariff.history.originTariffId)),
                switchMap((nbOfSubscribers: number) => this.alertService.confirmWithWarning(this.getModificationPopupMessage(nbOfSubscribers, this._tariff.applyByQRCode), warningMessage, popupTitle, popupApprovalLabel))
            )
                .subscribe(response => !!response && this.save());
        }
    }

    private getCurrentFoAccountId(): Observable<string> {
        if (!!this.foAccountId) {
            return of(this.foAccountId);
        } else {
            return this.foAccountService.getCurrentFoAccountId()
                .pipe(
                    tap(foAccountId => this.foAccountId = foAccountId)
                );
        }
    }

    private getStationListString(): string {
        if (!this.stations) {
            return '';
        }
        let stationString = this.translateService.instant('config.tariff.modification.popup.warning.station');
        return this.stations.filter(station => this.tariff.linkedStationRefs.includes(station.id))
            .map(station => stationString + ' ' + station.visualId)
            .join(', ');
    }

    save(): void {
        this.savingTariff = true;
        const newLinkedStationRefs = flatMap(this.stationsByChargeArea).filter(station => station.selected).map(station => station.id);
        this.cleanupTariff();
        if (!this._tariff.id) {
            if (!this._tariff.status || this._tariff.status === TariffStatus.ACTIVE) {
                this.createTariff(newLinkedStationRefs);
            } else {
                this.createTariffModification(newLinkedStationRefs);
            }

        } else {
            this.updateTariff()
                .pipe(
                    map(newTariff => cloneDeep(newTariff)),
                    finalize(() => this.savingTariff = false)
                )
                .subscribe(newTariff => {
                    newTariff.linkedStationRefs = newLinkedStationRefs;
                    this.originalTariff = newTariff;
                    this.tariffUpdated.emit(newTariff);
                    this.notificationService.success('config.tariff.update.success',
                        {tariffName: newTariff.name});
                    this.toggleEditMode();
                },
                () => this.notificationService.error('config.tariff.update.error',
                    {tariffName: this._tariff.name}));

            if (this._tariff.status === TariffStatus.ACTIVE) {
                this.tariffHttpService.linkStationRefs(this.originalTariff.id, newLinkedStationRefs)
                    .subscribe(() => this.notificationService.success('config.tariff.link.success',
                        {tariffName: this._tariff.name, stationCount: newLinkedStationRefs.length}),
                    () => this.notificationService.error('config.tariff.link.error',
                        {tariffName: this._tariff.name}));
            }
        }
    }

    private createTariff(newLinkedStationRefs: string[]): void {
        this.tariffHttpService.createTariff(this._tariff)
            .pipe(
                tap(newTariff => {
                    if (newTariff.applyByQRCode) {
                        this.notificationService.success('config.tariff.create.success',
                            {tariffName: newTariff.name});
                    } else {
                        this.notificationService.success('config.tariff.create.successWithToken',
                            {tariffName: newTariff.name, tokenValue: newTariff.tokenValue});
                    }

                }),
                catchError(error => {
                    let errorKey = 'config.tariff.create.error';

                    if (error.error?.labelKey) {
                        errorKey = error.error.labelKey;
                    }
                    this.savingTariff = false;
                    this.notificationService.error(errorKey,
                        {tariffName: this._tariff.name});
                    return of(null);
                }),
                filter(val => !!val),
                switchTap((newTariff: TariffDetailDto) =>
                    this.tariffHttpService.linkStationRefs(newTariff.id, newLinkedStationRefs)
                ),
                map(newTariff => cloneDeep(newTariff)),
                finalize(() => this.savingTariff = false)
            )
            .subscribe((newTariff: TariffDetailDto) => {
                newTariff.linkedStationRefs = newLinkedStationRefs;
                this.originalTariff = newTariff;
                this.tariffCreated.emit(newTariff);
                this.toggleEditMode();
                this.notificationService.success('config.tariff.link.success',
                    {tariffName: newTariff.name, stationCount: newLinkedStationRefs.length});
            }, () => this.notificationService.error('config.tariff.link.error',
                {tariffName: this._tariff.name}));
    }

    private createTariffModification(newLinkedStationRefs: string[]): void {
        this.tariffHttpService.createTariffModification(new TariffModificationCreationRequest(this.tariffApplicationDate, this._tariff.history.originTariffId, this._tariff))
            .pipe(
                tap(newTariff => this.notificationService.success('config.tariff.create.success', {tariffName: newTariff.modification.newTariff.name})),
                catchError(error => {
                    let errorKey = 'config.tariff.create.error';

                    if (error.error?.labelKey) {
                        errorKey = error.error.labelKey;
                    }
                    this.savingTariff = false;
                    this.notificationService.error(errorKey,
                        {tariffName: !this._tariff.modification ? this._tariff.name : this._tariff.modification.newTariff.name});
                    return of(null);
                }),
                filter(val => !!val),
                switchTap((newTariff: TariffDetailDto) => {
                    if (!newTariff.modification) {
                        this.tariffHttpService.linkStationRefs(newTariff.id, newLinkedStationRefs);
                    }
                }),
                map(newTariff => cloneDeep(newTariff)),
                finalize(() => this.savingTariff = false)
            )
            .subscribe((newTariff: TariffDetailDto) => {
                newTariff.modification.newTariff.linkedStationRefs = newLinkedStationRefs;
                this.originalTariff = newTariff.modification.newTariff;
                this.tariffCreated.emit(newTariff);
                this.toggleEditMode();
                this.notificationService.success('config.tariff.link.success',
                    {tariffName: newTariff.modification.newTariff.name, stationCount: newLinkedStationRefs.length});
            }, () => this.notificationService.error('config.tariff.link.error',
                {tariffName: this._tariff.name}));
    }

    private updateTariff(): Observable<TariffDetailDto> {
        if (this._tariff.status === TariffStatus.ACTIVE) {
            return this.tariffHttpService.updateTariff(this._tariff);
        } else {
            return this.tariffHttpService.updateTariffModification(this._tariff)
                .pipe(
                    map((tariff) => tariff.modification.newTariff)
                );
        }
    }

    private cleanupTariff(): void {
        if (this.isOneSlot) {
            this._tariff.tariffElements[0].startTime = null;
            this._tariff.tariffElements[0].endTime = null;
        }
        if (this.onlyOneTimeSlot()) {
            this._tariff.tariffElements = [this._tariff.tariffElements[0]];
        }
    }

    freeChargePriceChange() {
        if (this.paidCharge) {
            this._tariff.chargePrice = this.chargePrice;
        } else {
            this.chargePrice = this._tariff.chargePrice;
            this._tariff.chargePrice = 0;
        }
    }

    onlyOneTimeSlot(): boolean {
        return !!this._tariff && !!this._tariff.tariffElements && this._tariff.tariffElements.length > 0 &&
            this._tariff.tariffElements[0].startTime === this._tariff.tariffElements[0].endTime;
    }

    startTimeChange(index: number): void {
        const previousTariffElement: number = this.getPreviousTariffElementIndex(index);
        this._tariff.tariffElements[previousTariffElement].endTime =
            this._tariff.tariffElements[index].startTime;
        if (this.onlyOneTimeSlot()) {
            this.selectedTariffElementIndex = 0;
        }
    }

    isHiddenTariff(index: number): boolean {
        return this.selectedTariffElementIndex !== index;
    }

    isFreeTariff() {
        return TariffDetailDto.isFree(this._tariff);
    }

    selectTariffElement(index: number): void {
        this.selectedTariffElementIndex = index;
    }

    remoteStartChanged() {
        this.bindUserActions(this.remoteStartAllowed, UserAction.REMOTE_START);
    }
    remoteStopChanged() {
        this.bindUserActions(this.remoteStopAllowed, UserAction.REMOTE_STOP);
    }

    isOneSlotChanged() {
        this.selectedTariffElementIndex = 0;
        this.tariff.tariffElements = [new TariffElementDto(), new TariffElementDto()];
        this.updateTariffElementsHourType(!!this.subscriptionDefSelected?.allowedToDefinePeakAndOffPeakHours, this.isOneSlot);
    }

    public updateTariffAfterSubscriptionDefChange(subscriptionDef: SubscriptionDef): void {
        this.updateTariffTradeAgreementOtherEmo2CcSubscriptionDefRefLink(subscriptionDef);
        this.updateTariffElementsHourType(subscriptionDef.allowedToDefinePeakAndOffPeakHours, this.isOneSlot);
    }
    private updateTariffTradeAgreementOtherEmo2CcSubscriptionDefRefLink(subscriptionDef: SubscriptionDef): void {
        this.tariff.linkedTariffTradeAgreementOtherEmo2CcSubscriptionDefRef = subscriptionDef?._id;
        this.subscriptionDefSelected = subscriptionDef;
        this.findTariffTradeAgreementLinkedToOtherEmo2CcSubscriptionDef(this.subscriptionDefSelected._id);
    }

    private updateTariffElementsHourType(allowedToDefinePeakAndOffPeakHours: boolean, isOneSlot: boolean): void {
        this.tariff.tariffElements.forEach(tariffElement =>
            tariffElement.hourType = allowedToDefinePeakAndOffPeakHours && !isOneSlot ? HourType.OFF_PEAK : null
        );
    }

    private findTariffTradeAgreementLinkedToOtherEmo2CcSubscriptionDef(otherEmo2CcSubscriptionDef: string) {
        this.getCurrentFoAccountId()
            .pipe(
                switchMap(foAccountId =>
                    this.foAccountService.findTariffTradeAgreementLinkedToOtherEmo2CcSubscriptionDef(
                        foAccountId,
                        otherEmo2CcSubscriptionDef
                    )
                )
            )
            .subscribe((tradeAgreement) => this.tradeAgreementLinkedToOtherEmo2CcSubscriptionDef = tradeAgreement);
    }

    private getPreviousTariffElementIndex(index: number): number {
        return index > 0 ? index - 1 : this._tariff.tariffElements.length - 1;
    }

    private getNextTariffElementIndex(index: number): number {
        return (index + 1) % this._tariff.tariffElements.length;
    }

    private parseUserActions(tariff: TariffDetailDto): void {
        if (!!tariff && !!tariff.userActions) {
            this.remoteStartAllowed = tariff.userActions.includes(UserAction.REMOTE_START);
            this.remoteStopAllowed = tariff.userActions.includes(UserAction.REMOTE_STOP);
        }
    }

    private bindUserActions(remoteAllowed: boolean, action: UserAction) {
        if (remoteAllowed) {
            this.tariff.userActions.push(action);
        } else {
            this.tariff.userActions = this.tariff.userActions.filter(userAction => userAction !== action);
        }
    }

    private parseLinkedStations(linkedStationRefs: string[]): void {
        if (!!this.stations) {
            this.stationsByChargeArea =
                groupBy(this.stations
                    .map(station => <SelectableStation>station)
                    .map(station => {
                        station.selected = includes(linkedStationRefs, station.id);
                        return station;
                    }), 'chargeAreaRef');
        }
    }

    private newEditPage() {
        this.simulationHasBeenLaunched = false;
        this.pricingInfoPreview = null;
    }

    private restoreTariff() {
        this.parseUserActions(this.originalTariff);
        this._tariff = cloneDeep(this.originalTariff);
    }

    private addTariffElementIfNecessary(tariff: TariffDetailDto): TariffDetailDto {
        if (!tariff.tariffElements || tariff.tariffElements.length === 0) {
            tariff.tariffElements = [new TariffElementDto(), new TariffElementDto()];
        } else if (tariff.tariffElements.length === 1) {
            tariff.tariffElements = [tariff.tariffElements[0], new TariffElementDto()];
        }
        return tariff;
    }

    isTariffFree() {
        return this.tariff.chargePrice === 0 && this.allTariffElementsAreFree();
    }

    private allTariffElementsAreFree() {
        for (const tariff of this.tariff.tariffElements) {
            if (tariff.energyPrice > 0 ||
                (
                    tariff.parkingTimePrice &&
                    !this.isParkingTimePriceFree(tariff.parkingTimePrice)
                )
            ) {
                return false;
            }
        }
        return true;
    }

    isParkingTimePriceFree(parkingTimePrice: ParkingTimePriceDto) {
        return this.durationIsFree(parkingTimePrice.shortDuration) && this.durationIsFree(parkingTimePrice.longDuration);
    }

    durationIsFree(duration: DurationPriceDto) {
        return duration.price === 0 || duration.duration === 0;
    }

    simulateCharge(): void {
        this.simulationHasBeenLaunched = true;
        this.chargeSimulatorComponent.preview();

        this.fetchingPricingInfoPreview = true;
        this.previewTariffPricingInfo()
            .pipe(
                finalize(() => this.fetchingPricingInfoPreview = false)
            )
            .subscribe(
                pricingInfo => this.pricingInfoPreview = pricingInfo
            );
    }

    private previewTariffPricingInfo(): Observable<string> {
        if (this.isOneSlot) {
            this._tariff.tariffElements[0].startTime = null;
            this._tariff.tariffElements[0].endTime = null;
        }
        const tariffPreview: TariffPricingInfoPreviewRequestDto = {
            tariffElements: this.onlyOneTimeSlot() ? [this._tariff.tariffElements[0]] : this._tariff.tariffElements,
            chargePrice: this._tariff.chargePrice,
            currency: this._tariff.currency,
            subscriptionDefRef: this.subscriptionDefSelected?._id
        };
        return this.tariffHttpService.previewTariffPricingInfo(tariffPreview);
    }

    //todo this should have it's own popup ASF
    private getModificationPopupMessage(nbOfSubscribers: number, isPrimoTariff: boolean) {

        if (this.tariff.linkedStationRefs.length === 0 || this.tariff.linkedStationRefs.length > 5) {
            let key = isPrimoTariff ? 'config.tariff.modification.popup.warning.textWithoutStations' : 'config.tariff.modification.popup.warning.textWithUsersWithoutStations';

            return this.translateService.instant(key, {
                nbSubscribers: nbOfSubscribers,
                stationNb: this.nbOfLinkedStation
            });
        } else {
            let key = isPrimoTariff ? 'config.tariff.modification.popup.warning.textWithStations' : 'config.tariff.modification.popup.warning.textWithStationsAndUsers';
            return this.translateService.instant(key, {
                nbSubscribers: nbOfSubscribers,
                stationNb: this.nbOfLinkedStation,
                stationListString: this.getStationListString()
            });
        }
    }

    private getSaveConfirmationPopupMessage() {
        return  '<p><strong>' + this.translateService.instant('config.tariff.pricingInfo') + '</strong><br />'
            + this.pricingInfoPreview.split('+').map(text => '<p>' + text + '</p>').join('') + '</p>';
    }

    public setApplyMode(applyByQRCode: boolean): void {
        this.tariff.applyByQRCode = applyByQRCode;
        this.resetSelectedStations();
        if(applyByQRCode) {
            this.remoteStartAllowed = true;
            this.remoteStartChanged();
            this.remoteStopAllowed = true;
            this.remoteStopChanged();
            this.disabledStationRefs = this.stationWithQrCodeRefs;
        } else {
            this.disabledStationRefs = [];
        }
    }

    private resetSelectedStations(): void {
        this.stationsByChargeArea = groupBy(this.stations
            .map(station => <SelectableStation>station)
            .map(station => {
                station.selected = false;
                return station;
            }), 'chargeAreaRef');
    }

    showSuccessCopySubscriptionLinkToClipboard() {
        this.notificationService.success('config.tariff.linkCopied');
    }

    showSuccessCopySubscriptionTokenToClipboard() {
        this.notificationService.success('config.tariff.tokenCopied');
    }

    openApplicationDateSelectionPopup() {
        this.computeApplicationDateMinDaysLimit().subscribe((dayLimit) => {
            let applicationDate = moment().add(dayLimit, 'day').startOf('day');
            this.tariffEditionApplicationDate.emit(applicationDate);
        });
    }

    private computeApplicationDateMinDaysLimit(): Observable<number> {
        this.isLoadingNbOfSubscribers = true;
        return this.tariffHttpService.countTariffSubscribers(this._tariff.id).pipe(
            map((nbOfSubscribers: number) => this._tariff.applyByQRCode || nbOfSubscribers === 0 ? 1 : 31),
            finalize(() => this.isLoadingNbOfSubscribers = false)
        );
    };

    tariffCanBeModified(tariff: TariffDetailDto) {
        return !tariff.modification && tariff.id && !tariff.deletion && tariff.status !== TariffStatus.UPCOMING && !this.disableTariffModification;
    }

    private findOrInitializeTariffTradeAgreementEmo2CcSubscriptionDefRefLink(): void {
        this.subscriptionDefSelected = undefined;
        if (this.tariff?.linkedTariffTradeAgreementOtherEmo2CcSubscriptionDefRef && this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs?.length > 1) {
            this.subscriptionDefSelected = this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs
                .find(subscriptionDef => subscriptionDef._id === this.tariff.linkedTariffTradeAgreementOtherEmo2CcSubscriptionDefRef);
            this.findTariffTradeAgreementLinkedToOtherEmo2CcSubscriptionDef(this.subscriptionDefSelected._id);
        } else if (this.tariff && !this.tariff.id && this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs?.length === 1) {
            this.subscriptionDefSelected = this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs[0];
            this.tariff.linkedTariffTradeAgreementOtherEmo2CcSubscriptionDefRef = this._tariffTradeAgreementOtherEmo2CcSubscriptionDefs[0]?._id;
            this.findTariffTradeAgreementLinkedToOtherEmo2CcSubscriptionDef(this.tariff.linkedTariffTradeAgreementOtherEmo2CcSubscriptionDefRef);
        }
    }

    addTariffElement() {
        let tariffElementDto = new TariffElementDto();
        tariffElementDto.endTime = this.tariff.tariffElements[0].startTime;
        if (!!this.subscriptionDefSelected?.allowedToDefinePeakAndOffPeakHours) {
            tariffElementDto.hourType = HourType.OFF_PEAK;
        }
        this.tariff.tariffElements.push(tariffElementDto);
    }

    removeTariffElement(index: number) {
        this.tariff.tariffElements.splice(index, 1);

        let newLastIndex = this.tariff.tariffElements.length - 1;
        this.startTimeChange(index <= newLastIndex ? index : 0);
    }

    trackByIndex(index: number): number {
        return index;
    }

    getIdOfTariffElement(tariffElement: TariffElementDto) {
        if (tariffElement['id'] === undefined) {
            tariffElement['id'] = 'tariffElement' + Math.random();
        }

        return tariffElement['id'];
    }

    doNotShowStation(): boolean {
        return !this.editMode ||
            this.tariff.status === TariffStatus.UPCOMING ||
            (this.tradeAgreementLinkedToOtherEmo2CcSubscriptionDef &&
                this.tradeAgreementLinkedToOtherEmo2CcSubscriptionDef.allowTariffSubscriberToDeclareStation);
    }
}
