import {Component, Input, ViewChild} from '@angular/core';
import {
    ChargePointCriteria,
    ChargePointDto,
    ChargePointLazyCategory,
    CurrentSubscriberRolesService,
    FrontChargePointHttpService,
    FrontStationHttpService,
    FrontTariffHttpService,
    PlugDto,
    StationDto,
    StationLazyCategory,
    SubscriberRoleLevel,
    SubscriberRoleType,
    TariffDto,
    buildRole
} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';

@Component({
    selector: 'charge-area-station[hasStationWriteRole]',
    templateUrl: './charge-area-station.component.html',
    styleUrls: ['./charge-area-station.component.scss']
})
export class ChargeAreaStationComponent extends AbstractHasRoleActionComponent{
    @Input() chargeAreaRef: string;
    @Input() public hasStationWriteRole: boolean;
    chargePoints: ChargePointDto[];
    selectedPlug: PlugDto;
    waitingChargePoints: boolean;
    public waitingStationDetails: boolean;
    public selectedChargePoint: ChargePointDto;
    public stationTariffs: TariffDto[];
    public hasChargeAreasWriteRole: boolean;
    private initDetails: boolean = false;
    @ViewChild('collapsibleCardDetailComponent') collapsibleDetailCard;

    constructor(private readonly chargePointService: FrontChargePointHttpService,
        private readonly stationService: FrontStationHttpService,
        private readonly tariffHttpService: FrontTariffHttpService,
        protected readonly notificationService: NotificationService,
        private readonly router: Router,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
        super(notificationService);
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasChargeAreasWriteRole = hasRole);
    }

    private _station: StationDto;

    get station(): StationDto {
        return this._station;
    }

    @Input() set station(station: StationDto) {
        this._station = station;

        this.initDetails = false;

        if (this.collapsibleDetailCard && !this.collapsibleDetailCard.isCollapsed) {
            this.loadStationDetails();
        }
    }

    editStation() {
        this.doActionIfHasRole(
            () => this.router.navigate([`/main/chargeAreas/${this.chargeAreaRef}/stations/${this._station.id}`]),
            this.hasChargeAreasWriteRole
        );
    }

    selectPlug(chargePoint: ChargePointDto, plug: PlugDto) {
        this.selectedChargePoint = chargePoint;
        this.selectedPlug = plug;
    }

    isSelected(plug: PlugDto): boolean {
        return this.selectedPlug === plug;
    }

    loadStationDetails() {
        if (!this.initDetails) {
            this.waitingStationDetails = true;
            this.stationService.findStation(
                this.station.id,
                [StationLazyCategory.USER_ACTIONS, StationLazyCategory.CHARGE_POINTS,
                    StationLazyCategory.PLUG_TYPES])
                .pipe(finalize(() => this.waitingStationDetails = false ))
                .subscribe(value => this._station = value);

            this.tariffHttpService.fetchTariffs({stationId: this.station.id})
                .subscribe(tariffs => this.stationTariffs = tariffs);

            this.waitingChargePoints = true;
            const criteria: ChargePointCriteria = new ChargePointCriteria(this._station.id,
                [ChargePointLazyCategory.USER_ACTIONS, ChargePointLazyCategory.PLUG_DTO], 100);
            this.chargePointService.fetchChargePoints(criteria)
                .pipe(finalize(() => this.waitingChargePoints = false))
                .subscribe(chargePoints => this.chargePoints = chargePoints);
            this.initDetails = true;
        }
    }

    trackByChargePointId(index: number, chargePoint: ChargePointDto) {
        return chargePoint?.id ?? index;
    }

    trackByTariffId(index: number, tariffDto: TariffDto) {
        return tariffDto?.id ?? index;
    }

    trackByPlugId(index: number, plug: PlugDto) {
        return plug?.id ?? index;
    }
}
