import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FaqNodeDetailDto} from 'lib-front';

@Component({
    selector: 'faq-breadcrumbs',
    templateUrl: './faq-breadcrumbs.component.html',
    styleUrls: ['./faq-breadcrumbs.component.scss']
})
export class FaqBreadcrumbsComponent {

    @Input()
    public faqNode: FaqNodeDetailDto;

    @Output()
    public faqNodeClicked = new EventEmitter<string>();

    constructor() {
    }

    loadFaqNode(faqNodeId: string): void {
        this.faqNodeClicked.emit(faqNodeId);
    }

}
