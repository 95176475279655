<div class="grid-x grid-padding-x cell auto align-middle app-root-header-container">
    <div class="cell shrink">
        <img class="logo" src="../../assets/png/logo.png" alt="logo" [routerLink]="isConnected()? '/home':'/login'"/>
    </div>

    <div class="cell shrink">
        <span *ngFor="let lang of languages" class="space-left-5">
            <a *ngIf="lang!==translateService.currentLang" (click)="changeLanguage(lang)">{{'language.' + lang | translate}}</a>
            <b *ngIf="lang===translateService.currentLang">{{'language.' + lang | translate}}</b>
        </span>
    </div>

    <div class="cell auto">
        <div class="text-center" [hidden]="!isConnected() || isOnRegister()">
            <h3>{{'header.welcome' | translate: {firstName: user.firstName, lastName: user.lastName} }}</h3>
            <h5 *ngIf="finalClientConfigurable && foAccounts.length === 1 && currentFoAccount && currentFoAccount.socialReason && currentFoAccount.finalClientRef">
                {{'header.welcomeSubtitle' | translate: {socialReason: currentFoAccount.socialReason, finalClientRef: currentFoAccount.finalClientRef} }}
            </h5>
        </div>
    </div>

    <div class="cell shrink account-options text-right">
        <div>
            <button routerLink="/main/configuration/faq"
                    [disabled]="loggingOut"
                    *ngIf="hasFaqReadRole"
                    class="button secondary round force-space-right-8"
                    title="{{'config.faq.title' | translate}}">
                <ng-container>
                    <i class="fas fa-question-circle"></i>
                </ng-container>
            </button>
            <button routerLink="/main/imports"
                    [disabled]="loggingOut"
                    [hidden]="!isConnected()"
                    class="button secondary round force-space-right-8"
                    title="{{'imports.title' | translate}}">
                <ng-container *ngIf="currentImportIds$ | async as currentImport">
                    <i [hidden]="currentImport.length"
                       class="fas fa-cloud-upload-alt"></i>
                    <span [hidden]="!currentImport.length">
                    <div class="loader">&nbsp;</div>
                </span>
                </ng-container>
            </button>

            <button routerLink="/main/configuration"
                    *ngIf="hasConfigurationReadRole && hasManagerInfosReadRole"
                    [disabled]="loggingOut"
                    [hidden]="!isConnected() || isOnRegister()"
                    class="button secondary round force-space-right-8"
                    title="{{'config.account.title' | translate}}">
                <i class="fas fa-user"></i>
            </button>
            <button *ngIf="foAccounts.length <= 1" type="button" class="button secondary round" (click)="logout()"
                    [iziviaLoading]="loggingOut"
                    title="{{'' | translate}}"
                    [hidden]="!isConnected() || isOnRegister()">
                <i class="fas fa-sign-out-alt"></i>
            </button>
        </div>

        <div *ngIf="foAccounts.length > 1 && isConnected()" class="account-options_spacer">&nbsp;</div>

        <fo-account-switcher
                *ngIf="foAccounts.length > 1 && isConnected() && currentFoAccount"
                [foAccounts]="foAccounts"
                [currentFoAccount]="currentFoAccount"
                [finalClientConfigurable]="finalClientConfigurable"
                (logoutClicked)="logout()"></fo-account-switcher>

        <a routerLink="/register" class="button secondary go" [hidden]="isConnected() || isOnRegister() || !allowToSignUp">
            {{'login.signup' | translate}}
        </a>
        <a routerLink="/login" class="button secondary go" [hidden]="!isOnRegister()">
            {{'login.iHaveMyAccount' | translate}}
        </a>
    </div>
</div>
