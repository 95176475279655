<div class="indicator indicator-vrt">
    <div class="grid-x indicator-container infraStatus"
         [ngClass]="'infraStatus-'+status"
         [class.infraStatusNull]="number === 0">
        <div class="grid-x text-center indicator-content">
            <div class="indicator-icon" [ngClass]="'indicator-icon-'+status"></div>
            <span class="indicator-value">{{number}}</span>
            <span class="indicator-label">{{'stations.status.' + status | translate}}</span>
        </div>
    </div>
</div>


