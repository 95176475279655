import {OptionSubscription, PackSubscription, Subscription} from 'lib-front';
import {chain} from '../../libs/lodash-es/chain';

export function getOptionsFromPackSubscription(packSubscription: PackSubscription): OptionSubscription[] {
    return chain(packSubscription.customerAccounts)
        .map(customerAccount => customerAccount.subscriptions)
        .flatten()
        .filter(subscription => subscription && subscription.options && !!subscription.options.length)
        .map(subscription => subscription as Subscription)
        .map(subscription => {
            if (subscription.options.length > 0) {
                subscription.options.forEach(option => {
                    option.subscriptionDef.subscriptionDefParentRef = subscription.subscriptionDefRef;
                    option.subscriptionDef.subscriptionDefParentName = subscription.subscriptionDefName;
                });
                if (subscription.sharedOptionFreeTime) {
                    subscription.options[0].context.consumedFreeTime += subscription.context.consumedFreeTime;
                    subscription.options[0].currentPeriod.carriedOverFreeTime += subscription.currentPeriod.carriedOverFreeTime;
                }
            }
            return subscription.options;
        })
        .flatten()
        .groupBy('subscriptionDefRef')
        .values()
        .map(options => {
            const optionForm: OptionSubscription = {};
            let consumedFreeTime = 0;
            let carryOverTime = 0;

            options.filter(option => option && option.context)
                .forEach(option => {
                    consumedFreeTime += option.context.consumedFreeTime;
                    carryOverTime += option.currentPeriod.carriedOverFreeTime;
                });

            options[0].context.consumedFreeTime = consumedFreeTime;
            options[0].currentPeriod.carriedOverFreeTime = carryOverTime;
            optionForm.subscription = options[0];
            optionForm.number = options.length;

            return optionForm;
        })
        .value();
}
