<div class="iziviaPopup"
     role="dialog"
     tabindex="-1">
    <div class="iziviaPopup-header">
        <i class="fas fa-exclamation iziviaPopup-header_icon"></i>
    </div>
    <div class="iziviaPopup-content">
        <div>
            <p>{{ 'invoice.popup.message' | translate:{
                operator: selectedPaymentConf.emoName,
                paymentType: ('payment.' + selectedPaymentConf.paymentType | translate).toLowerCase()
            } }}</p>
        </div>
        <div class="iziviaPopup-content_message">
            {{ 'invoice.popup.payment.echeance' | translate }}
        </div>

        <input class="iziviaPopup-content_input"
               placeholder="{{ 'invoice.popup.label' | translate }}"
               type="text"
               [(ngModel)]="paymentName">

        <div class="iziviaPopup-content_message"
             *ngIf="paymentNameMissing">
            {{ 'invoice.popup.errorText' | translate }}
        </div>
    </div>

    <div class="iziviaPopup-buttons">
        <button class="button color withMargin"
                type="button"
                (click)="confirmModal()">
            {{ 'invoice.popup.confirmText' | translate }}
        </button>
        <button class="button secondary withMargin"
                type="button"
                (click)="closeModal()">
            {{ 'invoice.popup.cancelText' | translate }}
        </button>
    </div>
</div>

