<ng-container *ngIf="consumption">
    <div class="cell grid-x grid-padding-x">
        <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.priceWithoutVat' | translate}}</span>
        <span class="cell shrink consumption-detail-block-info-text">{{consumption.priceWithoutVat | currency:undefined:consumption.currencyUnit || ''}}</span>
    </div>
    <div class="cell grid-x grid-padding-x grid-padding-y">
        <span class="cell shrink consumption-detail-block-info-label">{{'consumptions.detail.priceWithVat' | translate}}</span>
        <span class="cell shrink consumption-detail-block-info-text">{{consumption.priceWithVat | currency:undefined:consumption.currencyUnit || ''}}</span>
    </div>
</ng-container>
