import {Component} from '@angular/core';
import {StepIndicatorComponent} from '../step-indicator.component';

@Component({
    selector: 'step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent {
    public number;

    constructor(public stepIndicator: StepIndicatorComponent) {
        stepIndicator.addStep(this);
    }
}
