import {NgModule} from '@angular/core';
import {TariffPriceValidatorDirective} from './tariffPriceValidatorDirective';
import {NumericDirective} from './numeric.directive';
import {ModalDirective} from './modal.directive';
import {PostalSpaceDirective} from './postalSpace.directive';
import {PostalCodeValidator} from './validators/postalCodeValidator.directive';
import {NoSpaceValidatorDirective} from './validators/noSpaceValidator.directive';
import {EqualPasswordValidator} from './validators/equalPasswordValidator.directive';
import {EmailUniquenessValidatorCurrentUserDirective} from './validators/emailUniquenessValidatorCurrentUser.directive';
import {LoadingDirective} from './loading.directive';
import {DragAndDropDirective} from './drag-and-drop.directive';

// please keep sorted alphabetically
const directives = [
    EmailUniquenessValidatorCurrentUserDirective,
    EqualPasswordValidator,
    LoadingDirective,
    ModalDirective,
    NoSpaceValidatorDirective,
    NumericDirective,
    PostalCodeValidator,
    PostalSpaceDirective,
    TariffPriceValidatorDirective,
    DragAndDropDirective
];

@NgModule({
    exports: directives,
    declarations: directives
})
export class DirectivesModule {
}
