<section class="grid-y style-guide-block">
    <h1 class="style-guide-title">Typography</h1>

    <div class="cell style-guide-section-content">
        <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Les régles de typography sont définies dans la feuille de style ce trouvant dans <strong>styles/base/typography.scss</strong><br>
                Les options de typography sont définies dans la feuille de style ce trouvant dans <strong>styles/utils/helpers.scss</strong>
            </p>
        </div>

        <h4 class="style-guide-sub-title">Titres</h4>

        <!-- START / Titles -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <h1>h1 - Title very large</h1>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="primary"</li>
                    <li>class="secondary"</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <h2>h2 - Title large</h2>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="secondary"</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <h3>h3 - Title medium</h3>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="primary"</li>
                    <li>class="secondary"</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <h4>h4 - Title moderate</h4>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>No options</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <h5>h5 - Title small</h5>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>No options</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <h6>h6 - Title very small</h6>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>No options</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <a>a - Link</a>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>No options</li>
                </ul>
            </div>
        </div>
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <span class="note">Note</span>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="error"</li>
                </ul>
            </div>
        </div>
        <!-- END / Titles -->

        <h4 class="style-guide-sub-title space-top-32">Options typography</h4>
        <!-- START / Options italic -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <p class="highlight-text-italic">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="highlight-text-italic"</li>
                </ul>
            </div>
        </div>
        <!-- END / Options italic -->

        <!-- START / Options bold -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <p class="highlight-text-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="highlight-text-bold"</li>
                </ul>
            </div>
        </div>
        <!-- END / Options bold -->

        <!-- START / Options uppercase -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <p class="text-uppercase">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="text-uppercase"</li>
                </ul>
            </div>
        </div>
        <!-- END / Options uppercase -->

        <!-- START / Options lowercase -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <p class="text-lowercase">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="text-lowercase"</li>
                </ul>
            </div>
        </div>
        <!-- END / Options lowercase -->

        <!-- START / Options Alignment -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>class="text-left"</li>
                    <li>class="text-center"</li>
                    <li>class="text-right"</li>
                </ul>
            </div>
        </div>
        <!-- END / Options Alignment -->

        <!-- START / Options Justify -->
        <div class="style-guide-element options">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <p class="text-justify">Aenean eu leo quam. Pellentesque
                        ornare sem lacinia quam venenatis vestibulum.Aenean eu leo quam. Pellentesque
                        ornare sem lacinia quam venenatis vestibulum.Aenean eu leo quam.</p>
                </div>
            </div>
            <div class="style-guide-code">
                <ul class="style-guide-list-options">
                    <li>text-justify</li>
                </ul>
            </div>
        </div>
        <!-- END / Options Justify -->
    </div>
</section>