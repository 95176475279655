<div class="cell auto has-space">
    <div>
        <p class="description" *ngIf="info?.minimumDayBetweenTwoReports > 0">
            {{(isSelfcareInvoiceActivated ?
            'finances.incomes.delayExplanation' :
            'finances.incomes.hideSelfcareInvoices.delayExplanation') | translate:{duration: info?.minimumDayBetweenTwoReports} }}
        </p>
    </div>

    <fieldset class="section space-top-32">
        <legend>{{'finances.incomes.consumptionToCollect' | translate}}</legend>
        <consumption-search #consumptionSearchComponent
                            (consumptionsChange)="updateConsumptions($event)"
                            (loadingChange)="updateLoadingState($event)"></consumption-search>

        <consumption-table [consumptions]="consumptions"
                           [currencyUnit]="currencyUnit"
                           [loading]="loading"></consumption-table>
    </fieldset>

    <fieldset class="section space-top-32" *ngIf="lastFinancialReports.length > 0">
        <legend>  {{'finances.invoices.last' | translate}}</legend>
        <financial-report-table
            [financialReports]="lastFinancialReports"
            (reloadReports)="reloadReports()"
            [isSelfcareInvoiceActivated]="isSelfcareInvoiceActivated"
        ></financial-report-table>
    </fieldset>

    <div class="grid-x space-top-32 space-bottom-32">
        <a class="cell small-24 button secondary" [routerLink]="'history'">
            {{(isSelfcareInvoiceActivated ?
            'finances.incomes.history' :
            'finances.incomes.hideSelfcareInvoices.history') | translate}}
        </a>
    </div>
</div>
