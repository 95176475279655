import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FOAccountWrapper, FrontEndHttpService, HOST_THIRD_PARTY_ID} from 'lib-front';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import { ModeAddressForm } from './modeAddressForm';

@Component({
    selector: 'address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true
        }
    ]
})

export class AddressFormComponent implements OnInit, AfterViewInit {

    @Input() foAccount: FOAccountWrapper;
    @Input() mode: ModeAddressForm = ModeAddressForm.BILLING;
    @Input() envelopeValidated = false;

    @Output() isFormAddressValid: EventEmitter<boolean> = new EventEmitter(false);
    @Output() isFormAddressDirty: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild('addressForm') formAddress: NgForm;

    // enum
    ModeAddressForm = ModeAddressForm;

    public siretMandatory: boolean;

    private isValid: boolean = true;

    private addressValidity: boolean = true;

    constructor(@Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string,
        private readonly frontEndHttpService: FrontEndHttpService) {
    }

    ngOnInit(): void {
        this.frontEndHttpService.findFrontInfo(this.thirdPartyId).subscribe(result => {
            this.siretMandatory = result.fleetConfig?.siretMandatory || true;
        });
    }

    ngAfterViewInit(): void {
        this.formAddress.statusChanges
            .pipe(debounceTime(500))
            .subscribe(() => {
                this.isFormAddressDirty.emit(this.formAddress.dirty);
                this.onValidityChange();
            });
    }

    onAddressValidityChange(isValid: boolean) {
        this.addressValidity = isValid;
        this.onValidityChange();
    }

    onValidityChange() {
        if (this.mode === ModeAddressForm.BILLING_ENVELOPE || this.mode === ModeAddressForm.DELIVERY_ENVELOPE) {
            this.addressValidity = true;
        }
        this.isValid = this.addressValidity && this.formAddress.valid;
        this.isFormAddressValid.emit(this.isValid);
    }
}
