<div class="cell auto app-view-content" style="min-height: 400px">
    <div class="has-space">
        <div class="grid-x cell shrink align-justify space-bottom-8 space-top-8">
            <h3 class="grid-x align-middle secondary block-title">
                <i class="fas fa-receipt space-right-8"></i>
                {{'expenseReport.title' | translate}}
            </h3>
        </div>

        <form>
            <div class="grid-y grid-padding-y">
                <div class="grid-x grid-margin-x expenseReport-search">
                    <label class="cell auto search-label with-label force-inner-space-left-0">
                        <span>{{'expenseReport.search.label' | translate}}</span>
                        <input type="text"
                               [(ngModel)]="searchRequest.collaboratorFirstName"
                               [placeholder]="'expenseReport.search.criteria.collaboratorFirstName' | translate"
                               name="collaboratorFirstName" class="search">
                        <i class="fas fa-search search-icon"></i>
                    </label>
                    <label class="cell auto search-label with-label force-inner-space-left-0">
                        <span>&nbsp;</span>
                        <input type="text"
                               [(ngModel)]="searchRequest.collaboratorLastName"
                               [placeholder]="'expenseReport.search.criteria.collaboratorLastName' | translate"
                               name="collaboratorLastName" class="search">
                        <i class="fas fa-search search-icon"></i>
                    </label>

                    <label class="cell auto">
                        <span>{{'expenseReport.search.criteria.startMonth' | translate}}</span>
                        <date-picker [(ngModel)]="fromSearch"
                                     (ngModelChange)="updateFromSearchRequest($event)"
                                     [mode]="calendarMode"
                                     name="fromMonth"
                                     class="full-input"></date-picker>
                    </label>

                    <label class="cell auto">
                        <span>{{'expenseReport.search.criteria.endMonth' | translate}}</span>
                        <date-picker [(ngModel)]="toSearch"
                                     (ngModelChange)="updateToSearchRequest($event)"
                                     [mode]="calendarMode"
                                     name="toMonth"
                                     class="full-input"></date-picker>
                    </label>

                    <label class="cell auto">
                        <span>{{'expenseReport.search.criteria.status' | translate}}</span>
                        <select [(ngModel)]="selectedStatus"
                                (ngModelChange)="updateStatusSearchRequest($event)"
                                name="status">
                            <option [ngValue]="null">{{'expenseReport.search.criteria.allStatuses' | translate}}</option>
                            <option *ngFor="let status of statuses"
                                    [ngValue]="status">
                                {{'expenseReport.statuses.' + status | translate}}
                            </option>
                        </select>
                    </label>

                    <div class="cell shrink">
                        <button type="submit"
                                (click)="search(1)"
                                [iziviaLoading]="searching"
                                class="button primary"
                                style="top: 25px;">
                            {{'expenseReport.search.button' | translate}}
                        </button>
                    </div>
                </div>

                <div class="grid-x cell shrink align-middle grouping-bloc space-bottom-8">
                <span class="cell shrink grouping-title space-right-20">{{'expenseReport.search.criteria.groupBy' | translate}}
                    :</span>

                    <div class="grid-x grid-margin-x cell auto small-align-left">
                        <div class="cell shrink">
                            <label class="cell shrink radio">
                                <input type="checkbox"
                                       checked
                                       [ngModel]="isChecked(expenseReportGroupingField.COLLABORATOR)"
                                       (change)="switchGroupBy(expenseReportGroupingField.COLLABORATOR)"
                                       name="groupByCollaborator">
                                <span class="custom-radio"></span>
                                <span class="label-choice">{{'expenseReport.search.criteria.collaborator' | translate}}</span>
                            </label>
                        </div>

                        <div class="cell shrink">
                            <label class="cell shrink radio">
                                <input type="checkbox"
                                       checked
                                       [ngModel]="isChecked(expenseReportGroupingField.MONTH)"
                                       (change)="switchGroupBy(expenseReportGroupingField.MONTH)"
                                       name="groupByMonth">
                                <span class="custom-radio"></span>
                                <span class="label-choice">{{'expenseReport.search.criteria.month' | translate}}</span>
                            </label>
                        </div>

                        <div class="cell shrink">
                            <label class="cell shrink radio">
                                <input type="checkbox"
                                       checked
                                       disabled
                                       name="groupByStatus">
                                <span class="custom-radio"></span>
                                <span class="label-choice">{{'expenseReport.search.criteria.status' | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="grid-x align-middle expenseReport-pagination">
            <div class="cell shrink">
                <div *ngIf="expenseReportGroups.length > 0"
                     class="pagination">
                    <button class="pagination-button" (click)="search(page - 1)"
                            [disabled]="page - 1 <= 0 || searching">
                        <i class="fas fa-angle-left"></i>
                    </button>
                    <span class="pagination-label">{{page}}</span>
                    <button class="pagination-button" (click)="search(page + 1)"
                            [disabled]="expenseReportGroups.length < resultsPerPage || searching">
                        <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
            <div class="cell shrink grid-x grid-margin-x">
                <indicator class="cell auto color" [img]="'charge'">
                    <ng-container value>
                        {{activeStationCount}}
                    </ng-container>
                    <ng-container label>
                        {{'expenseReport.activeStations' | translate}}
                    </ng-container>
                </indicator>
                <button type="button"
                        *ngIf="hasExpenseReportManualGenerationEnabled()"
                        (click)="regulateExpenseReports()"
                        [iziviaLoading]="generatingInvoice"
                        class="cell shrink button secondary color">
                    {{'expenseReport.regulate.title' | translate}}
                </button>
                <button type="button"
                        (click)="getExpenseReportReport()"
                        class="cell shrink  button secondary color">
                    {{'expenseReport.export' | translate}}
                </button>
                <a *ngIf="fleetConfig?.reportingEnabled && fleetConfig?.reportingList?.includes(Reporting.EXPENSES_REPORTS)"
                   [routerLink]="'/main/reporting/reportExpenses'"
                   class="cell shrink  button secondary"
                   type="button">
                    {{'common.more' | translate}}
                </a>
            </div>
        </div>

        <div *ngIf="expenseReportGroups.length > 0"
             class="grid-x rowBloc">
            <div class="cell small-24 cell-block">
                <ng-container *ngFor="let expenseReportGroup of expenseReportGroups">
                    <expense-report-group-card [fromSearch]="fromSearch.toDate()"
                                               [toSearch]="toSearch.toDate()"
                                               [firstNameSearch]="searchRequest.collaboratorFirstName"
                                               [lastNameSearch]="searchRequest.collaboratorLastName"
                                               [hasAutomaticallyRefundConfiguration]="refundedExpensesConfiguration?.automatically"
                                               [refundedExpensesConfiguration]="refundedExpensesConfiguration"
                                               [expenseReportGroup]="expenseReportGroup"
                                               (groupUpdated)="search(1)"></expense-report-group-card>
                </ng-container>
            </div>
        </div>
    </div>

    <payment-options *ngIf="invoiceForm$" [payment-request-infos]="invoiceForm$ | async"></payment-options>
</div>
