<div class="home-component center-container">
    <h2 class="grid-x home-card-title">
        {{'home.media' | translate}}
    </h2>

    <div class="home-card-content">
        <div class="grid-y center-container-card home-content-center">
            <div class="grid-x large-up-1 xlarge-up-3 align-middle align-justify grid-margin-x grid-padding-x home-content-center center-container-card-width">
                <indicator class="cell indicator-vrt" [img]="'passActivated'">
                    <ng-container value>{{mediaData?.activate}}
                    </ng-container>
                </indicator>
                <indicator class="cell indicator-vrt" [img]="'passOrders'">
                    <ng-container value>{{mediaData?.order}}
                    </ng-container>
                </indicator>
                <indicator class="cell indicator-vrt" [img]="'passDisabled'">
                    <ng-container value>{{mediaData?.disabled}}
                    </ng-container>
                </indicator>
            </div>
        </div>
        <div class="grid-x align-right align-middle moreDetail">
            <a>{{'home.details' |translate}}</a>
            <i class="moreDetail-arrow fas fa-arrow-right"></i>
        </div>
    </div>
</div>
