<div class="grid-y offer-selection-detail margin-0 text-left">
    <h5 class="offer-block-title"
         [innerHTML]="'register.subscription.pack.detail' | translate:{subscriptionPack : title}"></h5>
        <div class="cell offer-block-list" *ngFor="let info of infoItems"
            [hidden]="!info || (info.trim()) === ''"
            [innerHTML]="info | tariffPricingInfo"></div>
    <div class="cell space-top-16" *ngIf="termsAndConditionsI18nCode; else alternateContent;">
        <label class="checkbox align-middle">
            <input type="checkbox" name="{{subscriptionPackId}}" ngModel required #subscriptionPackCgu="ngModel">
            <span class="custom-checkbox"></span>
            <span class="label-choice">
                {{'register.subscription.cgau.read' | translate}}
                <a class="font-size-16" target="_blank"
                   href="{{getCGUUrl( )}}">{{'register.subscription.cgau.cgu' | translate}}</a>
            </span>
            <span class="cell error note"
                  [hidden]="!shouldDisplayCGURequired()">
                {{'register.subscription.cgau.required' | translate}}
            </span>
        </label>
    </div>

    <ng-template #alternateContent>
        <ng-content></ng-content>
    </ng-template>
</div>
