<div class="collapseContainer">
    <div (click)="toggleCollapsed()">
        <ng-content select="[fixed]"></ng-content>
    </div>
    <div [hidden]="isCollapsed">
        <ng-content select="[collapsible]"></ng-content>
    </div>
    <div class="cell text-right align-middle moreDetail" [class.hidden]="!isCollapsed"
         (click)="toggleCollapsed()">
        <a>{{'stations.moreDetails' | translate}}</a>
        <i class="collapseContainer-icon fa fa-arrow-down"></i>
    </div>
    <div class="cell text-right align-middle moreDetail" [class.hidden]="isCollapsed"
         (click)="toggleCollapsed()">
        <a>{{'stations.lessDetails' | translate}}</a>
        <i class="collapseContainer-icon moreDetail-arrow fa fa-arrow-up"></i>
    </div>
</div>

