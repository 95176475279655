import moment from 'moment-timezone';

export class AssumedVehiclePlanningDto {
    id?: string;
    foAccountRef?: string;
    energy: number;
    schedules: ScheduleDto[] = [];
}

export class ScheduleDto {
    from: moment.Moment;
}
