import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TariffDto} from 'lib-front';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'indemnity-card',
    templateUrl: './indemnity-card.component.html',
    styleUrls: ['./indemnity-card.component.scss']
})
export class IndemnityCardComponent {

    @Input()
    public indemnity: TariffDto;

    @Input()
    public selected: boolean;

    indemnityWasUpdated(indemnity: TariffDto): boolean {
        return TariffDto.wasUpdated(indemnity);
    }
}
