import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FrontPersonPortalContextService, FrontSessionBusinessService} from 'lib-front';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {FrontEndService} from '../../../../services/frontEnd.service';

@Injectable()
export class InvoicesGuard implements CanActivate {
    constructor(private readonly frontEndService: FrontEndService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly router: Router,
        private readonly personPortalContextService: FrontPersonPortalContextService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig),
                tap(fleetConfig => {
                    if (!fleetConfig.showInvoiceSection) {
                        this.router.navigate(['/home']);
                    }
                }),
                switchMap(() => this.personPortalContextService.isStationManager()),
                tap(isStationManager => {
                    if (isStationManager) {
                        this.router.navigate([
                            state.url.replace('invoices', 'finances/expenses')
                        ]);
                    }
                }),
                map(_ => true));
    }
}
