import {Component, Input, OnInit} from '@angular/core';
import {
    FrontEndFleetConfig,
    FrontInvoiceBusinessService,
    FrontInvoiceHttpService,
    InvoiceHomeStat,
    InvoiceWrapper,
    User
} from 'lib-front';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap, tap} from 'rxjs/operators';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {noop, of} from 'rxjs';

@Component({
    selector: 'home-invoice',
    templateUrl: './home-invoice.component.html',
    styleUrls: ['./home-invoice.component.scss'],
    host: {'class': 'cell auto home-card'}

})
export class HomeInvoiceComponent implements OnInit  {
    public readonly EXPENSES_URL = '/main/finances/expenses';
    public readonly INVOICES_URL = '/main/invoices';

    @Input()
    public refundManager: boolean;

    @Input()
    public isStationManager: boolean;

    public user: User;
    public invoice: InvoiceWrapper;
    public invoiceHomeStat: InvoiceHomeStat;
    public fleetConfig: FrontEndFleetConfig;

    constructor(private invoiceBusiness: FrontInvoiceBusinessService,
        route: ActivatedRoute,
        invoiceHttpService: FrontInvoiceHttpService,
        private readonly frontEndService: FrontEndService,
        private router: Router) {
        route.data.pipe(
            tap(data => this.user = data.user.user),
            switchMap(() => invoiceHttpService.findUnpaidInvoice(this.user._id)),
            switchMap(invoices => {
                if (invoices.length > 0) {
                    this.invoice = invoices[0];
                    return of(noop());
                } else {
                    return invoiceHttpService.findInvoiceHomeStat(this.user._id);
                }
            })
        ).subscribe(homeStat => {
            if (homeStat) {
                this.invoiceHomeStat = homeStat;
            }
        });
    }

    ngOnInit(): void {
        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => this.fleetConfig = fleetConfig);
    }

    public canPay(invoice) {
        return this.fleetConfig != null
            && this.fleetConfig.showPaymentSection
            && this.invoiceBusiness.canPay(invoice);
    }

    public redirectToExpenseReportPage(): void {
        this.router.navigate(['/main/expenseReport']);
    }

    public getInvoicesUrl(): string {
        return this.isStationManager ? this.EXPENSES_URL : this.INVOICES_URL;
    }
}
