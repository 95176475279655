import {Component, Input, ViewChild} from '@angular/core';
import {
    AvailabilityType,
    FrontStationHttpService,
    InfraStatus,
    StationDto,
    switchTap,
    UserChangeAvailabilityRequest,
    UserRemoteAction,
    UserRemoteActionRequest,
    UserResetRequest
} from 'lib-front';
import {finalize} from 'rxjs/operators';
import {noop, Observable, of} from 'rxjs';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../services/utils/notification.service';
import {ReportIncidentModalResponse} from '../station-report-incident-popup/station-report-incident-popup.component';
import {AbstractHasRoleActionComponent} from '../has-role-action/abstract-has-role-action.component';

@Component({
    selector: 'station-remote-action',
    templateUrl: './station-remote-action.component.html',
    styleUrls: ['./station-remote-action.component.scss']
})
export class StationRemoteAction extends AbstractHasRoleActionComponent {
    UserRemoteAction = UserRemoteAction;

    @ViewChild('incidentReportPopup') incidentReportPopup;
    @Input() station: StationDto;
    @Input() hasStationWriteRole: boolean;
    public InfraStatus = InfraStatus;
    public waitingForAction: boolean;

    constructor(private readonly stationHttpService: FrontStationHttpService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        protected readonly notificationService: NotificationService) {
        super(notificationService);
    }

    sendAction(action: UserRemoteAction): void {
        this.doActionIfHasRole(
            () => {
                this.waitingForAction = true;
                this.confirmAction(action).pipe(
                    switchTap(confirmed => confirmed ? this.putAction(action) : of(noop())),
                    finalize(() => this.waitingForAction = false)
                ).subscribe((confirmed) => {
                    if (confirmed) {
                        this.notifySuccess(action);
                    }
                },
                (error) => this.notifyError(error.error.labelKey, action));
            },
            this.hasStationWriteRole
        );
    }

    createIncident(modalResponse: ReportIncidentModalResponse): void {
        this.waitingForAction = true;
        if (modalResponse) {
            if (modalResponse.checkbox) {
                this.putAction(UserRemoteAction.CHANGE_AVAILABILITY).subscribe();
            }
            this.station.reason = modalResponse.reason;
            this.stationHttpService.updateStation(this.station).subscribe();
            this.notificationService.warning('stations.edit.incidentPopup.success');
            this.waitingForAction = false;
            this.incidentReportPopup.close();
        } else {
            this.notificationService.warning('stations.edit.incidentPopup.error');
            this.waitingForAction = false;
            this.incidentReportPopup.close();
        }
    }

    private notifySuccess(action: UserRemoteAction): void {
        switch (action) {
            case UserRemoteAction.CHANGE_AVAILABILITY:
                return this.notificationService
                    .success('stations.edit.servicingPopup.' + this.computeWantedAvailabilityStatus() + '.success',
                        {visualId: this.station.visualId});
            case UserRemoteAction.RESET:
                return this.notificationService.success('stations.edit.restartPopup.success',
                    {visualId: this.station.visualId});
        }
    }

    private notifyError(labelKey: string, action: UserRemoteAction): void {
        switch (action) {
            case UserRemoteAction.CHANGE_AVAILABILITY:
                labelKey = `${labelKey}.${this.computeWantedAvailabilityStatus()}`;
        }
        this.notificationService.error(labelKey, {visualId: this.station.visualId});
    }

    /**
     * Ask user confirmation and return a boolean as Observable.
     * If the boolean is true, the request should be send
     */
    private confirmAction(action: UserRemoteAction): Observable<boolean> {
        switch (action) {
            case UserRemoteAction.RESET:
                return this.alertService.confirm(
                    this.translateService.instant('stations.edit.restartPopup.text',
                        {station: this.station.visualId, chargeArea: this.station.chargeAreaName}),
                    this.translateService.instant('stations.edit.restartPopup.title')
                );
            case UserRemoteAction.CHANGE_AVAILABILITY:
                const newStatus: AvailabilityType = this.computeWantedAvailabilityStatus();
                return this.alertService.confirm(
                    this.translateService.instant('stations.edit.servicingPopup.' + newStatus + '.text',
                        {station: this.station.visualId, chargeArea: this.station.chargeAreaName}),
                    this.translateService.instant('stations.edit.servicingPopup.' + newStatus + '.title')
                );
        }
    }

    private putAction(action: UserRemoteAction): Observable<void> {
        const userRemoteActionRequest = this.fromUserRemoteAction(action);
        return this.stationHttpService.sendAction(this.station.id, userRemoteActionRequest);
    }

    private fromUserRemoteAction(userRemoteAction: UserRemoteAction): UserRemoteActionRequest {
        switch (userRemoteAction) {
            case UserRemoteAction.RESET:
                return new UserResetRequest();
            case UserRemoteAction.CHANGE_AVAILABILITY:
                return new UserChangeAvailabilityRequest(this.computeWantedAvailabilityStatus());
            default:
                throw new Error(`${userRemoteAction} is not implemented yet`);
        }
    }

    private computeWantedAvailabilityStatus() {
        return this.station.infraStatus === InfraStatus.SERVICING ?
            AvailabilityType.OPERATIVE : AvailabilityType.INOPERATIVE;
    }
}
