import {AfterViewInit, Component, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {
    RGPDWorkflowAcceptanceRequestDto,
    RGPDWorkflowHttpService,
    RGPDWorkflowSubscriberAcceptanceDto,
    RGPDWorkflowSubscriberAcceptanceStatus
} from 'lib-front';
import {Subscription} from 'rxjs';
import {IziviaPopupComponent} from '../../../../../components/izivia-popup/izivia-popup.component';
import {NotificationService} from '../../../../../services/utils/notification.service';

@Component({
    selector: 'expense-report-rgpd-acceptance',
    templateUrl: './expense-report-rgpd-acceptance.component.html',
    styleUrls: ['./expense-report-rgpd-acceptance.component.scss']
})
export class ExpenseReportRgpdAcceptanceComponent implements OnDestroy, AfterViewInit {

    @ViewChild('acceptancePopup') acceptancePopup: IziviaPopupComponent;

    RGPDWorkflowSubscriberAcceptanceStatus = RGPDWorkflowSubscriberAcceptanceStatus;

    subscriberAcceptance: RGPDWorkflowSubscriberAcceptanceDto;

    // By default a ../ relative redirect is done by the component but we can use a listener
    // to control what to do after accept
    @Output() accepted = new EventEmitter<any>();

    private routerSubscription: Subscription;

    constructor(private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly rgpdWorkflowService: RGPDWorkflowHttpService) {
    }

    ngAfterViewInit(): void {
        this.routerSubscription = this.activatedRoute.queryParamMap.pipe(
            map(paramMap => paramMap.get('workflowRef')),
            filter(workflowRef => !!workflowRef),
            switchMap(workflowRef => this.rgpdWorkflowService.getSubscriberAcceptanceByWorkflowId(workflowRef)),
            tap(subscriberAcceptance => this.subscriberAcceptance = subscriberAcceptance)
        ).subscribe(
            () => this.handleAcceptanceResult(),
            () => this.handleAcceptanceResult()
        );
    }

    ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
    }

    handleAcceptanceResult(): void {
        if (!this.subscriberAcceptance) {
            this.goBack();
        }

        switch (this.subscriberAcceptance.subscriberAcceptanceStatus) {
            case RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTED:
                this.goBack();
                break;
            case RGPDWorkflowSubscriberAcceptanceStatus.ACCEPTANCE_REQUIRED:
            case RGPDWorkflowSubscriberAcceptanceStatus.NOT_READY_FOR_ACCEPTANCE:
                this.acceptancePopup.open();
                break;
        }
    }

    private goBack() {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute, replaceUrl: true}).then();
    }

    acceptRGPDDocument(): void {
        const acceptanceRequest = this.buildAcceptanceRequestFromSubscriberAcceptance(this.subscriberAcceptance);
        this.rgpdWorkflowService.acceptRGPDWorkflow(this.subscriberAcceptance.workflowRef, acceptanceRequest).subscribe(
            () => {
                if (this.accepted.observers.length > 0) {
                    this.accepted.emit({});
                } else {
                    this.goBack();
                }

                this.acceptancePopup.close();
            },
            error => this.notificationService.error('expenseReport.rgpd.popup.error')
        );
    }

    private buildAcceptanceRequestFromSubscriberAcceptance(subscriberAcceptance: RGPDWorkflowSubscriberAcceptanceDto): RGPDWorkflowAcceptanceRequestDto {
        return {
            '@type': subscriberAcceptance['@type'],
            workflowRef: subscriberAcceptance.workflowRef
        };
    }
}
