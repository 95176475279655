import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment-timezone';
import {
    CHARGING_PROGRAM_DURATION, ChargingProgramError, FLEET_CALENDAR_EVENT_DATE_FORMAT,
    Estimate,
    FrontVehiclePlanningService,
    SocEventType,
    SocRequirementsRequest,
    SocRecurringRequirementsRequest,
    ActiveWeekDays,
    ChargingProgram
} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {SocUtils} from '../socUtils';
import RRule from 'rrule';
import {TitleCasePipe} from '@angular/common';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'fleet-charging-program',
    templateUrl: './fleet-charging-program.component.html',
    styleUrls: ['./fleet-charging-program.component.scss'],
    providers: [TitleCasePipe]
})
export class FleetChargingProgramComponent implements OnInit {
    @ViewChild('showDeleteProgramPopup') showDeleteProgramPopup;
    @ViewChild('showUpdateProgramPopup') showUpdateProgramPopup;

    @Input() vehicleId: string;
    @Input() vehicleTimeZone: string;
    @Input() vehicleLicenseNumber: string;
    @Input() socEventType: SocEventType;
    @Input() existingEventDates: string[];
    @Input() applicationDay: moment.Moment;
    @Input() applicationTime: moment.Moment;
    @Input() estimates: Estimate[];
    @Input() desiredChargeIndex: number;
    @Input() recurringFrom: moment.Moment;
    @Input() recurringTo: moment.Moment;
    @Input() daysOfWeek: string[];
    @Input() chargingProgramId: string;
    @Input() chargingProgramDate: moment.Moment;

    @Output() readonly closeAddProgramPopup: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly updateCalendar: EventEmitter<void> = new EventEmitter<void>();

    SocEventType = SocEventType;
    minSelectableDate: moment.Moment;
    chargingProgramErrors = [];
    ChargingProgramError = ChargingProgramError;
    activeWeekDays: ActiveWeekDays[] = [];
    passedEvent: boolean;
    chargingProgram: ChargingProgram;
    isUpdating: boolean;
    addingProgram: boolean;

    constructor(private readonly translateService: TranslateService,
        private readonly vehiclePlanningService: FrontVehiclePlanningService,
        private readonly notificationService: NotificationService,
        @Inject(CHARGING_PROGRAM_DURATION) public readonly chargingProgramDuration: number,
        @Inject(FLEET_CALENDAR_EVENT_DATE_FORMAT) public readonly dateTimeFormat: string,
        @Inject(LOCALE_ID) public readonly locale: string,
        private readonly titleCasePipe: TitleCasePipe
    ) { }

    ngOnInit(): void {
        this.minSelectableDate = moment().tz(this.vehicleTimeZone);
        this.initActiveWeekDays();

        if (this.chargingProgramId) {
            this.chargingProgram = {
                id: this.chargingProgramId,
                date: this.chargingProgramDate.format('DD/MM/YYYY'),
                time: this.chargingProgramDate.format('HH:mm')
            };

            this.passedEvent = this.chargingProgramDate.isBefore(moment().tz(this.vehicleTimeZone));
            this.daysOfWeek.forEach((dayOfWeek) => {
                this.activeWeekDays
                    .filter(activeWeekDay => activeWeekDay.key === dayOfWeek)
                    .map(activeWeekDay => activeWeekDay.active = true);
            });
        }
    }

    private initActiveWeekDays(): void {
        /**
         * weekdays in locale 'en' for the i18n translation and the key for active week days
         * result example : { key: Sunday, label: Dimanche, active: false }
         */
        const weekDaysEn: ActiveWeekDays[] = [];
        moment.locale('en');
        moment.weekdays(true).forEach(weekDay => {
            weekDaysEn.push({ key: weekDay,
                label: this.translateService.instant('fleet.program.application.days.' + weekDay.toLowerCase()),
                active: false
            });
        });

        /**
         * weekdays in user locale to keep the days order (for example, locale 'fr' weekdays begin with lundi)
         * result example : { key: MONDAY, label: Lundi, active: false }
         */
        moment.locale(this.locale.toString());
        moment.weekdays(true).forEach(activeWeekDay => {
            weekDaysEn.forEach(weekDayEn => {
                if (weekDayEn.label === this.titleCasePipe.transform(activeWeekDay)) {
                    this.activeWeekDays.push({ key: weekDayEn.key.toUpperCase(),
                        label: weekDayEn.label,
                        active: weekDayEn.active
                    });
                }
            });
        });
    }

    public cancelProgram(): void {
        this.closeAddProgramPopup.emit();
    }

    public addProgram(): void {
        this.isUpdating = false;
        if (this.socEventType === SocEventType.ONE_TIME) {
            this.addOneTimeProgram();
        } else {
            this.addRecurringProgram();
        }
    }

    private addOneTimeProgram(): void {
        const applicationDate = moment.tz(this.applicationDay.format('YYYY-MM-DD'), this.vehicleTimeZone)
            .add(this.applicationTime.hour(), 'hours')
            .add(this.applicationTime.minute(), 'minutes');

        if (!this.isValidDay(applicationDate)) {
            return;
        }

        const startISO = applicationDate.toISOString();
        // endIso is useful for V2G as we have to define a time range
        const addedHour = applicationDate.clone().add(this.chargingProgramDuration, 'seconds');
        const endISO = moment.min(moment(this.applicationDay).endOf('day'), addedHour).toISOString();

        const socOneTimeRequest: SocRequirementsRequest = {
            name: this.vehicleLicenseNumber,
            period: {
                from: startISO,
                to: endISO
            },
            soc: this.estimates[this.desiredChargeIndex].soc
        };

        this.addingProgram = true;
        this.vehiclePlanningService.postVehicleChargeOneTime(this.vehicleId, socOneTimeRequest)
            .pipe(
                finalize(() => this.addingProgram = false)
            )
            .subscribe(
                (socResponseOneTime) => {
                    this.updateCalendar.emit();
                    this.closeAddProgramPopup.emit();
                    if (this.isUpdating) {
                        this.showUpdateProgramPopup.close();
                        this.notificationService.success('fleet.program.update.oneTime.notificationSuccess');
                    } else {
                        this.notificationService.success('fleet.program.add.oneTime.notificationSuccess');
                    }
                },
                () => {
                    this.notificationService.error('fleet.program.add.oneTime.notificationError');
                });
    }

    private addRecurringProgram(): void {
        const recurringDateFrom = SocUtils.createDateFromApplicationDayAndSocTime(
            moment.tz(this.recurringFrom, this.vehicleTimeZone),
            this.applicationTime.format('HH:mm'),
            this.vehicleTimeZone);

        const recurringDateTo = SocUtils.createDateFromApplicationDayAndSocTime(
            moment.tz(this.recurringTo, this.vehicleTimeZone),
            this.applicationTime.format('HH:mm'),
            this.vehicleTimeZone);

        const startISO = recurringDateFrom.format('HH:mm:[00]');
        // endIso is useful for V2G as we have to define a time range
        const addedHour = recurringDateFrom.clone().add(this.chargingProgramDuration, 'seconds');
        const endISO = moment.min(moment(recurringDateFrom).tz(this.vehicleTimeZone).endOf('day'), addedHour).format('HH:mm:[00]');

        const socRecurringRequest: SocRecurringRequirementsRequest = {
            name: this.vehicleLicenseNumber,
            daysOfWeek: this.daysOfWeek,
            period: {
                from: startISO,
                to: endISO
            },
            recurrence: {
                from: recurringDateFrom.toISOString(),
                to: recurringDateTo.toISOString()
            },
            soc: this.estimates[this.desiredChargeIndex].soc
        };

        const rule: RRule = new RRule(SocUtils.getRecurringRuleOptions(socRecurringRequest, this.vehicleTimeZone));
        if (rule.all().some(date => !this.isValidDay(moment.tz(date, this.vehicleTimeZone)))) {
            return;
        }

        this.addingProgram = true;
        this.vehiclePlanningService.postVehicleChargeRecurring(this.vehicleId, socRecurringRequest)
            .pipe(
                finalize(() => this.addingProgram = false)
            )
            .subscribe((socResponseRecurring) => {
                this.updateCalendar.emit();
                this.closeAddProgramPopup.emit();
                if (this.isUpdating) {
                    this.showUpdateProgramPopup.close();
                    this.notificationService.success('fleet.program.update.recurring.notificationSuccess');
                } else {
                    this.notificationService.success('fleet.program.add.recurring.notificationSuccess');
                }
            },
            () => {
                this.notificationService.error('fleet.program.add.recurring.notificationError');
            });
    }

    private isValidDay(applicationDate: moment.Moment): boolean {
        if (this.isAddingProgramBeforeNow(applicationDate)) {
            this.chargingProgramErrors.push(ChargingProgramError.BEFORE_ERROR);
            return false;
        }

        if (this.eventAlreadyExists(applicationDate)) {
            this.chargingProgramErrors.push(ChargingProgramError.ALREADY_EXISTS_ERROR);
            return false;
        }
        return true;
    }

    private isAddingProgramBeforeNow(applicationDate: moment.Moment): boolean {
        const now = moment().tz(this.vehicleTimeZone);
        return applicationDate.isBefore(now);
    }

    private eventAlreadyExists(applicationDate: moment.Moment): boolean {
        return this.existingEventDates.includes(applicationDate.format(this.dateTimeFormat));
    }

    public hasError(error: ChargingProgramError): boolean {
        return this.chargingProgramErrors.includes(error);
    }

    /**
     * The socRangeEstimates provided by Dreev correspond to an array of 13 values (it begins at 35% and goes to 100% by step of 5%)
     */
    public sliderMax(): number {
        return (this.estimates && this.estimates.length - 1 < 0) ? 13 : this.estimates.length - 1;
    }

    public onCheckboxChange(day: ActiveWeekDays, event): void {
        day.active = !day.active;
        if (event.target.checked) {
            this.daysOfWeek.push(day.key);
        } else {
            this.daysOfWeek.splice(this.daysOfWeek.indexOf(day.key), 1);
        }
    }

    public canAddCharge(): boolean {
        return this.socEventType === SocEventType.ONE_TIME ||
            (this.socEventType === SocEventType.RECURRING &&
                this.daysOfWeek.length !== 0 &&
                this.recurringFrom.isBefore(this.recurringTo));
    }

    public updateCalendarAfterDelete(): void {
        this.showDeleteProgramPopup.close();
        this.closeAddProgramPopup.emit();
        this.updateCalendar.emit();
    }

    public updateProgram(chargingProgramId: string): void {
        if (this.socEventType === SocEventType.ONE_TIME) {
            this.updateOneTimeProgram(this.vehicleId, chargingProgramId);
        } else {
            this.updateRecurringProgram(this.vehicleId, chargingProgramId);
        }
    }

    private updateOneTimeProgram(vehicleId: string, chargingProgramId: string): void {
        this.isUpdating = true;
        this.vehiclePlanningService.deleteRequirementOneTime(vehicleId, chargingProgramId).subscribe((data) => {
            this.addOneTimeProgram();
        },
        () => {
            this.notificationService.error('fleet.program.update.oneTime.notificationError');
        });
    }

    private updateRecurringProgram(vehicleId: string, chargingProgramId: string): void {
        this.isUpdating = true;
        this.vehiclePlanningService.deleteRequirementRecurring(vehicleId, chargingProgramId).subscribe((data) => {
            this.addRecurringProgram();
        },
        () => {
            this.notificationService.error('fleet.program.update.recurring.notificationError');
        });
    }

    public cancelUpdate(): void {
        this.showUpdateProgramPopup.close();
        this.chargingProgramErrors = [];
    }
}
