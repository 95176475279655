<section class="grid-y style-guide-block">
    <h1 class="style-guide-title">Boutons</h1>

    <div class="cell style-guide-section-content">
        <div class="style-guide-note">
            <h1 class="style-guide-note-title">Note</h1>
            <p>Les boutons doivent toujours ressembler à ceux-ci grâce à une combinaison de classes de
                modificateurs détaillées ci-dessous.
                Vous retrouverez les styles des boutons sur la feuille de style ce trouvant dans <strong>styles/components/buttons.scss</strong>
            </p>
        </div>

        <h4 class="style-guide-sub-title">Bases styles</h4>
        <!-- START / base buttons -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button">button</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                          <button class="button">button</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button secondary">secondary</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                            <button class="button secondary">secondary</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button color">color</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                           <button class="button color">color</button>
                    </code>
                </pre>
            </div>
        </div>
        <!-- END / base buttons -->


        <h4 class="style-guide-sub-title">Boutons options</h4>

        <!-- START / options button -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button disabled">disabled</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
               <pre class="language-html">
                    <code iziviaPrism class="language-html">
                           <button class="button disabled">disabled</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button go">go</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                             <button class="button go">go</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button validate">validate</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                        <button class="button validate">validate</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button previous">previous</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
              <pre class="language-html">
                    <code iziviaPrism class="language-html">
                      <button class="button previous">previous</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button next">next</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                      <button class="button next">next</button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button round eye"><i class="fas fa-eye"></i></button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
               <pre class="language-html">
                    <code iziviaPrism class="language-html">
                        <button class="button round eye"><i class="fas fa-eye"></i></button>
                    </code>
                </pre>
            </div>
        </div>

        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button primary"
                                [class.loading]="true">
                            Rechercher
                        </button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
               <pre class="language-html">
                    <code iziviaPrism class="language-html">
                        <button class="button round eye"><i class="fas fa-eye"></i></button>
                    </code>
                </pre>
            </div>
        </div>
        <!-- END / options button -->


        <h4 class="style-guide-sub-title">Formes boutons</h4>

        <!-- START / shape button -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button round">round</button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                       <button class="button round">round</button>
                    </code>
                </pre>
            </div>
        </div>
        <!-- END / shape button -->


        <h4 class="style-guide-sub-title">Edit buttons</h4>

        <!-- START / shape button -->
        <div class="style-guide-element">
            <div class="style-guide-preview-container">
                <div class="style-guide-preview-content">
                    <div class="text-center">
                        <button class="button icon-btn left"><i class="fas fa-pencil-alt"></i></button>
                        <button class="button icon-btn right"><i class="fas fa-pencil-alt"></i></button>
                    </div>
                </div>
            </div>
            <div class="style-guide-code">
                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                        <button class="button icon-btn left"><i class="fas fa-pencil-alt"></i></button>
                    </code>
                </pre>

                <pre class="language-html">
                    <code iziviaPrism class="language-html">
                        <button class="button icon-btn right"><i class="fas fa-pencil-alt"></i></button>
                    </code>
                </pre>
            </div>
        </div>
        <!-- END / shape button -->
    </div>
</section>
