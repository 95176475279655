import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FrontOfferHttpService, HOST_THIRD_PARTY_ID, OptionSubscription, SubscriptionPack} from 'lib-front';
import {ModeSubscriptionOption, OptionForm} from '../../../../../../components/subscription-options/subscription-options.component';
import {OptionsResult} from '../update-subscription.component';

@Component({
    selector: 'update-subscription-options',
    templateUrl: './update-subscription-options.component.html',
    styleUrls: ['./update-subscription-options.component.scss'],
})
export class UpdateSubscriptionOptionsComponent {
    ModeSubscriptionOption = ModeSubscriptionOption;
    @Input() offerId: string;
    @Input() mode: 'update' | 'new' = 'update';

    @Input() set subscriptionPack(subscriptionPack) {
        this._subscriptionPack = subscriptionPack;

        if (this._subscriptionPack) {
            if (this.mode === 'update') {
                const options = [];
                if (this.offerId !== null) {
                    this.offerHttpService.findOptionsKeepByOffer(this.thirdPartyId, this.offerId, this._subscriptionPack._id)
                        .subscribe((data) => {
                            this.setOptions(data);
                        });
                } else {
                    this.offerHttpService
                        .findOptionsBySubscriptionPack(this.thirdPartyId, this._subscriptionPack._id).subscribe(data => {
                            this.setOptions(data);
                        });
                }
            } else {
                this.retainOptions = [];
                this.terminationOptions = [];

                this.initializeNewTotal();
                this.emitResultForSummary();
            }
        }
    }

    @Input() set currentOptions(currentOptions) {
        this._currentOptions = currentOptions;
        this.calculateCurrentTotal();
        this.emitResultForSummary();
    }

    @Output() optionsToSubscribeEvent: EventEmitter<Array<OptionForm>> = new EventEmitter<Array<OptionForm>>();

    @Output() optionsResultEvent: EventEmitter<OptionsResult> = new EventEmitter<OptionsResult>();

    _subscriptionPack: SubscriptionPack;
    _currentOptions: Array<OptionSubscription>;
    currencyUnit: string;
    newOptions: Array<OptionForm>;
    optionsToSubscribe: Array<OptionForm>;
    terminationOptions: Array<OptionSubscription> = [];
    retainOptions: Array<OptionSubscription> = [];
    currentTotalHours: number = 0;
    currentTotalPrice: number = 0;
    totalHours: number = 0;
    totalPrice: number = 0;

    constructor(private offerHttpService: FrontOfferHttpService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {
    }

    private initializeNewTotal() {
        if (this.retainOptions) {
            this.retainOptions.forEach(option => {
                this.totalHours += option.subscription.freeTime * option.number;
                this.totalPrice += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
            });
        }
    }

    calculateCurrentTotal() {
        if (this._currentOptions) {
            this._currentOptions.forEach(option => {
                this.currentTotalHours += option.subscription.freeTime * option.number;
                this.currentTotalPrice += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
            });
        }
    }

    setOptions(data: Array<any>) {
        this.retainOptions = this._currentOptions
            .filter(option => data.some(elem => elem._id === option.subscription.subscriptionDefRef));
        this.terminationOptions = this._currentOptions.filter(option => this.retainOptions.indexOf(option) < 0);
        this.initializeNewTotal();
        this.emitResultForSummary();
    }

    calculateTotal() {
        this.totalHours = 0;
        this.totalPrice = 0;

        if (this.retainOptions) {
            this.retainOptions.forEach(option => {
                this.totalHours += option.subscription.freeTime * option.number;
                this.totalPrice += option.subscription.subscriptionDef.fixAmountWithVAT * option.number;
            });
        }

        if (this.newOptions) {
            this.optionsToSubscribe = this.newOptions.filter(option => option && option.number && option.number > 0);
            this.optionsToSubscribe.forEach(option => {
                this.totalHours += option.subscription.freeTime * option.number;
                this.totalPrice += option.subscription.fixAmountWithVAT * option.number;
            });
        } else {
            this.optionsToSubscribe = [];
        }
        this.optionsToSubscribeEvent.emit(this.optionsToSubscribe);
        this.emitResultForSummary();
    }

    setCurrencyUnit(currency: string): void {
        if (!!currency) {
            this.currencyUnit = currency;
        }
    }

    emitResultForSummary(): void {
        this.optionsResultEvent.emit(
            new OptionsResult(
                this.currentTotalHours,
                this.currentTotalPrice,
                this.totalHours,
                this.totalPrice,
                this._currentOptions)
        );
    }
}
