import {Component, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {IziviaPopupComponent} from '../izivia-popup/izivia-popup.component';
import {finalize, map, tap} from 'rxjs/operators';
import {noop, Observable, timer} from 'rxjs';
import {
    FILE_API_PATH,
    FleetUser,
    FrontAccountHttpService,
    FrontEndHttpService,
    FrontSessionBusinessService,
    HOST_THIRD_PARTY_ID
} from 'lib-front';

@Component({
    selector: 'accept-terms-and-conditions-popup',
    templateUrl: './accept-terms-and-conditions-popup.component.html',
    styleUrls: ['./accept-terms-and-conditions-popup.component.scss']
})
export class AcceptTermsAndConditionsPopupComponent {
    @ViewChild('popup') popup: IziviaPopupComponent;
    @Output() accepted = new EventEmitter<void>();
    @Output() refused = new EventEmitter<void>();
    public termsAndConditionsOfUseAccepted: boolean;
    public termsUrl: string;

    public acceptingTerms: boolean;

    private userId: string;


    constructor(@Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string,
        private readonly frontEndHttpService: FrontEndHttpService,
        private readonly accountHttpService: FrontAccountHttpService) {
    }

    public askConfirmTermsAndConditions(userId: string): void {
        this.termsAndConditionsOfUseAccepted = false;
        this.userId = userId;
        this.fetchTermsUrl()
            .subscribe(
                () => {
                    this.popup.open();
                }
            );
    }


    public validTermsAndConditionsOfUse(accepted: boolean) {
        if (accepted && this.termsAndConditionsOfUseAccepted) {
            this.acceptingTerms = true;
            this.accountHttpService.acceptTermsAndConditionsOfUse(this.userId)
                .pipe(
                    finalize(() => this.acceptingTerms = false)
                )
                .subscribe(() => {
                    this.popup.close();
                    this.accepted.next();
                },
                () => {
                    this.refused.next();
                });
        } else {
            this.popup.close();
            this.refused.next();
        }
    }

    private fetchTermsUrl(): Observable<void>{
        return this.frontEndHttpService.findFrontInfo(this.thirdPartyId).pipe(tap(result => {
            if (!!result.fleetConfig?.termsAndConditionsI18nCode) {
                this.termsUrl = FILE_API_PATH + result.fleetConfig.termsAndConditionsI18nCode;
            }
        }), map(() => noop()));
    }
}
