import {Component, OnInit, ViewChild} from '@angular/core';
import {
    buildRole,
    ChargeAreaDto,
    CurrentSubscriberRolesService,
    FrontChargeAreaHttpService,
    FrontFoAccountHttpService,
    FrontStationHttpService,
    FrontTariffHttpService,
    StationDto,
    SubscriberRoleLevel,
    SubscriberRoleType,
    SubscriptionDef,
    TariffDetailDto,
    TariffDto,
    TariffHistory,
    TariffModificationStatus,
    TariffStatus
} from 'lib-front';
import {filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {cloneDeep, remove} from 'lodash-es';
import {
    AbstractHasRoleActionComponent
} from '../../../../../components/has-role-action/abstract-has-role-action.component';
import moment, {Moment} from 'moment';
import {IziviaPopupComponent} from '../../../../../components/izivia-popup/izivia-popup.component';
import {FleetMomentToStringDatePipe} from '../../../../../pipes/fleetMomentToStringDate.pipe';

@Component({
    selector: 'tariff-configuration',
    templateUrl: './tariff-configuration.component.html',
    styleUrls: ['./tariff-configuration.component.scss'],
})
export class TariffConfigurationComponent extends AbstractHasRoleActionComponent implements OnInit {
    TariffModificationStatus = TariffModificationStatus;

    @ViewChild('tariffModificationPopup') tariffModificationPopup: IziviaPopupComponent;

    public tariffs: TariffDto[];
    public stationWithQrCodeRefs: string[];
    public selectedTariff: TariffDetailDto;
    public nbOfSubscribers: number;
    public shownTariff: TariffDetailDto;
    public applicationDate: Moment | undefined = undefined;
    public minApplicationDate: Moment | undefined;
    public tariffToDelete: TariffDto;
    public isLoading: boolean;
    public isSaving: boolean = false;
    public editMode: boolean;
    public chargeAreas: ChargeAreaDto[];
    public tariffTradeAgreementOtherEmo2CcSubscriptionDefs: SubscriptionDef[];
    private readonly baseUrl: string = '/main/configuration/tariff';
    private tariffDetailSubscription: Subscription;
    deleting: boolean;
    private hasChargingPointWriteRole: boolean;
    private stations: StationDto[];
    selectedTariffStations: StationDto[];

    public constructor(
        readonly tariffHttpService: FrontTariffHttpService,
        private readonly route: ActivatedRoute,
        private readonly location: Location,
        private readonly datePipe: FleetMomentToStringDatePipe,
        protected readonly notificationService: NotificationService,
        private readonly stationHttpService: FrontStationHttpService,
        private readonly chargeAreaHttpService: FrontChargeAreaHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly foAccountService: FrontFoAccountHttpService) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.stationHttpService.searchStations()
            .subscribe(stations => {
                this.stations = stations;
                this.loadTariffs();
            });
        this.chargeAreaHttpService.fetchChargeAreas()
            .subscribe(chargeAreas => this.chargeAreas = chargeAreas);
        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.WRITE)
        ).subscribe(hasRole => this.hasChargingPointWriteRole = hasRole);
        this.foAccountService.getCurrentFoAccountId()
            .pipe(
                switchMap(id => this.foAccountService.findTariffTradeAgreementOtherEmo2CcSubscriptionDefsByFoAccountRef(id))
            )
            .subscribe(subscriptionDefs => this.tariffTradeAgreementOtherEmo2CcSubscriptionDefs = subscriptionDefs);
    }

    loadTariffs() {
        this.selectedTariff = null;
        this.shownTariff = null;
        this.tariffHttpService.fetchTariffs({})
            .pipe(
                tap(tariffs => this.tariffs = tariffs),
                filter(tariffs => !!tariffs && tariffs.length > 0),
                switchMap(tariffs => this.chooseDefaultSelectedTariff(tariffs)),
                map(tariff => this.selectTariff(tariff, this.shouldChangeUrl()))
            )
            .subscribe();
    }

    newTariff() {
        this.doActionIfHasRole(
            () => {
                if (!!this.tariffDetailSubscription && !this.tariffDetailSubscription.closed) {
                    this.tariffDetailSubscription.unsubscribe();
                }
                this.stationWithQrCodeRefs = [].concat(
                    ...(this.tariffs || [])
                        .filter(tariff => tariff.applyByQRCode)
                        .map(tariff => tariff.linkedStationRefs)
                        .filter(linkedStationRefs => linkedStationRefs && linkedStationRefs.length > 0)
                );
                this.selectedTariff = new TariffDetailDto();
                this.shownTariff = this.selectedTariff;
                this.editMode = true;
            },
            this.hasChargingPointWriteRole
        );
    }

    removeTariff(deletedTariff: TariffDto) {
        this.doActionIfHasRole(
            () => {
                remove(this.tariffs, tariff => tariff.id === deletedTariff.id);
                if (this.selectedTariff?.id === deletedTariff.id) {
                    this.selectTariff(this.tariffs?.length > 0 ? this.tariffs[0] : undefined, true);
                }
            },
            this.hasChargingPointWriteRole
        );
    }

    createTariff(newTariff: TariffDetailDto) {
        this.doActionIfHasRole(
            () => {
                if (!newTariff.modification) {
                    this.tariffs.unshift(newTariff);
                }
                this.selectedTariff = newTariff;
                this.shownTariff = this.selectedTariff;
            },
            this.hasChargingPointWriteRole
        );
    }

    updateTariffs(updatedTariff: TariffDetailDto) {
        this.doActionIfHasRole(
            () => {
                if (!updatedTariff.status || updatedTariff.status === TariffStatus.ACTIVE) {
                    const updatedTariffIndex = this.tariffs.findIndex(tariff => tariff.id === updatedTariff.id);
                    // if updatedTariffIndex == -1 then it's new tariff else it's updated
                    if (updatedTariffIndex >= 0) {
                        this.tariffs[updatedTariffIndex] = updatedTariff;
                    } else {
                        this.tariffs.unshift(updatedTariff);
                    }
                    this.selectedTariff = updatedTariff;
                } else {
                    this.selectedTariff.modification.newTariff = updatedTariff;
                }

                this.shownTariff = updatedTariff;
            },
            this.hasChargingPointWriteRole
        );

    }

    openDatePickerPopup(applicationDate: Moment) {
        this.applicationDate = applicationDate;
        this.minApplicationDate = this.applicationDate;
        this.tariffModificationPopup.open();
    }

    cloneTariffAndSetEditMode(applicationDate: Moment) {
        this.doActionIfHasRole(
            () => {
                let newTariff = cloneDeep(this.selectedTariff);
                newTariff.status = TariffStatus.UPCOMING;
                newTariff.id = null;
                newTariff.history = new TariffHistory(this.selectedTariff.id);
                this.applicationDate = applicationDate;
                this.setShownTariffAndSetEditMode(newTariff, true);
            },
            this.hasChargingPointWriteRole
        );
    }

    setShownTariffAndSetEditMode(tariff: TariffDetailDto, editMode: boolean) {
        this.shownTariff = tariff;
        this.editMode = editMode;
    }

    selectTariff(tariff: TariffDto | undefined, changeUrl: boolean): void {
        if (changeUrl) {
            this.changeUrl(tariff);
        }
        if (!!this.selectedTariff && this.selectedTariff.id === tariff?.id) {
            return;
        }
        if (!!this.tariffDetailSubscription && !this.tariffDetailSubscription.closed) {
            this.tariffDetailSubscription.unsubscribe();
        }
        this.isLoading = true;
        this.selectedTariff = undefined;
        if (!!tariff) {
            this.tariffDetailSubscription = this.tariffHttpService.findTariffById(tariff.id)
                .pipe(
                    finalize(() => this.isLoading = false)
                )
                .subscribe(
                    tariffDetail => this.fillSelectedTariffWithDetail(tariffDetail),
                    () => {
                        this.notificationService.error('config.tariff.fetchError');
                        this.isLoading = false;
                    });
        }
    }

    private fillSelectedTariffWithDetail(tariffDetail: TariffDetailDto) {
        this.selectedTariff = tariffDetail;
        this.selectedTariffStations = this.stations.filter(station => this.selectedTariff.linkedStationRefs.includes(station.id));
        this.shownTariff = this.selectedTariff;
        this.applicationDate = this.selectedTariff?.modification?.applicationDate;
        this.tariffHttpService.countTariffSubscribers(tariffDetail.id).subscribe((nbOfSubscribers) => {
            this.nbOfSubscribers = nbOfSubscribers;
        });
    }

    editModeChange() {
        this.changeUrl(this.checkSelectedTariff());
    }

    checkSelectedTariff(): TariffDto {
        if (!this.selectedTariff || !this.selectedTariff.id) {
            if (this.tariffs?.length > 0) {
                this.selectTariff(this.tariffs[0], true);
                return this.tariffs[0];
            } else {
                this.selectedTariff = undefined;
                this.shownTariff = undefined;
            }
        }
        return this.selectedTariff;
    }

    private chooseDefaultSelectedTariff(tariffs: TariffDto[]): Observable<TariffDto> {
        return this.route.url.pipe(
            map(urlSegment => {
                let selectedTariff: TariffDto;
                if (!!urlSegment && !!urlSegment[1]?.path) {
                    selectedTariff = tariffs.find(tariff => tariff.id === urlSegment[1].path);
                    if (!!urlSegment[2]?.path) {
                        this.editMode = true;
                    }
                }
                if (!selectedTariff) {
                    selectedTariff = tariffs[0];
                }
                return selectedTariff;
            })
        );
    }

    /**
     * return true if this config component it's displayed
     */
    private shouldChangeUrl(): boolean {
        return !!this.route.snapshot.url && this.route.snapshot.url[0]?.path === 'tariff';
    }

    private changeUrl(tariff: TariffDto | undefined): void {
        if (!tariff) {
            this.location.go(this.baseUrl);
        } else if (this.editMode) {
            this.location.go(`${this.baseUrl}/${tariff.id}/edit`);
        } else {
            this.location.go(`${this.baseUrl}/${tariff.id}`);
        }
    }

    toggleEditMode(): void {
        this.editMode = !this.editMode;
        this.shownTariff = this.selectedTariff;
        this.editModeChange();
    }

    trackByTariffId(index: number, tariffDto: TariffDto) {
        return tariffDto?.id ?? index;
    }

    deleteActiveTariff(tariff: TariffDto) {
        this.tariffHttpService.deleteTariff(tariff.id, this.applicationDate)
            .pipe(
                finalize(() => this.deleting = false)
            )
            .subscribe(
                () => {
                    this.notificationService.success('config.tariff.deletePopup.success', {
                        tariffName: tariff.name,
                        deletionDate: this.datePipe.transform(this.applicationDate)
                    });
                    this.loadTariffs();
                },
                () => this.notificationService.error('config.tariff.deletePopup.error', {tariffName: tariff.name})
            );
    }

    deleteTariffModification(tariff: TariffDetailDto) {
        this.tariffHttpService.deleteTariffModification(tariff.history.originTariffId)
            .pipe(
                finalize(() => this.deleting = false)
            )
            .subscribe(
                () => {
                    this.notificationService.success('config.tariff.deletePopup.instantSuccess', {tariffName: tariff.name});
                    this.selectedTariff.modification = null;
                    this.updateTariffs(this.selectedTariff);
                },
                () => this.notificationService.error('config.tariff.deletePopup.error', {tariffName: tariff.name})
            );
    }

    setDeletingAndOpenDatePickerPopup(tariff: TariffDto) {
        this.deleting = true;
        this.tariffToDelete = tariff;
        this.tariffHttpService.countTariffSubscribers(tariff.id).pipe(
            map(nbOfSubscribers => this.applicationDate = this.computeApplicationDateLimitFromSubscriberCount(this.tariffToDelete, nbOfSubscribers)),
            tap(minApplicationDate => this.applicationDate = minApplicationDate),
        ).subscribe(
            () => this.tariffModificationPopup.open()
        );
    }

    // todo: it is not acceptable to use the same component and logic for modification and deletion
    dateSelected(applicationDate: Moment) {
        if (this.deleting) {
            this.deleteActiveTariff(this.tariffToDelete);
        } else {
            this.applicationDate = applicationDate;
            this.cloneTariffAndSetEditMode(applicationDate);
        }
        this.tariffModificationPopup.close();
    }

    closePopup() {
        this.deleting = false;
        this.tariffModificationPopup.close();
    }

    private computeApplicationDateLimitFromSubscriberCount(tariff: TariffDto, nbSubscribers: number): Moment {
        let minDays = tariff.applyByQRCode || nbSubscribers === 0 ? 1 : 31;
        return moment().add(minDays, 'day').startOf('day');
        ;
    };

    getApplicationDate() {
        return this.selectedTariff?.modification?.applicationDate || this.applicationDate;
    }

    canShowTariffModificationDropdown(tariff: TariffDto): boolean {
        return this.selectedTariff && tariff.id === this.selectedTariff.id && this.selectedTariff?.modification && !this.selectedTariff?.deletion;
    }

    private shouldDisplayModificationWarning() {
        return (this.shownTariff && this.shownTariff.status === TariffStatus.UPCOMING) || (this.selectedTariff?.modification && !this.selectedTariff?.deletion);
    }

    selectedTariffWillBeModifiedWithoutSubscribers(): boolean {
        return this.shouldDisplayModificationWarning() && (this.selectedTariff?.applyByQRCode || this.nbOfSubscribers === 0);
    }

    selectedTariffWillBeModifiedWithSubscribers(): boolean {
        return this.shouldDisplayModificationWarning() && !this.selectedTariff?.applyByQRCode && this.nbOfSubscribers > 0;
    }

    selectedTariffWillBeDeleted(): boolean {
        return !!this.selectedTariff?.deletion;
    }

    getDeletionDate(): Moment {
        return this.selectedTariff?.deletion?.deletionDate || this.applicationDate;
    }
}
