import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {FrontPersonPortalContextService, FrontSessionBusinessService, FrontVehicleHttpService} from 'lib-front';
import {map, tap} from 'rxjs/operators';
import {FleetVehicleUtils} from '../components/fleetVehicleUtils';

@Injectable()
export class DreevProviderGuard implements CanActivate {

    private readonly LICENSE_NUMBER = 'licenseNumber';

    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!route.queryParams[this.LICENSE_NUMBER]) {
            return of(false);
        }
        return forkJoin([
            this.personPortalContextService.isRigeUser(),
            this.vehicleHttpService.findVehicleByLicenseNumber(route.queryParams[this.LICENSE_NUMBER])
        ]).pipe(
            map(([isRigeUser, vehicle]) =>
                isRigeUser && FleetVehicleUtils.isVehicleCreatedInDreevProvider(vehicle)),
            tap(canActivate => {
                if (!canActivate) {
                    this.router.navigate(['/home']).then();
                }
            })
        );
    };
}
