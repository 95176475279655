import {NgModule} from '@angular/core';
import {ChargeConsumptionsComponent} from './chargeConsumptions.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../../components/components.module';
import {ResolverModule} from '../../../../resolver/resolver.module';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ChargeReadRoleGuard} from './charge-read-role.guard';

@NgModule({
    declarations: [
        ChargeConsumptionsComponent,

    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ComponentsModule,
        ResolverModule,
        PipesModule
    ],
    providers: [
        ChargeReadRoleGuard
    ]

})
export class ChargeConsumptionsModule {

}
