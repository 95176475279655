import {Component, OnInit} from '@angular/core';
import {
    FinancialReportDto,
    FinancialReportSelfCareHttpService,
    FinancialReportSelfCareSearchRequest,
    InvoiceStatusEnum,
    PaginatedSearchRequest,
    PaginatedSelfCareFinancialReportSearchRequest, PersonFleetPortalContext,
} from 'lib-front';
import {finalize, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

type SearchRequestUpdate = PaginatedSearchRequest | FinancialReportSelfCareSearchRequest;

@Component({
    selector: 'income-history',
    templateUrl: './income-history.component.html',
    styleUrls: ['./income-history.component.scss']
})
export class IncomeHistoryComponent implements OnInit {

    public resultsPerPage: number = 10;
    public numberOfPages: number;

    private readonly searchRequest: PaginatedSelfCareFinancialReportSearchRequest;
    public financialReports: FinancialReportDto[];
    public searching = false;
    public isSelfcareInvoiceActivated = false;

    currentPage: number = 1;


    constructor(private financialReportService: FinancialReportSelfCareHttpService, private route: ActivatedRoute) {

        this.searchRequest = {
            from: null,
            to: null,
            invoiceNumber: null,
            limit: this.resultsPerPage,
            skip: 0
        };
    }

    ngOnInit(): void {
        this.searchFinancialReports();
        this.isSelfcareInvoiceActivated = this.route.snapshot.data && this.route.snapshot.data.userContext
            && ((this.route.snapshot.data.userContext) as PersonFleetPortalContext).selfcareInvoiceActivated;
    }


    public exportResults() {
        this.financialReportService.exportFinancialResults(this.searchRequest);
    }

    updateFinancialReportSearchRequest($event) {
        const searchRequestUpdate: SearchRequestUpdate = $event;

        // if not page change, goto first page
        if (!searchRequestUpdate.hasOwnProperty('skip')) {
            this.currentPage = 1;
            this.searchRequest.skip = 0;
        }

        Object.keys(searchRequestUpdate)
            .forEach(key => this.searchRequest[key] = searchRequestUpdate[key]);

        this.searchFinancialReports();
    }

    private searchFinancialReports(searchRequest: PaginatedSelfCareFinancialReportSearchRequest = this.searchRequest) {
        this.searching = true;
        this.financialReportService.searchSelfCareFinancialReports(searchRequest).pipe(
            tap(searchResults => this.numberOfPages = Math.ceil(searchResults.total / this.resultsPerPage)),
            tap(searchResults => this.financialReports = searchResults.financialReports),
            finalize(() => this.searching = false)
        ).subscribe();
    }

    changePage(pageNumber: number) {
        this.currentPage = pageNumber;
        this.updateFinancialReportSearchRequest(
            {
                skip: (pageNumber - 1) * this.resultsPerPage,
                limit: this.resultsPerPage
            }
        );
    }
}
