<h5 class="cell small-24 secondary block-title" [hidden]="smallTitle">
    {{'register.subscription.options.title' | translate}}
</h5>
<h5 class="space-top-24" *ngIf="smallTitle">
    {{'options.title.name' | translate:{name: options[0].subscription?.subscriptionDef?.subscriptionDefParentName | fleetSubstring:'Client "':'"'} }}
</h5>

<ul>
    <li *ngIf="smallTitle && options?.length">
        <h5 class="cell subscription-option-block-price-value space-top-16">
            {{totalPriceWithVAT | currency:'EUR':options[0].subscription?.subscriptionDef?.currencyUnit || '' }}
        </h5>
    </li>

    <div class="grid-y align-center" [hidden]="!(options && options.length)">
        <li>
            <h5 class="cell shrink space-left-32"
                *ngIf="!smallTitle && options?.length && options[0].subscription?.subscriptionDef?.subscriptionDefParentName">
                {{'options.freeHours.name' | translate:{name: options[0].subscription?.subscriptionDef?.subscriptionDefParentName | fleetSubstring:'Client "':'"'} }}
            </h5>
            <div class="cell shrink" [hidden]="!showBar">
                <h6 class="space-top-8">{{'subscription.pack.option.conso.conso' | translate:{
                    consume:
                        progressBarOptions?.consume | fleetDuration:{seconds: false, minutes: true},
                    consumeMax: progressBarOptions?.consumeMax | fleetDuration:{seconds: false, minutes: true}
                } }}</h6>
                <subscription-progress-bar [isBarAlert]="progressBarOptions?.isBarAlert"
                                           [consumePercent]="progressBarOptions?.consumePercent"
                                           [resetDate]="resetDate"
                                           [sumCarryOverTime]="sumCarryOverTime">
                </subscription-progress-bar>
            </div>


            <div class="grid-x grid-margin-x align-bottom space-top-8 fix-overflow-hidden">
                <div class="cell small-24" [hidden]="!showOptionTiles">
                    <subscription-options-blocks [smallBackground]="smallBackground"
                                                 [options]="options">
                    </subscription-options-blocks>
                </div>
                <div class="cell small-24" *ngIf="showTotal">
                    <div class="grid-x cell auto align-center subscription-result-block small-space">
                        <div class="cell shrink subscription-result-block-item time">
                            {{progressBarOptions?.totalHours | fleetDuration:{seconds: false, minutes: true} }}
                        </div>
                        <div class="grid-x shrink subscription-result-block-item price">
                    <span
                        *ngIf="options?.length">{{progressBarOptions?.totalPrice | currency:'EUR': options[0].subscription?.subscriptionDef?.currencyUnit || ''}}</span>
                            <span class="force-font-bold ttcText align-self-bottom">TTC</span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
</ul>


<div class="grid-y align-center space-all-8" [hidden]="options && options.length">
    <div class="info cell auto common-block force-space-bottom-0">
        {{'subscription.options.no' | translate}}
    </div>
</div>
