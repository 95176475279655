import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Moment} from 'moment';
import {PageFleetImportDto} from 'lib-front';

@Injectable()
export class FleetImportHttpService {
    constructor(private http: HttpClient) {
    }

    getParamsForCriteria(criteria: FleetImportCriteria): HttpParams {
        let params = new HttpParams();
        if (criteria.startDate) {
            params = params.set('from', criteria.startDate.startOf('day').toISOString());
        }
        if (criteria.endDate) {
            params = params.set('to', criteria.endDate.endOf('day').toISOString());
        }
        if (criteria.status) {
            params = params.set('status', criteria.status);
        }

        params = params.set('skip', String(criteria.skip));
        params = params.set('limit', String(criteria.limit));

        return params;
    }

    public fetchImports(foAccountId: string, criteria: FleetImportCriteria): Observable<PageFleetImportDto> {
        const params = this.getParamsForCriteria(criteria);
        return this.http.get<PageFleetImportDto>(`/foAccounts/${foAccountId}/imports`, {params: params});
    }

    public fetchInProgressImportIds(foAccountId: string): Observable<string[]> {
        return this.http.get<string[]>(`/foAccounts/${foAccountId}/imports/status/progressing/ids`);
    }
}

export class FleetImportCriteria {
    startDate?: Moment;
    endDate?: Moment;
    status: FleetImportStatus | '';
    skip: number;
    limit: number;

    public updateLimitForNext() {
        this.skip += this.limit;
    }

    public updateLimitForBack() {
        this.skip -= this.limit;
    }
}

export enum FleetImportStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    ERROR = 'ERROR',
    ENDED = 'ENDED'
}
