import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TariffDetailDto, TariffModificationStatus} from 'lib-front';
import moment, {Moment} from 'moment';

@Component({
    selector: 'tariff-modification-dropdown',
    templateUrl: './tariff-modification-dropdown.component.html',
    styleUrls: ['./tariff-modification-dropdown.component.scss']
})
export class TariffModificationDropdownComponent {

    TariffModificationStatus = TariffModificationStatus;

    private _tariff: TariffDetailDto;
    @Input() selectedTariffId: string;
    untilDate: Moment;

    @Input()
    set tariff(value: TariffDetailDto) {
        if (value) {
            this.untilDate = moment(value.modification.applicationDate).subtract(1, 'days');
        }
        this._tariff = value;
    }

    get tariff(): TariffDetailDto {
        return this._tariff;
    }

    @Output() selectTariff = new EventEmitter<[TariffDetailDto, boolean]>();
    @Output() deleteTariff = new EventEmitter<TariffDetailDto>();

    constructor() { }

    editNewTariff() {
        this.selectTariff.emit([this._tariff.modification.newTariff, true]);
    }

    select(tariff: TariffDetailDto) {
        this.selectTariff.emit([tariff, false]);
    }

    deleteNewTariff() {
        this.deleteTariff.emit(this._tariff.modification.newTariff);
    }
}
