import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {FrontEndHttpService, FrontEndInfo, FrontSessionBusinessService, HOST_THIRD_PARTY_ID} from 'lib-front';
import {catchError, first, switchMap} from 'rxjs/operators';

@Injectable()
export class FrontEndService {
    private readonly frontEndInfoSubject: BehaviorSubject<FrontEndInfo> = new BehaviorSubject(undefined);

    constructor(private readonly frontFrontHttpService: FrontEndHttpService,
        @Inject(HOST_THIRD_PARTY_ID) private thirdPartyId: string,
        private readonly sessionBusinessService: FrontSessionBusinessService) {
        this.sessionBusinessService.connectedUser$
            .pipe(
                first(user => user !== undefined),
                switchMap(user => this.frontFrontHttpService.findFrontInfo(this.thirdPartyId)),
                catchError(() => of(null))
            ).subscribe(frontEndInfo => this.frontEndInfoSubject.next(frontEndInfo || null));
    }

    get currentFrontEndInfo$(): Observable<FrontEndInfo> {
        return this.frontEndInfoSubject.asObservable()
            .pipe(first(frontEndInfo => frontEndInfo !== undefined));
    }
}
