import {Directive} from '@angular/core';
import {FOAccountWrapper, FrontMediaOrderHttpService, hasDeliveryChanged, PersonAddress} from 'lib-front';
import {AlertService} from '../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../services/utils/notification.service';

@Directive()
export abstract class AbstractMediaOrderDeliveryChangeWorkflowComponent {

    public orderingMedia: boolean;

    protected constructor(protected readonly notificationService: NotificationService,
        protected readonly mediaOrderHttpService: FrontMediaOrderHttpService,
        protected readonly alertService: AlertService,
        protected readonly translateService: TranslateService) {}

    protected checkDeliveryChanged(foAccount: FOAccountWrapper, initialFoAccountDelivery: PersonAddress): void {
        // If initialFoAccountDelivery is null we don't have to check that delivery change because
        // user didn't have a delivery until now
        if(!!initialFoAccountDelivery && hasDeliveryChanged(foAccount.delivery, initialFoAccountDelivery)){
            this.checkHasMediaOrdersWithEditableDelivery(foAccount);
        } else {
            this.mediaOrderDeliveryCommonCallback(foAccount);
        }
    }

    protected checkHasMediaOrdersWithEditableDelivery(foAccount: FOAccountWrapper): void {
        this.mediaOrderHttpService.findMediaOrdersWithEditableDeliveryByFoAccount(foAccount.foAccountRef).subscribe(
            mediaOrders => mediaOrders.length > 0 ?
                this.displayMediaOrderDeliverySynchronizationConfirm(foAccount) :
                this.mediaOrderDeliveryCommonCallback(foAccount),
            () => {
                this.notificationService.error('config.errorMediaOrderDeliveryState');
                this.orderingMedia = false;
            }
        );
    }

    protected displayMediaOrderDeliverySynchronizationConfirm(foAccount: FOAccountWrapper): void {
        this.alertService.confirm(
            this.translateService.instant('config.account.deliveryChange.text'),
            this.translateService.instant('config.account.deliveryChange.title'),
            this.translateService.instant('config.account.deliveryChange.yes'),
            this.translateService.instant('config.account.deliveryChange.no')
        ).subscribe((response: boolean) => response ?
            this.synchronizeMediaOrdersDeliveries(foAccount) :
            this.mediaOrderDeliveryCommonCallback(foAccount)
        );
    }

    protected synchronizeMediaOrdersDeliveries(foAccount: FOAccountWrapper): void {
        this.mediaOrderHttpService.synchronizeMediaOrdersDeliveryWithFoAddress(foAccount.foAccountRef).subscribe(
            () => this.mediaOrderDeliveryCommonCallback(foAccount),
            () => {
                this.notificationService.error('config.errorMediaOrderDeliverySync');
                this.orderingMedia = false;
            }
        );
    }

    protected abstract mediaOrderDeliveryCommonCallback(foAccount: FOAccountWrapper): void;
}
