import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportingView} from './reporting.view';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {ReportingPanelComponent} from './reporting-panel/reporting-panel.component';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ReportingGuard} from './reporting.guard';
import {ReportingPanelGuard} from './reporting-panel.guard';

@NgModule({
    declarations: [
        ReportingView,
        ReportingPanelComponent,
    ],
    exports: [
        ReportingView
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        PipesModule,
    ],
    providers: [
        ReportingGuard,
        ReportingPanelGuard
    ]
})
export class ReportingModule {
}
