 <div class="grid-x cell auto wrapper">
    <div class="columns cell auto">
        <h5 class="station-title">{{'stations.edit.station.title' | translate}}</h5>
        <div class="border-none infraStatus space-bottom-16" [ngClass]="'infraStatus-'+station?.infraStatus">
            <div class="cell station-status" [ngClass]="'background-'+ station?.infraStatus">
                <i class="fas fa-circle station-status-color"
                   [ngClass]="'color-'+ station?.infraStatus"></i>
                {{'stations.status.' + station?.infraStatus | translate}}
            </div>
        </div>

        <h5>
            <b>{{'stations.visualId' | translate : {visualId: station?.visualId} }}</b>,
            <b *ngIf="!!station?.alias">{{'stations.alias' | translate : {alias: station?.alias} }} ,</b>
        </h5>
        <h6>{{location | fleetLocation:LocationType.ROUTE}},
            {{location | fleetLocation:LocationType.CITY}}</h6>
    </div>

    <div class="columns shrink wrapper-buttons">
        <button type="button" class="button round color" [hidden]="selected">
            <i class="fas fa-chevron-down"></i>
        </button>
        <button type="button" class="button round color" [hidden]="!selected">
            <i class="fas fa-chevron-up"></i>
        </button>
    </div>
</div>
