import {Pipe, PipeTransform} from '@angular/core';
import {MetaTotalConsumption, MetaTotalConsumptionWithoutVat} from '../domain/meta/total/metaTotalConsumption';

@Pipe({
    name: 'mergeTotalConsumptionsByType'
})
/**
 * Transform array of MetaTotalConsumption to an array of MetaTotalConsumptionWithoutVat.
 * This basically iterates over input parameter and merge every item with the same type ignoring the vat.
 *
 * Without this pipe, you may want to display this kind of table:
 * ```
 * Détail            | Total HT | TVA | Total TTC
 * Total des charges | 10€      | 20% | 12€
 * Total des charges | 5€       | 10% | 5,5€
 * Total des lavages | 2€       | 20% | 2,4€
 * ```
 *
 * WITH this pipe, you may want to display this kind of table:
 * ```
 * Détail            | Total HT | Total TTC
 * Total des charges | 15€      |  17,5€
 * Total des lavages | 2€       |  2,4€
 * ```
 *
 */
export class FleetMergeTotalConsumptionsByTypePipe implements PipeTransform {

    constructor() {}

    transform(totalConsumptions: MetaTotalConsumption[]): MetaTotalConsumptionWithoutVat[] {
        if (!totalConsumptions) {
            return [];
        }

        const totalConsumptionsWithoutVat: MetaTotalConsumptionWithoutVat[] = [];

        totalConsumptions.forEach(totalConsumption => {
            const totalConsumptionWithUniqueType: MetaTotalConsumptionWithoutVat = totalConsumptionsWithoutVat
                .find(target => target.type === totalConsumption.type);

            if (totalConsumptionWithUniqueType) {
                // merge
                totalConsumptionWithUniqueType.priceWithoutVat = totalConsumptionWithUniqueType.priceWithoutVat + totalConsumption.priceWithoutVat;
                totalConsumptionWithUniqueType.priceWithVat = totalConsumptionWithUniqueType.priceWithVat + totalConsumption.priceWithVat;
            } else {
                // add
                totalConsumptionsWithoutVat.push(totalConsumption);
            }
        });

        return totalConsumptionsWithoutVat;
    }
}
