import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[iziviaNumeric]'
})
export class NumericDirective {
    private _decimals: number;

    private regex: RegExp;

    @Input()
    set decimals(value: number) {
        this.regex = new RegExp('^\\d*[\\.,]?\\d{0,' + value + '}$', 'g');
        this._decimals = value;
    }

    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        const currentValue: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;

        const next: string = this.computeNextValue(currentValue, position, event.key);
        if (!this.nextStringIsValid(next)) {
            event.preventDefault();
        }

    }

    @HostListener('keyup', ['$event'])
    onKeyUp() {
        this.el.nativeElement.value = this.el.nativeElement.value.replace(',', '.');
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');

        const formattedValue = pastedText.replace(',', '.');

        if (this.nextStringIsValid(formattedValue)) {
            this.el.nativeElement.value = formattedValue;
        }

        event.preventDefault();
    }

    private nextStringIsValid(next: string) {
        return !next || String(next).match(this.regex);
    }

    private computeNextValue(currentValue: string, position, key: string) {
        const beforeCursor = currentValue.slice(0, position);
        const afterCursor = currentValue.slice(position);
        return [
            beforeCursor,
            key === 'Decimal' ? '.' : key,
            afterCursor
        ].join('');
    }
}
