import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionForm} from '../../../../../../components/subscription-options/subscription-options.component';
import {
    ConsumptionPrice,
    FrontPackSubscriptionHttpService,
    FrontSessionBusinessService,
    MediaOrder,
    Offer,
    SubscriptionMode,
    SubscriptionPack,
    SubscriptionPriceRequestDto,
    User
} from 'lib-front';
import {MediaOrderDto} from '../../../../../../domain/mediaOrderDto';
import {PromoTokenWithSubscriptionPack} from '../../../../../../domain/promoTokenWithSubscriptionPack';
import {SubscriptionPackOrTokenWrapper} from '../../../../../../domain/subscriptionPackOrTokenWrapper';

@Component({
    selector: 'new-subscription-summary',
    templateUrl: './new-subscription-summary.component.html',
    styleUrls: ['./new-subscription-summary.component.scss'],
    host: {'class': 'cell grid-y'}
})
export class NewSubscriptionSummaryComponent implements OnInit {
    @Input('media-order') set mediaOrderInput(mediaOrder: MediaOrderDto) {
        this.mediaOrder = mediaOrder;
        this.computeTotalPrice();

        if (!this.mediaOrder || !this.mediaOrder.emoMediaOrderPrice || !this.mediaOrder.emoMediaOrderPrice.emoRef) {
            return;
        }
    }

    @Input() offer: Offer;
    @Input() subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper;
    @Input() options: Array<OptionForm>;

    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() validateSummary: EventEmitter<void> = new EventEmitter<void>();
    @Output() promoTokenChange: EventEmitter<string> = new EventEmitter<string>();

    get bonusPenaltyWithVats(): ConsumptionPrice[] {
        return this._bonusPenaltyWithVats;
    }

    set bonusPenaltyWithVats(value: ConsumptionPrice[]) {
        this._bonusPenaltyWithVats = value;
        this.computeTotalPrice();
    }

    mediaOrder: MediaOrderDto;
    user: User;
    promoToken: PromoTokenWithSubscriptionPack;
    totalMediaPrice: number;

    SubscriptionMode = SubscriptionMode;


    private _bonusPenaltyWithVats: ConsumptionPrice[] = [];

    constructor(private frontSessionBusinessService: FrontSessionBusinessService,
        private packSubscriptionService: FrontPackSubscriptionHttpService) {
    }

    ngOnInit(): void {
        this.frontSessionBusinessService.currentConnectedUser$
            .subscribe(connectedUser => this.user = connectedUser.user);
    }

    public stepBack() {
        this.back.emit();
    }

    public get totalOptions(): number {
        if (!this.options || !this.options.length) {
            return 0;
        }

        return this.options
            .map(optionChoose => (optionChoose.number || 0) * optionChoose.subscription.fixAmountWithVAT)
            .reduce((previousValue, currentValue) => previousValue + currentValue);
    }

    public get subscriptionPacks(): SubscriptionPack[] {
        return this.subscriptionPackOrTokenWrapper?.subscriptionPacks;
    }

    public get subscriptionPackRefs(): string[] {
        return this.subscriptionPackOrTokenWrapper?.subscriptionPacks.map(pack => pack._id);
    }

    onValidateSummary() {
        this.validateSummary.emit();
    }

    public showSubscriptionPacksTotalPrice(): boolean {
        if (!this.subscriptionPacks || !this.subscriptionPacks.length) {
            return true;
        }
        return this.subscriptionPacks?.length > 1
            || this.subscriptionPacks[0].overloadedFixPriceLabel === null;
    }

    public get subscriptionPacksTotalPrice(): number {
        if (!this.subscriptionPacks || !this.subscriptionPacks.length) {
            return 0;
        }
        return this.subscriptionPacks
            .reduce((price, subscriptionPack) => price + subscriptionPack.fixPriceWithVat, 0);
    }

    calculatePrice() {
        const subscriptionPriceRequestDto: SubscriptionPriceRequestDto = {
            promoSubscription: this.promoToken.token,
            subscriptionMode: SubscriptionMode.client,
            mediaOrders: [],
            subscriptionPackRef: null,
            packToken: null
        };

        if (this.mediaOrder?.quantity) {
            const mediaOrder: MediaOrder = {
                quantity: this.mediaOrder.quantity,
                mediaFamilyRef: this.mediaOrder.mediaCanOrderDto.mediaFamily.id,
                subscriptionPackRef: this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id
            };
            subscriptionPriceRequestDto.mediaOrders.push(mediaOrder);
        }

        if (this.subscriptionPackOrTokenWrapper.isSubscriptionPackMode()) {
            subscriptionPriceRequestDto.subscriptionPackRef =
                this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id;
        } else {
            subscriptionPriceRequestDto.packToken = this.subscriptionPackOrTokenWrapper.tokenValue;
        }

        this.packSubscriptionService.computePrices(subscriptionPriceRequestDto).subscribe(subscriptionPrices => {
            this.bonusPenaltyWithVats = subscriptionPrices.bonusPenaltyWithVats;
        });
    }

    public promoTokenChanged(promoToken: PromoTokenWithSubscriptionPack) {
        this.promoToken = promoToken;

        if (this.promoToken.valid) {
            this.promoTokenChange.emit(promoToken.token);
            this.calculatePrice();
        } else {
            this.promoTokenChange.emit(null);
            this.bonusPenaltyWithVats = [];
        }
    }

    trackBySubscriptionPackId(index: number, subscriptionPack: SubscriptionPack) {
        return subscriptionPack?._id ?? index;
    }

    private computeTotalPrice() {
        const mediaBestPrice: number = this.mediaOrder?.emoMediaOrderPrice?.bestPrice || 0;
        const bonus: number = this.bonusPenaltyWithVats
            .reduce((previousValue, currentValue) => previousValue + currentValue.priceWithVat,
                0);
        this.totalMediaPrice = mediaBestPrice + bonus;

    }
}
