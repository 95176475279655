import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Location, LocationInputMode} from 'lib-front';
@Component({
    selector: 'address-form-wrapper[address]',
    templateUrl: './address-form-wrapper.component.html',
    styleUrls: ['./address-form-wrapper.component.scss']
})
export class AddressFormWrapperComponent {

    @Input()
    set address(address: Location) {
        this._address = address ? address : {};
        if (!this._address.locationInputMode) {
            this._address.locationInputMode = LocationInputMode.AUTOMATIC;
        }
    }

    get address(): Location {
        return this._address;
    }
    @Input() required: boolean;
    @Output() formAddressValidChange: EventEmitter<boolean> = new EventEmitter(false);

    private _address: Location;

    protected readonly LocationInputMode = LocationInputMode;

    onAddressValidityChange($event: boolean) {
        this.formAddressValidChange.next($event);
    }

    changeMode(mode: LocationInputMode) {
        this.resetAddress(mode);
    }

    private resetAddress(mode: LocationInputMode): void {
        this._address.locationInputMode = mode;
        this._address.streetNumber = null;
        this._address.route = null;
        this._address.extra = null;
        this._address.postalCode = null;
        this._address.city = null;
        this._address.countryCode = null;
        this._address.country = null;
    }
}
