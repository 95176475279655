<div class="tableContainer">
    <div *ngIf="loading">
        <div class="app-loader small text-center">
            <span class="spinner small"></span>
        </div>
    </div>

    <table *ngIf="loading || consumptions?.length > 0 else noCharge" [class.loading]="loading">
        <thead>
        <tr>
            <th>{{'consumptions.table.consumptionDate' | translate}}</th>
            <th>{{'consumptions.table.kind' | translate}}</th>
            <th>{{'consumptions.table.reason' | translate}}</th>
            <th>{{'consumptions.table.priceWithoutVat' | translate}}</th>
            <th>{{'consumptions.table.priceWithVat' | translate}}</th>
            <th>{{'consumptions.table.financialReport' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let consumption of consumptions; trackBy: trackByConsumptionId">
            <td>{{consumption.consumptionDate | date:'short'}}</td>
            <td>{{'consumptions.kind.' + consumption.kind | translate}}</td>
            <td *ngIf="consumption.kind === ConsumptionKind.CHARGE">
                {{'consumptions.charge.reason' | translate:
                {
                    startDate: consumption.startDate | date:'short',
                    endDate: consumption.endDate | date:'short'
                } }}
            </td>
            <td *ngIf="consumption.kind === ConsumptionKind.RESERVATION">
                {{consumption.duration | fleetDuration}}
            </td>
            <td *ngIf="consumption.kind !== ConsumptionKind.CHARGE && consumption.kind !== ConsumptionKind.RESERVATION">
                {{consumption.reason}}
            </td>
            <td>{{consumption.priceWithoutVat | currency: 'EUR':currencyUnit}}</td>
            <td>{{consumption.priceWithVat | currency: 'EUR':currencyUnit}}</td>
            <td><a *ngIf="!!consumption.financialReportDownloadLink"
                   [href]="consumption.financialReportDownloadLink"
                   target="_blank">
                <i class="fas fa-download block-title-icon"></i>
            </a></td>
        </tr>
        </tbody>
    </table>
    <ng-template #noCharge>
        <div class="grid-y cell auto align-center align-middle message-empty"
             *ngIf="!loading">
            <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
            <span class="message-empty-label">{{'chargeConsumptions.no.result' | translate}}</span>
        </div>
    </ng-template>

</div>
