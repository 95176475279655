import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'collapsible-card-detail',
    templateUrl: './collapsible-card-detail.component.html',
    styleUrls: ['./collapsible-card-detail.component.scss']
})
export class CollapsibleCardDetailComponent {
    @Input() isCollapsed: boolean = true;
    @Input() colorClass: string;
    @Output() collapse: EventEmitter<void> = new EventEmitter();

    constructor() {
    }

    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
        if (!this.isCollapsed) {
            this.collapse.emit();
        }
    }
}
