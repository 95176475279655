import {SubscriptionPackWithCguDto} from 'lib-front';

/**
 * Token state with subscriptionPacks.
 */
export class PromoTokenWithSubscriptionPack {
    valid: boolean;
    token: string;
    subscriptionPacks: Array<SubscriptionPackWithCguDto>;

    constructor(valid: boolean, token: string, subscriptionPacks: Array<SubscriptionPackWithCguDto>) {
        this.valid = valid;
        this.token = token;
        this.subscriptionPacks = subscriptionPacks;
    }
}
