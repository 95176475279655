import {ConsumptionKind, ConsumptionStatus, IdLabel} from 'lib-front';
import {MetaChargeConsumptionDto} from './metaChargeConsumptionDto';
import {MetaReservationConsumptionDto} from './metaReservationConsumptionDto';
import {MetaOtpConsumptionDto} from './metaOtpConsumptionDto';
import {MetaServiceConsumptionDto} from './metaServiceConsumptionDto';

export interface MetaConsumptionDto {
    consumptionId: string;
    status: ConsumptionStatus;
    kind: ConsumptionKind;
    reason?: string;
    consumptionDate: string;
    consumerName: string;
    priceWithoutVat: number;
    priceWithVat: number;
    currencyUnit?: string;
    invoiceId?: string;
    mediaOwners?: IdLabel[];
}

export function isMetaChargeConsumption(metaConsumption: MetaConsumptionDto): metaConsumption is MetaChargeConsumptionDto {
    return metaConsumption.kind === ConsumptionKind.CHARGE;
}

export function isMetaReservationConsumption(metaConsumption: MetaConsumptionDto): metaConsumption is MetaReservationConsumptionDto {
    return metaConsumption.kind === ConsumptionKind.RESERVATION;
}

export function isMetaOtpConsumption(metaConsumption: MetaConsumptionDto): metaConsumption is MetaOtpConsumptionDto {
    return metaConsumption.kind === ConsumptionKind.OTP;
}

export function isMetaServiceConsumption(metaConsumption: MetaConsumptionDto): metaConsumption is MetaServiceConsumptionDto {
    return metaConsumption.kind === ConsumptionKind.SERVICE;
}
