import {Component, Input} from '@angular/core';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
    @Input() isBarAlert = false;
    @Input() color: 'secondary' | 'green' | 'red' | 'grey' = 'secondary';
    @Input() fillBar = false;
    @Input() showConsume = false;
    @Input('consume') set consumeInput(consume) {
        this.consume = consume;
        this.updatePercent();
    }
    @Input('consumeMax') set consumeMaxInput(consumeMax) {
        this.consumeMax = consumeMax;
        this.updatePercent();
    }

    consumePercent = 0;
    consume = 0;
    consumeMax = 100;

    updatePercent() {
        if (this.consumeMax !== 0) {
            this.consumePercent =  Math.min((this.consume / this.consumeMax) * 100, 100);
        }
    }
}
