import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ReportingTab} from '../../domain/reportingTab';
import {FrontEndHttpService, HOST_THIRD_PARTY_ID, Reporting} from 'lib-front';
import {reportingPanelRoutes} from '../../views/app/main/reporting/reporting.route';
import {filter} from 'rxjs/operators';

@Injectable()
export class ReportingService {

    private reportingTabsSubject: BehaviorSubject<ReportingTab[]> = new BehaviorSubject(undefined);

    constructor(@Inject(HOST_THIRD_PARTY_ID) private thirdPartyId: string,
        private readonly frontFrontHttpService: FrontEndHttpService) {
        this.frontFrontHttpService.findReportingUrls(thirdPartyId)
            .subscribe(reportingUrls => {
                let reportingTabs: ReportingTab[] = [];
                for (const [key, value] of Object.entries(reportingUrls.urlByReporting)) {
                    reportingTabs.push({
                        reporting: key as Reporting,
                        path: reportingPanelRoutes[key].path,
                        iFrameUrl: value,
                        order: reportingPanelRoutes[key].order
                    } as ReportingTab);
                }
                this.reportingTabsSubject.next(reportingTabs.sort((a, b) => a.order - b.order));
            });
    }

    public activeReportingTabs(): Observable<ReportingTab[]> {
        return this.reportingTabsSubject.asObservable().pipe(
            filter(tabs => tabs !== undefined)
        );
    }

}
