import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tariffPricingInfo'
})
export class TariffPricingInfoPipe implements PipeTransform {
    private createLine(content) {
        return '<li>' + content + '</li>';
    }

    private processLevel3(value) {
        let [firstLineContent, ...otherLines] = value.split(' +++ ');

        // Write the content of the level 2 line
        let result= this.createLine(firstLineContent);

        if (otherLines.length > 0) {
            result += '<ul>';

            otherLines.forEach((lineContent) => {
                result += this.createLine(lineContent);
            });

            result += '</ul>';
        }

        return result;
    }

    private processLevel2(value) {
        let [firstLineContent, ...otherLines] = value.split(' ++ ');

        // Write the content of the level 1 line
        let result = this.createLine(firstLineContent);

        if (otherLines.length > 0) {
            result += '<ul>';

            otherLines.forEach((lineContent) => {
                result += this.processLevel3(lineContent);
            });

            result += '</ul>';
        }


        return result;
    }

    private processLevel1(value) {
        let result = '';

        let lines = value.split(' + ');

        if (lines.length > 0) {
            result += '<ul>';

            lines.forEach((lineContent) => {
                result += this.processLevel2(lineContent);
            });

            result += '</ul>';
        }

        return result;
    }

    public transform(value) {
        if (value && value.includes(' + ')) {
            return this.processLevel1(value);
        }

        return value;
    }

}
