import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ConnectedUser, FrontSessionBusinessService} from 'lib-front';
import {Observable} from 'rxjs';

@Injectable()
export class CurrentUserResolver implements Resolve<ConnectedUser> {
    constructor(private readonly sessionBusinessService: FrontSessionBusinessService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ConnectedUser> {
        return this.sessionBusinessService.currentConnectedUser$;
    }
}
