import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FrontPackSubscriptionHttpService, FrontSessionBusinessService} from 'lib-front';
import {Observable} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class SubscriptionsGuard implements CanActivate {
    constructor(private readonly packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sessionBusinessService.currentConnectedUser$
            .pipe(
                map(connectedUser => connectedUser?.user._id),
                switchMap(connectedUserId => this.packSubscriptionHttpService
                    .findPackSubscriptionById(connectedUserId, route.paramMap.get('packSubscriptionId'))),
                catchError(() => this.router.navigate(['/home'])),
                tap(packSubscription => {
                    if (packSubscription == null) {
                        this.router.navigate(['/home']);
                    }
                }),
                map(_ => true)
            );
    }
}
