<div class="form-content" *ngIf="additionalInformation && additionalInformationAnswers"
     [ngSwitch]="additionalInformation.type">
    <div *ngSwitchCase="formElementType.section">
        {{additionalInformation.label}}
    </div>
    <ng-container *ngSwitchCase="formElementType.city">
        <div>
            <label>
                <span class="labelName">
                    {{additionalInformation.label}} <span class="required labelName"
                                                          *ngIf="additionalInformation.mandatory">*</span>
                </span>
                <div class="grid-x small-24">
                    <div class="input-item cell small-8">
                        <label class="inner-space-all-0 inner-space-right-8" [ngClass]="{validate : !!country.valid}">
                        <span class="labelName">{{'informations.billing.country' | translate}} <span class="required"
                                                                                                     *ngIf="additionalInformation.mandatory">*</span></span>
                            <select [(ngModel)]="additionalInformationAnswers['country_' + additionalInformation.label]"
                                    name="'country_' + additionalInformation.label"
                                    #country="ngModel"
                                    [required]="additionalInformation.mandatory">
                                <option *ngFor="let country of countries"
                                        [value]="country.code">{{country.name}}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="input-item cell small-8">
                        <label class="inner-space-all-0 inner-space-right-8"
                               [ngClass]="{validate : !!postalCode.valid, invalid : !postalCode.valid && postalCode?.dirty}">
                            <span class="labelName">{{'additionalInformation.postalCode.title' | translate}} <span
                                class="required" *ngIf="additionalInformation.mandatory">*</span></span>
                            <ng-select [name]="'zipCode_' + additionalInformation.label"
                                       #postalCode="ngModel"
                                       [ngModel]="additionalInformationAnswers['zipCode_' + additionalInformation.label]"
                                       (search)="pushNewPostalCode($event)"
                                       (focus)="focusOn('postalCode')"
                                       (blur)="focusOut('postalCode')"
                                       [required]="additionalInformation.mandatory"
                                       (change)="validPostalCodeSuggestion($event); notifyFieldChange()"
                                       [isOpen]="shouldShowPostalCodeSuggestions()"
                                       [compareWith]="compareAlwaysFalse"
                                       (clear)="clearPostalCodeSuggestions()"
                                       inputmode="decimal">
                                <ng-option *ngFor="let suggestion of postalCodeSuggestions; trackBy: trackByPostalCode"
                                           [value]="suggestion.postalCode"
                                >{{suggestion.postalCode}}</ng-option>
                            </ng-select>
                        </label>
                        <div class="cell note error"
                             [ngClass]="{'hiddenError': !(postalCode.invalid && (postalCode?.touched || postalCode?.dirty))}">
                            {{'additionalInformation.postalCode.error' | translate}}
                        </div>
                    </div>
                    <div class="input-item cell small-8">
                        <label class="inner-space-all-0"
                               [ngClass]="{validate : !!city.valid, invalid : !city.valid && city?.dirty}">
                            <span class="labelName">{{'additionalInformation.city' | translate}}
                                <span class="required labelName" *ngIf="additionalInformation.mandatory">*</span>
                            </span>
                            <ng-select [name]="'city_' + additionalInformation.label"
                                       #city="ngModel"
                                       [ngModel]="additionalInformationAnswers['city_' + additionalInformation.label]"
                                       (search)="pushNewCity($event)"
                                       (focus)="focusOn('city')"
                                       (blur)="focusOut('city')"
                                       [required]="additionalInformation.mandatory"
                                       (change)="validCitySuggestion($event)"
                                       [compareWith]="compareAlwaysFalse"
                                       [isOpen]="shouldShowCitySuggestions()"
                                       (clear)="clearCitySuggestions()"
                                       [maxlength]="(additionalInformation.size || 100).toString()"
                                       autocapitalize="sentences">
                                <ng-option *ngFor="let suggestion of citySuggestions; trackBy: trackByCityName"
                                           [value]="suggestion.name"
                                >{{suggestion.name}}</ng-option>
                            </ng-select>
                        </label>
                    </div>
                </div>
            </label>
        </div>
    </ng-container>

    <div class="input-item " *ngSwitchCase="formElementType.text">
        <label>{{additionalInformation.label}}
            <span class="required labelName" *ngIf="additionalInformation.mandatory">*</span>
            <input [(ngModel)]="additionalInformationAnswers[additionalInformation.label]"
                   (ngModelChange)="notifyFieldChange()"
                   type="text"
                   [name]="additionalInformation.label"
                   [maxlength]="(additionalInformation.size || 100).toString()"
                   [required]="additionalInformation.mandatory"
                   autocapitalize="sentences"/>
        </label>
    </div>

    <div class="input-item " *ngSwitchCase="formElementType.phone">
        <label [ngClass]="{validate : !!phoneNumber.valid, invalid : !phoneNumber.valid && phoneNumber?.dirty}">
            <span class="labelName">
                {{additionalInformation.label}}
                <span class="required labelName"
                      *ngIf="additionalInformation.mandatory">*</span>
            </span>
            <int-phone-number-input
                #phoneNumber="ngModel"
                [inputId]="additionalInformation.label"
                [(ngModel)]="additionalInformationAnswers[additionalInformation.label]"
                (ngModelChange)="notifyFieldChange()"
                [required]="additionalInformation.mandatory"
                [name]="additionalInformation.label"
                [maxlength]="additionalInformation.size || 100"
            ></int-phone-number-input>
        </label>
    </div>

    <div class="input-item " *ngSwitchCase="formElementType.mail">
        <label [ngClass]="{validate : !!email.valid, invalid : !email.valid && email?.dirty}">
            <span class="labelName">
                {{additionalInformation.label}}  <span class="required labelName"
                                                       *ngIf="additionalInformation.mandatory">*</span>
            </span>
            <input [(ngModel)]="additionalInformationAnswers[additionalInformation.label]"
                   (ngModelChange)="notifyFieldChange()"
                   type="email"
                   pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}"
                   [name]="additionalInformation.label"
                   [maxlength]="(additionalInformation.size || 100).toString()"
                   #email="ngModel"
                   [required]="additionalInformation.mandatory"/>
        </label>
        <div class="cell note error"
             [ngClass]="{'hiddenError': !(email?.invalid && (email?.touched || email?.dirty))}">
            {{'informations.administrator.email.incorrect' | translate}}
        </div>
    </div>

    <div class="input-item" *ngSwitchCase="formElementType.pattern">
        <label [ngClass]="{validate : !!pattern.valid, invalid : !pattern.valid && pattern?.dirty}">
            <span class="labelName">
                {{additionalInformation.label}}  <span class="required"
                                                       *ngIf="additionalInformation.mandatory">*</span>
            </span>
            <input [(ngModel)]="additionalInformationAnswers[additionalInformation.label]"
                   (ngModelChange)="notifyFieldChange()"
                   type="text"
                   [name]="additionalInformation.label"
                   [maxlength]="(additionalInformation.size || 100).toString()"
                   [required]="additionalInformation.mandatory"
                   #pattern="ngModel"/>
        </label>
    </div>

    <div class="input-item" *ngSwitchCase="formElementType.select">
        <label [ngClass]="{validate : !!select.valid, invalid : !select.valid && select?.dirty}">
            <span class="labelName">
                {{additionalInformation.label}} <span class="required" *ngIf="additionalInformation.mandatory">*</span>
            </span>
            <select [(ngModel)]="additionalInformationAnswers[additionalInformation.label]"
                    (ngModelChange)="notifyFieldChange()"
                    [name]="additionalInformation.label"
                    [required]="additionalInformation.mandatory"
                    #select="ngModel">
                <option *ngFor="let option of (!!additionalInformation.text ? additionalInformation.text.split(';') : [])"
                        [value]="option">{{option}}
                </option>
            </select>
        </label>
        <div class="cell note error"
             [ngClass]="{'hiddenError': !(select?.invalid && (select?.touched || select?.dirty))}">
            {{'informations.administrator.email.incorrect' | translate}}
        </div>
    </div>

    <div class="input-item noBorder" *ngSwitchCase="formElementType.vehicle">
        <label>
            <span class="labelName">
                {{additionalInformation.label}}  <span class="required" *ngIf="additionalInformation.mandatory">*</span>
            </span>
            <div class="subForm">
                <vehicle-version-selector [(ngModel)]="vehicleVersion"
                                          (ngModelChange)="addVehicleToAdditionalInformation(); notifyFieldChange()"
                                          [name]="additionalInformation.label"
                                          [required]="additionalInformation.mandatory"
                                          class="inputVehicle grid-x grid-margin-x small-24"></vehicle-version-selector>
            </div>
        </label>
        <div class="fake-label">&nbsp;</div>
    </div>

    <div class="input-item" *ngSwitchCase="formElementType.orderCode">
        <label [ngClass]="{validate : !!orderCode.valid && (orderCode.dirty || orderCode.touched)}">
            <span class="labelName">{{additionalInformation.label}} <span class="required"
                                                                          *ngIf="additionalInformation.mandatory">*</span></span>
            <input
                [(ngModel)]="additionalInformationAnswers[additionalInformationOrderCodeKey]"
                (ngModelChange)="notifyFieldChange()"
                type="text"
                [name]="additionalInformation.label"
                [maxlength]="(additionalInformation.size || 100).toString()"
                [required]="additionalInformation.mandatory"
                autocapitalize="sentences"
                #orderCode="ngModel"/>
        </label>
        <div class="cell note error"
             [ngClass]="{'hiddenError': !(orderCode?.invalid && (orderCode?.touched || orderCode?.dirty))}">
            {{'informations.administrator.email.incorrect' | translate}}
        </div>
    </div>

    <div class="input-item noBorder" *ngSwitchCase="formElementType.upload">
        <label [ngClass]="{validate : !!upload.valid, invalid : !upload.valid && upload?.dirty}">
            <span class="labelName">{{additionalInformation.label}}
                <span class="required" *ngIf="additionalInformation.mandatory">*</span></span>
            <div class="input-size">
                <p *ngIf="additionalInformation.text" class="help-text">({{additionalInformation.text}})</p>
                <input-file
                    [required]="additionalInformation.mandatory"
                    [(ngModel)]="additionalInformationAnswers[additionalInformation.label]
            && additionalInformationAnswers[additionalInformation.label].fileList"
                    (ngModelChange)="addFile(); notifyFieldChange()"
                    [name]="additionalInformation.label"
                    #upload="ngModel"
                    [text]="''"
                    accept="*">
                </input-file>
            </div>
        </label>
    </div>

    <div class="input-item" *ngSwitchCase="formElementType.checkbox">
        <label class="checkbox" [ngClass]="{validate : !!checkbox.valid, invalid : !checkbox.valid && checkbox?.dirty, required: additionalInformation.mandatory}">
            <input [(ngModel)]="additionalInformationAnswers[additionalInformation.label]"
                   (ngModelChange)="notifyFieldChange()"
                   [name]="additionalInformation.label"
                   [required]="additionalInformation.mandatory"
                   type="checkbox"
                   #checkbox="ngModel">
            <span class="custom-checkbox"></span>
            <span class="label-choice labelName">{{additionalInformation.label}}</span>
        </label>
    </div>
</div>
