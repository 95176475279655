import {ChangeDetectorRef, Directive, ElementRef, Input, Renderer2} from '@angular/core';

/**
 * Some buttons may declare some CSS classes that add ::before or ::after to display an additional icon along with the text. Therefore, applying this CSS class
 * will hide any content.
 */
const removePseudoElementContentClassName = 'remove-pseudo-content';

/***
 * This directive improve user experience by disabling the button and replacing the text (or any content) by a spinner while loading attribute is true.
 * This is especially useful and recommended to prevent user from clicking multiple times (which could lead to multiple requests and conflicting state).
 */
@Directive({
    selector: 'button[iziviaLoading]'
})
export class LoadingDirective {
    private initialInnerHTML: string;
    // As hideSpinner set disabled to false, without any business logic, we must call it only if spinner
    // is shown (meaning the element was not disabled at that time).
    private isSpinnerShown: boolean = false;

    @Input() set iziviaLoading(loading: boolean) {
        if (loading) {
            this.showSpinner();
        } else {
            if (this.isSpinnerShown) {
                this.hideSpinner();
            }
        }
    }

    constructor(private elementRef: ElementRef,  private cdRef: ChangeDetectorRef) {
    }

    private showSpinner(): void {
        this.isSpinnerShown = true;

        this.elementRef.nativeElement.disabled = true;
        this.elementRef.nativeElement.classList.add(removePseudoElementContentClassName);

        // This condition is needed in case iziviaLoading is set to true before content has been set
        if (this.elementRef.nativeElement.innerHTML) {
            this.initialInnerHTML = this.elementRef.nativeElement.innerHTML;
            this.elementRef.nativeElement.innerHTML = `<i class='fa fa-spinner fa-spin'></i>`;
        }
    }

    private hideSpinner(): void {
        if (this.initialInnerHTML) {
            this.elementRef.nativeElement.innerHTML = this.initialInnerHTML;
        }

        this.elementRef.nativeElement.classList.remove(removePseudoElementContentClassName);
        this.elementRef.nativeElement.disabled = false;

        this.isSpinnerShown = false;
    }
}
