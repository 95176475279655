import {NgModule} from '@angular/core';
import {LoginComponent} from './login.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ComponentsModule} from '../../components/components.module';
import {ForgottenPwdComponent} from './forgotten-pwd/forgotten-pwd.component';
import {ResetPwdComponent} from './reset-pwd/reset-pwd.component';
import {ServicesModule} from '../../services/services.module';
import {DirectivesModule} from '../../directives/directives.module';
import {LibFrontModule} from 'lib-front';

@NgModule({
    declarations: [
        LoginComponent,
        ForgottenPwdComponent,
        ResetPwdComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ServicesModule,
        ComponentsModule,
        LibFrontModule,
        DirectivesModule
    ]
})
export class LoginModule {
}
