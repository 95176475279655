<div class="cell auto app-view-content">
    <div class="info alert-info" *ngFor="let reminder of reminders">
        <div class="info-content">
         <span [innerHTML]="
            reminder.messageLabelKey | translate: {
                cardName: reminder.cardName,
                urlToInvoices:'#/main/invoices',
                expirationDate: (reminder.cardExpirationDate | date:'shortDate'),
                cardNumberLastDigits: reminder.cardNumberLastDigits
            }
        "></span>

            <div>
                <button (click)="dismissReminder(reminder)"
                        [iziviaLoading]="loading"
                        class="button small" >
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>
</div>
