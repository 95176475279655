import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {TabComponent} from '../tabs/tab.component';
import {TabsDirection} from '../tabs/tabs-direction';

@Component({
    selector: 'tabs-round',
    templateUrl: './tabs-round.component.html',
    styleUrls: ['./tabs-round.component.scss'],
    host: {'class': 'grid-x cell auto'}
})
export class TabsRoundComponent {

    @Input() public direction: TabsDirection = TabsDirection.HORIZONTAL;
    @Input() public hideHeads: boolean;
    @Input() set tabSelected (tabSelected: number) {
        this.selectItem(this.items[tabSelected]);
    }

    constructor(private location: Location) {}

    public items: Array<TabComponent> = [];
    public selectedItem: TabComponent;

    public registerTab(item: TabComponent): void {
        if (this.items.length === 0) {
            item.selected = true;
            this.selectedItem = item;
        }
        this.items.push(item);
    }

    public selectItem(item: TabComponent): void {
        this.selectedItem.selected = false;
        item.selected = true;
        this.selectedItem = item;

        if (item.changeUrl) {
            this.location.go(item.changeUrl);
        }
    }

    public isVertical(): boolean {
        return this.direction === TabsDirection.VERTICAL;
    }

    trackByTabTitle(index: number, tab: TabComponent) {
        return tab?.title ?? index;
    }
}
