import {
    AfterViewInit,
    Component,
    forwardRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    FrontReferenceHttpService,
    FrontSuggestionHttpService,
    LocationInputMode,
    LocationPipe
} from 'lib-front';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {AddressDirective} from '../address.directive';


export type AddressFormInputElem = 'description';
@Component({
    selector: 'automatic-address-suggestion-form[address]',
    templateUrl: './automatic-address-suggestion-form.component.html',
    styleUrls: ['./automatic-address-suggestion-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutomaticAddressSuggestionFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AutomaticAddressSuggestionFormComponent),
            multi: true
        }
    ]
})
export class AutomaticAddressSuggestionFormComponent extends AddressDirective implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('addressForm') formAddress: NgForm;
    protected inputFocusByAddressFormInputElem: Map<AddressFormInputElem, boolean> = new Map([['description', false]]);

    constructor(
        protected readonly suggestionService: FrontSuggestionHttpService,
        protected readonly referenceService: FrontReferenceHttpService,
        protected readonly locationPipe: LocationPipe) {
        super(suggestionService, referenceService, locationPipe);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        this.formAddress.statusChanges
            .pipe(debounceTime(1000))
            .subscribe(() => {
                this.formAddressDirtyChange.emit(this.formAddress.dirty);
                this.formAddressValidChange.emit(!this.formAddress.invalid);
            });
    }

    removeRouteAndClearAddressSuggestions() {
        this.resetAddress();
        this.description = null;
        this.clearAddressSuggestions();
    }

    focusOn(elem: AddressFormInputElem) {
        this.inputFocusByAddressFormInputElem.set(elem, true);
    }

    focusOut(elem: AddressFormInputElem) {
        if (this.description !== this.locationPipe.transform(this.address)) {
            this.removeRouteAndClearAddressSuggestions();
        }
        this.inputFocusByAddressFormInputElem.set(elem, false);
    }

    shouldShowAddressSuggestions(): boolean {
        return this.inputFocusByAddressFormInputElem.get('description') && this.addressSuggestions.length > 0;
    }

    private resetAddress(): void {
        this._address.streetNumber = null;
        this._address.route = null;
        this._address.extra = null;
        this._address.postalCode = null;
        this._address.city = null;
        this._address.countryCode = null;
        this._address.country = null;
    }
}
