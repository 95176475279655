import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {
    ChargeFilterKind,
    ChargeRecordCriteria,
    ChargeRecordDto,
    ChargeRecordDtoStatus,
    ChargeRecordStats,
    FrontChargeRecordHttpService,
    FrontEndFleetConfig,
    FrontInvoiceBusinessService,
    FrontInvoiceHttpService, FrontPersonPortalContextService,
    FrontSessionBusinessService
} from 'lib-front';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {InactivityService} from '../../../../services/utils/inactivity.service';
import {RepeatService} from '../../../../services/utils/repeat.service';
import {MetaConsumptionDto} from '../../../../domain/meta/metaConsumptionDto';

@Component({
    selector: 'chargeConsumptions',
    templateUrl: './chargeConsumptions.component.html',
    styleUrls: ['./chargeConsumptions.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ChargeConsumptionsComponent implements OnInit, OnDestroy {
    tab: string;
    fleetChargeRecordResults: ChargeRecordResults;
    stationChargeRecordResults: ChargeRecordResults;
    consumptionsResults: Array<MetaConsumptionDto>;
    fleetConfig: FrontEndFleetConfig;
    mode: ChargeFilterKind;
    stationChargeStatus: Array<ChargeRecordDtoStatus>;
    fleetChargeStatus: Array<ChargeRecordDtoStatus>;
    isStationManager: boolean;
    searchChargeSubscription: Subscription;
    searchStatsSubscription: Subscription;
    loading: boolean = true;

    modes = ChargeFilterKind;

    chargeRecordCriteria: ChargeRecordCriteria;
    inactiveRefreshInterval: Subscription;

    public constructor(private readonly chargeService: FrontChargeRecordHttpService,
        private readonly invoiceService: FrontInvoiceHttpService,
        private readonly invoiceBusiness: FrontInvoiceBusinessService,
        private readonly frontEndService: FrontEndService,
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly inactivityService: InactivityService,
        private readonly repeatService: RepeatService,
        private readonly personPortalContextService: FrontPersonPortalContextService) {
    }

    public ngOnInit(): void {
        this.fleetChargeRecordResults = new ChargeRecordResults();
        this.stationChargeRecordResults = new ChargeRecordResults();
        this.consumptionsResults = [];

        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig),
                tap(fleetConfig => this.fleetConfig = fleetConfig),
                switchMap(() => this.personPortalContextService.isStationManager()),
                finalize(() => this.loading = false)
            )
            .subscribe(isStationManager => {
                this.isStationManager = isStationManager;
                if (this.isStationManager) {
                    this.inactivityService.init(
                        () => this.setRefreshInterval(),
                        () => this.clearRefreshInterval()
                    );
                }
            });
    }

    ngOnDestroy(): void {
        this.inactivityService.destroy();
        this.clearRefreshInterval();
    }

    public searchCharges(criteria: ChargeRecordCriteria) {
        this.chargeRecordCriteria = criteria;
        this.searchChargeSubscription = this.chargeService.find(criteria)
            .subscribe(chargeRecordDto => {
                this.getChargeResultsFromChargeFilterKind(criteria.filterKind).chargeRecords = chargeRecordDto;
                this.getChargeStatusList(criteria.filterKind);

            });
    }

    public searchStats(criteria: ChargeRecordCriteria) {
        this.chargeRecordCriteria = criteria;
        this.searchStatsSubscription = this.chargeService.findChargeRecordStats(criteria)
            .subscribe(stats => {
                this.getChargeResultsFromChargeFilterKind(criteria.filterKind).stats = stats;
            });
    }

    public getChargeStatusList(criteriaKind: ChargeFilterKind) {
        if (this.isStationManager && criteriaKind === ChargeFilterKind.STATION) {
            this.stationChargeStatus = [ChargeRecordDtoStatus.CHARGING, ChargeRecordDtoStatus.IN_PROGRESS,
                ChargeRecordDtoStatus.COLLECTING, ChargeRecordDtoStatus.INVOICED,
                ChargeRecordDtoStatus.COLLECTED, ChargeRecordDtoStatus.ERROR];
        } else {
            this.fleetChargeStatus = [ChargeRecordDtoStatus.CHARGING, ChargeRecordDtoStatus.INVOICING, ChargeRecordDtoStatus.INVOICED];
        }
    }

    private getChargeResultsFromChargeFilterKind(chargeFilterKind: ChargeFilterKind) {
        return chargeFilterKind === ChargeFilterKind.FLEET
            ? this.fleetChargeRecordResults
            : this.stationChargeRecordResults;
    }

    private refreshData() {
        this.searchCharges(this.chargeRecordCriteria);
        this.searchStats(this.chargeRecordCriteria);
    }

    private setRefreshInterval() {
        this.refreshData();
        this.inactiveRefreshInterval =
            this.repeatService.repeat(() => this.refreshData(), 5 * 60 * 1000);
    }

    private clearRefreshInterval() {
        if (this.inactiveRefreshInterval) {
            this.inactiveRefreshInterval.unsubscribe();
        }
    }
}

class ChargeRecordResults {
    chargeRecords: Array<ChargeRecordDto>;
    stats: ChargeRecordStats;
}
