<div class="iziviaPopup" role="dialog">
    <div class="iziviaPopup-header">
        <h2 class="iziviaPopup-header_title">{{ 'stations.edit.incidentPopup.title' | translate }}</h2>
    </div>
    <div class="iziviaPopup-content">
        <p>{{'stations.edit.incidentPopup.text' | translate:{station: station.visualId, chargeArea: station.chargeAreaName} }}</p>
        <p>{{ 'stations.edit.incidentPopup.textAreaLabel' | translate }}</p>

        <textarea class="iziviaPopup-content_textarea"
                  [required]
                  [(ngModel)]="modalResponse.reason">
        </textarea>

        <div class="iziviaPopup-content_message"
             *ngIf="createIncidentReasonMissing">
            {{ 'stations.edit.incidentPopup.textAreaRequired' | translate }}
        </div>

        <label class="checkbox" for="incident-popup-checkbox">
            <input id="incident-popup-checkbox" type="checkbox" [(ngModel)]="modalResponse.checkbox">
            <span class="custom-checkbox"></span>
            <span class="label-choice">{{ 'stations.edit.incidentPopup.checkboxLabel' | translate }}</span>
        </label>
    </div>

    <div class="iziviaPopup-buttons">
        <button class="button color withMargin"
                type="button"
                (click)="confirmModal()">
            {{ 'alert.ok' | translate }}
        </button>
        <button class="button secondary withMargin"
                type="button"
                (click)="closeCreateIncidentModal()">
            {{ 'alert.cancel' | translate }}
        </button>
    </div>
</div>
