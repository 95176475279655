import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FinancialReportSelfCareSearchRequest} from 'lib-front';

@Component({
    selector: 'financial-report-search',
    templateUrl: './financial-report-search.component.html',
    styleUrls: ['./financial-report-search.component.scss']
})
export class FinancialReportSearchComponent {
    @Input() public isSelfcareInvoiceActivated = false;

    @Output()
    public search: EventEmitter<FinancialReportSelfCareSearchRequest> = new EventEmitter<FinancialReportSelfCareSearchRequest>();
    public searchRequest: FinancialReportSelfCareSearchRequest = {};

    invoiceSearch() {
        this.search.emit(this.searchRequest);
    }
}
