<div class="cell auto app-view-content">
    <div #scrollTop id="registerTop" class="grid-x cell shrink grid-margin-x align-justify main-container has-space">
        <div class="cell small-24">
            <h2 class="cell shrink">{{'subscription.pack.update.title' | translate}}
                <span>: {{oldSubscriptionPacksName}}</span>
            </h2>
            <step-indicator class="cell shrink" [current]="currentStep">
                <step class="text-uppercase cell shrink">{{'update.offers.title' | translate}}</step>
                <step class="text-uppercase cell shrink">{{'update.options.title' | translate}}</step>
                <step class="text-uppercase cell shrink">{{'update.medias.title' | translate}}</step>
                <step class="text-uppercase cell shrink">{{'update.summary.title' | translate}}</step>
            </step-indicator>
        </div>

        <update-subscription-offers class="cell small-24" *ngIf="currentStep" [hidden]="currentStep !== 1"
                                    [updateOffer]="updateOffer"
                                    [foAccount]="foAccount"
                                    [showToken]="false"
                                    (isFormValid)="updateOffersForm($event)"
                                    (changeBillingAddress)="updateBillingAddress($event)"
                                    (subscriptionPackOrTokenWrapperChange)="updateNewSubscriptionPack($event)">
        </update-subscription-offers>

        <div class="update-block padding">
            <additional-information
                    [hidden]="!shouldShowAdditionalInformation()"
                    [additionalInformationList]="getAdditionalInformationList()"
                    [additionalInformationAnswers]="additionalInformationAnswers"
                    #additionalInformationComponent>
            </additional-information>
        </div>

        <update-subscription-options class="cell small-24" *ngIf="currentStep >= 2" [hidden]="currentStep !== 2"
                                     [offerId]="offerRefForOptions"
                                     [currentOptions]="currentOptions"
                                     [subscriptionPack]="newSubscriptionPackOrTokenWrapper.subscriptionPackForOptions"
                                     (optionsToSubscribeEvent)="updateOptionsToSubscribe($event)"
                                     (optionsResultEvent)="updateOptionsResult($event)">
        </update-subscription-options>
        <update-subscription-medias class="cell small-24" *ngIf="currentStep >= 3" [hidden]="currentStep !== 3"
                                    [foAccount]="foAccount"
                                    [subscriptionPack]="newSubscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder"
                                    [mediaOrderForm]="mediaOrderForm"
                                    [mediaCanOrderDtoList]="mediaCanOrderDtoList"
                                    [numberCompatiblePass]="numberPassCarryOver"
                                    (isFormValid)="updateMediasForm($event)"
                                    (changeDeliveryAddress)="updateDeliveryAddress($event)"
                                    (mediaOrdersEvent)="updateMediasOrder($event)">
        </update-subscription-medias>
        <update-subscription-summary class="cell small-24" *ngIf="currentStep >= 4" [hidden]="currentStep !== 4"
                                     [optionResult]="optionsResult"
                                     [packSubscription]="packSubscription"
                                     [subscriptionPack]="newSubscriptionPackOrTokenWrapper.subscriptionPackToSubscribe"
                                     [orderMedias]="mediaOrderForm"
                                     [compatibleMedia]="numberPassCarryOver">
        </update-subscription-summary>

    </div>
    <div class="grid-x align-center align-justify main-container has-space">
        <button type="button" class="cell shrink button secondary force-space-right-8" routerLink="/main/subscriptions">
            {{'common.cancel' | translate}}
        </button>
        <button class="button color previous" type="button" (click)="goToBack()"
                [hidden]="currentStep === 1">{{'update.back' | translate}}
        </button>
        <div class="cell auto">&nbsp;</div>
        <button class="button color next" type="button" (click)="goToNext()"
                [hidden]="currentStep === 4"
                [disabled]="!activateNextStep || (currentStep === 1 && !additionalInformationComponent.valid)">{{'update.next' | translate}}
        </button>
        <button *ngIf="currentStep === 4"
                (click)="updateSubscription()"
                [iziviaLoading]="loading"
                [disabled]="!activateNextStep"
                class="button color next"
                type="button">{{'update.confirm.confirm' | translate}}
        </button>
    </div>
</div>

