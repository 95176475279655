import {Route} from '@angular/router';
import {FinancesView} from './finances.view';
import {expensesRoutes} from '../invoices/invoices.route';
import {incomeRoutes} from './incomes/income.route';
import {InfraManagementTariffCreationEnabledGuard} from './infra-management-tariff-creation-enabled.guard';


export const financesRoutes: Route[] = [
    {
        path: '', component: FinancesView, children: [
            {path: '', redirectTo: 'expenses', pathMatch: 'full'},
            {path: 'expenses', children: expensesRoutes},
            {path: 'incomes', children: incomeRoutes, canActivate: [InfraManagementTariffCreationEnabledGuard]}
        ]
    }
];
