<div class="cell auto app-view-content">
    <div class="grid-y cell auto main-container has-space force-space-bottom-0">
        <div class="grid-x cell shrink align-justify space-bottom-8 space-top-8">
            <h3 class="grid-x align-middle secondary block-title">
                {{'chargeAreas.title' | translate}}
            </h3>

            <a *ngIf="fleetConfig?.stationInstallationProposed ?? true"
               type="button"
               class="cell shrink button add mailto"
               [href]="'mailto:contact.commercial@izivia.com'">
                {{'chargeAreas.install' | translate}}
            </a>
        </div>
        <charge-area-filter *ngIf="chargeAreas?.length" [chargeAreas]="availableChargeAreas" [criteria]="criteria" [noResult]="noResult"
                            (filterChargeAreas)="filterChargeAreas($event)"></charge-area-filter>
        <div class="grid-x cell shrink align-right space-bottom-8 space-top-8">
            <a *ngIf="fleetConfig?.reportingEnabled && fleetConfig?.reportingList?.includes(Reporting.CHARGING_POINT)"
               [routerLink]="'/main/reporting/chargingStation'"
               class="cell shrink button secondary"
               type="button">
                {{'common.more' | translate}}
            </a>
        </div>
        <div class="grid-x cell shrink small-up-1  xxxlarge-up-2 grid-margin-x grid-padding-y">
            <ng-container *ngIf="chargeAreas?.length && !noResult else noChargeArea">
                <div *ngFor="let chargeArea of chargeAreas; trackBy: trackByChargeAreaId" class="cell shrink last-chargeAreas">
                    <charge-area [chargeArea]="chargeArea"
                                 [stationsCriteria]="stationCriteria"
                                 [hasStationWriteRole]="hasStationWriteRole"></charge-area>
                </div>
            </ng-container>
        </div>
        <div class="grid-y cell auto align-center align-middle message-empty"
             *ngIf="noResult">
            <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
            <span class="message-empty-label">{{'chargeConsumptions.no.result' | translate}}</span>
        </div>
    </div>

</div>
<ng-template #noChargeArea>
    <ng-container *ngIf="!isLoadingChargeAreas && !noResult else spinner">
        <div class="text-center align-center noChargeArea">
            <img class="message-empty-icon noChargeArea-img" src="/assets/svg/empty-borne.svg"/>
            <span class="message-empty-label text-center">{{'chargeAreas.empty' | translate}}</span>
        </div>
    </ng-container>
</ng-template>
<ng-template #spinner>
    <div class="grid-x cell grid-margin-x" *ngIf="isLoadingChargeAreas">
        <div class="app-loader">
            <span class="spinner"></span>
        </div>
    </div>
</ng-template>
