import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {emptyUser, FleetUser} from 'lib-front';
import {AccountFormValue} from '../../../components/informations/account-informations.component';

@Component({
    selector: 'register-information',
    templateUrl: './register-information.component.html',
    styleUrls: ['./register-information.component.scss']

})
export class RegisterInformationComponent {
    @Output() userEvent: EventEmitter<AccountFormValue> = new EventEmitter<AccountFormValue>();

    public user: FleetUser;

    constructor() {
        this.user = emptyUser();
    }

    public validateUser(accountFormValue: AccountFormValue): void {
        this.userEvent.emit(accountFormValue);
    }

}

