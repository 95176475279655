<div class="grid-y main-container" id="chargeArea-{{chargeArea.id}}">
    <div class="grid-x chargeArea">
        <h5 class="cell auto chargeArea-title">
            <div class="chargeArea-name">
                {{'chargeAreas.parc' | translate: {name: chargeArea.name} }}-
                 <span class="chargeArea-geolocation">{{chargeArea.geolocation | fleetLocation}}</span>
            </div>

        </h5>
        <button type="button" class="cell shrink small button round eye chargeArea-edit"
                (click)="editChargeArea()">
            <i class="fas fa-pen"></i>
        </button>
    </div>

    <div class="cell chargeArea-content">
        <collapsible-card #collapsibleCardComponent [parentId]="'chargeArea-'+chargeArea.id" [open]=isSelected() (collapse)="loadStations()">
            <ng-container fixed>
                <div class="grid-x cell shrink small-up-4 align-center indicators-bloc space-bottom-8">
                    <indicator class="cell color" [img]="'time'" [title]="'chargeAreas.monthHoursSubtitle' | translate">
                        <ng-container value *ngIf="!!monthStats; else spinner">
                            {{monthStats.chargingTimeInS | fleetDuration:{seconds: false, minutes: true} }}
                        </ng-container>
                    </indicator>
                    <indicator class="cell color" [img]="'energy'"
                               [title]="'chargeAreas.monthEnergySubtitle' | translate">
                        <ng-container value *ngIf="!!monthStats; else spinner">
                            {{monthStats.energyQuantityInWh / 1000 || 0 | number}}
                        </ng-container>
                        <ng-container label>{{'kWh' | translate}}</ng-container>
                    </indicator>
                    <indicator class="cell color" [img]="'price'"
                               [title]="'chargeAreas.monthAmountSubtitle' | translate">
                        <ng-container value *ngIf="!!monthStats; else spinner">
                            {{(monthStats.chargeAmount || 0) | currency:'EUR':monthStats.currencyUnit || '' }}
                        </ng-container>
                        <ng-container label>{{'TTC' | translate}}</ng-container>

                    </indicator>
                    <indicator class="cell color" [img]="'charge'"
                               [title]="'chargeAreas.stationNumberSubtitle' | translate">
                        <ng-container value>{{ getNbStations(chargeArea.stationInfraStatusMetrics) }}</ng-container>
                        <ng-container label>{{('chargeAreas.station') | translate}}
                        </ng-container>
                    </indicator>
                </div>

                <div class="grid-x grid-padding-x cell shrink small-up-6 align-center space-bottom-40"
                     *ngIf="!!chargeArea?.stationInfraStatusMetrics">
                    <station-status-indicator class="cell" *ngFor="let status of infraStatusValues"
                                              [number]="getNbStationWithStatus(chargeArea.stationInfraStatusMetrics,
                                              status)"
                                              [title]="'chargeAreas.statusSubtitle.'+status | translate"
                                              [status]="status"></station-status-indicator>
                </div>
            </ng-container>

            <ng-container collapsible *ngIf="!waitingStations; else spinner">
                <div class="grid-x cell shrink small-up-1 xlarge-up-2 space-bottom-8">
                    <charge-area-station class="cell" *ngFor="let station of stations; trackBy: trackByStationId"
                                         [station]="station"
                                         [chargeAreaRef]="chargeArea.id"
                                         [hasStationWriteRole]="hasChargeAreasWriteRole">
                    </charge-area-station>
                </div>
            </ng-container>
        </collapsible-card>
    </div>
</div>


<ng-template #spinner>
    <div class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>
</ng-template>

