<h3 class="cell small-24 secondary block-title space-bottom-24">
    {{'update.options.title' | translate}}
</h3>
<!--Options résiliées-->
<div class="cell grid-x space-bottom-24 block-container" [hidden]="terminationOptions.length === 0">
    <h3 class="cell small-24 secondary block-title">
        {{'update.subscription.options.terminate.title' | translate}}
    </h3>
    <subscription-options-blocks [options]="terminationOptions"></subscription-options-blocks>
</div>
<!--Options concervées-->
<div class="cell grid-x space-bottom-24 block-container" [hidden]="retainOptions.length === 0">
    <h3 class="cell small-24 secondary block-title space-bottom-16">
        {{'update.subscription.options.keep.title' | translate}}
        <span> *</span>
    </h3>
    <subscription-options-blocks [options]="retainOptions"></subscription-options-blocks>
    <div style="flex: content; text-align: right">
        <p><span>*</span>{{'update.subscription.options.additional.information' | translate}}</p>
    </div>
</div>
<!--Proposition d'options à ajouter-->
<div class="cell grid-x space-bottom-24 block-container">
    <form class="cell small-24">
        <subscription-options class="reset-space" [offerId]="offerId" [subscriptionPackId]="_subscriptionPack?._id"
                              [(ngModel)]="newOptions" name="newOptions" (ngModelChange)="calculateTotal()"
                              [mode]="ModeSubscriptionOption.UPDATE" (currency)="setCurrencyUnit($event)"></subscription-options>
    </form>
</div>
<!--Total-->
<div class="cell grid-x block-container">
    <h3 *ngIf="mode === 'update'" class="cell small-24 secondary block-title">
        {{'update.subscription.options.total.title' | translate}}
    </h3>
    <h3 *ngIf="mode === 'new'" class="cell small-24 secondary block-title">
        {{'new.subscription.options.total.title' | translate}}
    </h3>
    <div class="cell small-12" *ngIf="mode === 'update'">
        <h5 class="space-bottom-12">{{'update.subscription.options.total.before' | translate}}</h5>
        <subscription-options-result-view [totalPrice]="currentTotalPrice" [totalHours]="currentTotalHours"
                                          [options]="_currentOptions">
        </subscription-options-result-view>
    </div>
    <div class="cell small-24 align-center">
        <h5 class="space-bottom-12" *ngIf="mode === 'update'">{{'update.subscription.options.total.now' | translate}}</h5>
        <h5 class="space-bottom-12" *ngIf="mode === 'new'">{{'new.subscription.options.total.now' | translate}}</h5>
        <subscription-options-result-view [totalPrice]="totalPrice" [totalHours]="totalHours"
                                          [options]="_currentOptions">
        </subscription-options-result-view>
    </div>
</div>
