import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FrontPackSubscriptionHttpService} from 'lib-front';
import {Injectable} from '@angular/core';

@Injectable()
export class PackSubscriptionsConditionsGuard implements CanActivate, CanActivateChild {

    constructor(private readonly packSubscriptionsService: FrontPackSubscriptionHttpService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.hasAcceptedAllPackSubscriptionsConditions();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.hasAcceptedAllPackSubscriptionsConditions();
    }

    private hasAcceptedAllPackSubscriptionsConditions(): Observable<boolean> {
        return this.packSubscriptionsService.findPackSubscriptionByFoAccountAndCguNotAccepted()
            .pipe(
                tap(packSubscriptions => {
                    if (!!packSubscriptions.length) {
                        this.router.navigate(['/login']);
                    }
                }),
                map(packSubscriptions => !!packSubscriptions));
    }
}
