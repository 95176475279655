import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap, tap} from 'rxjs/operators';
import {
    FleetHomeStat, FrontEndFleetConfig,
    FrontFoAccountHttpService
} from 'lib-front';
import {User} from 'lib-front';
import {FrontEndService} from '../../../../services/frontEnd.service';

@Component({
    selector: 'home-fleet-collaborators',
    templateUrl: './home-fleet-collaborators.component.html',
    styleUrls: ['./home-fleet-collaborators.component.scss'],
    host: {'class': 'cell shrink home-card'}
})
export class HomeFleetCollaboratorsComponent implements OnInit {
    public user: User;
    public fleetHomeStat: FleetHomeStat;

    fleetConfig: FrontEndFleetConfig;

    constructor(route: ActivatedRoute,
        foAccountHttpService: FrontFoAccountHttpService,
        private readonly frontEndService: FrontEndService) {
        route.data.pipe(
            switchMap(() => foAccountHttpService.findFleetHomeStat()),
        ).subscribe(value => this.fleetHomeStat = value);
    }

    public ngOnInit(): void {
        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => this.fleetConfig = fleetConfig);
    }
}
