<div class="cell auto app-view-content">
    <div class="grid-y main-container has-space">
        <div class="cell shrink grid-x space-bottom-30">
            <h3 class="cell">
                {{'subscription.pack.options.add.title' | translate}}
            </h3>

            <div class="cell grid-x focus-block read-only space-top-16 space-bottom-16">
                <subscription-options-view  [options]="options" [showTotal]="false" [showBar]="false"></subscription-options-view>
            </div>

            <div class="cell grid-x">
                <form class="cell small-24">
                    <subscription-options class="reset-space" [offerId]="offerId" [subscriptionPackId]="packSubscription?.subscriptionPack._id"
                                          [(ngModel)]="newOptions" (ngModelChange)="calculateTotal()" name="newOptions"
                                          [mode]="ModeSubscriptionOption.ADD_OPTION" (currency)="setCurrencyUnit($event)"></subscription-options>
                </form>
            </div>

            <div class="cell grid-x">
                <h3 class="cell secondary block-title space-top-40 space-bottom-10">
                    {{'subscription.pack.options.total.new.title' | translate}}
                </h3>
            </div>

            <div class="cell grid-x">
                <div class="grid-x cell auto align-center subscription-result-block">
                    <div class="cell shrink subscription-result-block-item time">
                        {{totalHours | fleetDuration:{seconds: false, minutes: true} }}
                    </div>
                    <div class="cell shrink subscription-result-block-item price">
                        {{totalPrice | currency:'EUR': currencyUnit}}
                        <span class="force-font-bold ttcText align-self-bottom">TTC</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="cell shrink grid-x align-bottom align-justify">
            <div class="grid-x cell auto align-right action-bar">
                <div class="cell shrink force-space-right-15">
                    <a class="button secondary cell shrink" [routerLink]="'/main/subscriptions'"
                       [ngClass]="{disabled: loading}">
                        {{'common.cancel' | translate}}
                    </a>
                </div>

                <div class="cell shrink">
                    <button class="button primary cell shrink"
                            [iziviaLoading]="loading"
                            [disabled]="!(optionsToSubscribe && optionsToSubscribe.length) || !canOrderOption"
                            (click)="addOptions()">
                        {{'subscription.pack.option.button.validate' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
