import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'next-last-page',
    templateUrl: './next-last-page.component.html',
    styleUrls: ['./next-last-page.component.scss']
})
export class NextLastPageComponent {
    @Input() public pageNumber: number = 1;
    @Input() public maxPage: number;
    @Output() changePage: EventEmitter<number> = new EventEmitter();

    constructor() { }

    public nextPage() {
        this.pageNumber++;
        this.changePage.emit(this.pageNumber);
    }

    public previousPage() {
        this.pageNumber--;
        this.changePage.emit(this.pageNumber);
    }

    public isLastPage() {
        return this.maxPage <= this.pageNumber;
    }
}

