import {NgModule} from '@angular/core';
import {UtilsModule} from './utils/utils.module';
import {FrontEndService} from './frontEnd.service';
import {HttpServicesModule} from './http/httpServices.module';
import {EventsServicesModule} from './events/eventsServices.module';
import {BusinessServiceModule} from './business/businessService.module';

@NgModule({
    imports: [
        UtilsModule,
        HttpServicesModule,
        EventsServicesModule,
        BusinessServiceModule
    ],
    providers: [
        FrontEndService
    ]
})
export class ServicesModule {
}
