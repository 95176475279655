import {
    SocRecurringRequirementsRequest,
    RecurringRequirements
} from 'lib-front';
import RRule from 'rrule';
import moment from 'moment-timezone';

export class SocUtils {

    public static getRecurringRuleOptions(socRecurringRequirements: SocRecurringRequirementsRequest | RecurringRequirements,
        vehicleTimeZone: string) {
        const daysOfWeekStr = socRecurringRequirements.daysOfWeek.map((day) => {
            return day.substring(0,2);
        }).join();

        const options = RRule.parseString('BYDAY=' + daysOfWeekStr);
        options.dtstart = moment.tz(socRecurringRequirements.recurrence.from, vehicleTimeZone).toDate();
        options.until = moment.tz(socRecurringRequirements.recurrence.to, vehicleTimeZone).toDate();
        return options;
    }

    public static createDateFromApplicationDayAndSocTime(startDate: moment.Moment, socTime: string, vehicleTimeZone: string): moment.Moment {
        return moment.tz(startDate.format('YYYY-MM-DD') + 'T' + socTime, vehicleTimeZone);
    }
}
