<div class="cell auto app-view-content">
    <form [formGroup]="form" class="grid-y cell auto main-container has-space">
        <div class="cell auto grid-y" formArrayName="medias">
            <div class="grid-x cell shrink align-justify space-bottom-16">
                <h3 class="cell shrink grid-x align-middle secondary block-title space-bottom-16">
                    {{'media.activate.title' | translate}}
                </h3>
                <a class="cell shrink button secondary" [routerLink]="'/main/media'">
                    {{'media.activate.back' | translate}}
                </a>
            </div>

            <media-activate-form (validateOutput)="validatePass(false)"
                                 *ngFor="let media of mediaFormArray?.controls; trackBy: trackByIndex;
                                 let index = index; let last = last"
                                 [formControlName]="index"
                                 [last]="last"
                                 [logoRef]="logoRefs[index]"
                                 [showValidateError]="showValidateError"
                                 [subscribers]="subscribers" [userId]="user._id"
                                 [vehicles]="vehicles"
                                 class="cell grid-x media-activate-form">
            </media-activate-form>
            <div class="margeTop">
                <div class="cell shrink red" [hidden]="!validateError">{{'media.activate.error.title' | translate}}</div>
                <div class="cell shrink red" [hidden]="!validateErrorDisabled">{{'media.activate.error.disabled' | translate}}</div>
                <div class="cell shrink red" [hidden]="!validateErrorUsed">{{'media.activate.error.used' | translate}}</div>
            </div>

            <span class="cell small-24 grid-x align-right space-top-16"
                  [hidden]="form.valid || hasPackSubscriptionWithNoLimit || !hasSelectedSubscriptionPacks">
                {{'media.activateNotAvailable' | translate}}
                <a [routerLink]="'/main/subscriptions'">{{'media.upgrade' | translate}}</a>
            </span>
        </div>
    </form>
</div>
