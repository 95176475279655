import {Component, Input} from '@angular/core';
import {ConsumptionKind, SelfCareConsumptionDto} from 'lib-front';

@Component({
    selector: 'consumption-table',
    templateUrl: './consumption-table.component.html',
    styleUrls: ['./consumption-table.component.scss']
})
export class ConsumptionTableComponent {
    public ConsumptionKind = ConsumptionKind;
    @Input()
    public consumptions: SelfCareConsumptionDto[];
    @Input()
    public currencyUnit: string;
    @Input()
    public loading: boolean;

    constructor() {
    }

    trackByConsumptionId(index: number, consumption: SelfCareConsumptionDto) {
        return consumption?.id ?? index;
    }
}
