<div class="grid-y">
    <div class="collapseContainer">
        <div class="grid-x cell shrink small-up-4 align-center indicators-bloc">
            <indicator class="cell auto sm-block color" [img]="'receipt'">
                <ng-container value>{{expenseReportGroup.expenseReportCount | number}}
                </ng-container>
                <ng-container [ngPlural]="expenseReportGroup.expenseReportCount" label>
                    <ng-template
                        ngPluralCase="=1">{{'expenseReport.expenseReportCount.singular' | translate}}</ng-template>
                    <ng-template
                        ngPluralCase="other">{{'expenseReport.expenseReportCount.plural' | translate}}</ng-template>
                </ng-container>
            </indicator>

            <indicator class="cell auto sm-block color" [img]="'price'">
                <ng-container
                    value>{{expenseReportGroup.amountWithVat | currency: 'EUR': expenseReportGroup.currencyUnit}}
                </ng-container>
                <ng-container label>{{'expenseReport.withVat' | translate}}</ng-container>
            </indicator>

            <indicator class="cell auto sm-block color" [img]="'energy'">
                <ng-container value>{{expenseReportGroup.energyInkWh | number}}
                </ng-container>
                <ng-container label>{{'expenseReport.energyUnit' | translate}}</ng-container>
            </indicator>

            <div class="cell shrink grid-y collapseContainer-status">
                <span class="status status-color-{{expenseReportGroup.expenseReportStatusType}}">
                    {{'expenseReport.statuses.' + expenseReportGroup.expenseReportStatusType | translate}}
                </span>
            </div>
        </div>

        <div class="grid-x expenseReport-content">
            <div class="grid-x align-justify align-middle expenseReport-content_width">
                <div class="cell auto grid-x">
                    <h5 class="cell shrink expenseReport-content-title" *ngIf="expenseReportGroup.collaborator?.name">
                        <i class="fas fa-user expenseReport-content-title_icon"></i>
                        <span> {{expenseReportGroup.collaborator?.name}}</span>
                    </h5>
                    <div class="cell auto expenseReport-content-date">
                        <ng-container *ngIf="expenseReportGroup.month">
                            <span>|</span>
                            <i class="fas fa-calendar-alt expenseReport-content-title_icon"></i>
                            <h5> {{expenseReportGroup.month | date: 'MMMM YYYY'}}</h5>
                        </ng-container>
                    </div>
                </div>

                <div class="cell shrink expenseReport-content-refund" *ngIf="checkPlannedRefund()">
                    {{'expenseReport.expectedRefund' | translate : {date: computeExpectedRefundDate()} }}
                </div>
            </div>

            <div class="grid-x grid-margin-x cell auto expenseReport-content-button align-right">
                <div [hidden]="expenseReportGroup.expenseReportStatusType !== expenseReportStatusType.INITIAL"
                     class="cell shrink">
                    <button type="button"
                            (click)="acceptGroup()"
                            [iziviaLoading]="validateLoading"
                            [disabled]="refuseLoading"
                            class="button">
                        {{'expenseReport.accept' | translate}}
                    </button>
                </div>
                <div [hidden]="expenseReportGroup.expenseReportStatusType !== expenseReportStatusType.INITIAL"
                     class="cell shrink">
                    <button type="button"
                            (click)="rejectionModal.open()"
                            [iziviaLoading]="refuseLoading"
                            [disabled]="validateLoading"
                            class="button secondary color">
                        {{'expenseReport.refuse' | translate}}
                    </button>
                </div>
                <div *ngIf="!hasAutomaticallyRefundConfiguration
                        || expenseReportGroup.expenseReportStatusType === expenseReportStatusType.PAYMENT_ERROR"
                     [hidden]="expenseReportGroup.expenseReportStatusType !== expenseReportStatusType.WAITING_REFUND
                                && expenseReportGroup.expenseReportStatusType !== expenseReportStatusType.PAYMENT_ERROR
                                && expenseReportGroup.expenseReportStatusType !== expenseReportStatusType.AUTOMATIC_REFUND_IN_PROGRESS"
                     class="cell shrink">
                    <button type="button"
                            (click)="refundGroup()"
                            [iziviaLoading]="validateLoading"
                            class="button">
                        {{'expenseReport.markAsPaid' | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="grid-x align-right collapseContainer-moreDetail"
             *ngIf="!detailsShown">
            <a class="moreDetail"
               (click)="switchDetailsShown()">
                {{'expenseReport.moreDetails' | translate}}
            </a>
        </div>

        <div class="grid-x grid-padding-x collapseContainer-tab">
            <div *ngIf="expenseReports.length > 0 && detailsShown"
                 class="cell auto">
                <table>
                    <thead>
                    <tr>
                        <th>{{'expenseReport.table.header.status' | translate}}</th>
                        <th>{{'expenseReport.table.header.type' | translate}}</th>
                        <th>{{'expenseReport.table.header.collaborator' | translate}}</th>
                        <th>{{'expenseReport.table.header.date' | translate}}</th>
                        <th>{{'expenseReport.table.header.energyConsumed' | translate}}</th>
                        <th>{{'expenseReport.table.header.costWithVat' | translate}}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let expenseReport of expenseReports">
                        <td>{{'expenseReport.statuses.' + expenseReport.status.type | translate}}</td>
                        <td>{{'expenseReport.types.' + expenseReport.type | translate}}</td>
                        <td>{{expenseReport.collaborator?.name}}</td>
                        <td>{{expenseReport.date | date: 'dd/MM/YYYY'}}</td>
                        <td>{{expenseReport.energyInkWh | number}}</td>
                        <td>{{expenseReport.amountWithVat | currency: 'EUR':expenseReport.currencyUnit}}</td>
                        <td>
                            <button *ngIf="expenseReport.type !== expenseReportType.PERSONAL_USAGE"
                                    class="button round eye"
                                    (click)="redirectToExpenseReportDetail(expenseReport.id)"
                                    type="button">
                                <i class="far fa-eye"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="grid-x align-right collapseContainer-lessDetail"
             *ngIf="detailsShown">
            <a class="moreDetail"
               (click)="switchDetailsShown()">
                {{'expenseReport.lessDetails' | translate}}
            </a>
        </div>
    </div>
</div>

<izivia-popup #rejectionModal [size]="'unset'">
    <reject-expense-report-popup [expenseReportNumberMessage]="getExpenseReportNumberMessage()"
                                 (modalConfirm)="rejectGroup($event)"
                                 (modalClose)="rejectionModal.close()"></reject-expense-report-popup>
</izivia-popup>
