<div class="grid-y has-space">
    <div class="grid-x align-middle secondary block-title">
        <h3 class="secondary">
            {{'config.support.contacts' | translate}}
        </h3>
    </div>
    <div class="grid-x grid-padding-x grid-margin-y small-up-2 contactContainer space-top-16">
        <div class="cell" *ngIf="supportContacts.length === 0">
            {{'config.support.noContact' | translate}}
        </div>
        <div class="cell" *ngFor="let supportContact of supportContacts; trackBy: trackBySupportContactId">
            <contact-card [contact]="supportContact"></contact-card>
        </div>
    </div>
    <ng-container *ngIf="fleetSupportConfig && fleetSupportConfig.supportMailSubjects.length > 0">
        <div class="grid-x align-middle secondary block-title">
            <h3 class="secondary">
                {{'config.support.instantMessaging' | translate}}
            </h3>
        </div>

        <div class="grid-y grid-margin-y">
            <form class="space-top-16 space-bottom-32" #emailForm="ngForm" (ngSubmit)="sendMail()">
                <label class="required space-top-16">
                    <span>{{'config.support.topic' | translate}}</span>
                    <select class="selectSubject" [(ngModel)]="selectedSubject" name="subject" required>
                        <option *ngFor="let subject of fleetSupportConfig.supportMailSubjects" [ngValue]="subject">
                            {{subject.subject}}
                        </option>
                    </select>
                </label>

                <label class="required space-top-16 space-bottom-16">
                    <span>{{'config.support.message' | translate}}</span>
                    <textarea [(ngModel)]="email.content" class="content" name="content" required></textarea>
                </label>

                <button type="submit" class="button color"
                        [iziviaLoading]="sendingMail"
                        [disabled]="emailForm.invalid">
                    {{'config.support.send' | translate}}
                </button>
            </form>
        </div>
    </ng-container>
</div>
