<div class="cell auto app-view-content">
    <div class="grid-y cell auto main-container inner-space-all-16">
        <div class="grid-x align-justify space-bottom-16">
            <h3 class="grid-x align-middle secondary block-title">
                <i class="fas fa-shopping-cart block-title-icon"></i>
                {{'media.followOrder.title' |translate}}
            </h3>
            <a type="button" class="cell shrink button secondary" [routerLink]="'/main/media'">
                {{'media.followOrder.back' | translate}}
            </a>
        </div>
        <div class="cell auto">
            <table>
                <thead>
                <tr>
                    <th>{{'media.followOrder.date' | translate}}</th>
                    <th>{{'media.followOrder.numberPass' | translate}}</th>
                    <th>{{'media.followOrder.visuel' | translate}}</th>
                    <th>{{'media.followOrder.status' | translate}}</th>
                    <th>{{'media.followOrder.tracking' | translate}}</th>
                    <th>{{'media.followOrder.actions' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let media of medias; trackBy: trackByMediaOrderId">
                    <td>{{media.orderDate | date : "short"}}</td>
                    <td>{{media.quantity | number}}</td>
                    <td><img *ngIf="media.mediaVisual.logoRef" class="media-order-img space-right-32"
                             [src]="'/api/download/' + media.mediaVisual.logoRef" alt="media visual"></td>
                    <td>{{'media.order.mediaOrderStatus.' + media.currentStatus.status| translate}}</td>
                    <td *ngIf="!!media.deliveryInformation">
                        <span *ngIf="media.deliveryInformation.providerName">{{media.deliveryInformation?.providerName}}<br></span>
                        <a *ngIf="media.deliveryInformation.trackingNumber"
                           [href]="media.deliveryInformation?.providerUrl"
                           target="_blank">
                            {{media.deliveryInformation?.trackingNumber}}</a>
                    </td>
                    <td *ngIf="!media.deliveryInformation">
                    </td>
                    <td>
                        <a type="button"
                           *ngIf="media.invoice"
                           [hidden]="!fleetConfig?.showInvoiceSection || !hasInvoiceReadRole"
                           [href]="getInvoiceUrl(media.invoice)" target="_blank" rel="noopener noreferrer"
                           class="button round eye space-right-8"><i class="fas fa-eye"></i></a>
                        <button (click)="openSelectionPopup(media)"
                                *ngIf="canLinkMediaFromMediaOrder(media)"
                                title="{{'mediaOrder.flagAsReceivedAndLinkMedias' | translate}}"
                                class="button round">
                            <i class="fas fa-check"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<izivia-popup #subscriptionPacksSelectionPopup [size]="'medium'">
    <subscription-packs-to-link-select
            [mediaOrder]="mediaOrderToLink"
            (selectedSubscriptionPackRefs)="onSubscriptionPacksSelect($event)"
            (popupClosed)="subscriptionPacksSelectionPopup.close()">
    </subscription-packs-to-link-select>
</izivia-popup>
