<div class="cell auto" [class.grid-x]="isVertical()" [class.grid-y]="!isVertical()">
    <div *ngIf="!hideHeads"
          class="tabs cell shrink clear-padding "
         [class.vertical]="isVertical()"
         [class.focus-block]="isVertical()"
         [class.horizontal]="!isVertical()">
        <div  class="tabs-block">
            <div *ngFor="let item of items; trackBy: trackByTabTitle"
                 class="tabs-title"
                 (click)="selectItem(item)"
                 [class.is-active]="item.selected"
                 [hidden]="item.hidden" [class.horizontal]="!isVertical()">
                <a (click)="selectItem(item)" [attr.aria-selected]="item.selected"
                   *ngIf="!item.hidden">{{item.title}}</a>
            </div>
            <ng-content select="[buttons-section]"></ng-content>
        </div>
    </div>

    <div class="tabs-content main-container cell auto" [class.horizontal]="!isVertical()">
        <div class="tabs-panel is-active">
            <ng-content select="[tab-section]"></ng-content>
        </div>
    </div>
</div>
