import {Component, Input, ViewChild} from '@angular/core';
import {FILE_API_PATH} from 'lib-front';
import {ControlContainer, FormGroup, NgForm, NgModel} from '@angular/forms';

@Component({
    selector: 'subscription-pack-item-detail',
    templateUrl: './subscription-pack-item-detail.component.html',
    styleUrls: ['./subscription-pack-item-detail.component.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]

})
export class SubscriptionPackItemDetailComponent {
    @Input() title: string;
    @Input() subscriptionPackId?: string;
    @Input() infoItems?: string[];
    @Input() termsAndConditionsI18nCode?: string;
    @ViewChild('subscriptionPackCgu') cguField: NgModel;

    getCGUUrl(): string {
        return `${FILE_API_PATH}${this.termsAndConditionsI18nCode}?lang=fr`;
    }

    shouldDisplayCGURequired(): boolean {
        return this.cguField && this.cguField.control.invalid && (this.cguField.control.touched || this.cguField.control.dirty);
    }
}
