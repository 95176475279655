import {Component, OnInit} from '@angular/core';
import {HelpfulDocumentDetailDto, HelpfulDocumentHttpService} from 'lib-front';

@Component({
    selector: 'helpfulDocuments',
    templateUrl: 'helpfulDocuments.component.html',
    styleUrls: ['helpfulDocuments.component.scss']
})
export class HelpfulDocumentsComponent implements OnInit {

    public loading: boolean = true;
    public helpfulDocuments: HelpfulDocumentDetailDto[] = [];

    constructor(private helpfulDocumentHttpService: HelpfulDocumentHttpService) {
    }

    ngOnInit(): void {
        this.helpfulDocumentHttpService.findVisibleDocuments()
            .subscribe(documents => {
                this.helpfulDocuments = documents;
                this.loading = false;
            });
    }
}
