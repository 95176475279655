<div class="grid-y cell auto cell-block-y registerCreation">
    <h3 class="grid-x align-middle align-center cell secondary block-title registerCreation-title">
        {{ 'register.pending.title' | translate }}
    </h3>
    <div class="register-block creation-pending registerCreation-card">
        <p class="registerCreation-message subTitle">
            <i class="fas fa-check-circle registerCreation-icon"></i>{{ 'register.pending.subtitle' | translate }}
        </p>
        <p class="registerCreation-message">{{ 'register.pending.info1' | translate }}</p>
        <p class="registerCreation-message">{{ 'register.pending.info2' | translate }}
            <a href="mailto:service-client@izivia.com">{{ 'register.pending.customerService' | translate }}</a>
        </p>
        <p class="registerCreation-message">
            {{ 'register.pending.info3.1' | translate }}
            <a href="https://izivia.com/#">{{ 'register.pending.iziviaSite' | translate }}</a>
            {{ 'register.pending.info3.2' | translate }}
        </p>
    </div>
</div>
