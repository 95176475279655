import {Component, OnInit} from '@angular/core';
import moment from 'moment-timezone';
import {ActivatedRoute} from '@angular/router';
import {AssumedVehiclePlanning, Schedule, SmartChargingHttpService} from 'lib-front';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {AbstractHasRoleActionComponent} from '../../../../../components/has-role-action/abstract-has-role-action.component';
import {AssumedVehiclePlanningDto, ScheduleDto} from 'projects/app-fleet/src/app/domain/assumedVehiclePlanningDto';

@Component({
    selector: 'smart-charging',
    templateUrl: './smart-charging.component.html',
    styleUrls: ['./smart-charging.component.scss']
})
export class SmartChargingComponent extends AbstractHasRoleActionComponent implements OnInit {
    public readonly MAX_SCHEDULE_NUMBER = 3;
    public frontAssumedVehiclePlanning: AssumedVehiclePlanningDto = new AssumedVehiclePlanningDto();

    private readonly TIME_DELIMITER: string = ':';
    private needToCreate: boolean = true;

    constructor(private readonly route: ActivatedRoute,
        private readonly smartChargingHttpService: SmartChargingHttpService,
        protected readonly notificationService: NotificationService) {
        super(notificationService);
    }

    ngOnInit(): void {
        this.smartChargingHttpService.getAssumedVehiclesPlanningOfCurrentManager()
            .subscribe(assumedVehiclePlanning => {
                if (assumedVehiclePlanning) {
                    this.frontAssumedVehiclePlanning.id = assumedVehiclePlanning.id;
                    this.frontAssumedVehiclePlanning.foAccountRef = assumedVehiclePlanning.foAccountRef;
                    this.frontAssumedVehiclePlanning.energy = assumedVehiclePlanning.energy;
                    this.frontAssumedVehiclePlanning.schedules = this.mapSchedulesToDto(assumedVehiclePlanning.schedules);
                }
                if (assumedVehiclePlanning && assumedVehiclePlanning.id) {
                    this.needToCreate = false;
                }
            });
    }

    public addSchedule(): void {
        this.frontAssumedVehiclePlanning.schedules.push({from: moment()});
    }

    public trackByIndex(index: number) {
        return index;
    }

    public deleteSchedule(index: number): void {
        this.frontAssumedVehiclePlanning.schedules.splice(index, 1);
    }

    public save(): void {
        const assumedVehiclePlanning: AssumedVehiclePlanning = {
            id: this.frontAssumedVehiclePlanning.id,
            foAccountRef: this.frontAssumedVehiclePlanning.foAccountRef,
            energy: this.frontAssumedVehiclePlanning.energy,
            schedules: this.frontAssumedVehiclePlanning.schedules.map(
                frontSchedule => ({
                    from: frontSchedule.from.format('HH:mm')
                }))
        };
        if (this.needToCreate) {
            this.smartChargingHttpService.createAssumedVehiclePlanning(assumedVehiclePlanning)
                .subscribe((assumedVehiclePlanningCreated) => {
                    this.notificationService.success('config.smartCharging.form.create.success');
                    this.needToCreate = false;
                    this.frontAssumedVehiclePlanning.id = assumedVehiclePlanningCreated.id;
                    this.frontAssumedVehiclePlanning.foAccountRef = assumedVehiclePlanningCreated.foAccountRef;
                },
                () => this.notificationService.error('config.smartCharging.form.create.error')
                );
        } else {
            this.smartChargingHttpService.updateAssumedVehiclePlanning(assumedVehiclePlanning)
                .subscribe(
                    () => this.notificationService.success('config.smartCharging.form.update.success'),
                    () => this.notificationService.error('config.smartCharging.form.update.error')
                );
        }
    }

    private mapSchedulesToDto(schedules: Schedule[]): ScheduleDto[] {
        return schedules.map(stringSchedule => {
            const hoursAndMinutes = stringSchedule.from.split(this.TIME_DELIMITER);
            return {
                from: moment().set({
                    'hour': +hoursAndMinutes[0],
                    'minute': +hoursAndMinutes[1]
                })
            };
        });
    }
}
