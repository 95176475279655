import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {
    AdditionalInformationAnswers, FrontEndHttpService,
    FrontOfferHttpService,
    FrontTokenHttpService, HOST_THIRD_PARTY_ID,
    Offer,
    OfferCriteria,
    SubscriptionMode
} from 'lib-front';
import {NotificationService} from '../../../services/utils/notification.service';
import {ModeSubscriptionOption, OptionForm} from '../../../components/subscription-options/subscription-options.component';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {SubscriptionPackOrTokenWrapper} from '../../../domain/subscriptionPackOrTokenWrapper';

@Component({
    selector: 'register-subscription',
    templateUrl: './register-subscription.component.html',
    styleUrls: ['./register-subscription.component.scss']
})
export class RegisterSubscriptionComponent implements OnInit {
    ModeSubscriptionOption = ModeSubscriptionOption;
    SubscriptionMode = SubscriptionMode;

    public offer: Offer;
    public options: Array<OptionForm>;
    public isCguAccepted: boolean;
    public packTokensAvailable: boolean;
    public subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper;
    public optIn: boolean = false;
    public displayOptInAgreement: boolean = false;

    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() subscriptionEvent: EventEmitter<SubscriptionFormValue> = new EventEmitter<SubscriptionFormValue>();
    additionalInformationAnswers: AdditionalInformationAnswers = {};

    @ViewChild('additionalInformationComponent') additionalInformationComponent;

    constructor(private offerHttpService: FrontOfferHttpService,
        private frontEndHttpService: FrontEndHttpService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private tokenHttpService: FrontTokenHttpService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {
    }

    ngOnInit() {
        this.subscriptionPackOrTokenWrapper = new SubscriptionPackOrTokenWrapper();

        this.tokenHttpService
            .getPackTokenConfig(this.thirdPartyId)
            .subscribe(
                config => this.packTokensAvailable = config.packTokensAvailable
            );

        this.offerHttpService.find(this.thirdPartyId, new OfferCriteria())
            .subscribe(
                offer => {
                    if (!!offer) {
                        this.offer = offer;
                        this.route.queryParams
                            .pipe(
                                map(params => params.subscriptionPack)
                            )
                            .subscribe(subscriptionPackAlias => {
                                // if an alias is present then init with a subscriptionPack
                                const subscriptionPackOrTokenWrapper = new SubscriptionPackOrTokenWrapper();
                                subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe =
                                    this.findSubscriptionPackByAlias(subscriptionPackAlias) || this.offer.subscriptionPacks[0];

                                this.subscriptionPackOrTokenWrapper = subscriptionPackOrTokenWrapper;
                            });
                    } else {
                        this.notificationService.error('offers.error');
                    }
                },
                () => this.notificationService.error('offers.error'));

        this.frontEndHttpService.findFrontInfo(this.thirdPartyId).subscribe(result => {
            this.displayOptInAgreement = result.fleetConfig?.displayOptInAgreement ?? false;
        });
    }

    public acceptTerms(isAccepted: boolean) {
        this.isCguAccepted = isAccepted;
    }

    public stepBack() {
        this.back.emit();
    }

    shouldShowAdditionalInformationForm() {
        return !!this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe
            && !!this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations
            && this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations.length > 0;
    }

    public onSubmit() {
        this.subscriptionEvent.emit(
            {
                offer: this.offer,
                subscriptionPackOrTokenWrapper: this.subscriptionPackOrTokenWrapper,
                additionalInformationAnswers: this.additionalInformationAnswers,
                options: this.options,
                optIn: this.optIn
            }
        );
    }

    // Options components take a ref over an array of options
    // When a token is used, there is no offer
    // FIXME option component need to take an array of option over subscriptionPack or offer
    public get offerRefForOptions() {
        return this.subscriptionPackOrTokenWrapper.isSubscriptionPackMode() && this.offer ? this.offer._id : null;
    }

    public get subscriptionPackOptionsSelectedRef() {
        const subscriptionPackForOptions = this.subscriptionPackOrTokenWrapper.subscriptionPackForOptions;
        return !!subscriptionPackForOptions ? subscriptionPackForOptions._id : null;
    }

    private findSubscriptionPackByAlias(subscriptionPackAlias: string) {
        return this.offer.subscriptionPacks
            .find(subscriptionPack => subscriptionPackAlias === subscriptionPack.alias || subscriptionPackAlias === subscriptionPack._id);
    }

    getAdditionalInformationList() {
        return this.subscriptionPackOrTokenWrapper &&
            this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe &&
            this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations;
    }

    flushAdditionalInformationAnswers() {
        this.additionalInformationAnswers = {};
    }
}

export class SubscriptionFormValue {
    offer: Offer;
    subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper;
    additionalInformationAnswers: AdditionalInformationAnswers;
    options: Array<OptionForm>;
    optIn: boolean;
}

