import {Component, Input} from '@angular/core';
import {I18nFileFrontDto} from 'lib-front';

@Component({
    selector: 'faq-file[faqFile]',
    templateUrl: './faq-file.component.html',
    styleUrls: ['./faq-file.component.scss']
})
export class FaqFileComponent {

    @Input() faqFile: I18nFileFrontDto;

    constructor() {
    }
}
