import {Injectable, LOCALE_ID, Inject} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(@Inject(LOCALE_ID) private localeId: string) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req.clone({
            // server doesn't understand Accept-Language with region or with multiple languages (issue SMBP-4868),
            // so we need to truncate the locale to send only the language part
            headers: req.headers.set('Accept-Language', this.localeId.substring(0, 2))
        }));
    }
}
