import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {
    AdditionalInformationAnswers,
    AdditionalInformationService,
    CustomerAccount,
    FOAccountWrapper,
    FrontFoAccountHttpService,
    FrontMediaHttpService,
    FrontPackSubscriptionHttpService,
    FrontSubscriptionPackHttpService,
    MediaCanOrderDto,
    MediaOrder,
    MediaOrderContext,
    MultipleOptions,
    Offer,
    OptionSubscription,
    PackSubscription,
    PaymentConf,
    StdUser,
    Subscription,
} from 'lib-front';
import {getOptionsFromPackSubscription} from '../../../../../utils/packSubscriptionUtils.service';
import {OptionForm} from '../../../../../components/subscription-options/subscription-options.component';
import {AlertService} from '../../../../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {SubscriptionPackOrTokenWrapper} from '../../../../../domain/subscriptionPackOrTokenWrapper';
import {MediaOrderDto} from '../../../../../domain/mediaOrderDto';
import {clone, cloneDeep, isEmpty} from 'lodash-es';

@Component({
    selector: 'update-subscription',
    templateUrl: './update-subscription.component.html',
    styleUrls: ['./update-subscription.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class UpdateSubscriptionComponent implements OnInit {

    @ViewChild('scrollTop') private scrollTop;
    @ViewChild('additionalInformationComponent') additionalInformationComponent: ViewChild;

    currentStep: number;

    connectedUser: StdUser;
    packSubscription: PackSubscription;
    foAccountRef: string;
    updateOffer: Offer;
    activateNextStep: boolean;
    foAccount: FOAccountWrapper;

    // Subscribe to a new subscriptionPack or an array of subscriptionPacks (via token)
    newSubscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper;
    currentOptions: Array<OptionSubscription>;
    optionsToSubscribe: Array<OptionForm>;
    optionsResult: OptionsResult;
    saveOfFoAccount: FOAccountWrapper;
    oldSubscriptionPacksName: string;
    numberPassCarryOver: number;

    additionalInformationAnswers: AdditionalInformationAnswers = {};

    mediaOrderForm: MediaOrderDto;
    mediaCanOrderDtoList: MediaCanOrderDto[];
    readonly mediaOrderContext: MediaOrderContext = MediaOrderContext.SUBSCRIPTION;

    loading: boolean;

    private skipStepThree: boolean;
    private invoiceRef: String;
    private paymentConfs: PaymentConf[];
    private mediaFamilyRefsMap: Map<String, Number>;

    constructor(private packSubscriptionHttpService: FrontPackSubscriptionHttpService,
        private mediaHttpService: FrontMediaHttpService,
        private route: ActivatedRoute,
        private additionalInformationService: AdditionalInformationService,
        private subscriptionPackHttpService: FrontSubscriptionPackHttpService,
        private foAccountHttpService: FrontFoAccountHttpService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private router: Router
    ) {
        this.paymentConfs = [];
    }

    ngOnInit() {
        this.currentStep = 1;
        this.activateNextStep = false;
        this.skipStepThree = false;
        this.invoiceRef = null;
        this.numberPassCarryOver = 0;
        this.route.data
            .pipe(
                tap(data => this.connectedUser = data.user.user),
                tap(data => {
                    this.packSubscription = data.packSubscription;
                    this.packSubscription.additionalInformations = !this.packSubscription.additionalInformations ? {}
                        : this.packSubscription.additionalInformations;
                    this.paymentConfAvailable(this.packSubscription.customerAccounts);
                    // Search compatible Media
                    if (this.packSubscription.mediaRefs && this.packSubscription.mediaRefs.length > 0) {
                        this.mediaHttpService.findAndCountMediaFamilyRefByMediaRefs(this.connectedUser._id, this.packSubscription.mediaRefs)
                            .subscribe(mediaFamilyRefs => {
                                this.mediaFamilyRefsMap = mediaFamilyRefs;
                            });
                    }
                }),
                switchMap(() => this.foAccountHttpService.findFOAccount()),
                tap(foAccount => this.foAccount = foAccount),
                switchMap(() => this.subscriptionPackHttpService
                    .findUpdateOfSubscriptionPack(this.packSubscription.subscriptionPackRef, this.packSubscription._id)),
                tap(updateSubscriptions => this.updateOffer = updateSubscriptions[0]))
            .subscribe(() => {
                this.saveOfFoAccount = cloneDeep(this.foAccount);
                this.oldSubscriptionPacksName = this.packSubscription.subscriptionPackName;
                this.currentOptions = getOptionsFromPackSubscription(this.packSubscription);

            });
    }

    updateOffersForm(isChildrenFormValid: boolean) {
        if (this.currentStep === 1) {
            this.activateNextStep = isChildrenFormValid;
        }
    }

    updateMediasForm(isChildrenFormValid: boolean) {
        if (this.currentStep === 3) {
            this.activateNextStep = isChildrenFormValid;
        }
    }

    /** If the customer checks the functionality to keep the billing address after changing it,
     *  the original version must be returned to the object. */
    updateBillingAddress(keepBilling: boolean) {
        if (keepBilling) {
            this.foAccount.companyName = clone(this.saveOfFoAccount.companyName);
            this.foAccount.companySiret = clone(this.saveOfFoAccount.companySiret);
            this.foAccount.billing = cloneDeep(this.saveOfFoAccount.billing);
        }
    }

    updateDeliveryAddress(keepDelivery: boolean) {
        if (keepDelivery) {
            this.foAccount.delivery = cloneDeep(this.saveOfFoAccount.delivery);
        }
    }

    updateNewSubscriptionPack(newSubscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper) {
        this.newSubscriptionPackOrTokenWrapper = newSubscriptionPackOrTokenWrapper;
        this.additionalInformationAnswers = {};
        this.mediaHttpService.fetchOrderableBySubscriptionPackId(newSubscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder._id,
            this.mediaOrderContext)
            .subscribe(mediaCanOrderDtoList => {
                this.mediaCanOrderDtoList = mediaCanOrderDtoList;
                if (this.mediaCanOrderDtoList.length) {
                    this.mediaOrderForm = new MediaOrderDto();
                    this.mediaOrderForm.mediaCanOrderDto = mediaCanOrderDtoList[0];
                }
                this.calcPassCarryOver();
            });
    }

    // Options components take a ref over an array of options
    // When a token is used, there is no offer
    // FIXME option component need to take an array of option over subscriptionPack or offer


    public get offerRefForOptions() {
        return this.newSubscriptionPackOrTokenWrapper.isSubscriptionPackMode() && this.updateOffer ? this.updateOffer._id : null;
    }

    private calcPassCarryOver() {
        if (this.packSubscription.mediaRefs && this.packSubscription.mediaRefs.length > 0) {
            this.numberPassCarryOver = 0;
            this.newSubscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder.acceptedMediaFamilies.forEach(newMediaFamily => {
                if (this.mediaFamilyRefsMap[newMediaFamily._id] !== undefined) {
                    this.numberPassCarryOver += this.mediaFamilyRefsMap[newMediaFamily._id];
                }
            });
        }
    }

    updateOptionsToSubscribe(optionForms: Array<OptionForm>) {
        this.optionsToSubscribe = optionForms;
    }

    updateOptionsResult(optionsResult: OptionsResult) {
        this.optionsResult = optionsResult;
    }

    updateMediasOrder(mediaOrderFormValue: MediaOrderDto) {
        this.mediaOrderForm = mediaOrderFormValue;
    }

    /**
     * The navigating parent’s buttons must be reset unless the next child doesn't have a form to validate.
     */
    goToNext() {
        if (this.currentStep >= 1 && this.currentStep < 4) {
            this.currentStep++;
            if (this.currentStep === 3) {
                this.activateNextStep = this.checkDeliveryAddress();
            }
            this.scrollTop.nativeElement.scrollIntoView();
        }
    }

    /**
     * No matter what happens when you go back, the previously completed forms are considered valid without change.
     */
    goToBack() {
        this.activateNextStep = true;
        if (this.currentStep > 1 && this.currentStep <= 4) {
            this.currentStep--;
            this.scrollTop.nativeElement.scrollIntoView();
        }
    }

    checkDeliveryAddress() {
        return this.foAccount && !isEmpty(this.foAccount.companyName) && !isEmpty(this.foAccount.companySiret)
            && this.foAccount.delivery && !isEmpty(this.foAccount.delivery.firstName)
            && !isEmpty(this.foAccount.delivery.lastName) && !isEmpty(this.foAccount.delivery.phoneNumber)
            && !isEmpty(this.foAccount.delivery.email) && this.foAccount.delivery.address
            && !isEmpty(this.foAccount.delivery.address.route) && !isEmpty(this.foAccount.delivery.address.postalCode)
            && !isEmpty(this.foAccount.delivery.address.city);
    }

    orderOptions() {
        this.loading = true;
        const optionsPost: Array<MultipleOptions> = this.optionsToSubscribe
            .filter(option => option.number > 0)
            .map(option => {
                let customerSubscriptionParent: { customerAccount: CustomerAccount, subscription: Subscription };
                const customerAccounts = this.packSubscription.customerAccounts
                    .filter(customerAccount => customerAccount.subscriptions
                        .find(subscription =>
                            subscription.subscriptionDefRef === option.subscription.subscriptionDefParentRef)
                    );

                if (customerAccounts.length) {
                    customerSubscriptionParent = {
                        customerAccount: customerAccounts[0],
                        subscription: customerAccounts[0].subscriptions
                            .find(subscription =>
                                subscription.subscriptionDefRef === option.subscription.subscriptionDefParentRef)
                    };
                }

                if (customerSubscriptionParent
                    && customerSubscriptionParent.customerAccount
                    && customerSubscriptionParent.subscription) {
                    return {
                        numberOfSubscription: option.number,
                        customerId: customerSubscriptionParent.customerAccount._id,
                        parentSubscriptionId: customerSubscriptionParent.subscription.id,
                        subscription: {
                            targetRef: customerSubscriptionParent.subscription.targetRef,
                            subscriptionDefRef: option.subscription._id
                        }
                    };
                } else {
                    return null;
                }
            })
            .filter(value => value);
        this.packSubscriptionHttpService.subscribeMultipleOption(optionsPost)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(
                () => {
                    if (this.mediaOrderForm && this.mediaOrderForm.quantity > 0) {
                        // Pass
                        this.orderAndReportPass();
                    } else {
                        this.redirectTo();
                    }
                },
                () => this.notificationService.error('option.error')
            );
    }

    orderAndReportPass() {
        this.loading = true;

        this.mediaHttpService.createMediaOrder(this.packSubscription._id, {
            quantity: this.mediaOrderForm.quantity,
            mediaFamilyRef: this.mediaOrderForm.mediaCanOrderDto.mediaFamily.id
        })
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(
                (mediaOrder: MediaOrder) => {
                    if (!!mediaOrder && !!mediaOrder.invoiceRef) {
                        this.invoiceRef = mediaOrder.invoiceRef;
                    }
                    this.redirectTo();
                },
                () => this.notificationService.error('media.order.error'))
        ;
    }

    redirectTo() {
        if (!!this.invoiceRef && this.paymentConfs.length === 0) {
            this.showPayment();
        } else {
            this.updateSuccess();
        }
    }

    updateSuccess() {
        this.notificationService.success('update.subscribe.success');
        this.router.navigate(['/main/subscriptions']);
    }

    showPayment() {
        this.router.navigate(['/main/invoices/' + this.invoiceRef]);
    }

    private paymentConfAvailable(customerAccounts: CustomerAccount[]): void {
        customerAccounts.forEach((customerAccount) => {
            customerAccount.paymentConfs.forEach((paymentConf) => {
                if (!paymentConf.disabled && (paymentConf.paymentType === 'CREDIT_CARD_AUTHORIZATION' ||
                    paymentConf.paymentType === 'SEPA' || paymentConf.paymentType === 'CREDIT_CARD_RECURRING')) {
                    this.paymentConfs.push(paymentConf);
                }
            });
        });
    }

    updateSubscription() {
        if (this.newSubscriptionPackOrTokenWrapper.isPackTokenMode()) {
            return;
        }
        let filteredAdditionalInformation: AdditionalInformationAnswers = {};

        this.alertService.confirm(this.translateService.instant('update.confirm.alert'))
            .pipe(
                filter(confirm => confirm),
                tap(() => this.loading = true),
                switchMap(() => this.foAccountHttpService.foAccountSave(this.foAccount)),
                switchMap(() =>
                    this.additionalInformationService.handleAdditionalInformationFiles(
                        this.newSubscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations,
                        this.additionalInformationAnswers
                    )
                ),
                tap(res => filteredAdditionalInformation = res),
                switchMap(() => this.packSubscriptionHttpService.updatePackSubscription(
                    this.connectedUser._id,
                    this.packSubscription._id,
                    this.newSubscriptionPackOrTokenWrapper.subscriptionPackToSubscribe._id)
                ),
                tap(packSubscription => this.packSubscription = packSubscription),
                switchMap(packSubscription =>
                    this.packSubscriptionHttpService.updatePackSubscriptionAdditionalInformation(
                        this.connectedUser._id,
                        packSubscription._id,
                        filteredAdditionalInformation)
                ),
                finalize(() => this.loading = false)
            )
            .subscribe(packSubscription => {

                if (this.optionsToSubscribe && this.optionsToSubscribe.length > 0) {
                    // Options
                    this.orderOptions();
                } else if (this.mediaOrderForm && this.mediaOrderForm.quantity > 0) {
                    // Pass
                    this.orderAndReportPass();
                } else {
                    this.redirectTo();
                }
            });
    }

    getAdditionalInformationList() {
        return this.newSubscriptionPackOrTokenWrapper
            && this.newSubscriptionPackOrTokenWrapper.subscriptionPackToSubscribe
            && this.newSubscriptionPackOrTokenWrapper.subscriptionPackToSubscribe.additionalInformations;
    }

    shouldShowAdditionalInformation() {
        return this.currentStep === 1
            && this.getAdditionalInformationList() !== null;
    }
}


export class OptionsResult {
    currentTotalHours: number;
    currentTotalPrice: number;
    totalHours: number;
    totalPrice: number;
    options: Array<OptionSubscription>;

    constructor(currentTotalHours: number, currentTotalPrice: number, totalHours: number, totalPrice: number,
        options: Array<OptionSubscription>) {
        this.currentTotalHours = currentTotalHours;
        this.currentTotalPrice = currentTotalPrice;
        this.totalHours = totalHours;
        this.totalPrice = totalPrice;
        this.options = options;
    }
}
