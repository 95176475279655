import {NgModule} from '@angular/core';
import {MediaComponent} from './media.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MediaActivateComponent } from './media-activate/media-activate.component';
import {RouterModule} from '@angular/router';
import { MediaActivateFormComponent } from './media-activate/media-activate-form/media-activate-form.component';
import {MainMediaOrderComponent} from './main-media-order/main-media-order.component';
import {MediaDeactivationComponent} from './media-lost/media-deactivation.component';
import {ComponentsModule} from '../../../../components/components.module';
import { MediaFollowOrderComponent } from './media-follow-order/media-follow-order.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SubscriptionPacksToLinkSelectComponent } from './media-follow-order/subscription-packs-to-link-select/subscription-packs-to-link-select.component';
import {MediaReadRoleGuard} from './media-read-role.guard';
import {MediaWriteRoleGuard} from './media-write-role.guard';
import {DirectivesModule} from '../../../../directives/directives.module';

@NgModule({
    declarations: [
        MediaComponent,
        MediaActivateComponent,
        MediaActivateFormComponent,
        MainMediaOrderComponent,
        MediaFollowOrderComponent,
        MediaDeactivationComponent,
        SubscriptionPacksToLinkSelectComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NgSelectModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule
    ],
    providers: [
        MediaReadRoleGuard,
        MediaWriteRoleGuard
    ]
})
export class MediaModule {

}
