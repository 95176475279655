import {Component, Input} from '@angular/core';
import {PackSubscription, SubscriptionPack} from 'lib-front';

@Component({
    selector: 'promo-subscription-offer-view',
    templateUrl: './promo-subscription-offer-view.component.html',
    styleUrls: ['./promo-subscription-offer-view.component.scss'],
})
export class PromoSubscriptionOfferViewComponent {

    @Input('packSubscription') set packSubscriptionInput(packSubscription) {
        this.packSubscription = packSubscription;
        this.subscriptionPack = packSubscription?.subscriptionPack;
    }

    @Input('subscriptionPack') set subscriptionPackInput(subscriptionPack) {
        this.subscriptionPack = subscriptionPack;
    }

    packSubscription: PackSubscription;
    subscriptionPack: SubscriptionPack;

}
