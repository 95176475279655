import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';

@Pipe({
    name: 'asFormControl'
})
export class AbstractControlToFormControlPipe implements PipeTransform {

    constructor() { }

    /**
     * Convert an AbstractControl to a FormControl
     */
    transform(abstractControl: AbstractControl): UntypedFormControl {
        return abstractControl as UntypedFormControl;
    }

}
