import {Component, Input} from '@angular/core';
import {HelpfulDocumentDetailDto} from 'lib-front';

@Component({
    selector: 'helpful-document-detail',
    templateUrl: './helpful-document-detail.component.html',
    styleUrls: ['./helpful-document-detail.component.scss']
})
export class HelpfulDocumentDetailComponent  {

    @Input() helpfulDocument: HelpfulDocumentDetailDto;

    constructor() { }
}
