<div class="grid-x cell auto wrapper">
    <div class="columns cell auto">
        <h5 class="station-title">{{'stations.edit.tariff.title' | translate}}</h5>
        <div class="grid-x cell">
            <div *ngFor="let tariff of tariffs; trackBy: trackByTariffId"
                 class="cell tariff">{{'tariff.name'|translate:{name: tariff.name} }}
            </div>
            <div *ngIf="othrTariff"
                 class="cell tariff">
                {{'stations.edit.tariff.othrTariff.tag' | translate}}
            </div>
        </div>
    </div>

    <div class="columns cell shrink wrapper-buttons">
        <button type="button" class="button round color" [hidden]="selected">
            <i class="fas fa-chevron-down"></i>
        </button>
        <button type="button" class="button round color" [hidden]="!selected">
            <i class="fas fa-chevron-up"></i>
        </button>
    </div>
</div>
