import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'supporting-documents',
    templateUrl: './supporting-documents.component.html',
    styleUrls: ['./supporting-documents.component.scss']
})
export class SupportingDocumentsComponent {

    @Output() kbisChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() ribChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() identityCardChange: EventEmitter<File> = new EventEmitter<File>();

    fileExtensions: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

    ribChanged(file: File) {
        this.ribChange.next(file);
    }

    kbisChanged(file: File) {
        this.kbisChange.next(file);
    }

    identityCardChanged(file: File) {
        this.identityCardChange.next(file);
    }
}
