<label class="cell"
       [ngClass]="{validate : siretValid, invalid: !siretValid && siretInput?.dirty, required: required}">
    <span class="labelName">{{'informations.administrator.siret' | translate}}</span>
    <input [ngClass]="{validate : siretValid, required: required}"
           type="text"
           [(ngModel)]='siret'
           #siretInput='ngModel'>
    <div class="cell note error"
         [ngClass]="{hidden: (siretValid || !(siretInput?.touched || siretInput?.dirty))}">
        {{'informations.administrator.companySiret.error' | translate}}
    </div>
</label>
