<izivia-popup #popup
              [size]="'medium'">
    <div class="text-center">
        <i class="popup-icon fas fa-exclamation"></i>
        <h3 class="popup-title">{{'login.packSubscriptions.title' | translate }}</h3>

        <div class="popup-content">
            <div>{{'login.packSubscriptions.message' | translate }}</div>
            <div class="grid-x align-center space-top-15" *ngFor="let packSubscription of packSubscriptions">
                <label class="checkbox">
                    <input type="checkbox"
                           [(ngModel)]="packSubscription.cguAccepted">
                    <span class="custom-checkbox"></span>
                    <span class="label-choice">
                        {{'login.packSubscriptions.checkboxLabel' | translate : {name: packSubscription.subscriptionPackName} }}
                                <a class="font-size-16" target="_blank"
                                   href="{{getCGUUrl(packSubscription?.termsI18nCode)}}">{{'register.subscription.cgau.cgu' | translate}}</a>
                    </span>
                </label>
            </div>

        </div>

        <div class="grid-x grid-margin-x cell align-center add-space-block">
            <button class="cell shrink button validate"
                    (click)="validPackSubscriptionsConditions(true)"
                    [disabled]="allCguAreNotAccepted()">
                {{'login.packSubscriptions.button.accept' | translate }}
            </button>
            <a class="cell shrink button secondary loseButton"
               (click)="validPackSubscriptionsConditions(false)">
                {{'login.packSubscriptions.button.decline' | translate}}</a>
        </div>
    </div>
</izivia-popup>
