import {Route} from '@angular/router';
import {LoginComponent} from './login.component';
import {ForgottenPwdComponent} from './forgotten-pwd/forgotten-pwd.component';
import {ResetPwdComponent} from './reset-pwd/reset-pwd.component';

export const loginRoutes: Array<Route> = [
    {
        path: '', component: LoginComponent
    },
    {
        path: 'forgotten-pwd', component: ForgottenPwdComponent
    },
    {
        path: 'reset-pwd', component: ResetPwdComponent
    }
];
