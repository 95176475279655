import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'fleetCalendarEventTitle'
})
export class FleetCalendarRecurringEventTitle implements PipeTransform {

    constructor() {}

    transform(periodFrom: string, desiredCharge: number): string {
        // periodFrom needs to be in format HH:mm instead of HH:mm:ss
        const calendarEventPeriodFrom: string = periodFrom != null ? periodFrom.substring(0,5) : '';
        const calendarEventDesiredCharge: string = desiredCharge != null ? desiredCharge + '%' : '';
        return calendarEventPeriodFrom.concat(' ', calendarEventDesiredCharge);
    }
}

