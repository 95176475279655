import {UntypedFormGroup, NgForm, ValidationErrors} from '@angular/forms';

export function reduceFormGroupErrors(form: UntypedFormGroup | NgForm): ValidationErrors | null {
    return Object.keys(form.controls)
        .filter(key => form.controls[key].dirty || form.controls[key].touched)
        .filter(key => form.controls[key].invalid)
        .reduce((errors: any, name: string) => {
            errors[name] = form.controls[name].errors;
            return errors;
        }, {});
}
