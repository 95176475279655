<div class="text-center">
    <i class="popup-icon fas fa-check"></i>
    <h2 class="popup-title">{{'media.followOrder.subscriptionPacksSelectionPopup.title' | translate }}</h2>

    <div class="popup-content" *ngIf="subscribedPacksAvailableForLink.length > 0">
        <div class="popup-content_message space-bottom-24 space-top-16" [innerHTML]="'media.followOrder.subscriptionPacksSelectionPopup.message' | translate "></div>
        <ng-select [(ngModel)]="subscriptionPackRefs"
                   [multiple]="true"
                   [closeOnSelect]="false"
                   (clear)="clearSelect()"
                   [placeholder]="''">

            <ng-option *ngFor="let subscribedPack of subscribedPacksAvailableForLink; trackBy:trackById"
                       [value]="subscribedPack.id">{{subscribedPack.name}}</ng-option>

        </ng-select>
    </div>

    <div class="grid-x cell auto align-justify align-middle action-bar add-space-block">
        <div class="cell shrink">
            <button class="button"
                    [disabled]="loadingPackSubscriptions"
                    (click)="linkToSubscriptionPackRefs()">
                {{'media.followOrder.subscriptionPacksSelectionPopup.confirm' | translate }}
            </button>
        </div>
        <a class="cell shrink button secondary loseButton"
           (click)="close()">
            {{'common.cancel' | translate}}</a>
    </div>
</div>
