import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {map, switchMap} from 'rxjs/operators';
import {FrontSessionBusinessService} from 'lib-front';
import {ReportingService} from '../../../../services/business/reporting.service';

@Injectable()
export class ReportingGuard implements CanActivate {

    constructor(
        private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly reportingService: ReportingService,
        private readonly router: Router,
        private readonly frontEndService: FrontEndService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo?.fleetConfig),
                switchMap(fleetConfig => {
                    return this.reportingService.activeReportingTabs().pipe(
                        map(tabs => {
                            if (tabs?.length === 0) {
                                return this.router.createUrlTree(['/home']);
                            }
                            if (fleetConfig?.reportingEnabled) {
                                if (state.url === '/main/reporting') {
                                    if (tabs?.length > 0) {
                                        return this.router.createUrlTree([tabs[0].path]);
                                    }
                                } else if (state.url.includes('/main/reporting')) {
                                    if (tabs?.length > 0) {
                                        if (!tabs.map((tab) => tab.path).includes(state.url)) {
                                            return this.router.createUrlTree(['/main/reporting']);
                                        }
                                    }
                                }
                                return true;
                            } else {
                                return this.router.createUrlTree(['/home']);
                            }
                        })
                    );

                }
                )
            );
    }
}
