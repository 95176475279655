<div class="grid-y cell auto">
    <div class="grid-y cell auto main-container">
        <div class="space-bottom-32">
            <div class="grid-x cell shrink align-justify space-bottom-16">
                <h3 class="grid-x align-middle secondary block-title space-bottom-16">
                    {{'income.history.title' | translate}}
                </h3>
                <a type="button"
                   class="cell shrink button secondary"
                   [routerLink]="'..'">
                    {{'income.back' | translate}}
                </a>
            </div>

            <!--- invoice search --->

            <financial-report-search [isSelfcareInvoiceActivated]="isSelfcareInvoiceActivated"
                                     (search)="updateFinancialReportSearchRequest($event)"></financial-report-search>
        </div>

        <!--- page numbers and export buttons --->

        <div class="grid-x cell shrink space-bottom-8 space-top-16 align-middle">
            <next-last-page class="cell shrink"
                            [pageNumber]="currentPage"
                            [maxPage]="numberOfPages"
                            (changePage)="changePage($event)"></next-last-page>
            <div class="cell auto"></div>
            <a class="cell shrink button secondary color" (click)="exportResults()">
                {{'income.export' | translate}}
            </a>
        </div>

        <!--  invoice table  -->

        <financial-report-table
            [financialReports]="financialReports"
            [loading]="searching"
            [isSelfcareInvoiceActivated]="isSelfcareInvoiceActivated"></financial-report-table>

        <!--------------------->

        <next-last-page class="cell shrink"
                        [pageNumber]="currentPage"
                        [maxPage]="numberOfPages"
                        (changePage)="changePage($event)"></next-last-page>

    </div>
</div>
