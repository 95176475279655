import {Component} from '@angular/core';

@Component({
    selector: 'invoices-view',
    templateUrl: './invoices.view.html',
    styleUrls: ['./invoices.view.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class InvoicesView {

    constructor() {
    }
}
