<form #smartChargingForm="ngForm" (ngSubmit)="save()">
  <div class="grid-y has-space smartWrapper">
      <div>
          <h3 class="secondary block-title">
              Smart charging
          </h3>

          <div class="smartContainer">
              <h5>{{'config.smartCharging.header' | translate}}</h5>
          </div>
          <div class="smartContainer">
              <p>{{'config.smartCharging.explanation1' | translate}}</p>
              <p>{{'config.smartCharging.explanation2' | translate}}</p>
              <p>{{'config.smartCharging.explanation3' | translate}}</p>
              <p>{{'config.smartCharging.explanation4' | translate}}</p>
          </div>

          <div class="smartContainer">
              <h5 class="smartContainer-title">{{'config.smartCharging.energy' | translate}}</h5>

              <div class="grid-x inputContainer">
                  <input #energy="ngModel" [(ngModel)]="frontAssumedVehiclePlanning.energy" class="cell shrink"
                         min="0"
                         name="energy"
                         type="number">
                  <label class="cell auto">
                      <span>{{'config.smartCharging.energyUnit' | translate}}</span>
                  </label>
                  <div *ngIf="energy.dirty && energy.hasError('min')" class="cell error note">
                      {{'config.smartCharging.form.minEnergyError' | translate}}
                  </div>
              </div>
          </div>

          <div class="smartContainer">
              <div class="grid-x grid-margin-x">
                  <h5 class="cell shrink smartContainer-title">{{'config.smartCharging.schedules.title' | translate}}</h5>
                  <div class="cell shrink smartContainer-nb">{{'config.smartCharging.schedules.maxNumber' | translate}}</div>
              </div>

              <div class="grid-y grid-margin-y">
                  <ng-container *ngFor="let schedule of frontAssumedVehiclePlanning.schedules; let index = index; trackBy: trackByIndex">
                      <div class="cell shrink grid-x align-middle">
                          <fleet-time-picker class="cell shrink" [(ngModel)]="schedule.from" name="schedule_from_{{index}}"></fleet-time-picker>
                          <div class="cell shrink smartContainer-buttonDelete">
                              <button (click)="deleteSchedule(index)"
                                      [title]="('config.smartCharging.schedules.delete' | translate)"
                                      *ngIf="index !== 0"
                                      class="button round secondary tiny titleDisabledButton"
                                      type="button">
                                  <i class="fas fa-trash"></i>
                              </button>
                          </div>
                      </div>
                  </ng-container>
              </div>

              <div class="grid-x smartContainer-button">
                  <button (click)="addSchedule()"
                          [disabled]="frontAssumedVehiclePlanning.schedules.length >= MAX_SCHEDULE_NUMBER"
                          class="cell small-8 shrink button secondary color add"
                          type="button">
                      {{'config.smartCharging.schedules.add' | translate}}
                  </button>
              </div>
          </div>
      </div>


    <div class="grid-x grid-margin-x align-right save-button space-top-16">
        <button [disabled]="smartChargingForm.invalid"
                class="cell shrink button validate"
                type="submit">
            {{'config.smartCharging.save' | translate}}
        </button>
    </div>
  </div>
</form>
