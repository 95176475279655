import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FrontPersonPortalContextService} from 'lib-front';

@Component({
    selector: 'finances',
    templateUrl: './finances.view.html',
    styleUrls: ['./finances.view.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class FinancesView implements OnInit {
    public tabActivate: number = 0;

    public routedTabs: RoutedTab[] = [];
    private isInfraManagementTariffCreationEnabled: boolean;

    constructor(public router: Router,
        private personPortalContextService: FrontPersonPortalContextService) {
        personPortalContextService.isInfraManagementTariffCreationEnabled()
            .subscribe(isInfraManagementTariffCreationEnabled => this.isInfraManagementTariffCreationEnabled = isInfraManagementTariffCreationEnabled);
    }

    ngOnInit(): void {
        this.routedTabs.push({path: '/main/finances/expenses', label: 'finances.expenses'});
        if (this.isInfraManagementTariffCreationEnabled) {
            this.routedTabs.push({path: '/main/finances/incomes', label: 'finances.incomes.nav'});
        }
    }

    trackByTabPath(index: number, tab: RoutedTab) {
        return tab?.path ?? index;
    }
}

interface RoutedTab {
    label: string;
    path: string;
}

