import {Injectable} from '@angular/core';
import {Subscription, timer} from 'rxjs';

@Injectable()
export class InactivityService {
    private inactivityTimeLimitInMinutes: number = 5;
    private userActivityTimeout: Subscription;

    private inactivityCallback: () => void = () => {};
    private activityCallback: () => void = () => {};

    public init(inactivityCallback: () => void, activityCallback: () => void) {
        this.inactivityCallback = inactivityCallback;
        this.activityCallback = activityCallback;
        this.setTimeout();
        this.addMouseEventListener();
    }

    setTimeout() {
        this.userActivityTimeout =
            timer(this.inactivityTimeLimitInMinutes * 60 * 1000)
                .subscribe(() => this.inactivityCallback());
    }

    private addMouseEventListener() {
        document.addEventListener('mousemove', this.onMouseMove);
    }

    private onMouseMove = () => {
        this.userActivityTimeout.unsubscribe();
        this.activityCallback();
        this.setTimeout();
    };

    public destroy() {
        this.userActivityTimeout.unsubscribe();
        document.removeEventListener('mousemove', this.onMouseMove);
    }
}
