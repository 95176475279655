import {Route} from '@angular/router';
import {InvoicesComponent} from './invoices.component';
import {paymentRoutes} from './invoice-payment/invoicePayment.route';
import {InvoiceHistoricComponent} from './invoice-historic/invoice-historic.component';
import {PaymentGuard} from './payment.guard';
import {InvoicesView} from './invoices.view';
import {InvoiceReadRoleGuard} from './invoice-read-role.guard';

export const expensesRoutes: Array<Route> = [
    {path: '', component: InvoicesComponent, canActivate: [InvoiceReadRoleGuard]},
    {path: 'history', component: InvoiceHistoricComponent},
    {path: ':id', children: paymentRoutes, canActivate: [PaymentGuard], runGuardsAndResolvers: 'always'}
];

export const invoicesRoutes: Array<Route> = [
    {path: '', component: InvoicesView, children: expensesRoutes}
];
