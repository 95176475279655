import {Component, forwardRef, OnInit} from '@angular/core';
import {FrontVehicleVersionHttpService, VehicleMakeDto, VehicleModelDto, VehicleVersionDto} from 'lib-front';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {cloneDeep, find, isEqual} from 'lodash-es';

@Component({
    selector: 'vehicle-version-selector',
    templateUrl: './vehicle-version-selector.component.html',
    styleUrls: ['./vehicle-version-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VehicleVersionSelectorComponent),
            multi: true
        }
    ]
})
export class VehicleVersionSelectorComponent implements ControlValueAccessor, OnInit {
    currentVehicle: VehicleVersionDto = {};

    makes: Array<VehicleMakeDto>;
    models: Array<VehicleModelDto>;
    versions: Array<VehicleVersionDto>;

    private selectedVehicle: VehicleVersionDto;
    private onChange: (_: VehicleVersionDto) => {};
    private onTouched: () => void;

    private disabled: boolean;
    waitingMake: boolean;
    waitingModel: boolean;
    waitingVersion: boolean;

    constructor(private readonly vehicleService: FrontVehicleVersionHttpService) {
    }

    ngOnInit() {
        this.waitingMake = true;
        this.vehicleService.findAllMakes()
            .pipe(finalize(() => this.waitingMake = false))
            .subscribe(makes => this.makes = makes);
    }

    public registerOnChange(onChange: (_: VehicleVersionDto) => {}): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(value: VehicleVersionDto): void {
        if (value) {
            this.selectedVehicle = value;

            this.vehicleService.findAllMakes()
                .subscribe(() => {
                    this.currentVehicle = cloneDeep(this.selectedVehicle);
                    if (this.currentVehicle.make != null) {
                        this.onMakeChange();
                    }

                    if (this.currentVehicle.model != null) {
                        this.onModelChange();
                    }
                });
        } else {
            this.selectedVehicle = {};
            this.currentVehicle = {};
        }
    }

    onMakeChange(): void {
        this.models = [];
        this.versions = [];
        if (this.currentVehicle && !!this.currentVehicle.make) {
            this.waitingModel = true;
            this.vehicleService.findModelsByMake(this.currentVehicle.make)
                .pipe(finalize(() => this.waitingModel = false))
                .subscribe(
                    models => {
                        this.models = models;

                        // Set model to unknown (NA) only if a property has been changed by
                        // user (= prevent from erasing vehicle model set from writeValue)
                        if (!isEqual(this.currentVehicle, this.selectedVehicle)) {
                            if (this.models?.length === 1) {
                                this.currentVehicle.model = this.models[0].name;
                                this.onModelChange();
                            } else {
                                this.currentVehicle.model = null;
                            }
                            this.currentVehicle.version = null;
                        }
                    }
                );
        }
    }

    onModelChange(): void {
        this.waitingVersion = true;
        this.versions = [];
        this.vehicleService.findVersionsByMakeModel(this.currentVehicle.make, this.currentVehicle.model)
            .pipe(finalize(() => this.waitingVersion = false))
            .subscribe(
                versions => {
                    this.versions = versions;

                    // Set version to unknown (NA) only if a property has been
                    // changed by user (= prevent from erasing vehicle version set from writeValue)
                    if (!isEqual(this.currentVehicle, this.selectedVehicle)) {
                        if (this.versions?.length === 1) {
                            this.currentVehicle.version = this.versions[0].version;
                            this.onVersionChange();
                        } else {
                            this.currentVehicle.version = null;
                        }
                    }
                }
            );
    }

    onVersionChange(): void {
        // a version with an empty string is allowed, therefore we need to check explicitly against null or undefined value
        if (this.currentVehicle.version != null) {

            // Update vehicle version id based on version name
            const newVehicleVersion = find(this.versions, {version: this.currentVehicle.version});
            if (newVehicleVersion) {
                this.currentVehicle.id = newVehicleVersion.id;
            }
        }

        // Trigger change only if a property has changed
        if (!isEqual(this.currentVehicle, this.selectedVehicle)) {
            this.selectedVehicle = cloneDeep(this.currentVehicle);
            this.onChange(this.currentVehicle);
        }
    }

    isModelDisabled(): boolean {
        return !this.models
            || this.models.length === 0;
    }

    isVersionDisabled(): boolean {
        return !this.versions
            || this.versions.length === 0;
    }

    trackByName(index: number, item: VehicleMakeDto | VehicleModelDto): string {
        return item.name;
    }

    trackById(index: number, item: VehicleVersionDto): string {
        return item.id;
    }
}
