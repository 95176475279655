import {Component, OnInit} from '@angular/core';
import {FrontSessionBusinessService} from 'lib-front';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {FrontEndService} from '../../../services/frontEnd.service';

@Component({
    selector: 'register-creation-success',
    templateUrl: './register-creation-success.component.html',
    styleUrls: ['./register-creation-success.component.scss'],
    host : {'class' : 'grid-x cell auto'}
})
export class RegisterCreationSuccessComponent implements OnInit {
    isPaymentStepActivated: boolean;

    constructor(private readonly sessionBusinessService: FrontSessionBusinessService,
        private readonly router: Router,
        private readonly frontEndService: FrontEndService) {
    }

    ngOnInit(): void {
        this.frontEndService.currentFrontEndInfo$
            .pipe(
                map(frontEndInfo => frontEndInfo.fleetConfig)
            )
            .subscribe(fleetConfig => {
                this.isPaymentStepActivated = fleetConfig.showPaymentSection || false;
            });
    }

    public logout(): void {
        this.sessionBusinessService.logout()
            .subscribe(() => this.router.navigate(['/login']));
    }

}
