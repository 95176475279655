
<h2 class="cell shrink text-center">
    {{'subscription.pack.new.title' | translate}}
</h2>
<step-indicator class="cell shrink text-center" [current]="currentStep">
    <step class="text-uppercase cell shrink">{{'update.offers.title' | translate}}</step>
    <step class="text-uppercase cell shrink">{{'update.options.title' | translate}}</step>
    <step class="text-uppercase cell shrink">{{'update.medias.title' | translate}}</step>
    <step class="text-uppercase cell shrink">{{'update.summary.title' | translate}}</step>
</step-indicator>

<div class="cell auto app-view-content">
    <div #scrollTop id="registerTop" class="grid-x cell shrink grid-margin-x align-justify main-container has-space">
        <div class="cell small-24">
        <update-subscription-offers class="cell small-24" *ngIf="currentStep" [hidden]="currentStep !== 1"
                                    [updateOffer]="offer" [foAccount]="foAccount"
                                    (isFormValid)="updateOffersForm($event)"
                                    (changeBillingAddress)="updateBillingAddress($event)"
                                    (subscriptionPackOrTokenWrapperChange)="updateNewSubscriptionPackOrTokenWrapper($event)">
        </update-subscription-offers>
        <additional-information class="cell small-24"
            *ngIf="shouldShowAdditionalInformationForm()"
            [additionalInformationList]="getAdditionalInformationList()"
            [additionalInformationAnswers]="additionalInformationAnswers"
            #additionalInformationComponent>
        </additional-information>
        <update-subscription-options class="cell small-24" *ngIf="currentStep >= 2" [hidden]="currentStep !== 2"
                                     [mode]="'new'"
                                     [offerId]="offerRefForOptions"
                                     [subscriptionPack]="subscriptionPackOrTokenWrapper.subscriptionPackForOptions"
                                     (optionsToSubscribeEvent)="updateOptionsToSubscribe($event)"
                                     (optionsResultEvent)="updateOptionsResult($event)">
        </update-subscription-options>
        <update-subscription-medias class="cell small-24" *ngIf="currentStep >= 3" [hidden]="currentStep !== 3"
                                    [mode]="'new'"
                                    [foAccount]="foAccount"
                                    [subscriptionPack]="subscriptionPackOrTokenWrapper.subscriptionPackForMediaOrder"
                                    [numberCompatiblePass]="mediaRefsEligibleForActivation?.length"
                                    (isFormValid)="updateMediasForm($event)"
                                    (changeDeliveryAddress)="updateDeliveryAddress($event)"
                                    (mediaOrdersEvent)="updateMediasOrder($event)"
                                    [mediaOrderForm]="mediaOrderForm"
                                    [mediaCanOrderDtoList]="mediaCanOrderDtoList">
        </update-subscription-medias>
        <new-subscription-summary class="cell small-24" *ngIf="currentStep >= 4" [hidden]="currentStep !== 4"
                                  [offer]="offer"
                                  [subscriptionPackOrTokenWrapper]="subscriptionPackOrTokenWrapper"
                                  [media-order]="mediaOrderForm"
                                  [options]="optionsToSubscribe"
                                  (promoTokenChange)="onPromoToken($event)">
        </new-subscription-summary>
    </div>
    <div class="grid-x align-center align-justify main-container has-space small-24">
        <button type="button" class="cell shrink button secondary force-space-right-8" routerLink="/main/subscriptions">
            {{'common.cancel' | translate}}
        </button>
        <button class="button color previous" type="button" (click)="goToBack()"
                [hidden]="currentStep === 1">{{'update.back' | translate}}
        </button>
        <div class="cell auto">&nbsp;</div>
        <button class="button color next" type="button" (click)="goToNext()"
                [hidden]="currentStep === 4"
                [disabled]="!activateNextStep
                || (currentStep===1
                        && !!additionalInformationComponent
                        && !additionalInformationComponent.valid)">
            {{'update.next' | translate}}
        </button>
        <button *ngIf="currentStep === 4"
                (click)="subscribeToPack()"
                [iziviaLoading]="loading"
                [disabled]="!activateNextStep"
                type="button"
                class="button color next">{{'update.confirm.confirm' | translate}}
        </button>
    </div>
</div>

