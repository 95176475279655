import {EmoMediaOrderPrice, MediaCanOrderDto} from 'lib-front';

export class MediaOrderDto {
    quantity: number = 0;
    mediaCanOrderDto: MediaCanOrderDto;
    emoMediaOrderPrice?: EmoMediaOrderPrice;
    token?: string;
    linkMedia: boolean = false;

    // Check configuration and if user ordering media
    isOrderable() {
        return this.quantity > 0 && this.mediaCanOrderDto && this.mediaCanOrderDto.mediaFamily;
    }
}
