import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ChargeAreaDto,
    ChargeAreaSearchCriteria,
    FrontChargeAreaHttpService,
    FrontReferenceHttpService,
    InfraStatus,
    infraStatusValues,
    PlugTypeDto
} from 'lib-front';
import {flatMap} from 'lodash-es';


@Component({
    selector: 'charge-area-filter',
    templateUrl: './charge-area-filter.component.html',
    styleUrls: ['./charge-area-filter.component.scss']
})
export class ChargeAreaFilterComponent implements OnInit {
    get chargeAreas(): ChargeAreaDto[] {
        return this._chargeAreas;
    }

    @Input()
    set chargeAreas(value: ChargeAreaDto[]) {
        this._chargeAreas = value;
        this.chargeAreaPlugTypeNames = flatMap(value, chargeArea => chargeArea.plugTypeNames);
        this.updateAvailablePlugTypes();
    }
    @Output() filterChargeAreas: EventEmitter<ChargeAreaSearchCriteria> = new EventEmitter<ChargeAreaSearchCriteria>();
    availableStatus: InfraStatus[] = infraStatusValues();
    availablePlugTypes: PlugTypeDto[] = [];
    @Input() criteria: ChargeAreaSearchCriteria;
    @Input() noResult: boolean = false;
    private _chargeAreas: ChargeAreaDto[];
    private allPlugsTypes: PlugTypeDto[];
    private chargeAreaPlugTypeNames: string[];


    constructor(private readonly chargeAreaService: FrontChargeAreaHttpService,
        private readonly frontReferenceHttpService: FrontReferenceHttpService) {
    }

    ngOnInit(): void {
        this.frontReferenceHttpService.findPlugTypes()
            .subscribe(allPlugsTypes => {
                this.allPlugsTypes = allPlugsTypes;
                this.updateAvailablePlugTypes();
            });
    }

    public search() {
        this.filterChargeAreas.emit(this.criteria);
    }

    filterChargeAreaOptions(term: string, item: ChargeAreaDto): boolean {
        return item.name?.toLowerCase().includes(term.toLowerCase());
    }

    private updateAvailablePlugTypes() {
        if (this.allPlugsTypes?.length > 0 && this.chargeAreas?.length > 0) {
            this.availablePlugTypes = this.allPlugsTypes.filter(plugType =>
                this.chargeAreaPlugTypeNames.includes(plugType.name)
            );
        } else {
            this.availablePlugTypes = [];
        }
    }
}
