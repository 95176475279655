import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FrontPersonPortalContextService} from 'lib-front';

import {defaultIfEmpty, filter, map, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class RefundManagerGuard implements CanActivate, CanActivateChild {

    constructor(private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isRefundManager();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isRefundManager();
    }

    private isRefundManager(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personPortalContextService.isRefundManager().pipe(
                filter(isRefundManager => isRefundManager == true),
                defaultIfEmpty(false)
            ).subscribe(isRefundManager => {
                if (!isRefundManager) {
                    this.router.navigate(['/home']);
                }

                return resolve(isRefundManager);
            });
        });
    }
}
