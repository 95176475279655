<div class="style-guide-container">
    <div class="grid-x style-guide-content">

        <!-- NAV MENU - STYLE GUIDE -->
        <div class="grid-y cell shrink style-guide-nav-menu-container">
            <div class="cell auto style-guide-nav-menu">
                <div class="style-guide-nav-menu-title">
                    <h1 class="style-guide-nav-menu-header-title">Style Guide</h1>
                    <span class="style-guide-name-project">CIN New UI</span>
                    <hr>
                </div>

                <ul class="cell auto">
                    <li><a routerLinkActive="active" routerLink="/styleGuide/dashboard">Dashboard</a></li>
                </ul>

                <ul class="cell auto">
                    <li class="style-guide-item-section-nav"><a>ATOMS</a></li>
                    <ul class="style-guide-sub-nav">
                        <li><a routerLinkActive="active" routerLink="/styleGuide/colors">Couleurs</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/typography">Typography</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/icons">Icônes</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/buttons">Boutons</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/forms">Forms</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/status">Status</a></li>
                    </ul>

                    <li class="style-guide-item-section-nav"><a>MOLECULES</a></li>
                    <ul class="style-guide-sub-nav">
                        <li><a routerLinkActive="active" routerLink="/styleGuide/blocks">Blocks</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/messages">Messages</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/tabs" class="disabled">Onglets</a></li>
                        <li><a routerLinkActive="active" routerLink="/styleGuide/tables">Tableaux</a></li>
                    </ul>
                </ul>
            </div>
        </div>
        <!-- NAV MENU - STYLE GUIDE - END -->

        <!-- CONTENT - STYLE GUIDE -->
        <div class="grid-x cell auto style-guide-block-container">
            <router-outlet></router-outlet>
        </div>
        <!-- CONTENT - STYLE GUIDE - END-->
    </div>
</div>
