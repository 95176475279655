<div class="grid-y grid-margin-y has-space">
    <div class="grid-x align-middle secondary block-title cell">
        <h3 class="secondary">
            {{'config.helpfulDocuments.header' | translate}}
        </h3>
    </div>

    <div class="grid-x medium-up-2 grid-margin-x grid-margin-y">
        <helpful-document-detail *ngFor="let document of helpfulDocuments"
                                 class="cell"
                                 [helpfulDocument]="document">
        </helpful-document-detail>
    </div>

    <div *ngIf="loading"
         class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>
</div>
