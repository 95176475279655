<izivia-popup #popup
              [size]="'tiny'">
    <div class="text-center">
        <i class="popup-icon fas fa-exclamation"></i>
        <h3 class="popup-title">{{'login.cguPopup.title' | translate }}</h3>

        <div class="popup-content">
            <div>{{'login.cguPopup.message' | translate }}</div>
            <div class="grid-x align-center space-top-15">
                <label class="checkbox">
                    <input type="checkbox"
                           [(ngModel)]="termsAndConditionsOfUseAccepted">
                    <span class="custom-checkbox"></span>
                    <span class="label-choice checkbox-margin "
                          [innerHtml]="'login.cguPopup.checkboxLabel'|translate:{url: termsUrl}">
                </span>
                </label>
            </div>
        </div>

        <div class="grid-x grid-margin-x cell align-center add-space-block">
            <button class="cell shrink button validate"
                    (click)="validTermsAndConditionsOfUse(true)"
                    [iziviaLoading]="acceptingTerms"
                    [disabled]="!termsAndConditionsOfUseAccepted">
                {{'login.cguPopup.button.accept' | translate }}
            </button>
            <button class="cell shrink button secondary loseButton"
                    [disabled]="acceptingTerms"
                    (click)="validTermsAndConditionsOfUse(false)">
                {{'login.cguPopup.button.decline' | translate}}</button>
        </div>
    </div>
</izivia-popup>
