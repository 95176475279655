import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-status-guide',
    templateUrl: './status-guide.component.html',
    styleUrls: ['./status-guide.component.scss']
})
export class StatusGuideComponent {

    constructor() { }
}
