import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../../components/components.module';
import {ResolverModule} from '../../../../resolver/resolver.module';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ChargeAreasComponent} from './charge-areas.component';
import {ServicesModule} from '../../../../services/services.module';
import {StationManagerGuard} from './StationManager.guard';
import {RouterModule} from '@angular/router';
import {StationComponent} from './station/station.component';
import {ChargeAreaEditView} from './charge-area-edit/charge-area-edit.view';
import {ChargeAreaReadRoleGuard} from './charge-area-read-role.guard';
import {ChargeAreaWriteRoleGuard} from './charge-area-write-role.guard';

@NgModule({
    declarations: [
        ChargeAreasComponent,
        StationComponent,
        ChargeAreaEditView
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ServicesModule,
        ComponentsModule,
        ResolverModule,
        PipesModule,
        RouterModule
    ], providers: [
        StationManagerGuard,
        ChargeAreaReadRoleGuard,
        ChargeAreaWriteRoleGuard
    ]
})

export class ChargeAreasModule {}
