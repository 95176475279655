import {Route} from '@angular/router';
import {MainComponent} from './main.component';
import {chargeConsumptionsRoutes} from './chargeConsumptions/chargeConsumptions.route';
import {invoicesRoutes} from './invoices/invoices.route';
import {subscriptionsRoutes} from './subscriptions/subscriptions.route';
import {mediaRoutes} from './media/media.route';
import {configurationRoutes} from './configuration/configuration.route';
import {InvoicesGuard} from './invoices/invoices.guard';
import {importsRoutes} from './imports/imports.route';
import {chargeAreasRoutes} from './chargeAreas/charge-areas.route';
import {expenseReportRoutes} from './expenseReport/expense-report.route';
import {StationManagerGuard} from './chargeAreas/StationManager.guard';
import {financesRoutes} from './finances/finances.route';
import {CurrentDetailedPersonResolver} from '../../../resolver/currentDetailedPerson.resolver';
import {RefundManagerGuard} from './expenseReport/refund-manager.guard';
import {CurrentSubscriberRolesResolver} from '../../../resolver/currentSubscriberRoles.resolver';
import {CurrentSubscriberRolesGuard} from './currentSubscriberRoles.guard';
import {reportingRoutes} from './reporting/reporting.route';
import {ReportingGuard} from './reporting/reporting.guard';
import {ConfigurationTabGuard} from '../../../guard/configuration-tab.guard';

export const mainRoutes: Route[] = [
    {
        path: '', canActivate: [CurrentSubscriberRolesGuard], resolve: {subscriberRoles: CurrentSubscriberRolesResolver}, component: MainComponent, children: [
            {path: 'invoices', children: invoicesRoutes, canActivate: [InvoicesGuard], runGuardsAndResolvers: 'always'},
            {path: 'finances', children: financesRoutes, canActivate: [StationManagerGuard]},
            {path: 'chargeConsumptions', children: chargeConsumptionsRoutes},
            {path: 'chargeAreas', children: chargeAreasRoutes, canActivate: [StationManagerGuard]},
            {path: 'subscriptions', children: subscriptionsRoutes},
            {path: 'media', children: mediaRoutes},
            {path: 'expenseReport', children: expenseReportRoutes, canActivate: [RefundManagerGuard]},
            {path: 'configuration', children: configurationRoutes, canActivate: [ConfigurationTabGuard], resolve: {detailedPerson: CurrentDetailedPersonResolver}},
            {path: 'imports', children: importsRoutes},
            {path: 'reporting', children: reportingRoutes, canActivate: [ReportingGuard], runGuardsAndResolvers: 'always'}
        ]
    }
];
