import {Component, Input, OnInit} from '@angular/core';
import {TabsRoundComponent} from './../tabs-round/tabs-round.component';

@Component({
    selector: 'tab-round',
    templateUrl: './tab-round.component.html',
    styleUrls: ['./tab-round.component.scss']
})
export class TabRoundComponent {
    @Input()
    public title: string;

    @Input()
    public hidden: boolean;

    @Input()
    public changeUrl: string;

    public selected: boolean = false;

    constructor(tabs: TabsRoundComponent) {
        tabs.registerTab(this);
    }
}
