import {Component, OnInit} from '@angular/core';
import {FleetImportCriteria, FleetImportHttpService} from '../../../../services/http/fleetImportHttp.service';
import {
    ExecutionTaskGroupStatusType,
    FleetImportDto,
    FleetImportRequestReport,
    FrontCurrentPersonBusinessService
} from 'lib-front';
import {filter, map, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {noop, Observable, Subscription, timer} from 'rxjs';
import {NotificationService} from '../../../../services/utils/notification.service';

@Component({
    selector: 'import',
    templateUrl: './imports.component.html',
    styleUrls: ['./imports.component.scss'],
    host: {'class': 'cell auto scroll-container'}
})
export class ImportsComponent implements OnInit {
    private foAccountRef: string;
    private limit = 10;
    private refreshIfInProgressImports: Observable<void> = timer(10_000, 10_000)
        .pipe(
            tap(() => this.doSearch()),
            map(() => noop())
        );
    private refreshIfInProgressImportsSubscription: Subscription;

    hasNextPage = false;
    criteria: FleetImportCriteria;
    searchSubscription: Subscription;
    imports: Array<FleetImport> = [];
    importSelectedId;

    constructor(private currentPersonBusinessService: FrontCurrentPersonBusinessService,
        private fleetImportHttpService: FleetImportHttpService,
        private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.criteria = new FleetImportCriteria();
        this.criteria.startDate = moment().startOf('month');
        this.criteria.status = '';
        this.criteria.endDate = moment();
        this.criteria.skip = 0;
        this.criteria.limit = this.limit;

        this.currentPersonBusinessService.foAccountManager$
            .pipe(
                filter(foAccount => !!foAccount),
                map(foAccount => foAccount.foAccountRef),
                tap(foAccountRef => this.foAccountRef = foAccountRef)
            )
            .subscribe(() => this.search());
    }

    search() {
        this.criteria.skip = 0;
        this.criteria.limit = this.limit;
        this.doSearch();
    }

    nextPage() {
        if (this.hasNextPage) {
            this.criteria.updateLimitForNext();
            this.doSearch();
        }
    }

    previousPage() {
        if (this.criteria.skip !== 0) {
            this.criteria.updateLimitForBack();
            this.doSearch();
        }
    }

    selectImportDetails(importData: FleetImport) {
        if (importData.id !== this.importSelectedId) {
            this.importSelectedId = importData.id;
        } else {
            this.importSelectedId = null;
        }
    }

    public get importStatusList() {
        return Object.keys(FleetImportStatus);
    }

    private doSearch() {
        this.searchSubscription = this.fleetImportHttpService.fetchImports(this.foAccountRef, this.criteria)
            .pipe(
                tap(page => this.hasNextPage = page.next),
                map(page => page.data),
                tap(imports => this.refreshResultIfInProgress(imports))
            )
            .subscribe(
                imports => this.imports = FleetImport.ofArray(imports),
                () => this.notificationService.error('imports.search.error')
            );
    }

    /**
     * If there is at least one INITIAL/IN-PROGRESS import, then we need refresh to show the progress
     */
    private refreshResultIfInProgress(importDtos: FleetImportDto[]) {
        const hasRefreshableStatus = !!importDtos.find(importData => this.hasRefreshableStatus(importData));
        if (!this.refreshIfInProgressImportsSubscription && hasRefreshableStatus) {
            this.refreshIfInProgressImportsSubscription = this.refreshIfInProgressImports.subscribe();
        } else if (this.refreshIfInProgressImportsSubscription && !hasRefreshableStatus) {
            this.unsubscribeRefreshSearchSubscription();
        }
    }

    private hasRefreshableStatus(fleetImportDto: FleetImportDto) {
        return fleetImportDto.status === ExecutionTaskGroupStatusType.INITIAL
            || fleetImportDto.status === ExecutionTaskGroupStatusType.IN_PROGRESS;
    }

    private unsubscribeRefreshSearchSubscription() {
        if (this.refreshIfInProgressImportsSubscription) {
            this.refreshIfInProgressImportsSubscription.unsubscribe();
            this.refreshIfInProgressImportsSubscription = null;
        }
    }

    trackByImportId(index: number, fleetImport: FleetImport) {
        return fleetImport?.id ?? index;
    }
}

class FleetImport {
    id: string;
    status: FleetImportStatus;
    progressBarColor: 'secondary' | 'green' | 'red' | 'grey';
    treatmentStartDate: string;
    treatmentEndDate: string;
    duration: number;
    fileRef: string;
    filename: string;
    size: number;
    executedTasks: number;
    fleetImportRequestReport: FleetImportRequestReport;

    static ofArray(fleetImportDtos: FleetImportDto[]) {
        return fleetImportDtos.map(fleetImportDto => this.of(fleetImportDto));
    }

    static of(fleetImportDto: FleetImportDto) {
        const fleetImport = new FleetImport();

        if (fleetImportDto.status) {
            switch (fleetImportDto.status) {
                case ExecutionTaskGroupStatusType.INITIAL:
                    fleetImport.status = FleetImportStatus.INITIAL;
                    break;
                case ExecutionTaskGroupStatusType.IN_PROGRESS:
                case ExecutionTaskGroupStatusType.PENDING:
                    fleetImport.status = FleetImportStatus.IN_PROGRESS;
                    fleetImport.progressBarColor = 'secondary';
                    break;
                case ExecutionTaskGroupStatusType.ENDED:
                case ExecutionTaskGroupStatusType.CANCELED:
                    fleetImport.status = FleetImportStatus.ENDED;
                    fleetImport.progressBarColor = 'green';
                    break;
                case ExecutionTaskGroupStatusType.TECHNICAL_ERROR:
                    fleetImport.status = FleetImportStatus.TECHNICAL_ERROR;
                    fleetImport.progressBarColor = 'red';
                    break;
            }
        }

        if (fleetImportDto.treatmentStartDate && fleetImportDto.treatmentEndDate) {
            fleetImport.duration = moment(fleetImportDto.treatmentEndDate).unix() - moment(fleetImportDto.treatmentStartDate).unix();
        }

        fleetImport.id = fleetImportDto.id;
        fleetImport.treatmentStartDate = fleetImportDto.treatmentStartDate;
        fleetImport.treatmentEndDate = fleetImportDto.treatmentEndDate;
        fleetImport.fileRef = fleetImportDto.fileRef;
        fleetImport.filename = fleetImportDto.filename;
        fleetImport.size = fleetImportDto.size;
        fleetImport.executedTasks = fleetImportDto.executedTasks;
        fleetImport.fleetImportRequestReport = fleetImportDto.fleetImportRequestReport;

        return fleetImport;
    }
}

enum FleetImportStatus {
    INITIAL = 'INITIAL',
    IN_PROGRESS = 'IN_PROGRESS',
    ENDED = 'ENDED',
    TECHNICAL_ERROR = 'TECHNICAL_ERROR'
}
