import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FOAccountWrapper, Offer, SubscriptionMode} from 'lib-front';
import {NgForm} from '@angular/forms';
import {SubscriptionPackOrTokenWrapper} from '../../../../../../domain/subscriptionPackOrTokenWrapper';
import {isEmpty} from 'lodash-es';
import { ModeAddressForm } from '../../../../../../components/address-form/modeAddressForm';

@Component({
    selector: 'update-subscription-offers',
    templateUrl: './update-subscription-offers.component.html',
    styleUrls: ['./update-subscription-offers.component.scss']
})

export class UpdateSubscriptionOffersComponent implements AfterViewInit {
    SubscriptionMode = SubscriptionMode;
    ModeAddressForm = ModeAddressForm;

    @Input('updateOffer') set updateOfferInput(updateOffer) {
        this.updateOffer = updateOffer;

        if (this.updateOffer && updateOffer._id !== null) {
            this.subscriptionPackOrTokenWrapper.subscriptionPackToSubscribe = this.updateOffer.subscriptionPacks[0];
            this.onSubscriptionPackOrTokenWrapperChange();
        }
    }

    @Input() set foAccount(foAccount) {
        this._foAccount = foAccount;
        this.initKeepAddress();
    }

    @Input() showToken = true;

    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter();
    @Output() changeBillingAddress: EventEmitter<boolean> = new EventEmitter();
    @Output() subscriptionPackOrTokenWrapperChange: EventEmitter<SubscriptionPackOrTokenWrapper> = new EventEmitter<SubscriptionPackOrTokenWrapper>();

    public subscriptionPackOrTokenWrapper: SubscriptionPackOrTokenWrapper = new SubscriptionPackOrTokenWrapper();

    @ViewChild('updateSubscriptionOfferForm') form: NgForm;

    public _foAccount: FOAccountWrapper;
    public updateOffer: Offer;
    public keepAddress: boolean;
    public isValidAddress: boolean;
    public isCguTermsAccepted: boolean;

    constructor() {
    }

    ngAfterViewInit(): void {
        this.form.statusChanges.subscribe(() => {
            this.sendFormValidation();
        });
    }

    public onSubscriptionPackOrTokenWrapperChange() {
        this.subscriptionPackOrTokenWrapperChange.emit(this.subscriptionPackOrTokenWrapper);
    }

    public onFormAddressChange(isValid: boolean) {
        this.isValidAddress = isValid;
        this.sendFormValidation();
    }

    public sendFormValidation() {
        this.isFormValid.emit(!this.form.invalid && this.isValidAddress && this.isCguTermsAccepted);
    }

    public keepBillingAddress() {
        this.keepAddress = !this.keepAddress;
        this.changeBillingAddress.emit(this.keepAddress);
    }

    private initKeepAddress() {
        this.keepAddress = this._foAccount && !isEmpty(this._foAccount.companyName) && !isEmpty(this._foAccount.companySiret)
            && this._foAccount.billing && !isEmpty(this._foAccount.billing.firstName)
            && !isEmpty(this._foAccount.billing.lastName) && !isEmpty(this._foAccount.billing.phoneNumber)
            && !isEmpty(this._foAccount.billing.email) && this._foAccount.billing.address
            && !isEmpty(this._foAccount.billing.address.route) && !isEmpty(this._foAccount.billing.address.postalCode)
            && !isEmpty(this._foAccount.billing.address.city);
    }

    public acceptTerms(isAccepted: boolean) {
        this.isCguTermsAccepted = isAccepted;
        this.sendFormValidation();
    }
}
