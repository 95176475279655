import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    FinancialReportAction,
    FinancialReportDto,
    FinancialReportInvoiceDto,
    FinancialReportSelfCareHttpService
} from 'lib-front';
import {delay, filter, switchMap, tap} from 'rxjs/operators';
import {NotificationService} from '../../services/utils/notification.service';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'financial-report-table',
    templateUrl: './financial-report-table.component.html',
    styleUrls: ['./financial-report-table.component.scss']
})
export class FinancialReportTableComponent {

    public FinancialReportAction = FinancialReportAction;

    @Input()
    public financialReports: FinancialReportDto[];

    @Input()
    public loading = false;


    @Input()
    public isSelfcareInvoiceActivated = false;

    @Output()
    public reloadReports: EventEmitter<void> = new EventEmitter<void>();

    constructor(private reportService: FinancialReportSelfCareHttpService,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService,
        private readonly alertService: AlertService) {

    }

    trackByFinancialReportId(index: number, financialReport: FinancialReportDto) {
        return financialReport?.id ?? index;
    }

    public markInvoiceAsPayedInReport(reportId: string, invoice: FinancialReportInvoiceDto): void {
        this.alertService.confirm(
            this.translateService.instant(
                'financialReport.confirmPopup.MARK_INVOICE_AS_PAYED.message',
                {invoiceNumber: invoice.number}
            )
        ).pipe(
            filter(confirm => confirm),
            switchMap(() => this.reportService.markInvoiceAsPayedInReport(reportId, invoice.ref)),
            delay(1000),
            tap(() => this.reloadReports.emit())
        ).subscribe(
            () => this.notificationService.success('financialReport.actionNotification.success.MARK_INVOICE_AS_PAYED'),
            _ => this.notificationService.error('financialReport.actionNotification.error.MARK_INVOICE_AS_PAYED')
        );
    }
}
